import { useObservable } from "@mindspace-io/react";
import React from "react";
import { modalQuery } from "../state/modal/modal.query";

export const ModalsContainer: React.FC = ({ children }) => {
  const [visibleModals] = useObservable(modalQuery.selectVisibleModals(), []);

  const renderModals = visibleModals.map((m) => {
    return <React.Fragment key={m.type}>{m.component}</React.Fragment>;
  });

  return (
    <>
      {children}
      {renderModals}
    </>
  );
};
