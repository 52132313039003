import React from "react";
import { Button } from "../components/button/button";
import { DASHBOARD_PATH } from "../routes";

export const Page404: React.FC = () => {
  return (
    <div className="app">
      <div className="layout">
        <div className="container">
          <div className="row full-height align-items-center">
            <div className="col-12">
              <div className="text-center p-t-50">
                <h2 className="font-size-170 text-brand font-weight-light text-opacity ls-2">
                  404
                </h2>
                <h2 className="font-weight-light font-size-30">
                  Whoops! Looks like you got lost
                </h2>
                <p className="lead">
                  We couldnt find what you were looking for.
                </p>
                <Button
                  primary
                  to={DASHBOARD_PATH}
                  className="mx-auto"
                  style={{ maxWidth: "300px" }}
                >
                  Go Back
                </Button>
              </div>
            </div>
            <div className="col-12 align-self-end ">
              <div className="text-center p-b-20 font-size-13">
                <span>
                  Copyright © 2022{" "}
                  <a
                    className="text-dark"
                    href="https://getsubly.com"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Subly
                  </a>
                  . All rights reserved.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
