import classNames from "classnames";
import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { getCustomer, getPlans } from "../../api/billing.service";
import { Modal } from "../../containers/modal/modal";
import {
  PlanIcon,
  PlanLimits,
  PlanPrice
} from "../../containers/settings/plans/plans-table";
import {
  PlansTableContext,
  PlansTableProvider
} from "../../contexts/plans-table.context";
import { usePlan } from "../../hooks/use-plan";
import { useUser } from "../../hooks/use-user";
import { PlanInterval, SublyPlan } from "../../interfaces/billing";
import { PLANS_PATH, PLANS_UPGRADE_PATH, SALES_CONTACT } from "../../routes";
import { getPlanData, isBusinessPlan, SublyPlanData } from "../../utils/plans";
import { Button } from "../button/button";
import { LocationState } from "../button/plans-button";
import { CheckmarkIcon } from "../icons/icons";
import { PricingToggle } from "../toggle/pricing-toggle";
import styles from "./trial-limit-reached-modal.module.scss";

interface TrialLimitReachedModalProps {
  closeModal: () => void;
  trialEndsToday?: boolean;
  outOfMinutes?: boolean;
  daily?: boolean;
}

export const TrialLimitReachedModal: React.FC<TrialLimitReachedModalProps> = ({
  closeModal,
  trialEndsToday,
  outOfMinutes,
  daily
}) => {
  const { user } = useUser();

  React.useEffect(() => {
    if (!user) {
      return;
    }

    getCustomer({ force: false });
    getPlans();
  }, [user]);

  return (
    <Modal
      onDismiss={closeModal}
      className={classNames(styles["modal"])}
      hasCloseIcon
    >
      <PlansTableProvider interval={PlanInterval.Yearly}>
        <TrialLimitReachedModalContainer
          closeModal={closeModal}
          trialEndsToday={trialEndsToday}
          outOfMinutes={outOfMinutes}
          daily={daily}
        />
      </PlansTableProvider>
    </Modal>
  );
};

interface TrialLimitReachedModalContainerProps {
  closeModal: () => void;
  trialEndsToday?: boolean;
  outOfMinutes?: boolean;
  daily?: boolean;
}
export const TrialLimitReachedModalContainer: React.FC<
  TrialLimitReachedModalContainerProps
> = ({ closeModal, trialEndsToday, outOfMinutes, daily }) => {
  const { isYearly, setIsYearly, proPlan, premiumPlan, businessPlan } =
    useContext(PlansTableContext);
  const { plan } = usePlan();
  const { plan: planName } = getPlanData(plan);

  const headerTitle = outOfMinutes
    ? "Subscribe now before you run out of minutes"
    : trialEndsToday
    ? "Your Subly Trial ends today"
    : daily
    ? "Get all your minutes and unlimited downloads now!"
    : "You’ve reached the limit of project on your Subly Trial";

  return (
    <>
      <div className={styles["header"]}>
        <h5>{headerTitle}</h5>
        {!outOfMinutes && (
          <span>
            Thanks for trying out {planName} - to continue using Subly, please
            choose from a plan
          </span>
        )}
      </div>

      <div
        className={classNames(
          "d-flex align-items-center justify-content-center",
          styles["toggle-margin"]
        )}
      >
        <PricingToggle value={isYearly} onChange={setIsYearly} />
      </div>

      <div className={styles["card-container"]}>
        <SublyPlanCard plan={proPlan} closeModal={closeModal} />
        <SublyPlanCard plan={premiumPlan} closeModal={closeModal} />
        <SublyPlanCard plan={businessPlan} closeModal={closeModal} />
      </div>

      <div className={styles["link"]}>
        <Button primary to={PLANS_PATH} onClick={closeModal}>
          Browse all plans
        </Button>
      </div>
    </>
  );
};

interface SublyPlanCardProps {
  plan: SublyPlan;
  closeModal: () => void;
}

const SublyPlanCard: React.FC<SublyPlanCardProps> = ({ plan, closeModal }) => {
  const planData = getPlanData(plan);
  const { interval } = useContext(PlansTableContext);
  const state = useLocation().state as LocationState;

  return (
    <div className={styles["card"]}>
      <div className={styles["plan"]}>
        <PlanIcon plan={planData} />
      </div>
      <PlanPrice planData={planData} />

      <div className={styles["plan-button"]}>
        <Button
          className={styles["button"]}
          to={{
            pathname: PLANS_UPGRADE_PATH
          }}
          state={{
            ...state,
            plan,
            interval: interval
          }}
          onClick={closeModal}
        >
          Choose plan
        </Button>
      </div>

      <div className={styles["feature"]}>
        <FeatureList planData={planData} />
      </div>

      <div className={styles["feature-mobile"]}>
        <FeatureList planData={planData} shortlist />
      </div>

      {plan === SublyPlan.BusinessYearly && (
        <div className={styles["business-feature"]}>
          <p className={styles["business-feature--desc"]}>
            Needing a custom set up with more users?{" "}
            <span className={styles["business-feature--link"]}>
              <a
                href={SALES_CONTACT}
                target="_blank"
                rel="noopener noreferrer"
                onClick={closeModal}
              >
                Contact us
              </a>
            </span>
          </p>
        </div>
      )}

      <div className="position-relative mt-auto">
        <div className={styles["bottom"]}>
          <span className={styles["title"]}>Includes:</span>
          <div className={styles["box"]}>
            <PlanLimits
              onModal={true}
              limits={planData.limits}
              isBusiness={isBusinessPlan(plan)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

interface FeatureListProps {
  planData: SublyPlanData;
  shortlist?: boolean;
}
const FeatureList: React.FC<FeatureListProps> = ({
  planData,
  shortlist = false
}) => {
  const { plan, features } = planData;
  let { items } = features;

  if (plan === SublyPlan.Pro100) {
    items = items.slice(0, 6);
  } else {
    items = items.slice(0, 5);
  }

  if (shortlist) {
    items.length = 3;
  }

  const renderFeatures = items.map((f) => {
    return <FeatureItem feature={f} key={f} />;
  });

  return (
    <ul className={styles["plan-list"]}>
      <p className={styles["plan-list-title"]}>{features.title}</p>
      {renderFeatures}
    </ul>
  );
};

interface FeatureItemProps {
  feature: string;
}
const FeatureItem: React.FC<FeatureItemProps> = ({ feature }) => {
  return (
    <li className={styles["item"]}>
      <CheckmarkIcon className={styles["icon"]} />
      {feature}
    </li>
  );
};
