import React from "react";

export const BackgroundOnboardingOne: React.FC<
  React.SVGProps<SVGSVGElement>
> = (props) => (
  <svg
    width="531"
    height="258"
    viewBox="0 0 531 258"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M207 192C127 162 1 232 1 270V294L563 284C563 218 571 60.0001 571 4.00014C441 -23.9999 499 156 389 136C279 116 301 227.25 207 192Z"
      fill="#F2F2F2"
      stroke="#F2F2F2"
    />
  </svg>
);

export const BackgroundOnboardingTwo: React.FC<
  React.SVGProps<SVGSVGElement>
> = (props) => (
  <svg
    width="436"
    height="530"
    viewBox="0 0 436 530"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M318 86C342.419 1.64517 436 68 436 -18H-64V526C114 554 34.7452 396.627 80 374C176 326 102 190 176 154C227.907 128.748 296 162 318 86Z"
      fill="#FFF7E6"
    />
  </svg>
);

export const BackgroundOnboardingThree: React.FC<
  React.SVGProps<SVGSVGElement>
> = (props) => (
  <svg
    width="488"
    height="368"
    viewBox="0 0 488 368"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M146.046 302.234C55.9452 284.92 -14.3032 335.806 2.49538 406L525.54 396.844L539.666 29.468C517.141 29.468 563.272 15.0202 525.54 3.14512C428.567 -27.3741 461.864 173.89 372.063 189.313C274.326 206.098 265.163 325.124 146.046 302.234Z"
      fill="#E2186F"
    />
  </svg>
);

export const BackgroundOnboardingFour: React.FC<
  React.SVGProps<SVGSVGElement>
> = (props) => (
  <svg
    width="450"
    height="288"
    viewBox="0 0 450 288"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M96.1307 106.422C19.552 97.4667 8.14667 40.4768 0 -10H487.985V287.162C455.399 292.046 431.773 270.065 413.851 254.596C395.928 239.127 353.565 247.269 342.975 246.455C332.384 245.64 276.987 248.897 257.435 188.65C237.883 128.404 172.709 115.378 96.1307 106.422Z"
      fill="#F2E5FF"
    />
  </svg>
);
