import React from "react";

import { ZoomMeetingCard } from "./zoom-meeting-card";
import { IZoomRecordingSummary } from "../../api/apps.service";

interface IZoomRecordingListProps {
  recordingSummary: IZoomRecordingSummary;
  selected: string[];
  onChange: (id: string) => void;
}
export const ZoomRecordingList: React.FC<IZoomRecordingListProps> = ({
  recordingSummary,
  selected,
  onChange
}) => {
  return (
    <div>
      {recordingSummary.items.map((recording) => (
        <ZoomMeetingCard
          key={recording.meetingId}
          recording={recording}
          selected={selected}
          onChange={onChange}
        />
      ))}
    </div>
  );
};
