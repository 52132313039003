import React from "react";
import { fetchPublicAssetUrl } from "../api/file.service";

export const useArtworkUrl = (url = "", isSublyArtwork = false): string => {
  const [src, setSrc] = React.useState<string>("");

  React.useEffect(() => {
    if (!isSublyArtwork) {
      setSrc(url);
      return;
    }

    const fetchUrl = async () => {
      const signedUrl = await fetchPublicAssetUrl(url);
      setSrc(signedUrl);
    };

    fetchUrl();
  }, [url, isSublyArtwork]);

  return src;
};
