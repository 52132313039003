import { mdiArrowLeft } from "@mdi/js";
import Icon from "@mdi/react";
import React from "react";
import { Link } from "react-router-dom";
import { EditAccountForm } from "../../../containers/settings/account-card/edit-account-form";
import { SettingsLayout } from "../../../layouts/settings/settings.layout";
import { ACCOUNT_PATH } from "../../../routes";

export const EditAccountPage: React.FC = () => {
  return (
    <SettingsLayout>
      <div className="container-fluid my-2">
        <div className="row">
          <div className="col mt-3 mb-2">
            <h4 className="title text-black d-flex align-items-center">
              <Link to={ACCOUNT_PATH}>
                <Icon
                  path={mdiArrowLeft}
                  size="25px"
                  color="black"
                  className="mr-3"
                />
              </Link>{" "}
              Change profile details
            </h4>
          </div>
        </div>
        <div className="row">
          <div className="col py-3">
            <EditAccountForm />
          </div>
        </div>
      </div>
    </SettingsLayout>
  );
};
