import { PartnerFlow } from "@getsubly/common";
import classNames from "classnames";
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import {
  verificationResend,
  verifyPartnerUser,
  VerifyPartnerUserParams
} from "../../../api/auth.service";
import { EN } from "../../../assets/i18n/en";
import sent from "../../../assets/images/partners/sent.svg";
import { Button } from "../../../components/button/button";
import { notificationError } from "../../../components/notification";
import { PasswordRules } from "../../../components/password-rules/password-rules";
import { AuthContext } from "../../../contexts/auth.context";
import { DASHBOARD_PATH } from "../../../routes";
import { passwordPattern } from "../../../utils/regex-patterns";
import { SignUpSuccess } from "./sign-up-partner";

export const VerificationPartner: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [codeExpired, setCodeExpired] = useState(false);
  const { saveAuthInfo } = useContext(AuthContext);
  const { id = "", code = "" } = useParams();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch
  } = useForm<VerifyPartnerUserParams>();

  const onSubmit = async (params: VerifyPartnerUserParams) => {
    params.id = id;
    params.code = code;

    try {
      setLoading(true);

      const { data } = await verifyPartnerUser(params);
      saveAuthInfo(data);

      setLoading(false);

      setSuccess(true);
    } catch (e) {
      if (e.response?.data?.code === "ExpiredCodeException") {
        setCodeExpired(true);
        return;
      }

      notificationError(EN.error.defaultMessage);
    } finally {
      setLoading(false);
    }
  };

  if (success) {
    return <Navigate to={DASHBOARD_PATH} />;
  }

  if (codeExpired) {
    return (
      <div className="auth-form">
        <CodeExpired id={id} />
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="auth-form">
      <h2 className="title">Choose your password to access Subly</h2>
      <div className="form-container">
        <div className="form-group">
          <label htmlFor="email">Password</label>

          <input
            placeholder="Input your password"
            className={classNames("form-control", {
              "is-invalid": errors.password
            })}
            type="password"
            {...register("password", {
              required: true,
              pattern: passwordPattern
            })}
          />
          <PasswordRules value={watch("password")} />
        </div>

        <Button
          primary
          className="btn-block mt-3"
          type="submit"
          loading={loading}
        >
          Complete Registration
        </Button>
      </div>
    </form>
  );
};

interface CodeExpiredProps {
  id: string;
}
const CodeExpired: React.FC<CodeExpiredProps> = ({ id }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleRequestNewCode = async () => {
    try {
      setLoading(true);
      await verificationResend(id);
      setSuccess(true);
    } catch (e) {
      notificationError(EN.error.defaultMessage);
    } finally {
      setLoading(false);
    }
  };

  if (success) {
    return <SignUpSuccess flow={PartnerFlow.Representative} />;
  }

  return (
    <div className="success">
      <img src={sent} alt="Sign up succesful" />
      <h2 className="m-v-0 p-t-15 text-center">Thanks for registering</h2>
      <h5 className="m-v-0 p-v-15 text-center">
        Your account verification has expired. Please click the button below to
        generate a new verification email.
      </h5>
      <Button
        className="btn btn-block"
        loading={loading}
        onClick={handleRequestNewCode}
      >
        Send a new verification link
      </Button>
    </div>
  );
};
