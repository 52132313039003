import { mdiHelpCircleOutline } from "@mdi/js";
import Icon from "@mdi/react";
import React from "react";

interface HelpIconProps {
  showHelp: () => void;
}

export const HelpIcon: React.FC<HelpIconProps> = ({ showHelp }) => {
  return (
    <span
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        showHelp();
      }}
    >
      <Icon
        path={mdiHelpCircleOutline}
        size="1.2em"
        color="var(--tertiary)"
        className="ml-2"
      />
    </span>
  );
};
