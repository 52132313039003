import { AccountType } from "../api/billing.service";
import settings from "../config/settings/settings";
import { UnknownObject } from "./types";

// Order of plans matter as it is used to infer downgrades and permissions
export enum SublyPlan {
  Free = "Subly Free",
  Pro100 = "Subly Pro",
  Pro100Yearly = "Subly Pro Yearly",
  Pro500 = "Subly Pro 500",
  Pro500Yearly = "Subly Pro 500 Yearly",
  Pro1000 = "Subly Pro 1000",
  Pro1000Yearly = "Subly Pro 1000 Yearly",
  Premium = "Subly Premium",
  PremiumYearly = "Subly Premium Yearly",
  PremiumEducation = "Subly Education Premium",
  Premium1000 = "Subly Premium 1000",
  Premium1000Yearly = "Subly Premium 1000 Yearly",
  PAYG = "Subly Pay As You Go",
  Business = "Subly Business",
  BusinessYearly = "Subly Business Yearly",
  BusinessYearlyCustom = "Subly Business Yearly Custom"
}

export enum PlanInterval {
  Monthly = "month",
  Yearly = "year"
}

export interface Product {
  id: string;
  priceId: string;
  name: SublyPlan;
  description: string;
  metadata: UnknownObject;
  tier: number;
  currency: string;
  amount: number;
  units?: number;
}

export interface BillingDetails {
  name: string;
  balance: number;
  address?: BillingAddress;
  accountType: AccountType;
  taxId: BillingTaxId;
}
export interface BillingTaxId {
  type: string;
  value: string;
  country: string;
}
export interface BillingAddress {
  line1: string;
  line2: string;
  city: string;
  postalCode: string;
  state: string;
  country: string;
}
export interface Invoice {
  id: string;
  amount_due: number;
  amount_paid: number;
  amount_remaining: number;
  attempt_count: number;
  attempted: boolean;
  auto_advance: string;
  billing_reason: string;
  collection_method: string;
  created: Date;
  currency: string;
  description: string;
  hosted_invoice_url: string;
  invoice_pdf: string;
  lines: InvoiceLine[];
  metadata: UnknownObject;
  number: string;
  paid: boolean;
  period_end: Date;
  period_start: Date;
  receipt_number: string;
  status: string;
  subtotal: number;
  tax: number;
  tax_percent: number;
  total: number;
}
export interface InvoiceLine {
  id: string;
  amount: number;
  currency: string;
  description: string;
  metadata: UnknownObject;
  quantity: number;
}

export enum SubscriptionStatus {
  Incomplete = "incomplete",
  IncompleteExpired = "incomplete_expired",
  Trialing = "trialing",
  Active = "active",
  PastDue = "past_due",
  Canceled = "canceled",
  Unpaid = "unpaid"
}

export interface Subscription {
  id: string;

  name: SublyPlan;

  /**
   * https://stripe.com/docs/billing/subscriptions/overview
   *
   * Possible values are `incomplete`, `incomplete_expired`, `trialing`, `active`, `past_due`, `canceled`, or `unpaid`.
   *
   * For `collection_method=charge_automatically` a subscription moves into `incomplete` if the initial payment attempt fails. A subscription in this state can only have metadata and default_source updated. Once the first invoice is paid, the subscription moves into an `active` state. If the first invoice is not paid within 23 hours, the subscription transitions to `incomplete_expired`. This is a terminal state, the open invoice will be voided and no further invoices will be generated.
   *
   * A subscription that is currently in a trial period is `trialing` and moves to `active` when the trial period is over.
   *
   * If subscription `collection_method=charge_automatically` it becomes `past_due` when payment to renew it fails and `canceled` or `unpaid` (depending on your subscriptions settings) when Stripe has exhausted all payment retry attempts.
   *
   * If subscription `collection_method=send_invoice` it becomes `past_due` when its invoice is not paid by the due date, and `canceled` or `unpaid` if it is still not paid by an additional deadline after that. Note that when a subscription has a status of `unpaid`, no subsequent invoices will be attempted (invoices will be created, but then immediately automatically closed). After receiving updated payment information from a customer, you may choose to reopen and pay their closed invoices.
   */
  status: SubscriptionStatus;

  startDate: Date;
  nextInvoiceDate: Date;
  trialEndDate?: Date;

  productId: string;
  tier: number;

  cancelAtEndPeriod: boolean;

  latestInvoice?: {
    id: string;
    currency: string;
    total: number;
  };

  error?: SubscriptionError;
  nextAction?: SubscriptionNextAction;

  schedule?: SubscriptionSchedule;
  extraItems?: Product[];
}

export interface SubscriptionPaymentError {
  id: string;
  status:
    | "canceled"
    | "processing"
    | "requires_action"
    | "requires_capture"
    | "requires_confirmation"
    | "requires_payment_method"
    | "succeeded";
  code: string;
  message: string;
}

export enum BillingErrorCode {
  AlreadySubscribed = "AlreadySubscribed",
  AlreadyOnTrial = "AlreadyOnTrial",
  AlreadyHadATrial = "AlreadyHadATrial",
  InvalidProduct = "InvalidProduct",
  InvalidPrice = "InvalidPrice",
  NoCardOnAccount = "NoCardOnAccount",
  MissingCard = "MissingCard",
  InvalidCoupon = "InvalidCoupon",
  CardDeclined = "CardDeclined",
  UnknownError = "UnknownError",
  CouponUsed = "CouponUsed",
  RequiresAction = "RequiresAction"
}

export interface SubscriptionNextAction {
  clientSecret: string;
  payment?: string;
}

export interface SubscriptionError {
  code: BillingErrorCode;
  reason?: string;
}

export interface CreateSubscriptionResponse {
  status: "success" | "requires_action" | "fail";

  nextAction?: SubscriptionNextAction;
  error?: SubscriptionError;
}

export interface UpdateSubscriptionResponse {
  status: "success" | "requires_action" | "fail";

  nextAction?: SubscriptionNextAction;
  error?: SubscriptionError;
}

export interface PurchaseResponse {
  status: "success" | "requires_action" | "fail";

  nextAction?: SubscriptionNextAction;
  error?: SubscriptionError;
}

// TODO: Fix this interface
export interface SubscriptionSchedule {
  phases: {
    start_date: number;
    plans: {
      price: string;
    }[];
  }[];
  current_phase: {
    end_date: number;
  };
}

export interface Deal {
  id: string;
  accountId: string;
  invoiceId: string;
  status: DealStatus;
  description: string;
  isPaid: boolean;
  isTrial: boolean;
  teamCapacity: number;
  creditSeconds: number;
  createdAt: Date;
  updatedAt: Date;
  startedAt?: Date;
  endingAt?: Date;
}

export enum DealStatus {
  Draft = "DRAFT",
  Active = "ACTIVE",
  Archived = "ARCHIVED",
  Void = "VOID",
  Deleted = "DELETED"
}

export interface Source {
  id: string;
  name: string;
  brand: string;
  last4: string;
  funding: string;
}
export interface PaymentMethod {
  id: string;
  last4: string;
  brand: string;
  exp_month: number | string;
  exp_year: number | string;
  default: boolean;
}
export interface BasicStripeCustomer {
  id: string;
  accountType: AccountType;
  details: BillingDetails;
  subscription?: Subscription;
  invoices: Invoice[];
  paymentMethods: PaymentMethod[];
  creditFreeSeconds?: number;
  creditPaidSeconds?: number;
  creditExtraSeconds?: number;
  creditPaygSeconds?: number;
  lastPlan?: string;
}

export interface TaxIdType {
  country: string;
  code: string;
  format: string;
  name: string;
}

//used in Select form controls
export interface TaxIdTypeSelectOption extends TaxIdType {
  label: string;
  value: string;
}

//taxIdType codes are not unique, neither are countries. The combination of these is unique
const getUniqueIdForTaxIdType = (taxIdType: TaxIdType | undefined) => {
  if (!taxIdType) {
    return "";
  }

  return taxIdType.code + "|" + taxIdType.country;
};

export const getTaxIdTypeSelectOptions = (): TaxIdTypeSelectOption[] => {
  //zero-width space ensures empty option has full height
  const taxIdTypeSelectOptions: TaxIdTypeSelectOption[] = [
    { label: "\u200B", value: "", code: "", name: "", format: "", country: "" }
  ].concat(
    settings.stripe.taxIdTypes.map((taxIdType) => {
      return {
        label: taxIdType.name,
        value: getUniqueIdForTaxIdType(taxIdType),
        code: taxIdType.code,
        format: taxIdType.format,
        country: taxIdType.country,
        name: taxIdType.name
      };
    })
  );

  return taxIdTypeSelectOptions.sort((a, b) => a.name.localeCompare(b.name));
};
