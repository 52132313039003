import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAnalytics } from "../contexts/analytics";

export const HubspotAnalytics: React.FC = () => {
  const { trackPage } = useAnalytics();
  const location = useLocation();

  useEffect(() => {
    trackPage();
  }, [location]);

  return null;
};
