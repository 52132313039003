import { UAParser } from "ua-parser-js";

export const isEmbeddedWebView = (): boolean => {
  const uaParser = new UAParser();

  return ["Facebook", "WebKit"].includes(uaParser.getBrowser().name || "");
};

export const isMacOS = (): boolean => {
  const uaParser = new UAParser();

  return ["Mac OS"].includes(uaParser.getOS().name || "");
};
