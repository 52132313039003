import classNames from "classnames";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { Button } from "../../components/button/button";
import { LOGIN_PATH, SIGN_UP_PATH } from "../../routes";
import "./navbar.scss";
interface AuthNavbarProps {
  showLogin?: boolean;
  showRight?: boolean;
  trialPage?: boolean;
}
export const AuthNavbar: React.FC<AuthNavbarProps> = ({
  showLogin,
  showRight = true,
  trialPage = false
}) => {
  if (trialPage) {
    return (
      <nav className="navbar navbar-expand navbar-dark bg-white top-navbar border-bottom-0 pt-2 pl-md-5">
        {showRight && (
          <AuthNavbarRight showLogin={showLogin} trialPage={trialPage} />
        )}
      </nav>
    );
  }
  return (
    <nav className="navbar navbar-expand navbar-dark bg-white top-navba border-bottom-0 py-3 px-md-5">
      <Link className="navbar-brand d-inline-flex align-items-center" to="/">
        <img
          src={logo}
          width="69.875"
          height="26"
          className="mr-1"
          alt="Subly"
          loading="lazy"
        />
      </Link>

      {showRight && <AuthNavbarRight showLogin={showLogin} />}
    </nav>
  );
};

const AuthNavbarRight: React.FC<AuthNavbarProps> = ({
  showLogin,
  trialPage = false
}) => {
  const { search } = useLocation();

  if (trialPage) {
    return (
      <div className="collapse navbar-collapse">
        <ul
          className={classNames(
            "navbar-nav ml-auto d-flex align-items-center",
            "signup"
          )}
        >
          <li className="nav-item d-none d-sm-inline">
            Already have an account?
          </li>
          <li className="nav-item">
            <Button to={{ pathname: LOGIN_PATH, search }} className="px-2">
              Sign in
            </Button>
          </li>
        </ul>
      </div>
    );
  }

  if (showLogin) {
    return (
      <div className="collapse navbar-collapse">
        <ul className="navbar-nav ml-auto d-flex align-items-center">
          <NavHelper>Already have an account?</NavHelper>
          <li className="nav-item">
            <Button border to={{ pathname: LOGIN_PATH, search }}>
              Log in
            </Button>
          </li>
        </ul>
      </div>
    );
  }

  return (
    <div className="collapse navbar-collapse">
      <ul className="navbar-nav ml-auto d-flex align-items-center">
        <NavHelper>Don't have an account?</NavHelper>
        <li className="nav-item">
          <Button border to={{ pathname: SIGN_UP_PATH, search }}>
            Sign up
          </Button>
        </li>
      </ul>
    </div>
  );
};

const NavHelper: React.FC = ({ children }) => {
  return <li className="nav-item mr-4 d-none d-sm-inline">{children}</li>;
};
