import classNames from "classnames";
import React from "react";
import { Modal } from "../../containers/modal/modal";
import { App } from "../../interfaces/app";
import { PremiumBadge } from "../badge/premium-badge";
import { Button } from "../button/button";
import { Divider } from "../divider/divider";
import { ZoomIcon } from "../icons/icons";

import styles from "./app-modal.module.scss";

interface AppModalProps {
  app: App;
  closeModal: () => void;
}
export const AppModal: React.FC<AppModalProps> = ({ app, closeModal }) => {
  return (
    <Modal onDismiss={closeModal} className={styles["modal"]}>
      <AppModalContainer app={app} closeModal={closeModal} />
    </Modal>
  );
};

export const AppModalContainer: React.FC<AppModalProps> = ({
  app,
  closeModal
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <ZoomIcon className={styles.icon} />
        <h5 className={classNames(styles.title, "ml-4 mb-0")}>
          {app.modal.title}
        </h5>
        <PremiumBadge hideLink className={styles["badges"]} />
      </div>
      <Divider className={styles.divider} />
      <p className={styles.description}>{app.modal.description}</p>
      <img
        src={app.modal.screenshot}
        className={styles.screenshot}
        alt={"screenshot"}
        loading="lazy"
      />
      <div className="d-flex justify-content-end w-100 mt-4">
        <Button
          secondary
          onClick={closeModal}
          className={classNames("mr-3", styles.button)}
        >
          Cancel
        </Button>
        <Button
          primary
          className={styles.button}
          onClick={() => {
            window.location.replace(app.modal.link);
          }}
        >
          Connect {app.name} app
        </Button>
      </div>
    </div>
  );
};
