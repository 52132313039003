import axios from "axios";
import { getAccessToken } from "../../api/auth.service";
import { handleError } from "../../api/handle-error";
import config from "../../config";
import { getAccountId } from "../../config/settings/settings.service";
import { accountStore } from "../account/account.store";
import { mediaQuery, mediaStore } from "../media";
import { foldersQuery } from "./folders.query";
import { Folder, foldersStore } from "./folders.store";

const baseURL = `${config.apiUrl}/api/v1`;

interface CreateFolderResponse {
  folder: Folder;
}
export const createFolder = async (isPublic?: boolean): Promise<void> => {
  try {
    const folder = { name: "Untitled folder", isPublic };

    // 1. Create and return folder;
    const {
      data: { folder: newFolder }
    } = await axios.post<CreateFolderResponse>(
      `/${getAccountId()}/folders`,
      folder,
      {
        baseURL,
        headers: { "x-access-token": await getAccessToken() }
      }
    );

    // 2. Update store with newFolder, and set isEditing
    foldersStore.add(newFolder);
    foldersStore.updateEditing(newFolder.id, true);

    // 3. Set new folder active
    mediaStore.update({ activeFolderId: newFolder.id });
    foldersStore.setActive(newFolder.id);
  } catch (error) {
    handleError(error);
  }
};

export const updateFolder = async (folder: Folder): Promise<void> => {
  try {
    foldersStore.ui.update(folder.id, { isLoading: true });

    // 1. Update folder;
    const {
      data: { folder: updatedFolder }
    } = await axios.put<CreateFolderResponse>(
      `/${getAccountId()}/folders/${folder.id}`,
      folder,
      {
        baseURL,
        headers: { "x-access-token": await getAccessToken() }
      }
    );

    // 2. Update store with updatedFolder
    foldersStore.update(updatedFolder.id, folder);
    foldersStore.updateEditing(updatedFolder.id, false);
    foldersStore.ui.update(folder.id, { isLoading: false });
  } catch (error) {
    handleError(error);
  }
};

export const deleteFolder = async (folderId: string): Promise<void> => {
  try {
    const folder = foldersQuery.getEntity(folderId);

    const media = mediaQuery.getAll({
      filterBy: (x) => x.folderId === folderId
    });

    // 1. Delete folder;
    await axios.delete(`/${getAccountId()}/folders/${folderId}`, {
      baseURL,
      headers: { "x-access-token": await getAccessToken() }
    });

    // 2. Delete folder from the folders store
    foldersStore.remove(folderId);
    mediaStore.update({ activeFolderId: null });

    media.map((x) => {
      mediaStore.remove(x.mediaId);

      !folder?.isPublic && accountStore.decrementMediaCount();
    });
  } catch (error) {
    handleError(error);
  }
};
