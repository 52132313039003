import React from "react";
import { mdiPencilOutline } from "@mdi/js";
import Icon from "@mdi/react";
import classNames from "classnames";
import numeral from "numeral";
import { Button } from "../../../../components/button/button";
import { StopwatchIcon } from "../../../../components/icons/icons";
import { usePlan } from "../../../../hooks/use-plan";
import Skeleton from "react-loading-skeleton";
import { parseCreditToText, PAYG_PRICE } from "../../../../utils/plans";
import styles from "../plans.module.scss";
import { Input } from "../../../../components/controls/input";

interface PayAsYouGoUnitCardProps {
  loading: boolean;
  quantity?: number;
  setQuantity: React.Dispatch<React.SetStateAction<number | undefined>>;
  isReady: boolean;
  setReady: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PayAsYouGoUnitCard: React.FC<PayAsYouGoUnitCardProps> = ({
  loading,
  quantity,
  setQuantity,
  isReady,
  setReady
}) => {
  const { credit } = usePlan();
  const paygCredit = (credit?.payg || 0) + (credit?.extra || 0);
  const totalCredit = paygCredit + (quantity || 0) * 60;

  if (loading) {
    return (
      <div className={styles.card}>
        <h5>1. Select minutes</h5>
        <div className="mt-4">
          <div className="d-flex flex-column">
            <Skeleton width="100%" className="mb-2" />
            <Skeleton width="90%" />
          </div>
          <div className="d-flex flex-column mt-4">
            <Skeleton width="100%" className="mb-2" />
            <Skeleton width="90%" />
          </div>
        </div>
      </div>
    );
  }

  if (isReady && quantity) {
    return (
      <div className={styles.card}>
        <div className="d-flex align-items-center justify-content-between mb-4">
          <h5 className="mb-0">1. Select minutes</h5>
          <span
            className={styles["edit-icon"]}
            onClick={() => {
              setReady(false);
            }}
          >
            <Icon path={mdiPencilOutline} size="1.2rem" />
          </span>
        </div>

        <div className="d-flex align-items-baseline">
          <h3 className="m-0">
            ${numeral((quantity ? quantity : 0) * PAYG_PRICE).format("0.00")}
          </h3>
          <p className="m-0">/ {quantity ? quantity : 0} mins</p>
        </div>

        <div className="d-flex">
          <p className={classNames("small m-0", styles["payg-balance"])}>
            Balance after top up:{" "}
          </p>
          <p className={classNames("small", styles["payg-balance-value"])}>
            {parseCreditToText(totalCredit)}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className={classNames(styles["card"], styles["card-editing"])}>
      <div className="d-flex align-items-center justify-content-between">
        <h5 className="">1. Select minutes</h5>
      </div>
      <div className="d-flex align-items-center position-relative mt-4">
        <StopwatchIcon className={styles.stopwatchIcon} />
        <Input
          type="number"
          placeholder="Enter minutes"
          value={quantity ?? ""}
          min={0}
          max={100000}
          onChange={({ target }) => {
            setQuantity(target.value ? +target.value : undefined);
          }}
          className={styles["payg-input"]}
        />
      </div>
      <label className={classNames("small", styles["payg-label"])}>
        $0.60 per minute (20 minutes minimum)
      </label>

      <div className="d-flex">
        <p className={classNames("small m-0", styles["payg-balance"])}>
          Current balance:{" "}
        </p>
        <p className={classNames("small m-0", styles["payg-balance-value"])}>
          {parseCreditToText(paygCredit)}
        </p>
      </div>
      <div className="d-flex">
        <p className={classNames("small m-0", styles["payg-balance"])}>
          Balance after top up:{" "}
        </p>
        <p className={classNames("small", styles["payg-balance-value"])}>
          {parseCreditToText(totalCredit)}
        </p>
      </div>

      <div className="d-flex align-items-baseline">
        <h3 className="m-0">
          ${numeral((quantity ?? 0) * PAYG_PRICE).format("0.00")}
        </h3>
        <p className="m-0">/ {quantity} mins</p>
      </div>
      <Button
        primary
        isFull
        disabled={!quantity || quantity < 20}
        className={styles.continue}
        onClick={() => setReady(true)}
        type="submit"
      >
        Continue
      </Button>
    </div>
  );
};
