import classNames from "classnames";
import React, { useContext, useEffect } from "react";
import { UploadMediaButton } from "../../../components/button/upload-media-button";
import { MediaCard } from "../../../components/media-card/media-card";
import { UploadMediaCard } from "../../../components/media-card/upload-media-card";
import { UploadCard } from "../../../components/upload-card/upload-card";
import { NavContext } from "../../../contexts/nav-context";
import { useSelectedFiles } from "../../../contexts/selected-files.context";
import { useFolders } from "../../../hooks/use-folders";
import { useUpload } from "../../../hooks/use-upload";
import { BasicMedia } from "../../../interfaces/media";
import { DefaultFoldersId } from "../../../state/folders/folders.store";
import { UploadFile } from "../../../state/upload";
import { DashboardFileActionToolBar } from "../file-action-toolbar/file-action-toolbar";
import styles from "./media-list.module.scss";

interface MediaListProps {
  mediaList: BasicMedia[];
  isUploading: boolean;
}
export const MediaList: React.FC<MediaListProps> = ({
  mediaList,
  isUploading
}) => {
  const { uploadFolderId, progress, queue } = useUpload();
  const { selectedId } = useFolders();
  const { selectedFiles, setSelectedFiles } = useSelectedFiles();
  const { showSidebar } = useContext(NavContext);

  const renderMedia = mediaList.map((m) => (
    <div className="col-sm-6 col-md-4 col-xl-3" key={m.mediaId}>
      <MediaCard media={m} />
    </div>
  ));

  const showUploadingCards =
    isUploading && (selectedId === uploadFolderId || selectedId === null);

  const showUploadCard = selectedId !== DefaultFoldersId.AllSharedWithMe;

  const uploadingQueue = queue.map((m) => (
    <div className="col-sm-6 col-md-4 col-xl-3" key={m.id}>
      <UploadMediaCard
        progress={progress}
        isUploading={m.isUploading}
        uploaded={m.uploaded}
        mediaName={m.mediaName}
        isIndeterminate={Boolean((m as UploadFile)?.isGoogleDrive)}
      />
    </div>
  ));

  const handleDeselectAllTheFiles = () => {
    setSelectedFiles([]);
  };

  // Move Zendesk widget up when file-action-toolbar is visible
  useEffect(() => {
    const zE = (window as any)?.zE; // eslint-disable-line @typescript-eslint/no-explicit-any

    if (!zE) {
      return;
    }

    if (selectedFiles.length > 0) {
      zE("webWidget", "updateSettings", {
        webWidget: {
          offset: {
            horizontal: "0px",
            vertical: "70px",
            mobile: {
              horizontal: "0px",
              vertical: "70px"
            }
          }
        }
      });
    } else {
      zE("webWidget", "updateSettings", {
        webWidget: {
          offset: {
            horizontal: "0px",
            vertical: "0px",
            mobile: {
              horizontal: "0px",
              vertical: "0px"
            }
          }
        }
      });
    }
  }, [selectedFiles]);

  return (
    <div className="container-fluid px-0">
      <div className="row">
        {showUploadCard && <UploadCard />}
        {showUploadingCards && <>{uploadingQueue}</>}
        {renderMedia}
        <UploadMediaButton
          className={classNames(styles["upload-media-button"], {
            [styles["upload-media-button--hide"]]: showSidebar
          })}
        />

        <DashboardFileActionToolBar
          selectedFiles={selectedFiles}
          handleDeselectAllTheFiles={() => handleDeselectAllTheFiles()}
        />
      </div>
    </div>
  );
};
