import React from "react";
import { SignInForm } from "../containers/auth/sign-in-form";
import { AuthLayout } from "../layouts/auth.layout";

export const LoginPage: React.FC = () => {
  return (
    <AuthLayout>
      <SignInForm />
    </AuthLayout>
  );
};
