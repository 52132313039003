import classNames from "classnames";
import React from "react";
import { TutorialMedia } from "../../containers/settings/video-tutorials/tutorial-media";
import { ThumbnailImage } from "../thumbnail-image/thumbnail-image";
import styles from "./media-card.module.scss";

interface TutorialMediaCardProps {
  tutorialVideo: TutorialMedia;
}

export const TutorialMediaCard: React.FC<TutorialMediaCardProps> = ({
  tutorialVideo
}) => {
  return (
    <div
      className={classNames(styles["media-card"], {
        [styles["is-ready"]]: tutorialVideo
      })}
    >
      <figure className={classNames(styles["figure"], "m-0")}>
        <ThumbnailImage src={tutorialVideo?.thumbnail} alt={"no image"} />
      </figure>
      <div className="p-3">
        <span className="caption d-block p-0">{tutorialVideo?.name}</span>
      </div>
    </div>
  );
};
