import Cookies from "universal-cookie";

const cookies = new Cookies();

interface Rewardful {
  referral?: string;
  clearRewardful: () => void;
}

export const useRewardful = (): Rewardful => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const rewardful = (window as any)?.Rewardful;

  const referral = rewardful?.referral;

  if (referral) {
    sessionStorage.setItem("rewardful", referral);
  }

  const clearRewardful = (): void => {
    cookies.remove("rewardful.referral", { path: "/" });
    sessionStorage.removeItem("rewardful");
  };

  return {
    referral: referral ?? sessionStorage.getItem("rewardful"),
    clearRewardful
  };
};
