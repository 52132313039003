import React from "react";
import { useLocation } from "react-router-dom";
import { Badge } from "../../../components/badge/badge";
import config from "../../../config";
import { useAccounts } from "../../../hooks/use-accounts";
import { usePermissions } from "../../../hooks/use-permissions";
import { usePlan } from "../../../hooks/use-plan";
import { SublyPlan } from "../../../interfaces/billing";
import {
  ACCOUNT_PATH,
  APPS_HOME_PATH,
  BILLING_PATH,
  FAQ_URL,
  HELP_PATH,
  PLANS_PATH,
  REFERRAL_PATH,
  TEAM_PATH
} from "../../../routes";
import { SidebarContainer } from "./sidebar-container";
import { SidebarItem } from "./sidebar-items";

export const SettingsSidebar: React.FC = () => {
  const location = useLocation();
  const { plan } = usePlan();
  const { hasPermission } = usePermissions(SublyPlan.Premium);
  const { isAdmin, deal, user } = useAccounts();

  const enableZoomForUser = user?.id === "043a034d-30d9-43b9-af5a-c4acbab3b122";

  return (
    <SidebarContainer isSettings>
      <SidebarItem>
        <h5 className="font-weight-normal">Settings</h5>
      </SidebarItem>
      <SidebarItem
        to={ACCOUNT_PATH}
        isActive={isNavLinkActive(location, ACCOUNT_PATH)}
      >
        Account
      </SidebarItem>
      {isAdmin && (
        <SidebarItem
          to={BILLING_PATH}
          isActive={isNavLinkActive(location, BILLING_PATH)}
        >
          Billing
        </SidebarItem>
      )}
      {!Boolean(deal?.id) && (
        <SidebarItem
          to={{
            pathname: PLANS_PATH
          }}
          state={{
            plan
          }}
          isActive={isNavLinkActive(location, PLANS_PATH)}
        >
          Explore plans
        </SidebarItem>
      )}
      <SidebarItem
        to={TEAM_PATH}
        isActive={isNavLinkActive(location, TEAM_PATH)}
      >
        Manage teammates {!hasPermission && <Badge>Premium</Badge>}
      </SidebarItem>
      {config.features.hasReferral && (
        <SidebarItem
          to={REFERRAL_PATH}
          isActive={isNavLinkActive(location, REFERRAL_PATH)}
        >
          Refer a friend
        </SidebarItem>
      )}

      {(config.features.hasApps || enableZoomForUser) && (
        <SidebarItem
          to={APPS_HOME_PATH}
          isActive={isNavLinkActive(location, APPS_HOME_PATH)}
        >
          Apps
        </SidebarItem>
      )}
      <SidebarItem
        to={FAQ_URL}
        isActive={isNavLinkActive(location, FAQ_URL)}
        blank
      >
        FAQ
      </SidebarItem>
      <SidebarItem
        to={HELP_PATH}
        isActive={isNavLinkActive(location, HELP_PATH)}
      >
        Help
      </SidebarItem>
    </SidebarContainer>
  );
};

type Location = {
  pathname: string;
};
const isNavLinkActive = (location: Location, path: string): boolean => {
  const pattern = new RegExp(path);

  return pattern.test(location?.pathname);
};
