import React from "react";
import classNames from "classnames";

interface LoadingIconProps {
  loading?: boolean;
  primary?: boolean;
  secondary?: boolean;
  className?: string;
}
export const LoadingIcon: React.FC<LoadingIconProps> = ({
  loading = true,
  primary,
  secondary,
  className
}) => {
  if (!loading) {
    return null;
  }

  return (
    <span
      className={classNames(
        "spinner-border spinner-border-sm mr-2",
        className,
        {
          "text-primary": secondary,
          "text-white": primary
        }
      )}
    ></span>
  );
};
