import { mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import React from "react";
import { UploadModal } from "../../containers/dashboard/upload-modal/upload-modal";
import { useAnalyticsWithAuth } from "../../hooks/use-analytics-with-auth";
import { useModal } from "../../hooks/use-modal";
import { usePermissions } from "../../hooks/use-permissions";
import { useUpload } from "../../hooks/use-upload";
import { SublyPlan } from "../../interfaces/billing";
import { ModalType } from "../../interfaces/modal-type";
import { uploadQuery, uploadStore } from "../../state/upload";
import { LockFeature } from "../lock-feature/lock-feature";
import { Button } from "./button";

interface UploadMediaButtonProps {
  allowDragAndDrop?: boolean;
  className?: string;
}

export const UploadMediaButton: React.FC<UploadMediaButtonProps> = ({
  allowDragAndDrop,
  className
}) => {
  const [showModal, hideModal] = useModal(ModalType.Upload);
  const { trackEventWithAuth } = useAnalyticsWithAuth();
  const { upload } = useUpload();
  const isUploading = Boolean(upload?.isUploading);
  const { hasPermission } = usePermissions(SublyPlan.Pro100);

  const handleCloseModal = () => {
    hideModal();
    if (!uploadQuery.getValue().isUploading) {
      uploadStore.resetUpload();
    }
  };

  const showUploadModal = () => {
    showModal(<UploadModal closeModal={handleCloseModal} />);
  };

  const handleShowUpload = () => {
    if (isUploading) {
      return;
    }
    showUploadModal();
    trackEventWithAuth("Launch upload screen");
  };

  return (
    <LockFeature>
      <Button
        primary
        className={className}
        onClick={handleShowUpload}
        loading={isUploading}
        disabled={!hasPermission}
      >
        <Icon path={mdiPlus} className="mr-1" size="1.6em" />
        {isUploading ? "Uploading..." : "Upload"}
      </Button>
      {allowDragAndDrop && "or"}
      <br />
      {allowDragAndDrop && "Drag and drop files here"}
    </LockFeature>
  );
};
