import React from "react";
import emptyStorageImg from "../../assets/images/empty-storage.png";
import lowStorageImg from "../../assets/images/low-storage.png";
import { Modal } from "../../containers/modal/modal";
import { useAccounts } from "../../hooks/use-accounts";
import { usePlan } from "../../hooks/use-plan";
import { accountQuery } from "../../state/account";
import { STORAGE_AMOUNT } from "../../utils/plans";
import { formatBytes, getStorageInfo } from "../../utils/storage-size";
import { Button } from "../button/button";
import {
  CheckoutSuccessCard,
  StorageCheckoutCard
} from "../extra-storage/storage-checkout-card";
import { StorageAmountToggle } from "../extra-storage/storage-toggle";
import { LoadingIcon } from "../loading-icon/loading-icon";
import styles from "./low-storage-modal.module.scss";

interface LowStorageModalProps {
  closeModal: () => void;
}

export const LowStorageModal: React.FC<LowStorageModalProps> = ({
  closeModal
}) => {
  return (
    <Modal onDismiss={closeModal} hasCloseIcon className={styles["modal"]}>
      <LowStorageContainer closeModal={closeModal} />
    </Modal>
  );
};
export const LowStorageContainer: React.FC<LowStorageModalProps> = ({
  closeModal
}) => {
  const { totalStorageLabel, plan, availableStorage } = usePlan();
  const { subscription, currentAccount, isLoading } = useAccounts();
  const { usagePercentage } = getStorageInfo(plan);
  const [showStoragePurchase, setShowStoragePurchase] =
    React.useState<boolean>(false);
  const [storageAmount, setStorageAmount] = React.useState<number>(0);
  const [showSuccess, setSuccess] = React.useState<boolean>(false);

  const totalStoragePurchase = STORAGE_AMOUNT * storageAmount;

  if (!subscription) {
    return null;
  }

  const image = usagePercentage >= 99 ? emptyStorageImg : lowStorageImg;

  const extraStorage = formatBytes(currentAccount?.additionalStorageBytes ?? 0);
  const extraStorageText = `${extraStorage.size} ${extraStorage.units}`;

  const availableStorageBytes = formatBytes(availableStorage);
  const availableStorageText = `${availableStorageBytes.size} ${availableStorageBytes.units}`;

  const totalStorage = formatBytes(accountQuery.totalStorage);
  const totalStorageText = `${totalStorage.size} ${totalStorage.units}`;

  if (showSuccess) {
    return (
      <>
        <div className={styles["storage-info"]}>
          <p className={styles["balance"]}>
            <span>Storage available:</span>{" "}
            {isLoading ? (
              <LoadingIcon />
            ) : (
              <span>
                {availableStorageText} / {totalStorageText}{" "}
              </span>
            )}
          </p>
          <p className={styles["balance"]}>
            <span>Extra storage purchased:</span>{" "}
            {isLoading ? <LoadingIcon /> : <span>{extraStorageText}</span>}
          </p>
        </div>

        <CheckoutSuccessCard loading={isLoading} />
      </>
    );
  }

  if (showStoragePurchase) {
    return (
      <>
        <h5 className="mb-1">Buy extra storage</h5>

        <div className={styles["storage-info"]}>
          <p className={styles["balance"]}>
            <span>Storage available:</span>{" "}
            <span>
              {availableStorageText} / {totalStorageText}
            </span>
          </p>
          <p className={styles["balance"]}>
            <span>Extra storage purchased:</span>{" "}
            <span>{extraStorageText}</span>
          </p>
        </div>

        <div className="mb-2">
          <StorageAmountToggle
            amount={storageAmount}
            setAmount={setStorageAmount}
            totalAmount={totalStoragePurchase}
            isModal={true}
          />
        </div>

        {storageAmount > 0 && (
          <StorageCheckoutCard
            subscription={subscription}
            quantity={storageAmount}
            isModal={true}
            setSuccess={setSuccess}
          />
        )}

        <div className={styles["cta-buttons"]}>
          <Button
            secondary
            onClick={() => {
              closeModal();
            }}
          >
            Cancel
          </Button>
        </div>
      </>
    );
  }

  return (
    <>
      <h5 className="mb-1">
        {usagePercentage >= 100
          ? "You've run out of storage"
          : "You’re running out of storage"}
      </h5>

      <div className={styles["content"]}>
        {usagePercentage >= 100 ? (
          <div className="d-flex flex-column">
            <p>
              You have used up {totalStorageLabel} of storage included in your{" "}
              {plan} plan. You can no longer transcribe/translate new files.{" "}
            </p>
            <br />
            <p>
              Delete old files to free up space, or get extra storage from only
              $4.99 per 50GB.
            </p>
          </div>
        ) : (
          <div className="d-flex flex-column">
            <p className="pb-2">
              <span>
                {availableStorageText} of {totalStorageText} used
              </span>
            </p>
            <p>
              You’ve used up over 80% of your {totalStorageLabel} storage plan.
              Delete some files or get extra storage now to upload more content.
            </p>
          </div>
        )}
        <img src={image} alt="Low Storage" loading="lazy" />
      </div>

      <div className={styles["cta-buttons"]}>
        <Button
          secondary
          onClick={() => {
            closeModal();
          }}
        >
          Maybe later
        </Button>

        <Button
          primary
          onClick={() => {
            setShowStoragePurchase(true);
          }}
        >
          Get extra storage
        </Button>
      </div>
    </>
  );
};
