import classNames from "classnames";
import React, { useState } from "react";
import { uploadFiles } from "../../../api/media.service";
import { Button } from "../../../components/button/button";
import { Fieldset } from "../../../components/form-controls/fieldset";
import { InsufficientCreditAlert } from "../../../components/upload-modal/insufficient-credit-alert";
import { UpgradeStorage } from "../../../components/upload-modal/upgrade-storage";
import { UploadModalFileSection } from "../../../components/upload-modal/upload-modal-file-selection";
import settings from "../../../config/settings/settings";
import {
  setUserSettings,
  userLanguage
} from "../../../config/settings/settings.service";
import { useAccounts } from "../../../hooks/use-accounts";
import { useAnalyticsWithAuth } from "../../../hooks/use-analytics-with-auth";
import { useFolders } from "../../../hooks/use-folders";
import { useMediaInfo } from "../../../hooks/use-media-info";
import { usePermissions } from "../../../hooks/use-permissions";
import { usePlan } from "../../../hooks/use-plan";
import { useUpload } from "../../../hooks/use-upload";
import { SublyPlan } from "../../../interfaces/billing";
import { uploadQuery, uploadStore } from "../../../state/upload";
import { parseCreditToText } from "../../../utils/plans";
import { Modal } from "../../modal/modal";
import { AdvancedOptions } from "./advanced-options";
import { FilesList } from "./files-list";
import { ProjectLocationDropdown } from "./project-location-dropdown";
import styles from "./upload-modal.module.scss";
import { UrlUploader } from "./url-uploader";

interface UploadModalProps {
  initialFiles?: File[];
  closeModal: () => void;
}

export const UploadModal: React.FC<UploadModalProps> = ({
  initialFiles = [],
  closeModal
}) => {
  return (
    <Modal
      onDismiss={closeModal}
      className={styles["modal"]}
      hasCloseIcon
      closeIconClassName={styles.close}
    >
      <UploadModalContainer
        initialFiles={initialFiles}
        closeModal={closeModal}
      />
    </Modal>
  );
};

// Separated the container of the modal to a new component, so it
// doesn't rerender the whole Modal when things change
const UploadModalContainer: React.FC<UploadModalProps> = ({
  initialFiles = [],
  closeModal
}) => {
  const [selectedLanguage, setSelectedLanguage] = useState<string>(
    userLanguage()
  );
  const [languageError, setLanguageError] = useState<boolean>();
  const { plan, isProOrHigher, isTrial } = usePlan();
  const { trackEventWithAuth } = useAnalyticsWithAuth();
  const { loading } = useMediaInfo();
  const { activeFolder } = useFolders();
  const [folder, setFolder] = useState(activeFolder);
  const { availableSeconds, availableStorage } = usePlan();
  const { isLoading, subscription, deal } = useAccounts();
  const { hasPermission } = usePermissions(SublyPlan.Pro100);
  const [skipTranscription, setSkipTranscription] = useState<boolean>(false);

  const { languages } = settings.transcription;

  const { queue, totalDuration, totalSize, isChecking, uploadList } =
    useUpload();
  const totalDurationLabel = parseCreditToText(
    totalDuration,
    false,
    false,
    true
  );

  const isOnPlan = subscription && !(isTrial || deal);
  const minutesLeft = parseCreditToText(availableSeconds, false, false);
  const hasInsufficientCredit = totalDuration > availableSeconds;
  const hasInsufficientStorage = totalSize > availableStorage;

  const renderLanguages = languages.map((l) => (
    <option value={l.code} key={l.code}>
      {l.language}
    </option>
  ));

  const handleSelectedLanguage = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const language = e.target.value;

    setSelectedLanguage(language);
    setUserSettings({ language });
  };

  const handleUpload = async () => {
    if (!hasPermission) {
      return;
    }

    if (!selectedLanguage) {
      setLanguageError(true);
      return;
    }

    uploadStore.removeErrorFiles();

    if (!queue) {
      return;
    }

    uploadStore.update({
      language: selectedLanguage,
      folderId: folder?.id
    });

    // Do not put "await" before function because the modal would stay opened
    // noinspection ES6MissingAwait
    uploadFiles(plan, isProOrHigher, trackEventWithAuth, skipTranscription);
    closeModal();
  };

  const disableUpload =
    !hasPermission ||
    uploadQuery.upload.queue.filter((f) => !f.error).length === 0 ||
    loading ||
    hasInsufficientCredit ||
    hasInsufficientStorage;

  const languageErrorVisible = languageError && !selectedLanguage;

  return (
    <>
      <h5 className="mb-2">Upload video or audio</h5>

      <div
        className={classNames({
          [styles["checkout-container"]]: hasInsufficientStorage && isOnPlan
        })}
      >
        <div className={classNames(styles["upload"], "pt-2")}>
          {/* File upload area */}
          <div className={classNames(styles.totals)}>
            <label className="small">
              Available credit: <b>{minutesLeft}</b>
            </label>
            <label className="small ml-2">
              Selected files: <b>{totalDurationLabel}</b>
            </label>
          </div>
          <UploadModalFileSection initialFiles={initialFiles} />
          <InsufficientCreditAlert
            creditRequired={totalDuration - availableSeconds}
            files={uploadList.length}
            hasInsufficientCredit={hasInsufficientCredit}
            onUpgrade={closeModal}
          />
          <UrlUploader className="row mt-2" />
          <FilesList
            files={uploadList}
            isChecking={isChecking}
            removeFile={(id) => uploadStore.removeFile(id)}
          />

          {/* Upload form information area */}
          <div className={classNames("mt-4", styles["form"])}>
            <div className="row">
              <Fieldset className="col-6" title="Save file to this folder">
                <ProjectLocationDropdown
                  setFolder={setFolder}
                  folder={folder}
                />
              </Fieldset>
              <Fieldset
                className="col-6"
                title="What language is being spoken?"
              >
                <select
                  value={selectedLanguage}
                  onChange={handleSelectedLanguage}
                  disabled={skipTranscription}
                  className={classNames("form-control custom-select", {
                    [styles["languageSelector--error"]]: languageErrorVisible
                  })}
                >
                  <option disabled></option>
                  {renderLanguages}
                </select>
              </Fieldset>
              {languageErrorVisible && (
                <p className={styles.errorMessage}>Please pick a language</p>
              )}
            </div>
            <AdvancedOptions
              setSkipTranscription={setSkipTranscription}
              skipTranscription={skipTranscription}
            />
          </div>

          <div className="mt-3 d-flex justify-content-end">
            <Button
              primary
              disabled={disableUpload}
              className={classNames("mb-sm-0 upload-btn", styles["upload-btn"])}
              onClick={handleUpload}
            >
              {skipTranscription
                ? "Upload without transcribing"
                : "Upload and transcribe"}
            </Button>
          </div>
        </div>
        {hasInsufficientStorage && isOnPlan && !isLoading && <UpgradeStorage />}
      </div>
    </>
  );
};
