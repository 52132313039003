import React, { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { SecureRoute } from "./components/secure-route";
import config from "./config";
import {
  SignUpPartnerPage,
  VerifyPartnerPage
} from "./containers/auth/partners/sign-up-partner-page";
import { Page404 } from "./pages/404";
import { AppsHomePage } from "./pages/apps/apps-home-page";
import { AppsPage } from "./pages/apps/apps-page";
import { ZoomProcessPage } from "./pages/callbacks/zoom-process";
import { DashboardPage } from "./pages/dashboard-page/dashboard-page";
import { ForgotPasswordPage } from "./pages/forgot-password";
import { LoginPage } from "./pages/login";
import { OauthPage } from "./pages/oauth";
import { OauthProcessPage } from "./pages/oauth-process";
import { OnboardingPage } from "./pages/onboarding/onboarding";
import { OnboardingTrialPage } from "./pages/onboarding/onboarding-trial";
import { PreviewVideoPage } from "./pages/preview-video-page/preview-video-page";
import { PromoAuthPage } from "./pages/promo-auth-page";
import { ResetPasswordPage } from "./pages/reset-password";
import { AccountPage } from "./pages/settings/account/account-page";
import { EditAccountPage } from "./pages/settings/account/edit-account-page";
import { PlansPage } from "./pages/settings/account/plans-page";
import { PlansUpgradePage } from "./pages/settings/account/plans-upgrade-page";
import { AddBillingDetailsPage } from "./pages/settings/billing/add-billing-details.page";
import { AddPaymentMethodPage } from "./pages/settings/billing/add-payment-method-page";
import { BillingPage } from "./pages/settings/billing/billing-page";
import { InvoicesPage } from "./pages/settings/billing/invoices-page";
import { HelpPage } from "./pages/settings/help-page";
import { ReferralPage } from "./pages/settings/referral/referral-page";
import { TeamPage } from "./pages/settings/team-page";
import { SignUpPage } from "./pages/signup";
import { VerifyEmailPage } from "./pages/verify-email";
import { VerifyUserPage } from "./pages/verify-user";
import {
  ACCOUNT_PATH,
  ADD_BILLING_DETAILS_PATH,
  ADD_PAYMENT_METHOD_PATH,
  APPS_HOME_PATH,
  APPS_PATH,
  BILLING_PATH,
  DASHBOARD_PATH,
  EDIT_ACCOUNT_PATH,
  EMAIL_VERIFICATION_PATH,
  EXTERNAL_VERIFICATION_PATH,
  FORGET_PASSWORD_PATH,
  FOUNDING_MEMBERS_PATH,
  HELP_PATH,
  INVOICES_PATH,
  LOGIN_PATH,
  MEDIA_DETAILS_PATH,
  OAUTH_CALLBACK_PATH,
  OAUTH_CALLBACK_PROCESS_PATH,
  ONBOARDING_PATH,
  PARTNER_PATH,
  PARTNER_VERIFICATION_PATH,
  PLANS_PATH,
  PLANS_UPGRADE_PATH,
  PREVIEW_VIDEO,
  PROMO_AUTH_LOGIN_PATH,
  PROMO_AUTH_PATH,
  REFERRAL_PATH,
  RESET_PASSWORD_PATH,
  SIGN_UP_PATH,
  TEAM_PATH,
  TRIAL_ONBOARDING_PATH,
  VERIFICATION_PATH,
  VIDEO_DETAILS,
  ZOOM_CALLBACK_PATH
} from "./routes";

const MediaEditorPage = lazy(
  () => import("./pages/media-editor-page/media-editor-page")
);
const MediaEditorNew = lazy(
  () => import("./containers/media-editor-v2/media-editor")
);

export const AppRoutes: React.FC = () => {
  return (
    <Suspense fallback="">
      <Routes>
        <Route
          path={DASHBOARD_PATH}
          element={
            <SecureRoute>
              <DashboardPage />
            </SecureRoute>
          }
        />
        <Route
          path={VIDEO_DETAILS}
          element={
            <SecureRoute>
              <MediaEditorPage />
            </SecureRoute>
          }
        />
        <Route
          path={MEDIA_DETAILS_PATH}
          element={
            <SecureRoute>
              <MediaEditorNew />
            </SecureRoute>
          }
        />
        <Route
          path={EDIT_ACCOUNT_PATH}
          element={
            <SecureRoute>
              <EditAccountPage />
            </SecureRoute>
          }
        />
        <Route
          path={ADD_PAYMENT_METHOD_PATH}
          element={
            <SecureRoute>
              <AddPaymentMethodPage />
            </SecureRoute>
          }
        />
        <Route
          path={ADD_BILLING_DETAILS_PATH}
          element={
            <SecureRoute>
              <AddBillingDetailsPage />
            </SecureRoute>
          }
        />
        <Route
          path={PLANS_UPGRADE_PATH}
          element={
            <SecureRoute>
              <PlansUpgradePage />
            </SecureRoute>
          }
        />
        <Route
          path={PLANS_PATH}
          element={
            <SecureRoute>
              <PlansPage />
            </SecureRoute>
          }
        />
        <Route
          path={INVOICES_PATH}
          element={
            <SecureRoute>
              <InvoicesPage />
            </SecureRoute>
          }
        />
        <Route
          path={ACCOUNT_PATH}
          element={
            <SecureRoute>
              <AccountPage />
            </SecureRoute>
          }
        />
        <Route
          path={BILLING_PATH}
          element={
            <SecureRoute>
              <BillingPage />
            </SecureRoute>
          }
        />

        <Route
          path={TEAM_PATH}
          element={
            <SecureRoute>
              <TeamPage />
            </SecureRoute>
          }
        />

        {config.features.hasReferral && (
          <Route
            path={REFERRAL_PATH}
            element={
              <SecureRoute>
                <ReferralPage />
              </SecureRoute>
            }
          />
        )}
        <Route
          path={HELP_PATH}
          element={
            <SecureRoute>
              <HelpPage />
            </SecureRoute>
          }
        />

        <Route
          path={ONBOARDING_PATH}
          element={
            <SecureRoute>
              <OnboardingPage />
            </SecureRoute>
          }
        />

        <Route
          path={TRIAL_ONBOARDING_PATH}
          element={
            <SecureRoute>
              <OnboardingTrialPage />
            </SecureRoute>
          }
        />

        <Route
          path={APPS_PATH}
          element={
            <SecureRoute>
              <AppsPage />
            </SecureRoute>
          }
        />
        <Route
          path={APPS_HOME_PATH}
          element={
            <SecureRoute>
              <AppsHomePage />
            </SecureRoute>
          }
        />

        <Route
          path={EMAIL_VERIFICATION_PATH}
          element={
            <SecureRoute>
              <VerifyEmailPage />
            </SecureRoute>
          }
        />

        <Route
          path={ZOOM_CALLBACK_PATH}
          element={
            <SecureRoute>
              <ZoomProcessPage />
            </SecureRoute>
          }
        />

        <Route
          path={OAUTH_CALLBACK_PROCESS_PATH}
          element={<OauthProcessPage />}
        />
        <Route path={OAUTH_CALLBACK_PATH} element={<OauthPage />} />
        <Route path={LOGIN_PATH} element={<LoginPage />} />
        <Route path={SIGN_UP_PATH} element={<SignUpPage />} />
        <Route path={FORGET_PASSWORD_PATH} element={<ForgotPasswordPage />} />
        <Route path={RESET_PASSWORD_PATH} element={<ResetPasswordPage />} />
        <Route path={VERIFICATION_PATH} element={<VerifyUserPage />} />
        <Route
          path={EXTERNAL_VERIFICATION_PATH}
          element={<VerifyUserPage external />}
        />

        <Route path={PROMO_AUTH_LOGIN_PATH} element={<PromoAuthPage />} />
        <Route path={PROMO_AUTH_PATH} element={<PromoAuthPage />} />

        <Route
          path={PARTNER_VERIFICATION_PATH}
          element={<VerifyPartnerPage />}
        />
        <Route path={PARTNER_PATH} element={<SignUpPartnerPage />} />

        <Route
          path={FOUNDING_MEMBERS_PATH}
          element={<Navigate to={LOGIN_PATH} />}
        />

        <Route path={PREVIEW_VIDEO} element={<PreviewVideoPage />} />

        <Route path="/signin" element={<Navigate to={DASHBOARD_PATH} />} />

        {config.features.hasReferral && (
          <Route
            path="/refer-a-friend"
            element={<Navigate to={REFERRAL_PATH} />}
          />
        )}

        <Route path="*" element={<Page404 />} />
      </Routes>
    </Suspense>
  );
};
