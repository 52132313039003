import React, { useEffect } from "react";
import { fetchPublicAssetUrl } from "../../../api/file.service";
import { Modal } from "../../modal/modal";
import { TutorialMedia } from "./tutorial-media";
import styles from "./video-tutorial-modal.module.scss";

interface VideoTutorialModalProps {
  closeModal: () => void;
  tutorialVideo: TutorialMedia;
}

export const VideoTutorialModal: React.FC<VideoTutorialModalProps> = ({
  tutorialVideo: { name, filename },
  closeModal
}) => {
  const [videoUrl, setVideoUrl] = React.useState("");

  useEffect(() => {
    const getVideoUrl = async () => {
      const url = await fetchPublicAssetUrl(filename);
      setVideoUrl(url);
    };

    getVideoUrl();
  }, [filename]);

  return (
    <div className="d-flex flex-row flex-wrap">
      <Modal onDismiss={closeModal} className={styles["modal"]} hasCloseIcon>
        <div className="container">
          <h6 className="text-center">{name}</h6>
          <video
            preload="auto"
            controls
            playsInline
            autoPlay
            className={styles["video"]}
          >
            <source src={videoUrl} type="video/mp4" />
            Sorry, your browser doesn't support embedded videos.
          </video>
        </div>
      </Modal>
    </div>
  );
};
