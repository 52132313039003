import React from "react";
import { EN } from "../../assets/i18n/en";
import { Modal } from "../../containers/modal/modal";
import { FeatureList } from "../../containers/settings/plans/plans-table";
import { Button } from "../button/button";
import { Divider } from "../divider/divider";

interface InvitationHelpModalProps {
  closeModal: () => void;
}

export const InvitationHelpModal: React.FC<InvitationHelpModalProps> = ({
  closeModal
}) => {
  return (
    <Modal onDismiss={closeModal} className="w-auto mw-100">
      <h5>Admin vs Editor rights</h5>
      <h4>Editor</h4>
      <FeatureList features={EN.permissions.editor} />
      <Divider />
      <h4>Admin</h4>
      <FeatureList features={EN.permissions.admin} />
      <Button primary onClick={closeModal} className="mt-5">
        Got it!
      </Button>
    </Modal>
  );
};

export const InvitationMediaHelpModal: React.FC<InvitationHelpModalProps> = ({
  closeModal
}) => {
  return (
    <Modal onDismiss={closeModal} className="w-auto mw-100">
      <h5>Editor vs Viewer rights</h5>
      <h4>Viewer</h4>
      <FeatureList features={EN.mediaPermissions.viewer} />
      <Divider />
      <h4>Editor</h4>
      <FeatureList features={EN.mediaPermissions.editor} />
      <Button primary onClick={closeModal} className="mt-5">
        Got it!
      </Button>
    </Modal>
  );
};
