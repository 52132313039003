import InformationCircleIcon from "@heroicons/react/outline/InformationCircleIcon";
import React from "react";
import { BusinessBadge } from "../../../components/badge/business-badge";
import { UploadButton } from "../../../components/button/upload-button";
import { LockFeature } from "../../../components/lock-feature/lock-feature";
import settings from "../../../config/settings/settings";
import { useMediaInfo } from "../../../hooks/use-media-info";
import { SublyPlan } from "../../../interfaces/billing";
import { uploadStore } from "../../../state/upload";
import { readCsvFile } from "../../../utils/file";
import { urlPattern } from "../../../utils/regex-patterns";
import { ToolTip } from "../../tooltip/tooltip";
import styles from "./srt-uploader.module.scss";

interface UploadUrl {
  url: string;
  language?: string;
}

interface BulkUrlUploaderProps {
  className?: string;
}
export const BulkUrlUploader: React.FC<BulkUrlUploaderProps> = ({
  className
}) => {
  const { convertUrlToUploadFile } = useMediaInfo();

  const { languages } = settings.transcription;

  const parseRow = (row: string[]): UploadUrl | undefined => {
    const url = row[0];
    const langRow = row[1];

    const urlRegex = new RegExp(urlPattern);
    if (!url || !urlRegex.test(url)) {
      return;
    }

    let language;
    if (langRow && typeof langRow === "string") {
      const lang = languages.find(
        (l) =>
          l.code.toLowerCase() === langRow.toLowerCase() ||
          l.language.toLowerCase() === langRow.toLowerCase()
      );
      language = lang?.code;
    }

    return {
      url,
      language
    };
  };

  const handleCsvSelect = async (file: File) => {
    const rows = await readCsvFile<string[]>(file);
    if (!rows) {
      return;
    }
    const uploadUrls = rows.map(parseRow).filter(Boolean);
    if (!uploadUrls?.length) {
      return;
    }

    uploadStore.setChecking(true);

    for await (const u of uploadUrls) {
      if (u?.url) {
        const uploadFile = await convertUrlToUploadFile(u.url, u?.language);
        if (uploadFile) {
          uploadStore.addFiles([uploadFile]);
        }
      }
    }

    uploadStore.setChecking(false);
  };

  return (
    <div className={className}>
      <div className="d-flex flex-row mb-2">
        <h6 className="mb-0">Bulk URL upload</h6>
        <ToolTip
          text="CSV file format
Add URLs to first column and add the Language Spoken to second column. For Language Spoken format, refer to the dropdown, eg. English (US). Rows without Language Spoken will default to form."
        >
          <InformationCircleIcon className={styles["information-icon"]} />
        </ToolTip>
        <BusinessBadge className="ml-2" />
      </div>
      <LockFeature
        minPermission={SublyPlan.Business}
        mixpanelEvent="Bulk URL upload - Pop up to upgrade"
      >
        <div className="d-flex flex-row">
          <UploadButton
            secondary
            accept=".csv"
            className="d-block mx-0"
            handleChange={async (file) => await handleCsvSelect(file)}
          >
            Upload CSV file
          </UploadButton>
        </div>
      </LockFeature>
    </div>
  );
};
