import {
  COMEDY_CROWD,
  LINKEDIN_BUSINESS,
  LINKEDIN_PARTNER,
  LSFF,
  Partner
} from "@getsubly/common";
import { getPartnerLogo } from "@getsubly/common/dist/partners/partners-logos";
import classNames from "classnames";
import React from "react";
import Logo from "../../../assets/images/logo.png";
import download from "../../../assets/images/partners/download.svg";
import languages from "../../../assets/images/partners/languages.svg";
import statsCC from "../../../assets/images/partners/stats-cc.png";
import stats from "../../../assets/images/partners/stats.png";
import style from "../../../assets/images/partners/style.svg";
import upload from "../../../assets/images/partners/upload.svg";
import { WEBSITE_URL } from "../../../routes";

interface AsideProps {
  partner: Partner;
  showChildrenTop?: boolean;
}
export const Aside: React.FC<AsideProps> = ({
  partner,
  showChildrenTop,
  children
}) => {
  const showPartnerTitle = [
    LINKEDIN_PARTNER.slug,
    LINKEDIN_BUSINESS.slug
  ].includes(partner?.slug);
  const partnerTitle = showPartnerTitle && partner.title;
  const showCCStats =
    partner.slug === COMEDY_CROWD.slug || partner.slug === LSFF.slug;
  const statsSrc = showCCStats ? statsCC : stats;

  return (
    <div className="container-fluid" id="aside">
      <div className="row">
        <div className="col">
          <AsideLogo partner={partner} />
        </div>
      </div>

      {showChildrenTop && children}

      <div className="row hero">
        <div className="col">
          <div>
            <h2 className="title">
              Reach more of your audience{" "}
              {partnerTitle ? <>on {partnerTitle}</> : null} with subtitled
              videos
            </h2>
            <PartnerBenefits partner={partner} />
          </div>
        </div>
        <div className="col-sm-5 stats">
          <img src={statsSrc} alt="Subly captions" className="img-fluid" />
        </div>
      </div>

      {!showChildrenTop && children}

      <div className="row how-it-works">
        {/* <h3>Forget the usual complicated steps when adding subtitles</h3> */}
        <h2 className="title">
          How to add subtitles to your {partnerTitle} video
        </h2>
        <div className="benefits">
          <div className="benefit">
            <img src={upload} alt="Upload your video" className="img-fluid" />
            <p>Upload your video</p>
            <p className="small">In any format</p>
          </div>
          <div className="benefit">
            <img
              src={languages}
              alt="Select subtitle language"
              className="img-fluid"
            />
            <p>Select subtitle language</p>
            <p className="small">
              Choose the language and dialect in your video
            </p>
          </div>
          <div className="benefit">
            <img src={style} alt="Edit your subtitles" className="img-fluid" />
            <p>Edit your subtitles </p>
            <p className="small">Change text, fonts, colours and styles </p>
          </div>
          <div className="benefit">
            <img
              src={download}
              alt="Download and share"
              className="img-fluid"
            />
            <p>Download and share</p>
            <p className="small">
              Download your subtitled video and share
              {partnerTitle && <> on {partnerTitle}</>}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

interface AsideLogoProps {
  partner?: Partner;
}
const AsideLogo: React.FC<AsideLogoProps> = ({ partner }) => {
  const partnerLogo = getPartnerLogo(partner?.slug);

  if (!partner || !partnerLogo) {
    return (
      <a href={WEBSITE_URL} target="_blank" rel="noopener noreferrer">
        <img src={Logo} className="img-responsive logo" alt="Subly" />
      </a>
    );
  }

  return (
    <a
      href={WEBSITE_URL}
      target="_blank"
      rel="noopener noreferrer"
      className="with-partner"
    >
      <img src={Logo} className="img-responsive logo" alt="Subly" />
      <img
        src={partnerLogo}
        className={classNames("img-responsive logo", {
          lsff: partner.name === LSFF.name
        })}
        alt={partner.name}
      />
    </a>
  );
};

interface PartnerBenefitsProps {
  partner?: Partner;
}
const PartnerBenefits: React.FC<PartnerBenefitsProps> = ({ partner }) => {
  switch (partner?.slug) {
    case COMEDY_CROWD.slug:
      return (
        <ul>
          <li>
            <span>Increase engagement</span> with your comedy content
          </li>
          <li>
            <span>No video editing skills needed</span> - anyone can use Subly
          </li>
          <li>
            <span>Save time and money</span> on editing and transcribing videos
          </li>
          <li>
            <span>Still have control to edit and style</span> your subtitles
          </li>
          <li>
            <span>Subtitles are embedded, ready to share</span> your comedy
          </li>
        </ul>
      );
    case LSFF.slug:
      return (
        <ul>
          <li>
            <span>Increase engagement</span> with your content
          </li>
          <li>
            <span>No video editing skills needed</span>
          </li>
          <li>
            <span>Save time and money</span> editing videos
          </li>
          <li>
            <span>Edit and style</span> your subtitles
          </li>
          <li>
            <span>Download a video, SRT ot TXT file</span>
          </li>
        </ul>
      );

    default:
      return (
        <ul>
          <li>
            <span>Increase engagement</span> with your content and ads
          </li>
          <li>
            <span>No video editing skills needed</span> - anyone can use Subly
          </li>
          <li>
            <span>Save time and money</span> on editing and transcribing videos
          </li>
          <li>
            <span>Still have control to edit and style</span> your subtitles
          </li>
          <li>
            <span>Subtitles are embedded, ready to share</span> your video
          </li>
        </ul>
      );
  }
};
