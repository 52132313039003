import classNames from "classnames";
import React from "react";
import {
  DashboardActiveDrop,
  FileDropCard
} from "../../containers/dashboard/upload-modal/file-drop-card";
import { UploadModal } from "../../containers/dashboard/upload-modal/upload-modal";
import { useModal } from "../../hooks/use-modal";
import { usePermissions } from "../../hooks/use-permissions";
import { usePlan } from "../../hooks/use-plan";
import { SublyPlan } from "../../interfaces/billing";
import { ModalType } from "../../interfaces/modal-type";
import { uploadQuery, uploadStore } from "../../state/upload";
import { getAcceptedFormats } from "../../utils/mime-types";
import { UploadMediaButton } from "../../components/button/upload-media-button";
import styles from "./upload-card.module.scss";

export const UploadCard: React.FC = () => {
  const [showModal, hideModal] = useModal(ModalType.Upload);
  const { isProOrHigher, isPayg, isPremiumOrHigher } = usePlan();
  const { hasPermission } = usePermissions(SublyPlan.Pro100);

  const handleCloseModal = () => {
    hideModal();
    if (!uploadQuery.getValue().isUploading) {
      uploadStore.resetUpload();
    }
  };

  const handleDropFiles = (files?: File[]) => {
    if (!files) {
      return;
    }

    showModal(
      <UploadModal initialFiles={files} closeModal={handleCloseModal} />
    );
  };

  return (
    <div
      className={classNames("col-sm-6 col-md-4 col-xl-3", styles["container"])}
    >
      <FileDropCard
        setFiles={handleDropFiles}
        dropActiveChildren={<DashboardActiveDrop />}
        disableGoogleDrive
        noClick
        className={styles["drop-zone"]}
        activeClassName={styles["drop-zone-active"]}
        accept={getAcceptedFormats(isProOrHigher, isPayg)}
        multiple={isPremiumOrHigher}
        disabled={!hasPermission}
      >
        <div className={styles.card}>
          <UploadMediaButton allowDragAndDrop />
        </div>
      </FileDropCard>
    </div>
  );
};
