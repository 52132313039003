import React from "react";
import { mdiPencilOutline } from "@mdi/js";
import Icon from "@mdi/react";
import classNames from "classnames";
import numeral from "numeral";
import { Button } from "../../../../components/button/button";
import { usePlan } from "../../../../hooks/use-plan";
import Skeleton from "react-loading-skeleton";
import { getPlanData, PAYG_PRICE } from "../../../../utils/plans";
import {
  TopUpHeader,
  TopUpMinuteInput,
  TopUpTotal
} from "../../plan-card/plan-card";
import { SublyPlan } from "../../../../interfaces/billing";
import styles from "../plans.module.scss";

interface PTopUpCreditCardProps {
  currentPlan: SublyPlan;
  loading: boolean;
  quantity?: number;
  setQuantity: React.Dispatch<React.SetStateAction<number | undefined>>;
  isReady: boolean;
  setReady: React.Dispatch<React.SetStateAction<boolean>>;
}

export const TopUpCreditCard: React.FC<PTopUpCreditCardProps> = ({
  currentPlan,
  loading,
  quantity,
  setQuantity,
  isReady,
  setReady
}) => {
  const { credit, isPayg } = usePlan();

  const currentCredit = credit?.total ?? 0;

  const planData = isPayg
    ? getPlanData(SublyPlan.PAYG)
    : getPlanData(currentPlan);

  if (loading) {
    return (
      <div className={styles["card"]}>
        <h5>1. Select minutes to top up</h5>
        <div className="mt-4">
          <div className="d-flex flex-column">
            <Skeleton width="100%" className="mb-2" />
            <Skeleton width="90%" />
          </div>
        </div>
      </div>
    );
  }

  if (isReady && quantity) {
    return (
      <div className={styles["card"]}>
        <div className="d-flex align-items-center justify-content-between mb-4">
          <h5 className="mb-0">1. Select minutes to top up</h5>
          <span
            className={styles["edit-icon"]}
            onClick={() => {
              setReady(false);
            }}
          >
            <Icon path={mdiPencilOutline} size="1.2rem" />
          </span>
        </div>

        <div className="d-flex align-items-baseline">
          <h3 className="m-0">
            ${numeral((quantity ? quantity : 0) * PAYG_PRICE).format("0.00")}
          </h3>
          <p className="m-0">/ {quantity ? quantity : 0} mins</p>
        </div>
      </div>
    );
  }

  return (
    <div className={classNames(styles["card"], styles["card-editing"])}>
      <div className="d-flex align-items-center justify-content-between">
        <h5 className="mb-2">1. Select minutes to top up</h5>
      </div>
      <div className={styles["topup-card"]}>
        <TopUpHeader
          planData={planData}
          credit={currentCredit}
          checkout={true}
        />
        <TopUpMinuteInput quantity={quantity} setQuantity={setQuantity} />
        <TopUpTotal quantity={quantity ?? 0} />
        <Button
          primary
          isFull
          disabled={!quantity || quantity < 20}
          className={styles.continue}
          onClick={() => setReady(true)}
          type="submit"
        >
          Continue
        </Button>
      </div>
    </div>
  );
};
