import {
  cleanTranscription,
  MediaConfig,
  Transcription
} from "@getsubly/common";
import * as Sentry from "@sentry/react";
import axios, { AxiosRequestConfig } from "axios";
import { saveAs } from "file-saver";
import { notificationError } from "../components/notification";
import config from "../config";
import { getAccountId } from "../config/settings/settings.service";
import { rawAnalyticsTrack } from "../contexts/analytics";
import {
  GoogleDriveDownloadInfo,
  GoogleDriveFileInfo
} from "../interfaces/google-picker";
import { BasicMedia, MediaFile } from "../interfaces/media";
import { TranscriptionMap } from "../interfaces/media-editor";
import { UnknownObject } from "../interfaces/types";
import {
  downloadQueueStore,
  QueueFileStatus
} from "../state/download-queue/download-queue.store";
import {
  mediaQuery,
  mediaStore,
  SavingFileType,
  SavingStatus
} from "../state/media";
import { getBurntFileFromJob, getOriginalFile } from "../utils/media-functions";
import { getAccessToken } from "./auth.service";
import { handleError } from "./handle-error";

const baseURL = `${config.apiUrl}/api/v1`;

export const saveSubtitles = async (
  mediaId: string,
  transcriptionMap: TranscriptionMap,
  mediaConfig: MediaConfig
): Promise<void> => {
  try {
    mediaStore.ui.update(mediaId, { savingStatus: SavingStatus.Saving });

    const savingIds = mediaQuery.ui.getEntity(mediaId)?.savingIds ?? [];

    const savingArray: Promise<unknown>[] = [];

    if (mediaQuery.uiHasSavingType(mediaId, SavingFileType.Subtitles)) {
      for (const fileId of savingIds) {
        const transcription = transcriptionMap[fileId];

        if (transcription) {
          savingArray.push(saveTranscription(mediaId, transcription, fileId));
        }
      }
    }

    if (mediaQuery.uiHasSavingType(mediaId, SavingFileType.Config)) {
      savingArray.push(saveMediaConfig(mediaId, mediaConfig));
    }

    await axios.all(savingArray);
  } catch (e) {
    handleError(e);
  } finally {
    mediaStore.setMediaIsSaved(mediaId);
  }
};

interface SaveMediaConfigOptions {
  updateState?: boolean;
}
export const saveMediaConfig = async (
  mediaId: string,
  mediaConfig: MediaConfig,
  options?: SaveMediaConfigOptions
): Promise<void> => {
  try {
    await axios.put(`/${getAccountId()}/media/${mediaId}/config`, mediaConfig, {
      baseURL,
      headers: { "x-access-token": await getAccessToken() }
    });

    // Most times the `saveMediaConfg()` is called from the `mediaEditorContext` and it
    // updates the state but in some cases we call it directly (eg. snippets modals)
    // and we need to force the update on the state. This avoids the jumping update
    // in the editor during saving.
    if (options?.updateState) {
      mediaStore.update(mediaId, { assConfig: mediaConfig });
    }
  } catch (e) {
    handleError(e);
  }
};

export const downloadBurntMediaFromJob = (
  media: BasicMedia,
  jobId: string,
  analyticsData: UnknownObject
): void => {
  const burntFile = getBurntFileFromJob(media, jobId);

  if (!burntFile) {
    notificationError(
      "Sorry, we failed to download the file. Please try again or contact support."
    );
    return;
  }

  downloadBurntMedia({ mediaId: media.id, burntFile, jobId, analyticsData });
};

interface DownloadBurntMediaParams {
  mediaId: string;
  burntFile?: MediaFile;
  googleDrive?: GoogleDriveDownloadInfo;
  showNotification?: boolean;
  jobId?: string;
  analyticsData: UnknownObject;
}

export const downloadBurntMedia = async ({
  mediaId,
  burntFile,
  googleDrive,
  analyticsData,
  jobId = ""
}: DownloadBurntMediaParams): Promise<void> => {
  const media = mediaQuery.getEntity(mediaId);

  if (!media) {
    return;
  }

  if (!burntFile) {
    return;
  }

  downloadQueueStore.updateQueueJob(mediaId, jobId, {
    status: QueueFileStatus.Downloading,
    progress: 0
  });

  try {
    await downloadFile(media.id, jobId, burntFile, analyticsData, googleDrive);

    downloadQueueStore.completeQueueJob(mediaId, jobId);
  } catch (e) {
    Sentry.captureException(e);

    rawAnalyticsTrack("Download burnt media error", { error: e });

    downloadQueueStore.completeQueueJob(mediaId, jobId);

    await downloadModal(media.id, burntFile);
  }
};

const downloadModal = async (
  mediaId: string,
  burntFile: MediaFile
): Promise<void> => {
  try {
    const downloadUrl = await getDownloadUrl(
      { mediaId, fileId: burntFile.id },
      burntFile.url
    );

    mediaStore.ui.update(mediaId, {
      download: {
        showDownloadModal: true,
        downloadUrl
      }
    });
  } catch (e) {
    handleError(e);
  }
};

export const downloadOriginalFile = async (
  mediaId: string,
  jobId: string,
  analyticsData: UnknownObject,
  googleDrive?: GoogleDriveDownloadInfo
): Promise<void> => {
  const media = mediaQuery.getEntity(mediaId);

  if (!media) {
    return;
  }

  const originalFile = getOriginalFile(media);
  if (!originalFile) {
    return;
  }

  downloadFile(media.id, jobId, originalFile, analyticsData, googleDrive);
};

export const getGoogleDriveFile = async (
  params: GoogleDriveFileInfo
): Promise<File> => {
  const url = `https://www.googleapis.com/drive/v3/files/${params.fileId}?alt=media`;

  const axiosRequestConfig: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${params.accessToken}`
    },
    responseType: "arraybuffer"
  };

  const { data } = await axios.get(url, axiosRequestConfig);

  const buffer = Buffer.from(data, "binary");

  const blob = new Blob([buffer], { type: params.mimeType });

  return new File([blob], params.filename, {
    type: params.mimeType
  });
};

export const getFileFromURL = async (
  url: string,
  filename: string,
  mimeType = "video/mp4"
): Promise<File> => {
  const axiosRequestConfig: AxiosRequestConfig = {
    responseType: "arraybuffer"
  };

  const { data } = await axios.get(url, axiosRequestConfig);
  const buffer = Buffer.from(data, "binary");

  const blob = new Blob([buffer], { type: mimeType });
  return new File([blob], filename);
};

export type SubtitlesFormat = "srt" | "txt" | "vtt";
export const downloadSubtitles = async (
  mediaId: string,
  fileId: string,
  filename: string,
  analyticsData: UnknownObject = {}
): Promise<void> => {
  const url = `/${getAccountId()}/files/${mediaId}/subtitle-download/${fileId}`;

  try {
    const { data } = await axios.get(url, {
      baseURL: baseURL,
      headers: { "x-access-token": await getAccessToken() }
    });

    const blob = new Blob([data], { type: "text/plain" });

    rawAnalyticsTrack("Download content", {
      downloadType: "subtitles",
      ...analyticsData
    });

    saveAs(blob, filename);
  } catch (e) {
    handleError(e);
  }
};

// Private functions

const downloadFile = async (
  mediaId: string,
  jobId: string,
  file: MediaFile,
  analyticsData: UnknownObject = {},
  googleDrive?: GoogleDriveDownloadInfo
): Promise<void> => {
  const media = mediaQuery.getEntity(mediaId);
  const mediaName = file.downloadName ?? media?.name ?? file.original_filename;
  const filename = `${mediaName}.${file.extension}`;

  // Chrome iOS browser it will open the s3 url
  if (/CriOS/.test(navigator.userAgent)) {
    const timestamp = `timestamp=${new Date().getTime()}`;
    const fileUrl = await getDownloadUrl({
      mediaId,
      fileId: file.id,
      timestamp
    });
    window.location.assign(fileUrl);
    return;
  }

  // Android browsers
  if (/Linux; Android/.test(navigator.userAgent)) {
    const timestamp = `timestamp=${new Date().getTime()}`;
    const fileUrl = await getDownloadUrl({
      mediaId,
      fileId: file.id,
      timestamp
    });

    rawAnalyticsTrack("Download content", {
      downloadType: "media",
      ...analyticsData
    });

    saveAs(fileUrl, filename);
    return;
  }

  if (googleDrive) {
    await axios.post(
      `/${getAccountId()}/files/${mediaId}/download/${file.id}/google`,
      { ...googleDrive },
      {
        baseURL: baseURL,
        headers: { "x-access-token": await getAccessToken() }
      }
    );
    return;
  }

  // Any other browser download the blob
  const data = await fetchFile(mediaId, jobId, file);

  if (!data) {
    return;
  }

  const blob = new Blob([data]);

  rawAnalyticsTrack("Download content", {
    downloadType: "media",
    ...analyticsData
  });

  saveAs(blob, filename);
};

interface IDownloadRequestResponse {
  fileUrl: string;
  fileType: string;
}

const fetchFile = async (
  mediaId: string,
  jobId: string,
  file: MediaFile
): Promise<Blob | undefined> => {
  const timestamp = `timestamp=${new Date().getTime()}`;
  // Try download v2 way
  try {
    rawAnalyticsTrack("Download - fetchFileDownloadV2", {
      mediaId,
      fileId: file.id
    });
    return fetchFileDownloadV2(mediaId, jobId, file.id, timestamp);
  } catch (e) {
    console.error(e);
  }

  // Try download v1 way
  try {
    rawAnalyticsTrack("Download - fetchFileLegacy", {
      mediaId,
      fileId: file.id
    });
    return fetchFileLegacy(mediaId, jobId, file.id, timestamp);
  } catch (e) {
    console.error(e);
  }

  // Try by URL
  rawAnalyticsTrack("Download - fetchFileByUrl", { mediaId, fileId: file.id });
  return fetchFileByUrl(mediaId, jobId, file.url);
};

export const getDownloadUrl = async (
  {
    mediaId,
    fileId,
    timestamp
  }: { mediaId: string; fileId: string; timestamp?: string },
  defaultUrl?: string
): Promise<string> => {
  try {
    const {
      data: { fileUrl }
    } = await axios.get<IDownloadRequestResponse>(
      `/${getAccountId()}/files/${mediaId}/download_request/${fileId}?${timestamp}`,
      {
        baseURL,
        headers: { "x-access-token": await getAccessToken() }
      }
    );

    return fileUrl;
  } catch (e) {
    if (defaultUrl) {
      return defaultUrl;
    }

    throw e;
  }
};

const fetchFileDownloadV2 = async (
  mediaId: string,
  jobId: string,
  fileId: string,
  timestamp: string
): Promise<Blob | undefined> => {
  const fileUrl = await getDownloadUrl({ mediaId, fileId, timestamp });

  const { data } = await axios.get<Blob>(fileUrl, {
    responseType: "blob",
    onDownloadProgress: (e) => downloadProgressHandler(mediaId, jobId, e)
  });

  return data;
};

const fetchFileLegacy = async (
  mediaId: string,
  jobId: string,
  fileId: string,
  timestamp: string
): Promise<Blob | undefined> => {
  const { data } = await axios.get<Blob>(
    `/${getAccountId()}/files/${mediaId}/download/${fileId}?${timestamp}`,
    {
      baseURL: baseURL,
      headers: { "x-access-token": await getAccessToken() },
      responseType: "blob",
      onDownloadProgress: (e) => downloadProgressHandler(mediaId, jobId, e)
    }
  );

  return data;
};

const fetchFileByUrl = async (
  mediaId: string,
  jobId: string,
  url: string
): Promise<Blob | undefined> => {
  const { data } = await axios.get<Blob>(url, {
    responseType: "blob",
    onDownloadProgress: (e) => downloadProgressHandler(mediaId, jobId, e)
  });

  return data;
};

const saveTranscription = async (
  mediaId: string,
  transcription: Transcription,
  fileId: string
): Promise<void> => {
  try {
    // Make sure the <mark> highlights are not saved
    const cleanedTranscription = cleanTranscription(transcription, {
      ignoreErrors: true
    });

    await axios.put(
      `/${getAccountId()}/media/${mediaId}/json/${fileId}`,
      cleanedTranscription,
      {
        baseURL: baseURL,
        headers: { "x-access-token": await getAccessToken() }
      }
    );
  } catch (e) {
    handleError(e);
  }
};

// This is used for legacy files that don't have a transcription
// and this transcription gets generated by the .srt
interface SaveLegacyTranscriptionResponse {
  fileId?: string;
}
export const saveLegacyTranscription = async (
  mediaId: string,
  transcription: Transcription
): Promise<SaveLegacyTranscriptionResponse> => {
  try {
    const {
      data: { fileId }
    } = await axios.put<SaveLegacyTranscriptionResponse>(
      `/${getAccountId()}/media/${mediaId}/json`,
      transcription,
      {
        baseURL: baseURL,
        headers: { "x-access-token": await getAccessToken() }
      }
    );

    return { fileId };
  } catch (e) {
    throw new Error(e);
  }
};

const downloadProgressHandler = (
  mediaId: string,
  jobId: string,
  progressEvent: ProgressEvent
) => {
  const progress = Math.floor(
    (progressEvent.loaded * 100) / progressEvent.total
  );

  downloadQueueStore.updateQueueJob(mediaId, jobId, {
    status: QueueFileStatus.Downloading,
    progress
  });
};

export enum PublicAssetFilename {
  WelcomeVideoPro = "welcome-pro.mp4",
  WelcomeVideoPremium = "welcome-premium.mp4",
  FoundingMembersLandingVideo = "founding-members-landing.mp4",
  FoundingMembersFeedbackVideo = "founding-members-feedback.mp4"
}

interface IFetchPublicAssetUrlResponse {
  url: string;
}

export const fetchPublicAssetUrl = async (
  publicAssetPath: string
): Promise<string> => {
  const {
    data: { url }
  } = await axios.get<IFetchPublicAssetUrlResponse>(
    `/s3/assets/${publicAssetPath}`,
    {
      baseURL
    }
  );
  return url;
};
