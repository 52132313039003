import { UserAccount } from "./account";
import { SurveyQuestion } from "./survey";

export interface User {
  id: string;
  givenName?: string;
  familyName?: string;
  name: string;
  email: string;
  emailConfirmed: boolean;
  verification_status: boolean;
  weekly_update: boolean;
  picture: string;
  picturePublic?: string;
  accounts: UserAccount[];
  accountIds: [string];
  accountId: string;
  enabled: boolean;
  status: string;
  settings?: UserSettings;
  partner: string;
  createdAt: Date;
  invitations?: UserInvitation[];
  isNewUser?: boolean;
}

export interface UserInvitation {
  id: string;
  accountName: string;
}

export enum NotificationType {
  UploadFailed = "UploadFailed",
  MediaShared = "MediaShared",
  CommentTag = "CommentTag",
  LowMinutes = "LowMinutes",
  CardExpiring = "CardExpiring",
  PaymentFailed = "PaymentFailed",
  TeammateAccepted = "TeammateAccepted"
}

export interface UserNotification {
  id: string;
  accountId?: string;
  mediaId?: string;
  type: NotificationType;
  data?: NotificationData;
  createdAt: string;
  readAt?: string;
}

export interface NotificationData {
  name?: string;
  timeLeft?: number;
  timeLeftUnits?: string;
  earnedMinutes?: number;
}

export interface UserSettings {
  location?: {
    country?: string;
  };
  onboarding?: OnboardingInfo;
  showFoundingMember?: boolean;
  foundingMember?: boolean;
  hasSeenReferral?: boolean;
  lastSeenReferralAt?: Date;
  hasAlreadyTrialled?: boolean;
  surveysCompleted?: SurveySettings;
  trialOnboarding?: boolean;
}

export interface OnboardingInfo {
  userType?: UserType;
  jobType?: IndividualJobType;
  contentFor?: ContentFor;
  jobTitle?: string;
  industry?: Industry;
  companyName?: string;
  hasCompleted?: boolean;
  teamSize?: TeamSize | string;
}

// TODO: Move to common package as it is shared with BE
export enum UserType {
  Individual = "Individual",
  Business = "Business",
  Education = "Education"
}

export enum IndividualJobType {
  Influencer = "Influencer",
  Coach = "Coach",
  ContentCreator = "Content Creator",
  Other = "Other"
}

export enum ContentFor {
  IndividualCreator = "Individual creator",
  Corporate = "Corporate / Enterprise",
  DigitalAgency = "Digital or Media agency ",
  VideoProduction = "Video production",
  Other = "Other"
}

export enum TeamSize {
  Me = "Just Me",
  UpToTen = "2-10",
  UpToFifty = "11-50",
  UptoTwoHundred = "51-200",
  OverTwoHundred = ">200"
}

export enum Industry {
  Education = "Education",
  FinancialServices = "Financial services",
  ComputerSoftware = "Computer software",
  ProfessionalTrainingAndCoaching = "Professional Training & Coaching",
  MarketingAndAdvertising = "Marketing & Advertising",
  Media = "Media/Social Media",
  GovernmentAndAdministration = "Government and Administration",
  Entertainment = "Entertainment",
  Technology = "Technology",
  Legal = "Legal",
  Other = "Other"
}

export interface FormUser extends User {
  password?: string;
  newPassword?: string;
  passwordConfirm?: string;
}

export interface UserAuth {
  accessToken: string;
}

export type SurveySettings = {
  [key in SurveyQuestion]?: boolean;
};
