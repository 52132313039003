import numeral from "numeral";
import React, { useEffect, useState } from "react";
import { removePaymentMethod } from "../../../api/billing.service";
import { BlankBillingCard } from "../../../components/blank-billing-card/blank-billing-card";
import { Button } from "../../../components/button/button";
import { LoadingIcon } from "../../../components/loading-icon/loading-icon";
import { PaymentMethod } from "../../../interfaces/billing";
import { ADD_PAYMENT_METHOD_PATH } from "../../../routes";
import { accountQuery } from "../../../state/account";
import { getCardImage } from "../../../utils/card-images";
import { Modal } from "../../modal/modal";

export const PaymentMethodCard: React.FC = () => {
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [card, setCard] = useState<PaymentMethod>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const account$ = accountQuery
      .select(["loading", "paymentMethods"])
      .subscribe(({ loading, paymentMethods }) => {
        setLoading(loading);
        setCard(paymentMethods[0]);
      });

    return () => account$.unsubscribe();
  }, []);

  if (loading) {
    return (
      <BlankBillingCard to="#">
        <LoadingIcon />
      </BlankBillingCard>
    );
  }

  if (!card) {
    return (
      <BlankBillingCard to={ADD_PAYMENT_METHOD_PATH}>
        <p>
          <b>Add payment method</b> so you can automatically pay for your
          subscription fee and add funds to your balance.
        </p>
      </BlankBillingCard>
    );
  }

  const onRemove = () => {
    removePaymentMethod();
  };

  return (
    <div className="account-card card my-4">
      <div className="card-body p-4">
        <h4 className="mt-2 text-black">Payment method</h4>
        <CardPreview card={card} />
      </div>

      <div className="card-footer py-3">
        <Button tertiary to="#" onClick={() => setShowRemoveModal(true)}>
          Remove
        </Button>
      </div>

      <RemovePaymentMethodModal
        visible={showRemoveModal}
        confirm={onRemove}
        close={() => setShowRemoveModal(false)}
      />
    </div>
  );
};

interface CardPreviewProps {
  card: PaymentMethod;
}
export const CardPreview: React.FC<CardPreviewProps> = ({ card }) => {
  const expiryMonth = numeral(card.exp_month).format("00");
  const cardImage = getCardImage(card.brand);

  return (
    <div className="mt-4 d-flex align-items-start align-items-sm-center">
      {cardImage && (
        <img src={cardImage} alt={card.brand} className="mr-3" height="60" />
      )}
      <div>
        <h5 className="text-black text-capitalize font-weight-normal">
          {card.brand} **** {card.last4}
        </h5>
        <p className="mb-0">
          Expires {expiryMonth}/{card.exp_year}
        </p>
      </div>
    </div>
  );
};

interface RemovePaymentMethodModalProps {
  visible: boolean;
  confirm: () => void;
  close: () => void;
}

const RemovePaymentMethodModal: React.FC<RemovePaymentMethodModalProps> = ({
  visible,
  confirm,
  close
}) => {
  if (!visible) {
    return null;
  }

  return (
    <Modal>
      <h5>Remove payment method?</h5>

      <p>
        Are you sure you want to remove this card? Please add another card to
        your account to keep you subscription active.
      </p>
      <p>
        By removing your card, your account might get downgraded to free plan
        which will remove access to some amazing Subly features.{" "}
      </p>
      <div className="d-flex">
        <Button secondary onClick={close} className="ml-auto mr-2">
          Cancel
        </Button>
        <Button danger onClick={confirm}>
          Remove
        </Button>
      </div>
    </Modal>
  );
};
