import React from "react";
import { FSDocs } from "../containers/media-player/media-player-controls/media-player-controls";
import { formatTime, getTimeObject } from "../utils/time";

interface MediaPlayerContext {
  hasHours: boolean;
  setHasHours: React.Dispatch<React.SetStateAction<boolean>>;
  isMuted: boolean;
  setMuted: React.Dispatch<React.SetStateAction<boolean>>;
  duration: string;
  setDuration: React.Dispatch<React.SetStateAction<string>>;
  durationValue: number;
  setDurationValue: React.Dispatch<React.SetStateAction<number>>;
  timeElapsed: string;
  setTimeElapsed: React.Dispatch<React.SetStateAction<string>>;
  timeValue: number;
  setTimeValue: React.Dispatch<React.SetStateAction<number>>;
  scrubTo: number;
  setScrubTo: React.Dispatch<React.SetStateAction<number>>;
  mediaPlayer: HTMLVideoElement | null;
  setMediaPlayer: React.Dispatch<React.SetStateAction<HTMLVideoElement | null>>;
  mediaLoaded: boolean;
  setMediaLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  currentTime: number;
  onTimeUpdate: (time?: number) => void;
  togglePlay: () => void;
  pauseVideo: () => void;
  isPlaying: boolean;
  isFullscreen: boolean;
  setIsFullscreen: React.Dispatch<React.SetStateAction<boolean>>;
  fullscreenRef: React.RefObject<FSDocs> | null;
}

const MediaPlayerContext = React.createContext<MediaPlayerContext>({
  hasHours: false,
  setHasHours: () => null,
  isMuted: false,
  setMuted: () => null,
  duration: "--:--",
  setDuration: () => null,
  durationValue: 0,
  setDurationValue: () => null,
  timeElapsed: "00:00",
  setTimeElapsed: () => null,
  timeValue: 0,
  setTimeValue: () => null,
  scrubTo: 0,
  setScrubTo: () => null,
  mediaPlayer: null,
  setMediaPlayer: () => null,
  mediaLoaded: false,
  setMediaLoaded: () => null,
  currentTime: 0,
  onTimeUpdate: () => null,
  togglePlay: () => null,
  pauseVideo: () => null,
  isPlaying: false,
  isFullscreen: false,
  setIsFullscreen: () => null,
  fullscreenRef: null
});

export const MediaPlayerProvider: React.FC = ({ children }) => {
  const [hasHours, setHasHours] = React.useState(false);
  const [isMuted, setMuted] = React.useState(false);
  const [isPlaying, setPlaying] = React.useState(false);
  const [duration, setDuration] = React.useState("--:--");
  const [durationValue, setDurationValue] = React.useState(0);
  const [timeElapsed, setTimeElapsed] = React.useState("00:00");
  const [timeValue, setTimeValue] = React.useState(0);
  const [currentTime, setCurrentTime] = React.useState(0);
  const [scrubTo, setScrubTo] = React.useState(0);
  const [mediaPlayer, setMediaPlayer] = React.useState<HTMLVideoElement | null>(
    null
  );
  const [mediaLoaded, setMediaLoaded] = React.useState(false);
  const [isFullscreen, setIsFullscreen] = React.useState<boolean>(false);
  const fullscreenRef = React.useRef<FSDocs>(null);

  React.useEffect(() => {
    setPlaying(!Boolean(mediaPlayer?.paused));
  }, [mediaPlayer?.paused]);

  const onTimeUpdate = (currentTime?: number) => {
    if (currentTime == null) {
      return;
    }

    const time = getTimeObject(Math.round(currentTime));
    setTimeElapsed(formatTime(time, hasHours));
    setTimeValue(currentTime);

    setCurrentTime(currentTime * 1000);
  };

  const togglePlay = () => {
    if (!mediaPlayer) {
      return;
    }

    if (mediaPlayer?.paused) {
      mediaPlayer.play();
    } else {
      mediaPlayer.pause();
    }

    setPlaying(!mediaPlayer?.paused);
  };

  const pauseVideo = () => {
    mediaPlayer?.pause();
    setPlaying(false);
  };

  return (
    <MediaPlayerContext.Provider
      value={{
        hasHours,
        setHasHours,
        isMuted,
        setMuted,
        duration,
        setDuration,
        durationValue,
        setDurationValue,
        timeElapsed,
        setTimeElapsed,
        timeValue,
        setTimeValue,
        scrubTo,
        setScrubTo,
        mediaPlayer,
        setMediaPlayer,
        mediaLoaded,
        setMediaLoaded,
        currentTime,
        onTimeUpdate,
        togglePlay,
        pauseVideo,
        isPlaying,
        isFullscreen,
        setIsFullscreen,
        fullscreenRef
      }}
    >
      {children}
    </MediaPlayerContext.Provider>
  );
};

export const useMediaPlayer = (): MediaPlayerContext => {
  return React.useContext(MediaPlayerContext);
};
