import { mdiMenu } from "@mdi/js";
import Icon from "@mdi/react";
import classNames from "classnames";
import React, { useContext, useRef } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { PlanBadge } from "../../components/badge/plan-badge";
import { ArrowLeftIcon } from "../../components/icons/icons";
import { NavContext } from "../../contexts/nav-context";
import { TopBannerContext } from "../../contexts/top-banner.context";
import { useAccounts } from "../../hooks/use-accounts";
import { usePlan } from "../../hooks/use-plan";
import { SublyPlan } from "../../interfaces/billing";
import { outOfMinutes } from "../../utils/plans";
import { Balance } from "../balance/balance";
import { LowBalance } from "../balance/low-balance";
import { AccountDropdown } from "./account-dropdown/account-dropdown";
import "./navbar.scss";
import { NotificationsMenu } from "./notifications-menu";
import config from "../../config";

interface NavbarProps {
  hideNavbarRight?: boolean;
  hasBackArrow?: boolean;
}
export const Navbar: React.FC<NavbarProps> = ({
  hideNavbarRight = false,
  hasBackArrow = false
}) => {
  const { isTopBannerVisible } = useContext(TopBannerContext);
  const { toggleSidebar } = useContext(NavContext);

  return (
    <nav
      className={classNames(
        "navbar navbar-expand navbar-dark bg-white top-navbar",
        {
          "with-banner": isTopBannerVisible
        }
      )}
    >
      <span
        className="mb-0 mr-2 d-md-none cursor-pointer"
        onClick={() => toggleSidebar((s) => !s)}
      >
        <Icon path={mdiMenu} size="1.8rem" />
      </span>
      <Link className="navbar-brand d-inline-flex align-items-center" to="/">
        {hasBackArrow && <ArrowLeftIcon className="navbar-back-arrow" />}

        <img
          src={logo}
          width="69.875"
          height="26"
          className="mr-1"
          alt="Subly"
          loading="lazy"
        />
      </Link>

      {!hideNavbarRight && <PlanBadge className="d-none d-sm-block" />}

      {!hideNavbarRight && <NavbarRight />}
    </nav>
  );
};

export const NavbarRight: React.FC = () => {
  const dropdownMenu = useRef<HTMLLIElement>(null);
  const dropdownMenuBalance = useRef<HTMLLIElement>(null);
  const dropdownMenuNotifications = useRef<HTMLLIElement>(null);
  const { isFree, isTrial, credit, plan, isPayg } = usePlan();
  const { isLoading, deal } = useAccounts();

  const secondsLeft = credit?.total ?? 0;
  const currentPlan = isPayg ? SublyPlan.PAYG : plan;
  const isMinutesLow = outOfMinutes(currentPlan, secondsLeft / 60);
  const showLowMinutes =
    !isLoading && !isFree && !isTrial && !deal && isMinutesLow;

  return (
    <div className="collapse navbar-collapse">
      <ul className="navbar-nav ml-auto">
        {showLowMinutes && (
          <LowBalance credit={secondsLeft ?? 0} plan={currentPlan} />
        )}
        <li className="nav-item balance" ref={dropdownMenuBalance}>
          <Balance showUpgrade dropdownMenu={dropdownMenuBalance} />
        </li>
        {config.features.notifications && (
          <li>
            <NotificationsMenu dropdownMenu={dropdownMenuNotifications} />
          </li>
        )}
        <li className="nav-item dropdown" ref={dropdownMenu}>
          <AccountDropdown dropdownMenu={dropdownMenu} />
        </li>
      </ul>
    </div>
  );
};
