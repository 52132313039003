import React from "react";
import classNames from "classnames";
import { Checkbox } from "../checkbox/checkbox";
import { TERMS_URL, PRIVACY_URL } from "../../routes";

interface TermsCheckboxProp {
  accepted: boolean;
  onAccepted: (accepted: boolean) => void;
  showError: boolean;
}

export const TermsCheckbox: React.FC<TermsCheckboxProp> = ({
  accepted,
  onAccepted,
  showError
}) => {
  return (
    <Checkbox value={accepted} onChange={onAccepted}>
      <p
        className={classNames("small mb-0", {
          "text-black": !showError,
          "text-feedback-error": showError
        })}
      >
        By clicking on Sign up, I agree to the{" "}
        <a
          href={TERMS_URL}
          target="_blank"
          rel="noopener noreferrer"
          className={classNames({
            "text-feedback-error": showError
          })}
        >
          Terms of Service
        </a>{" "}
        and{" "}
        <a
          href={PRIVACY_URL}
          target="_blank"
          rel="noopener noreferrer"
          className={classNames({
            "text-feedback-error": showError
          })}
        >
          Privacy Policy.
        </a>
      </p>
    </Checkbox>
  );
};
