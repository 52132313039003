import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getCustomer, getPlans } from "../../../api/billing.service";
import { Button } from "../../../components/button/button";
import { PlanSuggestionCard } from "../../../containers/settings/plans/cards/plan-suggestion-card";
import { PricingContactCard } from "../../../containers/settings/plans/cards/pricing-contact-card";
import { PlanComparisonTable } from "../../../containers/settings/plans/comparison-table";
import { PayAsYouGoPricing } from "../../../containers/settings/plans/payg-pricing";
import { PlansTable } from "../../../containers/settings/plans/plans-table";
import { AuthContext } from "../../../contexts/auth.context";
import { PlansTableProvider } from "../../../contexts/plans-table.context";
import { PlanInterval, SublyPlan } from "../../../interfaces/billing";
import { AppLayout } from "../../../layouts/app.layout";
import { SublyCouponCode } from "../../../utils/plans";

interface UpgradeState {
  plan?: SublyPlan;
  interval?: PlanInterval;
  coupon?: SublyCouponCode;
}
export const PlansPage: React.FC = () => {
  const state = useLocation().state as UpgradeState;
  const { user } = useContext(AuthContext);
  const interval = state?.interval ?? PlanInterval.Yearly;
  const [quantity, setQuantity] = useState<number | undefined>();
  const [isPAYGView, setIsPAYGView] = useState(state?.plan === SublyPlan.PAYG);
  const [toggleComparison, setToggleComparison] = useState(false);
  const headerRef = React.useRef<null | HTMLDivElement>(null);
  const executeScroll = () =>
    headerRef.current?.scrollIntoView({ behavior: "smooth" });

  useEffect(() => {
    if (!user) {
      return;
    }

    getCustomer({ force: false });
    getPlans();
  }, [user]);

  return (
    <AppLayout hideNavbarRight hideSidebar>
      <PlansTableProvider interval={interval} couponCode={state?.coupon}>
        <div ref={headerRef} className="d-flex flex-column align-items-center">
          {isPAYGView ? (
            <PayAsYouGoPricing updateQuantity={(q) => setQuantity(q)} />
          ) : (
            <PlansTable />
          )}

          {toggleComparison && <PlanComparisonTable />}

          <Button
            className="mb-5"
            secondaryBorder
            onClick={() => setToggleComparison((s) => !s)}
          >
            {toggleComparison ? "Hide Table" : "Compare plans"}
          </Button>

          <PlanSuggestionCard
            quantity={quantity}
            isPAYGView={isPAYGView}
            setIsPAYGView={setIsPAYGView}
            executeScroll={executeScroll}
          />
          <PricingContactCard />
        </div>
      </PlansTableProvider>
    </AppLayout>
  );
};
