import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { UserVerification } from "../containers/auth/user-verification";
import { useQuery } from "../hooks/use-query";
import { AuthLayout } from "../layouts/auth.layout";
import { decodeQueryState, isBase64 } from "../utils/links";

interface LocationState {
  hasStateParsed?: boolean;
}
interface Params {
  state?: string;
}
interface VerifyUserPageProps {
  external?: boolean;
}
export const VerifyUserPage: React.FC<VerifyUserPageProps> = ({
  external = false
}) => {
  const { pathname, state: locationState } = useLocation();
  const { state: queryState } = useQuery<Params>();

  if (
    !(locationState as LocationState)?.hasStateParsed &&
    isBase64(queryState)
  ) {
    const { state, gclid } = decodeQueryState(queryState);
    const searchParams = new URLSearchParams();

    if (state) {
      searchParams.append("state", state);
    }

    if (gclid) {
      searchParams.append("gclid", gclid);
    }

    const search = searchParams.toString();

    return (
      <Navigate to={{ pathname, search }} state={{ hasStateParsed: true }} />
    );
  }

  return (
    <AuthLayout showLogin>
      <UserVerification external={external} />
    </AuthLayout>
  );
};
