import { mdiLock, mdiPencilOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import classNames from "classnames";
import numeral from "numeral";
import React, { useState } from "react";
import { getCustomer, getIntent } from "../../../../api/billing.service";
import { Button } from "../../../../components/button/button";
import { LoadingIcon } from "../../../../components/loading-icon/loading-icon";
import { useAnalyticsWithAuth } from "../../../../hooks/use-analytics-with-auth";
import { PaymentMethod } from "../../../../interfaces/billing";
import { getCardImage } from "../../../../utils/card-images";
import styles from "../plans.module.scss";

interface PaymentMethodCardProps {
  card?: PaymentMethod;
  isReady: boolean;
  loading: boolean;
}
export const PaymentMethodCard: React.FC<PaymentMethodCardProps> = ({
  card,
  isReady,
  loading
}) => {
  const [focus, setFocus] = useState(false);
  const [complete, setComplete] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string>();
  const [editMode, setEditMode] = React.useState(false);
  const { trackEventWithAuth } = useAnalyticsWithAuth();

  const stripe = useStripe();
  const elements = useElements();

  if (!isReady) {
    return (
      <div className={classNames(styles["card"])}>
        <div className="d-flex align-items-center justify-content-between">
          <h5 className="mb-0">3. Add credit or debit card</h5>
        </div>
      </div>
    );
  }

  if (!card && loading) {
    return (
      <div className={classNames(styles["card"])}>
        <div className="d-flex align-items-center justify-content-between mb-4">
          <h5 className="mb-0">3. Add credit or debit card</h5>
        </div>
        <LoadingIcon />
      </div>
    );
  }

  if (card && !editMode) {
    const expiryMonth = numeral(card.exp_month).format("00");
    const expiryYear =
      String(card.exp_year).length > 2
        ? String(card.exp_year).slice(-2)
        : card.exp_year;
    const cardImage = getCardImage(card.brand);

    return (
      <div className={classNames(styles["card"])}>
        <div className="d-flex align-items-center justify-content-between mb-4">
          <h5 className="mb-0">3. Add credit or debit card</h5>
          <span
            className={styles["edit-icon"]}
            onClick={() => setEditMode(true)}
          >
            <Icon path={mdiPencilOutline} size="1.2rem" />
          </span>
        </div>
        <div className="mt-4 d-flex  align-items-start align-items-sm-center">
          {cardImage && (
            <img
              src={cardImage}
              alt={card.brand}
              className="mr-3"
              height="40"
            />
          )}
          <div>
            <h6 className="text-capitalize mb-0">
              {card.brand} •••• {card.last4}
            </h6>
            <p className="small mb-0">
              Expires {expiryMonth}/{expiryYear}
            </p>
          </div>
        </div>
      </div>
    );
  }

  const onSubmit = async () => {
    if (!stripe) {
      setError(
        "There is an issue please send an email to support@getsubly.com."
      );
      return;
    }

    if (!elements) {
      setError("There is an issue please contact support@getsubly.com");
      return;
    }

    if (!complete) {
      setError("Some information is missing.");
      return;
    }

    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      setError("There is an issue, please contact support@getsubly.com.");
      return;
    }

    setError("");
    setSubmitting(true);

    try {
      const {
        data: { intent }
      } = await getIntent();

      const { error, setupIntent } = await stripe.confirmCardSetup(intent, {
        payment_method: {
          card: cardElement
        }
      });

      if (error) {
        setError(error.message);
        return;
      }

      if (!setupIntent || !setupIntent.payment_method) {
        setError("There was an unknown error");
        return;
      }

      await getCustomer({ force: true });
      trackEventWithAuth("Checkout - Added a valid card");
      setEditMode(false);
    } catch (error) {
      setError("There was an error. Please try again or contact support.");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className={classNames(styles["card"], styles["card-editing"])}>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <h5 className="mb-0">3. Add credit or debit card</h5>
        <h6 className="d-inline-flex align-items-center">
          <Icon path={mdiLock} size="16" className="mr-2" color="inherit" />
          Secure
        </h6>
      </div>
      <CardElement
        className={classNames("form-control d-flex credit-card-input mb-0", {
          focused: focus
        })}
        options={{ hidePostalCode: true }}
        onBlur={() => setFocus(false)}
        onFocus={() => setFocus(true)}
        onChange={(e) => setComplete(e.complete)}
      />
      <div className="form-group d-flex justify-content-start">
        {error && <span className="text-accent-1">{error}</span>}
      </div>
      <Button
        primary
        disabled={!complete}
        className="ml-auto mt-4"
        onClick={onSubmit}
        loading={submitting}
        type="submit"
      >
        Save and continue
      </Button>
    </div>
  );
};
