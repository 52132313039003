import React from "react";
import { useNavigate } from "react-router";
import { finishOnboarding } from "../api/auth.service";
import { OnboardingInfo } from "../interfaces/user";
import { DASHBOARD_PATH } from "../routes";
import { AuthContext } from "./auth.context";
import { useAccounts } from "../hooks/use-accounts";

type CurrentStep = 1 | 2 | 3 | 4;
interface OnboardingContext {
  currentStep: CurrentStep;
  totalSteps: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<CurrentStep>>;
  data: OnboardingInfo;
  setData: React.Dispatch<React.SetStateAction<OnboardingInfo>>;
  nextStep: (skip?: boolean) => void;
  previousStep: (skip?: boolean) => void;
  isLoading: boolean;
}

export const OnboardingContext = React.createContext<OnboardingContext>({
  currentStep: 1,
  totalSteps: 0,
  setCurrentStep: () => null,
  data: {},
  setData: () => null,
  nextStep: () => null,
  isLoading: false,
  previousStep: () => null
});

export const OnboardingProvider: React.FC = ({ children }) => {
  const { user } = useAccounts();
  const [currentStep, setCurrentStep] = React.useState<CurrentStep>(1);
  const [isLoading, setLoading] = React.useState(false);
  const { setUser } = React.useContext(AuthContext);

  const initSettings = user?.settings?.onboarding ?? {};
  const [data, setData] = React.useState<OnboardingInfo>(initSettings);

  const navigate = useNavigate();
  const totalSteps = 4;

  const handleFinish = async () => {
    setLoading(true);
    try {
      const user = await finishOnboarding({ ...data, hasCompleted: true });
      setUser(user);
    } catch (error) {
      console.error(error);
    } finally {
      // If it fails, just send the user to the Dashboard
      setFinishPath();
    }
  };

  const setFinishPath = () => {
    navigate(DASHBOARD_PATH);
  };

  const nextStep = async (skip?: boolean) => {
    if (skip) {
      await handleFinish();
    }

    if (currentStep < totalSteps) {
      setCurrentStep((s) => (s + 1) as CurrentStep);
      return;
    }

    await handleFinish();
  };

  const previousStep = () => {
    setCurrentStep((s) => (s - 1) as CurrentStep);
  };

  return (
    <OnboardingContext.Provider
      value={{
        currentStep,
        totalSteps,
        setCurrentStep,
        data,
        setData,
        nextStep,
        isLoading,
        previousStep
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};

export const useOnboarding = (): OnboardingContext =>
  React.useContext(OnboardingContext);
