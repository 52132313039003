import React from "react";
import { Button } from "../button/button";
import { MinusCircleIcon, PlusCircleIcon } from "../icons/icons";
import styles from "./storage-checkout-card.module.scss";

interface StorageAmountToggleProps {
  amount: number;
  setAmount: (amount: number) => void;
  totalAmount: number;
  isModal?: boolean;
  toggleNext?: (next: boolean) => void;
}

export const StorageAmountToggle: React.FC<StorageAmountToggleProps> = ({
  amount,
  setAmount,
  totalAmount,
  isModal = false
}) => {
  return (
    <div className={styles["toggle-container"]}>
      {isModal ? (
        <p className={styles["toggle-container-title"]}>
          Increase your monthly extra storage <span>($4.99 per 50GB)</span>
        </p>
      ) : (
        <p className={styles["toggle-container-title"]}>
          How much extra storage would you like per month?
        </p>
      )}
      <div className={styles["toggle-container-amount"]}>
        <MinusCircleIcon
          className={styles["toggle-container-toggle"]}
          onClick={() => {
            amount === 0 ? setAmount(0) : setAmount(amount - 1);
          }}
        />
        <span
          className={styles["toggle-container-title"]}
        >{`${totalAmount} GB`}</span>
        <PlusCircleIcon
          className={styles["toggle-container-toggle"]}
          onClick={() => setAmount(amount + 1)}
        />
      </div>
    </div>
  );
};

interface StorageAmountToggleDProps extends StorageAmountToggleProps {
  toggleNext: (next: boolean) => void;
  exceededLimit: string;
}

export const UploadStorageAmount: React.FC<StorageAmountToggleDProps> = ({
  amount,
  setAmount,
  totalAmount,
  toggleNext,
  exceededLimit
}) => {
  return (
    <div className={styles["toggle-container-dark"]}>
      <p className={styles["toggle-container-dark-title"]}>
        Increase your monthly storage
      </p>

      <div className={styles["toggle-container-dark-box"]}>
        <MinusCircleIcon
          className={styles["toggle-container-dark-toggle"]}
          onClick={() => {
            amount === 0 ? setAmount(0) : setAmount(amount - 1);
          }}
        />
        <span
          className={styles["toggle-container-dark-quantity"]}
        >{`${totalAmount} GB`}</span>
        <PlusCircleIcon
          className={styles["toggle-container-dark-toggle"]}
          onClick={() => setAmount(amount + 1)}
        />
      </div>

      <div className={styles["description"]}>
        <p>
          You need {exceededLimit} more to transcribe all selected files.
          Increase your storage now to transcribe selected files.
          <br />
          <br />
          Extra storage starts from $4.99/ 50GB.
        </p>
      </div>

      <Button primary onClick={() => toggleNext(true)} disabled={amount === 0}>
        Next
      </Button>
    </div>
  );
};
