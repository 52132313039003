import {
  AspectFit,
  AspectRatio,
  AspectRatioConfig,
  BackgroundStyle,
  BurnPosition,
  CustomLogoSize,
  HeadlineConfig,
  MediaConfig
} from "@getsubly/common";
import { AudioTemplate } from "../../../../../types/media-editor.types";
import { getBackgroundImage } from "../../../../../utils/templates";

export const AUDIO_TEMPLATE_1: AudioTemplate = {
  templateId: "audiogram-1",
  fontSize: 56,
  colorPrimary: "000B0B0B",
  alignment: 5,
  marginL: 69,
  marginR: 69,
  marginV: 298,
  headline: {
    style: {
      marginL: 69,
      marginR: 69,
      marginV: 69,
      alignment: 7,
      colorPrimary: "000B0B0B",
      fontSize: 56
    }
  },
  artwork: {
    id: "template-1",
    url: "audio-templates/template-1.png",
    dimensions: {
      width: 1080,
      height: 1080
    },
    sublyArtwork: true
  },
  customLogo: {
    visible: false,
    id: "",
    url: "",
    position: BurnPosition.TopRight,
    opacity: 100,
    size: CustomLogoSize.Small,
    dimensions: {
      width: 0,
      height: 0
    },
    padding: {
      x: 69,
      y: 69
    }
  },
  aspectRatio: {
    color: "#39EFA5"
  }
};

export const AUDIO_TEMPLATE_2: AudioTemplate = {
  templateId: "audiogram-2",
  fontSize: 56,
  colorPrimary: "000B0B0B",
  alignment: 8,
  marginL: 100,
  marginR: 100,
  marginV: 280,
  headline: {
    style: {
      alignment: 1,
      colorPrimary: "00FFFFFF",
      fontSize: 56,
      marginL: 230,
      marginR: 230,
      marginV: 64
    }
  },
  artwork: {
    id: "template-2",
    url: "audio-templates/template-2.png",
    dimensions: {
      width: 1080,
      height: 1080
    },
    sublyArtwork: true
  },
  customLogo: {
    visible: false,
    id: "",
    url: "",
    position: BurnPosition.BottomLeft,
    opacity: 100,
    size: CustomLogoSize.Small,
    dimensions: {
      width: 0,
      height: 0
    },
    padding: {
      x: 59,
      y: 59
    }
  },
  aspectRatio: {
    color: "#8A50F7"
  },
  noLogoHeadline: {
    marginL: 74,
    marginR: 74,
    marginV: 64
  }
};

export const AUDIO_TEMPLATE_3: AudioTemplate = {
  templateId: "audiogram-3",
  fontSize: 56,
  colorPrimary: "00FFFFFF",
  alignment: 8,
  marginL: 150,
  marginR: 150,
  marginV: 350,
  headline: {
    style: {
      alignment: 7,
      colorPrimary: "00FFFFFF",
      fontSize: 56,
      marginL: 230,
      marginR: 230,
      marginV: 78
    }
  },
  artwork: {
    id: "template-3",
    url: "audio-templates/template-3.png",
    dimensions: {
      width: 1080,
      height: 1080
    },
    sublyArtwork: true
  },
  customLogo: {
    visible: false,
    id: "",
    url: "",
    position: BurnPosition.TopLeft,
    opacity: 100,
    size: CustomLogoSize.Small,
    dimensions: {
      width: 0,
      height: 0
    },
    padding: {
      x: 69,
      y: 69
    }
  },
  aspectRatio: {
    color: "#520F9A"
  },
  noLogoHeadline: {
    marginL: 78,
    marginR: 78,
    marginV: 78
  }
};

export const AUDIO_TEMPLATE_4: AudioTemplate = {
  templateId: "audiogram-4",
  fontSize: 56,
  colorPrimary: "000B0B0B",
  alignment: 7,
  marginL: 100,
  marginR: 100,
  marginV: 300,
  headline: {
    style: {
      alignment: 3,
      colorPrimary: "000B0B0B",
      fontSize: 56,
      marginL: 230,
      marginR: 230,
      marginV: 64
    }
  },
  artwork: {
    id: "template-4",
    url: "audio-templates/template-4.png",
    dimensions: {
      width: 1080,
      height: 1080
    },
    sublyArtwork: true
  },
  customLogo: {
    visible: false,
    id: "",
    url: "",
    position: BurnPosition.BottomRight,
    opacity: 100,
    size: CustomLogoSize.Small,
    dimensions: {
      width: 0,
      height: 0
    },
    padding: {
      x: 59,
      y: 59
    }
  },
  aspectRatio: {
    color: "#E2FDF2"
  },
  noLogoHeadline: {
    marginL: 64,
    marginR: 64,
    marginV: 64
  }
};

interface BaseAudiogramTemplate
  extends Omit<MediaConfig, "playResX" | "playResY"> {
  headline: HeadlineConfig;
  aspectRatio: AspectRatioConfig;
}
const BASE_AUDIOGRAM_TEMPLATE: BaseAudiogramTemplate = {
  fontSize: 72,
  colorPrimary: "000B0B0B",
  colorOutline: "000000",
  colorBack: "009A0F52",
  alignment: 8,
  font: "Inter",
  colorSecondary: "0000FF",
  bold: 0,
  italic: 0,
  underline: 0,
  strikeOut: 0,
  scaleX: 100,
  scaleY: 100,
  spacing: 0,
  angle: 0,
  borderStyle: "\r",
  outline: 0,
  shadow: 0,
  marginL: 100,
  marginR: 100,
  marginV: 280,
  encoding: 0,
  backgroundStyle: BackgroundStyle.None,
  hasBackground: false,
  showWatermark: false,
  headline: {
    duration: 1000 * 60 * 60 * 24 - 1,
    style: {
      alignment: 1,
      angle: 0,
      backgroundStyle: BackgroundStyle.None,
      bold: -1,
      borderStyle: "\r",
      colorBack: "000000",
      colorOutline: "000000",
      colorPrimary: "00FFFFFF",
      colorSecondary: "0000FF",
      encoding: 0,
      font: "Inter",
      fontSize: 64,
      hasBackground: false,
      italic: 0,
      marginL: 230,
      marginR: 230,
      marginV: 64,
      outline: 0,
      scaleX: 100,
      scaleY: 100,
      shadow: 0,
      spacing: 0,
      strikeOut: 0,
      underline: 0
    },
    text: "Title goes here",
    visible: true
  },
  aspectRatio: {
    ratio: AspectRatio.Square,
    fit: AspectFit.Border,
    color: "#8A50F7"
  }
};

export const createAudioTemplateConfig = (
  template: AudioTemplate,
  ratio: AspectRatio
): BaseAudiogramTemplate => {
  const artworkUrl = getBackgroundImage(template.templateId, ratio);
  return {
    ...BASE_AUDIOGRAM_TEMPLATE,
    ...template,
    headline: {
      ...BASE_AUDIOGRAM_TEMPLATE.headline,
      style: {
        ...BASE_AUDIOGRAM_TEMPLATE.headline.style,
        ...template.headline.style
      }
    },
    aspectRatio: {
      ...BASE_AUDIOGRAM_TEMPLATE.aspectRatio,
      ...template.aspectRatio,
      ratio: ratio
    },
    artwork: {
      ...template.artwork,
      url: artworkUrl
    }
  };
};

export const AUDIO_TEMPLATES = [
  AUDIO_TEMPLATE_1,
  AUDIO_TEMPLATE_2,
  AUDIO_TEMPLATE_3,
  AUDIO_TEMPLATE_4
];
