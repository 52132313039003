import { Order, QueryConfig, QueryEntity } from "@datorama/akita";
import { Observable } from "@mindspace-io/react";
import { UserNotification } from "../../interfaces/user";
import {
  NotificationsState,
  notificationsStore,
  NotificationsStore
} from "./notifications.store";

@QueryConfig({
  sortBy: "createdAt",
  sortByOrder: Order.DESC
})
export class NotificationsQuery extends QueryEntity<NotificationsState> {
  get isLoading(): boolean {
    return this.getValue().loading;
  }

  constructor(protected store: NotificationsStore) {
    super(store);
  }

  selectAllNotifications(): Observable<UserNotification[]> {
    return this.selectAll({ sortByOrder: Order.DESC, sortBy: "createdAt" });
  }

  selectUnreadNotifications(): Observable<UserNotification[]> {
    return this.selectAll({ filterBy: (n) => !Boolean(n?.readAt) });
  }

  selectReadNotifications(): Observable<UserNotification[]> {
    return this.selectAll({ filterBy: (n) => Boolean(n?.readAt) });
  }

  selectRecentNotifications(): Observable<UserNotification[]> {
    const recentNotificationsFilter = (notification: UserNotification) => {
      const date1 = Math.floor(Date.parse(Date()) / (24 * 60 * 60 * 1000));
      const date2 = Math.floor(
        new Date(notification.createdAt).getTime() / (24 * 60 * 60 * 1000)
      );
      const diffDays = date1 - date2;

      return diffDays < 15;
    };
    return this.selectAll({
      filterBy: [
        (n) => Boolean(n.readAt),
        (n) => Boolean(recentNotificationsFilter(n))
      ]
    });
  }
}

export const notificationsQuery = new NotificationsQuery(notificationsStore);
