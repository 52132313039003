import {
  BrandYourVideoImage,
  CreatingAudiogramImage,
  ExploreMoreImage,
  PairingSubtitleImage,
  ResizeImage,
  SnippetsImage,
  SubtitlesImage,
  TranscribingImage,
  TranslatingImage,
  UploadVideoImage
} from "../components/icons/dashboard-shortcut-assets";

export enum ShortcutTag {
  Transcribing = "Transcribing",
  Translating = "Translating",
  Audiogram = "Audiogram",
  SRT = "SRT",
  Resizing = "Resizing",
  CreatingSnippets = "Creating snippets",
  GeneratingSubtitles = "Generating subtitles (TXT, SRT, VTT)",
  UploadingVideo = "Uploading video",
  BrandingVideo = "Branding video",
  ExploringOptions = "Explore options"
}

interface Shortcut {
  label: string;
  Image: React.FC<React.SVGProps<SVGSVGElement>>;
  tag: string;
  isPremiumFeature?: boolean;
}

const TRANSCRIBING_SHORTCUT: Shortcut = {
  label: "Subtitle",
  Image: TranscribingImage,
  tag: ShortcutTag.Transcribing,
  isPremiumFeature: false
};
const TRANSLATING_SHORTCUT: Shortcut = {
  label: "Translate",
  Image: TranslatingImage,
  tag: ShortcutTag.Translating,
  isPremiumFeature: true
};

const AUDIOGRAM_SHORTCUT: Shortcut = {
  label: "Create an audiogram",
  Image: CreatingAudiogramImage,
  tag: ShortcutTag.Audiogram,
  isPremiumFeature: false
};
const PAIRING_SRT_SHORTCUT: Shortcut = {
  label: "Pair SRT to video",
  Image: PairingSubtitleImage,
  tag: ShortcutTag.SRT,
  isPremiumFeature: true
};
const RESIZING_SHORTCUT: Shortcut = {
  label: "Resize",
  Image: ResizeImage,
  tag: ShortcutTag.Resizing,
  isPremiumFeature: false
};
const SNIPPETS_SHORTCUT: Shortcut = {
  label: "Create snippets",
  Image: SnippetsImage,
  tag: ShortcutTag.CreatingSnippets,
  isPremiumFeature: false
};
const GENERATING_SUBTITLES_SHORTCUT: Shortcut = {
  label: "Generate TXT, SRT, VTT",
  Image: SubtitlesImage,
  tag: ShortcutTag.GeneratingSubtitles,
  isPremiumFeature: false
};
const UPLOADING_SHORTCUT: Shortcut = {
  label: "Upload video",
  Image: UploadVideoImage,
  tag: ShortcutTag.UploadingVideo,
  isPremiumFeature: false
};
const BRANDING_SHORTCUT: Shortcut = {
  label: "Brand your video",
  Image: BrandYourVideoImage,
  tag: ShortcutTag.BrandingVideo,
  isPremiumFeature: true
};

const EXPLORE_OPTIONS_SHORTCUT: Shortcut = {
  label: "Explore options",
  Image: ExploreMoreImage,
  tag: ShortcutTag.ExploringOptions
};

export const SHORTCUTS = [
  TRANSCRIBING_SHORTCUT,
  TRANSLATING_SHORTCUT,
  AUDIOGRAM_SHORTCUT,
  PAIRING_SRT_SHORTCUT,
  RESIZING_SHORTCUT,
  SNIPPETS_SHORTCUT,
  GENERATING_SUBTITLES_SHORTCUT,
  UPLOADING_SHORTCUT,
  BRANDING_SHORTCUT
];

export const MINIMUM_SHORTCUTS = [
  TRANSCRIBING_SHORTCUT,
  TRANSLATING_SHORTCUT,
  AUDIOGRAM_SHORTCUT,
  PAIRING_SRT_SHORTCUT,
  EXPLORE_OPTIONS_SHORTCUT
];
