import React from "react";
import Lottie from "react-lottie-player";
import IndividualCreatorAnimation from "../../../../assets/images/onboarding/individual-creator-animation.json";
import CorporateAnimation from "../../../../assets/images/onboarding/corporate-animation.json";
import OtherAnimation from "../../../../assets/images/onboarding/other-animation.json";
import DigitalAgencyAnimation from "../../../../assets/images/onboarding/digital-agency-animation.json";
import VideoProductionAnimation from "../../../../assets/images/onboarding/video-production-animation.json";
import { useOnboarding } from "../../../../contexts/onboarding.context";
import { ContentFor } from "../../../../interfaces/user";
import { OnboardingSteps } from "../../onboarding-steps";
import styles from "./onboarding-intro.module.scss";

export const OnboardingIntro: React.FC = () => {
  const { setData, setCurrentStep, nextStep } = useOnboarding();

  const handleClick = (contentFor: ContentFor) => {
    setData((d) => ({ ...d, contentFor }));

    const shouldJump = [
      ContentFor.DigitalAgency,
      ContentFor.VideoProduction
    ].includes(contentFor);

    if (shouldJump) {
      setCurrentStep(3);
    } else {
      nextStep();
    }
  };

  return (
    <div className={styles.wrapper}>
      <h3 className={styles.header}>
        Who are you?
        <OnboardingSteps />
      </h3>
      <div className={styles.cardContainer}>
        <div className={styles.grid}>
          <ContentForCard
            title={ContentFor.IndividualCreator}
            onClick={() => handleClick(ContentFor.IndividualCreator)}
          />
          <ContentForCard
            title={ContentFor.Corporate}
            onClick={() => handleClick(ContentFor.Corporate)}
          />
          <ContentForCard
            title={ContentFor.Other}
            onClick={() => handleClick(ContentFor.Other)}
          />
          <ContentForCard
            title={ContentFor.DigitalAgency}
            onClick={() => handleClick(ContentFor.DigitalAgency)}
          />
          <ContentForCard
            title={ContentFor.VideoProduction}
            onClick={() => handleClick(ContentFor.VideoProduction)}
          />
        </div>
      </div>
    </div>
  );
};

interface ContentForCardProps {
  title: ContentFor;
  onClick: () => void;
}
const ContentForCard: React.FC<ContentForCardProps> = ({ title, onClick }) => {
  let animation;

  switch (title) {
    case ContentFor.IndividualCreator:
      animation = IndividualCreatorAnimation;
      break;
    case ContentFor.Corporate:
      animation = CorporateAnimation;
      break;
    case ContentFor.Other:
      animation = OtherAnimation;
      break;
    case ContentFor.DigitalAgency:
      animation = DigitalAgencyAnimation;
      break;
    case ContentFor.VideoProduction:
      animation = VideoProductionAnimation;
      break;
  }
  return (
    <div className={styles.card} onClick={onClick}>
      <Lottie
        loop
        animationData={animation}
        speed={0.5}
        className={styles["animation"]}
        play
      />
      <div className="my-3">
        <h6 className={styles.title}>{title}</h6>
      </div>
    </div>
  );
};
