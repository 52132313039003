import React from "react";
import { Onboarding } from "../../containers/onboarding/onboarding-container/onboarding-container";
import { OnboardingProvider } from "../../contexts/onboarding.context";

export const OnboardingPage: React.FC = () => {
  return (
    <OnboardingProvider>
      <Onboarding />
    </OnboardingProvider>
  );
};
