import React from "react";
import { SignUpParams } from "../../api/auth.service";
import { Alert } from "../../components/alert/alert";
import { Button } from "../../components/button/button";
import { AuthContext } from "../../contexts/auth.context";
import { AnalyticsContext } from "../../contexts/analytics";
import { useDataLayer } from "../../hooks/use-data-layer";
import { useQuery } from "../../hooks/use-query";
import { LOGIN_PATH } from "../../routes";
import { isEmbeddedWebView } from "../../utils/browser";
import { encodeParamsToState } from "../../utils/links";
import { SublyCouponCode, URLStateToPlan } from "../../utils/plans";
import { NewUserEntry } from "./new-user-entry";

import styles from "./auth.module.scss";

interface QueryState {
  state?: string;
  invite?: string;
  coupon?: SublyCouponCode;
  gclid?: string;
}

enum SignUpFormState {
  SignUp = "SignUp",
  EmailSent = "EmailSent"
}

interface SignUpFormProps {
  isTrial?: boolean;
}

export const SignUpForm: React.FC<SignUpFormProps> = ({ isTrial = false }) => {
  const [formState, setFormState] = React.useState<SignUpFormState>(
    SignUpFormState.SignUp
  );
  const [loading, setLoading] = React.useState(false);
  const [acceptedTerms, setAcceptedTerms] = React.useState(false);
  const [showTermsError, setShowTermsError] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState<string>();
  const [signUpData, setSignUpData] = React.useState<SignUpParams>();
  const { state, invite, coupon, gclid } = useQuery<QueryState>();
  const { signUp } = React.useContext(AuthContext);
  const { trackEvent } = React.useContext(AnalyticsContext);
  const dataLayer = useDataLayer();

  const sendSignUp = async (data: SignUpParams) => {
    setLoading(true);
    setAlertMessage("");
    const [localPart, provider] = data.email.split("@") ?? ["", ""];

    if (localPart.includes("+") && !provider.includes("getsubly.com")) {
      setLoading(false);
      setAlertMessage(
        `Special characters like "+" are not allowed in the email`
      );
    } else {
      // Encode query params into state
      data.state = encodeParamsToState(data.state, gclid);

      const { success, alertMessage } = await signUp(data);

      setLoading(false);

      if (success) {
        setFormState(SignUpFormState.EmailSent);

        dataLayer("New User");
        const { analyticsEvent } = URLStateToPlan(state, coupon);

        if (!analyticsEvent) {
          // We use this event with Premium on GA and we should keep it
          // to make things easier for marketing. It is just a label.
          // Please don't rename this event from "Is Trial on Premium"!
          dataLayer("Is Trial on Premium");
        }
      } else {
        setFormState(SignUpFormState.SignUp);
      }

      setAlertMessage(alertMessage);
    }
  };

  const onSubmit = async (data: SignUpParams) => {
    if (!acceptedTerms) {
      setShowTermsError(true);
      return;
    }
    trackEvent("Sign up email+password");

    setShowTermsError(false);

    data.givenName = data.givenName?.trim();
    data.familyName = data.familyName?.trim();
    data.name = `${data.givenName} ${data.familyName}`;

    if (state) {
      data.state = state;
    }

    data.invite = invite;

    setSignUpData(data);

    // Email verify flow
    await sendSignUp(data);
  };

  const renderContent = () => {
    switch (formState) {
      case SignUpFormState.EmailSent:
        return (
          <>
            <h2 className="mb-3 text-center">Thanks for signing up</h2>
            <p className="mb-4 text-center">
              Please check your email. We have just sent you a verification
              link.
            </p>
            <Button primary className="btn-block" to={LOGIN_PATH}>
              Go back
            </Button>
          </>
        );

      case SignUpFormState.SignUp:
      default:
        return (
          <NewUserEntry
            onSubmit={onSubmit}
            loading={loading}
            showTermsError={showTermsError}
            onAcceptedTerms={setAcceptedTerms}
            acceptedTerms={acceptedTerms}
            initialData={signUpData ?? {}}
            isWebView={isEmbeddedWebView()}
            isTrial={isTrial}
          />
        );
    }
  };

  return (
    <div className={styles.container}>
      {alertMessage && (
        <Alert
          danger
          title="Something went wrong!"
          closable
          onClose={() => setAlertMessage("")}
        >
          {alertMessage}
        </Alert>
      )}
      {renderContent()}
    </div>
  );
};
