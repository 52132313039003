import { Query } from "@datorama/akita";
import { Observable, of, switchMap } from "rxjs";
import {
  PresenceUser,
  UserPresenceState,
  UserPresenceStore,
  userPresenceStore
} from "./user-presence.store";

const isString = (item: string | undefined): item is string => {
  return Boolean(item);
};

export class UserPresenceQuery extends Query<UserPresenceState> {
  get state(): UserPresenceState {
    return this.getValue();
  }

  get hasLoaded(): boolean {
    return this.state.hasLoaded;
  }
  get isLoading(): boolean {
    return this.state.isLoading;
  }

  get lockedMediaIds(): string[] {
    return this.state.users?.map((u) => u.mediaId)?.filter(isString) ?? [];
  }

  get lockedMediaUsers(): PresenceUser[] {
    return this.state.users?.filter((u) => Boolean(u?.mediaId)) ?? [];
  }

  constructor(protected store: UserPresenceStore) {
    super(store);
  }

  selectHasLoaded(): Observable<boolean> {
    return this.select((s) => s.hasLoaded);
  }

  selectIsLoading(): Observable<boolean> {
    return this.select((s) => s.isLoading);
  }

  selectLockedMediaIds(): Observable<string[]> {
    return this.select((s) => s.users.map((u) => u.mediaId).filter(isString));
  }

  selectLockedMediaUsers(): Observable<PresenceUser[]> {
    return this.select((s) => s.users.filter((u) => u?.mediaId));
  }

  selectLockedUsers(mediaId: string): Observable<PresenceUser[]> {
    return this.select((s) => s.users.filter((u) => u?.mediaId === mediaId));
  }

  selectActiveUser(mediaId: string): Observable<PresenceUser | undefined> {
    return this.selectLockedUsers(mediaId).pipe(
      switchMap((users) => {
        if (!users.length) {
          return of(undefined);
        }

        const earliestUser = users.reduce((prev, curr) =>
          prev?.joinedAt && curr?.joinedAt
            ? prev?.joinedAt?.valueOf() > curr?.joinedAt?.valueOf()
              ? curr
              : prev
            : prev
        );

        return of(earliestUser);
      })
    );
  }
}

export const userPresenceQuery = new UserPresenceQuery(userPresenceStore);
