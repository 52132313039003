import { mdiChevronLeft } from "@mdi/js";
import Icon from "@mdi/react";
import classNames from "classnames";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Coupon,
  getCoupon,
  getCustomer,
  getPlans,
  handlePurchaseSuccess,
  purchaseCredit
} from "../../../api/billing.service";
import { EN } from "../../../assets/i18n/en";
import { Button } from "../../../components/button/button";
import { SmallDotsIcon } from "../../../components/icons/brand-assets";
import { notificationError } from "../../../components/notification";
import { useAccounts } from "../../../hooks/use-accounts";
import { useDataLayer } from "../../../hooks/use-data-layer";
import { useAnalyticsWithAuth } from "../../../hooks/use-analytics-with-auth";
import { usePlan } from "../../../hooks/use-plan";
import {
  ACCOUNT_PATH,
  DASHBOARD_PATH,
  ONBOARDING_PATH,
  PLANS_PATH
} from "../../../routes";
import {
  getPlanData,
  getPlanNameLabel,
  PAYG_PRICE
} from "../../../utils/plans";
import { BillingDetailsCard } from "./cards/billing-card";
import { PayAsYouGoUnitCard } from "./cards/payg-unit-card";
import { PayAsYouGoTotalCard } from "./cards/payg-total-card";
import { PaymentMethodCard } from "./cards/payment-card";
import { TermsCopy } from "./cards/terms-card";
import styles from "./plans.module.scss";
import { ModalType } from "../../../interfaces/modal-type";
import { useModal } from "../../../hooks/use-modal";
import { UpgradePayAsYouGoModal } from "../../../components/upgrade-modal/upgrade-payg-modal";
import { SublyPlan } from "../../../interfaces/billing";
import { useStripe } from "@stripe/react-stripe-js";
import { useUser } from "../../../hooks/use-user";
import { PayAsYouGoPromoCodeCard } from "./cards/payg-promo-card";
import { TopUpCreditCard } from "./cards/topup-credit-card";
import { TopUpTotalCard } from "./cards/topup-total-card";

interface PayAsYouGoUpgradeContainerProps {
  topUp?: boolean;
  coupon?: string;
  redirect?: string;
  analyticsEvent?: string;
  quantity?: number;
  skipSettingValue?: boolean;
}
export const PayAsYouGoUpgradeContainer: React.FC<
  PayAsYouGoUpgradeContainerProps
> = ({
  topUp = false,
  coupon: couponProps,
  redirect,
  analyticsEvent,
  quantity: initialQuantity,
  skipSettingValue = false
}) => {
  const stripe = useStripe();

  const [showModal, hideModal] = useModal(ModalType.UpgradePayAsYouGoModal);

  const [isReady, setReady] = React.useState(false);
  const [country, setCountry] = React.useState<string>();
  const [submitting, setSubmitting] = React.useState(false);
  const [coupon, setCoupon] = React.useState<Coupon>();
  const [promoId, setPromoId] = React.useState<string>();
  const [quantity, setQuantity] = React.useState<number | undefined>(undefined);
  const { plan: currentPlan, isTrial, hasTrialed, isPayg } = usePlan();
  const { paymentMethods: cards = [], billing, isLoading } = useAccounts();
  const { hasOnboarded } = useUser();

  const { trackEventWithAuth } = useAnalyticsWithAuth();
  const dataLayer = useDataLayer();

  const navigate = useNavigate();

  useEffect(() => {
    if (initialQuantity) {
      setQuantity(initialQuantity);
    }
  }, [initialQuantity]);

  useEffect(() => {
    getCustomer();
    getPlans();

    const fetchCoupon = async () => {
      if (couponProps) {
        try {
          const c = await getCoupon(couponProps);
          setCoupon(c.coupon);
          setPromoId(c.promoId);
        } catch (e) {
          console.error("Couldn't fetch coupon....", e);
        }
      }
    };

    fetchCoupon();
  }, [couponProps]);

  const balance = billing?.details?.balance ?? 0;
  const billingCountry = billing?.details?.address?.country;

  useEffect(() => {
    setCountry(billingCountry);
  }, [billingCountry]);

  useEffect(() => {
    if (skipSettingValue) {
      setReady(true);
    }
  }, [skipSettingValue]);

  const purchaseEnabled = Boolean(
    isReady && cards[0] && Boolean(billing?.details) && quantity
  );

  const handlePurchase = async () => {
    if (currentPlan !== SublyPlan.Free && !isTrial && !isPayg && !topUp) {
      showModal(
        <UpgradePayAsYouGoModal
          plan={getPlanData(currentPlan)}
          upgrade={async () => {
            hideModal();
            await purchase();
          }}
          closeModal={hideModal}
        />
      );

      return;
    }

    await purchase();
  };

  const purchase = async (): Promise<void> => {
    if (!stripe) {
      throw new Error(
        "There is an issue, please contact support@getsubly.com."
      );
    }

    const methodId = cards[0]?.id;

    const oldPlan = getPlanNameLabel(currentPlan);
    const newPlan = "Subly PAYG";

    setSubmitting(true);

    try {
      if (!quantity) {
        throw new Error("Quantity not set");
      }

      if (!methodId) {
        throw new Error("No card information");
      }

      const response = await purchaseCredit({
        quantity,
        payg: !topUp,
        methodId,
        promoId
      });

      if (response.status === "requires_action" && response.nextAction) {
        const { error } = await stripe.confirmCardPayment(
          response.nextAction.clientSecret,
          {
            payment_method: response.nextAction.payment
          }
        );

        if (error?.message) {
          throw new Error(error.message);
        }
      }

      await handlePurchaseSuccess();

      if (topUp) {
        trackEventWithAuth("Checkout - top up minutes");
      } else {
        trackEventWithAuth("Checkout - Upgraded plan", {
          toPlan: "Subly PAYG"
        });

        dataLayer("Upgraded Membership", { oldPlan, newPlan });
        dataLayer(`${oldPlan} to ${newPlan} paid`);

        if (analyticsEvent) {
          dataLayer(analyticsEvent);
          trackEventWithAuth("Upgraded from code", { event: analyticsEvent });
        }

        // User is under trial and didn't come through with a code straight to checkout
        // eg. https://my.getsubly.com/login?state=premium
        if (isTrial && !analyticsEvent) {
          dataLayer(`Free to ${newPlan} paid`);
          trackEventWithAuth("Upgraded during trial");
        }

        if (oldPlan === SublyPlan.Free) {
          dataLayer("Free to Paid Plan");
        }

        if (!isTrial && hasTrialed) {
          dataLayer("Come Back After Trial or Paid");
          trackEventWithAuth("Upgraded after trial ended");
        }
      }

      if (redirect) {
        navigate(redirect);
      } else if (!hasOnboarded) {
        navigate(ONBOARDING_PATH);
      } else if (topUp) {
        navigate(ACCOUNT_PATH);
      } else {
        navigate(DASHBOARD_PATH);
      }
    } catch (e) {
      console.error("Upgrade error", e);
      let errorMessage = e.message;

      if (e?.response) {
        errorMessage =
          e.response.data?.error?.reason ?? e.response.data?.message;
      }

      notificationError(errorMessage ?? EN.error.defaultMessage);

      trackEventWithAuth("Checkout - Error while purchasing", {
        toPlan: "Subly PAYG",
        error: errorMessage
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="container">
      <Link to={PLANS_PATH} className={classNames(styles["link"], "mb-3")}>
        <Icon path={mdiChevronLeft} size="1.2em" /> Back
      </Link>
      <div className="row">
        <div className="col position-relative">
          {!topUp && (
            <SmallDotsIcon
              className={classNames(
                styles.dots,
                "d-none d-md-block position-absolute"
              )}
            />
          )}

          {topUp ? (
            <TopUpCreditCard
              currentPlan={currentPlan}
              loading={isLoading}
              quantity={quantity}
              setQuantity={setQuantity}
              isReady={isReady}
              setReady={setReady}
            />
          ) : (
            <PayAsYouGoUnitCard
              loading={isLoading}
              quantity={quantity}
              setQuantity={setQuantity}
              isReady={isReady}
              setReady={setReady}
            />
          )}

          <BillingDetailsCard
            billing={billing?.details}
            loading={isLoading}
            isReady={true}
          />
          <PaymentMethodCard
            card={cards[0]}
            loading={isLoading}
            isReady={Boolean(billing?.details?.address)}
          />
          <PayAsYouGoPromoCodeCard
            code={couponProps}
            setCoupon={setCoupon}
            setPromoId={setPromoId}
          />
          <div className="d-block d-md-none">
            {topUp ? (
              <TopUpTotalCard
                quantity={quantity ?? 0}
                amount={(quantity ?? 0) * PAYG_PRICE}
                purchaseEnabled={!purchaseEnabled}
                handlePurchase={handlePurchase}
                submitting={submitting}
                coupon={coupon}
                country={country}
              />
            ) : (
              <PayAsYouGoTotalCard
                quantity={quantity ?? 0}
                amount={(quantity ?? 0) * PAYG_PRICE}
                coupon={coupon}
                country={country}
                credit={balance ? balance : 0}
              />
            )}
          </div>

          {!topUp && (
            <>
              <TermsCopy coupon={coupon} isPayg />

              <Button
                primary
                className={styles["purchase-btn"]}
                disabled={!purchaseEnabled}
                onClick={handlePurchase}
                loading={submitting}
                type="submit"
              >
                Purchase minutes
              </Button>
            </>
          )}
        </div>

        <div className="col d-none d-md-block">
          {topUp ? (
            <TopUpTotalCard
              quantity={quantity ?? 0}
              amount={(quantity ?? 0) * PAYG_PRICE}
              purchaseEnabled={!purchaseEnabled}
              handlePurchase={handlePurchase}
              submitting={submitting}
              coupon={coupon}
              country={country}
            />
          ) : (
            <PayAsYouGoTotalCard
              quantity={quantity ?? 0}
              amount={(quantity ?? 0) * PAYG_PRICE}
              coupon={coupon}
              country={country}
              credit={balance ? balance : 0}
            />
          )}
        </div>
      </div>
    </div>
  );
};
