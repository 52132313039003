import React from "react";
import { ToolTip } from "../../containers/tooltip/tooltip";
import { Place } from "react-tooltip";
import { mdiLoading } from "@mdi/js";
import { PlusIcon } from "@heroicons/react/outline";
import { createFolder } from "../../state/folders/folders.service";
import Icon from "@mdi/react";
import styles from "./add-folder-button.module.scss";

interface AddFolderButtonProps {
  folderCategory: string;
  onClick?: (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
  toolTipPlace: Place | undefined;
}

export const AddFolderButton: React.FC<AddFolderButtonProps> = ({
  folderCategory,
  onClick,
  toolTipPlace
}) => {
  const [loading, setLoading] = React.useState(false);

  const handleClickAddFolder = async () => {
    try {
      setLoading(true);
      const isShared = folderCategory !== "Private";
      await createFolder(isShared);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div onClick={onClick}>
        <Icon path={mdiLoading} spin size="25px" className="p-1" />
      </div>
    );
  }

  return (
    <ToolTip text="New folder" className={styles.tooltip} place={toolTipPlace}>
      <PlusIcon
        className={styles["plus-icon"]}
        onClick={handleClickAddFolder}
      />
    </ToolTip>
  );
};
