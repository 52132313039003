import { mdiPencilOutline } from "@mdi/js";
import Icon from "@mdi/react";
import classNames from "classnames";
import numeral from "numeral";
import React from "react";
import Skeleton from "react-loading-skeleton";
import { Navigate } from "react-router";
import { EN } from "../../../../assets/i18n/en";
import { Badge } from "../../../../components/badge/badge";
import { Button } from "../../../../components/button/button";
import { notificationError } from "../../../../components/notification";
import { RadioInput } from "../../../../components/radio-input/radio-input";
import { usePlan } from "../../../../hooks/use-plan";
import {
  PlanInterval,
  Product,
  SublyPlan
} from "../../../../interfaces/billing";
import { PLANS_PATH } from "../../../../routes";
import { getPlanNameLabel, getPlansForPlan } from "../../../../utils/plans";
import styles from "../plans.module.scss";

interface PlanIntervalCardProps {
  plan: SublyPlan;
  loading: boolean;
  plans: Product[];
  setPlan: (sublyPlan: SublyPlan) => void;
  setPlanInterval: (interval: PlanInterval) => void;
  selectedInterval: boolean;
  setSelectedInterval: (selected: boolean) => void;
}

export const PlanIntervalCard: React.FC<PlanIntervalCardProps> = ({
  plan,
  loading,
  plans,
  setPlan,
  setPlanInterval,
  selectedInterval,
  setSelectedInterval
}) => {
  const { plan: currentPlan, isTrial } = usePlan();

  const [yearly, monthly] = getPlansForPlan(plan);
  const yearlyPlan = plans?.find((p) => p.name === yearly);
  const monthlyPlan = plans?.find((p) => p.name === monthly);

  React.useEffect(() => {
    if (loading || isTrial) {
      return;
    }

    if (currentPlan === monthly) {
      setSelectedInterval(true);
      setPlan(yearly);
      setPlanInterval(PlanInterval.Yearly);
    } else if (currentPlan === yearly) {
      setSelectedInterval(true);
      setPlan(monthly);
      setPlanInterval(PlanInterval.Monthly);
    }
  }, [currentPlan, plan, loading]);

  if ((loading || !plans?.length) && !selectedInterval) {
    return (
      <div className={styles.card}>
        <h5>1. Choose your plan</h5>
        <div className="mt-4">
          <div className="d-flex flex-column">
            <Skeleton width="100%" className="mb-2" />
            <Skeleton width="90%" />
          </div>
          <div className="d-flex flex-column mt-4">
            <Skeleton width="100%" className="mb-2" />
            <Skeleton width="90%" />
          </div>
        </div>
      </div>
    );
  }

  if (!yearlyPlan || !monthlyPlan) {
    notificationError(EN.error.defaultMessage, { toastId: "no-plan" });

    return <Navigate to={PLANS_PATH} />;
  }

  const format = "$0.00";
  const yearlyPrice = numeral(yearlyPlan.amount / 100).format(format);
  const yearlyPriceMonth = numeral(yearlyPlan.amount / 100 / 12).format(format);
  const monthlyPrice = numeral(monthlyPlan.amount / 100).format(format);
  const yearlyDiscount = numeral(
    (monthlyPlan.amount * 12 - yearlyPlan.amount) / 100
  ).format(format);

  const planLabel = getPlanNameLabel(yearly);

  const handleIntervalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPlan(e.target.value as SublyPlan);
    setPlanInterval(e.target.id as PlanInterval);
  };

  if (selectedInterval) {
    const planIntervalLabel = plan === yearly ? "Yearly" : "Monthly";

    return (
      <div className={styles.card}>
        <div className="d-flex align-items-center justify-content-between">
          <h5 className="">1. Choose your plan</h5>
          <span
            className={styles["edit-icon"]}
            onClick={() => setSelectedInterval(false)}
          >
            <Icon path={mdiPencilOutline} size="1.2rem" />
          </span>
        </div>
        <div className="mt-4">
          <RadioInput id="placeholder" defaultChecked>
            <span className="font-weight-bold mb-1">
              {planLabel} {planIntervalLabel}
            </span>
          </RadioInput>
        </div>
      </div>
    );
  }

  return (
    <div className={classNames(styles["card"], styles["card-editing"])}>
      <h5>1. Choose your plan</h5>
      <div onChange={handleIntervalChange} className="mt-4">
        <RadioInput
          name="interval"
          id={PlanInterval.Yearly}
          value={yearly}
          defaultChecked={plan === yearly}
          className="mb-4"
        >
          <span className="font-weight-bold mb-1">
            {planLabel} Yearly{" "}
            <Badge className={styles["popular-badge-2"]}>
              BEST VALUE - SAVE {yearlyDiscount}
            </Badge>
          </span>
          <p className="small mb-0">
            {yearlyPrice} ({yearlyPriceMonth}/month)
          </p>
        </RadioInput>
        <RadioInput
          className="mb-2"
          name="interval"
          id={PlanInterval.Monthly}
          value={monthly}
          defaultChecked={plan === monthly}
        >
          <span className="font-weight-bold mb-1">{planLabel} Monthly</span>
          <p className="small mb-0">{monthlyPrice}/month</p>
        </RadioInput>
      </div>
      <Button
        primary
        isFull
        className={styles.continue}
        onClick={() => setSelectedInterval(true)}
        type="submit"
      >
        Continue
      </Button>
    </div>
  );
};
