import classNames from "classnames";
import React from "react";
import styles from "./progress-bar.module.scss";

interface ProgressBarProps {
  progress: number;
  loading?: boolean;
  className?: string;
}
export const ProgressBar: React.FC<ProgressBarProps> = ({
  progress,
  loading,
  className
}) => {
  if (loading) {
    return (
      <div className={classNames(styles["progress"], className)}>
        <div
          className="progress-bar progress-bar-striped progress-bar-animated"
          role="progressbar"
          style={{ width: "100%" }}
        ></div>
      </div>
    );
  }

  return (
    <div className={classNames(styles["progress"], className)}>
      <div
        className="progress-bar"
        role="progressbar"
        style={{ width: `${progress ?? 0}%` }}
      ></div>
    </div>
  );
};
