import React from "react";
import { SALES_CONTACT } from "../../../../routes";
import styles from "../plans.module.scss";
import { Button } from "../../../../components/button/button";

export const PricingContactCard: React.FC = () => {
  return (
    <div className={styles["pricing-contact-card"]}>
      <div className={styles["contact-card-content"]}>
        <div>
          <h3 className="font-weight-bold">Got pricing questions?</h3>
          <p className={styles["contact-card-text"]}>
            Don’t hesistate to reach out if you have any questions about our
            features, pricing, or if you require a custom business plan.
          </p>
        </div>
        <Button secondaryBorder to={SALES_CONTACT} type="blank">
          Contact us
        </Button>
      </div>
    </div>
  );
};
