import { PartnerDiscountCode } from "@getsubly/common";
import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";
import { Coupon, getCoupon } from "../../../../api/billing.service";
import { Button } from "../../../../components/button/button";
import { AuthContext } from "../../../../contexts/auth.context";
import { useAnalyticsWithAuth } from "../../../../hooks/use-analytics-with-auth";
import { Product, SublyPlan } from "../../../../interfaces/billing";
import { SublyCouponCode } from "../../../../utils/plans";
import { compareStrings } from "../../../../utils/strings";
import styles from "../plans.module.scss";

interface PromoCodeCardProps {
  code?: string;
  plan: SublyPlan;
  plans: Product[];

  setCoupon: (coupon: Coupon) => void;
  setPromoId: (promoId: string) => void;
}
export const PromoCodeCard: React.FC<PromoCodeCardProps> = (props) => {
  const { partner, isFoundingMember, isEducation } =
    React.useContext(AuthContext);
  const [showPromoInput, setShowPromoInput] = React.useState(false);
  const [code, setCode] = React.useState(props.code);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const [coupon, setCoupon] = React.useState<Coupon>();
  const { trackEventWithAuth } = useAnalyticsWithAuth();

  const handleCode = async (
    e?: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e?.preventDefault();

    if (!code) {
      setError("Enter a code to check if it is valid.");
      return;
    }

    if (compareStrings(code, PartnerDiscountCode.PARTNER20) && !partner) {
      setError("This code is only valid for partners!");
      return;
    } else if (
      compareStrings(code, SublyCouponCode.FMUpgrade) &&
      !isFoundingMember
    ) {
      setError("This code is only valid for Founding Members!");
      return;
    } else if (
      compareStrings(code, SublyCouponCode.EDUpgrade) &&
      !isEducation
    ) {
      setError("This code is only valid for Education Users!");
      return;
    } else if (
      compareStrings(code, SublyCouponCode.EarlyBird) &&
      props.plan !== SublyPlan.Pro100
    ) {
      setError("This code is only valid for Subly Pro 100 minutes!");
      return;
    } else if (
      compareStrings(code, SublyCouponCode.PremiumUpgrade) &&
      ![SublyPlan.Premium, SublyPlan.Premium1000].includes(props.plan)
    ) {
      setError("This code is only valid for Premium monthly subscriptions");
      return;
    } else if (
      // If code is set, but it wasn't set by props the props reject this!
      compareStrings(code, SublyCouponCode.HubspotOffer) &&
      !compareStrings(props.code, SublyCouponCode.HubspotOffer)
    ) {
      setError("Sorry, this code is private and not applicable to you.");
      return;
    }

    try {
      setLoading(true);
      const c = await getCoupon(code);

      const couponAppliesTo = c?.coupon?.applies_to?.products || [];
      if (couponAppliesTo.length) {
        const product = props.plans.find((p) => p.name === props.plan);
        if (product?.id && !couponAppliesTo.includes(product.id)) {
          setError("This code is not valid for this plan!");
          setLoading(false);
          return;
        }
      }

      props.setCoupon(c.coupon);
      setCoupon(c.coupon);
      props.setPromoId(c.promoId);

      setError("");
      setLoading(false);

      trackEventWithAuth("Checkout - Added valid promo code", { code });
    } catch (e) {
      setError("Code is invalid.");
      setLoading(false);
      setCoupon(undefined);
      console.error("Code is invalid", e);

      trackEventWithAuth("Checkout - Added invalid promo code", { code });
    }
  };

  if (showPromoInput) {
    return (
      <div className={classNames(styles["card"], styles["card-editing"])}>
        <form className="" onSubmit={() => handleCode()}>
          <div className="form-row">
            <div className="form-group col-lg-9 mb-0">
              <label htmlFor="promo-code">Promo code</label>
              <input
                type="text"
                className={classNames("form-control mb-2 mr-sm-2", {
                  "is-invalid": Boolean(error),
                  "is-valid": Boolean(coupon)
                })}
                id="promo-code"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                placeholder="Promo code"
              />
              {error && <div className="invalid-feedback">{error}</div>}
              {coupon && (
                <div className="valid-feedback text-black">
                  This promo code is valid for {coupon.duration_in_months}{" "}
                  months
                </div>
              )}
            </div>
            <div className="form-group col">
              <label>&nbsp;</label>
              <Button
                primary
                type="submit"
                className={classNames("mb-2", styles["coupon-btn"])}
                onClick={handleCode}
                loading={loading}
              >
                Apply
              </Button>
            </div>
          </div>
        </form>
      </div>
    );
  }
  return (
    <div className={classNames(styles["card"], "text-center")}>
      <Link
        to="#"
        onClick={(e) => {
          e.preventDefault();

          trackEventWithAuth("Checkout - Show promo input");
          setShowPromoInput(true);
        }}
        className={classNames(styles["link"], "d-inline-block")}
      >
        Have a promo code?
      </Link>
    </div>
  );
};
