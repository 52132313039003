import {
  useChannel,
  useClientTrigger,
  useEvent,
  usePresenceChannel
} from "@harelpls/use-pusher";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getCredit, getCustomer } from "../../api/billing.service";
import { downloadBurntMediaFromJob } from "../../api/file.service";
import { acceptInvitation } from "../../api/invitation.service";
import { fetchMedia } from "../../api/media.service";
import { getAllNotifications } from "../../api/notifications.service";
import { EN } from "../../assets/i18n/en";
import config from "../../config";
import { useAnalyticsWithAuth } from "../../hooks/use-analytics-with-auth";
import {
  BasicMedia,
  BurningTask,
  Media,
  MediaComment,
  MediaJobType,
  MediaStatus
} from "../../interfaces/media";
import { UnknownObject } from "../../interfaces/types";
import { UserNotification } from "../../interfaces/user";
import { accountStore } from "../../state/account";
import { commentsStore } from "../../state/comments";
import { downloadQueueQuery } from "../../state/download-queue/download-queue.query";
import {
  downloadQueueStore,
  QueueFileStatus
} from "../../state/download-queue/download-queue.store";
import { mediaQuery, mediaStore } from "../../state/media";
import { uploadStore } from "../../state/upload";
import { userPresenceStore } from "../../state/user-presence";
import { isJobFailed } from "../../utils/media-functions";
import {
  notificationError,
  notificationSuccess,
  notificationWarning
} from "../notification";

enum PusherAction {
  NewMedia = "NEW_MEDIA",
  UpdateMediaStatus = "UPDATE_MEDIA_STATUS",
  UpdateMediaConvertProgress = "UPDATE_MEDIA_CONVERT_PROGRESS",
  UpdateMediaUploadProgress = "UPDATE_MEDIA_UPLOAD_PROGRESS",
  BurnStart = "BURN_START",
  BurnComplete = "BURN_COMPLETE",
  BurnFail = "BURN_FAIL",
  UpdateMediaJobStatus = "UPDATE_MEDIA_JOB_STATUS",
  MediaTranslate = "MEDIA_TRANSLATE",
  NewComment = "NEW_COMMENT",
  EditComment = "EDIT_COMMENT",
  DeleteComment = "DELETE_COMMENT",
  DeleteReply = "DELETE_REPLY",
  BurnToGoogleDriveComplete = "BURN_TO_GOOGLE_DRIVE_COMPLETE",
  BillingChange = "BILLING_CHANGE",
  NewInvitation = "NEW_INVITATION",
  TeamCapacityChange = "TEAM_CAPACITY_CHANGE",
  AccountChange = "ACCOUNT_CHANGE",
  NewNotification = "NEW_NOTIFICATION"
}

export enum PusherEvent {
  Media = "MEDIA",
  Billing = "BILLING",
  User = "USER",
  Account = "ACCOUNT",
  Comment = "COMMENT",
  Notification = "NOTIFICATION",
  PresenceConnect = "pusher:subscription_succeeded",
  MemberAdded = "pusher:member_added",
  MemberRemoved = "pusher:member_removed",
  RequestSync = "client-request_sync",
  HasOpened = "client-has_opened",
  HasSaved = "client-has_saved",
  HasClosed = "client-has_closed"
}

const IGNORE_PUSHER_ALERTS = [
  PusherAction.NewMedia,
  PusherAction.UpdateMediaStatus,
  PusherAction.UpdateMediaConvertProgress,
  PusherAction.UpdateMediaUploadProgress,
  PusherAction.BurnStart,
  PusherAction.BurnComplete,
  PusherAction.BurnToGoogleDriveComplete,
  PusherAction.BurnFail,
  PusherAction.MediaTranslate
];

interface PusherMediaData {
  alert: {
    status: MediaStatus;
    message: string;
    description: string;
  };
  action: PusherAction;
  mediaId: string;
  jobId?: string;
  status: MediaStatus;
  media?: Media;
  progress?: {
    overallProgress: number;
    tasks: BurningTask[];
  };
  percentage?: number;
  needsConverting?: boolean;
  userId?: string;
}

interface PusherBillingData {
  action: PusherAction;
  freeCredit: number;
  paidCredit: number;
  extraCredit: number;
  paygCredit: number;
}

interface PusherUserData {
  action: PusherAction;
  invitationId: string;
  accountName: string;
}
interface PusherAccountData {
  action: PusherAction;
  teamCapacity?: number;
}

interface PusherNotificationData {
  action: PusherAction;
  notification: UserNotification;
}

interface PusherCommentData {
  action: PusherAction;
  mediaId: string;
  comment?: MediaComment;
  commentId?: string;
  replyId?: string;
}

interface PusherProps {
  accountId: string;
  userId: string;
}

const updateConvertJob = (m: BasicMedia, percentage: number) => {
  if (m.latestJob?.type !== MediaJobType.Conversion) return m;

  const jobs = m.jobs.map((j) => {
    if (j.type !== MediaJobType.Conversion) return j;

    return {
      ...j,
      percentage
    };
  });

  return {
    ...m,
    jobs,
    latestJob: {
      ...m.latestJob,
      percentage
    }
  };
};

const handleMediaPusher = async ({
  data,
  userId,
  analyticsData
}: {
  data?: PusherMediaData;
  userId: string;
  analyticsData: UnknownObject;
}) => {
  if (!data) {
    return;
  }

  const { alert, action, mediaId, userId: pusherUserId, jobId } = data;

  if (pusherUserId && pusherUserId !== userId) {
    return;
  }

  if (action === PusherAction.UpdateMediaJobStatus) {
    const { progress, jobId } = data;

    const media = mediaQuery.getEntity(mediaId);

    if (!progress || !media || !jobId) {
      return;
    }

    // Update queue job progress
    if (downloadQueueQuery.isCanceledJob(mediaId, jobId)) {
      return;
    }

    downloadQueueStore.updateQueueJob(mediaId, jobId, {
      progress: progress.overallProgress,
      status: QueueFileStatus.Processing
    });
  }

  if (alert && !IGNORE_PUSHER_ALERTS.includes(action)) {
    switch (alert.status) {
      case MediaStatus.Failed:
        notificationError(alert.description);
        break;

      default:
        notificationSuccess(alert.description);
        break;
    }
  }

  switch (action) {
    case PusherAction.UpdateMediaStatus:
      const { status } = data;

      if (status === MediaStatus.Ready) {
        getCredit({ force: true });
      } else if (status === MediaStatus.Failed) {
        notificationError(
          "There was an error with transcribing your file. Please try again or contact support."
        );
      }

      fetchMedia(mediaId);
      break;
    case PusherAction.UpdateMediaConvertProgress:
      const { percentage } = data;

      if (percentage) {
        mediaStore.update(mediaId, (m) => updateConvertJob(m, percentage));
      }
      break;
    case PusherAction.UpdateMediaUploadProgress:
      const { percentage: progress } = data;

      if (progress) {
        uploadStore.setUploadProgress(progress);
      }
      break;
    case PusherAction.NewMedia:
      const { media } = data;

      if (!media || !media.mediaId) {
        return;
      }

      fetchMedia(media.mediaId, { stopUpload: true });
      break;

    case PusherAction.BurnComplete:
      const updatedMedia = await fetchMedia(mediaId);

      if (!updatedMedia || !jobId) {
        return;
      }

      if (
        downloadQueueQuery.isCanceledJob(mediaId, jobId) ||
        isJobFailed(updatedMedia, jobId)
      ) {
        return;
      }

      // Don't download if it is a Google Drive file
      if (downloadQueueQuery.isGoogleDrive(mediaId, jobId)) {
        downloadQueueStore.updateQueueJob(mediaId, jobId, {
          progress: 0,
          status: QueueFileStatus.Downloading
        });
        return;
      }

      downloadQueueStore.updateQueueJob(mediaId, jobId, {
        progress: 100,
        status: QueueFileStatus.Processing
      });

      downloadBurntMediaFromJob(updatedMedia, jobId, analyticsData);
      break;

    case PusherAction.BurnToGoogleDriveComplete:
      await fetchMedia(mediaId);

      if (jobId) {
        downloadQueueStore.completeQueueJob(mediaId, jobId);
      }

      break;

    case PusherAction.MediaTranslate:
      await fetchMedia(mediaId);

      break;

    case PusherAction.BurnFail:
      if (!mediaId) {
        return;
      }

      notificationError(EN.error.defaultMessage);

      await fetchMedia(mediaId);
      break;

    default:
      break;
  }
};

const handleCommentPusher = async ({ data }: { data?: PusherCommentData }) => {
  if (!data) {
    return;
  }

  const { action } = data;

  switch (action) {
    case PusherAction.NewComment:
      if (data.comment) {
        commentsStore.add(data.comment);
      }

      break;

    case PusherAction.EditComment:
      const { comment } = data;
      if (comment) {
        commentsStore.updateComment(comment.id, comment);
      }

      break;

    case PusherAction.DeleteComment:
      const { commentId } = data;
      if (commentId) {
        commentsStore.remove(commentId);
      }

      break;

    case PusherAction.DeleteReply:
      const { replyId } = data;
      if (replyId) {
        commentsStore.remove(replyId);
      }

      break;

    default:
      break;
  }
};

interface PresenceMeData {
  id: string;
  info: {
    username: string;
    name: string;
    photo: string;
  };
}

interface PresenceConnectData {
  count: number;
  me: PresenceMeData;
  members: {
    [id: string]: {
      username: string;
      name: string;
      photo: string;
    };
  };
  myID: string;
}

const handleAccountPresenceConnect = async ({
  userId,
  data,
  clientTrigger
}: {
  userId: string;
  data?: PresenceConnectData;
  clientTrigger: (
    event: PusherEvent,
    data: PresenceMeData | PresenceUserUpdateData
  ) => void;
}) => {
  if (!data) {
    return;
  }

  const mediaId = mediaQuery.getActiveId() as string;
  const joinedAt = mediaStore.getValue()?.loadedAt;

  let hasMembers = false;
  if (data.members) {
    for (const [key, value] of Object.entries(data.members)) {
      hasMembers = true;
      userPresenceStore.updateUser(key, value);
    }
  }

  if (hasMembers) {
    clientTrigger(PusherEvent.RequestSync, {
      ...data.me,
      id: userId
    });
  }
  // This gets processed on load anyway
  if (mediaId) {
    clientTrigger(PusherEvent.HasOpened, {
      userId,
      mediaId,
      joinedAt
    });
  }
};

interface PresenceMemberChangeData {
  id: string;
  info: {
    username: string;
    name: string;
    photo: string;
  };
}

enum MemberChange {
  Added = "added",
  Removed = "removed"
}
const handleAccountPresenceMemberChange = async ({
  event,
  userId,
  data
}: {
  event: MemberChange;
  userId: string;
  data?: PresenceMemberChangeData;
}) => {
  if (!data) {
    return;
  }

  const { id, info } = data;

  if (id !== userId) {
    return;
  }

  switch (event) {
    case MemberChange.Added:
      userPresenceStore.updateUser(id, info);
      break;
    case MemberChange.Removed:
      userPresenceStore.removeUser(id);
      break;
  }
};

interface PresenceUserUpdateData {
  userId: string;
  mediaId?: string;
  joinedAt?: Date;
}
enum UserUpdate {
  OpenedMedia = "OpenedMedia",
  SavedMedia = "SavedMedia",
  ClosedMedia = "ClosedMedia"
}
const handleAccountPresenceUserUpdate = async ({
  event,
  data
}: {
  event: UserUpdate;
  data?: PresenceUserUpdateData;
}) => {
  if (!data) {
    return;
  }

  const { userId, mediaId, joinedAt } = data;

  switch (event) {
    case UserUpdate.OpenedMedia:
      if (userId && mediaId && joinedAt) {
        userPresenceStore.updateUser(userId, {
          mediaId,
          joinedAt: new Date(joinedAt)
        });
      }
      break;
    case UserUpdate.ClosedMedia:
      if (userId) {
        userPresenceStore.updateUser(userId, {
          mediaId: undefined,
          joinedAt: undefined
        });
      }
      break;
  }
};

const setupAccountPresence = ({
  accountId,
  userId
}: {
  accountId: string;
  userId: string;
}) => {
  const presenceAccountChannel = usePresenceChannel(`presence-${accountId}`);
  const clientTrigger = useClientTrigger(presenceAccountChannel.channel);

  const { mediaId = "" } = useParams();

  const [lastMedia, setLastMedia] = useState("");

  const handleClientTrigger = (
    eventName: string,
    data: PresenceMeData | PresenceUserUpdateData
  ) => {
    if (!config.features.hasUserPresence) {
      return;
    }

    clientTrigger(eventName, data);
  };

  useEffect(() => {
    if (mediaId && !lastMedia) {
      setLastMedia(mediaId);
      handleClientTrigger(PusherEvent.HasOpened, {
        userId,
        mediaId,
        joinedAt: new Date()
      });
    } else if (!mediaId && lastMedia) {
      handleClientTrigger(PusherEvent.HasClosed, { userId });
      setLastMedia("");
    }

    return () => {
      if (!mediaId) {
        handleClientTrigger(PusherEvent.HasClosed, {
          userId
        });
      }
    };
  }, [mediaId]);

  useEvent(
    presenceAccountChannel.channel,
    PusherEvent.PresenceConnect,
    async (data?: PresenceConnectData) => {
      await handleAccountPresenceConnect({
        data,
        userId,
        clientTrigger: handleClientTrigger
      });
    }
  );

  useEvent(
    presenceAccountChannel.channel,
    PusherEvent.MemberAdded,
    async (data?: PresenceMemberChangeData) => {
      await handleAccountPresenceMemberChange({
        event: MemberChange.Added,
        data,
        userId
      });
    }
  );

  useEvent(
    presenceAccountChannel.channel,
    PusherEvent.MemberRemoved,
    async (data?: PresenceMemberChangeData) => {
      await handleAccountPresenceMemberChange({
        event: MemberChange.Removed,
        data,
        userId
      });
    }
  );

  useEvent(
    presenceAccountChannel.channel,
    PusherEvent.RequestSync,
    async (data?: PresenceMeData) => {
      const mediaId = mediaQuery.getActiveId() as string;
      const loadedAt = mediaStore.getValue()?.loadedAt;

      if (data?.id && data?.info) {
        userPresenceStore.updateUser(data.id, data.info);
      }

      if (mediaId) {
        clientTrigger(PusherEvent.HasOpened, {
          userId,
          mediaId,
          joinedAt: loadedAt
        });
      }
    }
  );

  useEvent(
    presenceAccountChannel.channel,
    PusherEvent.HasOpened,
    async (data?: PresenceUserUpdateData) => {
      await handleAccountPresenceUserUpdate({
        event: UserUpdate.OpenedMedia,
        data
      });
    }
  );

  useEvent(
    presenceAccountChannel.channel,
    PusherEvent.HasClosed,
    async (data?: PresenceUserUpdateData) => {
      await handleAccountPresenceUserUpdate({
        event: UserUpdate.ClosedMedia,
        data
      });
    }
  );
};

export const Pusher: React.FC<PusherProps> = ({ accountId, userId }) => {
  const accountChannel = useChannel(accountId);
  const userChannel = useChannel(userId);
  const { analyticsData } = useAnalyticsWithAuth();

  setupAccountPresence({ accountId, userId });

  useEvent(accountChannel, PusherEvent.Media, async (data?: PusherMediaData) =>
    handleMediaPusher({ data, userId, analyticsData })
  );

  const handleBillingPusher = async (data?: PusherBillingData) => {
    if (!data) {
      return;
    }

    const { action, freeCredit, paidCredit, extraCredit, paygCredit } = data;

    switch (action) {
      case PusherAction.BillingChange:
        const creditFreeSeconds = freeCredit || 0;
        const creditPaidSeconds = paidCredit || 0;
        const creditExtraSeconds = extraCredit || 0;
        const creditPaygSeconds = paygCredit || 0;
        const totalCredit =
          creditFreeSeconds +
          creditPaidSeconds +
          creditExtraSeconds +
          creditPaygSeconds;

        accountStore.update({
          credit: {
            loading: true,
            loaded: false,
            free: creditFreeSeconds,
            paid: creditPaidSeconds,
            extra: creditExtraSeconds,
            payg: creditPaygSeconds,
            total: totalCredit
          }
        });

        await getCustomer({ force: true });
    }
  };

  useEvent(accountChannel, PusherEvent.Billing, handleBillingPusher);

  const handleUserPusher = async (data?: PusherUserData) => {
    if (!data) {
      return;
    }

    if (data.action === PusherAction.NewInvitation) {
      const { invitationId, accountName } = data;

      const handleAccept = async () => {
        await acceptInvitation(invitationId, accountName);
      };

      notificationWarning(
        <>
          You've been invited to join the <strong>{accountName}</strong>{" "}
          workspace.{" "}
          <Link
            to="#"
            onClick={handleAccept}
            className="font-weight-normal underline text-dark"
          >
            Accept invite
          </Link>
          .
        </>
      );
    }
  };
  useEvent(userChannel, PusherEvent.User, handleUserPusher);

  const handleNotificationPusher = async (data?: PusherNotificationData) => {
    if (!data) {
      return;
    }

    if (data.action === PusherAction.NewNotification) {
      await getAllNotifications();
    }
  };
  useEvent(userChannel, PusherEvent.Notification, handleNotificationPusher);

  const handleAccountPusher = async (data?: PusherAccountData) => {
    if (!data) {
      return;
    }

    if (data.action === PusherAction.TeamCapacityChange) {
      const { teamCapacity } = data;

      if (teamCapacity == null) {
        return;
      }

      accountStore.update({ teamCapacity });
    }

    if (data.action === PusherAction.AccountChange) {
      await getCustomer({ force: true });
      await getCredit({ force: true });
    }
  };
  useEvent(accountChannel, PusherEvent.Account, handleAccountPusher);

  useEvent(
    accountChannel,
    PusherEvent.Comment,
    async (data?: PusherCommentData) => handleCommentPusher({ data })
  );

  return null;
};

interface SharedMediaPusherProps {
  mediaId: string;
  accountId: string;
  userId: string;
}

export const SharedMediaPusher: React.FC<SharedMediaPusherProps> = ({
  userId,
  accountId,
  mediaId
}) => {
  const mediaChannel = useChannel(mediaId);
  const accountChannel = useChannel(accountId);
  const { analyticsData } = useAnalyticsWithAuth();

  setupAccountPresence({ accountId, userId });

  useEvent(
    accountChannel,
    PusherEvent.Media,
    async (data?: PusherMediaData) => {
      if (data?.mediaId === mediaId) {
        await handleMediaPusher({ data, userId, analyticsData });
      }
    }
  );

  useEvent(
    mediaChannel,
    PusherEvent.Comment,
    async (data?: PusherCommentData) => handleCommentPusher({ data })
  );

  return <></>;
};
