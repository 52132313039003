import { mdiChevronDown } from "@mdi/js";
import Icon from "@mdi/react";
import { useObservable } from "@mindspace-io/react";
import classNames from "classnames";
import React from "react";
import { NavLink } from "react-router-dom";
import {
  Dropdown,
  DropdownButton,
  DropdownDivider,
  DropdownItem,
  DropdownMenu
} from "../../../components/dropdown/dropdown";
import { LoadingIcon } from "../../../components/loading-icon/loading-icon";
import { useAccounts } from "../../../hooks/use-accounts";
import { useFolders } from "../../../hooks/use-folders";
import { useModal } from "../../../hooks/use-modal";
import { usePlan } from "../../../hooks/use-plan";
import { ModalType } from "../../../interfaces/modal-type";
import { updateFolder } from "../../../state/folders/folders.service";
import { mediaQuery, DashboardFilter, mediaStore } from "../../../state/media";
import { DeleteFolderModal } from "../sidebar/delete-folder-modal";
import { RenameFolderModal } from "./rename-folder-modal";
import styles from "./dashboard-nav.module.scss";

interface DashboardNavProps {
  isUpdating: boolean;
}
export const DashboardNav: React.FC<DashboardNavProps> = ({ isUpdating }) => {
  const [filter] = useObservable(
    mediaQuery.select("dashboardFilter"),
    DashboardFilter.All
  );

  return (
    <nav className="navbar justify-content-between align-items-start my-2">
      <div className="flex-grow-1">
        <FolderOptionsDropdown />

        <ul className={classNames("nav flex-row", styles.nav)}>
          <DashboardFilterNavLink
            selectedFilter={filter}
            filter={DashboardFilter.All}
          >
            All
          </DashboardFilterNavLink>
          <DashboardFilterNavLink
            selectedFilter={filter}
            filter={DashboardFilter.Video}
          >
            Video
          </DashboardFilterNavLink>
          <DashboardFilterNavLink
            selectedFilter={filter}
            filter={DashboardFilter.Audio}
          >
            Audio
          </DashboardFilterNavLink>

          {isUpdating && (
            <div className="alert mb-0 p-0 d-flex align-items-center absolute">
              <LoadingIcon />{" "}
              <span className="d-none d-sm-flex">Checking for updates...</span>
            </div>
          )}
        </ul>
      </div>
    </nav>
  );
};

interface DashboardFilterNavLinkProps {
  filter: DashboardFilter;
  selectedFilter: DashboardFilter;
}
const DashboardFilterNavLink: React.FC<DashboardFilterNavLinkProps> = ({
  filter,
  selectedFilter,
  children
}) => {
  return (
    <NavLink
      to="#"
      onClick={() => mediaStore.setDashboardFilter(filter)}
      className={classNames("nav-link py-2 px-0 mr-2 mr-sm-4", {
        [styles.active]: filter === selectedFilter
      })}
    >
      {children}
    </NavLink>
  );
};

const FolderOptionsDropdown: React.FC = () => {
  const [showRenameModal, hideRenameModal] = useModal(ModalType.RenameFolder);
  const [showDeleteFolderModal, hideDeleteFolderModal] = useModal(
    ModalType.DeleteFolder
  );
  const { isPremium } = usePlan();
  const { user, isAdmin } = useAccounts();
  const { activeFolder, selectedId } = useFolders();
  const dropdownRef = React.useRef(null);
  const [media] = useObservable(mediaQuery.selectAllFiltered(), []);

  if (!activeFolder) {
    const name = selectedId === null ? "Recent" : "All files";

    return (
      <div className={classNames("dropdown user-select-none", styles.dropdown)}>
        <DropdownButton
          dropdownEl={dropdownRef}
          className={classNames(
            styles["dropdown-button"],
            styles["dropdown-button-disabled"]
          )}
        >
          {name}
        </DropdownButton>
      </div>
    );
  }

  const handleRenameFolder = () => {
    if (!activeFolder?.id) {
      return;
    }

    showRenameModal(
      <RenameFolderModal folder={activeFolder} hideModal={hideRenameModal} />
    );
  };

  const handleMoveToShared = () => {
    updateFolder({ ...activeFolder, isPublic: true });
  };

  const handleDeleteFolder = () => {
    if (!activeFolder?.id) {
      return;
    }

    const folderHasFilesCreatedByAnother = media.some(
      (m) => m.userId !== user?.id && m.folderId === activeFolder.id
    );

    showDeleteFolderModal(
      <DeleteFolderModal
        folder={activeFolder}
        close={hideDeleteFolderModal}
        folderHasFilesCreatedByAnother={folderHasFilesCreatedByAnother}
      />
    );
  };

  const hasMoveToSharedFolder = !activeFolder.isPublic && isPremium;
  const canDelete = activeFolder?.userId === user?.id || isAdmin;

  return (
    <div
      ref={dropdownRef}
      className={classNames("dropdown user-select-none", styles.dropdown)}
    >
      <Dropdown>
        <DropdownButton
          dropdownEl={dropdownRef}
          className={styles["dropdown-button"]}
          isOpenClassName={styles["dropdown-button--open"]}
        >
          {activeFolder.name} <Icon path={mdiChevronDown} size="1.2em" />
        </DropdownButton>

        <DropdownMenu
          title={activeFolder.name}
          className={styles["dropdown-menu"]}
        >
          <DropdownItem onClick={handleRenameFolder}>
            Rename folder
          </DropdownItem>

          {hasMoveToSharedFolder && (
            <DropdownItem onClick={handleMoveToShared}>
              Move to shared folders...
            </DropdownItem>
          )}

          <DropdownDivider />
          <DropdownItem
            onClick={handleDeleteFolder}
            isDangerous
            disabled={!canDelete}
          >
            Delete folder
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};
