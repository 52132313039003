import { mdiCheck } from "@mdi/js";
import Icon from "@mdi/react";
import classNames from "classnames";
import React, { MutableRefObject, useContext, useState } from "react";
import { Link, LinkProps } from "react-router-dom";
import { useAnalyticsWithAuth } from "../../hooks/use-analytics-with-auth";
import { useOnClickOutside } from "../../hooks/use-on-click-outside";
import { AnyOnClickHandler } from "../../interfaces/types";
import { CloseIcon } from "../icons/icons";
import styles from "./dropdown.module.scss";

export const Dropdown: React.FC = ({ children }) => {
  return <DropdownProvider>{children}</DropdownProvider>;
};

interface DropdownButtonProps {
  dropdownEl: MutableRefObject<HTMLElement | null>;
  className?: string;
  isOpenClassName?: string;
  disabled?: boolean;
  mixpanelEvent?: string;
}
export const DropdownButton: React.FC<DropdownButtonProps> = ({
  dropdownEl,
  className,
  isOpenClassName = "",
  disabled = false,
  mixpanelEvent,
  children
}) => {
  const { isOpen, toggleOpen } = useContext(DropdownContext);
  const { trackEventWithAuth } = useAnalyticsWithAuth();

  useOnClickOutside(dropdownEl, () => toggleOpen(false));

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    if (mixpanelEvent) {
      trackEventWithAuth(mixpanelEvent);
    }

    if (disabled) {
      return;
    }

    toggleOpen((s) => !s);
  };

  return (
    <div
      className={classNames(className, "cursor-pointer", {
        "is-open": isOpen,
        [isOpenClassName]: isOpen
      })}
      onClick={(e) => handleClick(e)}
    >
      {children}
    </div>
  );
};

interface DropdownMenuProps {
  isRight?: boolean;
  isUp?: boolean;
  className?: string;
  title?: string;
  hasOverflow?: boolean;
  forceClose?: number;
  animate?: "up" | "down" | "none";
}
export const DropdownMenu: React.FC<DropdownMenuProps> = ({
  isRight,
  isUp,
  className,
  title,
  hasOverflow = false,
  forceClose,
  animate = "up",
  children
}) => {
  const { isOpen, toggleOpen } = useContext(DropdownContext);

  React.useEffect(() => {
    toggleOpen(false);
  }, [forceClose, toggleOpen]);

  return (
    <div
      className={classNames(className, "dropdown-menu", {
        show: isOpen,
        "dropdown-menu-right": isRight,
        "dropdown-menu-up": isUp,
        "animate animate-slide-up": animate === "up",
        "animate animate-slide-down": animate === "down"
      })}
    >
      {title && (
        <div className="dropdown-header">
          <p>{title}</p>
          <button
            className={styles["editor-button"]}
            onClick={() => toggleOpen(false)}
          >
            <CloseIcon />
          </button>
        </div>
      )}
      <div
        className={classNames("dropdown-menu-container", {
          "has-overflow": hasOverflow
        })}
      >
        {children}
      </div>
    </div>
  );
};

type DropdownItemProps = {
  to?: LinkProps["to"];
  blank?: boolean;
  className?: string;
  onClick?: AnyOnClickHandler;
  disabled?: boolean;
  closeOnClick?: boolean;
  selected?: boolean;
  showSelectedIcon?: boolean;
  isDangerous?: boolean;
  checkMarkColour?: string;
  state?: unknown;
} & (
  | {
      blank: true;
      to: string;
    }
  | {
      blank?: false;
      to?: LinkProps["to"] | string;
    }
);
export const DropdownItem: React.FC<DropdownItemProps> = ({
  to,
  blank,
  className,
  onClick,
  disabled = false,
  closeOnClick = true,
  selected = false,
  showSelectedIcon = false,
  isDangerous = false,
  checkMarkColour,
  state,
  children
}) => {
  const { toggleOpen } = useContext(DropdownContext);

  const handleClick: AnyOnClickHandler = (e, props) => {
    e.stopPropagation();
    if (disabled) {
      return;
    }

    if (closeOnClick) {
      toggleOpen(false);
    }

    if (onClick) {
      onClick(props);
    }
  };

  const klass = classNames(
    "dropdown-item",
    styles["dropdown-item"],
    className,
    {
      disabled,
      [styles.selected]: selected,
      [styles["is-dangerous"]]: isDangerous
    }
  );

  const isChildrenString = typeof children === "string";

  if (blank) {
    return (
      <a
        href={to as string}
        target="_blank"
        rel="noopener noreferrer"
        onClick={(e) => handleClick(e)}
        className={klass}
      >
        {isChildrenString ? <span>{children}</span> : children}
        {showSelectedIcon && selected && (
          <span>
            <Icon path={mdiCheck} size="1.2rem" />
          </span>
        )}
      </a>
    );
  }

  if (to) {
    return (
      <Link
        to={to}
        onClick={(e) => handleClick(e)}
        className={klass}
        state={state}
      >
        {isChildrenString ? <span>{children}</span> : children}
        {showSelectedIcon && selected && (
          <span>
            <Icon color={checkMarkColour} path={mdiCheck} size="1.2rem" />
          </span>
        )}
      </Link>
    );
  }

  return (
    <div onClick={(e) => handleClick(e)} className={klass}>
      {isChildrenString ? <span>{children}</span> : children}
      {showSelectedIcon && selected && (
        <span>
          <Icon path={mdiCheck} size="1.2rem" />
        </span>
      )}
    </div>
  );
};

interface DropdownDividerProps {
  className?: string;
}
export const DropdownDivider: React.FC<DropdownDividerProps> = ({
  className
}) => {
  return <div className={classNames("dropdown-divider", className)}></div>;
};

interface DropdownContext {
  isOpen: boolean;
  toggleOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DropdownContext = React.createContext<DropdownContext>({
  isOpen: false,
  toggleOpen: () => null
});

export const DropdownProvider: React.FC = ({ children }) => {
  const [isOpen, toggleOpen] = useState(false);

  return (
    <DropdownContext.Provider value={{ isOpen, toggleOpen }}>
      {children}
    </DropdownContext.Provider>
  );
};
