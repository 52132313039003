import axios from "axios";
import config from "../config";
import { getAccessToken } from "./auth.service";
import { referralQuery } from "../state/referral/referral.query";
import { referralStore } from "../state/referral/referral.store";
import { handleError } from "./handle-error";
import { Affiliate, Referral } from "../interfaces/referral";

const referralURL = `${config.apiUrl}/api/v1/referral`;

interface GetReferralsOptions {
  force?: boolean;
}
interface GetReferralsResponse {
  affiliate: Affiliate;
  visitors: number;
  referrals: Referral[];
}
export const getReferrals = async (
  options: GetReferralsOptions = { force: false }
): Promise<void> => {
  const state = referralQuery.getValue();

  if (state.loaded && !options.force) {
    return;
  }

  referralStore.update({ loaded: false });

  const {
    data: { affiliate, referrals, visitors }
  } = await axios.get<GetReferralsResponse>("", {
    baseURL: referralURL,
    headers: { "x-access-token": await getAccessToken() }
  });

  referralStore.update({
    affiliate,
    referrals,
    visitors,
    loaded: true
  });
};

interface SendReferralsResponse {
  sent: number;
}
export const sendReferrals = async (
  emails: string[]
): Promise<number | undefined> => {
  try {
    const { data } = await axios.post<SendReferralsResponse>(
      "/send",
      { emails },
      {
        baseURL: referralURL,
        headers: { "x-access-token": await getAccessToken() }
      }
    );

    return data.sent;
  } catch (error) {
    handleError(error);
  }
};
