import { ModalType } from "../interfaces/modal-type";
import { modalStore } from "../state/modal/modal.store";

type UseModal = [
  (component: JSX.Element) => void,
  () => void,
  () => void,
  (component: JSX.Element) => void
];

export const useModal = (type: ModalType): UseModal => {
  const showModal = (component: JSX.Element) => {
    modalStore.showModal(type, component);
  };

  const hideModal = () => {
    modalStore.hideModal(type);
  };

  const hideAll = () => {
    modalStore.hideAll();
  };

  const toggleModal = (component: JSX.Element) => {
    modalStore.toggleModal(type, component);
  };

  return [showModal, hideModal, hideAll, toggleModal];
};
