import {
  Artwork,
  AspectRatioConfig,
  AssStyle,
  CustomLogoConfig,
  ProgressBarConfig,
  ProgressType
} from "@getsubly/common";

export type AudioTemplateStyle = Pick<
  AssStyle,
  "alignment" | "marginL" | "marginR" | "marginV" | "colorPrimary" | "fontSize"
>;

export type AudioTemplate = AudioTemplateStyle & {
  templateId: string;
  headline: {
    style: AudioTemplateStyle;
  };
  artwork: Artwork;
  customLogo: CustomLogoConfig;
  aspectRatio: Pick<AspectRatioConfig, "color">;
  noLogoHeadline?: Pick<AssStyle, "marginL" | "marginR" | "marginV">;
};

export interface ProgressBarStyle {
  backgroundColor: string;
  backgroundOpacity: number;
  barOpacity: number;
  barColor: string;
  width: number;
  height: number;
}

export interface ProgressBar {
  id: ProgressType;
  Component: React.FC<
    Omit<ProgressBarConfig, "position" | "padding" | "visible">
  >;
  percentage: number;
  isCircle?: boolean;
  styles: ProgressBarStyle;
}

export enum EditorToolbar {
  Subtitles = "Subtitles",
  Logo = "Logo",
  Headline = "Headline",
  Translations = "Translations",
  Elements = "Elements",
  Speakers = "Speakers",
  Snippets = "Snippets"
}

export enum EditorView {
  Editor,
  Timeline
}

export enum CommentsSortType {
  Recent = "Sort by most recent",
  Timeline = "Sort by timeline"
}
