import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";
import { FileDropCard } from "../../containers/dashboard/upload-modal/file-drop-card";
import { useMediaInfo } from "../../hooks/use-media-info";
import { useModal } from "../../hooks/use-modal";
import { usePermissions } from "../../hooks/use-permissions";
import { usePlan } from "../../hooks/use-plan";
import { SublyPlan } from "../../interfaces/billing";
import { ModalType } from "../../interfaces/modal-type";
import { PLANS_PATH } from "../../routes";
import { uploadStore } from "../../state/upload";
import { getAcceptedFormats } from "../../utils/mime-types";
import styles from "./upload-modal.module.scss";

interface UploadModalFileSectionProps {
  initialFiles: File[];
}

export const UploadModalFileSection: React.FC<UploadModalFileSectionProps> = ({
  initialFiles
}) => {
  const { hasPermission: hasProPermission } = usePermissions(SublyPlan.Pro100);
  const { convertFileToUploadFile } = useMediaInfo();
  const { isProOrHigher, isPremiumOrHigher, isPayg } = usePlan();

  React.useEffect(() => {
    if (initialFiles.length) {
      handleSetFiles(initialFiles);
    }
  }, [initialFiles]);

  const handleSetFiles = async (files: File[], isGoogleDrive?: boolean) => {
    if (!files) {
      return;
    }
    if (!isPremiumOrHigher && !isPayg) {
      uploadStore.resetUpload();
    }
    uploadStore.setChecking(true);
    for await (const file of files) {
      const uploadFile = await convertFileToUploadFile(file, {
        isGoogleDrive
      });
      if (uploadFile) {
        uploadStore.addFiles([uploadFile]);
      }
    }

    uploadStore.setChecking(false);
  };

  return (
    <>
      <FileDropCard
        accept={getAcceptedFormats(isProOrHigher, isPayg)}
        setFiles={(files, isGoogleDrive) =>
          handleSetFiles(files ?? [], isGoogleDrive)
        }
        className={classNames(
          "upload-zone show-upload-zone",
          styles["upload-zone"]
        )}
        multiple={isPremiumOrHigher || isPayg}
      />
      <UploadPermissionLabel hasPermission={hasProPermission} />
    </>
  );
};

interface UploadPermissionLabelProps {
  hasPermission: boolean;
}
const UploadPermissionLabel: React.FC<UploadPermissionLabelProps> = ({
  hasPermission
}) => {
  const [, hideModal] = useModal(ModalType.Upload);

  const linkToPlans = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation();
    hideModal();
  };

  if (!hasPermission) {
    return (
      <span className="caption text-center">
        Free accounts allows .mp4 and .mov files.{" "}
        <Link
          className={classNames(
            "text-secondary caption-link",
            styles.plansLink
          )}
          to={PLANS_PATH}
          onClick={linkToPlans}
        >
          Upgrade to Subly Pro
        </Link>{" "}
        to upload .mp3 files.
      </span>
    );
  }

  return (
    <span className="caption text-center">
      We currently support .mp4 and .mov files, and .mp3 audio files.
    </span>
  );
};
