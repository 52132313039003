import classNames from "classnames";
import React from "react";
import ReactTooltip, { Place, TooltipProps } from "react-tooltip";
import styles from "./tooltip.module.scss";

interface ToolTipProps extends TooltipProps {
  text: string | JSX.Element;
  hide?: boolean;
  place?: Place;
  className?: string;
  forceRebuild?: boolean; // Sometimes in Modals the tooltip doesn't show, so force rebuild will enable this
}
export const ToolTip: React.FC<ToolTipProps> = ({
  text,
  hide,
  children,
  place = "bottom",
  effect = "solid",
  offset = { top: 0 },
  forceRebuild = false,
  className,
  ...props
}) => {
  const [id] = React.useState(String(Math.random()));

  React.useEffect(() => {
    if (!forceRebuild) {
      return;
    }

    setTimeout(() => {
      ReactTooltip.rebuild();
    }, 10);
  }, [forceRebuild]);

  if (hide) {
    return <>{children}</>;
  }

  return (
    <>
      <div data-tip data-for={id} className={styles["parent"]}>
        {children}
      </div>
      <ReactTooltip
        id={id}
        arrowColor="var(--dark)"
        place={place}
        effect={effect}
        offset={offset}
        className={classNames(styles["tooltip"], className)}
        {...props}
      >
        {text}
      </ReactTooltip>
    </>
  );
};
