import classNames from "classnames";
import React from "react";
import styles from "./segmented-control.module.scss";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface SegmentedOption<T = any> {
  id: number;
  label: JSX.Element | string;
  value: T;
}
interface SegmentedControlProps {
  className?: string;
  selectedClassName?: string;
  value: SegmentedOption;
  options: SegmentedOption[];
  onChange: (option: SegmentedOption) => void;
}
export const SegmentedControl: React.FC<SegmentedControlProps> = ({
  className,
  selectedClassName,
  value,
  options,
  onChange
}) => {
  const renderOptions = React.useMemo(
    () =>
      options.map((o) => {
        return (
          <SegmentedButton
            option={o}
            key={o.value}
            onClick={onChange}
            className={classNames(
              {
                [selectedClassName ?? styles["selected"]]:
                  value.value === o.value
              },
              { [styles["button--right"]]: o.id === 1 }
            )}
          />
        );
      }),
    [value.value]
  );

  return (
    <div
      className={classNames("btn-group", styles["container"], className)}
      role="group"
    >
      {renderOptions}
    </div>
  );
};

interface SegmentedButtonProps {
  option: SegmentedOption;
  className?: string;
  onClick: (option: SegmentedOption) => void;
}
const SegmentedButton: React.FC<SegmentedButtonProps> = ({
  option,
  className,
  onClick
}) => {
  return (
    <button
      type="button"
      className={classNames("btn", styles["button"], className)}
      onClick={() => onClick(option)}
    >
      {option.label}
    </button>
  );
};
