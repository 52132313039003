import { useObservable } from "@mindspace-io/react";
import { format } from "date-fns";
import React from "react";
import { LoadingIcon } from "../../../components/loading-icon/loading-icon";
import { Table } from "../../../components/table/table";
import { Invoice } from "../../../interfaces/billing";
import { accountQuery } from "../../../state/account";

interface InvoiceTableProps {
  skipDescription?: boolean;
  limit?: number;
}
export const InvoiceTable: React.FC<InvoiceTableProps> = ({
  skipDescription = false,
  limit
}) => {
  const [{ invoices, loading }] = useObservable(
    accountQuery.select(["loading", "invoices"]),
    { loading: true, invoices: [] }
  );

  const rows = invoices.slice(0, limit).map((invoice) => {
    return (
      <tr key={invoice.id}>
        <td>{format(new Date(invoice.created), "dd/MM/yyyy")}</td>

        {!skipDescription && <td>{getInvoiceDescription(invoice)}</td>}

        <td className="text-uppercase">
          {invoice.total / 100} {invoice.currency}
        </td>
        <td>
          <a
            href={invoice.hosted_invoice_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            View
          </a>
        </td>
      </tr>
    );
  });

  return (
    <div className="mt-4">
      <Table striped>
        <InvoiceTableHeader skipDescription={skipDescription} />

        <tbody>
          {loading ? (
            <tr>
              <td>
                <LoadingIcon />
              </td>
            </tr>
          ) : (
            rows
          )}
        </tbody>
      </Table>
    </div>
  );
};

const InvoiceTableHeader: React.FC<InvoiceTableProps> = ({
  skipDescription = false
}) => {
  return (
    <thead>
      <tr>
        <th scope="col">Date</th>
        {!skipDescription && <th scope="col">Description</th>}
        <th scope="col">Total</th>
        <th scope="col" />
      </tr>
    </thead>
  );
};

const getInvoiceDescription = (invoice: Invoice): string => {
  if (invoice.description) {
    return invoice.description;
  }
  if (invoice.lines && invoice.lines[0]?.description) {
    return invoice.lines[0].description;
  }
  return "No description";
};
