import React from "react";
import { PlanInterval } from "../interfaces/billing";

interface PricingIntervalToggleContext {
  pricingToggle: PlanInterval;
  setPricingToggle: React.Dispatch<React.SetStateAction<PlanInterval>>;
}

export const PricingIntervalToggleContext =
  React.createContext<PricingIntervalToggleContext>({
    pricingToggle: PlanInterval.Yearly,
    setPricingToggle: () => null
  });

export const PricingIntervalToggleProvider: React.FC = ({ children }) => {
  const [pricingToggle, setPricingToggle] = React.useState(PlanInterval.Yearly);

  return (
    <PricingIntervalToggleContext.Provider
      value={{
        pricingToggle,
        setPricingToggle
      }}
    >
      {children}
    </PricingIntervalToggleContext.Provider>
  );
};
