import Quote1 from "../../assets/images/wretch-32-quote.png";
import Quote2 from "../../assets/images/Bee-Gees-quote.png";
import Quote3 from "../../assets/images/Elton-John-quote.png";

export interface CancelQoutes {
  src: string;
  name: string;
}

const QUOTE_ONE: CancelQoutes = {
  src: Quote1,
  name: "Wretch 32"
};

const QUOTE_TWO: CancelQoutes = {
  src: Quote2,
  name: "Bee Gees"
};

const QUOTE_THREE: CancelQoutes = {
  src: Quote3,
  name: "Elton John"
};

export const QUOTES = [QUOTE_ONE, QUOTE_TWO, QUOTE_THREE];
