import { mdiLoading } from "@mdi/js";
import Icon from "@mdi/react";
import React from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../../contexts/auth.context";
import { useDataLayer } from "../../hooks/use-data-layer";
import { useQuery } from "../../hooks/use-query";
import {
  DASHBOARD_PATH,
  LOGIN_PATH,
  TRIAL_ONBOARDING_PATH
} from "../../routes";
import { URLStateToPlan } from "../../utils/plans";
import { extractErrorDescription } from "../../utils/strings";
import styles from "./auth.module.scss";

interface Params {
  state?: string;
  code: string;
  error_description?: string;
}
export const Oauth: React.FC = () => {
  const { oauthLogin } = React.useContext(AuthContext);
  const [userIsNew, setUserIsNew] = React.useState(false);
  const [isLoggedIn, setLoggedIn] = React.useState(false);
  const [hasError, setHasError] = React.useState(false);
  const {
    code,
    state: queryState,
    error_description: errorDescription
  } = useQuery<Params>();
  const dataLayer = useDataLayer();

  React.useEffect(() => {
    const getToken = async () => {
      if (!code) {
        return;
      }

      const { success, data } = await oauthLogin(code, queryState);

      if (!success) {
        setHasError(true);
        return;
      }

      const { analyticsEvent } = URLStateToPlan(queryState);

      const isNewUser = Boolean(data?.isNewUser);
      const trialInvitationPage = !Boolean(data?.trialOnboarding) && isNewUser;

      if (isNewUser) {
        dataLayer("New User");

        setUserIsNew(trialInvitationPage);

        if (!analyticsEvent) {
          // We use this event with Premium on GA and we should keep it
          // to make things easier for marketing. It is just a label.
          // Please don't rename this event from "Is Trial on Premium"!
          dataLayer("Is Trial on Premium");
        }
      }

      setLoggedIn(true);
    };

    getToken();
  }, [code]); // eslint-disable-line

  if (!code) {
    const error = extractErrorDescription(errorDescription);

    if (error) {
      return (
        <Navigate
          to={{
            pathname: LOGIN_PATH
          }}
          state={{
            alertMessage: error
          }}
        />
      );
    }

    return <Navigate to={LOGIN_PATH} />;
  }

  if (userIsNew) {
    return <Navigate to={TRIAL_ONBOARDING_PATH} />;
  }

  if (isLoggedIn) {
    const redirectState = URLStateToPlan(queryState);

    if (redirectState.pathname) {
      return (
        <Navigate
          to={{
            pathname: redirectState.pathname
          }}
          state={redirectState}
        />
      );
    }

    return <Navigate to={DASHBOARD_PATH} />;
  }

  if (hasError) {
    return (
      <Navigate
        to={{
          pathname: LOGIN_PATH
        }}
        state={{
          alertMessage:
            "There was a problem authenticating you. Please try again"
        }}
      />
    );
  }

  return (
    <div className={styles.container}>
      <h4 className="mb-4 text-center">We are preparing your account</h4>
      <p className="text-center">
        <Icon path={mdiLoading} spin size="1.2rem" className="mr-2" /> Please
        wait a moment...
      </p>
    </div>
  );
};
