import { AxiosError } from "axios";
import { ErrorResponse } from "../interfaces/error-response";

export const getErrorMessageForDisplay = (error: AxiosError): string => {
  //Back end's API's ErrorResponse object
  if (error?.response?.data?.error?.code) {
    const errorResponse = error?.response?.data?.error as ErrorResponse;

    let errorCodes = errorResponse?.code || errorResponse?.reason || "";

    if (errorResponse?.code && errorResponse?.reason) {
      errorCodes = `${errorResponse?.code} - ${errorResponse?.reason}`;
    }

    const errorMessage = `${errorResponse.message} (${errorCodes})`;

    return "Error: " + errorMessage;
  }

  return "Internal error: please try again or contact support@getsubly.com";
};
