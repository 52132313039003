import React from "react";
import { AnalyticsContext } from "../contexts/analytics";
import { SublyPlan } from "../interfaces/billing";
import { UnknownObject } from "../interfaces/types";
import { getPlanNameLabel } from "../utils/plans";
import { useAccounts } from "./use-accounts";
import { usePlan } from "./use-plan";
import { useUser } from "./use-user";

type AnalyticsWithAuth = (
  eventName: string,
  data?: UnknownObject | undefined
) => void;

export const useAnalyticsWithAuth = (): {
  trackEventWithAuth: AnalyticsWithAuth;
  analyticsData: UnknownObject;
} => {
  const {
    isPro,
    isPremium,
    isBusiness,
    isTrial,
    plan: userPlan,
    isPayg
  } = usePlan();
  const { isLoaded } = useAccounts();
  const { user } = useUser();
  const { trackEvent } = React.useContext(AnalyticsContext);
  const [buffer, setBuffer] = React.useState<Array<[string, UnknownObject]>>(
    []
  );

  // Force Pay as you go plan if it is pay as you go
  const plan = isPayg ? SublyPlan.PAYG : userPlan;
  const label = getPlanNameLabel(plan);

  // Force trial label to plan name
  const planName = isTrial ? `${label} (trial)` : label;

  // Forces account to be loaded before tracking
  React.useEffect(() => {
    if (!isLoaded || !buffer.length) {
      return;
    }

    buffer.forEach(([eventName, data]) => {
      trackEventWithAuth(eventName, { ...data, buffer: true });
    });

    setBuffer([]);
  }, [isLoaded, buffer.length]);

  React.useEffect(() => {
    if (!isLoaded) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const clarity = (window as any)?.clarity;

    if (clarity == null) {
      return;
    }

    if (isTrial) {
      clarity("set", "isTrial", "true");
    }

    clarity("set", "planName", planName);
  }, [isLoaded, planName, isTrial]);

  const analyticsData = {
    plan,
    isPro,
    isPremium,
    isBusiness,
    isTrial,
    isPayg,
    planName
  };

  const trackEventWithAuth = (eventName: string, data: UnknownObject = {}) => {
    if (user?.email.includes("getsubly.com")) {
      return;
    }

    // If account is not loaded but signed in (user), add events to the
    // buffer array to fire once the account loading finishes.
    if (!isLoaded && user) {
      setBuffer([...buffer, [eventName, data]]);
      return;
    }

    // If account is not loaded and there's no user, send events without auth data
    if (!isLoaded && !user) {
      trackEvent(eventName, data);
      return;
    }

    trackEvent(eventName, {
      ...analyticsData,
      ...data
    });
  };

  return {
    trackEventWithAuth,
    analyticsData
  };
};
