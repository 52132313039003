import classNames from "classnames";
import React, { useState } from "react";
import { uploadFiles } from "../../../api/media.service";
import { Button } from "../../../components/button/button";
import { Fieldset } from "../../../components/form-controls/fieldset";
import { ZoomIcon } from "../../../components/icons/icons";
import { InsufficientCreditAlert } from "../../../components/upload-modal/insufficient-credit-alert";
import { UploadModalAlert } from "../../../components/upload-modal/upload-modal-alert";
import settings from "../../../config/settings/settings";
import {
  setUserSettings,
  userLanguage
} from "../../../config/settings/settings.service";
import { useAnalyticsWithAuth } from "../../../hooks/use-analytics-with-auth";
import { useFolders } from "../../../hooks/use-folders";
import { useMediaInfo } from "../../../hooks/use-media-info";
import { usePermissions } from "../../../hooks/use-permissions";
import { usePlan } from "../../../hooks/use-plan";
import { useUpload } from "../../../hooks/use-upload";
import { SublyPlan } from "../../../interfaces/billing";
import { uploadQuery, uploadStore } from "../../../state/upload";
import { parseCreditToText } from "../../../utils/plans";
import { Modal } from "../../modal/modal";
import { FilesList } from "./files-list";
import { ProjectLocationDropdown } from "./project-location-dropdown";
import styles from "./zoom-upload-modal.module.scss";

export interface ZoomFile {
  fileId: string;
  meetingId: number;
  name: string;
  type: string;
  duration: number;
  size: number;
}

interface ZoomUploadModalProps {
  initialFiles: ZoomFile[];
  closeModal: (goToDashboard?: boolean) => void;
}

export const ZoomUploadModal: React.FC<ZoomUploadModalProps> = ({
  initialFiles = [],
  closeModal
}) => {
  return (
    <Modal
      onDismiss={closeModal}
      className={styles.modal}
      closeIconClassName={styles.close}
    >
      <ZoomUploadModalContainer
        initialFiles={initialFiles}
        closeModal={closeModal}
      />
    </Modal>
  );
};

// Separated the container of the modal to a new component, so it
// doesn't rerender the whole Modal when things change
const ZoomUploadModalContainer: React.FC<ZoomUploadModalProps> = ({
  initialFiles = [],
  closeModal
}) => {
  const [selectedLanguage, setSelectedLanguage] = useState<string>(
    userLanguage()
  );
  const {
    plan,
    isProOrHigher,
    isPremiumOrHigher,
    isPayg,
    availableSeconds,
    availableStorage
  } = usePlan();
  const { trackEventWithAuth } = useAnalyticsWithAuth();
  const { loading, convertZoomToUploadFile } = useMediaInfo();

  const { activeFolder } = useFolders();
  const [folder, setFolder] = useState(activeFolder);

  const { hasPermission } = usePermissions(SublyPlan.Pro100);

  const { languages } = settings.transcription;

  const {
    queue,
    totalCount,
    totalDuration,
    totalSize,
    isChecking,
    uploadList
  } = useUpload();
  const totalDurationLabel = parseCreditToText(
    totalDuration,
    false,
    false,
    true
  );
  const minutesLeft = parseCreditToText(availableSeconds, false, false);
  const hasInsufficientCredit = totalDuration > availableSeconds;
  const hasInsufficientStorage = totalSize > availableStorage;

  React.useEffect(() => {
    if (initialFiles.length && !queue.length) {
      handleSetFiles(initialFiles);
    }
  }, [initialFiles]);

  const handleSetFiles = async (files: ZoomFile[]) => {
    if (!files) {
      return;
    }
    if (!isPremiumOrHigher && !isPayg) {
      uploadStore.resetUpload();
    }
    uploadStore.setChecking(true);
    for await (const file of files) {
      const uploadFile = await convertZoomToUploadFile({
        meetingId: file.meetingId,
        fileId: file.fileId,
        filename: file.name ?? "Test",
        fileSize: file.size,
        fileType: file.type,
        duration: file.duration,
        isAudio: file.type.includes("audio")
      });
      if (uploadFile) {
        uploadStore.addFiles([uploadFile]);
      }
    }

    uploadStore.setChecking(false);
  };

  const renderLanguages = languages.map((l) => (
    <option value={l.code} key={l.code}>
      {l.language}
    </option>
  ));

  const handleSelectedLanguage = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const language = e.target.value;

    setSelectedLanguage(language);
    setUserSettings({ language });
  };

  const handleUpload = async () => {
    if (!hasPermission) {
      return;
    }

    uploadStore.removeErrorFiles();

    if (!queue) {
      return;
    }

    uploadStore.update({
      language: selectedLanguage,
      folderId: folder?.id
    });

    // Do not put "await" before function because the modal would stay opened
    // noinspection ES6MissingAwait
    uploadFiles(plan, isProOrHigher, trackEventWithAuth);
    closeModal(true);
  };

  const disableUpload =
    !hasPermission ||
    uploadQuery.upload.queue.filter((f) => !f.error).length === 0 ||
    loading ||
    hasInsufficientCredit ||
    hasInsufficientStorage;

  return (
    <>
      <div className={styles.icon}>
        <ZoomIcon />
        <h5>Import from Zoom and transcribe</h5>
      </div>

      <div>
        {/* File upload area */}
        <div className={classNames(styles.totals)}>
          <label className="small">
            Available credit: <b>{minutesLeft}</b>
          </label>
          <label className="small ml-2">
            Selected files: <b>{totalDurationLabel}</b>
          </label>
        </div>
        <InsufficientCreditAlert
          creditRequired={totalDuration - availableSeconds}
          files={uploadList.length}
          hasInsufficientCredit={hasInsufficientCredit}
          onUpgrade={closeModal}
        />
        <FilesList
          files={uploadList}
          isChecking={isChecking}
          removeFile={(id) => uploadStore.removeFile(id)}
        />
        <UploadModalAlert
          count={totalCount}
          credit={hasInsufficientCredit}
          storage={hasInsufficientStorage}
          onUpgrade={closeModal}
        />
        {/* Upload form information area */}
        <div className={classNames("mt-4", styles["form"])}>
          <div className="row">
            <Fieldset
              className={classNames("col-6", styles.label)}
              title="File location"
            >
              <ProjectLocationDropdown setFolder={setFolder} folder={folder} />
            </Fieldset>
            <Fieldset className={classNames("col-6")} title="Language spoken">
              <select
                className="form-control custom-select"
                value={selectedLanguage}
                onChange={handleSelectedLanguage}
              >
                {renderLanguages}
              </select>
            </Fieldset>
          </div>

          {/* Hide this for now */}
          {/* <AdvancedOptions disableBulkUrl={true} /> */}

          <div className="mt-3 d-flex justify-content-end">
            <Button
              secondary
              className={classNames(
                "mb-sm-0 mr-2 upload-btn",
                styles["cancel-btn"]
              )}
              onClick={closeModal}
            >
              Cancel
            </Button>
            <Button
              primary
              disabled={disableUpload}
              className={classNames("mb-sm-0 upload-btn", styles["upload-btn"])}
              onClick={handleUpload}
              noLink
            >
              Upload and transcribe
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
