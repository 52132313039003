import React from "react";
import { useOnboarding } from "../../contexts/onboarding.context";

export const OnboardingSteps: React.FC = () => {
  const { currentStep, totalSteps } = useOnboarding();

  return (
    <span className="small ml-2">
      {currentStep}/{totalSteps}
    </span>
  );
};
