import classNames from "classnames";
import numeral from "numeral";
import React from "react";
import { Button } from "../button/button";
import { Divider } from "../divider/divider";
import { usePlan } from "../../hooks/use-plan";
import styles from "./storage-checkout-card.module.scss";
import { differenceInMonths, format } from "date-fns";
import { Subscription } from "../../interfaces/billing";
import {
  getPlanData,
  isMonthlyPlan,
  STORAGE_AMOUNT,
  STORAGE_PRICE_MONTHLY,
  STORAGE_PRICE_YEARLY
} from "../../utils/plans";
import { getVAT } from "../../utils/vat-rates";
import { useAccounts } from "../../hooks/use-accounts";
import { PaymentCardIcon, SuccessIcon } from "../icons/icons";
import { Link } from "react-router-dom";
import { BILLING_PATH } from "../../routes";
import { formatBytes } from "../../utils/storage-size";
import { useStripe } from "@stripe/react-stripe-js";
import { purchaseStorage } from "../../api/billing.service";
import { EN } from "../../assets/i18n/en";
import { notificationError } from "../notification";
import { LoadingIcon } from "../loading-icon/loading-icon";
import { accountStore } from "../../state/account";
import { uploadQuery, uploadStore } from "../../state/upload";

const HIDE_CHANGE_PAYMENT_METHOD = false;
interface StorageCheckoutCardProps {
  subscription: Subscription;
  quantity: number;
  isModal?: boolean;
  setSuccess?: (success: boolean) => void;
  setShowStorageOption?: (show: boolean) => void;
}

export const StorageCheckoutCard: React.FC<StorageCheckoutCardProps> = ({
  subscription,
  quantity,
  isModal = false,
  setSuccess,
  setShowStorageOption
}) => {
  const stripe = useStripe();
  const { plan } = usePlan();
  const isMonthly = isMonthlyPlan(plan);
  const { paymentMethods: cards = [], billing } = useAccounts();
  const [country, setCountry] = React.useState<string>("");
  const [submitting, setSubmitting] = React.useState(false);

  const credit = billing?.details?.balance ?? 0;
  const billingCountry = billing?.details?.address?.country ?? "";

  const nextInvoiceDatePhrase = subscription?.nextInvoiceDate
    ? format(new Date(subscription?.nextInvoiceDate), "MMMM dd, yyyy")
    : "";
  React.useEffect(() => {
    setCountry(billingCountry);
  }, [billingCountry]);

  const purchaseEnabled = Boolean(cards[0]) && Boolean(billing) && quantity > 0;

  const taxRate = getVAT(country) || 0;
  const showTax = taxRate > 0;

  //check if they have prev bought storage
  let currentExtraStorageUnits = 0;

  if (subscription.extraItems?.length != 0) {
    currentExtraStorageUnits = subscription.extraItems?.[0].units ?? 0;
  }

  const totalQuantity = quantity + currentExtraStorageUnits;

  const proRateMonths = differenceInMonths(
    new Date(subscription.nextInvoiceDate),
    new Date()
  );
  const proRateCost = STORAGE_PRICE_MONTHLY * quantity * proRateMonths;

  //Price of storage purchasing
  const storagePriceTotal = isMonthly
    ? STORAGE_PRICE_MONTHLY * quantity
    : proRateCost;
  const totalStorage = STORAGE_AMOUNT * quantity;

  const total = Math.max(storagePriceTotal * (1 + taxRate) - credit, 0);

  const purchase = async (): Promise<void> => {
    if (!stripe) {
      throw new Error(
        "There is an issue, please contact support@getsubly.com."
      );
    }

    const methodId = cards[0]?.id;

    setSubmitting(true);

    try {
      if (!quantity) {
        throw new Error("Quantity not set");
      }

      if (!methodId) {
        throw new Error("No card information");
      }

      if (!billing) {
        throw new Error("No billing information");
      }

      const response = await purchaseStorage(subscription?.id, {
        units: quantity,
        methodId
      });

      if (response.status === "requires_action" && response.nextAction) {
        const { error } = await stripe.confirmCardPayment(
          response.nextAction.clientSecret,
          {
            payment_method: response.nextAction.payment
          }
        );

        if (error?.message) {
          throw new Error(error.message);
        }
      }
      accountStore.update({ loading: true });

      if (uploadQuery.getValue().queue.length) {
        uploadStore.removeErrorFiles();
      }

      setSuccess && setSuccess(true);
    } catch (e) {
      let errorMessage = e.message;

      if (e?.response) {
        errorMessage =
          e.response.data?.error?.reason ?? e.response.data?.message;
      }

      notificationError(errorMessage ?? EN.error.defaultMessage);
      setSuccess && setSuccess(false);
    } finally {
      setShowStorageOption && setShowStorageOption(false);
      setSubmitting(false);
    }
  };

  return (
    <div className={styles["checkout-card"]}>
      {!isModal && <h6>Add extra storage</h6>}

      <BillingCycleInvoice
        subscription={subscription}
        taxRate={taxRate}
        totalQuantity={totalQuantity}
        isMonthly={isMonthly}
        credit={credit}
      />

      <div className={styles["checkout-card-rollover"]}>
        <span>
          Payment using card ending in{" "}
          <span className={styles["last4"]}>{cards[0].last4}</span>
        </span>

        {HIDE_CHANGE_PAYMENT_METHOD && (
          <div className="d-flex mt-1">
            <Link to={BILLING_PATH}>
              <PaymentCardIcon className="mr-2" />
              <span>Change payment method</span>
            </Link>
          </div>
        )}
      </div>

      <Divider className="my-1 bg-white" />

      {!isMonthly && (
        <div className={styles["checkout-card-rollover"]}>
          <span>
            Amount due is pro-rated to the remaining months of your yearly
            billing cycle.
          </span>
        </div>
      )}

      <div className={styles["checkout-card-total"]}>
        <div className={styles["checkout-card-total-row"]}>
          {isMonthly ? (
            <p>Extra storage</p>
          ) : (
            <p className="mb-1">
              Extra storage until: <br /> {nextInvoiceDatePhrase}
            </p>
          )}

          <span
            className={styles["checkout-card-total-row-grand-total"]}
          >{`${totalStorage} GB`}</span>
        </div>

        <div className={styles["checkout-card-total-row"]}>
          <p>Subtotal</p>
          <p>$ {numeral(storagePriceTotal).format("0.00")}</p>
        </div>

        {credit > 0 && (
          <div className={styles["checkout-card-total-row"]}>
            <p>Credit</p>
            <p>-$ {numeral(credit).format("0.00")}</p>
          </div>
        )}

        {showTax && (
          <div className={styles["checkout-card-total-row"]}>
            <p>Tax ({numeral(taxRate * 100).format("0")}%)</p>
            <p>$ {numeral(storagePriceTotal * taxRate).format("0.00")}</p>
          </div>
        )}

        <Divider className="my-2 bg-white" />

        <div className={styles["checkout-card-total-row"]}>
          <p>Total {!isMonthly && "due now"}</p>
          <span className={styles["checkout-card-total-row-grand-total"]}>
            $ {numeral(total).format("0.00")}
          </span>
        </div>
        <Button
          primary
          className={styles["pay-btn"]}
          disabled={!purchaseEnabled}
          onClick={purchase}
          loading={submitting}
          type="submit"
        >
          Add to subscription
        </Button>
      </div>
    </div>
  );
};

interface BillingCycleInvoice {
  subscription: Subscription;
  totalQuantity: number;
  taxRate: number;
  isMonthly?: boolean;
  credit?: number;
}
export const BillingCycleInvoice: React.FC<BillingCycleInvoice> = ({
  subscription,
  totalQuantity,
  taxRate,
  isMonthly,
  credit = 0
}) => {
  const { plan } = usePlan();
  const planData = getPlanData(plan);
  const nextInvoiceDatePhrase = subscription?.nextInvoiceDate
    ? format(new Date(subscription?.nextInvoiceDate), "MMMM dd, yyyy")
    : "";

  const totalStorage = STORAGE_AMOUNT * totalQuantity;
  const storagePriceTotal = isMonthly
    ? STORAGE_PRICE_MONTHLY * totalQuantity
    : STORAGE_PRICE_YEARLY * totalQuantity;

  const taxTotal = (planData.price + storagePriceTotal) * taxRate;
  const total = Math.max(
    (planData.price + storagePriceTotal) * (1 + taxRate) - credit,
    0
  );

  return (
    <div className={styles["checkout-card-summary"]}>
      <p className={styles["checkout-card-summary-text"]}>
        Due at the end of your billing cycle on {nextInvoiceDatePhrase}:
      </p>

      <div className={classNames(styles["checkout-card-summary-row"], "mb-2")}>
        <p>${planData.price}</p>
        <p>{planData.name} plan</p>
      </div>

      <div className={classNames(styles["checkout-card-summary-row"], "mb-2")}>
        <p>${numeral(storagePriceTotal).format("0.00")}</p>
        <p>+{`${totalStorage} GB`} storage</p>
      </div>

      <div className={classNames(styles["checkout-card-summary-row"], "mb-2")}>
        <p>${numeral(taxTotal).format("0.00")}</p>
        <p>Tax ({numeral(taxRate * 100).format("0")}%)</p>
      </div>

      <Divider className={styles["checkout-card-summary-divider"]} />
      <div className={classNames(styles["checkout-card-summary-row"], "mb-2")}>
        <p>${numeral(total).format("0.00")}</p>
        <p>{isMonthly ? "Monthly" : "Yearly"} invoice</p>
      </div>
    </div>
  );
};

interface CheckoutSuccessCard {
  loading?: boolean;
}

export const CheckoutSuccessCard: React.FC<CheckoutSuccessCard> = ({
  loading
}) => {
  const { availableStorage, storageBytesUsed } = usePlan();
  const { size, units } = formatBytes(storageBytesUsed);
  const storageUsedText = `${size} ${units}`;

  const available = formatBytes(availableStorage);

  const availableText = `${available.size} ${available.units}`;

  if (loading) {
    return (
      <div className={styles["checkout-card"]}>
        <div className={styles["checkout-card-summary"]}>
          <p className={styles["checkout-card-summary-success"]}>
            Upgrading storage
          </p>
          <p className={styles.balance}>
            <span>Storage used:</span>{" "}
            <span>
              <LoadingIcon primary />
            </span>
          </p>
          <p className={styles.balance}>
            <span>Storage available:</span>{" "}
            <span>
              <LoadingIcon primary />
            </span>
          </p>
        </div>

        <div className={styles["success-box"]}>
          <LoadingIcon primary className="p-4" />
        </div>
      </div>
    );
  }

  return (
    <div className={styles["checkout-card"]}>
      <div className={styles["checkout-card-summary"]}>
        <p className={styles["checkout-card-summary-success"]}>
          Your storage has been increased successfully!
        </p>
        <p className={styles.balance}>
          <span>Storage used:</span> <span>{storageUsedText}</span>
        </p>
        <p className={styles.balance}>
          <span>Storage available:</span> <span>{availableText}</span>
        </p>
      </div>

      <div className={styles["success-box"]}>
        <SuccessIcon />
      </div>
    </div>
  );
};
