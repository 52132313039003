import { mdiChevronLeft } from "@mdi/js";
import Icon from "@mdi/react";
import { useStripe } from "@stripe/react-stripe-js";
import classNames from "classnames";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Coupon,
  createSubscription,
  getCoupon,
  getCustomer,
  getPlans,
  handlePurchaseSuccess,
  updateSubscription
} from "../../../api/billing.service";
import { EN } from "../../../assets/i18n/en";
import { Button } from "../../../components/button/button";
import { SmallDotsIcon } from "../../../components/icons/brand-assets";
import { notificationError } from "../../../components/notification";
import { useAccounts } from "../../../hooks/use-accounts";
import { useDataLayer } from "../../../hooks/use-data-layer";
import { useAnalyticsWithAuth } from "../../../hooks/use-analytics-with-auth";
import { usePlan } from "../../../hooks/use-plan";
import { useUser } from "../../../hooks/use-user";
import {
  CreateSubscriptionResponse,
  PlanInterval,
  SublyPlan,
  UpdateSubscriptionResponse
} from "../../../interfaces/billing";
import { DASHBOARD_PATH, ONBOARDING_PATH, PLANS_PATH } from "../../../routes";
import { getPlanNameLabel, getPlanPrice } from "../../../utils/plans";
import { BillingDetailsCard } from "./cards/billing-card";
import { PaymentMethodCard } from "./cards/payment-card";
import { PlanIntervalCard } from "./cards/plan-interval-card";
import { PromoCodeCard } from "./cards/promo-card";
import { TermsCopy } from "./cards/terms-card";
import { TotalCard } from "./cards/total-card";
import styles from "./plans.module.scss";

interface PlansUpgradeContainerProps {
  subscriptionId?: string;
  plan: SublyPlan;
  interval: PlanInterval;
  coupon?: string;
  redirect?: string;
  analyticsEvent?: string;
}
export const PlansUpgradeContainer: React.FC<PlansUpgradeContainerProps> = ({
  subscriptionId,
  plan,
  interval,
  coupon: couponProps,
  redirect,
  analyticsEvent
}) => {
  const [country, setCountry] = React.useState<string>();
  const [submitting, setSubmitting] = React.useState(false);
  const [coupon, setCoupon] = React.useState<Coupon>();
  const [promoId, setPromoId] = React.useState<string>();
  const [upgradeCredit, setUpgradeCredit] = React.useState(0);
  const { plan: currentPlan, hasTrialed, isTrial } = usePlan();
  const {
    paymentMethods: cards = [],
    billing,
    isLoading,
    subscription,
    plans
  } = useAccounts();

  const [selectedPlan, setSelectedPlan] = React.useState<SublyPlan>(plan);
  const [planInterval, setPlanInterval] = React.useState(interval);
  const [selectedInterval, setSelectedInterval] = React.useState(false);

  const { hasOnboarded } = useUser();
  const { trackEventWithAuth } = useAnalyticsWithAuth();
  const dataLayer = useDataLayer();

  const stripe = useStripe();
  const navigate = useNavigate();

  useEffect(() => {
    // Work out any credit
    if (!subscription || !plans) {
      return;
    }

    const currentPlan = plans.find(({ id }) => id === subscription.productId);
    const newPlan = plans.find((p) => p.name === selectedPlan);

    if (
      !currentPlan ||
      currentPlan.metadata.yearly !== "true" ||
      newPlan?.metadata?.yearly !== "true"
    ) {
      return;
    }

    const daysLeft = Math.floor(
      (new Date(subscription.nextInvoiceDate).getTime() -
        new Date().getTime()) /
        1000 /
        60 /
        60 /
        24
    );

    setUpgradeCredit(Math.round((daysLeft / 365) * currentPlan.amount) * 0.01);
  }, [subscription, plans]);

  useEffect(() => {
    getCustomer();
    getPlans();

    const fetchCoupon = async () => {
      if (couponProps) {
        try {
          const c = await getCoupon(couponProps);
          setCoupon(c.coupon);
          setPromoId(c.promoId);
        } catch (e) {
          console.error("Couldn't fetch coupon....", e);
        }
      }
    };

    fetchCoupon();
  }, [couponProps]);

  const balance = billing?.details?.balance ?? 0;
  const billingCountry = billing?.details?.address?.country;

  useEffect(() => {
    setCountry(billingCountry);
  }, [billingCountry]);

  const skipBilling = Boolean(coupon?.metadata?.cancel_at_end);
  const purchaseEnabled =
    skipBilling ||
    Boolean(cards[0] && Boolean(billing?.details) && selectedInterval);

  const handlePurchase = async () => {
    if (!stripe) {
      throw new Error(
        "There is an issue, please contact support@getsubly.com."
      );
    }

    const product = plans.find((p) => p.name === selectedPlan);
    const methodId = cards[0]?.id;

    const oldPlan = isTrial ? "Trial" : getPlanNameLabel(currentPlan);
    const newPlan = getPlanNameLabel(selectedPlan);

    setSubmitting(true);

    try {
      if (!product) {
        throw new Error("Can't find plan id");
      }

      if (!skipBilling && !methodId) {
        throw new Error("No card information");
      }

      let response: CreateSubscriptionResponse | UpdateSubscriptionResponse;

      if (subscriptionId) {
        response = await updateSubscription(subscriptionId, {
          productId: product.id,
          immediate: true,
          promoId
        });
      } else {
        response = await createSubscription({
          productId: product.id,
          methodId,
          promoId
        });
      }

      if (response.status === "requires_action" && response.nextAction) {
        const { error } = await stripe.confirmCardPayment(
          response.nextAction.clientSecret,
          {
            payment_method: response.nextAction.payment
          }
        );

        if (error?.message) {
          throw new Error(error.message);
        }
      }
      await handlePurchaseSuccess();

      trackEventWithAuth("Checkout - Upgraded plan", {
        oldPlan,
        toPlan: selectedPlan
      });

      dataLayer("Upgraded Membership", { oldPlan, newPlan });
      dataLayer(`${oldPlan} to ${newPlan} paid`);

      if (analyticsEvent) {
        dataLayer(analyticsEvent);
        trackEventWithAuth("Upgraded from code", { event: analyticsEvent });
      }

      // User is under trial and didn't come through with a code straight to checkout
      // eg. https://my.getsubly.com/login?state=premium
      if (isTrial && !analyticsEvent) {
        dataLayer(`Free to ${newPlan} paid`);
        trackEventWithAuth("Upgraded during trial");
      }

      if (oldPlan === SublyPlan.Free) {
        dataLayer("Free to Paid Plan");
      }

      if (selectedPlan === SublyPlan.BusinessYearly) {
        dataLayer("New Business 1000");
      }

      if (!isTrial && hasTrialed) {
        dataLayer("Come Back After Trial or Paid");
        trackEventWithAuth("Upgraded after trial ended");
      }

      if (redirect) {
        navigate(redirect);
      } else if (!hasOnboarded) {
        navigate(ONBOARDING_PATH);
      } else {
        navigate(DASHBOARD_PATH);
      }
    } catch (e) {
      console.error("Upgrade error", e);
      let errorMessage = e.message;

      if (e?.response) {
        errorMessage =
          e.response.data?.error?.reason ?? e.response.data?.message;
      }

      notificationError(errorMessage ?? EN.error.defaultMessage);

      trackEventWithAuth("Checkout - Error while purchasing", {
        toPlan: selectedPlan,
        error: errorMessage
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="container">
      <Link to={PLANS_PATH} className={classNames(styles["link"], "mb-3")}>
        <Icon path={mdiChevronLeft} size="1.2em" /> Back
      </Link>
      <div className="row">
        <div className="col position-relative">
          <SmallDotsIcon
            className={classNames(
              styles.dots,
              "d-none d-md-block position-absolute"
            )}
          />
          <PlanIntervalCard
            plan={selectedPlan}
            plans={plans}
            loading={isLoading}
            setPlan={setSelectedPlan}
            setPlanInterval={setPlanInterval}
            selectedInterval={selectedInterval}
            setSelectedInterval={setSelectedInterval}
          />
          <BillingDetailsCard
            billing={billing?.details}
            loading={isLoading}
            isReady={selectedInterval}
          />
          <PaymentMethodCard
            card={cards[0]}
            loading={isLoading}
            isReady={Boolean(billing?.details?.address) && selectedInterval}
          />
          <PromoCodeCard
            code={couponProps}
            setCoupon={setCoupon}
            setPromoId={setPromoId}
            plan={selectedPlan}
            plans={plans}
          />
          <div className="d-block d-md-none">
            <TotalCard
              amount={getPlanPrice(selectedPlan)}
              interval={planInterval}
              country={country}
              coupon={coupon}
              plan={selectedPlan}
              credit={(balance ? balance : 0) + upgradeCredit}
            />
          </div>

          <TermsCopy coupon={coupon} interval={planInterval} />

          <Button
            primary
            className={styles["purchase-btn"]}
            disabled={!purchaseEnabled}
            onClick={handlePurchase}
            loading={submitting}
            type="submit"
          >
            Upgrade subscription
          </Button>
        </div>

        <div className="col d-none d-md-block">
          <TotalCard
            amount={getPlanPrice(selectedPlan)}
            interval={planInterval}
            country={country}
            coupon={coupon}
            plan={selectedPlan}
            credit={(balance ? balance : 0) + upgradeCredit}
          />
        </div>
      </div>
    </div>
  );
};
