import classNames from "classnames";
import React from "react";
import styles from "./pricing-toggle.module.scss";

export interface PricingToggleProps {
  value: boolean;
  onChange: (value: boolean) => void;
}

export const PricingToggle: React.FC<PricingToggleProps> = ({
  value,
  onChange
}) => {
  return (
    <div className={styles.container} role="tablist">
      <div
        className={classNames(styles.tab, {
          [styles["tab--active"]]: value
        })}
        onClick={() => onChange(!value)}
      >
        <div>Yearly</div>
      </div>
      <div
        className={classNames(styles.tab, {
          [styles["tab--active"]]: !value
        })}
        onClick={() => onChange(!value)}
      >
        <div>Monthly</div>
      </div>
      <span className={styles["price-discount"]}>
        Save 20% on annual subscriptions.
        <br /> Cancel at any time before renewal.
      </span>
    </div>
  );
};
