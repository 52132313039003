import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { startCheckout } from "../../../api/account.service";
import { Coupon } from "../../../api/billing.service";
import { PayAsYouGoUpgradeContainer } from "../../../containers/settings/plans/payg-upgrade-container";
import { PlansUpgradeContainer } from "../../../containers/settings/plans/plans-upgrade-container";
import { AuthContext } from "../../../contexts/auth.context";
import { useAccounts } from "../../../hooks/use-accounts";
import { PlanInterval, SublyPlan } from "../../../interfaces/billing";
import { AppLayout } from "../../../layouts/app.layout";
import { PLANS_PATH } from "../../../routes";
import { getDiscountCode, plansWithDiscount } from "../../../utils/plans";

interface UpgradeState {
  plan?: SublyPlan;
  interval?: PlanInterval;
  quantity?: number;
  redirect?: string;
  coupon: Coupon;
  couponCode?: string;
  analyticsEvent?: string;
  upgradeToPAYG: boolean;
  topup: boolean;
  skipSettingValue?: boolean;
}
export const PlansUpgradePage: React.FC = () => {
  const state = useLocation().state as Partial<UpgradeState>;
  const { partner, isEducation, user } = React.useContext(AuthContext);
  const { subscription } = useAccounts();

  const plan = state?.plan;
  const interval = state?.interval;
  const quantity = state?.quantity ?? 20;
  const topup = state?.topup ?? false;
  const upgradetoPAYG = state?.upgradeToPAYG ?? false;

  React.useEffect(() => {
    if (plan) {
      startCheckout(plan);
    }
  }, [plan]);

  if (upgradetoPAYG || topup) {
    return (
      <AppLayout hideNavbarRight hideTopBanner hideSidebar>
        <PayAsYouGoUpgradeContainer
          topUp={topup}
          quantity={quantity}
          coupon=""
          redirect={state?.redirect}
          analyticsEvent={state?.analyticsEvent}
          skipSettingValue={state?.skipSettingValue}
        />
      </AppLayout>
    );
  }

  // Go back if there is no plan
  if (!plan || !interval) {
    return <Navigate to={PLANS_PATH} />;
  }

  const discountedPlans = plansWithDiscount({
    partner,
    isEducation,
    email: user?.email,
    coupon: state?.coupon,
    couponCode: state?.couponCode
  });

  const hasDiscount = discountedPlans.includes(plan);

  const coupon = getDiscountCode(hasDiscount, {
    partner,
    isEducation,
    email: user?.email,
    coupon: state?.coupon,
    couponCode: state?.couponCode
  });

  return (
    <AppLayout hideNavbarRight hideTopBanner hideSidebar>
      <PlansUpgradeContainer
        subscriptionId={subscription?.id}
        plan={plan}
        interval={interval}
        coupon={coupon}
        redirect={state?.redirect}
        analyticsEvent={state?.analyticsEvent}
      />
    </AppLayout>
  );
};
