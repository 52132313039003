import React from "react";
import { deleteMedia } from "../../api/media.service";
import { Button } from "../../components/button/button";
import { pluralize } from "../../utils/strings";
import { Modal } from "../modal/modal";
import styles from "./delete-media-modal.module.scss";

interface DeleteMediaModalProps {
  mediaIds: string[];
  closeModal: (shouldClear?: boolean) => void;
  isEditor?: boolean;
}

export const DeleteMediaModal: React.FC<DeleteMediaModalProps> = ({
  mediaIds,
  closeModal,
  isEditor = false
}) => {
  return (
    <Modal
      onDismiss={closeModal}
      hasCloseIcon
      closeIconClassName={styles["close-icon"]}
      className={styles.modal}
    >
      <DeleteMediaModalContainer
        mediaIds={mediaIds}
        closeModal={closeModal}
        isEditor={isEditor}
      />
    </Modal>
  );
};

const DeleteMediaModalContainer: React.FC<DeleteMediaModalProps> = ({
  mediaIds,
  closeModal,
  isEditor
}) => {
  const [isLoading, setLoading] = React.useState(false);

  const deleteSingleMedium = async (mediaIds: string[]) => {
    await deleteMedia(mediaIds[0]);
  };

  const deleteMultipleMediums = async (mediaId: string) => {
    await deleteMedia(mediaId);
  };

  const handleDelete = () => {
    setLoading(true);
    if (mediaIds.length === 1) {
      deleteSingleMedium(mediaIds);
    } else {
      mediaIds.forEach(async (mediaId) => await deleteMultipleMediums(mediaId));
    }

    closeModal(true);

    setLoading(false);
  };

  return (
    <>
      <h5 className={styles.header}>
        Delete {pluralize(mediaIds.length, "file")}
      </h5>

      <p>
        Are you sure you would like to permanently delete{" "}
        {isEditor ? (
          "this"
        ) : (
          <span className="font-weight-bold text-black text-break">
            {mediaIds.length}
          </span>
        )}{" "}
        {pluralize(mediaIds.length, "file")}? This cannot be undone.
      </p>
      <div className={styles["button-container"]}>
        <Button
          secondary
          onClick={closeModal}
          className={styles["cancel-button"]}
        >
          Cancel
        </Button>
        <Button danger onClick={handleDelete} loading={isLoading}>
          Delete
        </Button>
      </div>
    </>
  );
};
