import { useObservable } from "@mindspace-io/react";
import React from "react";
import { getCustomer } from "../api/billing.service";
import { WorkspaceLoadingModal } from "../components/workspace-loading-modal";
import { setUserSettings } from "../config/settings/settings.service";
import { ModalType } from "../interfaces/modal-type";
import { AccountSettings, RoleName, UserAccount } from "../interfaces/account";
import {
  BillingDetails,
  Deal,
  PaymentMethod,
  Product,
  Subscription
} from "../interfaces/billing";
import { User } from "../interfaces/user";
import { accountQuery, AccountState } from "../state/account";
import { authQuery } from "../state/auth/auth.query";
import { authStore } from "../state/auth/auth.store";
import { foldersStore } from "../state/folders/folders.store";
import { mediaStore } from "../state/media";
import { useModal } from "./use-modal";

interface UseAccounts {
  user?: User;
  accounts: UserAccount[];
  subscription?: Subscription;
  deal?: Deal;
  currentAccount?: AccountState;
  billing?: {
    loading: boolean;
    loaded: boolean;
    details?: BillingDetails | undefined;
  };
  paymentMethods?: PaymentMethod[];
  isLoading: boolean;
  isLoaded: boolean;
  isLoadingPlans: boolean;
  switchAccount: (accountId: string) => Promise<void>;
  isAdmin: boolean;
  isOwner: boolean;
  settings: AccountSettings;
  plans: Product[];
}

export function useAccounts(): UseAccounts {
  const [showModal, hideModal, hideAll] = useModal(
    ModalType.LoadWorkspaceModal
  );
  const [user] = useObservable(authQuery.selectUser(), authQuery.user);
  const [accounts] = useObservable(
    authQuery.selectAccounts(),
    authQuery.accounts
  );
  const [isLoading] = useObservable(
    accountQuery.select("loading"),
    accountQuery.getValue().loading
  );
  const [isLoaded] = useObservable(
    accountQuery.select("loaded"),
    accountQuery.getValue().loaded
  );
  const [isLoadingPlans] = useObservable(
    accountQuery.select("loadingPlans"),
    accountQuery.getValue().loadingPlans
  );
  const [currentAccount] = useObservable(
    accountQuery.select(),
    accountQuery.getValue()
  );
  const [billing] = useObservable(
    accountQuery.select("billing"),
    accountQuery.getValue().billing
  );
  const [paymentMethods] = useObservable(
    accountQuery.select("paymentMethods"),
    accountQuery.getValue().paymentMethods
  );
  const [settings] = useObservable(
    accountQuery.select("settings"),
    accountQuery.getValue().settings
  );
  const [plans] = useObservable(
    accountQuery.selectPlans(),
    accountQuery.getValue().plans
  );

  const subscription = currentAccount?.subscription;
  const deal = currentAccount?.deal;

  const switchAccount = async (accountId: string) => {
    if (accountId === currentAccount?.accountId) {
      return;
    }

    hideAll();
    showModal(<WorkspaceLoadingModal />);

    foldersStore.set([]);
    mediaStore.set([]);
    authStore.setAccountId(accountId);
    setUserSettings({ workspaceId: accountId });

    await getCustomer({ force: true });

    hideModal();
  };

  const isOwner = Boolean(currentAccount?.isOwner);

  const isAdmin = currentAccount?.role === RoleName.FullAccess || isOwner;

  return {
    user,
    accounts,
    subscription,
    deal,
    currentAccount,
    billing,
    paymentMethods,
    isLoading,
    isLoaded,
    isLoadingPlans,
    switchAccount,
    isAdmin,
    isOwner,
    settings,
    plans
  };
}
