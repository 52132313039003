import thumbnail_one from "../../../assets/images/tutorial-thumbnails/thumbnail-1.jpg";
import thumbnail_two from "../../../assets/images/tutorial-thumbnails/thumbnail-2.jpg";
import thumbnail_three from "../../../assets/images/tutorial-thumbnails/thumbnail-3.jpg";
import thumbnail_four from "../../../assets/images/tutorial-thumbnails/thumbnail-4.jpg";
import thumbnail_five from "../../../assets/images/tutorial-thumbnails/thumbnail-5.jpg";
import thumbnail_six from "../../../assets/images/tutorial-thumbnails/thumbnail-6.jpg";
import thumbnail_seven from "../../../assets/images/tutorial-thumbnails/thumbnail-7.jpg";
import thumbnail_eight from "../../../assets/images/tutorial-thumbnails/thumbnail-8.jpg";
import thumbnail_nine from "../../../assets/images/tutorial-thumbnails/thumbnail-9.jpg";
import thumbnail_ten from "../../../assets/images/tutorial-thumbnails/thumbnail-10.jpg";
import thumbnail_eleven from "../../../assets/images/tutorial-thumbnails/thumbnail-11.jpg";
import thumbnail_twelve from "../../../assets/images/tutorial-thumbnails/thumbnail-12.jpg";
import thumbnail_thirteen from "../../../assets/images/tutorial-thumbnails/thumbnail-13.jpg";
import thumbnail_fourteen from "../../../assets/images/tutorial-thumbnails/thumbnail-14.jpg";
import thumbnail_fifteen from "../../../assets/images/tutorial-thumbnails/thumbnail-15.jpg";
import thumbnail_sixteen from "../../../assets/images/tutorial-thumbnails/thumbnail-16.jpg";

export interface TutorialMedia {
  id: string;
  name: string;
  thumbnail: string;
  filename: string;
}

export const TUTORIAL_ONE: TutorialMedia = {
  id: "1",
  name: "How To Change the Position of My Subtitles",
  thumbnail: thumbnail_one,
  filename: "How-To-Change-the-Position-of-My-Subtitles.mp4"
};

export const TUTORIAL_TWO: TutorialMedia = {
  id: "2",
  name: "How to create and use custom styling templates",
  thumbnail: thumbnail_two,
  filename: "How-to-create-and-use-custom-styling-templates.mp4"
};

export const TUTORIAL_THREE: TutorialMedia = {
  id: "3",
  name: "How To Create Folders and Workspaces",
  thumbnail: thumbnail_three,
  filename: "How-To-Create-Folders-and-Workspaces.mp4"
};

export const TUTORIAL_FOUR: TutorialMedia = {
  id: "4",
  name: "How to Export Only TXT",
  thumbnail: thumbnail_four,
  filename: "How-to-Export-Only-TXT.mp4"
};

export const TUTORIAL_FIVE: TutorialMedia = {
  id: "5",
  name: "How to Export SRT and VTT",
  thumbnail: thumbnail_five,
  filename: "How-to-Export-SRT-and-VTT.mp4"
};

export const TUTORIAL_SIX: TutorialMedia = {
  id: "6",
  name: "What’s the difference between SRT and VTT",
  thumbnail: thumbnail_six,
  filename: "Whats-the-difference-between-SRT-and-VTT.mp4"
};

export const TUTORIAL_SEVEN: TutorialMedia = {
  id: "7",
  name: "How to Export Translated Videos",
  thumbnail: thumbnail_seven,
  filename: "How-to-Export-Translated-Videos.mp4"
};

export const TUTORIAL_EIGHT: TutorialMedia = {
  id: "8",
  name: "How to Generate and Share Public Links",
  thumbnail: thumbnail_eight,
  filename: "How-to-Generate-and-Share-Public-Links.mp4"
};

export const TUTORIAL_NINE: TutorialMedia = {
  id: "9",
  name: "How To Invite Teammates to Subly",
  thumbnail: thumbnail_nine,
  filename: "How-To-Invite-Teammates-to-Subly.mp4"
};

export const TUTORIAL_TEN: TutorialMedia = {
  id: "10",
  name: "How to Move Subtitles to the Border",
  thumbnail: thumbnail_ten,
  filename: "How-to-Move-Subtitles-to-the-Border.mp4"
};

export const TUTORIAL_ELEVEN: TutorialMedia = {
  id: "11",
  name: "How To Remove The Subly Watermark",
  thumbnail: thumbnail_eleven,
  filename: "How-To-Remove-The-Subly-Watermark.mp4"
};

export const TUTORIAL_TWELVE: TutorialMedia = {
  id: "12",
  name: "How to repurpose video for social media",
  thumbnail: thumbnail_twelve,
  filename: "How-to-repurpose-video-for-social-media.mp4"
};

export const TUTORIAL_THIRTEEN: TutorialMedia = {
  id: "13",
  name: "How to resize video for social media",
  thumbnail: thumbnail_thirteen,
  filename: "How-to-resize-video-for-social-media.mp4"
};

export const TUTORIAL_FOURTEEN: TutorialMedia = {
  id: "14",
  name: "How To Share and Edit Projects with Teammates",
  thumbnail: thumbnail_fourteen,
  filename: "How-To-Share-and-Edit-Projects-with-Teammates.mp4"
};

export const TUTORIAL_FIFTEEN: TutorialMedia = {
  id: "15",
  name: "How To Use Find and Replace for Custom Vocabulary",
  thumbnail: thumbnail_fifteen,
  filename: "How-To-Use-Find-Replace-for-Custom-Vocabulary.mp4"
};

export const TUTORIAL_SIXTEEN: TutorialMedia = {
  id: "16",
  name: "How to Use Google Drive in Subly",
  thumbnail: thumbnail_sixteen,
  filename: "How-to-Use-Google-Drive-in-Subly.mp4"
};

export const Tutorials = [
  TUTORIAL_ONE,
  TUTORIAL_TWO,
  TUTORIAL_THREE,
  TUTORIAL_FOUR,
  TUTORIAL_FIVE,
  TUTORIAL_SIX,
  TUTORIAL_SEVEN,
  TUTORIAL_EIGHT,
  TUTORIAL_NINE,
  TUTORIAL_TEN,
  TUTORIAL_ELEVEN,
  TUTORIAL_TWELVE,
  TUTORIAL_THIRTEEN,
  TUTORIAL_FOURTEEN,
  TUTORIAL_FIFTEEN,
  TUTORIAL_SIXTEEN
];
