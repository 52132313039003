import { useObservable } from "@mindspace-io/react";
import classNames from "classnames";
import React, { useContext, useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useForm } from "react-hook-form";
import { getReferrals, sendReferrals } from "../../../../api/referral.service";
import { Button } from "../../../../components/button/button";
import {
  ProfileIcon,
  ReferralPresents,
  TrashCanIcon
} from "../../../../components/icons/icons";
import { Modal } from "../../../../containers/modal/modal";
import { AuthContext } from "../../../../contexts/auth.context";
import { ModalType } from "../../../../interfaces/modal-type";
import { useModal } from "../../../../hooks/use-modal";
import { referralQuery } from "../../../../state/referral/referral.query";
import { emailPattern } from "../../../../utils/regex-patterns";
import { ReferralSentModal } from "../referral-sent-modal/referral-sent-modal";
import styles from "./referral-modal.module.scss";
import { ReferralSocial } from "./referral-social";

interface ReferralModalProps {
  closeModal: () => void;
}

export const ReferralModal: React.FC<ReferralModalProps> = ({ closeModal }) => {
  const [link] = useObservable(
    referralQuery.selectReferralLink(),
    referralQuery.referralLink
  );
  const { user } = useContext(AuthContext);
  const [showModal, hideModal] = useModal(ModalType.ReferralSend);

  useEffect(() => {
    if (link) {
      return;
    }

    getReferrals({ force: false });
  }, [link]);

  const handleClose = (sent?: number) => {
    if (sent) {
      showReferralSentModal(sent);
    }
    closeModal();
  };

  const showReferralSentModal = (sent: number) => {
    showModal(<ReferralSentModal number={sent} closeModal={hideModal} />);
  };

  return (
    <Modal onDismiss={closeModal} className={styles["modal"]} hasCloseIcon>
      <ReferralModalContainer
        name={user?.givenName}
        link={link}
        closeModal={handleClose}
      />
    </Modal>
  );
};

interface ReferralModalContainerProps {
  closeModal: (sent?: number) => void;
  name?: string;
  link?: string;
}
const ReferralModalContainer: React.FC<ReferralModalContainerProps> = ({
  name,
  link,
  closeModal
}) => {
  const [loading, setLoading] = React.useState(false);
  const [emails, setEmails] = useState<string[]>([]);

  const handleAddEmail = (email: string) => {
    setEmails([...emails, email].filter((v, i, a) => a.indexOf(v) === i));
  };

  const handleRemoveEmail = (email: string) => {
    setEmails(emails.filter((e) => e !== email));
  };

  const handleSend = async () => {
    try {
      setLoading(true);
      const sent = await sendReferrals(emails);
      closeModal(sent);
    } catch (e) {
      // setAlert(EN.error.defaultMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ReferralModalHeading />
      <AddReferralEmail onAdd={handleAddEmail} />
      <AddReferralTable emails={emails} removeEmail={handleRemoveEmail} />
      <CopyInviteLink link={link} />

      <div className="d-flex align-items-center">
        <ReferralSocial name={name} link={link} />
        <Button onClick={() => closeModal()} className="ml-auto mr-2">
          Skip for now
        </Button>
        <Button
          primary
          onClick={handleSend}
          loading={loading}
          disabled={!emails.length}
        >
          Share the love
        </Button>
      </div>
    </>
  );
};

const ReferralModalHeading: React.FC = () => {
  return (
    <div className="d-flex mb-2">
      <div>
        <h5 className="mt-2 mb-3">Earn FREE minutes today!</h5>
        <p>Invite a friend and earn minutes when they sign up</p>
      </div>
      <div className="ml-auto mr-2">
        <ReferralPresents />
      </div>
    </div>
  );
};

interface AddReferralTableProps {
  emails: string[];
  removeEmail(email: string): void;
}

const AddReferralTable: React.FC<AddReferralTableProps> = ({
  emails,
  removeEmail
}) => {
  const emailList = emails.map((e, i) => {
    return (
      <div
        key={i}
        className={classNames(
          "d-flex align-items-center mb-2",
          styles["purple-box"]
        )}
      >
        <div className="icon-holder" style={{ paddingRight: 8 }}>
          <ProfileIcon />
        </div>
        {e}
        <div className="ml-auto mr-2">
          <TrashCanIcon
            className={styles["delete-invite"]}
            onClick={() => removeEmail(e)}
          />
        </div>
      </div>
    );
  });

  return <>{emailList}</>;
};

interface AddReferralEmailProps {
  onAdd(email: string): void;
}

const AddReferralEmail: React.FC<AddReferralEmailProps> = ({ onAdd }) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<{
    email: string;
  }>();
  const [email, setEmail] = useState<string>("");

  const handleAddEmail = async ({ email }: { email: string }) => {
    if (email) {
      onAdd(email);
      setEmail("");
    }
  };

  return (
    <form onSubmit={handleSubmit(handleAddEmail)}>
      <div>
        <h6>Email</h6>
        <div className="form-group d-flex align-items-center">
          <input
            type="email"
            className={classNames("form-control", {
              "is-invalid": errors.email
            })}
            value={email}
            {...register("email", { required: true, pattern: emailPattern })}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            secondary
            className={classNames("d-block", styles["add-btn"])}
            type="submit"
          >
            Add
          </Button>
        </div>
      </div>
    </form>
  );
};

interface CopyInviteLinkProps {
  link?: string;
}
const CopyInviteLink: React.FC<CopyInviteLinkProps> = ({ link }) => {
  const [isCopied, setIsCopied] = React.useState(false);

  if (!link) {
    return null;
  }

  const handleCopied = () => {
    setIsCopied(true);

    setTimeout(() => setIsCopied(false), 3000);
  };

  return (
    <CopyToClipboard text={link} onCopy={handleCopied}>
      <p className="text-center text-black mb-0 mt-4 px-2 small">
        {!isCopied && <>or </>}
        <a className="text-black cursor-pointer">
          {isCopied ? <>copied! 😎</> : <>copy an invite link</>}
        </a>
      </p>
    </CopyToClipboard>
  );
};
