import classNames from "classnames";
import React, { useContext } from "react";
import { Modal } from "../../containers/modal/modal";
import { PricingIntervalToggleContext } from "../../contexts/interval-toggle.context";
import { useAnalyticsWithAuth } from "../../hooks/use-analytics-with-auth";
import { usePlan } from "../../hooks/use-plan";
import { SublyPlan } from "../../interfaces/billing";
import { PLANS_PATH, PLANS_UPGRADE_PATH, SALES_CONTACT } from "../../routes";
import { Button } from "../button/button";
import { Input } from "../controls/input";
import { EmailIcon, StopwatchIcon } from "../icons/icons";
import styles from "./out-of-minutes-modal.module.scss";

interface OutOfMinutesModalProps {
  closeModal: () => void;
}

export const OutOfMinutesModal: React.FC<OutOfMinutesModalProps> = ({
  closeModal
}) => {
  return (
    <Modal className={styles["modal"]} onDismiss={closeModal} hasCloseIcon>
      <OutOfMinutesModalContainer closeModal={closeModal} />
    </Modal>
  );
};

export const OutOfMinutesModalContainer: React.FC<OutOfMinutesModalProps> = ({
  closeModal
}) => {
  const { trackEventWithAuth } = useAnalyticsWithAuth();
  const { pricingToggle } = useContext(PricingIntervalToggleContext);
  const { isPayg, isPro, isBusiness, plan } = usePlan();

  if (isPayg) {
    return (
      <>
        <h5>You’re running out of minutes 😢</h5>
        <p>
          Top up now to continue subtitling with ease. Alternatively, browse our
          plans for the best value for minutes for your needs.
        </p>

        <div className={styles["cta-buttons"]}>
          <Button
            secondary
            to={{
              pathname: PLANS_PATH
            }}
            onClick={() => {
              closeModal();
            }}
          >
            Browse Plans
          </Button>

          <Button
            primary
            to={{ pathname: PLANS_UPGRADE_PATH }}
            state={{
              plan: SublyPlan.PAYG,
              quantity: 20,
              topup: true
            }}
            onClick={() => {
              trackEventWithAuth("Out of minutes warning", {
                toPlan: SublyPlan.PAYG
              });
              closeModal();
            }}
          >
            Top up minutes
          </Button>
        </div>
      </>
    );
  }

  if (isPro) {
    return (
      <>
        <h5>You’re running out of minutes 😢</h5>
        <p>
          Looks like you’re running out of minutes. Top up now to create more
          content in Subly or upgrade to Premium for more minutes per month.
        </p>

        <div className={styles["cta-buttons"]}>
          <Button
            secondary
            to={{
              pathname: PLANS_UPGRADE_PATH
            }}
            state={{
              plan: SublyPlan.Premium,
              interval: pricingToggle
            }}
            onClick={() => {
              trackEventWithAuth("Out of minutes warning", {
                toPlan: SublyPlan.Premium
              });
              closeModal();
            }}
          >
            Upgrade to Premium
          </Button>

          <Button
            primary
            to={{ pathname: PLANS_UPGRADE_PATH }}
            state={{
              plan: plan,
              quantity: 20,
              topup: true
            }}
            onClick={() => {
              trackEventWithAuth("Out of minutes warning", {
                toPlan: plan
              });
              closeModal();
            }}
          >
            Top up minutes
          </Button>
        </div>
      </>
    );
  }

  if (isBusiness) {
    return (
      <>
        <h5>You’re running out of minutes 😢</h5>
        <p>
          Looks like you’re running out of minutes. Top up minutes now or
          contact your Subly account manager to increase your monthly minutes.
        </p>

        <div className={styles["contact-sales"]}>
          <p>Want to increase you monthly minutes?</p>

          <Button
            secondary
            to={SALES_CONTACT}
            type="blank"
            onClick={() => {
              trackEventWithAuth("Out of minutes warning - contact sales");
              closeModal();
            }}
          >
            <EmailIcon className={styles["icon"]} />
            Contact your account manager
          </Button>
        </div>

        <div className={styles["cta-buttons"]}>
          <Button
            secondary
            onClick={() => {
              closeModal();
            }}
          >
            Not now
          </Button>

          <Button
            primary
            to={{ pathname: PLANS_UPGRADE_PATH }}
            state={{
              plan: plan,
              quantity: 20,
              topup: true
            }}
            onClick={() => {
              trackEventWithAuth("Out of minutes warning", {
                toPlan: plan
              });
              closeModal();
            }}
          >
            Top up minutes
          </Button>
        </div>
      </>
    );
  }

  return (
    <>
      <h5>You’re running out of minutes 😢</h5>
      <p>
        Looks like you’re running out of minutes. Top up now to create more
        content in Subly.
      </p>

      <div className={styles["cta-buttons"]}>
        <Button
          secondary
          onClick={() => {
            closeModal();
          }}
        >
          Not now
        </Button>

        <Button
          primary
          to={{ pathname: PLANS_UPGRADE_PATH }}
          state={{
            plan: plan,
            quantity: 20,
            topup: true
          }}
          onClick={() => {
            trackEventWithAuth("Out of minutes warning", {
              toPlan: plan
            });
            closeModal();
          }}
        >
          Top up minutes
        </Button>
      </div>
    </>
  );
};

interface PaygMinuteInputProps {
  quantity: number | undefined;
  setQuantity: React.Dispatch<React.SetStateAction<number | undefined>>;
}
export const PayAsYouGoMinuteInput: React.FC<PaygMinuteInputProps> = ({
  quantity,
  setQuantity
}) => {
  return (
    <>
      <div className="d-flex align-items-center position-relative">
        <StopwatchIcon className={styles.stopwatchIcon} />
        <Input
          type="number"
          min="20"
          placeholder="Enter minutes"
          value={quantity ?? ""}
          onChange={({ target }) => {
            setQuantity(target.value ? +target.value : undefined);
          }}
          className={styles["payg-input"]}
        />
      </div>
      <label className={classNames("small", styles["payg-label"])}>
        $0.60 per minute (20 minutes minimum)
      </label>
    </>
  );
};
