/* eslint-disable @typescript-eslint/no-explicit-any */
import MediaInfo, { MediaInfo as MediaInfoInterface } from "mediainfo.js";
import { Track } from "mediainfo.js/dist/types";
import { useState } from "react";
import {
  UploadErrorTypes,
  UploadFile,
  UploadFileBase,
  UploadUrlFile,
  UploadZoomFile
} from "../state/upload";
import { getPlanUploadLimit, maxFileDuration } from "../utils/plans";
import { addHttps, formatMediaName } from "../utils/strings";
import { usePlan } from "./use-plan";
import { v4 } from "uuid";
import { getFileFromURL } from "../api/file.service";
import { getUrlInfo, isUrlInfoError } from "../api/media.service";
import { useAnalyticsWithAuth } from "./use-analytics-with-auth";

const readChunk =
  (file: File) =>
  (chunkSize: number, offset: number): Promise<Uint8Array> | Uint8Array =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        if (event.target.error) {
          reject(event.target.error);
        }

        resolve(new Uint8Array(event.target.result));
      };

      reader.readAsArrayBuffer(file.slice(offset, offset + chunkSize));
    });

let mediaInfo: MediaInfoInterface;

interface IMediaInfo {
  duration: number;
  fileSize: number;
  isAudio: boolean;
  warning?: string;
}

interface UseMediaInfoInterface {
  loading: boolean;
  checkMediaInfo: (file?: File) => Promise<IMediaInfo>;
  checkFileErrors: ({
    duration,
    size
  }: {
    duration: number;
    size: number;
  }) => UploadErrorTypes | undefined;

  convertFileToUploadFile: (
    file: File,
    {
      isUrl,
      isGoogleDrive,
      language
    }: {
      isUrl?: boolean;
      isGoogleDrive?: boolean;
      language?: string;
    }
  ) => Promise<UploadFile | UploadUrlFile | UploadZoomFile | undefined>;
  convertUrlToUploadFile: (
    url: string,
    language?: string
  ) => Promise<UploadFile | UploadUrlFile | UploadZoomFile | undefined>;
  convertZoomToUploadFile: ({
    meetingId,
    fileId,
    filename,
    fileSize,
    fileType,
    duration,
    isAudio
  }: {
    meetingId: number;
    fileId: string;
    filename: string;
    fileSize: number;
    fileType?: string;
    duration: number;
    isAudio?: boolean;
  }) => Promise<UploadFile | UploadUrlFile | UploadZoomFile | undefined>;
}
export function useMediaInfo(): UseMediaInfoInterface {
  const [loading, setLoading] = useState(false);
  const {
    credit,
    plan,
    availableStorage,
    hasCustomUploadLimit,
    maxUploadLimit
  } = usePlan();
  const { trackEventWithAuth } = useAnalyticsWithAuth();

  const checkMediaInfo = async (file?: File): Promise<IMediaInfo> => {
    try {
      setLoading(true);

      if (!file) {
        throw Error("No file");
      }

      if (!mediaInfo) {
        mediaInfo = (await MediaInfo()) as MediaInfoInterface;
      }

      const result = await mediaInfo.analyzeData(
        () => file.size,
        readChunk(file)
      );

      if (typeof result === "string" || !result) {
        throw Error("Can't read file");
      }

      const general: any = result.media.track.find(
        (r: Track) => r["@type"] === "General"
      );

      const duration = general?.Duration ?? 0;
      const fileSize = general?.FileSize ?? 0;

      const videoCount = general?.VideoCount ?? 0;
      const isVideo = +videoCount > 0;
      const isAudio = !isVideo;

      const videoTrack: any = result.media.track.find(
        (r) => r["@type"] === "Video"
      );
      let warning = undefined;
      if (videoTrack?.CodecID === "hvc1" && videoTrack?.Format === "HEVC") {
        warning = "HEVC video may have limited colour range";
      } else if (
        videoTrack?.CodecID === "av01" &&
        videoTrack?.Format === "AV1"
      ) {
        warning = "AV1 encoded video may not be supported";
      }

      return {
        duration: +duration,
        fileSize: +fileSize,
        isAudio,
        warning
      };
    } catch (e) {
      return {
        duration: 0,
        fileSize: 0,
        isAudio: false
      };
    } finally {
      setLoading(false);
    }
  };

  const checkFileErrors = ({
    duration,
    size
  }: {
    duration: number;
    size: number;
  }): UploadErrorTypes | undefined => {
    const availableSeconds = credit?.total ?? 0;

    if (availableStorage < size) {
      return UploadErrorTypes.storage;
    } else if (availableSeconds < duration) {
      return UploadErrorTypes.credit;
    } else if (duration > maxFileDuration) {
      return UploadErrorTypes.duration;
    } else if (maxUploadLimit < size) {
      if (hasCustomUploadLimit) {
        return UploadErrorTypes.fileSize;
      }
      switch (getPlanUploadLimit(plan)) {
        case 0.5:
          return UploadErrorTypes.fileSizeMax500MB;
        case 2:
          return UploadErrorTypes.fileSizeMax2GB;
        case 5:
          return UploadErrorTypes.fileSizeMax5GB;
        default:
          return UploadErrorTypes.fileSize;
      }
    }
    return;
  };

  const convertFileToUploadFile = async (
    file: File,
    {
      isUrl,
      isGoogleDrive
    }: {
      isUrl?: boolean;
      isGoogleDrive?: boolean;
    }
  ): Promise<UploadFile | UploadUrlFile | UploadZoomFile | undefined> => {
    try {
      const { duration, fileSize, isAudio, warning } = await checkMediaInfo(
        file
      );

      return makeUploadFile({
        filename: file.name,
        duration,
        fileSize,
        file,
        isAudio,
        isUrl,
        isGoogleDrive,
        // language,
        warning
      });
    } catch (error) {
      console.error(error);
    }
  };

  const convertUrlToUploadFile = async (
    url: string
  ): Promise<UploadFile | UploadUrlFile | UploadZoomFile | undefined> => {
    try {
      const { pathname } = new URL(addHttps(url));
      const hasExtension = /\.[A-Za-z0-9]{3,4}$/.test(pathname);

      if (hasExtension) {
        const file = await getFileFromURL(url, pathname);
        const uploadFile = await convertFileToUploadFile(file, {
          isUrl: true
        });

        if (uploadFile) {
          return uploadFile;
        }
      }

      const urlInfo = await getUrlInfo(url);

      if (!urlInfo) {
        return;
      }

      if (isUrlInfoError(urlInfo)) {
        return makeUploadFile({
          duration: -1,
          fileSize: -1,
          filename: urlInfo.url,
          ...urlInfo
        });
      }

      return makeUploadFile(urlInfo);
    } catch (error) {
      return;
    }
  };

  const convertZoomToUploadFile = async ({
    meetingId,
    fileId,
    filename,
    fileSize,
    duration,
    isAudio
  }: {
    meetingId: number;
    fileId: string;
    filename: string;
    fileSize: number;
    duration: number;
    isAudio?: boolean;
  }): Promise<UploadFile | UploadUrlFile | UploadZoomFile | undefined> => {
    try {
      return makeUploadFile({
        filename,
        fileSize,
        duration,
        isAudio,
        meetingId,
        fileId
      });
    } catch (error) {
      console.error(error);
    }
  };

  const makeUploadFile = ({
    filename,
    fileSize,
    duration,
    warning,
    error,

    file,
    url,
    isUrl,
    isAudio,
    isGoogleDrive,
    meetingId,
    fileId
  }: {
    filename: string;
    fileSize: number;
    duration: number;
    fileType?: string;
    warning?: string;
    error?: string;

    file?: File;
    url?: string;
    isUrl?: boolean;
    isAudio?: boolean;
    isGoogleDrive?: boolean;
    meetingId?: number;
    fileId?: string;
  }): UploadFile | UploadUrlFile | UploadZoomFile | undefined => {
    error =
      error ||
      checkFileErrors({
        duration,
        size: fileSize
      });

    isUrl = url ? true : isUrl;
    duration = Math.ceil(duration);

    try {
      const fileSizeMB = parseFloat(((fileSize ?? 0) / 1000 ** 2).toFixed(2));
      const durationMinutes = parseFloat(((duration ?? 0) / 60).toFixed(2));

      trackEventWithAuth("Prepare upload file", {
        filename,
        fileSize,
        fileSizeMB,
        duration,
        durationMinutes,
        isAudio,
        isGoogleDrive,
        isUrl,
        url,
        warning,
        error
      });
    } catch (e) {
      console.error(e);
    }

    try {
      const mediaName = formatMediaName(filename).slice(0, 129);

      const base: UploadFileBase = {
        id: v4(),
        mediaName,
        filename,
        isAudio: Boolean(isAudio),
        size: fileSize || 0,
        duration,
        isUploading: false,
        uploaded: false,
        warning,
        error
      };

      if (file) {
        return {
          ...base,
          media: file,
          isGoogleDrive: Boolean(isGoogleDrive)
        };
      } else if (url) {
        return {
          ...base,
          url,
          isUrl: true
        };
      } else if (meetingId && fileId) {
        return {
          ...base,
          mediaName: filename,
          meetingId: meetingId,
          fileId: fileId,
          isZoom: true
        };
      }
    } catch (error) {
      console.error(error);
    }
  };

  return {
    convertUrlToUploadFile,
    convertFileToUploadFile,
    convertZoomToUploadFile,
    checkMediaInfo,
    checkFileErrors,
    loading
  };
}
