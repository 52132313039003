import React from "react";
import { ForgotPassword } from "../containers/auth/forgot-password";
import { AuthLayout } from "../layouts/auth.layout";

export const ForgotPasswordPage: React.FC = () => {
  return (
    <AuthLayout showLogin>
      <ForgotPassword />
    </AuthLayout>
  );
};
