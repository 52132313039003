import classNames from "classnames";
import React from "react";

interface AlertProps {
  success?: boolean;
  danger?: boolean;
  warning?: boolean;
  title?: string;
  closable?: boolean;
  onClose?: () => void;
}
export const Alert: React.FC<AlertProps> = ({
  success,
  danger,
  warning,
  title,
  closable,
  onClose,
  children
}) => {
  return (
    <div
      className={classNames("alert", {
        "alert-success": success,
        "alert-danger": danger,
        "alert-warning": warning
      })}
      role="alert"
    >
      {closable && (
        <button
          type="button"
          className="close"
          data-dismiss="alert"
          aria-label="Close"
          onClick={onClose}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      )}

      {title && <h6 className="alert-heading">{title}</h6>}

      <p className="mb-0">{children}</p>
    </div>
  );
};
