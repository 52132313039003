import {
  EyeIcon,
  MicrophoneIcon,
  VideoCameraIcon
} from "@heroicons/react/outline";
import classNames from "classnames";
import { format, intervalToDuration } from "date-fns";
import React from "react";
import { IZoomRecording, IZoomRecordingFile } from "../../api/apps.service";
import { Badge } from "../../components/badge/badge";
import { Button } from "../../components/button/button";
import { Checkbox } from "../../components/checkbox/checkbox";
import { durationToString } from "../../utils/time";
import styles from "./zoom.module.scss";

interface IZoomMeetingCardProps {
  recording: IZoomRecording;
  selected: string[];
  onChange: (id: string) => void;
}

interface MeetingDetailsProps {
  start: Date;
  end: Date;
  playUrl: string;
}

export const MeetingDetails: React.FC<MeetingDetailsProps> = ({
  start,
  end,
  playUrl
}) => {
  const duration = intervalToDuration({
    start,
    end
  });

  return (
    <div className={styles.box}>
      <div className={classNames(styles.duration, styles.limit)}>
        <div className={styles.label}>Duration</div>
        <div className={styles.value}>{durationToString(duration)}</div>
      </div>
      <div className={classNames(styles.start, styles.limit)}>
        <div className={styles.label}>Start time</div>
        <div className="d-flex flex-row">
          <div className={styles.value}>{format(start, "hh:mm")}</div>
          <div className={classNames(styles.date, "ml-2")}>
            {format(start, "dd/MM/yyyy")}
          </div>
        </div>
      </div>
      <div className={classNames(styles.end, styles.limit)}>
        <div className={styles.label}>End time</div>
        <div className="d-flex flex-row">
          <div className={styles.value}>{format(end, "hh:mm")}</div>
          <div className={classNames(styles.date, "ml-2")}>
            {format(end, "dd/MM/yyyy")}
          </div>
        </div>
      </div>
      <div className={classNames(styles.preview, styles.limit)}>
        <Button
          secondary
          className={styles["preview-button"]}
          type="blank"
          to={playUrl}
        >
          <>
            <EyeIcon />
            Preview
          </>
        </Button>
      </div>
    </div>
  );
};

interface ZoomMeetingTileProps {
  file: IZoomRecordingFile;
  selected: boolean;
  onChange: (checked: boolean) => void;
}

export const ZoomMeetingTile: React.FC<ZoomMeetingTileProps> = ({
  file,
  selected,
  onChange
}) => {
  const isAudio = file.fileType === "M4A";

  return (
    <div className={styles.tile}>
      <div className="d-flex flex-row align-items-center">
        <div className="w-100">
          <div className="d-flex align-items-center">
            <Badge
              className={classNames(styles["type-badge"], {
                [styles["type-badge-audio"]]: isAudio
              })}
            >
              {isAudio ? <MicrophoneIcon /> : <VideoCameraIcon />}
              {isAudio ? "Audio only" : "Video"}
            </Badge>

            <Button
              secondary
              className={classNames(
                styles["preview-button"],
                styles["mobile-preview-button"]
              )}
              type="blank"
              to={file.playUrl}
            >
              <>
                <EyeIcon />
                Preview
              </>
            </Button>
          </div>

          <MeetingDetails
            start={new Date(file.start)}
            end={new Date(file.end)}
            playUrl={file.playUrl}
          />
        </div>
        <Checkbox
          className="ml-5 mr-5"
          value={selected}
          onChange={() => onChange(!selected)}
        />
      </div>
    </div>
  );
};

export const ZoomMeetingCard: React.FC<IZoomMeetingCardProps> = ({
  recording,
  selected,
  onChange
}) => {
  const renderFiles = recording.files.map((f) => (
    <ZoomMeetingTile
      key={f.id}
      file={f}
      selected={selected.some((s) => s === f.id)}
      onChange={() => onChange(f.id)}
    />
  ));

  return (
    <div className={styles.meetingCard}>
      <div className={styles.meetingHeader}>
        <h6 className={styles.topic}>{recording.topic}</h6>
        <span className="ml-auto">
          <span>ID:</span>
          <span className={styles.meetingNumber}>
            {recording.meetingNumber}
          </span>
        </span>
      </div>
      {renderFiles}
    </div>
  );
};
