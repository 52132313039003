export enum ModalType {
  Upload,
  HelpVideo,
  TrialUpgrade,
  UpgradeReach80,
  UpgradeFree95,
  UpgradeGoogleDrive,
  NewStylesTemplate,
  DeleteStylesTemplate,
  RenameStylesTemplate,
  NewRelease,
  PositioningModal,
  DownloadModal,
  InvitationModal,
  InvitationHelp,
  InvitationMediaHelp,
  TranslationDoubleCheck,
  TranslationConfirmation,
  ReferralInvite,
  ReferralSend,
  CustomVocabulary,
  UploadSubtitles,
  CreateWorkspace,
  MoveMedia,
  RenameMedia,
  DeleteMedia,
  RenameFolder,
  DeleteFolder,
  LoadWorkspaceModal,
  UpgradePlan,
  CancelModal,
  ColorModal,
  TrialCornerModal,
  TrialConfirmModal,
  ComingSoon,
  TrialDashboardModal,
  MigrationModal,
  SaveSnippetModal,
  RenameSnippetModal,
  DeleteSnippetModal,
  WelcomeTrialModal,
  TrialLimitReachedModal,
  UpgradePayAsYouGoModal,
  OverdueInvoiceModal,
  ShortcutsModal,
  InactiveUserModal,
  OutOfMinutesModal,
  BatchDuplicatedModal,
  TrialOneDownloadRemainModal,
  TrialDownloadLimitReachedModal,
  SpeakerRecognition,
  FeedBackFormDashboardModal,
  UpgradeToTranslateModal,
  LowStorageModal,
  AppModal,
  ZoomModal,
  TranscribeModal,
  ChooseSubtitleUploadActions
}
