import React from "react";
import { Modal } from "../../containers/modal/modal";
import { Button } from "../button/button";
import { PlanIcon } from "../../containers/settings/plans/plans-table";
import { SublyPlanData } from "../../utils/plans";

interface UpgradePayAsYouGoModalProps {
  plan: SublyPlanData;
  upgrade: () => Promise<void>;
  closeModal: () => void;
}
export const UpgradePayAsYouGoModal: React.FC<UpgradePayAsYouGoModalProps> = ({
  plan,
  upgrade,
  closeModal
}) => {
  return (
    <Modal onDismiss={closeModal} hasCloseIcon>
      <UpgradePayAsYouGoModalContainer
        plan={plan}
        upgrade={upgrade}
        closeModal={closeModal}
      />
    </Modal>
  );
};

const UpgradePayAsYouGoModalContainer: React.FC<
  UpgradePayAsYouGoModalProps
> = ({ plan, upgrade, closeModal }) => (
  <>
    <div className="d-flex justify-content-between">
      <h5>Switch from {plan.name} to Pay as you go</h5>
    </div>
    <span>
      <strong>{plan.name} subscription will be cancelled</strong> effective
      immediately once you click on cancel and switch to Pay as you Go. Existing
      minutes from your {plan.name} account will not be rolled over.
      <br />
      Would you like to cancel {plan.name} and continue with purchasing minutes?
    </span>
    <PlanIcon plan={plan} />
    <div className="mt-4 d-flex justify-content-end">
      <Button tertiary className="mr-2" onClick={closeModal} noLink>
        Cancel
      </Button>
      <Button primary onClick={upgrade} noLink>
        Cancel {plan.name} and buy minutes
      </Button>
    </div>
  </>
);
