import { mdiPencilOutline } from "@mdi/js";
import Icon from "@mdi/react";
import classNames from "classnames";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import countries from "typed-countries";
import {
  AccountType,
  BillingDetailsParams,
  setBillingDetails
} from "../../../../api/billing.service";
import { EN } from "../../../../assets/i18n/en";
import { Button } from "../../../../components/button/button";
import { LoadingIcon } from "../../../../components/loading-icon/loading-icon";
import { notificationError } from "../../../../components/notification";
import { useAnalyticsWithAuth } from "../../../../hooks/use-analytics-with-auth";
import {
  BillingDetails,
  getTaxIdTypeSelectOptions
} from "../../../../interfaces/billing";
import { BillingDetailsForm } from "../../billing-details-card/billing-details-form";
import { BillingDetailsForm as IBillingDetailsForm } from "../../../settings/billing-details-card/add-billing-details-form";

import styles from "../plans.module.scss";
import { reset } from "mixpanel-browser";
import { AuthContext } from "../../../../contexts/auth.context";

interface BillingDetailsCardProps {
  billing?: BillingDetails;
  loading: boolean;
  isReady: boolean;
}

export const BillingDetailsCard: React.FC<BillingDetailsCardProps> = ({
  billing,
  loading,
  isReady
}) => {
  const [submitting, setSubmitting] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);
  const { trackEventWithAuth } = useAnalyticsWithAuth();
  const { user } = React.useContext(AuthContext);
  const form = useForm<IBillingDetailsForm>();

  const taxIdTypeSelectOptions = getTaxIdTypeSelectOptions();

  useEffect(() => {
    const name = billing?.name;
    const address = billing?.address;

    if (billing && name && address) {
      const countryLabel =
        countries.find((c) => c.iso === address.country)?.name ||
        address.country;

      const taxIdTypeSelectOption = taxIdTypeSelectOptions?.find(
        (x) =>
          x.code === billing?.taxId?.type &&
          x.country === billing.taxId?.country
      );

      const initForm: IBillingDetailsForm = {
        name: name,
        address: address.line1,
        city: address.city,
        state: address.state,
        postalCode: address.postalCode,
        country: {
          value: address.country,
          label: countryLabel
        },
        taxIdNumber: billing?.taxId?.value,
        taxIdType: taxIdTypeSelectOptions.find(
          (x) => x.value === taxIdTypeSelectOption?.value
        )
      };

      form.reset(initForm);
    } else if (user?.settings?.location?.country) {
      const userCountry = user?.settings?.location?.country;
      const countryLabel = countries.find((c) => c.iso === userCountry)?.name;

      if (countryLabel) {
        form.reset({
          country: {
            value: userCountry,
            label: countryLabel
          }
        });
      }
    }
  }, [billing, user, reset]);

  if (!isReady) {
    return (
      <div className={classNames(styles["card"])}>
        <div className="d-flex align-items-center justify-content-between">
          <h5 className="mb-0">2. Add billing details </h5>
        </div>
      </div>
    );
  }

  if (!billing && loading) {
    return (
      <div className={classNames(styles["card"])}>
        <div className="d-flex align-items-center justify-content-between mb-4">
          <h5 className="mb-0">2. Add billing details </h5>
        </div>
        <LoadingIcon />
      </div>
    );
  }

  if (billing?.name && billing?.address && isReady && !editMode) {
    const country =
      countries.find((c) => c.iso === billing.address?.country)?.name ||
      billing.address.country;

    return (
      <div className={classNames(styles["card"])}>
        <div className="d-flex align-items-center justify-content-between mb-4">
          <h5 className="mb-0">2. Add billing details</h5>
          <span
            className={styles["edit-icon"]}
            onClick={() => setEditMode(true)}
          >
            <Icon path={mdiPencilOutline} size="1.2rem" />
          </span>
        </div>
        <div>{billing.name}</div>
        <div>{billing.address.line1}</div>
        {billing.address.line2 && <div>{billing.address.line2}</div>}
        <div>
          {billing.address.city} {billing.address.state}{" "}
          {billing.address.postalCode}
        </div>
        <div>{country}</div>
      </div>
    );
  }

  const onSubmit = async ({
    name,
    address: line1,
    city,
    postalCode,
    state,
    country: { value: country },
    taxIdNumber,
    taxIdType
  }: IBillingDetailsForm) => {
    try {
      setSubmitting(true);

      const isBusiness = taxIdType
        ? AccountType.Business
        : AccountType.Personal;

      const billingDetails: BillingDetailsParams = {
        name,
        line1,
        city,
        postalCode,
        state,
        country,
        taxIdNumber: taxIdNumber ?? undefined,
        taxIdType: taxIdType?.code,
        accountType: isBusiness
      };

      await setBillingDetails(billingDetails);

      trackEventWithAuth("Checkout - Added a valid address", { isBusiness });
    } catch (e) {
      notificationError(EN.error.defaultMessage);
    } finally {
      setSubmitting(false);
      setEditMode(false);
    }
  };

  return (
    <div className={classNames(styles["card"], styles["card-editing"])}>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <h5 className="mb-0">2. Add billing details </h5>
      </div>
      <BillingDetailsForm
        useForm={form}
        handleSubmit={form.handleSubmit(onSubmit)}
      >
        <Button
          primary
          className={styles.continue}
          type="submit"
          loading={submitting}
        >
          Continue
        </Button>
      </BillingDetailsForm>
    </div>
  );
};
