import { MediaConfig } from "@getsubly/common";
import { SublyPlan } from "./billing";

export interface Media {
  createdAt: string;
  updatedAt: string;
  mediaId: string;
  accountId: string;
  status: MediaStatus;
  reason: MediaErrorReason;
  name: string;
  files: MediaFile[];
  current: string;
  originalFileId?: string;
  thumbnail: string;
  jobs: MediaJob[];
  assConfig: MediaConfig;
  lastBurnHash?: string;
  subtitlesHash?: string;
  original_file_name: string;
  publicShareId?: string;
  folderId?: string;
  userId: string;
  owner: {
    name: string;
    email: string;
  };
  sharedWithMe?: boolean;
  plan?: SublyPlan;
  sharedUsers?: MediaInvite[];
  settings?: MediaSettings;
}

export interface MediaSettings {
  skippedTranscription?: boolean;
}
export interface BasicMediaExtras {
  sharedWithMe?: boolean;
  plan?: SublyPlan;
}

export interface BasicMedia extends Media {
  id: string;
  videoUrl: string;
  mainFile?: MediaFile;
  posterUrl: string;
  type: MediaFileType;
  latestJob?: MediaJob;
  isBurning?: boolean;
  isTranscribing: boolean;
  burningTasks?: BurningTask[];
  burnProgress?: number;
  language?: string;
  duration?: number;
  fileSize?: number;
  transcriptions: {
    originalId: string;
    original?: MediaLanguage;
    translationsIds: string[];
    translations: MediaLanguage[];
  };
}

export interface MediaJob {
  id: string;
  type: MediaJobType;
  message: string;
  startDate: string;
  endDate: string;
  inputs: string[];
  outputs: string[];
  status: MediaStatus;
  percentage?: number;
  batchId?: string;
}

export interface MediaFile {
  extension: string;
  size_bytes: number;
  uploadedDate: string;
  type: MediaFileType;
  url: string;
  publicUrl?: string;
  filename: string;
  original_filename: string;
  width: number;
  id: string;
  key: string;
  durationSeconds?: number;
  sizeBytes?: number;
  burnt?: boolean;
  burnHash?: string;
  overridden?: boolean;
  height?: number;
  language?: string;
  translated?: boolean;
  downloadName?: string;
  languageId?: string;
}

export interface Language {
  language: string;
  code: string;
}

export interface MediaLanguage {
  language: string;
  code: string;
  id: string;
}

export enum MediaFileType {
  Audio = "AUDIO",
  Video = "VIDEO",
  Thumbnail = "THUMBNAIL",
  SRT = "SRT",
  VTT = "VTT",
  TXT = "TXT",
  ASS = "ASS",
  Transcript = "TRANSCRIPT",
  Subtitle = "SUBTITLE"
}

export enum MediaStatus {
  Uploading = "UPLOADING",
  Uploaded = "UPLOADED",
  Converting = "CONVERTING",
  Pending = "PENDING",
  Ready = "READY",
  Complete = "COMPLETE",
  Archived = "ARCHIVED",
  Deleted = "DELETED",
  Failed = "FAILED",
  Submitted = "SUBMITTED"
}

export enum MediaJobType {
  Upload = "UPLOAD",
  Burn = "BURN",
  Transcribe = "TRANSCRIBE",
  Conversion = "CONVERT",
  Queue = "QUEUE",
  Download = "DOWNLOAD"
}

export enum ProgressProcess {
  FetchingFiles = "Fetching files.",
  PreparingFiles = "Preparing files for edit.",
  ModifyingVideo = "Modifying video.",
  Uploading = "Uploading processed video.",
  CleanUp = "Clean up."
}

export interface BurningTask {
  process: ProgressProcess;
  isBurnTask: false;
  progress: number;
}

export enum MediaErrorReason {
  InsufficientFunds = "InsufficientFunds",
  UploadCancelled = "UploadCancelled",
  ProcessingCancelled = "ProcessingCancelled"
}

export interface MediaInvite {
  shareId: string;
  name: string;
  email: string;
  permission: MediaSharePermission;
  isOwner?: boolean;
  message?: string;
}

export enum MediaSharePermission {
  Edit = "edit",
  View = "view"
}

export interface MediaSharedAsset {
  id: string;
  url: string;
}

export type SubtitleFormat = "srt" | "vtt" | "txt";

export interface MediaComment {
  id: string;
  text: string;
  time: number;
  isResolved: boolean;
  author: {
    id: string;
    name: string;
    picture: string;
  };
  createdAt: string;
  replies: Reply[];
}

export interface Reply {
  id: string;
  text: string;
  author: {
    id: string;
    name: string;
    picture: string;
  };
  createdAt: string;
}
