import classNames from "classnames";
import React from "react";
import { useAccounts } from "../../hooks/use-accounts";
import { useAnalyticsWithAuth } from "../../hooks/use-analytics-with-auth";
import { useModal } from "../../hooks/use-modal";
import { usePermissions } from "../../hooks/use-permissions";
import { SublyPlan } from "../../interfaces/billing";
import { ModalType } from "../../interfaces/modal-type";
import { PlanUpgradeModal } from "../upgrade-modal/plan-upgrade-modal";
import styles from "./lock-feature.module.scss";

interface LockFeatureProps {
  mixpanelEvent?: string;
  skipWithFlag?: boolean;
  minPermission?: SublyPlan;
  className?: string;
}
export const LockFeature: React.FC<LockFeatureProps> = ({
  mixpanelEvent,
  skipWithFlag,
  minPermission = SublyPlan.Pro100,
  className,
  children
}) => {
  const { isLoaded } = useAccounts();
  const [showModal, hideModal] = useModal(ModalType.TrialUpgrade);
  const { hasPermission } = usePermissions(minPermission);
  const { trackEventWithAuth } = useAnalyticsWithAuth();

  if (skipWithFlag === false) {
    return <>{children}</>;
  }

  if (hasPermission) {
    return <>{children}</>;
  }

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();

    // Don't show anything if account is not loaded yet
    if (!isLoaded) {
      return;
    }

    showModal(
      <PlanUpgradeModal upgradePlan={minPermission} closeModal={hideModal} />
    );

    if (mixpanelEvent) {
      trackEventWithAuth(mixpanelEvent);
    }
  };

  return (
    <div className={styles["container"]}>
      {children}
      <div
        className={classNames(styles["lock"], className)}
        onClick={handleClick}
      />
    </div>
  );
};
