import React from "react";
import { BellIcon } from "@heroicons/react/outline";
import {
  Dropdown,
  DropdownButton,
  DropdownMenu
} from "../../components/dropdown/dropdown";
import { Button } from "../../components/button/button";
import greenBell from "../../assets/images/notifications-menu/green-bell.png";
import { Divider } from "../../components/divider/divider";
import { parseCreditToText } from "../../utils/plans";
import { usePlan } from "../../hooks/use-plan";
import smallCircularSubly from "../../assets/images/notifications-menu/small-circular-subly.png";
import { CloseIcon } from "../../components/icons/icons";
import { PLANS_UPGRADE_PATH, BILLING_PATH } from "../../routes";
import { SublyPlan } from "../../interfaces/billing";
import { useObservable } from "@mindspace-io/react";
import { notificationsQuery } from "../../state/notifications/notifications.query";
import classNames from "classnames";
import { format } from "date-fns";
import { Badge } from "../../components/badge/badge";
import {
  markNotificationRead,
  markAllNotificationRead,
  hideNotification
} from "../../api/notifications.service";
import styles from "./notifications-menu.module.scss";
import { NotificationType, UserNotification } from "../../interfaces/user";
import { pluralize } from "../../utils/strings";

interface NotificationsMenuProps {
  dropdownMenu: React.RefObject<HTMLLIElement>;
}

export const NotificationsMenu: React.FC<NotificationsMenuProps> = ({
  dropdownMenu
}) => {
  const [notifications] = useObservable(
    notificationsQuery.selectAllNotifications(),
    []
  );
  const [unreadNotifications] = useObservable(
    notificationsQuery.selectUnreadNotifications(),
    []
  );
  const [recentNotifications] = useObservable(
    notificationsQuery.selectRecentNotifications(),
    []
  );

  const empty = (notifications?.length ?? 0) === 0;

  return (
    <>
      <Dropdown>
        <DropdownButton dropdownEl={dropdownMenu}>
          <Button className="position-relative">
            <BellIcon className={styles.icon} />
            {unreadNotifications?.length > 0 && (
              <Badge className={styles.badge}>
                {unreadNotifications.length}
              </Badge>
            )}
          </Button>
        </DropdownButton>
        <DropdownMenu
          isRight
          className={classNames(styles["notifications-menu"], {
            [styles["notifications-menu--empty"]]: empty
          })}
        >
          <h6>Notifications</h6>
          <Divider className={styles.divider} />
          {!empty ? (
            <MenuWithNotifications
              unreadNotifications={unreadNotifications}
              recentNotifications={recentNotifications}
            />
          ) : (
            <MenuWithoutNotifications />
          )}
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

interface MenuWithNotificationsProps {
  unreadNotifications: UserNotification[];
  recentNotifications: UserNotification[];
}

const MenuWithNotifications: React.FC<MenuWithNotificationsProps> = ({
  unreadNotifications,
  recentNotifications
}) => {
  const renderUnreadNotifications = unreadNotifications.map((n) => (
    <div key={n.id}>
      <NotificationItems notification={n} />
    </div>
  ));

  const renderRecentNotifications = recentNotifications.map((n) => (
    <div key={n.id}>
      <NotificationItems notification={n} />
    </div>
  ));

  const handleMarkAllNotifications = () => {
    markAllNotificationRead();
  };

  return (
    <div className={styles["notification-content"]}>
      {unreadNotifications.length > 0 && (
        <>
          <div className="d-flex justify-content-between">
            <p className={styles["heading"]}>Unread</p>
            <p
              className={classNames(
                styles["mark-all-button"],
                styles["heading"]
              )}
              onClick={handleMarkAllNotifications}
            >
              Mark all as read
            </p>
          </div>
          <div className={styles["notification-list"]}>
            {renderUnreadNotifications}
          </div>
          <Divider className={styles.divider} />
        </>
      )}
      <p className={styles["heading"]}>Recent</p>
      <div className={styles["notification-list"]}>
        {renderRecentNotifications}
      </div>
    </div>
  );
};

const MenuWithoutNotifications = () => {
  return (
    <div className={styles["no-notification-content"]}>
      <img
        className={styles["green-bell"]}
        src={greenBell}
        alt="notification"
      />
      <p className={styles["no-notification-message"]}>
        You're up to date with everything!
      </p>
    </div>
  );
};

interface NotificationItemsProps {
  notification: UserNotification;
}

const NotificationItems: React.FC<NotificationItemsProps> = ({
  notification
}) => {
  const { data } = notification;
  const { credit } = usePlan();
  const minutesLeft = credit?.total ?? 0;

  const uploadFailedText = `Your file ${
    data?.name ? `${data.name} ` : ""
  }has failed to upload, please retry again.`;

  const mediaSharedText = `A new file has been shared with you${`${
    data?.name ? `: ${data?.name}` : ""
  }}.`}`;

  const commentTagText = `You have been tagged in ${
    data?.name ? data.name : "a media file."
  }`;

  const lowMinutesText = `You only have ${parseCreditToText(
    minutesLeft
  )} left in your balance. Buy
    more minutes now!`;

  const cardExpiringText = `Your credit card is expiring ${
    data?.timeLeft && data?.timeLeftUnits
      ? `in ${data.timeLeft} ${pluralize(data.timeLeft, data.timeLeftUnits)}`
      : "soon"
  }. Please update your card details.`;

  const paymentFailedText =
    "Your payment method has failed, please update you payment method.";

  const teammateAcceptText = `Your teammate ${
    data?.name ? `${data.name} ` : ""
  }has accepted your invite.`;

  switch (notification.type) {
    case NotificationType.UploadFailed:
      return (
        <NotificationItem
          text={paymentFailedText}
          date={new Date(notification.createdAt)}
          notificationId={notification.id}
          read={Boolean(notification?.readAt)}
          showUpdateButton
        />
      );
    case NotificationType.MediaShared:
      return (
        <NotificationItem
          text={mediaSharedText}
          date={new Date(notification.createdAt)}
          notificationId={notification.id}
          read={Boolean(notification?.readAt)}
          openMedia={notification?.mediaId}
        />
      );
    case NotificationType.CommentTag:
      return (
        <NotificationItem
          text={commentTagText}
          date={new Date(notification.createdAt)}
          notificationId={notification.id}
          read={Boolean(notification?.readAt)}
          openMedia={notification?.mediaId}
        />
      );
    case NotificationType.LowMinutes:
      return (
        <NotificationItem
          text={lowMinutesText}
          date={new Date(notification.createdAt)}
          notificationId={notification.id}
          read={Boolean(notification?.readAt)}
          showBuyMinutesButton
        />
      );
    case NotificationType.CardExpiring:
      return (
        <NotificationItem
          text={cardExpiringText}
          date={new Date(notification.createdAt)}
          notificationId={notification.id}
          read={Boolean(notification?.readAt)}
          showUpdateButton
        />
      );
    case NotificationType.UploadFailed:
      return (
        <NotificationItem
          text={uploadFailedText}
          date={new Date(notification.createdAt)}
          notificationId={notification.id}
          read={Boolean(notification?.readAt)}
        />
      );
    case NotificationType.TeammateAccepted:
      return (
        <NotificationItem
          text={teammateAcceptText}
          date={new Date(notification.createdAt)}
          notificationId={notification.id}
          read={Boolean(notification?.readAt)}
        />
      );

    default:
      return <></>;
  }
};

interface NotificationItemProps {
  text: string;
  date: Date;
  read: boolean;
  showUpdateButton?: boolean;
  showBuyMinutesButton?: boolean;
  openMedia?: string;
  notificationId: string;
}

const NotificationItem: React.FC<NotificationItemProps> = ({
  text,
  date,
  notificationId,
  read,
  showUpdateButton,
  showBuyMinutesButton,
  openMedia
}) => {
  const formattedDate = date ? format(date, "MMM dd, yyyy") : "";
  const handleMarkNotification = () => {
    if (read) {
      hideNotification(notificationId);
    } else {
      markNotificationRead(notificationId);
    }
  };
  return (
    <>
      <div className={styles["notification-item"]}>
        <img
          src={smallCircularSubly}
          alt="subly logo"
          className={styles.logo}
        />
        <p className={styles["notification-message"]}>{text}</p>
        <Button className={styles["close-button"]}>
          <CloseIcon onClick={handleMarkNotification} />
        </Button>
      </div>
      <p className={styles.date}>{formattedDate}</p>
      {showBuyMinutesButton && (
        <Button
          primary
          className={styles["action-button"]}
          to={{ pathname: PLANS_UPGRADE_PATH }}
          state={{
            plan: SublyPlan.PAYG,
            quantity: 20,
            topup: true
          }}
        >
          buy more minutes
        </Button>
      )}
      {showUpdateButton && (
        <Button
          primary
          className={styles["action-button"]}
          to={{ pathname: BILLING_PATH }}
        >
          update now
        </Button>
      )}
      {openMedia && (
        <Button
          primary
          className={styles["action-button"]}
          to={{ pathname: `/videos/${openMedia}` }}
        >
          open
        </Button>
      )}
    </>
  );
};
