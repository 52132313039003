import classNames from "classnames";
import React, { useContext, useState } from "react";
import { EN } from "../../../../assets/i18n/en";
import { Alert } from "../../../../components/alert/alert";
import { Button } from "../../../../components/button/button";
import { WarningIcon } from "../../../../components/icons/icons";
import { SettingsCardContainer } from "../../../../components/settings-card/settings-card-container";
import { AuthContext } from "../../../../contexts/auth.context";
import { Modal } from "../../../modal/modal";
import styles from "./delete-account-card.module.scss";

export const DeleteAccountCard: React.FC = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  return (
    <SettingsCardContainer title="Delete account">
      <div className="mt-2">
        <p className={styles["delete-text"]}>
          By deleting your account you’ll no longer be able to access any of
          your content or sign in to Subly.
        </p>
      </div>

      <div
        className={classNames("mt-2", styles["delete-account-card-actions"])}
      >
        <Button danger to="#" onClick={() => setShowDeleteModal(true)}>
          <WarningIcon className="mr-2" />
          Delete account
        </Button>
      </div>
      <DeleteAccountModal
        visible={showDeleteModal}
        close={() => setShowDeleteModal(false)}
      />
    </SettingsCardContainer>
  );
};

interface DeleteAccountModalProps {
  visible: boolean;
  close: () => void;
}

const DeleteAccountModal: React.FC<DeleteAccountModalProps> = ({
  visible,
  close
}) => {
  const { deleteUser, logout } = useContext(AuthContext);
  const [alert, setAlert] = useState<string>();
  const [loading, setLoading] = useState(false);

  if (!visible) {
    return null;
  }

  const handleClose = () => {
    setAlert("");
  };

  const handleConfirm = async () => {
    try {
      setLoading(true);

      const result = await deleteUser();

      if (result.useAlert) {
        throw new Error(result.alertMessage);
      }

      setLoading(false);
      await logout();
    } catch (e) {
      setAlert(e.message || EN.error.defaultMessage);
      setLoading(false);
    }
  };

  return (
    <Modal onDismiss={handleClose}>
      <h5>Delete your account?</h5>
      {alert && (
        <Alert
          danger
          title="Something went wrong"
          closable
          onClose={handleClose}
        >
          {alert}
        </Alert>
      )}
      <p className="warning-message">
        You will immediately lose access to all of your content and won't be
        able to sign in.
      </p>
      <p>
        If you're trying to downgrade your Subly account, go to Subscription and
        change your plan instead.
      </p>

      <div className="d-flex">
        <Button secondary onClick={close} className="ml-auto mr-2">
          Cancel
        </Button>
        <Button danger onClick={handleConfirm} loading={loading}>
          Delete account
        </Button>
      </div>
    </Modal>
  );
};
