import { mdiAlertCircleOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { useObservable } from "@mindspace-io/react";
import classNames from "classnames";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { LongPressDetectEvents, useLongPress } from "use-long-press";
import { reportIssue } from "../../api/account.service";
import { deleteMedia } from "../../api/media.service";
import { EN } from "../../assets/i18n/en";
import config from "../../config";
import { ToolTip } from "../../containers/tooltip/tooltip";
import { useSelectedFiles } from "../../contexts/selected-files.context";
import { useAccounts } from "../../hooks/use-accounts";
import {
  BasicMedia,
  MediaErrorReason,
  MediaJob,
  MediaJobType,
  MediaStatus
} from "../../interfaces/media";
import { PLANS_PATH } from "../../routes";
import { authQuery } from "../../state/auth/auth.query";
import { mediaQuery, mediaStore } from "../../state/media";
import { userPresenceQuery } from "../../state/user-presence";
import { getBurningTaskLabel } from "../../utils/media-functions";
import { pluralize } from "../../utils/strings";
import { Button } from "../button/button";
import { Checkbox } from "../checkbox/checkbox";
import { FilmSnippetsIcon, TranslationsIcon } from "../icons/icons";
import { notificationError, notificationSuccess } from "../notification";
import { ProfileImageIcon } from "../profile-image-icon/profile-image-icon";
import { ProgressBar } from "../progress-bar/progress-bar";
import { ThumbnailImage } from "../thumbnail-image/thumbnail-image";
import { MediaCardDropdown } from "./media-card-dropdown";
import styles from "./media-card.module.scss";

const statusForInProgressJob = (job: MediaJob): string => {
  switch (job.type) {
    case MediaJobType.Upload:
      return "Uploading";

    case MediaJobType.Conversion:
      return "Optimising media";

    case MediaJobType.Transcribe:
      return "Transcribing subtitles";

    default:
      return "";
  }
};

interface MediaCardTopProps {
  media: BasicMedia;
  isDropdownOpen: boolean;
  setDropdownOpen: (isOpen: boolean) => void;
}
export const MediaCardTop: React.FC<MediaCardTopProps> = (props) => {
  const { media } = props;
  const job = media.latestJob;

  if (!job) {
    return <MediaCardTopRegular {...props} />;
  }

  if (media.isBurning) {
    return <MediaCardTopBurning {...props} />;
  }

  switch (media.status) {
    case MediaStatus.Uploading:
    case MediaStatus.Uploaded:
    case MediaStatus.Pending:
    case MediaStatus.Converting:
      return (
        <MediaCardTopWithProgress
          media={media}
          status={statusForInProgressJob(job)}
          progress={job.percentage}
          loading
        />
      );

    case MediaStatus.Failed:
      return <MediaCardTopWithError {...props} />;

    default:
      return <MediaCardTopRegular {...props} />;
  }
};

interface MediaCardTopWithProgressProps {
  media: BasicMedia;
  status: string | JSX.Element;
  progress?: number;
  loading?: boolean;
}
const MediaCardTopWithProgress: React.FC<MediaCardTopWithProgressProps> = ({
  media,
  status,
  progress,
  loading
}) => {
  const isMediaReady = MediaStatus.Ready === media.status;
  const mediaLink = isMediaReady ? `/videos/${media.mediaId}` : "#";
  const hasProgress = progress != null;

  return (
    <Link to={mediaLink} className="stretched-link">
      <ThumbnailImage
        src={media.posterUrl}
        alt={media.name}
        mediaType={media.type}
      />
      <div
        className={classNames(styles["toolbar"], styles["toolbar--progress"])}
      >
        <p className="small text-black text-center mb-1">{status}</p>
        <h6 className="text-center mb-2">
          {hasProgress ? `${progress}%` : <>&nbsp;</>}
        </h6>
        <ProgressBar progress={progress ?? 0} loading={loading} />
      </div>
    </Link>
  );
};

const MediaCardTopRegular: React.FC<MediaCardTopProps> = ({
  media,
  isDropdownOpen,
  setDropdownOpen
}) => {
  const [activeUser] = useObservable(
    userPresenceQuery.selectActiveUser(media.id)
  );
  const navigate = useNavigate();
  const { isAdmin } = useAccounts();
  const { selectedFiles, setSelectedFiles } = useSelectedFiles();

  const isSelected = React.useMemo(
    () => selectedFiles.includes(media.id),
    [selectedFiles.length, media.id]
  );

  const toggleSelected = React.useCallback(() => {
    if (isSelected) {
      setSelectedFiles((ids) => [...ids].filter((id) => id !== media.id));
    } else {
      setSelectedFiles((ids) => [...ids, media.id]);
    }
  }, [isSelected, selectedFiles.length]);

  const handleLongPress = useLongPress(toggleSelected, {
    detect: LongPressDetectEvents.TOUCH
  });

  const isMediaReady = MediaStatus.Ready === media.status;

  // ****************
  // ! Should be modified when new editor is complete.

  const [useNewEditor, setUseNewEditor] = React.useState(false);

  const mediaLink =
    isMediaReady && useNewEditor
      ? `/media/${media.mediaId}`
      : isMediaReady && !selectedFiles.length
      ? `/videos/${media.mediaId}`
      : "#";
  // ****************

  const languages = media.transcriptions.translations.length + 1;
  const hasTranslations = languages > 1;
  const snippets = media?.assConfig?.snippets?.length ?? 0;
  const hasSnippets = snippets > 0;

  const isMediaOwner = authQuery.userId === media.userId;
  const isSharedMediaNotOwner =
    authQuery.accountId === media.accountId && !isMediaOwner;
  const isMediaOwnerOrAdmin =
    (isSharedMediaNotOwner && isAdmin) || isMediaOwner;

  const handleClickCard = () => {
    if (selectedFiles.length) {
      toggleSelected();
    } else {
      navigate(mediaLink);
    }
  };

  const renderMediaTopButton = () => {
    if (isSelected && selectedFiles.length) {
      return "Deselect";
    } else if (!isSelected && selectedFiles.length) {
      return "Select";
    } else if (activeUser) {
      return "View only";
    } else {
      return "Edit";
    }
  };

  return (
    <div onClick={handleClickCard} {...handleLongPress()}>
      <ThumbnailImage
        src={media.posterUrl}
        alt={media.name}
        mediaType={media.type}
      />
      <div className={styles["badge-container"]}>
        {hasTranslations && <MediaLanguagesBadge languagesCount={languages} />}
        {hasSnippets && <MediaSnippetsBadge snippetsCount={snippets} />}
      </div>

      <div
        className={classNames(styles.toolbar, {
          [styles.toolbarSelected]: selectedFiles.length || isDropdownOpen
        })}
      >
        <div className={styles["tool-container"]}>
          <Checkbox
            className={styles.checkbox}
            onClick={(e) => e.stopPropagation()}
            onChange={() => toggleSelected()}
            value={isSelected}
          />
          {isMediaOwnerOrAdmin && !selectedFiles.length && (
            <MediaCardDropdown
              media={media}
              className={styles["media-card-dropdown"]}
              setDropdownOpen={setDropdownOpen}
            />
          )}
        </div>

        <Button
          secondary
          className={classNames(styles["edit-button"], {
            [styles["pick-button"]]: selectedFiles.length
          })}
          to={mediaLink}
        >
          {renderMediaTopButton()}
        </Button>

        {config.features.NewEditor && (
          <div className={styles["use-new-editor"]}>
            <Checkbox
              className={styles.checkbox}
              onClick={(e) => e.stopPropagation()}
              onChange={() => setUseNewEditor(!useNewEditor)}
              value={useNewEditor}
            />
            <span>Use New Editor</span>
          </div>
        )}
      </div>
    </div>
  );
};

const MediaCardTopBurning: React.FC<MediaCardTopProps> = ({ media }) => {
  if (!media.burningTasks) {
    return (
      <MediaCardTopWithProgress
        media={media}
        status={getBurningTaskLabel(media.burningTasks)}
        loading
        progress={0}
      />
    );
  }

  return (
    <MediaCardTopWithProgress
      media={media}
      status={getBurningTaskLabel(media.burningTasks)}
      progress={media.burnProgress ?? 0}
    />
  );
};

interface MediaCardTopWithErrorProps {
  media: BasicMedia;
}
const MediaCardTopWithError: React.FC<MediaCardTopWithErrorProps> = ({
  media
}) => {
  const [reportLoading, setReportLoading] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [reportedIssue] = useObservable(
    mediaQuery.ui.selectEntity(media.mediaId, "reportedIssue"),
    false
  );

  const handleDelete = async () => {
    try {
      setLoading(true);
      await deleteMedia(media.mediaId);
      close();
    } catch (e) {
      notificationError(EN.error.defaultMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleReportError = async () => {
    try {
      setReportLoading(true);
      await reportIssue({ mediaId: media.mediaId });
      mediaStore.ui.update(media.mediaId, { reportedIssue: true });
      notificationSuccess(
        "We're on it! Customer support will be in touch with you soon."
      );
    } catch (e) {
      console.error(e);
    } finally {
      setReportLoading(false);
    }
  };

  if (media.reason === MediaErrorReason.InsufficientFunds) {
    return (
      <div className={classNames(styles["toolbar"], styles["toolbar--error"])}>
        <div className="d-flex overflow-auto flex-column w-100 mb-1">
          <div className="mb-2">
            <Icon
              path={mdiAlertCircleOutline}
              size="20px"
              color="var(--feedback-error)"
              className="mr-2 flex-shrink-0"
            />
            <label className="mb-0">Insufficient Funds</label>
          </div>
          <p className={classNames("text-black m-0", styles["error-message"])}>
            {EN.media[MediaErrorReason.InsufficientFunds]}
          </p>
        </div>
        <div className="d-flex justify-content-between w-100 mt-3">
          <Button
            danger
            className={classNames("mr-2", styles["error-delete"])}
            onClick={() => mediaStore.setShowDeleteMediaModal(media.mediaId)}
          >
            Delete
          </Button>
          <Button primary to={PLANS_PATH}>
            Upgrade
          </Button>
        </div>
      </div>
    );
  }
  const errorMessage =
    media.reason === MediaErrorReason.UploadCancelled
      ? EN.media[MediaErrorReason.UploadCancelled]
      : EN.media[MediaErrorReason.ProcessingCancelled];

  return (
    <div className={classNames(styles["toolbar"], styles["toolbar--error"])}>
      <div className="d-flex overflow-auto flex-column w-100 mb-1">
        <div className="mb-2">
          <Icon
            path={mdiAlertCircleOutline}
            size="20px"
            color="var(--feedback-error)"
            className="mr-2 flex-shrink-0 align-items-start"
          />
          <label className="mb-0">Error</label>
        </div>
        <p className={classNames("text-black m-0", styles["error-message"])}>
          {errorMessage}
        </p>
      </div>
      <div className="d-flex justify-content-between align-items-end mt-2 w-100">
        <Button
          secondary
          className={classNames("mr-2", styles["report-button"])}
          loading={reportLoading}
          disabled={reportedIssue}
          onClick={handleReportError}
        >
          Report
        </Button>
        <Button
          danger
          className={styles["error-delete"]}
          onClick={handleDelete}
          loading={loading}
        >
          Delete
        </Button>
      </div>
    </div>
  );
};

interface MediaLanguagesBadgeProps {
  languagesCount: number;
}
const MediaLanguagesBadge: React.FC<MediaLanguagesBadgeProps> = ({
  languagesCount
}) => {
  return (
    <ToolTip
      text={`${languagesCount} ${pluralize(languagesCount, "language")}`}
      place="top"
    >
      <div className={styles["language-badge"]}>
        <div className={styles.icon}>
          <TranslationsIcon />
        </div>
        <div className={styles.text}>{languagesCount}</div>
      </div>
    </ToolTip>
  );
};
interface MediaSnippetsBadgeProps {
  snippetsCount: number;
}
const MediaSnippetsBadge: React.FC<MediaSnippetsBadgeProps> = ({
  snippetsCount
}) => {
  return (
    <ToolTip
      text={`${snippetsCount} ${pluralize(snippetsCount, "snippet")}`}
      place="top"
    >
      <div className={styles["snippet-badge"]}>
        <div className={styles.icon}>
          <FilmSnippetsIcon />
        </div>
        <div className={styles.text}>{snippetsCount}</div>
      </div>
    </ToolTip>
  );
};

interface MediaCardUserProps {
  media: BasicMedia;
}

export const MediaCardUser: React.FC<MediaCardUserProps> = ({ media }) => {
  const [activeUser] = useObservable(
    userPresenceQuery.selectActiveUser(media.id)
  );

  if (!activeUser) {
    return null;
  }

  return (
    <div className={styles["profile"]}>
      <ToolTip
        text={`Your teammate ${
          activeUser.name ?? ""
        } is currently editing this video`}
        place={"top"}
        className={styles["tooltip"]}
      >
        <ProfileImageIcon src={activeUser.photo} name={activeUser.name ?? ""} />
      </ToolTip>
    </div>
  );
};
