import { PusherProvider } from "@harelpls/use-pusher";
import { useObservable } from "@mindspace-io/react";
import { format } from "date-fns";
import React, { useContext, useState } from "react";
import { Link, matchPath, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { updateAccountSettings } from "../api/account.service";
import { LowStorageModal } from "../components/low-storage-modal/low-storage-modal";
import { authorisePusher } from "../api/pusher.service";
import { notificationWarning } from "../components/notification";
import { InactiveUserModal } from "../components/upgrade-modal/inactive-user-modal";
import { MigrationModal } from "../components/upgrade-modal/migration-modal";
import { OutOfMinutesModal } from "../components/upgrade-modal/out-of-minutes-modal";
import { DashboardTrialModal } from "../components/upgrade-modal/trial-dashboard-modal";
import { TrialLimitReachedModal } from "../components/upgrade-modal/trial-limit-reached-modal";
import { TrialOneDownloadRemainModal } from "../components/upgrade-modal/trial-one-download-remain-modal";
import { TrialWelcomeModal } from "../components/upgrade-modal/trial-welcome-modal";
import { UpgradeReach95FreeModal } from "../components/upgrade-modal/upgrade-reach-95-free-modal";
import config from "../config";
import { TrialCornerModal } from "../containers/trial/trial-corner-modal";
import { useAccounts } from "../hooks/use-accounts";
import { useModal } from "../hooks/use-modal";
import { usePlan } from "../hooks/use-plan";
import { useUpload } from "../hooks/use-upload";
import { SublyPlan } from "../interfaces/billing";
import { ModalType } from "../interfaces/modal-type";
import { DASHBOARD_PATH, PLANS_PATH, VIDEO_DETAILS } from "../routes";
import { accountQuery, accountStore } from "../state/account";
import { authQuery } from "../state/auth/auth.query";
import { outOfMinutes, parseCreditToText } from "../utils/plans";
import { getStorageInfo } from "../utils/storage-size";
import { AuthContext } from "./auth.context";
import { TopBannerProvider } from "./top-banner.context";

export const NotificationsProvider: React.FC = ({ children }) => {
  // TODO: Hide release modal for now until further updates
  // const [showReleaseModal, hideReleaseModal] = useModal(ModalType.NewRelease);
  const [numberOfTrialDownloads] = useObservable(
    accountQuery.selectTrialDownloads(),
    0
  );
  const trialDownloadsLeft =
    config.trialDownloadsLimit - numberOfTrialDownloads;
  const [showFree95Modal, hideFree95Modal] = useModal(ModalType.UpgradeFree95);
  const [showTrialModal, hideTrialModal] = useModal(ModalType.TrialCornerModal);
  const [showMigrationModal, hideMigrationModal] = useModal(
    ModalType.MigrationModal
  );
  const [showWelcomeTrialModal, hideWelcomeTrialModal] = useModal(
    ModalType.WelcomeTrialModal
  );
  const [showTrialLimitReachedModal, hideTrialLimitReachedModal] = useModal(
    ModalType.TrialLimitReachedModal
  );
  const [showTrialDashboardModal, hideTrialDashboardModal] = useModal(
    ModalType.TrialDashboardModal
  );
  const [showOutOfMinutesModal, hideOutOfMinutesModal] = useModal(
    ModalType.OutOfMinutesModal
  );
  const [showInactiveUserModal, hideInactiveUserModal] = useModal(
    ModalType.InactiveUserModal
  );
  const [showTrialOneDownloadRemainModal, hideTrialOneDownloadRemainModal] =
    useModal(ModalType.TrialOneDownloadRemainModal);

  const [showOutOfStorageModal, hideOutOfStorageModal] = useModal(
    ModalType.LowStorageModal
  );
  const [hasSeenDailyTrialModal, setHasSeenDailyTrialModal] = useState(false);

  const [hasSeenMinutesModals, setHasSeenMinutesModals] = useState(false);
  const [hasSeenInactiveModal, setHasSeenInactiveModal] = useState(false);
  const { isTrial, trialEndDate, hasTrialed, isPremium, isFree, isPayg, plan } =
    usePlan();
  const { currentAccount, subscription, deal } = useAccounts();
  const { pathname } = useLocation();
  const { upload } = useUpload();
  const { isAuthenticated } = useContext(AuthContext);

  const isUploading = Boolean(upload?.isUploading);
  const loaded = currentAccount?.loaded;
  const settings = currentAccount?.settings;
  const credit = currentAccount?.credit;
  const { isStorageLow } = getStorageInfo(plan);

  // TODO: Hide release modal for now until further updates
  // const shouldShowReleaseModal = Boolean(settings?.showReleaseModal);
  const shouldShowTrialModal =
    isFree &&
    !isTrial &&
    !hasTrialed &&
    !isPayg &&
    !Boolean(settings?.hasSeenTrialModal);
  const shouldShowMigrationModal =
    isPremium && Boolean(settings?.migratedProToPremium);
  const hasNotSeeTrialWelcome =
    isTrial && !Boolean(settings?.hasSeenTrialWelcome);
  const hasSeenTrialOneDownloadRemain =
    isTrial && !Boolean(settings?.hasSeenTrialOneDownloadRemain);
  const shouldShowTrialLimitModal =
    isFree && !isPayg && !Boolean(settings?.hasSeenTrialLimitModal);
  const isDashboard = pathname === DASHBOARD_PATH;
  const isEditor = matchPath(VIDEO_DETAILS, pathname);

  // TODO: Hide release modal for now until further updates
  // React.useEffect(() => {
  //   // Show release modal only on the dashboard page

  //   if (!loaded || !shouldShowReleaseModal || !isDashboard) {
  //     return;
  //   }

  //   const showNewReleaseModal = async () => {
  //     await updateAccountSettings({ lastVersionTour: version });

  //     showReleaseModal(<ReleaseModal closeModal={hideReleaseModal} />);
  //   };

  //   showNewReleaseModal();
  // }, [loaded, shouldShowReleaseModal, isDashboard]);

  const showUpgradeModal = credit?.showUpgradeModal;

  // Show upgrade modal
  React.useEffect(() => {
    if (!showUpgradeModal) {
      return;
    }

    accountStore.resetUpgradeModal();

    switch (showUpgradeModal) {
      case ModalType.UpgradeFree95: {
        showFree95Modal(
          <UpgradeReach95FreeModal closeModal={hideFree95Modal} />
        );
        break;
      }
      case ModalType.UpgradeReach80: {
        const totalCredit = credit?.total ?? 0;
        const balanceText = parseCreditToText(totalCredit);

        notificationWarning(
          <>
            <h6>
              Wow you've used 80% of your minutes{" "}
              <span role="img" aria-labelledby="strong">
                💪
              </span>
            </h6>
            <p className="mb-0">
              You only have <strong>{balanceText}</strong> left this month.
            </p>
            <p>
              Don't stop making great content.{" "}
              <Link to={PLANS_PATH}>Upgrade</Link> to get more minutes today.
            </p>
          </>,
          {
            toastId: "credit-warning"
          }
        );
        return;
      }
    }
  }, [showUpgradeModal]);

  // Show trial corner modal
  React.useEffect(() => {
    // Show trial corner modal only on the dashboard page

    if (!isDashboard || !loaded || !isTrial || !trialEndDate || !subscription) {
      return hideTrialModal();
    }

    showTrialModal(
      <TrialCornerModal
        plan={plan}
        subscriptionId={subscription.id}
        endDate={trialEndDate}
      />
    );
  }, [isTrial, trialEndDate, subscription, isDashboard]);

  // Show dashboard trial modal
  React.useEffect(() => {
    if (!isDashboard || !loaded || !shouldShowTrialModal) {
      return;
    }

    const showDashboardTrialModal = async () => {
      await updateAccountSettings({ hasSeenTrialModal: true });

      showTrialDashboardModal(
        <DashboardTrialModal closeModal={hideTrialDashboardModal} />
      );
    };

    showDashboardTrialModal();
  }, [isDashboard, loaded, shouldShowTrialModal]);

  // Show migration modal
  React.useEffect(() => {
    if (!isDashboard || !loaded || !shouldShowMigrationModal) {
      return;
    }

    const showDashboardMigrationModal = async () => {
      await updateAccountSettings({ migratedProToPremium: false });

      showMigrationModal(<MigrationModal closeModal={hideMigrationModal} />);
    };

    showDashboardMigrationModal();
  }, [isDashboard, loaded, shouldShowMigrationModal]);

  // Show welcome to trial modal
  React.useEffect(() => {
    // Show modal when user starts trial
    if (!loaded || !isDashboard || !hasNotSeeTrialWelcome) {
      return;
    }

    const showDashboardWelcomeTrialModal = async () => {
      await updateAccountSettings({ hasSeenTrialWelcome: true });

      showWelcomeTrialModal(
        <TrialWelcomeModal closeModal={hideWelcomeTrialModal} />
      );
    };

    showDashboardWelcomeTrialModal();
  }, [isTrial, loaded, isDashboard]);

  // Show modal when user on trial signs in and have only one download left
  React.useEffect(() => {
    if (
      !loaded ||
      !isDashboard ||
      !hasSeenTrialOneDownloadRemain ||
      trialDownloadsLeft !== 1
    ) {
      return;
    }

    const showDashboardTrialOneDownloadRemainModal = async () => {
      await updateAccountSettings({ hasSeenTrialOneDownloadRemain: true });

      showTrialOneDownloadRemainModal(
        <TrialOneDownloadRemainModal
          closeModal={hideTrialOneDownloadRemainModal}
        />
      );
    };

    showDashboardTrialOneDownloadRemainModal();
  }, [isTrial, loaded, isDashboard]);

  // Show upgrade modal upon every Login
  React.useEffect(() => {
    if (
      !loaded ||
      !isDashboard ||
      !isTrial ||
      !trialEndDate ||
      hasSeenDailyTrialModal ||
      !Boolean(settings?.hasSeenTrialWelcome)
    ) {
      return;
    }

    // Check if last day of trial
    const lastTrialDay =
      format(new Date(trialEndDate), "dd MMM yyyy") ===
      format(new Date(), "dd MMM yyyy");

    // TA-2917 Don't show the modal to test conversions
    if (!lastTrialDay) {
      return;
    }

    const showTrialUpgradeModal = () => {
      setHasSeenDailyTrialModal(true);
      showTrialLimitReachedModal(
        <TrialLimitReachedModal
          closeModal={hideTrialLimitReachedModal}
          trialEndsToday={lastTrialDay}
          daily={!lastTrialDay}
        />
      );
    };

    showTrialUpgradeModal();
  }, [loaded, isDashboard, isTrial, trialEndDate, hasSeenDailyTrialModal]);

  // If trial has ended show trial ended modal
  React.useEffect(() => {
    if (
      !isDashboard ||
      !loaded ||
      !hasTrialed ||
      !shouldShowTrialLimitModal ||
      subscription ||
      isTrial
    ) {
      return;
    }

    setHasSeenDailyTrialModal(true);
    const showUpgradeModal = async () => {
      await updateAccountSettings({ hasSeenTrialLimitModal: true });

      showTrialLimitReachedModal(
        <TrialLimitReachedModal closeModal={hideTrialLimitReachedModal} />
      );
    };

    showUpgradeModal();
  }, [isTrial, hasTrialed, subscription, loaded, isDashboard]);

  // Show inactive modal
  React.useEffect(() => {
    if (credit?.total == null || !loaded || hasSeenInactiveModal || !isEditor) {
      return;
    }

    const minutesLeft = credit.total / 60;
    const isInactive = (isFree && !isPayg) || (isPayg && minutesLeft === 0);

    if (isInactive) {
      showInactiveUserModal(
        <InactiveUserModal closeModal={hideInactiveUserModal} />
      );
      setHasSeenInactiveModal(true);
      return;
    }
  }, [credit, isEditor]);

  // Show out of minutes modal
  React.useEffect(() => {
    if (
      credit?.total == null ||
      !loaded ||
      hasSeenMinutesModals ||
      deal ||
      !isDashboard ||
      isFree ||
      !credit.total
    ) {
      return;
    }

    const minutesLeft = credit.total / 60;
    const currentPlan = isPayg ? SublyPlan.PAYG : plan;
    const lowMinutes = outOfMinutes(currentPlan, minutesLeft);

    if (!isTrial && lowMinutes) {
      showOutOfMinutesModal(
        <OutOfMinutesModal closeModal={hideOutOfMinutesModal} />
      );
      setHasSeenMinutesModals(true);
      return;
    }

    if (minutesLeft < 10 && isTrial) {
      showTrialLimitReachedModal(
        <TrialLimitReachedModal
          closeModal={hideTrialLimitReachedModal}
          outOfMinutes={true}
        />
      );
      setHasSeenMinutesModals(true);
      return;
    }
  }, [credit, isDashboard, loaded, credit, hasSeenMinutesModals, isFree, deal]);

  //Low Storage modal
  React.useEffect(() => {
    if (!loaded || !isDashboard || isFree || deal || !isStorageLow || isTrial) {
      return;
    }

    const showModal = () => {
      showOutOfStorageModal(
        <LowStorageModal closeModal={hideOutOfStorageModal} />
      );
    };

    showModal();
  }, [isDashboard, loaded, isFree, deal]);

  // Reset state on logout, so popups can trigger per login.
  React.useEffect(() => {
    setHasSeenMinutesModals(false);
    setHasSeenInactiveModal(false);
  }, [isAuthenticated, isUploading]);

  return (
    <TopBannerProvider>
      <PusherProvider
        clientKey={config.pusher.clientKey}
        cluster={config.pusher.cluster}
        authorizer={
          config.features.hasUserPresence
            ? ({ name }) => ({
                authorize: async (socketId, callback) => {
                  if (!authQuery.accessToken) {
                    return callback(null, {
                      auth: ""
                    });
                  }

                  const auth = await authorisePusher(name, socketId);

                  if (!auth) {
                    return callback(null, {
                      auth: ""
                    });
                  }
                  return callback(null, auth);
                }
              })
            : undefined
        }
      >
        {children}
      </PusherProvider>

      <ToastContainer />
    </TopBannerProvider>
  );
};
