import React from "react";
import styles from "./search-bar.module.scss";
import { SearchIcon } from "@heroicons/react/outline";
import classNames from "classnames";

interface SearchBarProps {
  placeholder: string;
  searchWords: string;
  setSearchWords: React.Dispatch<React.SetStateAction<string>>;
  className?: string;
}
export const SearchBar: React.FC<SearchBarProps> = ({
  placeholder,
  searchWords,
  setSearchWords,
  className
}) => {
  return (
    <div className={classNames(styles["search-bar-container"], className)}>
      <div>
        <SearchIcon className={styles.icon} />
      </div>
      <input
        placeholder={placeholder}
        value={searchWords}
        onChange={(e) => setSearchWords(e.target.value)}
      />
    </div>
  );
};
