import { format } from "date-fns";
import React from "react";
import {
  ProfileIcon,
  ReferralMobileGift
} from "../../../../components/icons/icons";
import { LoadingIcon } from "../../../../components/loading-icon/loading-icon";
import { Table } from "../../../../components/table/table";
import { Referral } from "../../../../interfaces/referral";
import styles from "./referral-table.module.scss";

interface ReferralTableProps {
  loaded: boolean;
  referrals: Referral[];
}
export const ReferralTable: React.FC<ReferralTableProps> = ({
  loaded,
  referrals
}) => {
  if (!loaded || !referrals.length) return <EmptyReferralList />;

  const rows = referrals.map((referral) => {
    return (
      <tr key={referral.id}>
        <td className={styles["profile-cell"]}>
          <div className="icon-holder">
            <ProfileIcon
              primaryColor={"#377546"}
              secondarycolor={"#F2E5FF"}
              style={{ width: 46, height: "auto" }}
            />
          </div>
        </td>
        <td className={styles["cell"]}>{referral.email}</td>
        <td className={styles["cell"]}>
          {format(new Date(referral.date), "dd/MM/yyyy")}
        </td>
        <td className={styles["cell"]}>{referral.earnedCredit / 60}</td>
      </tr>
    );
  });

  const tbody = <tbody>{rows}</tbody>;

  return (
    <div className="mt-4">
      <Table>
        <ReferralHeader />
        {loaded ? tbody : <LoadingIcon />}
      </Table>
    </div>
  );
};

const ReferralHeader: React.FC = () => {
  return (
    <thead>
      <tr>
        <th className={styles["table-header"]} scope="col"></th>
        <th className={styles["table-header"]} scope="col">
          Email
        </th>
        <th className={styles["table-header"]} scope="col">
          Date joined
        </th>
        <th className={styles["table-header"]} scope="col">
          Minutes earned
        </th>
      </tr>
    </thead>
  );
};

const EmptyReferralList: React.FC = () => {
  return (
    <div className="row justify-content-center mt-5">
      <div
        style={{
          maxWidth: "500px",
          textAlign: "center"
        }}
      >
        <ReferralMobileGift className="mb-4" />
        <h4>Invite a friend and earn free minutes when they sign up</h4>
      </div>
    </div>
  );
};
