import React from "react";
import { MINIMUM_SHORTCUTS, ShortcutTag } from "../../interfaces/shortcuts";
import { useAnalyticsWithAuth } from "../../hooks/use-analytics-with-auth";
import { useModal } from "../../hooks/use-modal";
import { useUpload } from "../../hooks/use-upload";
import { ModalType } from "../../interfaces/modal-type";
import { uploadQuery, uploadStore } from "../../state/upload";
import styles from "./dashboard-shortcuts.module.scss";
import { UploadModal } from "./upload-modal/upload-modal";
import { UpgradeToTranslateModal } from "./upgrade-to-translate-modal/upgrade-to-translate-modal";
import { DashboardShortcutsModal } from "./dashboard-shortcuts-modal/dashboard-shortcuts-modal";
import { PremiumBadge } from "../../components/badge/premium-badge";
import { usePermissions } from "../../hooks/use-permissions";
import { SublyPlan } from "../../interfaces/billing";
import { LockFeature } from "../../components/lock-feature/lock-feature";

export const DashboardShortcuts: React.FC = () => {
  const { trackEventWithAuth } = useAnalyticsWithAuth();
  const { upload } = useUpload();
  const [showModal, hideModal] = useModal(ModalType.Upload);
  const isUploading = Boolean(upload?.isUploading);
  const { hasPermission } = usePermissions(SublyPlan.Premium);

  const handleCloseModal = () => {
    hideModal();

    if (!uploadQuery.getValue().isUploading) {
      uploadStore.resetUpload();
    }
  };

  // Triggered when clicking 'Translate' shortcut
  const handleShowUpgradeToTranslate = (tag: string) => {
    if (isUploading) {
      return;
    }
    showModal(<UpgradeToTranslateModal closeModal={() => hideModal()} />);
    trackEventWithAuth(`Launch upload screen from shortcut ${tag}`);
    trackEventWithAuth("Launch upload screen from shortcut", {
      shortcut: tag
    });
    trackEventWithAuth("Click on upgrade from shortcut", { shortcut: tag });
  };

  // Triggered when clicking 'Explore options' shortcut
  const handleShowMoreShortcuts = () => {
    if (isUploading) {
      return;
    }
    showModal(<DashboardShortcutsModal closeModal={() => hideModal()} />);
  };

  // Triggered when clicking other dashboard shortcuts
  const handleShowUpload = (tag: string) => {
    if (isUploading) {
      return;
    }

    showModal(<UploadModal closeModal={handleCloseModal} />);
    trackEventWithAuth(`Launch upload screen from shortcut ${tag}`);
    trackEventWithAuth("Launch upload screen from shortcut", {
      shortcut: tag
    });
  };

  // Clicking different shortcut opens a different modal
  const handleClickShortcut = (tag: string) => {
    if (tag === ShortcutTag.Translating && !hasPermission) {
      handleShowUpgradeToTranslate(tag);
    } else if (tag === ShortcutTag.ExploringOptions) {
      handleShowMoreShortcuts();
    } else {
      handleShowUpload(tag);
    }
  };

  const renderMinimumShortcuts = MINIMUM_SHORTCUTS.map(
    ({ label, Image, tag, isPremiumFeature }, index) => {
      return (
        <div
          key={index}
          className={styles.shortcut}
          onClick={() => handleClickShortcut(tag)}
        >
          <Image />
          <div className={styles["name-container"]}>
            <p className={styles.name}>{label}</p>
            {isPremiumFeature && (
              <PremiumBadge hideLink className={styles["badges"]} />
            )}
          </div>
        </div>
      );
    }
  );

  return (
    <LockFeature>
      <div className={styles.container}>
        <h6 className="font-weight-bold">What would you like to do today?</h6>
        <div className={styles.shortcutsContainer}>
          <div className={styles.shortcuts}>{renderMinimumShortcuts}</div>
        </div>
      </div>
    </LockFeature>
  );
};
