import classNames from "classnames";
import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { ForgotPasswordParams } from "../../api/auth.service";
import { EN } from "../../assets/i18n/en";
import { Alert } from "../../components/alert/alert";
import { Button } from "../../components/button/button";
import { AuthContext } from "../../contexts/auth.context";
import { LOGIN_PATH } from "../../routes";
import { emailPattern } from "../../utils/regex-patterns";
import styles from "./auth.module.scss";

export const ForgotPassword: React.FC = () => {
  return (
    <div className={styles.container}>
      <h4 className="text-center mb-4">Forgot your password?</h4>
      <ForgotPasswordForm />
    </div>
  );
};

const ForgotPasswordForm: React.FC = () => {
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState<string | JSX.Element>(
    ""
  );
  const { forgotPassword } = useContext(AuthContext);
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<ForgotPasswordParams>();

  const onSubmit = async (form: ForgotPasswordParams) => {
    setLoading(true);

    const { success, alertMessage } = await forgotPassword(form);
    if (!success) {
      setAlertMessage(alertMessage || EN.error.defaultMessage);
    }
    setSuccess(success);
    setLoading(false);
  };

  const handleClose = () => {
    setAlertMessage("");
  };

  if (success) {
    return (
      <>
        <p className="text-center">
          We have sent you an email with instructions on how to reset your
          password.
        </p>
        <Button primary to={LOGIN_PATH} className="btn-block mt-3">
          Go back
        </Button>
      </>
    );
  }

  return (
    <div id="forgot-password">
      {alertMessage && (
        <Alert
          danger
          title="Something went wrong"
          closable
          onClose={handleClose}
        >
          {alertMessage}
        </Alert>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <p className="small mt-4 mb-2">
          Please enter your email to receive instructions to reset your
          password.
        </p>
        <div className="form-group">
          <input
            type="email"
            placeholder="Email Address"
            className={classNames("form-control", {
              "is-invalid": errors.email
            })}
            {...register("email", { required: true, pattern: emailPattern })}
          />
          {errors.email && (
            <span style={{ color: "var(--danger, red)" }}>
              A valid email is required
            </span>
          )}
        </div>

        <Button primary className="btn-block" type="submit" loading={loading}>
          Continue
        </Button>
      </form>

      <p className="mt-3">
        Back to <Link to={LOGIN_PATH}>Log In</Link>?
      </p>
    </div>
  );
};
