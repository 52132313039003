import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";
import { MediaComment } from "../../interfaces/media";

export interface CommentsState extends EntityState<MediaComment, string> {
  loading: boolean;
}

@StoreConfig({ name: "comments" })
export class CommentsStore extends EntityStore<CommentsState> {
  constructor() {
    super({ loading: false });
  }

  updateComment(id: string, params: Partial<MediaComment>): void {
    this.update(id, { ...params });
  }
}

export const commentsStore = new CommentsStore();
