import { mdiArrowLeft } from "@mdi/js";
import Icon from "@mdi/react";
import React from "react";
import { Link } from "react-router-dom";
import { AddPaymentMethodForm } from "../../../containers/settings/payment-method-card/add-payment-method-form";
import { SettingsLayout } from "../../../layouts/settings/settings.layout";
import { BILLING_PATH } from "../../../routes";

export const AddPaymentMethodPage: React.FC = () => {
  return (
    <SettingsLayout>
      <div className="container-fluid my-2">
        <div className="row">
          <div className="col mt-3 mb-2">
            <h4 className="title text-black d-flex align-items-center">
              <Link to={BILLING_PATH}>
                <Icon
                  path={mdiArrowLeft}
                  size="25px"
                  color="black"
                  className="mr-3"
                />
              </Link>{" "}
              Add payment method
            </h4>
          </div>
        </div>
        <div className="row">
          <div className="col-md-5 py-3">
            <AddPaymentMethodForm />
          </div>
        </div>
      </div>
    </SettingsLayout>
  );
};
