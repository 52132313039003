import {
  Cue,
  isEmptyCue,
  isOverlapingCue,
  Transcription
} from "@getsubly/common";
import { isStartAfterEnd } from "@getsubly/common/dist/assParser/validation";
import { sanitizeString } from "./strings";

export const getSubtitleIdx = (
  subtitle: Cue,
  transcription: Transcription
): number => {
  return transcription.findIndex((c) => c.id === subtitle.id);
};
export const validateCueAndAddToTranscription = (
  cue: Cue,
  transcription: Transcription
): Transcription => {
  // If any problems are found in isEmptyCue, isOverlapingCue, isStartAfterEnd
  // errors are effectively added to the Cue (and its neighbours, if there are overlaps)
  if (cue.text !== undefined) {
    cue = isEmptyCue(cue);
  }

  if (cue.start !== undefined || cue.end !== undefined) {
    const subtitleIdx = getSubtitleIdx(cue, transcription);

    // Checks for overlapping and update the previous and next cues as well
    const [prevCue, updatedCue, nextCue] = isOverlapingCue(
      transcription,
      cue,
      subtitleIdx
    );

    transcription.splice(subtitleIdx, 1, updatedCue);

    if (prevCue) {
      transcription.splice(subtitleIdx - 1, 1, prevCue);
    }

    if (nextCue) {
      transcription.splice(subtitleIdx + 1, 1, nextCue);
    }

    // Checks if the cue start time is after the end time
    cue = isStartAfterEnd(updatedCue);
    transcription.splice(subtitleIdx, 1, cue);
  }

  // Non-breaking spaces can mysteriously appear in contenteditable controls
  cue.text = cue.text.replace(/&nbsp;/g, " ");

  return transcription;
};

export const countTranscriptionErrors = (
  transcription: Transcription
): number => {
  return transcription.reduce(
    (n, c) => (c.metadata?.errors?.length ? n + c.metadata?.errors?.length : n),
    0
  );
};

export const sanitizeTranscription = (
  transcription: Transcription = []
): Transcription => {
  return transcription.map((c) => ({
    ...c,
    text: sanitizeString(c.text)
  }));
};
