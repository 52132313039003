import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/auth.context";
import {
  DowngradeSubscription,
  PlansTableContext
} from "../../contexts/plans-table.context";
import { useAccounts } from "../../hooks/use-accounts";
import { useAnalyticsWithAuth } from "../../hooks/use-analytics-with-auth";
import { usePlan } from "../../hooks/use-plan";
import { SublyPlan } from "../../interfaces/billing";
import { DASHBOARD_PATH, PLANS_UPGRADE_PATH } from "../../routes";
import {
  getDiscount,
  getDiscountCode,
  isBusinessPlan,
  isInferiorPlan,
  ShowDiscountOptions
} from "../../utils/plans";
import { LoadingIcon } from "../loading-icon/loading-icon";
import { Button } from "./button";
import { startTrial } from "../../api/billing.service";
import { notificationError } from "../notification";
import { EN } from "../../assets/i18n/en";
import { TrialConfirmModal } from "../../containers/trial/trial-confirm-modal";
import { useModal } from "../../hooks/use-modal";
import { ModalType } from "../../interfaces/modal-type";
import styles from "./button.module.scss";

export interface LocationState {
  redirect?: string;
}
interface PlanUpgradeButtonProps {
  plan: SublyPlan;
  loading: boolean;
  handleDowngrade: ({ subscriptionId, plan }: DowngradeSubscription) => void;
  handleDontCancel: (subscriptionId: string) => void;
  isComparisonTable?: boolean;
  className?: string;
}
export const PlanUpgradeButton: React.FC<PlanUpgradeButtonProps> = ({
  plan,
  loading,
  handleDowngrade,
  isComparisonTable
}) => {
  const { user, partner, isEducation } = React.useContext(AuthContext);
  const {
    interval,
    coupon: stripeCoupon,
    couponCode: stateCouponCode
  } = React.useContext(PlansTableContext);
  const { plan: currentPlan, isTrial, hasTrialed } = usePlan();
  const { subscription, deal, plans, paymentMethods } = useAccounts();
  const state = useLocation().state as LocationState;
  const { trackEventWithAuth } = useAnalyticsWithAuth();
  const [loadingTrial, setLoadingTrial] = React.useState(false);
  const navigate = useNavigate();
  const subscriptionId = subscription?.id;
  const [showModal, hideModal] = useModal(ModalType.TrialConfirmModal);

  // If user has a deal, she shouldn't even be on this page so we hide the buttons
  if (deal?.id) {
    return null;
  }

  if (loading) {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <LoadingIcon />
      </div>
    );
  }

  if (plan === SublyPlan.BusinessYearlyCustom) {
    return (
      <Button
        primary
        to="https://www.getsubly.com/sales"
        type="blank"
        className={styles["plans-button"]}
        onClick={() => trackEventWithAuth("Click on custom business plan")}
      >
        Book a demo
      </Button>
    );
  }

  if (plan === currentPlan && !isTrial) {
    return (
      <Button secondary to="#" className={styles["plans-button"]} disabled>
        Current plan
      </Button>
    );
  }

  if (plan === currentPlan && isTrial && subscription?.id) {
    if (paymentMethods?.length) {
      const handleUpgradeTrial = () => {
        showModal(
          <TrialConfirmModal
            onDismiss={hideModal}
            subscription={subscription}
            fromPlansPage
          />
        );
      };

      return (
        <div className="d-flex flex-row justify-content-between align-items-center">
          <Button
            primary
            to="#"
            className={styles["plans-button"]}
            onClick={handleUpgradeTrial}
          >
            Upgrade now
          </Button>
          <div>
            <Link
              to="#"
              className={styles.cancelTrial}
              onClick={() =>
                handleDowngrade({
                  subscriptionId: subscription.id,
                  plan: SublyPlan.Free
                })
              }
            >
              Cancel trial
            </Link>
          </div>
        </div>
      );
    } else {
      return (
        <div className="d-flex flex-row justify-content-between align-items-center">
          <Button
            primary
            className={styles["plans-button"]}
            to={{
              pathname: PLANS_UPGRADE_PATH
            }}
            state={{
              ...state,
              plan,
              interval,
              coupon: stripeCoupon
            }}
            onClick={() =>
              trackEventWithAuth("Click to upgrade", { toPlan: plan })
            }
          >
            Choose plan
          </Button>
          <div>
            <Link
              to="#"
              className={styles.cancelTrial}
              onClick={() =>
                handleDowngrade({
                  subscriptionId: subscription.id,
                  plan: SublyPlan.Free
                })
              }
            >
              Cancel trial
            </Link>
          </div>
        </div>
      );
    }
  }

  if (subscriptionId) {
    if (isInferiorPlan(plan, currentPlan)) {
      if (plan === SublyPlan.Free) {
        return (
          <Button
            secondary
            className={styles["plans-button"]}
            onClick={() => {
              trackEventWithAuth("Click to cancel subscription");
              handleDowngrade({ subscriptionId, plan });
            }}
          >
            Downgrade now
          </Button>
        );
      }

      if (!isTrial) {
        return (
          <Button
            primary
            className={styles["plans-button"]}
            onClick={() => {
              trackEventWithAuth("Click to downgrade", { toPlan: plan });
              handleDowngrade({ subscriptionId, plan });
            }}
          >
            Choose plan
          </Button>
        );
      }
    }
  }

  const startBusinessTrial = async () => {
    const businessPlan = plans.find(({ name }) => SublyPlan.Business === name);

    try {
      if (!businessPlan) throw new Error();

      setLoadingTrial(true);

      await startTrial({
        productId: businessPlan?.id
      });

      navigate(DASHBOARD_PATH);
    } catch (error) {
      console.error(error);
      notificationError(EN.error.defaultMessage);
      setLoadingTrial(false);
    }
  };

  if (!hasTrialed && isBusinessPlan(plan)) {
    return (
      <Button
        primary
        className={styles["plans-button"]}
        onClick={startBusinessTrial}
        loading={loadingTrial}
      >
        Start 7 day trial
      </Button>
    );
  }

  const discountOptions: ShowDiscountOptions = {
    partner,
    isEducation,
    email: user?.email,
    coupon: stripeCoupon,
    couponCode: stateCouponCode
  };
  const hasDiscount = getDiscount(plan, discountOptions) > 0;
  const couponCode = getDiscountCode(hasDiscount, discountOptions);

  return (
    <Button
      primary
      className={styles["plans-button"]}
      to={{
        pathname: PLANS_UPGRADE_PATH
      }}
      state={{
        ...state,
        plan,
        interval,
        coupon: stripeCoupon,
        couponCode
      }}
      onClick={() => trackEventWithAuth("Click to upgrade", { toPlan: plan })}
    >
      {isComparisonTable ? "Upgrade Now" : "Choose plan"}
    </Button>
  );
};
