import classNames from "classnames";
import React from "react";
import { Link, LinkProps } from "react-router-dom";
import { AnyOnClickHandler } from "../../interfaces/types";
import { LoadingIcon } from "../loading-icon/loading-icon";
import styles from "./button.module.scss";

export interface ButtonProps {
  primary?: boolean;
  secondary?: boolean;
  tertiary?: boolean;
  danger?: boolean;
  border?: boolean;
  secondaryBorder?: boolean;
  primaryReverse?: boolean;
  borderless?: boolean;
  className?: string;
  isDropdown?: boolean;
  isFull?: boolean;
  outlined?: boolean;
  loading?: boolean;
  disabled?: boolean;
  to?: LinkProps["to"];
  type?: "link" | "submit" | "blank";
  onClick?: AnyOnClickHandler;
  noLink?: boolean;
  style?: React.CSSProperties;
  state?: unknown;
}
export const Button: React.FC<ButtonProps> = ({
  primary,
  secondary,
  tertiary,
  danger,
  border,
  secondaryBorder,
  primaryReverse,
  borderless,
  className,
  isDropdown,
  isFull,
  outlined,
  loading = false,
  disabled,
  to,
  type = "link",
  onClick,
  style,
  noLink,
  state,
  children
}) => {
  const isDisabled = disabled || loading;
  const buttonClass = classNames(styles["btn"], className, {
    [styles["btn-primary"]]: primary,
    [styles["btn-secondary"]]: secondary,
    [styles["btn-tertiary"]]: tertiary,
    [styles["btn-danger"]]: danger,
    [styles["btn-border"]]: border,
    [styles["btn-border-secondary"]]: secondaryBorder,
    [styles["btn-primary-reverse"]]: primaryReverse,
    [styles["btn-borderless"]]: borderless,
    [styles.disabled]: isDisabled,
    [styles["btn-full"]]: isFull,
    [styles.btnOutlined]: outlined,
    "dropdown-toggle": isDropdown
  });

  const renderLoadingIcon = (
    <LoadingIcon loading={loading} primary={primary} secondary={secondary} />
  );

  if (type === "blank") {
    return (
      <a
        href={to as string}
        target="_blank"
        rel="noopener noreferrer"
        className={buttonClass}
        onClick={onClick}
        style={style}
      >
        {renderLoadingIcon} {children}
      </a>
    );
  }

  if (type === "submit") {
    return (
      <button
        className={buttonClass}
        disabled={isDisabled}
        type="submit"
        onClick={onClick}
        style={style}
      >
        {renderLoadingIcon} {children}
      </button>
    );
  }

  if (!to && onClick && noLink) {
    return (
      <button
        className={buttonClass}
        disabled={isDisabled}
        type="button"
        onClick={onClick}
        style={style}
      >
        {renderLoadingIcon} {children}
      </button>
    );
  }

  const hrefTo = to ?? "#";

  if (isDisabled) {
    return (
      <div className={styles["button-disabled"]}>
        <Link
          className={buttonClass}
          to={hrefTo}
          state={state}
          onClick={onClick}
          style={style}
        >
          {renderLoadingIcon} {children}
        </Link>
      </div>
    );
  }

  return (
    <Link
      className={buttonClass}
      to={hrefTo}
      onClick={onClick}
      style={style}
      state={state}
    >
      {renderLoadingIcon} {children}
    </Link>
  );
};
