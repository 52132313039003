import React, { useState, useEffect } from "react";
import classNames from "classnames";
import "./profile-image-icon.scss";
import Icon from "@mdi/react";
import { mdiCamera } from "@mdi/js";
import { getColor } from "../../utils/colors";

interface ProfileImageIconProps {
  src?: string;
  name: string;
  size?: string;
  twoLetters?: boolean;
  className?: string;
  isUploader?: boolean;
}
export const ProfileImageIcon: React.FC<ProfileImageIconProps> = ({
  src,
  name,
  size = "32px",
  twoLetters = false,
  isUploader,
  className
}) => {
  const [loaded, setLoaded] = useState(false);
  const [loadingError, setLoadingError] = useState(false);

  useEffect(() => {
    setLoadingError(false);
  }, [src]);

  if (loadingError || !src) {
    if (isUploader) {
      return (
        <div className="uploader-icon-holder">
          <Icon path={mdiCamera} color="black" size="2rem" />
          <div className="uploader-icon-holder-text">Upload</div>
        </div>
      );
    }

    return (
      <div
        className={classNames(
          "letter-icon rounded-circle animate animate-border d-flex align-items-center justify-content-center",
          className
        )}
        style={{ width: size, height: size, backgroundColor: getColor(name) }}
      >
        {getInitials(name, twoLetters).toUpperCase()}
      </div>
    );
  }

  return (
    <img
      src={src}
      className={classNames(
        "rounded-circle animate animate-border d-inline-block align-top profile-image",
        className
      )}
      onError={() => setLoadingError(true)}
      onLoad={() => setLoaded(true)}
      style={{ display: loaded ? "block" : "none", width: size, height: size }}
      alt={name}
    />
  );
};

const getInitials = (name: string, twoLetters: boolean): string => {
  // If name is null / empty, use S for Subly
  if (!name?.length) {
    return "S";
  }

  const firstLetter = name[0];
  const allNames = name.split(" ");

  if (allNames.length > 1 && twoLetters) {
    const lastLetter = allNames[allNames.length - 1][0];

    return `${firstLetter}${lastLetter}`;
  }

  return firstLetter;
};
