export const DASHBOARD_PATH = "/";

export const ACCOUNT_PATH = "/settings/account";
export const EDIT_ACCOUNT_PATH = "/settings/account/edit";
export const PLANS_PATH = "/settings/account/plans";
export const PLANS_UPGRADE_PATH = "/settings/account/plans/upgrade";

export const BILLING_PATH = "/settings/billing";
export const ADD_PAYMENT_METHOD_PATH = "/settings/billing/payment-method";
export const ADD_BILLING_DETAILS_PATH = "/settings/billing/billing-details";
export const INVOICES_PATH = "/settings/billing/invoices";

export const TEAM_PATH = "/settings/team";
export const REFERRAL_PATH = "/settings/referral";
export const HELP_PATH = "/settings/help";

export const VIDEOS = "/videos";
export const VIDEO_DETAILS = "/videos/:mediaId";

export const MEDIA_PATH = "/media";
export const MEDIA_DETAILS_PATH = "/media/:mediaId";

export const LOGIN_PATH = "/login";
export const SIGN_UP_PATH = "/signup";
export const OAUTH_CALLBACK_PATH = "/auth/google/process";
export const OAUTH_CALLBACK_PROCESS_PATH = "/auth/google/callback";

export const ZOOM_CALLBACK_PATH = "/callback/zoom";
export const APPS_HOME_PATH = "/apps";
export const APPS_PATH = "/apps/:appId";

export const PARTNER_PATH = "/partner/:partner";
export const PARTNER_VERIFICATION_PATH = "/partner/:partner/verify/:id/:code";

export const FOUNDING_MEMBERS_PATH = "/foundingmembers";

export const FORGET_PASSWORD_PATH = "/forgot-password";
export const RESET_PASSWORD_PATH = "/reset/:id/:code";
export const VERIFICATION_PATH = "/verify/:id/:code";
export const EXTERNAL_VERIFICATION_PATH = "/verify/external/:id/:code";
export const EMAIL_VERIFICATION_PATH = "/verify-email/:id/:code";

export const ONBOARDING_PATH = "/welcome";
export const TRIAL_ONBOARDING_PATH = "/welcome-trial";

export const PROMO_AUTH_LOGIN_PATH = "/p/login/:plan";
export const PROMO_AUTH_PATH = "/p/:plan";

export const PREVIEW_VIDEO = "/preview/:publicShareId";

// Public website
export const FAQ_URL = "https://getsublyhelp.zendesk.com/hc/en-us";
export const PRIVACY_URL = "https://www.getsubly.com/legal/privacy-policy";
export const TERMS_URL = "https://www.getsubly.com/legal/terms-of-service";
export const WEBSITE_URL = "https://getsubly.com/";
export const SUPPORT_URL = "mailto:support@getsubly.com";
export const SALES_CONTACT = "https://www.getsubly.com/contact/sales";
