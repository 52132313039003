import React from "react";
import { Link } from "react-router-dom";
import { ClockIcon } from "../../components/icons/icons";
import { SublyPlan } from "../../interfaces/billing";
import { PLANS_UPGRADE_PATH } from "../../routes";
import { parseCreditToText } from "../../utils/plans";

interface LowBalanceProps {
  credit: number;
  plan: SublyPlan;
}

export const LowBalance: React.FC<LowBalanceProps> = ({ credit, plan }) => {
  return (
    <li className="nav-item low">
      <Link
        to={{
          pathname: PLANS_UPGRADE_PATH
        }}
        state={{
          plan: plan,
          quantity: 20,
          topup: true
        }}
      >
        <ClockIcon className="mr-1" />
        {parseCreditToText(credit)} left. Top up minutes now
      </Link>
    </li>
  );
};
