import InformationCircleIcon from "@heroicons/react/outline/InformationCircleIcon";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Badge } from "../../../components/badge/badge";
import { UploadButton } from "../../../components/button/upload-button";
import { CloseIcon } from "../../../components/icons/icons";
import { LockFeaturePremium } from "../../../components/lock-feature/lock-feature-premium";
import { useAnalyticsWithAuth } from "../../../hooks/use-analytics-with-auth";
import { usePermissions } from "../../../hooks/use-permissions";
import { useUpload } from "../../../hooks/use-upload";
import { SublyPlan } from "../../../interfaces/billing";
import { uploadStore } from "../../../state/upload";
import { readFileAsTranscription } from "../../../utils/file";
import { pluralize } from "../../../utils/strings";
import {
  countTranscriptionErrors,
  sanitizeTranscription
} from "../../../utils/transcription";
import { ToolTip } from "../../tooltip/tooltip";
import styles from "./srt-uploader.module.scss";

interface SrtUploaderProps {
  className?: string;
}
export const SrtUploader: React.FC<SrtUploaderProps> = ({ className }) => {
  const [srtFile, setSrtFile] = useState<File>();
  const [error, setError] = useState<string>("");
  const [warning, setWarning] = useState<string>();
  const { totalCount } = useUpload();
  const { hasPermission: hasPremiumPermission } = usePermissions(
    SublyPlan.Premium
  );
  const { trackEventWithAuth } = useAnalyticsWithAuth();

  useEffect(() => {
    if (totalCount > 1) {
      setSrtFile(undefined);
      uploadStore.setTranscription(undefined);
    }
  }, [totalCount]);

  const handleSrtSelection = async (file: File) => {
    setWarning("");
    setError("");
    setSrtFile(file);

    const srtData = await readFileAsTranscription(file);

    const srt = sanitizeTranscription(srtData);

    uploadStore.setTranscription(srt);

    trackEventWithAuth("Select SRT file to upload");

    if (!srt) {
      setError("Invalid srt file");
    } else {
      const errorCount = countTranscriptionErrors(srt);

      if (errorCount) {
        setWarning(
          `Transcript has ${errorCount} ${pluralize(errorCount, "issue")}.`
        );
      }
    }
  };

  const textStyle = error
    ? styles.error
    : warning
    ? styles.warning
    : styles.success;
  const closeIconStyle = error
    ? styles.errorCloseIcon
    : warning
    ? styles.warningCloseIcon
    : styles.successCloseIcon;

  return (
    <div className={className}>
      <div className="d-flex flex-row mb-2">
        <h6 className="mb-0">Upload SRT file</h6>
        <ToolTip
          text="SRT files can be added when uploading a single video. Videos will not be transcribed when SRT files are added. Minutes will still be deducted from your balance.
            Note: You will be unable to upload a SRT file if you select multiple files or bulk upload URLs."
        >
          <InformationCircleIcon className={styles["information-icon"]} />
        </ToolTip>
        {!hasPremiumPermission && <Badge className="ml-2">Premium</Badge>}
      </div>
      <LockFeaturePremium mixpanelEvent="Srt upload - Pop up to upgrade">
        <div className="d-flex flex-row align-items-center flex-wrap">
          <ToolTip
            text={`SRT file can only be added to 1 file. Please remove ${
              totalCount - 1
            } out of ${totalCount} of the selected files.`}
            disable={totalCount < 2}
          >
            <UploadButton
              secondary
              accept=".srt"
              className="d-block mx-0"
              handleChange={handleSrtSelection}
              disabled={totalCount > 1}
            >
              Upload SRT file
            </UploadButton>
          </ToolTip>

          {srtFile && (
            <>
              <label className="w-25 mb-0 text-truncate">{srtFile.name}</label>

              <label className={classNames("ml-auto mb-0", textStyle)}>
                {error || warning}
              </label>

              <CloseIcon
                className={classNames(
                  "close ml-auto",
                  styles.closeIcon,
                  closeIconStyle
                )}
                onClick={() => setSrtFile(undefined)}
              />
            </>
          )}
        </div>
      </LockFeaturePremium>
    </div>
  );
};
