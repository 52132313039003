import React from "react";
import { useForm } from "react-hook-form";
import { renameMedia } from "../../api/media.service";
import { Button } from "../../components/button/button";
import { Fieldset } from "../../components/form-controls/fieldset";
import { BasicMedia } from "../../interfaces/media";
import { Modal } from "../modal/modal";
import styles from "./rename-media-modal.module.scss";

interface RenameMediaModalProps {
  media: BasicMedia;
  hideModal: () => void;
}
export const RenameMediaModal: React.FC<RenameMediaModalProps> = ({
  media,
  hideModal
}) => {
  return (
    <Modal
      onDismiss={hideModal}
      hasCloseIcon
      closeIconClassName={styles["close-icon"]}
      className={styles.modal}
      disableBackgroundDismiss
    >
      <RenameMediaModalContainer media={media} hideModal={hideModal} />
    </Modal>
  );
};

type RenameForm = { name: string };

const RenameMediaModalContainer: React.FC<RenameMediaModalProps> = ({
  media,
  hideModal
}) => {
  const [isLoading, setLoading] = React.useState(false);
  const { register, handleSubmit } = useForm<RenameForm>({
    defaultValues: { name: media.name }
  });

  const handleSave = async ({ name }: RenameForm) => {
    setLoading(true);

    await renameMedia(media.mediaId, name);

    hideModal();
  };

  return (
    <>
      <h5 className="mb-3 d-flex align-items-center">Rename file</h5>

      <form onSubmit={handleSubmit(handleSave)}>
        <Fieldset title="File name">
          <input
            className="form-control"
            {...register("name", { required: true })}
          />
        </Fieldset>

        <div className="d-flex justify-content-end">
          <Button secondary onClick={hideModal}>
            Cancel
          </Button>
          <Button
            primary
            className="ml-2 px-4"
            loading={isLoading}
            type="submit"
          >
            Rename
          </Button>
        </div>
      </form>
    </>
  );
};
