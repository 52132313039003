import React, { useState } from "react";
import { mdiChevronDown } from "@mdi/js";
import Icon from "@mdi/react";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu
} from "../../components/dropdown/dropdown";
import settings from "../../config/settings/settings";
import styles from "./language-dropdown.module.scss";

interface LanguageDropdownProps {
  fileId: string;
  onLanguageChange: (id: string, language: string) => void;
}
export const LanguageDropdown: React.FC<LanguageDropdownProps> = ({
  fileId,
  onLanguageChange
}) => {
  const [label, setLabel] = useState("Select language");
  const dropdownRef = React.useRef(null);

  const handleClickLanguage = (language: string): void => {
    if (!language) {
      return;
    }
    setLabel(language);
    onLanguageChange(fileId, language);
  };

  const renderLanguage = settings.translation.languages.map((l) => {
    return (
      <DropdownItem
        key={l.language}
        onClick={() => handleClickLanguage(l.language)}
        selected={l.language === label}
        closeOnClick={false}
      >
        {l.language}
      </DropdownItem>
    );
  });

  return (
    <div ref={dropdownRef} className={styles.dropdown}>
      <Dropdown>
        <DropdownButton
          dropdownEl={dropdownRef}
          className={styles["dropdown-button"]}
        >
          {label}
          <Icon path={mdiChevronDown} size="1.2em" />
        </DropdownButton>
        <DropdownMenu className={styles["dropdown-menu"]}>
          <DropdownItem>
            <p className={styles["dropdown-header"]}>Select language</p>
          </DropdownItem>
          <div className={styles["language-list"]}>{renderLanguage}</div>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};
