import classNames from "classnames";
import React from "react";
import { useForm } from "react-hook-form";
import { Link, useParams } from "react-router-dom";
import { resetPassword, ResetPasswordParams } from "../../api/auth.service";
import { EN } from "../../assets/i18n/en";
import { Alert } from "../../components/alert/alert";
import { Button } from "../../components/button/button";
import { LOGIN_PATH } from "../../routes";
import { passwordPattern } from "../../utils/regex-patterns";
import { PasswordRules } from "../../components/password-rules/password-rules";
import styles from "./auth.module.scss";

export const ResetPassword: React.FC = () => {
  return (
    <div className={styles.container}>
      <h2 className="text-center mb-4">Reset your password</h2>
      <ResetPasswordForm />
    </div>
  );
};

const ResetPasswordForm: React.FC = () => {
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [alert, setAlert] = React.useState<JSX.Element>();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    getValues
  } = useForm<ResetPasswordParams>();
  const { id = "", code = "" } = useParams();

  const onSubmit = async (form: ResetPasswordParams) => {
    form = { ...form, id, code };

    try {
      setLoading(true);

      await resetPassword(form);

      setSuccess(true);
    } catch (e) {
      console.error(e);
      setAlert(EN.error.defaultMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setAlert(undefined);
  };

  if (success) {
    return (
      <>
        <p className="text-center">Your password was successfuly changed.</p>
        <Button to={LOGIN_PATH} primary className="btn-block mt-3">
          Go to Sign In
        </Button>
      </>
    );
  }

  return (
    <>
      {alert && (
        <Alert
          danger
          title="Something went wrong"
          closable
          onClose={handleClose}
        >
          {alert}
        </Alert>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <input
            placeholder="Input your password"
            className={classNames("form-control", {
              "is-invalid": errors.password
            })}
            type="password"
            {...register("password", {
              required: true,
              pattern: passwordPattern
            })}
          />
        </div>
        <div className="form-group">
          <input
            placeholder="Confirm your password"
            className={classNames("form-control", {
              "is-invalid": errors.passwordConfirm
            })}
            type="password"
            {...register("passwordConfirm", {
              required: true,
              validate: {
                matchesPreviousPassword: (value) => {
                  const { password } = getValues();
                  return password === value || "Passwords should match";
                }
              }
            })}
          />
          {errors.passwordConfirm && (
            <span style={{ color: "var(--danger, red)" }}>
              {errors.passwordConfirm.message}
            </span>
          )}
        </div>

        <PasswordRules
          value={watch("password")}
          className="d-flex flex-row flex-wrap"
        />

        <Button
          primary
          className="btn-block mt-4"
          type="submit"
          loading={loading}
        >
          Reset your password
        </Button>
      </form>

      <p className="mt-3">
        Back to <Link to={LOGIN_PATH}>Sign In</Link>?
      </p>
    </>
  );
};
