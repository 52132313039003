import React from "react";
import { Button } from "../../../components/button/button";
import { Modal } from "../../modal/modal";
import styles from "./upgrade-to-translate-modal.module.scss";
import { PLANS_PATH } from "../../../routes";
interface UpgradeToTranslateModal {
  closeModal: () => void;
}
export const UpgradeToTranslateModal: React.FC<UpgradeToTranslateModal> = ({
  closeModal
}) => {
  return (
    <Modal onDismiss={closeModal} className={styles.modal} hasCloseIcon>
      <div className={styles.content}>
        <h5 className={styles["title"]}>
          Upgrade now to translate your content
        </h5>

        <p>
          Translation is available for Premium or Business plans. Upgrade your
          account now to start translating content.
        </p>

        <div className={styles["btn-container"]}>
          <Button className={styles["cancel-button"]} onClick={closeModal}>
            Maybe later
          </Button>
          <Button primary to={PLANS_PATH} onClick={closeModal}>
            Browse plans
          </Button>
        </div>
      </div>
    </Modal>
  );
};
