import {
  mdiAccount,
  mdiAccountMultiple,
  mdiClockTimeFourOutline,
  mdiFolder
} from "@mdi/js";
import React from "react";
import { useAccounts } from "../../../hooks/use-accounts";
import { useFolders } from "../../../hooks/use-folders";
import { usePermissions } from "../../../hooks/use-permissions";
import { SublyPlan } from "../../../interfaces/billing";
import { createFolder } from "../../../state/folders/folders.service";
import { DefaultFoldersId } from "../../../state/folders/folders.store";
import { SidebarContainer } from "./sidebar-container";
import { SidebarFolder, SidebarFolderSekeleton } from "./sidebar-folder";
import {
  SidebarItem,
  SidebarItemDivider,
  SidebarItemLabel
} from "./sidebar-items";

export const DashboardSidebar: React.FC = () => {
  const { selectedId, selectFolder } = useFolders();

  return (
    <SidebarContainer>
      <SidebarItem
        isActive={selectedId === null}
        onClick={() => selectFolder(null)}
      >
        <SidebarItemLabel label="Recent" icon={mdiClockTimeFourOutline} />
      </SidebarItem>
      <SidebarItem
        isActive={selectedId === DefaultFoldersId.All}
        onClick={() => selectFolder(DefaultFoldersId.All)}
      >
        <SidebarItemLabel label="All Projects" icon={mdiFolder} />
      </SidebarItem>
      <SidebarPrivateSection />
      <SidebarSharedSection />
      <SidebarSharedWithMeSection />
    </SidebarContainer>
  );
};

const SidebarPrivateSection: React.FC = () => {
  const { privateFolders, countPrivateAllFiles } = useFolders();
  const { isLoading, currentAccount } = useAccounts();
  const [loadingPrivate, setLoadingPrivate] = React.useState(false);

  const renderPrivateFolders = isLoading ? (
    <SidebarFolderSekeleton />
  ) : (
    privateFolders.map((f) => <SidebarFolder folder={f} key={f.id} />)
  );

  const createNewPrivateFolder = async () => {
    try {
      setLoadingPrivate(true);
      await createFolder();
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingPrivate(false);
    }
  };

  const count = countPrivateAllFiles(currentAccount);

  return (
    <>
      <SidebarItemDivider />

      <SidebarItem addFolder={createNewPrivateFolder} loading={loadingPrivate}>
        <SidebarItemLabel label="Private" icon={mdiAccount} />
      </SidebarItem>
      {!isLoading && (
        <SidebarFolder
          folder={{
            name: "All files",
            id: DefaultFoldersId.AllPrivate,
            count
          }}
        />
      )}
      {renderPrivateFolders}
    </>
  );
};

const SidebarSharedSection: React.FC = () => {
  const { sharedFolders, countFiles } = useFolders();
  const { isLoading } = useAccounts();
  const [loadingShared, setLoadingShared] = React.useState(false);
  const { hasPermission } = usePermissions(SublyPlan.Premium);

  if (!hasPermission) {
    return null;
  }

  const renderSharedFolders = sharedFolders.map((f) => (
    <SidebarFolder folder={f} key={f.id} />
  ));

  const createNewSharedFolder = async () => {
    try {
      setLoadingShared(true);
      await createFolder(true);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingShared(false);
    }
  };

  return (
    <>
      <SidebarItemDivider />

      <SidebarItem addFolder={createNewSharedFolder} loading={loadingShared}>
        <SidebarItemLabel label="Shared" icon={mdiAccountMultiple} />
      </SidebarItem>
      {!isLoading && (
        <SidebarFolder
          folder={{
            name: "All files",
            id: DefaultFoldersId.AllShared,
            count: countFiles(sharedFolders)
          }}
        />
      )}
      {isLoading ? <SidebarFolderSekeleton /> : renderSharedFolders}
    </>
  );
};

const SidebarSharedWithMeSection: React.FC = () => {
  const { isLoading, currentAccount } = useAccounts();
  const { countSharedWithMeAllFiles } = useFolders();

  return (
    <>
      <SidebarItemDivider />

      <SidebarItem>
        <SidebarItemLabel label="Shared with me" icon={mdiAccountMultiple} />
      </SidebarItem>
      {!isLoading && (
        <SidebarFolder
          folder={{
            name: "All files",
            id: DefaultFoldersId.AllSharedWithMe,
            count: countSharedWithMeAllFiles(currentAccount)
          }}
        />
      )}
    </>
  );
};
