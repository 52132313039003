import axios, { AxiosResponse } from "axios";
import config from "../config";
import { getAccountId } from "../config/settings/settings.service";
import { UserAccount } from "../interfaces/account";
import {
  BasicStripeCustomer,
  CreateSubscriptionResponse,
  Deal,
  Product,
  PurchaseResponse,
  UpdateSubscriptionResponse
} from "../interfaces/billing";
import {
  accountQuery,
  accountStore,
  DEFAULT_ACCOUNT_SETTINGS
} from "../state/account";
import { foldersStore } from "../state/folders/folders.store";
import { mediaStore } from "../state/media";
import { showPlanUpgradeModal } from "../utils/plans";
import { getAccountSettings, updateAccountSettings } from "./account.service";
import { getAccessToken } from "./auth.service";
import { handleError } from "./handle-error";

const baseURL = `${config.apiUrl}/api/v1`;
const billingURL = `${baseURL}/billing`;

interface GetIntentResponse {
  message: string;
  intent: string;
}
export const getIntent = async (): Promise<
  AxiosResponse<GetIntentResponse>
> => {
  return await axios.get<GetIntentResponse>(`${getAccountId()}/setup-intent`, {
    baseURL: billingURL,
    headers: { "x-access-token": await getAccessToken() }
  });
};

interface GetPlansResponse {
  message: string;
  plans: Product[];
}
export const getPlans = async (options = { force: false }): Promise<void> => {
  const account = accountQuery.getValue();

  if (account.loadedPlans && !options.force) {
    return;
  }

  accountStore.update({ loadingPlans: true });

  const {
    data: { plans }
  } = await axios.get<GetPlansResponse>(`/plans`, {
    baseURL: billingURL,
    headers: { "x-access-token": await getAccessToken() }
  });

  accountStore.update({
    plans,
    loadingPlans: false,
    loadedPlans: true
  });
};

interface GetCreditResponse {
  message: string;
  credit: {
    creditFreeSeconds?: number;
    creditPaidSeconds?: number;
    creditExtraSeconds?: number;
    creditPaygSeconds?: number;
  };
}
export const getCredit = async (options = { force: false }): Promise<void> => {
  const accountCredit = accountQuery.credit;

  if (accountCredit?.loaded && !options.force) {
    return;
  }

  accountStore.update({
    credit: { loading: true, loaded: Boolean(accountCredit?.loaded) }
  });

  const beforeSeconds = accountCredit?.total ?? 0;

  const {
    data: { credit }
  } = await axios.get<GetCreditResponse>(`${getAccountId()}/credit`, {
    baseURL: billingURL,
    headers: { "x-access-token": await getAccessToken() }
  });

  const creditFreeSeconds = credit.creditFreeSeconds || 0;
  const creditPaidSeconds = credit.creditPaidSeconds || 0;
  const creditExtraSeconds = credit.creditExtraSeconds || 0;
  const creditPaygSeconds = credit.creditPaygSeconds || 0;
  const totalCredit =
    creditFreeSeconds +
    creditPaidSeconds +
    creditExtraSeconds +
    creditPaygSeconds;

  const showUpgradeModal = showPlanUpgradeModal(
    accountQuery.currentPlan,
    beforeSeconds,
    totalCredit,
    accountQuery.isTrial,
    accountQuery.isPayg
  );

  accountStore.update({
    credit: {
      loading: false,
      loaded: true,
      free: creditFreeSeconds,
      paid: creditPaidSeconds,
      extra: creditExtraSeconds,
      payg: creditPaygSeconds,
      total: totalCredit,
      showUpgradeModal
    }
  });
};

interface GetCustomerOptions {
  force?: boolean;
  getSettings?: boolean;
  reset?: boolean;
  skipCache?: boolean;
}
interface GetCustomerResponse {
  customer: BasicStripeCustomer;
  account: UserAccount;
  deal?: Deal;
}
export const getCustomer = async (
  options?: GetCustomerOptions
): Promise<void> => {
  const defaultOptions = {
    force: false,
    getSettings: false,
    reset: false,
    skipCache: false
  };

  options = { ...defaultOptions, ...options };

  const shouldForce = options.force || options.skipCache;

  if (accountQuery.isLoaded && !shouldForce) {
    return;
  }

  if (options.reset) {
    mediaStore.set([]);
    foldersStore.set([]);
  }

  accountStore.update({ loading: true });

  const params = options.skipCache ? { r: Math.random() } : null;

  const {
    data: { account, customer, deal }
  } = await axios.get<GetCustomerResponse>(`${getAccountId()}`, {
    baseURL: `${baseURL}/accounts`,
    headers: { "x-access-token": await getAccessToken() },
    params
  });

  if (options.getSettings) {
    await getAccountSettings();
  }

  const creditFreeSeconds = account.creditFreeSeconds || 0;
  const creditPaidSeconds = account.creditPaidSeconds || 0;
  const creditExtraSeconds = account.creditExtraSeconds || 0;
  const creditPaygSeconds = account.creditPaygSeconds || 0;
  const totalCredit =
    creditFreeSeconds +
    creditPaidSeconds +
    creditExtraSeconds +
    creditPaygSeconds;

  accountStore.update({
    ...account,
    settings: {
      ...DEFAULT_ACCOUNT_SETTINGS,
      ...account.settings
    },
    billing: {
      loading: false,
      loaded: true,
      details: customer.details
    },
    subscription: customer.subscription,
    paymentMethods: customer.paymentMethods,
    invoices: customer.invoices,
    deal,
    credit: {
      loading: false,
      loaded: true,
      free: creditFreeSeconds,
      paid: creditPaidSeconds,
      extra: creditExtraSeconds,
      payg: creditPaygSeconds,
      total: totalCredit
    },
    additionalStorageBytes: account.additionalStorageBytes,
    loading: false,
    loaded: true,
    lastPlan: customer.lastPlan
  });

  if (account.folders?.length) {
    foldersStore.set(account.folders);
  }
};

export enum AccountType {
  Personal = "PERSONAL",
  Business = "BUSINESS"
}
export interface BillingDetailsParams {
  name: string;
  line1: string;
  line2?: string;
  city: string;
  postalCode: string;
  state: string;
  country: string;
  taxIdNumber?: string;
  taxIdType?: string;
  accountType: AccountType;
}
export const setBillingDetails = async (
  params: BillingDetailsParams,
  options = { force: true }
): Promise<void> => {
  const account = accountQuery.getValue();

  if (account.loading) {
    return;
  }

  accountStore.update({
    billing: { loading: true, loaded: Boolean(account.loaded) }
  });

  await axios.put(`/${getAccountId()}/details`, params, {
    baseURL: billingURL,
    headers: { "x-access-token": await getAccessToken() }
  });

  await getCustomer({ force: options.force });
};

export const removeBillingDetails = async (): Promise<void> => {
  const account = accountQuery.getValue();

  if (account.loading) {
    return;
  }

  accountStore.update({
    billing: { loading: true, loaded: Boolean(account.billing?.loaded) },
    loading: true
  });

  await axios.delete(`/${getAccountId()}/details`, {
    baseURL: billingURL,
    headers: { "x-access-token": await getAccessToken() }
  });

  await getCustomer({ force: true });
};

export const removePaymentMethod = async (): Promise<void> => {
  const account = accountQuery.getValue();

  if (account.loading) {
    return;
  }

  accountStore.update({
    billing: { loading: true, loaded: false },
    loading: true
  });

  await axios.delete(`/${getAccountId()}/card`, {
    baseURL: billingURL,
    headers: { "x-access-token": await getAccessToken() }
  });

  await getCustomer({ force: true });
};

interface CreateSubParams {
  productId: string;
  methodId?: string;
  promoId?: string;
  taxId?: string;
}
export const createSubscription = async (
  params: CreateSubParams
): Promise<CreateSubscriptionResponse> => {
  const { data } = await axios.post<CreateSubscriptionResponse>(
    `${getAccountId()}/subscription`,
    params,
    {
      baseURL: billingURL,
      headers: { "x-access-token": await getAccessToken() }
    }
  );

  return data;
};

interface UpdateSubParams extends Omit<CreateSubParams, "methodId"> {
  immediate: boolean;
  downgrading?: boolean;
}
export const updateSubscription = async (
  subscriptionId: string,
  params: UpdateSubParams
): Promise<UpdateSubscriptionResponse> => {
  const { data } = await axios.put<UpdateSubscriptionResponse>(
    `${getAccountId()}/subscription/${subscriptionId}`,
    params,
    {
      baseURL: billingURL,
      headers: { "x-access-token": await getAccessToken() }
    }
  );

  return data;
};

export const dontCancelSubscription = async (
  subscriptionId: string
): Promise<void> => {
  await axios.put(
    `${getAccountId()}/subscription/${subscriptionId}/dont-cancel`,
    {},
    {
      baseURL: billingURL,
      headers: { "x-access-token": await getAccessToken() }
    }
  );

  await getCustomer({ force: true });
};

export const cancelSchedule = async (subscriptionId: string): Promise<void> => {
  await axios.put(
    `${getAccountId()}/subscription/${subscriptionId}/cancel-schedule`,
    {},
    {
      baseURL: billingURL,
      headers: { "x-access-token": await getAccessToken() }
    }
  );

  await getCustomer({ force: true });
};

interface DowngradeParams {
  productId: string;
  immediate?: boolean;
}
export const downgradeSubscription = async (
  subscriptionId: string,
  { productId, immediate = false }: DowngradeParams
): Promise<void> => {
  await updateSubscription(subscriptionId, {
    productId,
    immediate,
    downgrading: true
  });
};

export interface CancelSubParams {
  immediate?: boolean;
  reason?: string;
}
export const cancelSubscription = async (
  subscriptionId: string,
  data: CancelSubParams = { immediate: false }
): Promise<void> => {
  await axios.put(
    `${getAccountId()}/subscription/${subscriptionId}/cancel`,
    data,
    {
      baseURL: billingURL,
      headers: { "x-access-token": await getAccessToken() }
    }
  );

  await getCustomer({ force: true });
};

interface PurchaseCreditParams {
  quantity: number;
  payg: boolean;
  methodId?: string;
  promoId?: string;
  taxId?: string;
}
export const purchaseCredit = async (
  params: PurchaseCreditParams
): Promise<PurchaseResponse> => {
  const { data } = await axios.post<PurchaseResponse>(
    `${getAccountId()}/purchase-credit`,
    params,
    {
      baseURL: billingURL,
      headers: { "x-access-token": await getAccessToken() }
    }
  );

  return data;
};

export interface Coupon {
  id: string;
  duration: "forever" | "once" | "repeating";
  duration_in_months: number;
  percent_off?: number;
  amount_off?: number;
  valid: boolean;
  metadata?: {
    cancel_at_end?: boolean;
  };
  applies_to: {
    products: string[];
  };
}
interface GetCouponResponse {
  coupon: Coupon;
  promoId: string;
}

export const getCoupon = async (code: string): Promise<GetCouponResponse> => {
  const { data } = await axios.get<GetCouponResponse>(`/coupon/${code}`, {
    baseURL: billingURL,
    headers: { "x-access-token": await getAccessToken() }
  });

  if (!data.coupon?.valid) {
    throw new Error("Coupon not valid");
  }

  return data;
};

export const startTrial = async (
  params: CreateSubParams,
  options = { force: true }
): Promise<void> => {
  await axios.post(`${getAccountId()}/subscription/trial`, params, {
    baseURL: billingURL,
    headers: { "x-access-token": await getAccessToken() }
  });

  await handlePurchaseSuccess(true, options);
};

export const handlePurchaseSuccess = async (
  resetEditorTour?: boolean,
  options = { force: true }
): Promise<void> => {
  // Reset media editor tour after upgrading from a Free account
  if (resetEditorTour) {
    await updateAccountSettings({
      hasSeenMediaEditorTour: false
    });
  }

  await getCustomer({ force: options.force, skipCache: true });
  await getCredit({ force: options.force });
};

interface UpdateSubCouponParams {
  productId: string;
  promoId?: string;
}
export const updateSubscriptionWithCoupon = async (
  subscriptionId: string,
  couponCode: string,
  params: UpdateSubCouponParams
): Promise<void> => {
  try {
    const c = await getCoupon(couponCode);
    params.promoId = c.promoId;
    await axios.put(
      `${getAccountId()}/subscription/${subscriptionId}/coupon`,
      params,
      {
        baseURL: billingURL,
        headers: { "x-access-token": await getAccessToken() }
      }
    );
    await updateAccountSettings({ hasUsedReturnCoupon: true });
  } catch (error) {
    handleError(error);
    throw new Error(error);
  }
};

interface PurchaseStorageParams {
  units: number;
  methodId?: string;
  promoId?: string;
  taxId?: string;
}

export const purchaseStorage = async (
  subscriptionId: string,
  params: PurchaseStorageParams
): Promise<PurchaseResponse> => {
  const { data } = await axios.post<PurchaseResponse>(
    `${getAccountId()}/subscription/${subscriptionId}/purchase-storage`,
    params,
    {
      baseURL: billingURL,
      headers: { "x-access-token": await getAccessToken() }
    }
  );

  return data;
};
