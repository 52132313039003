import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";
import { useAccounts } from "../../hooks/use-accounts";
import { usePlan } from "../../hooks/use-plan";
import { PLANS_PATH } from "../../routes";
import { pluralize } from "../../utils/strings";
import styles from "./upload-modal.module.scss";

interface UploadModalAlertProps {
  credit?: boolean;
  storage?: boolean;
  count: number;
  onUpgrade: () => void;
}
export const UploadModalAlert: React.FC<UploadModalAlertProps> = ({
  credit,
  storage,
  count,
  onUpgrade
}) => {
  if (!credit && !storage) {
    return null;
  }

  const { plan, isTrial } = usePlan();
  const { deal } = useAccounts();

  //hide if user is on a paid plan
  if (plan && !isTrial && !deal) {
    return null;
  }

  let message = "";

  const ending =
    count > 0
      ? `process ${count > 1 ? "these" : "this"}
  ${pluralize(count, "file")}`
      : "upload files";

  if (credit && storage) {
    message = `Oops, looks like you don't have enough credit and storage left to ${ending}. Get more credit and storage, `;
  } else if (credit) {
    message = `Oops, looks like you don't have enough credit left to ${ending}. Get more minutes, `;
  } else if (storage) {
    message = `Oops, looks like you don't have enough storage left to ${ending}. Get more storage, `;
  }

  if (!message) {
    return null;
  }

  return (
    <div className={classNames(styles["alert"], styles["alert--show"])}>
      <p className="small m-0">
        <>
          {message}
          <Link to={PLANS_PATH} onClick={onUpgrade}>
            upgrade now
          </Link>
        </>
      </p>
    </div>
  );
};
