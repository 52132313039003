import classNames from "classnames";
import React from "react";
import styles from "./badge.module.scss";

interface BadgeProps {
  className?: string;
  inverted?: boolean;
  onClick?: () => void;
}
export const Badge: React.FC<BadgeProps> = ({
  onClick,
  className,
  inverted,
  children
}) => {
  return (
    <span
      className={classNames(styles["badge"], className, {
        [styles["badge--inverted"]]: inverted
      })}
      onClick={onClick}
    >
      {children}
    </span>
  );
};
