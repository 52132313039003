import classNames from "classnames";
import React from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { SignInParams } from "../../api/auth.service";
import { EmbeddedWebviewMsg } from "./embedded-webview-msg";
import { Alert } from "../../components/alert/alert";
import { Button } from "../../components/button/button";
import { Divider } from "../../components/divider/divider";
import { GoogleButton } from "../../components/google-button/google-button";
import { AuthContext } from "../../contexts/auth.context";
import { AnalyticsContext } from "../../contexts/analytics";
import { useQuery } from "../../hooks/use-query";
import {
  DASHBOARD_PATH,
  FORGET_PASSWORD_PATH,
  TRIAL_ONBOARDING_PATH
} from "../../routes";
import { SublyCouponCode, URLStateToPlan } from "../../utils/plans";
import { emailPattern } from "../../utils/regex-patterns";
import { isEmbeddedWebView } from "../../utils/browser";
import styles from "./auth.module.scss";

interface QueryParams {
  state?: string;
  coupon?: SublyCouponCode;
  alertMessage?: string;
  redirect?: string;
  invite?: string;
}
export const SignInForm: React.FC = () => {
  const [loading, setLoading] = React.useState(false);
  const {
    state: queryState,
    alertMessage: alertQuery,
    redirect,
    invite,
    coupon
  } = useQuery<QueryParams>();
  const state = useLocation().state as QueryParams;
  const [alertMessage, setAlertMessage] = React.useState(
    alertQuery || state?.alertMessage
  );
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<SignInParams>();
  const { login } = React.useContext(AuthContext);
  const navigate = useNavigate();
  const { trackEvent } = React.useContext(AnalyticsContext);
  const isWebView = isEmbeddedWebView();

  const onSubmit = async (params: SignInParams) => {
    setLoading(true);

    params.invite = invite;

    const { success, alertMessage, data } = await login(params);

    if (!success) {
      setLoading(false);
      setAlertMessage(alertMessage);
      return;
    }

    trackEvent("Sign in password", { redirectTo: queryState });

    const redirectState = URLStateToPlan(queryState, coupon);

    const trialInvitationPage = !Boolean(data?.trialOnboarding);

    if (redirectState.pathname) {
      navigate(
        {
          pathname: redirectState.pathname
        },
        { state: redirectState }
      );
      return;
    }

    if (redirect) {
      navigate({
        pathname: redirect
      });
      return;
    }

    if (trialInvitationPage) {
      navigate(TRIAL_ONBOARDING_PATH);
      return;
    }

    navigate(DASHBOARD_PATH);
  };

  return (
    <div className={styles.container}>
      {alertMessage && (
        <Alert
          danger
          title="Something went wrong!"
          closable
          onClose={() => setAlertMessage("")}
        >
          {alertMessage}
        </Alert>
      )}
      <h4 className="text-center mb-4">Log in</h4>

      {isWebView ? (
        <EmbeddedWebviewMsg />
      ) : (
        <>
          <GoogleButton
            className={styles["btn-google"]}
            mixpanelAction="Sign in Google"
          >
            Log In with Google
          </GoogleButton>

          <Divider>OR</Divider>
        </>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <input
            id="email"
            placeholder="Email Address"
            className={classNames("form-control", {
              "is-invalid": errors.email
            })}
            autoCapitalize="off"
            type="email"
            {...register("email", { required: true, pattern: emailPattern })}
          />
        </div>
        <div className="form-group">
          <input
            id="password"
            placeholder="Password"
            className={classNames("form-control", {
              "is-invalid": errors.password
            })}
            type="password"
            {...register("password", { required: true })}
          />
        </div>

        <div>
          <Button
            primary
            className="btn-block my-2"
            type="submit"
            loading={loading}
          >
            <span className="text-white">Log in</span>
          </Button>
          <div className="text-center">
            <Link className="text-interactive" to={FORGET_PASSWORD_PATH}>
              Forgot password?
            </Link>
          </div>
        </div>
      </form>
    </div>
  );
};
