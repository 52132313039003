import {
  ActiveState,
  EntityState,
  EntityStore,
  EntityUIStore,
  StoreConfig
} from "@datorama/akita";
import { MediaSnippet } from "@getsubly/common";
import { AccountSettings } from "../../interfaces/account";
import { BasicMedia, MediaInvite } from "../../interfaces/media";
import { PresenceUser } from "../user-presence";
import { mediaQuery } from "./media.query";
import { v4 } from "uuid";
import { secToMs } from "../../utils/time";

export interface MediaState
  extends EntityState<BasicMedia, string>,
    ActiveState {
  loading: boolean;
  editorLoading: boolean;
  editorLoaded: boolean;
  loadedAt?: Date;
  error?: string;
  dashboardFilter: DashboardFilter;
  deleteMediaId?: string;
  activeFolderId?: string | null;
  activeFolderMediaTotal?: number;
}

export interface DownloadState {
  showDownloadModal: boolean;
  downloadUrl?: string;
}

export enum DashboardFilter {
  All = "All",
  Video = "Video",
  Audio = "Audio"
}

export enum SavingStatus {
  Unsaved = "Unsaved changes",
  Saving = "Saving changes...",
  Saved = "All changes saved"
}

export enum SavingFileType {
  Subtitles = "Subtitles",
  Config = "Config"
}

export interface SnippetTime {
  start: number;
  end: number;
}

export interface NewSnippet {
  [id: string]: SnippetTime;
}

export interface MediaUI {
  savingStatus: SavingStatus;
  download: DownloadState;
  reportedIssue?: boolean;
  savingIds: string[];
  savingFileTypes: SavingFileType[];
  duration: number;
  currentTime: number;
  snippets: NewSnippet;
  currentTranscriptionId: string;
  accountSettings?: AccountSettings;
  lockedUsers: PresenceUser[];
}

interface ResetSnippetProps {
  id?: string;
  startTime?: number;
  length?: number;
  useCurrentTime?: boolean;
}

export interface MediaUIState extends EntityState<MediaUI>, ActiveState {}

@StoreConfig({ name: "media", idKey: "mediaId" })
export class MediaStore extends EntityStore<MediaState> {
  ui!: EntityUIStore<MediaUIState>;

  constructor() {
    super({
      loading: false,
      editorLoading: false,
      editorLoaded: false,
      dashboardFilter: DashboardFilter.All,
      activeFolderId: null
    });

    this.createUIStore().setInitialEntityState<MediaUI>({
      savingStatus: SavingStatus.Saved,
      download: { showDownloadModal: false },
      savingIds: [],
      savingFileTypes: [],
      duration: -1,
      currentTime: 0,
      currentTranscriptionId: "",
      snippets: {},
      lockedUsers: []
    });
  }

  resetDownload = (mediaId: string): void => {
    this.ui.update(mediaId, {
      download: { showDownloadModal: false }
    });
  };

  setMediaIsSaved = (mediaId: string): void => {
    const currentSavingStatus = mediaQuery.ui.getEntity(mediaId)?.savingStatus;

    if (currentSavingStatus === SavingStatus.Unsaved) {
      return;
    }

    this.ui.update(mediaId, {
      savingStatus: SavingStatus.Saved,
      savingIds: [],
      savingFileTypes: []
    });
  };

  setDashboardFilter = (dashboardFilter: DashboardFilter): void => {
    this.update({ dashboardFilter });
  };

  setActiveFolderMediaTotal = (activeFolderMediaTotal?: number): void => {
    this.update({ activeFolderMediaTotal });
  };

  setShowDeleteMediaModal = (mediaId?: string): void => {
    this.update({ deleteMediaId: mediaId });
  };

  addMediaInvite = (mediaId: string, invite: MediaInvite): void => {
    this.update(mediaId, (media) => {
      const oldSharedUsers = media.sharedUsers ?? [];
      const sharedUsers = [...oldSharedUsers, invite];

      return { ...media, sharedUsers };
    });
  };

  updateMediaInvite = (mediaId: string, invite: MediaInvite): void => {
    this.update(mediaId, (media) => {
      const oldSharedUsers = media.sharedUsers ?? [];
      const inviteIndex = oldSharedUsers.findIndex(
        (i) => i.shareId === invite.shareId
      );

      if (inviteIndex < 0) {
        return media;
      }

      const sharedUsers = [...oldSharedUsers];
      sharedUsers.splice(inviteIndex, 1, invite);

      return { ...media, sharedUsers };
    });
  };

  deleteMediaInvite = (mediaId: string, inviteId: string): void => {
    this.update(mediaId, (media) => {
      const sharedUsers = media.sharedUsers?.filter(
        (i) => i.shareId !== inviteId
      );

      return { ...media, sharedUsers };
    });
  };

  updateDuration = (mediaId?: string, duration = 0): void => {
    this.ui.update(mediaId, { duration });
  };

  updateCurrentTime = (mediaId?: string, currentTime = 0): void => {
    this.ui.update(mediaId, { currentTime });
  };

  updateSnippetTimeStart = (
    mediaId: string,
    snippetId: string,
    newStart = 0
  ): void => {
    this.ui.update(mediaId, ({ snippets, duration }) => {
      const { end } = { ...{ ...snippets[snippetId] } };
      let start: number;
      const durationInMs = secToMs(duration);

      if (newStart < 0) {
        start = 0;
      } else if (duration !== -1 && newStart > durationInMs) {
        start = durationInMs;
      } else {
        start = newStart;
      }
      const updatedSnippet = { [snippetId]: { start, end } };

      return { snippets: { ...snippets, ...updatedSnippet } };
    });
  };

  updateSnippetTimeEnd = (
    mediaId: string,
    snippetId: string,
    newEnd = 0
  ): void => {
    this.ui.update(mediaId, ({ snippets, duration }) => {
      const { start } = { ...{ ...snippets[snippetId] } };
      let end: number;
      const durationInMs = secToMs(duration);

      if (newEnd < 0) {
        end = 0;
      } else if (duration !== -1 && newEnd > durationInMs) {
        end = durationInMs;
      } else {
        end = newEnd;
      }
      const updatedSnippet = { [snippetId]: { start, end } };

      return { snippets: { ...snippets, ...updatedSnippet } };
    });
  };

  resetSnippets = (mediaId?: string): void => {
    this.ui.update(mediaId, () => ({ snippets: {} }));
  };

  loadSnippet = (mediaId: string, snippet: MediaSnippet): void => {
    this.ui.update(mediaId, () => {
      const {
        id,
        time: { start, end }
      } = snippet;

      return {
        snippets: { [id]: { start, end } }
      };
    });
  };

  resetSnippet = (
    mediaId: string,
    { id, startTime, length, useCurrentTime }: ResetSnippetProps
  ): void => {
    this.ui.update(mediaId, ({ currentTime, duration, snippets }) => {
      const start = useCurrentTime
        ? secToMs(currentTime)
        : startTime
        ? startTime
        : 0;
      let end = start + secToMs(length ?? 5);
      const durationInMs = secToMs(duration);

      if (duration !== -1 && end > durationInMs) {
        end = durationInMs;
      }
      const newSnippet = { [id || v4()]: { start, end } };
      return { snippets: { ...snippets, ...newSnippet } };
    });
  };

  removeSnippet = (mediaId: string, snippetIdToRemove: string): void => {
    this.ui.update(mediaId, ({ snippets }) => {
      const updatedSnippets = { ...snippets };
      delete updatedSnippets[snippetIdToRemove];

      return { snippets: updatedSnippets };
    });
  };

  updateCurrentTranscriptionId = (currentTranscriptionId: string): void => {
    this.ui.updateActive({ currentTranscriptionId });
  };
}
export const mediaStore = new MediaStore();
