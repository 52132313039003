import { useObservable } from "@mindspace-io/react";
import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { getReferrals } from "../../../api/referral.service";
import { LoadingIcon } from "../../../components/loading-icon/loading-icon";
import { AuthContext } from "../../../contexts/auth.context";
import { ModalType } from "../../../interfaces/modal-type";
import { useModal } from "../../../hooks/use-modal";
import { SettingsLayout } from "../../../layouts/settings/settings.layout";
import { PLANS_PATH } from "../../../routes";
import { referralQuery } from "../../../state/referral/referral.query";
import { ReferralModal } from "./referral-modal/referral-modal";
import { ReferralNav } from "./referral-nav/referral-nav";
import { ReferralTable } from "./referral-table/referral-table";

export const ReferralPage: React.FC = () => {
  const { user } = useContext(AuthContext);
  const [showModal, hideModal] = useModal(ModalType.ReferralInvite);
  const [loaded] = useObservable(
    referralQuery.selectIsLoaded(),
    referralQuery.isLoaded
  );
  const [referrals] = useObservable(
    referralQuery.selectReferrals(),
    referralQuery.referrals
  );

  useEffect(() => {
    if (!user) {
      return;
    }

    getReferrals({ force: true });
  }, [user]);

  if (!user) {
    return null;
  }

  if (!loaded) {
    return (
      <SettingsLayout>
        <div className="container-fluid my-2">
          <div className="d-flex flex-column justify-content-center align-items-center mt-5">
            <LoadingIcon />
            <p className="text-center mt-3">Loading, please wait.</p>
          </div>
        </div>
      </SettingsLayout>
    );
  }

  const showReferralModal = () => {
    showModal(<ReferralModal closeModal={hideModal} />);
  };

  return (
    <SettingsLayout>
      <div className="container-fluid my-2">
        <ReferralNav showReferralModal={showReferralModal} />
        <div className="row">
          <div className="col">
            <div className="px-3 max-w-prose">
              <p className="small">
                For each friend who joins, we'll give you both extra minutes!
                You'll get 5 minutes if they sign up for a free account, 10
                minutes if paid!
              </p>
              <p className="small">
                If you need even more minutes,{" "}
                <Link to={PLANS_PATH}>upgrade</Link> today.
              </p>
            </div>
            <ReferralTable loaded={loaded} referrals={referrals} />
          </div>
        </div>
      </div>
    </SettingsLayout>
  );
};
