import React from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { AppId, unlinkApp } from "../../api/apps.service";
import { Button } from "../../components/button/button";
import config from "../../config";
import { ZoomContainer } from "../../containers/zoom/zoom-container";
import { SettingsLayout } from "../../layouts/settings/settings.layout";
import { APPS_HOME_PATH } from "../../routes";
import styles from "./app-page.module.scss";

export const AppsPage: React.FC = () => {
  const { appId } = useParams();
  const navigate = useNavigate();

  const unlink = async (): Promise<void> => {
    if (!appId) {
      return;
    }

    const unlinked = await unlinkApp(appId as AppId);

    if (unlinked) {
      navigate("/apps");
    }
  };

  return (
    <SettingsLayout hasBackArrow={false}>
      <div className="container-fluid">
        <div className={styles.appMenu}>
          {config.features.hasUnlinkApps && (
            <Button tertiary className="ml-auto" onClick={unlink}>
              Unlink app
            </Button>
          )}
        </div>
        <AppContainer appId={appId} />
      </div>
    </SettingsLayout>
  );
};

interface AppContainerProps {
  appId?: string;
}
const AppContainer: React.FC<AppContainerProps> = ({ appId }) => {
  if (appId === "zoom") {
    return <ZoomContainer />;
  }

  return <Navigate to={APPS_HOME_PATH} />;
};
