import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { StripeCardElement } from "@stripe/stripe-js";
import classNames from "classnames";
import React, { useState } from "react";
import { getIntent, handlePurchaseSuccess } from "../../api/billing.service";
import { Modal } from "../../containers/modal/modal";
import { useAccounts } from "../../hooks/use-accounts";
import { useAnalyticsWithAuth } from "../../hooks/use-analytics-with-auth";
import { Button } from "../button/button";

interface OverdueInvoiceModalProps {
  closeAlert: () => void;
}

export const OverdueInvoiceModal: React.FC<OverdueInvoiceModalProps> = ({
  closeAlert
}) => {
  return (
    <Modal onDismiss={closeAlert} hasCloseIcon>
      <OverdueInvoiceModalContainer closeAlert={closeAlert} />
    </Modal>
  );
};

const OverdueInvoiceModalContainer: React.FC<OverdueInvoiceModalProps> = ({
  closeAlert
}) => {
  const { subscription, paymentMethods } = useAccounts();

  const handleClose = (success = false) => {
    if (success) {
      handlePurchaseSuccess();
    }
    closeAlert();
  };

  if (!subscription || !subscription?.latestInvoice) {
    handleClose();
    return null;
  }

  const [useNewPayment, setUseNewPayment] = useState(false);
  const price = (subscription.latestInvoice.total / 100).toFixed(2);

  let payment = subscription.nextAction?.payment;

  if (!payment && paymentMethods && paymentMethods?.length > 0) {
    payment = paymentMethods[0].id;
  }

  const clientSecret = subscription?.nextAction?.clientSecret;

  if (!useNewPayment && clientSecret && payment) {
    return (
      <ConfirmPaymentMethodContainer
        price={price}
        clientSecret={clientSecret}
        payment={payment}
        switchToNew={() => setUseNewPayment(true)}
        closeAlert={handleClose}
      />
    );
  }

  return (
    <NewPaymentMethodContainer
      price={price}
      clientSecret={clientSecret}
      closeAlert={handleClose}
    />
  );
};

interface ConfirmPaymentMethodContainerProps {
  price: string;
  clientSecret: string;
  payment: string;
  switchToNew: () => void;
  closeAlert: (success?: boolean) => void;
}
const ConfirmPaymentMethodContainer: React.FC<
  ConfirmPaymentMethodContainerProps
> = ({ price, clientSecret, payment, switchToNew, closeAlert }) => {
  const [error, setError] = useState("");
  const [isLoading, setLoading] = useState(false);

  const { trackEventWithAuth } = useAnalyticsWithAuth();
  const stripe = useStripe();

  const handleConfirmPayment = async () => {
    if (!stripe) {
      setError("There is an issue, please contact support@getsubly.com.");
      return;
    }

    setError("");
    setLoading(true);

    const { error } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: payment
    });

    setLoading(false);
    if (error?.message) {
      trackEventWithAuth("Overdue - Confirm card payment fail", {
        error: error.code
      });
      setError(error.message);
    }
    if (!error) {
      closeAlert(true);
    }
  };

  return (
    <>
      <h5>Confirm ${price} payment</h5>
      <p className="mb-1">
        You'll be asked to verify your identity with your bank.
      </p>

      <div className="form-group d-flex justify-content-end">
        {error && <span className="has-error">{error}</span>}
      </div>

      <div>
        <Button
          primary
          className="mb-2"
          onClick={handleConfirmPayment}
          loading={isLoading}
        >
          Confirm payment
        </Button>
      </div>

      <Button
        secondary
        onClick={() => {
          switchToNew();
          setError("");
        }}
      >
        Select a new payment method
      </Button>
    </>
  );
};

interface NewPaymentMethodContainerProps {
  price?: string;
  clientSecret?: string;
  closeAlert: (success?: boolean) => void;
}
const NewPaymentMethodContainer: React.FC<NewPaymentMethodContainerProps> = ({
  price,
  clientSecret,
  closeAlert
}) => {
  const [error, setError] = useState("");
  const [focus, setFocus] = useState(false);
  const [complete, setComplete] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const { trackEventWithAuth } = useAnalyticsWithAuth();
  const stripe = useStripe();
  const elements = useElements();

  const getElementCard = (): StripeCardElement | undefined => {
    if (!elements) {
      setError("There is an issue please contact support@getsubly.com");
      return;
    }

    if (!complete) {
      setError("Some information is missing.");
      return;
    }

    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      setError("There is an issue, please contact support@getsubly.com.");
      return;
    }

    return cardElement;
  };

  if (price && clientSecret) {
    const handlePurchase = async () => {
      if (!stripe) {
        setError("There is an issue, please contact support@getsubly.com.");
        return;
      }

      const cardElement = getElementCard();

      if (!cardElement) {
        return;
      }

      setError("");
      setLoading(true);

      const { error } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement
        }
      });

      setLoading(false);
      if (error?.message) {
        trackEventWithAuth("Overdue - Confirm card setup fail", {
          error: error.code
        });
        setError(error.message);
      }
      if (!error) {
        closeAlert(true);
      }
    };

    return (
      <>
        <h5>Pay with card</h5>
        <p className="mb-1">Card information</p>{" "}
        <div className="form-group">
          <label className="mb-1">Credit / debit card number</label>
          <CardElement
            className={classNames(
              "form-control d-flex credit-card-input mb-0",
              {
                focused: focus
              }
            )}
            options={{ hidePostalCode: true }}
            onBlur={() => setFocus(false)}
            onFocus={() => setFocus(true)}
            onChange={(e) => setComplete(e.complete)}
          />
        </div>
        <div className="form-group d-flex justify-content-end">
          {error && <span className="has-error">{error}</span>}
        </div>
        <div>
          <Button
            secondary
            className="mr-2"
            onClick={handlePurchase}
            loading={isLoading}
          >
            Pay ${price}
          </Button>
        </div>
      </>
    );
  }

  const handleNewPaymentMethod = async () => {
    if (!stripe) {
      setError("There is an issue, please contact support@getsubly.com.");
      return;
    }

    const cardElement = getElementCard();

    if (!cardElement) {
      return;
    }

    setError("");
    setLoading(true);

    const {
      data: { intent }
    } = await getIntent();

    const { error } = await stripe.confirmCardSetup(intent, {
      payment_method: {
        card: cardElement
      }
    });

    setLoading(false);
    if (error?.message) {
      trackEventWithAuth("Overdue - Confirm new card setup fail", {
        error: error.code
      });
      setError(error.message);
    }
    if (!error) {
      closeAlert();
    }
  };

  return (
    <>
      <h5>Add payment method</h5>
      <p className="mb-1">Card information</p>{" "}
      <div className="form-group">
        <label className="mb-1">Credit / debit card number</label>
        <CardElement
          className={classNames("form-control d-flex credit-card-input mb-0", {
            focused: focus
          })}
          options={{ hidePostalCode: true }}
          onBlur={() => setFocus(false)}
          onFocus={() => setFocus(true)}
          onChange={(e) => setComplete(e.complete)}
        />
      </div>
      <div className="form-group d-flex justify-content-end">
        {error && <span className="has-error">{error}</span>}
      </div>
      <div>
        <Button
          secondary
          className="mr-2"
          onClick={handleNewPaymentMethod}
          loading={isLoading}
        >
          Setup card
        </Button>
      </div>
    </>
  );
};
