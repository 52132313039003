import { PartnerDiscountCode } from "@getsubly/common";
import { mdiAlertCircleOutline } from "@mdi/js";
import Icon from "@mdi/react";
import classNames from "classnames";
import { format } from "date-fns";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../contexts/auth.context";
import {
  BannerType,
  TopBannerContext
} from "../../contexts/top-banner.context";
import { useAccounts } from "../../hooks/use-accounts";
import { useAnalyticsWithAuth } from "../../hooks/use-analytics-with-auth";
import { useModal } from "../../hooks/use-modal";
import { usePlan } from "../../hooks/use-plan";
import { SublyPlan } from "../../interfaces/billing";
import { ModalType } from "../../interfaces/modal-type";
import { ACCOUNT_PATH, PLANS_PATH, PLANS_UPGRADE_PATH } from "../../routes";
import { getStorageInfo } from "../../utils/storage-size";
import { LoadingIcon } from "../loading-icon/loading-icon";
import { OverdueInvoiceModal } from "../overdue-invoice-modal/overdue-invoice-modal";
import styles from "./banner.module.scss";

interface BannerProps {
  bannerType?: BannerType;
}

export const Banner: React.FC<BannerProps> = ({ bannerType }) => {
  if (!bannerType) {
    bannerType = useContext(TopBannerContext).bannerType;
  }

  switch (bannerType) {
    case BannerType.Trial:
      return <TrialBanner />;

    case BannerType.PaymentOverdue:
      return <PaymentOverdueBanner />;

    case BannerType.PartnerWelcome:
      return <PartnerWelcomeBanner />;

    case BannerType.PartnerUpgrade:
      return <PartnerUpgradeBanner />;

    case BannerType.StorageLow:
      return <StorageLowBanner />;

    case BannerType.SaveYearly:
      return <SaveYearly />;

    default:
      return null;
  }
};

const PartnerWelcomeBanner: React.FC = () => {
  const { partner } = React.useContext(AuthContext);

  const isLinkedin25 = partner?.discountCode === PartnerDiscountCode.LINKEDIN25;

  const discount = isLinkedin25 ? 25 : 20;

  return (
    <div className={styles["promo-banner"]}>
      <p className="text-center small">
        Special partner offer just for you. Get {discount}% off for 3 months
        when you{" "}
        <Link
          to={{
            pathname: PLANS_UPGRADE_PATH
          }}
          state={{
            plan: SublyPlan.Pro100,
            coupon: partner?.discountCode
          }}
        >
          upgrade to Subly Pro
        </Link>
        .
      </p>
    </div>
  );
};

const PartnerUpgradeBanner: React.FC = () => {
  const { isProOrHigher } = usePlan();

  if (isProOrHigher) {
    return null;
  }

  return (
    <div className={styles["promo-banner"]}>
      <p className="text-center small">
        Want your Subly Pro features back? Get 20% off when you{" "}
        <Link to={PLANS_PATH}>upgrade</Link> today 😉
      </p>
    </div>
  );
};

const PaymentOverdueBanner: React.FC = () => {
  const { hasUserClosedOverduePaymentPopUp } = React.useContext(AuthContext);
  const { hasPaymentOverdue } = usePlan();

  const [showModal, hideModal] = useModal(ModalType.OverdueInvoiceModal);

  if (!hasPaymentOverdue || !hasUserClosedOverduePaymentPopUp) {
    return null;
  }

  return (
    <div
      className={classNames(
        "d-block py-2 text-center",
        styles["promo-banner"],
        styles["bg-error"]
      )}
    >
      <p className="text-center text-black mb-0 px-2 small">
        <Icon
          path={mdiAlertCircleOutline}
          size="20px"
          color="var(--feedback-error)"
          className="mr-2"
        />
        It seems that the latest payment hasn’t been processed. Please{" "}
        <span
          className="text-black underline cursor-pointer"
          onClick={() =>
            showModal(<OverdueInvoiceModal closeAlert={hideModal} />)
          }
        >
          click here to confirm payment
        </span>{" "}
        before accessing your Subly account.
      </p>
    </div>
  );
};

const TrialBanner: React.FC = () => {
  const { isTrial, trialEndDate } = usePlan();

  if (!isTrial || !trialEndDate) {
    return null;
  }

  return (
    <div className={styles["promo-banner"]}>
      <p className="text-center small">
        Your trial will end on {format(new Date(trialEndDate), "dd MMM")}.{" "}
        <Link to={PLANS_PATH}>Upgrade now</Link> to get unlimited downloads.
      </p>
    </div>
  );
};

const SaveYearly: React.FC = () => {
  const { trackEventWithAuth } = useAnalyticsWithAuth();

  return (
    <div className={styles["promo-banner"]}>
      <p className="text-center small">
        Save up to 20% when you{" "}
        <Link
          to={PLANS_PATH}
          onClick={() => trackEventWithAuth("Click on save yearly banner")}
        >
          switch to yearly subscription
        </Link>
      </p>
    </div>
  );
};

const StorageLowBanner: React.FC = () => {
  const { isLoading } = useAccounts();
  const { plan } = usePlan();
  const { usagePercentage } = getStorageInfo(plan);
  return (
    <div className={styles["promo-banner"]}>
      {isLoading ? (
        <LoadingIcon />
      ) : (
        <p className="text-center small">
          💿 You’re at {usagePercentage}% of your storage limit.{" "}
          <Link to={ACCOUNT_PATH}>Upgrade</Link> your storage to continue using
          Subly.
        </p>
      )}
    </div>
  );
};
