import { VALID_PARTNERS } from "@getsubly/common";
import React from "react";
import { Navigate, useParams } from "react-router-dom";
import { SIGN_UP_PATH } from "../../../routes";
import { compareStrings } from "../../../utils/strings";
import { Aside } from "./aside";
import { SignUpPartner } from "./sign-up-partner";
import { VerificationPartner } from "./verification-partner";
import "./partners.scss";

export const SignUpPartnerPage: React.FC = () => {
  const params = useParams();

  const partner = VALID_PARTNERS.find((p) =>
    compareStrings(p.slug, params.partner)
  );

  if (!partner) {
    return <Navigate to={SIGN_UP_PATH} />;
  }

  return (
    <div className="app" id="partner-auth-wrapper">
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <Aside partner={partner}>
              <div className="row d-block d-md-none">
                <div className="col-md-5 auth-container">
                  <SignUpPartner partner={partner} />
                </div>
              </div>
            </Aside>
          </div>
          <div className="col-md-5 auth-container d-none d-md-flex">
            <SignUpPartner partner={partner} />
          </div>
        </div>
      </div>
    </div>
  );
};

export const VerifyPartnerPage: React.FC = () => {
  const params = useParams();

  const partner = VALID_PARTNERS.find((p) =>
    compareStrings(p.name, params.partner)
  );

  if (!partner) {
    return <Navigate to={SIGN_UP_PATH} />;
  }

  return (
    <div className="app" id="partner-auth-wrapper">
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <Aside partner={partner} showChildrenTop={true}>
              <div className="row d-block d-md-none">
                <div className="col-md-5 auth-container">
                  <VerificationPartner />
                </div>
              </div>
            </Aside>
          </div>
          <div className="col-md-5 auth-container d-none d-md-flex">
            <VerificationPartner />
          </div>
        </div>
      </div>
    </div>
  );
};
