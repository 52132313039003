import classNames from "classnames";
import React from "react";
import { useAccounts } from "../../hooks/use-accounts";
import { usePlan } from "../../hooks/use-plan";
import { SublyPlan } from "../../interfaces/billing";
import { PLANS_UPGRADE_PATH } from "../../routes";
import { pluralize } from "../../utils/strings";
import { Button } from "../button/button";
import styles from "./upload-modal.module.scss";

interface InsufficientCreditAlertProps {
  files: number;
  creditRequired: number;
  hasInsufficientCredit: boolean;
  onUpgrade: () => void;
}
export const InsufficientCreditAlert: React.FC<
  InsufficientCreditAlertProps
> = ({ files, creditRequired, hasInsufficientCredit, onUpgrade }) => {
  const { plan, isPayg, isTrial } = usePlan();
  const { deal } = useAccounts();

  const currentPlan = isPayg ? SublyPlan.PAYG : plan;

  if (!hasInsufficientCredit) {
    return null;
  }

  //hide if user is on trial / business deal
  if (isTrial || deal) {
    return null;
  }

  let minutes = Math.floor(creditRequired / 60);
  const seconds = creditRequired - minutes * 60;
  minutes = seconds > 0 ? minutes + 1 : minutes;

  return (
    <div className={classNames(styles["insufficient-credit"])}>
      <div className={styles["text"]}>
        <p>Uh oh! You don’t have enough minutes</p>
        <p>
          {files} {pluralize(files, "file")} failed to add. You need {minutes}{" "}
          more {pluralize(minutes, "minute")}.
        </p>
      </div>

      <Button
        secondary
        className={styles["button"]}
        to={{
          pathname: PLANS_UPGRADE_PATH
        }}
        state={{
          plan: currentPlan,
          quantity: 20,
          topup: true
        }}
        onClick={onUpgrade}
      >
        Top up minutes now
      </Button>
    </div>
  );
};
