import React from "react";
import { Banner } from "../../components/banner/banner";
import { MaintenanceBanner } from "../../components/banner/maintenance-banner";
import { WebviewBanner } from "../../components/banner/webview-banner";

interface TopBannerContainerProps {
  hideTopBanner?: boolean;
}
export const TopBannerContainer: React.FC<TopBannerContainerProps> = ({
  hideTopBanner,
  children
}) => {
  return (
    <>
      <MaintenanceBanner />
      <WebviewBanner />
      {!hideTopBanner && <Banner />}

      {children}
    </>
  );
};
