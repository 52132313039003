import amplitudePlugin from "@analytics/amplitude";
import mixpanelPlugin from "@analytics/mixpanel";
import hubspotPlugin from "@analytics/hubspot";
import { Analytics } from "analytics";
import React from "react";
import config from "../config";
import { useUser } from "../hooks/use-user";
import { UnknownObject } from "../interfaces/types";
import { authQuery } from "../state/auth/auth.query";

const analytics = Analytics({
  debug: !config.isProduction,
  plugins: [
    mixpanelPlugin({
      token: config.mixpanel,
      enabled: Boolean(config.mixpanel)
    }),
    amplitudePlugin({
      apiKey: config.amplitude,
      options: {
        trackingOptions: {
          ip_address: false
        }
      },
      enabled: Boolean(config.amplitude)
    }),
    hubspotPlugin({
      portalId: config.hubspot,
      enabled: Boolean(config.hubspot)
    })
  ]
});

interface IdentifyOptions {
  userId?: string;
  stripeId?: string;
  email?: string;
}
interface AnalyticsContext {
  trackEvent: (action: string, properties?: UnknownObject) => void;
  trackPage: () => void;
  analyticsIdentify: (options?: IdentifyOptions) => void;
  hsSignUpIdentify: (email: string) => void;
}

export const AnalyticsContext = React.createContext<AnalyticsContext>({
  trackEvent: () => null,
  trackPage: () => null,
  analyticsIdentify: () => null,
  hsSignUpIdentify: () => null
});

export const AnalyticsProvider: React.FC = ({ children }) => {
  const { user } = useUser();

  const trackEvent = (action: string, properties?: UnknownObject) => {
    if (!analytics || !config.useDataAnalytics) {
      return;
    }

    if (user?.email.includes("getsubly.com")) {
      return;
    }

    analytics.track(action, properties);
  };

  const profitWellIdentify = (stripeId?: string) => {
    if (!config.useDataAnalytics) {
      return;
    }

    if (!stripeId) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const pw = (window as any).profitwell;

    if (!pw) {
      return;
    }

    pw("start", { user_id: stripeId });
  };

  const analyticsIdentify = (options?: IdentifyOptions) => {
    if (!config.useDataAnalytics) {
      return;
    }

    if (options?.stripeId) {
      profitWellIdentify(options.stripeId);
    }

    if (!analytics || !options?.userId) {
      return;
    }

    if (options.email?.includes("getsubly.com")) {
      return;
    }

    analytics.identify(options.userId, {
      email: options.email
    });
  };

  const hsSignUpIdentify = (email: string) => {
    if (!config.useDataAnalytics) {
      return;
    }

    if (!analytics || !email) {
      return;
    }

    analytics.identify(
      email,
      { email },
      {
        plugins: {
          all: false,
          hubspot: true
        }
      }
    );

    // In order to send a the the identify, we have to fake an event
    analytics.page(
      { path: "/signup" },
      {
        plugins: {
          all: false,
          hubspot: true
        }
      }
    );

    // We run it twice to force hubspot to detect and push the event
    analytics.page(
      { path: "/signup" },
      {
        plugins: {
          all: false,
          hubspot: true
        }
      }
    );
  };

  // Only track pages on hubspot
  const trackPage = () => {
    if (!config.useDataAnalytics || !analytics) {
      return;
    }

    analytics.page(
      {},
      {
        plugins: {
          all: false,
          hubspot: true
        }
      }
    );
  };

  return (
    <AnalyticsContext.Provider
      value={{ trackEvent, analyticsIdentify, trackPage, hsSignUpIdentify }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};

export const useAnalytics = (): AnalyticsContext => {
  const context = React.useContext(AnalyticsContext);

  if (context === undefined) {
    throw new Error("useAnalytics must be used within a AnalyticsProvider");
  }

  return context;
};

export const rawAnalyticsTrack = (
  event: string,
  data: UnknownObject = {}
): void => {
  if (!analytics || !config.useDataAnalytics) {
    return;
  }

  const user = authQuery.user;

  if (user?.email.includes("getsubly.com")) {
    return;
  }

  analytics.track(event, data);
};
