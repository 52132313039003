import classNames from "classnames";
import numeral from "numeral";
import React, { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { downloadBurntMedia } from "../../api/file.service";
import { EN } from "../../assets/i18n/en";
import settings from "../../config/settings/settings";
import { Modal } from "../../containers/modal/modal";
import { getCardData } from "../../containers/settings/plans/cards/plan-suggestion-card";
import {
  FeatureList,
  PlanIcon
} from "../../containers/settings/plans/plans-table";
import { PricingIntervalToggleContext } from "../../contexts/interval-toggle.context";
import { useAccounts } from "../../hooks/use-accounts";
import { useAnalyticsWithAuth } from "../../hooks/use-analytics-with-auth";
import { PlanInterval, SublyPlan } from "../../interfaces/billing";
import { MediaFile } from "../../interfaces/media";
import { PLANS_UPGRADE_PATH, SALES_CONTACT } from "../../routes";
import { mediaQuery } from "../../state/media";
import { getLastProcessedFiles } from "../../utils/media-functions";
import { getPlanData, PAYG_PRICE, PlanLabelTypes } from "../../utils/plans";
import { Badge } from "../badge/badge";
import { Button } from "../button/button";
import { LocationState } from "../button/plans-button";
import { IntervalToggle } from "../interval-toggle/interval-toggle";
import { notificationError } from "../notification";
import styles from "./inactive-user-modal.module.scss";
import { PayAsYouGoMinuteInput } from "./out-of-minutes-modal";

interface InactiveUserModalProps {
  closeModal: () => void;
}

export const InactiveUserModal: React.FC<InactiveUserModalProps> = ({
  closeModal
}) => {
  return (
    <Modal className={styles["modal"]} onDismiss={closeModal} hasCloseIcon>
      <InactiveUserModalContainer closeModal={closeModal} />
    </Modal>
  );
};

interface InactiveUserModalContainerProps {
  closeModal: () => void;
}
export const InactiveUserModalContainer: React.FC<
  InactiveUserModalContainerProps
> = ({ closeModal }) => {
  const { pricingToggle } = useContext(PricingIntervalToggleContext);
  const { currentAccount } = useAccounts();
  const lastPlan = currentAccount?.lastPlan ?? SublyPlan.Pro100;
  const isLastPlanCustom = lastPlan === SublyPlan.BusinessYearlyCustom;
  const isLastPlanPAYG = lastPlan === "Subly PAYG minute credit";
  const modalPlan = getLastPlanLabel(lastPlan);
  const modalData = getCardData(modalPlan, pricingToggle);
  const media = mediaQuery.getActive();
  const [quantity, setQuantity] = useState<number | undefined>();
  const { trackEventWithAuth, analyticsData } = useAnalyticsWithAuth();
  const state = useLocation().state as LocationState;
  const [isDownloading, setIsDownloading] = useState(false);

  // Should be multiple files array
  const [lastProcessedFiles, setLastProcessedFiles] = useState<
    MediaFile | undefined
  >();

  React.useEffect(() => {
    if (!media) {
      return;
    }

    const files = getLastProcessedFiles(media);
    setLastProcessedFiles(files);
  }, [media]);

  const handleDownload = async () => {
    if (!media) {
      return;
    }
    // TODO: when multi-file, change to download multi and loop over files for render in file box
    setIsDownloading(true);

    try {
      await downloadBurntMedia({
        mediaId: media.id,
        burntFile: lastProcessedFiles,
        analyticsData
      });
    } catch (e) {
      notificationError(EN.error.defaultMessage);
    } finally {
      setIsDownloading(false);
    }
  };

  const renderFilenames = parseFilename(lastProcessedFiles?.filename ?? "");

  return (
    <>
      <h5 className="mb-4 d-flex align-items-center">
        Download file or resubscribe
      </h5>

      <div className={styles["container"]}>
        <div className={styles["download-box"]}>
          <div>
            <h6>Download last processed file</h6>
            <p>
              Your account is inactive and files are in view-only mode. You can
              still download the latest file that was processed for downloading.
            </p>
            {lastProcessedFiles && <h6>Last processed file:</h6>}
          </div>

          <div>
            <div className={styles["filename-box"]}>
              <p>{`${renderFilenames.ratio} ${renderFilenames.language} ${renderFilenames.name}`}</p>
            </div>
          </div>
          {lastProcessedFiles && (
            <Button
              secondaryBorder
              className={styles["download-button"]}
              onClick={handleDownload}
              disabled={!Boolean(lastProcessedFiles)}
              loading={isDownloading}
            >
              Download files
            </Button>
          )}
        </div>
        <div className={styles["plan-box"]}>
          {isLastPlanPAYG ? (
            <InactivePaygCard quantity={quantity} setQuantity={setQuantity} />
          ) : isLastPlanCustom ? (
            <InactiveBusinessCustomCard />
          ) : (
            <ModalPlanCard
              modalData={modalData}
              pricingToggle={pricingToggle}
            />
          )}
        </div>
      </div>
      <div className={styles["cta-button"]}>
        {isLastPlanPAYG ? (
          <Button
            primary
            isFull
            disabled={!quantity || quantity < 20}
            to={{
              pathname: PLANS_UPGRADE_PATH
            }}
            state={{
              ...state,
              plan: SublyPlan.PAYG,
              quantity,
              topup: true
            }}
            onClick={() => {
              trackEventWithAuth("Click to purchase minutes - inactive user");
              closeModal();
            }}
          >
            Purchase minutes
          </Button>
        ) : isLastPlanCustom ? (
          <Button
            secondaryBorder
            isFull
            to={SALES_CONTACT}
            onClick={() =>
              trackEventWithAuth("Click to contact - inactive user")
            }
            type="blank"
          >
            Contact us
          </Button>
        ) : (
          <Button
            primary
            isFull
            to={{
              pathname: PLANS_UPGRADE_PATH
            }}
            state={{
              ...state,
              plan: modalData.planData.plan,
              interval: pricingToggle
            }}
            onClick={() => {
              trackEventWithAuth("Click to resubscribe - inactive user", {
                toPlan: lastPlan
              }); // Update to previous plan
              closeModal();
            }}
          >
            Resubscribe {modalData.modalButtonText}
          </Button>
        )}
      </div>
    </>
  );
};

interface InactivePaygCardProps {
  quantity?: number;
  setQuantity: React.Dispatch<React.SetStateAction<number | undefined>>;
}
export const InactivePaygCard: React.FC<InactivePaygCardProps> = ({
  quantity,
  setQuantity
}) => {
  const planData = getPlanData(SublyPlan.PAYG);
  return (
    <>
      <PlanIcon plan={planData} />
      <h3 className={styles["price"]}>
        <sup className={styles["dollar-sign"]}>$</sup>
        {numeral((quantity ?? 0) * PAYG_PRICE).format("0.00")}
        <span className="ml-1 text-text-primary">total</span>
      </h3>
      <PayAsYouGoMinuteInput quantity={quantity} setQuantity={setQuantity} />
      <div className={styles["features-list"]}>
        <FeatureList
          features={{
            items: [
              "No expiry date to use minutes",
              "50 GB storage",
              "2 GB file upload"
            ]
          }}
        />
      </div>
    </>
  );
};

interface ModalPlanCard {
  modalData: getCardData;
  pricingToggle: PlanInterval;
  isOutOfMinuteModal?: boolean;
  isCurrentPlanCard?: boolean;
}
export const ModalPlanCard: React.FC<ModalPlanCard> = ({
  modalData,
  pricingToggle,
  isOutOfMinuteModal,
  isCurrentPlanCard
}) => {
  const featureItems = isOutOfMinuteModal
    ? [`${modalData.minutes} minutes`, ...modalData.featuresList]
    : modalData.featuresList;

  return (
    <>
      <div className={styles["icon-badge-container"]}>
        <PlanIcon plan={modalData.planData} />
        {isCurrentPlanCard && (
          <Badge className={styles["current-plan-badge"]}>Current Plan</Badge>
        )}
      </div>
      <h3 className={styles["price"]}>
        <sup className={styles["dollar-sign"]}>$</sup>
        {pricingToggle === PlanInterval.Monthly
          ? modalData.monthlyPrice
          : modalData.yearlyPrice}
        <span className="ml-1 text-text-primary">total</span>
      </h3>

      <div
        className={classNames(styles["toggle-container"], {
          [styles["oom-version"]]: isOutOfMinuteModal
        })}
      >
        <IntervalToggle />
      </div>
      <div className={styles["features-list"]}>
        <FeatureList features={{ items: featureItems }} />
      </div>
    </>
  );
};

export const InactiveBusinessCustomCard: React.FC = () => {
  return (
    <>
      <div className={classNames("mb-2", styles["icon-badge-container"])}>
        <PlanIcon plan={getPlanData(SublyPlan.Business)} />
        <Badge className={styles["current-plan-badge"]}>Custom Plan</Badge>
      </div>
      <p>
        Chat to our friendly sales team member to resubscribe to our custom
        Business plan.
      </p>
      <div className={styles["features-list"]}>
        <FeatureList
          features={{
            items: [
              "Unlimited users",
              "Account manager",
              "Bulk upload files",
              "Priority support",
              "Team billing by invoice",
              "Scalable plan"
            ]
          }}
        />
      </div>
    </>
  );
};

const getLastPlanLabel = (plan: string): PlanLabelTypes => {
  switch (plan) {
    case "Subly Pro":
    case "Subly Pro Yearly":
      return PlanLabelTypes.Pro;
    case "Subly Premium":
    case "Subly Premium Yearly":
      return PlanLabelTypes.Premium;
    case "Subly Business":
    case "Subly Business Yearly":
      return PlanLabelTypes.Business;
    default:
      return PlanLabelTypes.Pro;
  }
};

const parseFilename = (rawFilename: string) => {
  const langRegex = new RegExp(/[a-z]{2}-[a-z]{2}/, "gi");
  const ratioRegex = new RegExp(/[0-9]{1,2}_[0-9]{1,2}/, "gi");
  const fileRegex = new RegExp(/[A-Z]{2}[a-z]+.*/, "gi");
  const { languages } = settings.transcription;

  const burnless = rawFilename.replace("burn-", "");
  const langCode = burnless.match(langRegex)?.toString() ?? "";
  const foundLang = languages.find((l) => l.code === langCode);
  const preRatio = burnless.match(ratioRegex)?.toString() ?? "";

  const lang = foundLang?.language ?? "";
  const ratio = preRatio.replace("_", ":");
  const filename = burnless.match(fileRegex)?.toString() ?? "";

  return {
    language: lang,
    ratio: ratio,
    name: filename
  };
};
