import React from "react";
import { inviteMembers } from "../../../api/invitation.service";
import { Button } from "../../../components/button/button";
import { useAccounts } from "../../../hooks/use-accounts";
import { Invitation } from "../../../interfaces/account";
import { Modal } from "../../modal/modal";
import { InviteEmailsContainer } from "../../onboarding/forms/invite-emails-container";
import styles from "./workspaces-invite-modal.module.scss";

interface WorkspaceInviteModalProps {
  onDismiss: () => void;
}
export const WorkspaceInviteModal: React.FC<WorkspaceInviteModalProps> = ({
  onDismiss
}) => {
  return (
    <Modal onDismiss={onDismiss} hasCloseIcon className={styles.modal}>
      <WorkspaceInviteModalContainer onDismiss={onDismiss} />
    </Modal>
  );
};

const WorkspaceInviteModalContainer: React.FC<WorkspaceInviteModalProps> = ({
  onDismiss
}) => {
  const [invitations, setInvitations] = React.useState<Invitation[]>([]);
  const [isLoading, setLoading] = React.useState(false);
  const { currentAccount } = useAccounts();

  const inviteEmails = async () => {
    setLoading(true);

    try {
      await inviteMembers(invitations);
      onDismiss();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <h5>Invite teammates to {currentAccount?.accountName} Workspace</h5>
      </div>
      <InviteEmailsContainer
        invitations={invitations}
        setInvitations={setInvitations}
      />
      <div className="mt-4 d-flex justify-content-end">
        <Button tertiary className="mr-2" onClick={onDismiss}>
          Cancel
        </Button>
        <Button
          primary
          loading={isLoading}
          onClick={inviteEmails}
          disabled={!Boolean(invitations.length)}
        >
          Send invites
        </Button>
      </div>
    </>
  );
};
