import React from "react";
import { saveSurveyResult, updateSettings } from "../../../api/auth.service";
import { Survey, SurveyQuestion, SurveyType } from "../../../interfaces/survey";
import { SendIcon } from "../../../components/icons/editor-icons";
import { LoadingIcon } from "../../../components/loading-icon/loading-icon";
import { useAccounts } from "../../../hooks/use-accounts";
import { SHORTCUTS, ShortcutTag } from "../../../interfaces/shortcuts";
import { Modal } from "../../modal/modal";
import styles from "./dashboard-shortcuts-modal.module.scss";
import { useAnalyticsWithAuth } from "../../../hooks/use-analytics-with-auth";
import { useUpload } from "../../../hooks/use-upload";
import { useModal } from "../../../hooks/use-modal";
import { ModalType } from "../../../interfaces/modal-type";
import { uploadQuery, uploadStore } from "../../../state/upload";
import { UploadModal } from "../upload-modal/upload-modal";
import { UpgradeToTranslateModal } from "../upgrade-to-translate-modal/upgrade-to-translate-modal";
import { PremiumBadge } from "../../../components/badge/premium-badge";
import { usePermissions } from "../../../hooks/use-permissions";
import { SublyPlan } from "../../../interfaces/billing";

interface DashboardShortcutsModal {
  closeModal: () => void;
}

export const DashboardShortcutsModal: React.FC<DashboardShortcutsModal> = ({
  closeModal
}) => {
  return (
    <Modal
      onDismiss={closeModal}
      className={styles.modal}
      hasCloseIcon
      closeIconClassName={styles.close}
    >
      <DashboardShortcutsContainer />
    </Modal>
  );
};

const DashboardShortcutsContainer: React.FC = () => {
  const [featureResponse, setFeatureResponse] = React.useState<string>("");
  const { trackEventWithAuth } = useAnalyticsWithAuth();
  const { upload } = useUpload();
  const [showUploadModal, hideUploadModal] = useModal(ModalType.Upload);
  const [showUpgradeToTranslateModal, hideUpgradeToTranslateModal] = useModal(
    ModalType.UpgradeToTranslateModal
  );
  const isUploading = Boolean(upload?.isUploading);
  const { hasPermission } = usePermissions(SublyPlan.Premium);

  const handleCloseUploadModal = () => {
    hideUploadModal();

    if (!uploadQuery.getValue().isUploading) {
      uploadStore.resetUpload();
    }
  };

  // Triggered when cliking 'Translate' shortcut
  const handleShowUpgradeToTranslate = (tag: string) => {
    if (isUploading) {
      return;
    }
    showUpgradeToTranslateModal(
      <UpgradeToTranslateModal closeModal={hideUpgradeToTranslateModal} />
    );
    trackEventWithAuth(`Launch upload screen from shortcut ${tag}`);
    trackEventWithAuth("Launch upload screen from shortcut", {
      shortcut: tag
    });
  };

  // Triggered when clicking other dashboard shortcuts
  const handleShowUpload = (tag: string) => {
    if (isUploading) {
      return;
    }

    showUploadModal(<UploadModal closeModal={handleCloseUploadModal} />);
    trackEventWithAuth(`Launch upload screen from shortcut ${tag}`);
    trackEventWithAuth("Launch upload screen from shortcut", {
      shortcut: tag
    });
    trackEventWithAuth("Click on upgrade from shortcut", { shortcut: tag });
  };

  const handleClickShortcut = (tag: string) => {
    handleCloseUploadModal();
    if (tag === ShortcutTag.Translating && !hasPermission) {
      handleShowUpgradeToTranslate(tag);
    } else {
      handleShowUpload(tag);
    }
  };

  const renderShortcuts = SHORTCUTS.map(
    ({ label, Image, tag, isPremiumFeature }, index) => {
      return (
        <div
          className={styles.shortcut}
          onClick={() => handleClickShortcut(tag)}
          key={index}
        >
          <Image />
          <div className={styles["name-container"]}>
            <p className={styles.name}>{label}</p>
            {isPremiumFeature && (
              <PremiumBadge hideLink className={styles["badges"]} />
            )}
          </div>
        </div>
      );
    }
  );
  return (
    <div className={styles.container}>
      <h5>What would you like to do today?</h5>
      <div>
        <div className={styles.shortcuts}>
          {renderShortcuts}
          <FeedbackFormInput
            currentInput={featureResponse}
            setInput={setFeatureResponse}
          />
        </div>
      </div>
    </div>
  );
};

interface FeedbackFormInputProps {
  currentInput: string;
  setInput: (text: string) => void;
}
const FeedbackFormInput: React.FC<FeedbackFormInputProps> = ({
  currentInput,
  setInput
}) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const { user } = useAccounts();

  const submitSurvey = async () => {
    try {
      setLoading(true);

      const surveyResults: Survey = {
        surveyId: SurveyQuestion.OtherFeatures,
        surveyType: SurveyType.Text,
        result: currentInput
      };

      await saveSurveyResult(surveyResults);

      await updateSettings({
        surveysCompleted: {
          ...user?.settings?.surveysCompleted,
          [SurveyQuestion.OtherFeatures]: true
        }
      });
      setInput("");
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.feedbackForm}>
      <p>{SurveyQuestion.OtherFeatures}</p>
      <div className={styles.formInput}>
        <input
          type="text"
          placeholder="Please describe in detail"
          className={styles.input}
          value={currentInput}
          onChange={(e) => setInput(e.target.value)}
          disabled={loading}
        />

        {loading ? (
          <LoadingIcon className={styles.send} />
        ) : (
          <SendIcon className={styles.send} onClick={submitSurvey} />
        )}
      </div>
    </div>
  );
};
