import { useObservable } from "@mindspace-io/react";
import React from "react";
import { BlankBillingCard } from "../../../components/blank-billing-card/blank-billing-card";
import { Button } from "../../../components/button/button";
import { LoadingIcon } from "../../../components/loading-icon/loading-icon";
import { INVOICES_PATH } from "../../../routes";
import { accountQuery } from "../../../state/account";
import { InvoiceTable } from "./invoice-table";

export const InvoicesCard: React.FC = () => {
  const [{ invoices, loading }] = useObservable(
    accountQuery.select(["loading", "invoices"]),
    { loading: true, invoices: [] }
  );

  if (loading) {
    return (
      <BlankBillingCard to="#">
        <LoadingIcon />
      </BlankBillingCard>
    );
  }

  if (!invoices || !invoices.length) {
    return (
      <div className="account-card card my-4">
        <div className="card-body p-4">
          <h4 className="mt-2">Invoices</h4>
          <div className="mt-4">
            <p>No invoices yet.</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="account-card card my-4">
      <div className="card-body p-4">
        <h4 className="mt-2">Invoices</h4>
        <InvoiceTable skipDescription limit={3} />
      </div>
      <div className="card-footer py-3">
        <Button tertiary to={INVOICES_PATH}>
          See more
        </Button>
      </div>
    </div>
  );
};
