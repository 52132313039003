import React from "react";
import { Link } from "react-router-dom";
import { PLANS_PATH } from "../../routes";

export const EN = {
  media: {
    InsufficientFunds: (
      <>
        You don't have enough balance, please{" "}
        <Link to={PLANS_PATH}>upgrade</Link> for more minutes.
      </>
    ),
    UploadCancelled: (
      <>
        An error occurred while uploading your file. Delete file to try again,
        and report issue if the problem persists.
      </>
    ),
    ProcessingCancelled: (
      <>
        An error occurred while processing your file. Delete file to try again,
        and report issue if the problem persists.
      </>
    ),
    NotFound: <>An error occurred while loading your file.</>
  },
  error: {
    defaultMessage: (
      <>
        There was an unknown error, please try again or contact{" "}
        <a href="mailto:support@getsubly.com">support@getsubly.com</a>
      </>
    ),
    Empty: (
      <>
        Oops, you've left this subtitle empty. Add text here or remove this
        subtitle.
      </>
    ),
    StartTimeOverlap: (
      <>
        Oops, there's an overlap here in your subtitles. Edit this timestamp or
        it won't be included.
      </>
    ),
    EndTimeOverlap: (
      <>
        Oops, there's an overlap here in your subtitles. Edit this timestamp or
        it won't be included.
      </>
    ),
    StartAfterEnd: (
      <>
        Oops, this timestamp has an end date before its start date. Edit the
        timestamp or it won't be included.
      </>
    ),
    MaxFileDuration: (
      <>The selected file is over the maximum duration of 4 hours.</>
    ),
    PrivateVideo: (
      <>
        Could not upload file as it is private.
        <br />
        Please make it public or unlisted to be able to upload it.
      </>
    )
  },
  permissions: {
    admin: {
      title: "Everything that Editor can do plus:",
      items: [
        "Can edit account billing details",
        "Can remove admin or editor users within the workspace, except the owner",
        "Can delete any media in shared folders"
      ]
    },
    editor: {
      items: [
        "Can add editors to the workspace",
        "Can invite users to view/edit their content",
        "Can add/edit/rename/delete their own medias",
        "Can add/edit any media in shared folders",
        "Can move media uploaded by them to private folders",
        "Can move media uploaded by others within shared folders only",
        "Can create new shared folders"
      ]
    }
  },
  mediaPermissions: {
    editor: {
      title: "Everything that viewer can do plus:",
      items: [
        "Can view and edit shared media with all the features available in their plan",
        "Can remove themselves from shared media"
      ]
    },
    viewer: {
      items: [
        "Can view shared media",
        "Can download shared media",
        "Cannot edit shared media",
        "Cannot delete shared media",
        "Cannot invite other users to view or edit media"
      ]
    }
  },
  shortcuts: [
    {
      title: "Show keyboard shortcuts",
      keys: ["Ctrl", "/"]
    },
    {
      title: "Toggle autoscroll",
      keys: ["Alt", "s"]
    },
    {
      title: "Search for word",
      keys: ["Ctrl", "f"]
    },
    {
      title: "New subtitle cue",
      keys: ["Enter"]
    },
    {
      title: "Add a break line to subtitle cue",
      keys: ["Ctrl", "Enter"]
    },
    {
      title: "Merge current and previous subtitle cue",
      keys: ["Ctrl", "Del"]
    },
    {
      title: "Navigate to next word",
      keys: ["Alt", "→"]
    },
    {
      title: "Navigate to previous word",
      keys: ["Alt", "←"]
    },
    {
      title: "Start/Pause video",
      keys: ["Alt", "Spacebar"]
    },
    {
      title: "Skip forward 10 seconds",
      keys: ["l"]
    },
    {
      title: "Skip back 10 seconds",
      keys: ["j"]
    },
    {
      title: "Navigate to next subtitle cue",
      keys: ["Ctrl", "]"]
    },
    {
      title: "Navigate to previous subtitle cue",
      keys: ["Ctrl", "["]
    },
    {
      title: "Undo",
      keys: ["Ctrl", "z"]
    },
    {
      title: "Redo",
      keys: ["Ctrl", "y"]
    }
  ]
};
