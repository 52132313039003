import { Store, StoreConfig } from "@datorama/akita";
import config from "../../config";
import { AUDIO_TEMPLATES } from "../../containers/media-editor/toolbars/media-elements-toolbar/templates-dropdown/audio-templates";
import { AccountSettings, UserAccount } from "../../interfaces/account";
import {
  Deal,
  Invoice,
  PaymentMethod,
  Product,
  Subscription
} from "../../interfaces/billing";
import { ModalType } from "../../interfaces/modal-type";
import { User } from "../../interfaces/user";
import { compareVersions } from "../../utils/strings";

export const DEFAULT_ACCOUNT_SETTINGS: AccountSettings = {
  primaryColors: [],
  outlineColors: [],
  aspectRatioColors: [],
  hasSeenMediaEditorTour: false,
  hasSeenSpeakersTour: false,
  templates: [],
  lastVersionTour: "2.0.0",
  showReleaseModal: false,
  hasTranslated: false,
  hidePositioningHint: false,
  hideTranslationWarning: false,
  hasSeenTrialModal: false,
  hasSeenTrialWelcome: false,
  hasSeenTrialOneDownloadRemain: false,
  hasSeenTrialLimitModal: false,
  hasUsedReturnCoupon: false,
  audioTemplates: AUDIO_TEMPLATES,
  progressBars: {},
  trialDownloads: 0,
  hasSeenTemplateDeleteModal: false
};

export interface AccountState extends UserAccount {
  user?: User;
  subscription?: Subscription;
  deal?: Deal;
  plans: Product[];
  paymentMethods: PaymentMethod[];
  invoices: Invoice[];
  credit: AccountCredit;
  settings: AccountSettings;
  storageBytesUsed: number;
  loadingPlans: boolean;
  loadedPlans: boolean;
  loading: boolean;
  loaded: boolean;
  lastPlan?: string;
}

export interface AccountCredit {
  loading: boolean;
  loaded: boolean;
  free?: number;
  paid?: number;
  extra?: number;
  payg?: number;
  total?: number;
  showUpgradeModal?: ModalType;
}

const createInitialState = (): Partial<AccountState> => {
  return {
    credit: {
      loaded: false,
      loading: true
    },
    billing: undefined,
    subscription: undefined,
    deal: undefined,
    loadedPlans: false,
    loadingPlans: true,
    loaded: false,
    loading: true,
    plans: [],
    paymentMethods: [],
    invoices: [],
    settings: DEFAULT_ACCOUNT_SETTINGS,
    lastPlan: undefined
  };
};

@StoreConfig({ name: "account" })
export class AccountStore extends Store<AccountState> {
  constructor() {
    super(createInitialState());
  }

  updateAccountSettings(settings: Partial<AccountSettings>): void {
    this.update((s) => ({ ...s, settings: { ...s.settings, ...settings } }));
  }

  updateWithShowReleaseModal(settings: AccountSettings): void {
    const updatedSettings = { ...DEFAULT_ACCOUNT_SETTINGS, ...settings };
    const showReleaseModal =
      compareVersions(
        config.latestReleaseVersion,
        updatedSettings.lastVersionTour
      ) > 0;

    this.updateAccountSettings({ ...updatedSettings, showReleaseModal });
  }

  resetUpgradeModal(): void {
    this.update((s) => ({
      ...s,
      credit: { ...s.credit, showUpgradeModal: undefined }
    }));
  }

  decrementMediaCount(): void {
    this.update((x) => ({
      ...x,
      mediaCount: x.mediaCount ? x.mediaCount - 1 : undefined
    }));
  }

  decrementStorageBytesUsed(fileSize: number): void {
    this.update((s) => ({
      ...s,
      storageBytesUsed: s.storageBytesUsed - fileSize
    }));
  }
  incrementStorageBytesUsed(fileSize: number): void {
    this.update((s) => ({
      ...s,
      storageBytesUsed: s.storageBytesUsed + fileSize
    }));
  }
}

export const accountStore = new AccountStore();
