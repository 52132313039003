import React from "react";
import classNames from "classnames";
import styles from "./app-card.module.scss";
import { App } from "../../interfaces/app";

interface AppCardProps {
  app: App;
  open: () => void;
}
export const AppCard: React.FC<AppCardProps> = ({ app, open }) => {
  return (
    <div className={classNames(styles["app-card"])} onClick={open}>
      {app.icon}
      <h6 className={classNames(styles.name, "mb-1 mt-3 text-truncate")}>
        {app.name}
      </h6>
      <div className="d-flex justify-content-between mb-1">
        <div className={styles.info}>{app.description}</div>
      </div>
    </div>
  );
};
