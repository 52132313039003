import classNames from "classnames";
import React from "react";
import { Modal } from "../../containers/modal/modal";
import { PlanIcon } from "../../containers/settings/plans/plans-table";
import { SublyPlan } from "../../interfaces/billing";
import { PLANS_PATH } from "../../routes";
import { getPlanData, SublyPlanData } from "../../utils/plans";
import { Button } from "../button/button";
import { Divider } from "../divider/divider";
import { CheckmarkFullIcon, PriceSlashIcon } from "../icons/icons";
import styles from "./trial-dashboard-modal.module.scss";

interface DashboardTrialModalProps {
  closeModal: () => void;
}
export const DashboardTrialModal: React.FC<DashboardTrialModalProps> = ({
  closeModal
}) => {
  const planData = getPlanData(SublyPlan.Business);

  return (
    <Modal onDismiss={closeModal} className={styles["modal"]} hasCloseIcon>
      <div className={styles["title"]}>
        <h5>Welcome to Subly</h5>
        <p>
          Thanks for joining Subly. We're excited to help you get started with
          creating amazing subtitled content.
        </p>

        <Divider className={styles["divider"]} />

        <h6>Did you know?</h6>
        <span className={styles["title-desc"]}>
          You can now try Subly for 7 days for free. Start your trial below.
        </span>
      </div>

      <div className="d-flex flex-sm-row flex-column align-items-center justify-content-center my-2">
        <TrialPlanCard planData={planData} closeModal={closeModal} />
      </div>

      <div className="d-flex align-items-center justify-content-center">
        <Button secondary className="d-block" onClick={closeModal}>
          Continue with Subly Free
        </Button>
      </div>
    </Modal>
  );
};

interface TrialPlanCardProps {
  planData: SublyPlanData;
  closeModal: () => void;
}

const TrialPlanCard: React.FC<TrialPlanCardProps> = ({
  planData,
  closeModal
}) => {
  return (
    <div className={styles["card"]}>
      <PlanIcon plan={planData} />
      <br />
      <br className={styles["price-desc-mobile"]} />
      <span className={styles["price-desc-mobile"]}>
        FREE FOR 7 DAYS - 40 mins included
      </span>
      <div className={styles["display-plan-list"]}>
        <FeatureList planData={planData} />
      </div>

      <div className={styles["plan-price"]}>
        <div className={styles["price"]}>
          <div className={styles["slash"]}>
            <PriceSlashIcon />
            <h4>
              ${planData.price}
              <span>/month</span>
            </h4>
          </div>

          <span className={styles["price-desc-desk"]}>
            FREE FOR 7 DAYS <br /> 40 mins included
          </span>
        </div>

        <Button
          primary
          to={{
            pathname: PLANS_PATH
          }}
          state={{
            plan: planData.plan
          }}
          onClick={closeModal}
        >
          Try free
        </Button>
      </div>
    </div>
  );
};

interface FeatureListProps {
  planData: SublyPlanData;
}
const FeatureList: React.FC<FeatureListProps> = (planData) => {
  const { plan, features } = planData.planData;

  if (plan === SublyPlan.Pro100) {
    const featureList = [
      "Remove Subly watermark",
      "Create videos from audio files",
      "Download SRT, TXT and VTT",
      "Brand your content"
    ];
    const renderFeatures = featureList.map((f) => {
      return <FeatureItem feature={f} key={f} />;
    });

    return (
      <ul className={styles["plan-list"]}>
        <li className={classNames(styles["plan-list-title"], "mb-3")}> </li>
        {renderFeatures}
      </ul>
    );
  }

  const featureList = [
    "Translate subtitles",
    "File collaboration",
    "HD files",
    "Custom style templates"
  ];

  const renderFeatures = featureList.map((f) => {
    return <FeatureItem feature={f} key={f} />;
  });

  return (
    <ul className={styles["plan-list"]}>
      {features.title && (
        <li className={styles["plan-list-title"]}>{features.title}</li>
      )}
      {renderFeatures}
    </ul>
  );
};

interface FeatureItemProps {
  feature: string;
}
const FeatureItem: React.FC<FeatureItemProps> = ({ feature }) => {
  return (
    <li className={styles["item"]}>
      <CheckmarkFullIcon className={styles["icon"]} />
      {feature}
    </li>
  );
};
