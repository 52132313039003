import { useObservable } from "@mindspace-io/react";
import classNames from "classnames";
import { formatDistance, intervalToDuration, subDays } from "date-fns";
import React from "react";
import { Link } from "react-router-dom";

import {
  BasicMedia,
  MediaErrorReason,
  MediaJob,
  MediaJobType,
  MediaStatus
} from "../../interfaces/media";
import { userPresenceQuery } from "../../state/user-presence";
import { durationLessThan1Min } from "../../utils/dates";
import { getBurningTaskLabel } from "../../utils/media-functions";
import { parseCreditToText } from "../../utils/plans";
import { MediaCardTop, MediaCardUser } from "./media-card-top";
import styles from "./media-card.module.scss";

interface MediaCardProps {
  media: BasicMedia;
}
export const MediaCard: React.FC<MediaCardProps> = ({ media }) => {
  const [isDropdownOpen, setDropdownOpen] = React.useState(false);
  const isMediaReady = MediaStatus.Ready === media.status;
  const mediaLink = isMediaReady ? `/videos/${media.mediaId}` : "#";
  const hasError = MediaStatus.Failed === media.status;
  const mediaDuration = media.duration ?? 0;

  const [activeUser] = useObservable(
    userPresenceQuery.selectActiveUser(media.id)
  );

  return (
    <div
      className={classNames(styles["media-card"], {
        [styles["is-ready"]]: isMediaReady,
        [styles["is-error"]]: hasError,
        [styles["is-dropdown-open"]]: isDropdownOpen
      })}
    >
      <MediaCardUser media={media} />
      <figure
        className={classNames(styles["figure"], "m-0", {
          [styles["error"]]: hasError
        })}
      >
        <MediaCardTop
          media={media}
          isDropdownOpen={isDropdownOpen}
          setDropdownOpen={setDropdownOpen}
        />
      </figure>
      <Link to={mediaLink} className={styles["media-card-bottom"]}>
        <h6 className={classNames(styles["name"], "m-0 text-truncate")}>
          {media.name}
        </h6>
        <div className={"d-flex justify-content-between"}>
          <div className={styles.info}>{parseCreditToText(mediaDuration)}</div>
          <div className={styles.info}>
            {getMediaStatus(media, Boolean(activeUser))}
          </div>
        </div>
      </Link>
    </div>
  );
};
const getStatusForPendingJob = (job?: MediaJob): string => {
  switch (job?.type) {
    case MediaJobType.Upload:
      return "Uploading...";
    case MediaJobType.Conversion:
      return "Optimising";
    case MediaJobType.Transcribe:
      return "Transcribing";
    default:
      return "Queuing";
  }
};

const getMediaStatus = (media: BasicMedia, hasActiveUser?: boolean): string => {
  const job = media.latestJob;

  if (media.reason === MediaErrorReason.InsufficientFunds) {
    return "Insufficient credit";
  }

  if (media.isBurning) {
    return getBurningTaskLabel(media.burningTasks);
  }

  switch (media.status) {
    case MediaStatus.Uploading:
    case MediaStatus.Uploaded:
    case MediaStatus.Pending:
    case MediaStatus.Converting:
      return getStatusForPendingJob(job);

    case MediaStatus.Failed:
      return "Error in processing";
  }

  if (hasActiveUser) {
    return "Currently being edited";
  }

  const createdDuration = intervalToDuration({
    start: new Date(media.createdAt),
    end: new Date(media.updatedAt)
  });

  if (!durationLessThan1Min(createdDuration)) {
    return "Ready to edit";
  }

  return formatDistance(subDays(new Date(media.updatedAt), 0), new Date(), {
    addSuffix: true
  });
};
