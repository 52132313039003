import React from "react";
import Lottie from "react-lottie-player";
import { Select } from "../../../../components/controls/select";
import IndividualCreatorAnimation from "../../../../assets/images/onboarding/individual-creator-animation.json";
import CorporateAnimation from "../../../../assets/images/onboarding/corporate-animation.json";
import OtherAnimation from "../../../../assets/images/onboarding/other-animation.json";
import DigitalAgencyAnimation from "../../../../assets/images/onboarding/digital-agency-animation.json";
import VideoProductionAnimation from "../../../../assets/images/onboarding/video-production-animation.json";
import { RadioInput } from "../../../../components/radio-input/radio-input";
import { useOnboarding } from "../../../../contexts/onboarding.context";
import {
  ContentFor,
  IndividualJobType,
  Industry
} from "../../../../interfaces/user";
import { SelectChangeEvent } from "../../../../utils/select";
import { OnboardingButtons } from "../../onboarding-buttons";
import { OnboardingSteps } from "../../onboarding-steps";
import styles from "./onboarding-user.module.scss";

export const OnboardingUser: React.FC = () => {
  const { data, previousStep } = useOnboarding();

  const hideForm =
    !data.contentFor ||
    [ContentFor.DigitalAgency, ContentFor.VideoProduction].includes(
      data.contentFor
    );

  React.useEffect(() => {
    if (hideForm) {
      previousStep();
    }
  }, [data.contentFor]);

  if (hideForm) {
    return <></>;
  }

  if (data.contentFor === ContentFor.IndividualCreator) {
    return <OnboardingIndividualCreator />;
  }

  if (data.contentFor === ContentFor.Corporate) {
    return <OnboardingCorporate />;
  }

  return <OnboardingOther />;
};

const OnboardingIndividualCreator: React.FC = () => {
  const { data, setData, isLoading } = useOnboarding();

  const jobTypes = Object.values(IndividualJobType);
  const hasJobType = jobTypes.includes(data.jobType as IndividualJobType);
  const initOther = hasJobType ? "" : data.jobType;

  const [otherJob, setOtherJob] = React.useState(initOther);

  const renderSources = jobTypes.map((job) => {
    const isOther =
      job === IndividualJobType.Other && Boolean(!hasJobType && otherJob);
    const defaultChecked = isOther || data.jobType === job;

    return (
      <RadioInput
        className="mt-2"
        name="jobType"
        id={job}
        value={job}
        defaultChecked={defaultChecked}
        key={job}
      >
        {job}
      </RadioInput>
    );
  });

  const handleSourceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const jobType = e.target.value as IndividualJobType;
    setData((d) => ({ ...d, jobType }));
  };

  const handleOtherChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let jobType = e.target.value as IndividualJobType;

    if (e.target.value === "") {
      jobType = IndividualJobType.Other;
    }

    setData((d) => ({ ...d, jobType }));
    setOtherJob(jobType);
  };

  const handleBackButton = () => {
    delete data.jobType;
    delete data.contentFor;
    setData(data);
  };

  const showOther =
    Boolean(!hasJobType && otherJob) ||
    data.jobType === IndividualJobType.Other;

  return (
    <div className={styles.wrapper}>
      <div className={styles.image}>
        <Animation contentType={ContentFor.IndividualCreator} />

        <h6>Individual Creator</h6>
      </div>
      <div className={styles.container}>
        <div className={styles.form} onChange={handleSourceChange}>
          <h3 className={styles.header}>
            What is your job?
            <OnboardingSteps />
          </h3>
          <div>
            {renderSources}
            {showOther && (
              <input
                type="text"
                className="form-control mt-2"
                placeholder="Please specify"
                value={otherJob}
                onChange={handleOtherChange}
              />
            )}
          </div>
        </div>
        <OnboardingButtons
          onPrev={handleBackButton}
          disableNext={!data.jobType}
          isLoading={isLoading}
          skipToFinish={true}
        />
      </div>
    </div>
  );
};

const OnboardingCorporate: React.FC = () => {
  const { data, setData } = useOnboarding();
  const [selectedIndustry, setSelectedIndustry] = React.useState<Industry>();

  const hasIndustry = Object.values(Industry).includes(
    data.industry as Industry
  );
  const initOther = hasIndustry ? "" : data.industry;
  const [other, setOther] = React.useState(initOther);

  const handleIndustryChange = (e: SelectChangeEvent) => {
    if (e === null) {
      return;
    }

    const industry = e.value as Industry;
    setSelectedIndustry(industry);

    if (selectedIndustry === Industry.Other) {
      setOther(industry);
    } else {
      setData((d) => ({ ...d, industry }));
    }
  };

  const handleOtherChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const industry = e.target.value as Industry;
    setData((d) => ({ ...d, industry }));
    setOther(industry);
  };

  const handleBackButton = () => {
    delete data.industry;
    delete data.contentFor;
  };

  const isOther =
    Boolean(!hasIndustry && other) || selectedIndustry === Industry.Other;

  const renderOptions = Object.values(Industry).map((i) => ({
    value: i,
    label: i
  }));

  const selectedValue = initOther
    ? Industry.Other
    : (data.industry as Industry);

  return (
    <div className={styles.wrapper}>
      <div className={styles.image}>
        <Animation contentType={ContentFor.Corporate} />
        <h6>Corporate / Enterprise</h6>
      </div>
      <div className={styles.container}>
        <div className={styles.form}>
          <h3 className={styles.header}>
            What is your industry?
            <OnboardingSteps />
          </h3>
          <Select
            placeholder="Please select"
            isSearchable
            options={renderOptions}
            defaultValue={{
              value: selectedValue,
              label: selectedValue
            }}
            onChange={handleIndustryChange}
            className={styles.select}
          />
          {isOther && (
            <input
              type="text"
              className="form-control mt-2"
              placeholder="Please specify"
              value={other}
              onChange={handleOtherChange}
            />
          )}
        </div>
        <OnboardingButtons
          onPrev={handleBackButton}
          disableNext={!data.industry}
        />
      </div>
    </div>
  );
};

const OnboardingOther: React.FC = () => {
  const { data, setData } = useOnboarding();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const jobTitle = e.target.value;
    setData((d) => ({ ...d, jobTitle }));
  };

  const handleBackButton = () => {
    delete data.jobTitle;
    delete data.contentFor;
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.image}>
        <Animation contentType={ContentFor.Other} />
        <h6>Other</h6>
      </div>
      <div className={styles.container}>
        <div className={styles.form}>
          <h3 className={styles.header}>
            Who are you?
            <OnboardingSteps />
          </h3>
          <input
            type="text"
            className="form-control"
            value={data.jobTitle ?? ""}
            placeholder="What is your job title?"
            onChange={handleChange}
          />
        </div>
        <OnboardingButtons
          onPrev={handleBackButton}
          disableNext={!data.jobTitle}
        />
      </div>
    </div>
  );
};

interface AnimationProps {
  contentType: ContentFor;
}
const Animation: React.FC<AnimationProps> = ({ contentType }) => {
  let animation;

  switch (contentType) {
    case ContentFor.IndividualCreator:
      animation = IndividualCreatorAnimation;
      break;
    case ContentFor.Corporate:
      animation = CorporateAnimation;
      break;
    case ContentFor.Other:
      animation = OtherAnimation;
      break;
    case ContentFor.DigitalAgency:
      animation = DigitalAgencyAnimation;
      break;
    case ContentFor.VideoProduction:
      animation = VideoProductionAnimation;
      break;
  }
  return (
    <>
      <Lottie
        loop
        animationData={animation}
        speed={0.5}
        className={styles["animation"]}
        play
      />
    </>
  );
};
