export function notEmpty<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

export function arrayIncludes(arr: string[], compare: string[]): boolean {
  return arr.some((v) => compare.includes(v));
}

export function firstFreeId(array: number[], start = 0): number {
  if (!array.includes(start)) return start;

  const availableIds = array.filter((val) => val >= start).sort();
  if (availableIds.length === 0) {
    return start;
  }

  const freeId = availableIds.find((val, index) => {
    return index < array.length && array[index + 1] - val > 1;
  });

  return freeId ? freeId + 1 : availableIds[availableIds.length - 1] + 1;
}
