import React from "react";
import { Oauth } from "../containers/auth/oauth";
import { AuthLayout } from "../layouts/auth.layout";

export const OauthPage: React.FC = () => {
  return (
    <AuthLayout showLogin>
      <Oauth />
    </AuthLayout>
  );
};
