import React from "react";

import styles from "./zoom.module.scss";
import classNames from "classnames";
import { Button } from "../../components/button/button";
import { Badge } from "../../components/badge/badge";
import { FolderDownloadIcon } from "@heroicons/react/outline";
import { LockFeaturePremium } from "../../components/lock-feature/lock-feature-premium";
import { PremiumBadge } from "../../components/badge/premium-badge";
import { usePermissions } from "../../hooks/use-permissions";
import { SublyPlan } from "../../interfaces/billing";

enum ZoomTabs {
  NotImported,
  Imported,
  Archived
}

interface ZoomHeaderProps {
  showTabs?: boolean;
  tab: ZoomTabs;
  setTab: React.Dispatch<React.SetStateAction<ZoomTabs>>;
  archiveFiles?: () => void;
  importFiles?: () => void;
  showImport: boolean;
  disableImport: boolean;
  showArchive: boolean;
  disableArchive: boolean;
}
export const ZoomHeader: React.FC<ZoomHeaderProps> = ({
  showTabs,
  tab,
  setTab,
  archiveFiles,
  importFiles,
  showImport,
  disableImport,
  disableArchive,
  showArchive
}) => {
  const { hasPermission } = usePermissions(SublyPlan.Premium);

  const renderTabs = (
    <ul className={styles["zoom-tabs"]}>
      <li
        className={classNames(styles["zoom-tab"], {
          [styles["zoom-tab--active"]]: tab === ZoomTabs.NotImported
        })}
        onClick={() => setTab(ZoomTabs.NotImported)}
        role="button"
      >
        <>
          {/* <FolderAddIcon style={{ height: 14, width: 14 }} /> */}
          Not imported
          <Badge className="ml-1">4</Badge>
        </>
      </li>
      <li
        className={classNames(styles["zoom-tab"], {
          [styles["zoom-tab--active"]]: tab === ZoomTabs.Imported
        })}
        onClick={() => setTab(ZoomTabs.Imported)}
        role="button"
      >
        {/* <FolderDownloadIcon /> */}
        Imported
        <Badge className="ml-1">4</Badge>
      </li>

      <li
        className={classNames(styles["zoom-tab"], {
          [styles["zoom-tab--active"]]: tab === ZoomTabs.Archived
        })}
        onClick={() => setTab(ZoomTabs.Archived)}
        role="button"
      >
        {/* <ArchiveIcon /> */}
        Archived
        <Badge className="ml-1">4</Badge>
      </li>
    </ul>
  );

  return (
    <div className="d-flex flex-row mb-3">
      {showTabs && renderTabs}
      <div className="d-flex justify-content-end w-100">
        {showArchive && (
          <Button
            secondary
            onClick={archiveFiles}
            className="mr-3"
            disabled={disableArchive}
          >
            {/* <TrashIcon /> */}
            Archive
          </Button>
        )}
        {showImport && (
          <div className="d-flex align-items-center">
            {!hasPermission && <PremiumBadge className={styles["badges"]} />}
            <LockFeaturePremium>
              <Button primary onClick={importFiles} disabled={disableImport}>
                <div className={styles.buttonIcon}>
                  <FolderDownloadIcon />
                  Import and transcribe
                </div>
              </Button>
            </LockFeaturePremium>
          </div>
        )}
      </div>
    </div>
  );
};
