import React from "react";
import ReactSelect, { GroupTypeBase, NamedProps, Styles } from "react-select";

interface Options {
  value: string;
  label: string;
}

interface SelectProps extends NamedProps {
  label?: string;
  options: Options[];
  styles?: Partial<Styles<Options, false, GroupTypeBase<Options>>>;
  placeholder?: string;
  className?: string;
}

export const Select = React.forwardRef<ReactSelect, SelectProps>(
  ({ label, ...props }, ref) => {
    return (
      <>
        {label && <label className="mb-1">{label}</label>}
        <ReactSelect
          isSearchable={true}
          theme={(theme) => ({
            ...theme,
            borderRadius: 4,
            colors: {
              ...theme.colors,
              primary: "var(--interactive)"
            }
          })}
          ref={ref}
          {...props}
        />
      </>
    );
  }
);
