import { Query } from "@datorama/akita";
import { Observable } from "rxjs";
import {
  ModalState,
  modalStore,
  ModalStore,
  VisibleModal
} from "./modal.store";

export class ModalQuery extends Query<ModalState> {
  get visibleModals(): VisibleModal[] {
    return this.getValue().visibleModals;
  }

  constructor(protected store: ModalStore) {
    super(store);
  }

  selectVisibleModals(): Observable<VisibleModal[]> {
    return this.select("visibleModals");
  }
}

export const modalQuery = new ModalQuery(modalStore);
