import {
  ChevronDownIcon,
  PencilIcon,
  UserGroupIcon,
  UserIcon
} from "@heroicons/react/outline";
import { mdiChevronDown } from "@mdi/js";
import Icon from "@mdi/react";
import { useObservable } from "@mindspace-io/react";
import classNames from "classnames";
import React from "react";
import { AddFolderButton } from "../../../components/button/add-folder-button";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu
} from "../../../components/dropdown/dropdown";
import { useFolders } from "../../../hooks/use-folders";
import { useOnClickOutside } from "../../../hooks/use-on-click-outside";
import { usePlan } from "../../../hooks/use-plan";
import { foldersQuery } from "../../../state/folders/folders.query";
import { updateFolder } from "../../../state/folders/folders.service";
import { Folder, foldersStore } from "../../../state/folders/folders.store";
import styles from "./project-location-dropdown.module.scss";

interface ProjectLocationDropdownProps {
  folder?: Folder;
  setFolder: (folder?: Folder) => void;
  hidePrivateFolders?: boolean;
}
export const ProjectLocationDropdown: React.FC<
  ProjectLocationDropdownProps
> = ({ folder, setFolder, hidePrivateFolders }) => {
  const [isPrivateOpen, setIsPrivateOpen] = React.useState(false);
  const [isSharedOpen, setIsSharedOpen] = React.useState(false);
  const { isPremium, isBusiness } = usePlan();
  const { activeFolder, privateFolders, sharedFolders } = useFolders();
  const dropdownRef = React.useRef(null);
  const defaultFileLabel = activeFolder ? activeFolder.name : "All Files";
  const [label, setLabel] = React.useState(defaultFileLabel);

  React.useEffect(() => {
    if (!folder) {
      setLabel("All Files");
      setIsPrivateOpen(true);
    } else {
      setLabel(folder.name);
      if (folder.isPublic) {
        setIsSharedOpen(true);
      } else {
        setIsPrivateOpen(true);
      }
    }
  }, [folder]);

  const renderPrivateFolders = privateFolders.map((f) => {
    return (
      <DropdownFolderItem
        key={f.id}
        selected={f.id === folder?.id}
        onClick={() => setFolder(f)}
        isOpen={isPrivateOpen}
        folder={f}
      />
    );
  });

  const hasSharedFolders = isPremium || isBusiness;
  const renderSharedFolders = sharedFolders.map((f) => {
    return (
      <DropdownFolderItem
        key={f.id}
        selected={f.id === folder?.id}
        onClick={() => setFolder(f)}
        isOpen={isSharedOpen}
        folder={f}
      />
    );
  });

  return (
    <div
      ref={dropdownRef}
      className={classNames("dropdown user-select-none", styles.dropdown)}
    >
      <Dropdown>
        <DropdownButton
          dropdownEl={dropdownRef}
          className={styles["dropdown-button"]}
          isOpenClassName={styles["dropdown-button--open"]}
        >
          {label} <Icon path={mdiChevronDown} size="1.2em" />
        </DropdownButton>

        <DropdownMenu title={label} className={styles["dropdown-menu"]}>
          {!hidePrivateFolders && (
            <>
              <DropdownFolderHeader
                handleToggle={() => setIsPrivateOpen((s) => !s)}
                isOpen={isPrivateOpen}
                title="Private"
              />

              <div className={styles["folder-list"]}>
                <DropdownItem
                  selected={!Boolean(folder)}
                  onClick={() => setFolder()}
                  className={classNames(styles["dropdown-folder-item"], {
                    [styles["dropdown-folder-item--isOpen"]]: isPrivateOpen
                  })}
                  closeOnClick={false}
                >
                  All files
                </DropdownItem>
                {renderPrivateFolders}
              </div>
            </>
          )}
          {hasSharedFolders && (
            <>
              <DropdownFolderHeader
                handleToggle={() => setIsSharedOpen((s) => !s)}
                isOpen={isSharedOpen}
                title="Shared"
              />
              <div className={styles["folder-list"]}>{renderSharedFolders}</div>
            </>
          )}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

interface DropdownFolderHeaderProps {
  handleToggle: () => void;
  isOpen: boolean;
  title: string;
}
const DropdownFolderHeader: React.FC<DropdownFolderHeaderProps> = ({
  handleToggle,
  children,
  isOpen,
  title
}) => {
  const handleClickAddFolderButton = () => {
    if (isOpen) {
      return;
    } else {
      handleToggle();
    }
  };
  return (
    <div className={styles["dropdown-folder-header"]}>
      {children}
      <div className="d-flex align-items-center">
        {title === "Private" && <UserIcon className={styles.icon} />}
        {title === "Shared" && <UserGroupIcon className={styles.icon} />}
        <h6 className="mr-2">{title}</h6>
        <AddFolderButton
          folderCategory={title}
          onClick={handleClickAddFolderButton}
          toolTipPlace="right"
        />
      </div>
      <div onClick={handleToggle}>
        <ChevronDownIcon
          className={classNames(styles.icon, styles.chevron, {
            [styles["chevron--isOpen"]]: isOpen
          })}
        />
      </div>
    </div>
  );
};

interface DropdownFolderItemProps {
  selected: boolean;
  onClick: () => void;
  isOpen: boolean;
  folder: Folder;
}
const DropdownFolderItem: React.FC<DropdownFolderItemProps> = ({
  selected,
  onClick,
  isOpen,
  folder
}) => {
  const [isEditing] = useObservable(
    foldersQuery.selectIsEditing(folder.id),
    false
  );

  return (
    <DropdownItem
      selected={selected}
      onClick={onClick}
      className={classNames(styles["dropdown-folder-item"], {
        [styles["dropdown-folder-item--isOpen"]]: isOpen
      })}
      closeOnClick={false}
    >
      <div className="w-100 d-flex">
        {!isEditing ? folder.name : <FolderEditor folder={folder} />}

        <PencilIcon
          className={classNames(styles.icon, styles["pencil-icon"])}
          onClick={(e) => {
            e.stopPropagation();
            foldersStore.updateEditing(folder.id, true);
          }}
        />
      </div>
    </DropdownItem>
  );
};

interface FolderEditorProps {
  folder: Folder;
}

const FolderEditor: React.FC<FolderEditorProps> = ({ folder }) => {
  const [name, setName] = React.useState(folder.name);
  const ref = React.useRef<HTMLInputElement | null>(null);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    saveName();
  };

  const saveName = async () => {
    if (!name) {
      return;
    }

    updateFolder({ ...folder, name });
    foldersStore.updateEditing(folder.id, false);
  };

  useOnClickOutside(ref, saveName, true);

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        name="name"
        className={styles["folder-input"]}
        autoFocus
        ref={ref}
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
    </form>
  );
};
