import classNames from "classnames";
import React, { useState } from "react";
import { saveSurveyResult, updateSettings } from "../../api/auth.service";
import { useAccounts } from "../../hooks/use-accounts";
import { Survey, SurveyQuestion, SurveyType } from "../../interfaces/survey";
import { Button } from "../button/button";
import { Input } from "../controls/input";
import styles from "./app-card.module.scss";

export const SuggestionAppCard: React.FC = () => {
  const [suggestion, setSuggestion] = useState("");
  const [loading, setLoading] = useState(false);
  const [completedSurvey, setCompletedSurvey] = useState(false);
  const { user } = useAccounts();

  const handleSubmit = async () => {
    if (!suggestion) return;

    try {
      setLoading(true);

      const surveyResults: Survey = {
        surveyId: SurveyQuestion.AppIdea,
        surveyType: SurveyType.Text,
        result: suggestion
      };

      await saveSurveyResult(surveyResults);

      setCompletedSurvey(true);

      await updateSettings({
        surveysCompleted: {
          ...user?.settings?.surveysCompleted,
          [SurveyQuestion.AppIdea]: true
        }
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  if (completedSurvey) {
    return (
      <div className={classNames(styles["app-card"], styles["suggestion"])}>
        <h6 className={classNames(styles["name"], "mb-1 text-truncate")}>
          Suggest an app
        </h6>
        <div className={"d-flex justify-content-between mb-1"}>
          <div className={styles.info}>
            Thank you for submitting an app idea.
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={classNames(styles["app-card"], styles["suggestion"])}>
      <h6 className={classNames(styles["name"], "mb-1 text-truncate")}>
        Suggest an app
      </h6>
      <div className={"d-flex justify-content-between mb-1"}>
        <div className={styles.info}>What apps would you want to see here?</div>
      </div>
      <Input
        type="text"
        placeholder="Name of app and how it would work"
        value={suggestion ?? ""}
        onChange={({ target }) => {
          setSuggestion(target.value);
        }}
        className={classNames(styles["app-input"], "mb-2")}
      />
      <div className="d-flex justify-content-end w-100 mt-1">
        <Button
          secondary
          className={styles["apps-button"]}
          disabled={loading}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};
