import axios, { AxiosResponse } from "axios";
import jwt from "jsonwebtoken";
import { CountryCode } from "libphonenumber-js";
import config from "../config";
import { Survey } from "../interfaces/survey";
import {
  OnboardingInfo,
  User,
  UserAuth,
  UserSettings
} from "../interfaces/user";
import { authQuery } from "../state/auth/auth.query";
import { authStore } from "../state/auth/auth.store";
import { handleError } from "./handle-error";
import { msToSec } from "../utils/time";

interface RefreshTokenResponse {
  message: string;
  data: {
    auth: {
      accessToken: string;
      expiresIn: number;
      tokenType: string;
    };
  };
}

export const isExpired = (token: string): boolean => {
  const decode = jwt.decode(token);

  if (!decode || typeof decode === "string" || !decode.exp) {
    return false;
  }

  const nowTimestamp = Math.floor(msToSec(new Date().getTime()));
  const expTimestamp = decode.exp;

  return nowTimestamp > expTimestamp;
};

export const getAccessToken = async (): Promise<string> => {
  let accessToken = authQuery.accessToken;

  if (!accessToken || isExpired(accessToken)) {
    accessToken = await refreshAccessToken();
  }

  return accessToken ?? "";
};

// API

const baseURL = `${config.apiUrl}/api/v1`;

export interface SignInResponse {
  data: {
    user: User;
    auth: UserAuth;
  };
}
export interface SignInParams {
  email: string;
  password: string;
  remember?: boolean;
  foundingMember?: boolean;
  invite?: string;
}
export const signIn = async (
  params: SignInParams
): Promise<AxiosResponse<SignInResponse>> => {
  return await axios.post<SignInResponse>(`${baseURL}/auth/signin`, params, {
    withCredentials: true
  });
};

export const triggerEmailVerification = async (
  userId: string
): Promise<AxiosResponse> => {
  return axios.put(`${baseURL}/auth/verify/email/${userId}`);
};

export const getCurrentUser = async (): Promise<void> => {
  const { data, status } = await axios.get<User>(`${baseURL}/auth/user`, {
    headers: { "x-access-token": await getAccessToken() }
  });

  if (status !== 200) {
    throw new Error(`Get user failed with status ${status}`);
  }

  authStore.setUser(data);
};

interface ProviderSignInParams {
  code: string;
  redirect_uri: string;
  state?: string | null;
  rewardfulReferral?: string;
  invite?: string;
}
export const providerSignIn = async ({
  code,
  redirect_uri,
  state,
  rewardfulReferral,
  invite
}: ProviderSignInParams): Promise<AxiosResponse<SignInResponse>> => {
  return await axios.post<SignInResponse>(
    `${baseURL}/auth/token`,
    {
      code,
      redirect_uri,
      state,
      rewardfulReferral,
      invite
    },
    {
      withCredentials: true
    }
  );
};

export interface SignUpParams {
  email: string;
  password: string;
  name: string;
  givenName?: string;
  familyName?: string;
  referralCode?: string;
  partner?: string;
  foundingMember?: boolean;
  state?: string;
  invite?: string;
  token?: string;
}

export type SignUpResponse = { data: string };

export const signUp = async (
  params: SignUpParams
): Promise<AxiosResponse<SignUpResponse>> => {
  return axios.post<SignUpResponse>(`${baseURL}/auth/signup`, params);
};

export interface VerifyUserParams {
  code: string;
  id: string;
  rewardfulReferral?: string;
  password?: string;
}
export const verifyUser = async ({
  code,
  id,
  rewardfulReferral,
  password
}: VerifyUserParams): Promise<AxiosResponse<unknown>> => {
  return await axios.post(`${baseURL}/auth/verify/${id}`, {
    verification_code: code,
    rewardfulReferral,
    password
  });
};

export interface VerifyPartnerUserParams {
  id: string;
  code: string;
  password: string;
}
export const verifyPartnerUser = async ({
  id,
  code,
  password
}: VerifyPartnerUserParams): Promise<AxiosResponse<SignInResponse>> => {
  return await axios.post<SignInResponse>(
    `${baseURL}/auth/verify/partner/${id}`,
    {
      verification_code: code,
      password
    }
  );
};

export const finishOnboarding = async (
  onboardingData: OnboardingInfo
): Promise<User | undefined> => {
  try {
    const { data } = await axios.post<User>(
      `${baseURL}/auth/onboarding`,
      onboardingData,
      {
        headers: { "x-access-token": await getAccessToken() }
      }
    );

    return data;
  } catch (e) {
    handleError(e);
  }
};

export const updateSettings = async (
  settings: Partial<UserSettings>,
  showError = true
): Promise<User | undefined> => {
  try {
    const { data } = await axios.put<User>(
      `${baseURL}/auth/settings`,
      settings,
      {
        headers: { "x-access-token": await getAccessToken() }
      }
    );

    authStore.setUser(data);

    return data;
  } catch (e) {
    if (showError) {
      handleError(e);
    }
  }
};

export interface ResetPasswordParams {
  id: string;
  code: string;
  password: string;
  passwordConfirm: string;
}
export const resetPassword = async (
  params: ResetPasswordParams
): Promise<AxiosResponse<unknown>> => {
  return await axios.post(`${baseURL}/auth/reset`, params);
};

export interface ChangePasswordParams {
  email: string;
  password: string;
}

export interface ForgotPasswordParams {
  email: string;
}
export const forgotPassword = async (
  params: ForgotPasswordParams
): Promise<AxiosResponse<unknown>> => {
  return axios.post(`${baseURL}/auth/forget-password`, params);
};

export const changePassword = async ({
  email,
  password
}: ChangePasswordParams): Promise<AxiosResponse<unknown>> => {
  return await axios.post(`${baseURL}/auth/change-password`, {
    email,
    password
  });
};
export const verificationResend = async (
  id: string
): Promise<AxiosResponse<unknown>> => {
  return await axios.get(`${baseURL}/auth/verify/resend/${id}`);
};

export const verifyEmailResend = async (): Promise<AxiosResponse<unknown>> => {
  return await axios.get(`${baseURL}/auth/verify-email/resend`, {
    baseURL,
    headers: {
      "x-access-token": await getAccessToken()
    }
  });
};

export const verifyEmailCode = async (
  code: string
): Promise<AxiosResponse<unknown>> => {
  return axios.post(
    `${baseURL}/auth/verify-email`,
    {
      verification_code: code
    },
    {
      baseURL,
      headers: {
        "x-access-token": await getAccessToken()
      }
    }
  );
};

export const refreshAccessToken = async (): Promise<string | undefined> => {
  const response = await axios.post<RefreshTokenResponse>(
    `${config.apiUrl}/api/v1/auth/refresh-token`,
    null,
    {
      withCredentials: true
    }
  );

  if (response.status !== 200) {
    throw new Error(`Refresh token failed with status ${response.status}`);
  }

  const accessToken = response.data?.data?.auth?.accessToken;
  authStore.setAccessToken(accessToken);

  return accessToken;
};

export const getUserLocation = async (): Promise<CountryCode | undefined> => {
  const userCountry = authQuery.user?.settings?.location?.country;

  if (userCountry) {
    return userCountry as CountryCode;
  }

  const { data } = await axios.get<{ country?: CountryCode }>(
    `${baseURL}/user/location`
  );

  return data?.country;
};

export const saveSurveyResult = async (surveyData: Survey): Promise<void> => {
  try {
    await axios.post(`${baseURL}/user/survey`, surveyData, {
      baseURL,
      headers: {
        "x-access-token": await getAccessToken()
      }
    });
  } catch (error) {
    handleError(error);
  }
};
