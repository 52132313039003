import { mdiTrashCan } from "@mdi/js";
import { format } from "date-fns";
import React from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import {
  deleteInvitation,
  resendInvitation,
  updateInvitationRole
} from "../../../api/invitation.service";
import { deleteMember, updateMemberRole } from "../../../api/members.service";
import upgradeImage from "../../../assets/images/members/upgrade.png";
import { IconButton } from "../../../components/icon-button/icon-button";
import { LoadingIcon } from "../../../components/loading-icon/loading-icon";
import { Table } from "../../../components/table/table";
import { useAccounts } from "../../../hooks/use-accounts";
import { usePermissions } from "../../../hooks/use-permissions";
import {
  AccountMember,
  Invitation,
  RoleName
} from "../../../interfaces/account";
import { SublyPlan } from "../../../interfaces/billing";
import { PLANS_PATH } from "../../../routes";
import { RoleNameDropdown } from "../../onboarding/forms/role-name-dropdown";

interface MembersTableProps {
  members?: AccountMember[];
  invitations?: Invitation[];
  isLoading?: boolean;
}
export const MembersTable: React.FC<MembersTableProps> = ({
  members = [],
  invitations = [],
  isLoading = true
}) => {
  const { hasPermission } = usePermissions(SublyPlan.Premium);

  if (isLoading) {
    return <LoadingMembersTable />;
  }

  const tableHeaders = ["Name", "Role", "Member Since", ""];

  if (!hasPermission) {
    return (
      <>
        <Table header={tableHeaders}></Table>
        <div className="d-flex flex-column align-items-center justify-content-center mt-5">
          <img src={upgradeImage} alt="Upgrade" className="mt-5" />
          <p className="text-text-primary mt-4 text-center">
            Teammates is a Subly Premium & Business feature.{" "}
            <Link to={PLANS_PATH} className="text-text-primary underline">
              Upgrade now
            </Link>{" "}
            to add teammates to your workspace!
          </p>
        </div>
      </>
    );
  }

  const renderMembers = members.map((m) => (
    <MembersTableRow key={m.id} member={m} />
  ));
  const renderInvitations = invitations.map((i) => (
    <InvitationTableRow invitation={i} key={i.id} />
  ));

  return (
    <Table header={tableHeaders}>
      <tbody>
        {renderMembers}
        {renderInvitations}
      </tbody>
    </Table>
  );
};

export const LoadingMembersTable: React.FC = () => {
  return (
    <Table>
      <tbody>
        <tr>
          <td>
            <Skeleton width="100%" />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton width="100%" />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton width="100%" />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton width="100%" />
          </td>
        </tr>
      </tbody>
    </Table>
  );
};
interface MembersTableRowProps {
  member: AccountMember;
}
const MembersTableRow: React.FC<MembersTableRowProps> = ({ member }) => {
  return (
    <tr>
      <td>
        <span className="font-weight-semibold text-text-primary">
          {member.name}
        </span>
        <br />
        <span className="font-size-inherit text-text-primary">
          {member.email}
        </span>
      </td>
      <td>
        <MemberRoleCell user={member} type="member" />
      </td>
      <td>{format(new Date(member.createdAt), "PPP")}</td>
      <td>
        <MemberActions user={member} type="member" />
      </td>
    </tr>
  );
};
interface InvitationTableRowProps {
  invitation: Invitation;
}
const InvitationTableRow: React.FC<InvitationTableRowProps> = ({
  invitation
}) => {
  return (
    <tr>
      <td>{invitation.email}</td>
      <td>
        <MemberRoleCell user={invitation} type="invitation" />
      </td>
      <td className="text-text-disabled">Invited</td>
      <td>
        <MemberActions user={invitation} type="invitation" />
      </td>
    </tr>
  );
};
interface MemberRoleCellProps {
  user: AccountMember | Invitation;
  type: "member" | "invitation";
}
const MemberRoleCell: React.FC<MemberRoleCellProps> = ({ user, type }) => {
  const [isLoading, setLoading] = React.useState(false);
  const { currentAccount, isAdmin } = useAccounts();

  const isMemberOwner = user.id === currentAccount?.owner;

  if (isMemberOwner || !isAdmin) {
    return <>{roleToText(user.role)}</>;
  }

  const onChange = async (role: RoleName) => {
    setLoading(true);

    if (type === "member") {
      await updateMemberRole(user.id, role);
    } else if (type === "invitation") {
      await updateInvitationRole(user.id, role);
    }

    setLoading(false);
  };

  if (isLoading) {
    return <LoadingIcon />;
  }

  return (
    <RoleNameDropdown
      value={user.role}
      onChange={onChange}
      isButtonSmall
      isAdmin={isAdmin}
    />
  );
};

const roleToText = (role: RoleName): string => {
  if (role === RoleName.FullAccess) {
    return "Admin";
  }

  return role;
};

interface MemberActionsProps {
  user: AccountMember | Invitation;
  type: "member" | "invitation";
}
const MemberActions: React.FC<MemberActionsProps> = ({ user, type }) => {
  const [isLoading, setLoading] = React.useState(false);
  const { isAdmin, currentAccount } = useAccounts();

  if (!isAdmin) {
    return null;
  }

  if (type === "invitation") {
    const handleResend = async () => {
      setLoading(true);

      await resendInvitation(user.id);

      setLoading(false);
    };

    return (
      <>
        {isLoading ? (
          <LoadingIcon />
        ) : (
          <Link
            to="#"
            className="text-text-primary font-weight-normal"
            onClick={handleResend}
          >
            Resend invite
          </Link>
        )}
        <span className="mx-2">|</span>
        <DeleteMember user={user} type={type} />
      </>
    );
  }

  // Don't allow to delete the owner of the account!
  if (currentAccount?.owner === user.id) {
    return null;
  }

  return <DeleteMember user={user} type={type} />;
};
const DeleteMember: React.FC<MemberActionsProps> = ({ user, type }) => {
  const [isLoading, setLoading] = React.useState(false);

  const handleDelete = async () => {
    setLoading(true);

    if (type === "member") {
      await deleteMember(user.id);
    } else if (type === "invitation") {
      await deleteInvitation(user.id);
    }

    setLoading(false);
  };

  if (isLoading) {
    return <LoadingIcon />;
  }

  return <IconButton path={mdiTrashCan} size="1.2rem" onClick={handleDelete} />;
};
