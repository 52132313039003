import {
  AspectRatio,
  hasErrors,
  MediaConfig,
  MediaSnippet,
  Transcription
} from "@getsubly/common";
import { useEvent, usePusher } from "@harelpls/use-pusher";
import { useObservable } from "@mindspace-io/react";
import React from "react";
import { loadMediaEditor } from "../api/media-editor.service";
import { PusherEvent } from "../components/pusher/pusher";
import config from "../config";
import { useDebounce } from "../hooks/use-debounce";
import { useUser } from "../hooks/use-user";
import {
  AccountTemplateStyles,
  ProgressBarStylesSettings
} from "../interfaces/account";
import { BasicMedia, MediaFileType } from "../interfaces/media";
import { TranscriptionMap } from "../interfaces/media-editor";
import { mediaQuery, mediaStore } from "../state/media";
import { mediaHistoryRepository } from "../state/media/media-history.state";
import { PresenceUser } from "../state/user-presence";
import {
  AudioTemplate,
  EditorToolbar,
  EditorView
} from "../types/media-editor.types";
import { getOriginalFile } from "../utils/media-functions";
import { useMediaPlayer } from "./media-player.context";
import { UndoRedoProvider } from "./undo-redo";

interface IMediaEditorContext {
  mediaId?: string;
  accountId?: string;
  media?: BasicMedia;
  mediaConfig?: MediaConfig;
  hasTranscriptionError: boolean;
  transcriptions?: TranscriptionMap;
  setTranscriptions: (transcriptionMap: TranscriptionMap) => void;
  setTranscriptionError: (hasTranscriptionError: boolean) => void;
  currentTranscription?: Transcription;
  currentTranscriptionId: string;
  activeUser?: PresenceUser;
  handleUpdateConfig: (media: MediaConfig) => void;
  handleUpdateStyles: (style: AccountTemplateStyles) => void;
  handleUpdateTranscription: (transcription: Transcription, id: string) => void;
  getTranscriptionLanguage: (transcriptionId: string) => string | undefined;
  translateAndReturnSubtitles: (
    targets: string[],
    mediaId: string
  ) => Promise<TranscriptionMap>;
  editorVisible: boolean;
  setEditorVisible: (editorVisible: boolean) => void;
  commentsVisible: boolean;
  setCommentsVisible: (commentsVisible: boolean) => void;
  snippetsVisible: boolean;
  setSnippetsVisible: (snippetsVisible: boolean) => void;
  editorToolbar?: EditorToolbar;
  setEditorToolbar: (editorToolbar?: EditorToolbar) => void;
  loaded: boolean;
  setLoaded: (loaded: boolean) => void;
  showTour: boolean;
  showSpeakersTour: boolean;
  publicShareId?: string;
  setPublicPreviewMode: (on: boolean) => void;
  publicPreviewMode: boolean;
  lastChange?: number;
  isAudio: boolean;
  canEdit: boolean;
  canComment: boolean;
  isOwner: boolean;
  isMp4: boolean;
  editorView: EditorView;
  setEditorView: (editorView: EditorView) => void;
  selectedNewSnippetId: string;
  setSelectedNewSnippetId: (id: string) => void;
  snippetEdit?: MediaSnippet;
  setSnippetEdit: (snippet?: MediaSnippet) => void;
  selectedExistingSnippetId?: string;
  setSelectedExistingSnippetId: (s: string | undefined) => void;
  editSnippet: (snippet: MediaSnippet) => void;
  audioTemplates: AudioTemplate[];
  progressBars: ProgressBarStylesSettings;
  refreshId: number;
  setRefreshId: React.Dispatch<React.SetStateAction<number>>;
  handleUndoRedo: (
    transcriptions?: TranscriptionMap,
    mediaConfig?: MediaConfig
  ) => void;
}

const MediaEditorContext = React.createContext<IMediaEditorContext>({
  hasTranscriptionError: false,
  currentTranscriptionId: "",
  setTranscriptions: () => null,
  setTranscriptionError: () => null,
  handleUpdateConfig: () => null,
  handleUpdateStyles: () => null,
  handleUpdateTranscription: () => null,
  getTranscriptionLanguage: () => undefined,
  translateAndReturnSubtitles: () => Promise.resolve({}),
  editorVisible: true,
  setEditorVisible: () => null,
  commentsVisible: false,
  setCommentsVisible: () => null,
  snippetsVisible: false,
  setSnippetsVisible: () => null,
  editorToolbar: EditorToolbar.Subtitles,
  setEditorToolbar: () => null,
  loaded: false,
  setLoaded: () => null,
  showTour: false,
  showSpeakersTour: false,
  setPublicPreviewMode: () => null,
  publicPreviewMode: false,
  isAudio: false,
  canEdit: false,
  canComment: false,
  isOwner: false,
  isMp4: false,
  editorView: EditorView.Editor,
  setEditorView: () => null,
  selectedNewSnippetId: "",
  setSelectedNewSnippetId: () => null,
  setSnippetEdit: () => null,
  setSelectedExistingSnippetId: () => null,
  editSnippet: () => null,
  audioTemplates: [],
  progressBars: {},
  refreshId: 0,
  setRefreshId: () => null,
  handleUndoRedo: () => null
});

interface IMediaEditorProviderProps {
  onUpdateTranscription: (id: string, mediaId: string) => void;
  onUpdateConfig: (mediaConfig: MediaConfig, mediaId: string) => void;
  onDebouncedSave: (
    transcriptions: TranscriptionMap,
    mediaConfig: MediaConfig,
    mediaId: string
  ) => Promise<void>;
  translateAndReturnSubtitles: (
    targets: string[],
    mediaId: string
  ) => Promise<TranscriptionMap>;
  media?: BasicMedia;
  mediaConfig?: MediaConfig;
  canEdit: boolean;
  canComment: boolean;
  isOwner: boolean;
  hasSeenMediaEditorTour: boolean;
  hasSeenSpeakersTour: boolean;
  audioTemplates: AudioTemplate[];
  progressBars: ProgressBarStylesSettings;
}

export const MediaEditorProvider: React.FC<IMediaEditorProviderProps> = ({
  onUpdateTranscription,
  onUpdateConfig,
  onDebouncedSave,
  media,
  mediaConfig,
  canEdit,
  canComment,
  isOwner,
  hasSeenMediaEditorTour,
  hasSeenSpeakersTour,
  translateAndReturnSubtitles,
  audioTemplates,
  progressBars,
  children
}) => {
  const { user } = useUser();
  const userId = user?.id;
  const mediaId = media?.mediaId;
  const accountId = media?.accountId;
  const [hasTranscriptionError, setTranscriptionError] = React.useState(false);
  const [transcriptions, setTranscriptions] =
    React.useState<TranscriptionMap>();

  const [editorVisible, setEditorVisible] = React.useState(true);
  const [commentsVisible, setCommentsVisible] = React.useState(false);
  const [snippetsVisible, setSnippetsVisible] = React.useState(false);
  const [currentTranscriptionId] = useObservable(
    mediaQuery.selectCurrentTranscriptionId(),
    ""
  );
  const [activeUser] = useObservable(mediaQuery.selectActiveUser());
  const [hasViewer] = useObservable(mediaQuery.selectHasViewer());
  const [editorToolbar, setEditorToolbar] = React.useState<EditorToolbar>();
  const [loaded, setLoaded] = React.useState(false);
  const [publicShareId] = useObservable(
    mediaQuery.selectActive((m) => m.publicShareId)
  );
  const [publicPreviewMode, setPublicPreviewMode] = React.useState(false);
  const [editorView, setEditorView] = React.useState(EditorView.Editor);
  const [selectedNewSnippetId, setSelectedNewSnippetId] = React.useState("");
  const [snippetEdit, setSnippetEdit] = React.useState<
    MediaSnippet | undefined
  >();
  const [selectedExistingSnippetId, setSelectedExistingSnippetId] =
    React.useState<string>();
  const [refreshId, setRefreshId] = React.useState(Math.random());
  const { pauseVideo } = useMediaPlayer();

  const currentTranscription = React.useMemo(() => {
    return transcriptions?.[currentTranscriptionId];
  }, [transcriptions, currentTranscriptionId]);

  const hideTour =
    hasSeenMediaEditorTour ||
    !Boolean(currentTranscription?.length) ||
    editorView === EditorView.Timeline;
  const showTour = !hideTour;

  const showSpeakersTour = !hasSeenSpeakersTour;

  // This workaround is used to debounce the auto saving. Any time there are changes
  // we change the lastChange state with a random number. This will call `useDebounce()`
  // and return a new value after the ms set. Then we use a useEffect that is called
  // every time the `debouncedTranscription` changes, and this will call the `saveSubtitles()`.
  const [lastChange, setLastChange] = React.useState(Math.random());
  const debouncedTranscription = useDebounce(lastChange, 3000);
  const { client: pusherClient } = usePusher();

  const presenceAccountChannel = pusherClient?.channel(`presence-${accountId}`);

  useEvent(
    presenceAccountChannel,
    PusherEvent.HasSaved,
    async (data?: { mediaId: string }) => {
      const handleLoadMediaEditor = async (mediaId: string) => {
        try {
          const { assConfig, transcriptions } = await loadMediaEditor(mediaId);

          const hasError = Object.values(transcriptions).some((t) =>
            hasErrors(t)
          );
          setTranscriptionError(hasError);
          setTranscriptions(transcriptions);

          mediaHistoryRepository.initState(transcriptions, assConfig);

          setLoaded(true);
        } catch (error) {
          console.error("Error loading media:", error);
        }
      };

      if (mediaId && data?.mediaId === mediaId) {
        handleLoadMediaEditor(mediaId);
      }
    }
  );

  React.useEffect(() => {
    const autoSaveSubtitles = async () => {
      if (!transcriptions || !mediaConfig || !media?.mediaId) {
        return;
      }

      const hasError = Object.values(transcriptions).some((t) => hasErrors(t));
      setTranscriptionError(hasError);

      await onDebouncedSave(transcriptions, mediaConfig, media.mediaId);

      if (config.features.hasUserPresence) {
        const presenceAccountChannel = pusherClient?.channel(
          `presence-${accountId}`
        );
        if (presenceAccountChannel && hasViewer) {
          presenceAccountChannel.trigger(PusherEvent.HasSaved, {
            userId,
            mediaId
          });
        }
      }
    };

    autoSaveSubtitles();

    // We just want to run this hook when debounce hook is called
    // eslint-disable-next-line
  }, [debouncedTranscription]);

  React.useEffect(() => {
    if (!media || !mediaConfig) {
      return;
    }

    const isAudio = media.type === MediaFileType.Audio;
    const hasArtwork = mediaConfig?.artwork?.id;

    if (isAudio && !hasArtwork) {
      setEditorToolbar(EditorToolbar.Elements);
    } else {
      setEditorToolbar(EditorToolbar.Subtitles);
    }
  }, [loaded]);

  const handleUpdateStyles = (styles: AccountTemplateStyles): void => {
    if (!mediaConfig) {
      return;
    }

    const currentRatio = styles.aspectRatio?.ratio ?? AspectRatio.Original;

    const ratioStyles = {
      ...mediaConfig.ratioStyles,
      [currentRatio]: styles
    };

    handleUpdateConfig({ ...mediaConfig, ratioStyles });
  };

  const handleUpdateConfig = (mediaConfig: MediaConfig): void => {
    if (!media?.mediaId) {
      return;
    }

    onUpdateConfig(mediaConfig, media.mediaId);

    mediaHistoryRepository.configUpdates$.next(mediaConfig);

    setLastChange(Math.random());
  };

  const handleUpdateTranscription = (
    transcription: Transcription,
    id: string
  ): void => {
    if (!media?.mediaId || !transcriptions) {
      return;
    }

    // Pause video when update subtitles
    pauseVideo();

    onUpdateTranscription(id, media.mediaId);

    const updatedTranscriptions = { ...transcriptions, [id]: transcription };

    setTranscriptions(updatedTranscriptions);

    mediaHistoryRepository.transcriptionUpdates$.next(updatedTranscriptions);

    setLastChange(Math.random());
  };

  const handleUndoRedo = (
    transcriptions?: TranscriptionMap,
    mediaConfig?: MediaConfig
  ) => {
    if (!media?.mediaId || !transcriptions || !mediaConfig) {
      return;
    }

    Object.keys(transcriptions).forEach((id) =>
      onUpdateTranscription(id, media.mediaId)
    );
    onUpdateConfig(mediaConfig, media.mediaId);

    setTranscriptions(transcriptions);

    // Add back snippets to the mediaConfig from media history
    const assConfig: MediaConfig = {
      ...mediaConfig,
      snippets: media.assConfig.snippets
    };
    mediaStore.updateActive({ assConfig });

    setLastChange(Math.random());
  };

  const getTranscriptionLanguage = (
    transcriptionId: string
  ): string | undefined => {
    return transcriptionId === media?.transcriptions.originalId
      ? //it's the original transcription
        media.transcriptions.original?.code
      : //it's a translation
        undefined;
  };

  const editSnippet = (snippet: MediaSnippet): void => {
    if (!media?.mediaId) {
      return;
    }

    setEditorView(EditorView.Timeline);
    setSelectedNewSnippetId(snippet.id);
    setSnippetEdit(snippet);

    if (snippet.transcriptionId) {
      mediaStore.updateCurrentTranscriptionId(snippet.transcriptionId);
    }
    mediaStore.loadSnippet(media.mediaId, snippet);
  };

  const isAudio = media?.type === MediaFileType.Audio;
  const isMp4 = Boolean(media && getOriginalFile(media)?.extension === "mp4");

  return (
    <MediaEditorContext.Provider
      value={{
        mediaId,
        accountId,
        media,
        mediaConfig,
        hasTranscriptionError,
        setTranscriptionError,
        transcriptions,
        setTranscriptions,
        currentTranscriptionId,
        activeUser,
        handleUpdateConfig,
        handleUpdateStyles,
        handleUpdateTranscription,
        getTranscriptionLanguage,
        translateAndReturnSubtitles,
        editorVisible,
        setEditorVisible,
        commentsVisible,
        setCommentsVisible,
        snippetsVisible,
        setSnippetsVisible,
        editorToolbar,
        setEditorToolbar,
        loaded,
        setLoaded,
        showTour,
        showSpeakersTour,
        publicShareId,
        setPublicPreviewMode,
        publicPreviewMode,
        lastChange,
        isAudio,
        canEdit,
        canComment,
        isOwner,
        isMp4,
        editorView,
        setEditorView,
        selectedNewSnippetId,
        setSelectedNewSnippetId,
        snippetEdit,
        setSnippetEdit,
        selectedExistingSnippetId,
        setSelectedExistingSnippetId,
        editSnippet,
        audioTemplates,
        progressBars,
        refreshId,
        setRefreshId,
        handleUndoRedo
      }}
    >
      <UndoRedoProvider>{children}</UndoRedoProvider>
    </MediaEditorContext.Provider>
  );
};

export const useMediaEditor = (): IMediaEditorContext => {
  return React.useContext(MediaEditorContext);
};
