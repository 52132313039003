import { mdiArrowLeft } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { getCustomer } from "../../../api/billing.service";
import { AddBillingDetailsForm } from "../../../containers/settings/billing-details-card/add-billing-details-form";
import { AuthContext } from "../../../contexts/auth.context";
import { SettingsLayout } from "../../../layouts/settings/settings.layout";
import { BILLING_PATH } from "../../../routes";
import { accountQuery } from "../../../state/account";

export const AddBillingDetailsPage: React.FC = () => {
  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (!user) {
      return;
    }

    getCustomer({ force: false });
  }, [user]);

  if (!user) {
    return null;
  }

  const hasBillingDetails = accountQuery.hasBillingDetails;
  const title = hasBillingDetails
    ? "Edit billing details"
    : "Add billing details";

  return (
    <SettingsLayout>
      <div className="container-fluid my-2">
        <div className="row">
          <div className="col mt-3 mb-2">
            <h4 className="title text-black d-flex align-items-center">
              <Link to={BILLING_PATH}>
                <Icon
                  path={mdiArrowLeft}
                  size="25px"
                  color="black"
                  className="mr-3"
                />
              </Link>{" "}
              {title}
            </h4>
          </div>
        </div>
        <div className="row">
          <div className="col py-3">
            <AddBillingDetailsForm />
          </div>
        </div>
      </div>
    </SettingsLayout>
  );
};
