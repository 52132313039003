// TODO: Fix eslint warnings
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React from "react";
import loadScript from "load-script";
import {
  GoogleDriveAuthInfo,
  GoogleDrivePickerEvent,
  GooglePickerError
} from "../../interfaces/google-picker";

const GOOGLE_SDK_URL = "https://apis.google.com/js/api.js";

let scriptLoadingStarted = false;

interface GoogleChooserProps {
  createPicker?: (google: any, auth: GoogleDriveAuthInfo) => void;
  onChange?: (e: GoogleDrivePickerEvent) => void;
  onAuthenticate: (auth: GoogleDriveAuthInfo) => void;
  onAuthFailed: (response: GooglePickerError) => void;
  scope: string[];
  mimeTypes?: string[];
  query?: string;
  clientId: string | undefined;
  developerKey: string | undefined;
  origin?: string;
  viewId?: string;
  authImmediate: boolean;
  multiselect: boolean;
  navHidden: boolean;
  disabled?: boolean;
}

export class GoogleChooser extends React.Component<GoogleChooserProps> {
  constructor(props: GoogleChooserProps) {
    super(props);

    this.onApiLoad = this.onApiLoad.bind(this);
    this.onChoose = this.onChoose.bind(this);
  }

  componentDidMount() {
    if (this.isGoogleReady()) {
      // google api is already exists
      // init immediately
      this.onApiLoad();
    } else if (!scriptLoadingStarted) {
      // load google api and the init
      scriptLoadingStarted = true;
      loadScript(GOOGLE_SDK_URL, this.onApiLoad);
    } else {
      // is loading
    }
  }

  isGoogleReady = (): boolean => !!(window as any)?.gapi;
  isGoogleAuthReady = (): boolean =>
    !!(window as any)?.gapi.auth && !!(window as any)?.gapi.auth2;
  isGooglePickerReady = (): boolean => !!(window as any)?.google.picker;

  onApiLoad = (): void => {
    (window as any)?.gapi.load("auth");
    (window as any)?.gapi.load("picker");
  };

  onChoose = () => {
    if (
      !this.isGoogleReady() ||
      !this.isGoogleAuthReady() ||
      !this.isGooglePickerReady() ||
      this.props.disabled
    ) {
      return null;
    }

    const gapi = (window as any)?.gapi;

    gapi.auth2
      .init({
        client_id: this.props.clientId
      })
      .then((auth: any) => {
        const token = gapi.auth.getToken();

        if (token) {
          auth
            .grantOfflineAccess()
            .then(({ code }: { code: string }) => {
              if (token.access_token && code) {
                this.createPicker({
                  accessToken: token.access_token,
                  authorizationCode: code
                });
              }
            })
            .catch((err: GooglePickerError) => this.props.onAuthFailed(err));
        } else {
          gapi.auth.authorize(
            {
              client_id: this.props.clientId,
              scope: this.props.scope,
              immediate: this.props.authImmediate
            },
            (response: any) => {
              if (!response?.access_token) {
                this.props.onAuthFailed(response);
              }

              auth
                .grantOfflineAccess()
                .then(({ code }: { code: string }) => {
                  if (response.access_token && code) {
                    this.createPicker({
                      accessToken: response.access_token,
                      authorizationCode: code
                    });
                  }
                })
                .catch((err: GooglePickerError) => {
                  this.props.onAuthFailed(err);
                });
            }
          );
        }
      })
      .catch((err: GooglePickerError) => {
        this.props.onAuthFailed(err);
      });
  };

  createPicker(auth: GoogleDriveAuthInfo) {
    this.props.onAuthenticate(auth);

    const google: any = (window as any)?.google;

    if (this.props.createPicker) {
      return this.props.createPicker(google, auth);
    }

    if (!this.props.viewId) return;

    const googleViewId = google.picker.ViewId[this.props.viewId];
    const view = new google.picker.View(googleViewId);

    if (this.props.mimeTypes) {
      view.setMimeTypes(this.props.mimeTypes.join(","));
    }
    if (this.props.query) {
      view.setQuery(this.props.query);
    }

    if (!view) {
      throw new Error("Can't find view by viewId");
    }

    const picker = new google.picker.PickerBuilder()
      .addView(view)
      .setOAuthToken(auth.accessToken)
      .setDeveloperKey(this.props.developerKey)
      .setCallback(this.props.onChange);

    if (this.props.origin) {
      picker.setOrigin(this.props.origin);
    }

    if (this.props.navHidden) {
      picker.enableFeature(google.picker.Feature.NAV_HIDDEN);
    }

    if (this.props.multiselect) {
      picker.enableFeature(google.picker.Feature.MULTISELECT_ENABLED);
    }

    picker.build().setVisible(true);
  }

  render() {
    return (
      <div onClick={this.onChoose}>
        {this.props.children ? (
          this.props.children
        ) : (
          <button>Open google chooser</button>
        )}
      </div>
    );
  }
}
