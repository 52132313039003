import React from "react";
import { Navigate } from "react-router";
import { useQuery } from "../hooks/use-query";
import { OAUTH_CALLBACK_PATH } from "../routes";
import { decodeQueryState } from "../utils/links";

interface Params {
  code: string;
  state?: string;
  error_description?: string;
}
export const OauthProcessPage: React.FC = () => {
  const { code, state: queryState, error_description } = useQuery<Params>();
  const { state, gclid } = decodeQueryState(queryState);

  const searchParams = new URLSearchParams();
  searchParams.append("code", code);

  if (error_description) {
    searchParams.append("error_description", error_description);
  }

  if (state) {
    searchParams.append("state", state);
  }

  if (gclid) {
    searchParams.append("gclid", gclid);
  }

  const search = searchParams.toString();

  return <Navigate to={{ pathname: OAUTH_CALLBACK_PATH, search }} />;
};
