import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { makeShareUrl } from "../../utils/links";
import config from "../../config";

export const PreviewVideoPage: React.FC = () => {
  const { publicShareId = "" } = useParams();

  useEffect(() => {
    window.location.href = makeShareUrl({
      previewBaseUrl: config.previewBaseUrl,
      publicShareId
    });
  }, [publicShareId]);

  return null;
};
