import React from "react";
import { SublyPlan } from "../../interfaces/billing";
import { LockFeature } from "./lock-feature";

interface LockFeaturePremiumProps {
  mixpanelEvent?: string;
  minPermission?: SublyPlan;
}
export const LockFeaturePremium: React.FC<LockFeaturePremiumProps> = ({
  mixpanelEvent,
  minPermission = SublyPlan.Premium,
  children
}) => {
  return (
    <LockFeature mixpanelEvent={mixpanelEvent} minPermission={minPermission}>
      {children}
    </LockFeature>
  );
};
