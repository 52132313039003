import { useCookieConsents } from "@enzsft/react-cookie-consents";
import React from "react";
import { Button } from "../button/button";
import { PRIVACY_URL } from "../../routes";
import styles from "./cookie-banner.module.scss";

export const CookieBanner: React.FC = () => {
  const cookieConsents = useCookieConsents();

  if (!cookieConsents || cookieConsents.get().length > 0) {
    return null;
  }

  return (
    <div className={styles["cookies-consent"]}>
      <p>
        This site uses cookies to store information on your computer. Some are
        essential to make our site work; others help us improve the user
        experience. By using the site, you consent to the placement of these
        cookies.
      </p>
      <div className={styles["actions"]}>
        <Button
          primary
          type="submit"
          onClick={(e) => {
            e.stopPropagation();
            cookieConsents.add("analytics");
          }}
        >
          I'm all right with it
        </Button>

        <p className="m-0">
          Read our{" "}
          <a href={PRIVACY_URL} target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a>
        </p>
      </div>
    </div>
  );
};
