import { Cue, MediaSpeaker } from "@getsubly/common";
import React from "react";

interface SpeakerRecognitionContext {
  currentSpeaker?: MediaSpeaker;
  setCurrentSpeaker: React.Dispatch<
    React.SetStateAction<MediaSpeaker | undefined>
  >;
  speakerChosenReload: number;
  setSpeakerChosenReload: React.Dispatch<React.SetStateAction<number>>;
  speakerDropdownVisible: boolean;
  setSpeakerDropdownVisible: React.Dispatch<React.SetStateAction<boolean>>;
  speakerModalToggle: boolean;
  setSpeakerModalToggle: React.Dispatch<React.SetStateAction<boolean>>;
  selectedCue: Cue | undefined;
  setSelectedCue: React.Dispatch<React.SetStateAction<Cue | undefined>>;
}

export const SpeakerRecognitionContext =
  React.createContext<SpeakerRecognitionContext>({
    currentSpeaker: undefined,
    setCurrentSpeaker: () => null,
    speakerChosenReload: 0,
    setSpeakerChosenReload: () => null,
    speakerDropdownVisible: false,
    setSpeakerDropdownVisible: () => null,
    speakerModalToggle: false,
    setSpeakerModalToggle: () => null,
    selectedCue: undefined,
    setSelectedCue: () => null
  });

export const SpeakerRecognitionProvider: React.FC = ({ children }) => {
  const [currentSpeaker, setCurrentSpeaker] = React.useState<MediaSpeaker>();
  const [speakerChosenReload, setSpeakerChosenReload] =
    React.useState<number>(0);
  const [speakerDropdownVisible, setSpeakerDropdownVisible] =
    React.useState(false);
  const [speakerModalToggle, setSpeakerModalToggle] = React.useState(false);
  const [selectedCue, setSelectedCue] = React.useState<Cue>();

  return (
    <SpeakerRecognitionContext.Provider
      value={{
        currentSpeaker,
        setCurrentSpeaker,
        speakerChosenReload,
        setSpeakerChosenReload,
        speakerDropdownVisible,
        setSpeakerDropdownVisible,
        speakerModalToggle,
        setSpeakerModalToggle,
        selectedCue,
        setSelectedCue
      }}
    >
      {children}
    </SpeakerRecognitionContext.Provider>
  );
};

export const useSpeakerRecognition = (): SpeakerRecognitionContext => {
  return React.useContext(SpeakerRecognitionContext);
};
