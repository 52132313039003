import { useUser } from "./use-user";

type Data = { [key: string]: unknown };
type UseDataLayer = (event: string, data?: Data) => void;

export const useDataLayer = (): UseDataLayer => {
  const { user } = useUser();

  const sendEvent = (event: string, data?: Data) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dataLayer = (window as any)?.dataLayer;

    if (dataLayer == null) {
      return;
    }

    if (user?.id) {
      data = { ...data, userId: user.id };
    }

    dataLayer.push({ event, ...data });
  };

  return sendEvent;
};
