import classNames from "classnames";
import React from "react";

interface FieldsetProps {
  title?: string | JSX.Element;
  helper?: string | JSX.Element;
  className?: string;
}
export const Fieldset: React.FC<FieldsetProps> = ({
  title,
  helper,
  children,
  className
}) => {
  return (
    <div className={classNames("form-group", className)}>
      {title && <h6 className={classNames({ "mb-0": helper })}>{title}</h6>}
      {helper && <p className="caption mb-2">{helper}</p>}

      {children}
    </div>
  );
};
