import React, { useEffect, useState } from "react";
import { Navigate } from "react-router";
import { mdiLoading } from "@mdi/js";
import Icon from "@mdi/react";
import { useQuery } from "../../hooks/use-query";
import { authorizeZoom } from "../../api/apps.service";
import { SettingsLayout } from "../../layouts/settings/settings.layout";
import { APPS_HOME_PATH } from "../../routes";

interface Params {
  code: string;
  state?: string;
  error_description?: string;
}

export const ZoomProcessPage: React.FC = () => {
  const { code } = useQuery<Params>();
  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    const getAuth = async () => {
      if (!code) {
        return;
      }

      // Send code to backend
      await authorizeZoom(code);

      // TODO: Handle any error here
      setIsAuthorized(true);
    };

    getAuth();
  }, [code]);

  if (isAuthorized) {
    const pathname = `${APPS_HOME_PATH}/zoom`;

    return <Navigate to={{ pathname }} />;
  }

  return (
    <SettingsLayout>
      <p className="text-center">
        <Icon path={mdiLoading} spin size="1.2rem" className="mr-2" /> Please
        wait a moment...
      </p>
    </SettingsLayout>
  );
};
