import React from "react";
import { Button } from "../../../components/button/button";
import { deleteFolder } from "../../../state/folders/folders.service";
import { Folder } from "../../../state/folders/folders.store";
import { Modal } from "../../modal/modal";

interface DeleteFolderModalProps {
  folder: Folder;
  close: () => void;
  folderHasFilesCreatedByAnother: boolean;
}
export const DeleteFolderModal: React.FC<DeleteFolderModalProps> = ({
  folder,
  close,
  folderHasFilesCreatedByAnother
}) => {
  return (
    <Modal onDismiss={close}>
      <DeleteFolderModalContainer
        close={close}
        folder={folder}
        folderHasFilesCreatedByAnother={folderHasFilesCreatedByAnother}
      />
    </Modal>
  );
};

export const DeleteFolderModalContainer: React.FC<DeleteFolderModalProps> = ({
  close,
  folder,
  folderHasFilesCreatedByAnother
}) => {
  const [loading, setLoading] = React.useState(false);

  const handleDelete = async () => {
    try {
      setLoading(true);
      await deleteFolder(folder.id);
      close();
    } catch (e) {
      close();
      setLoading(false);
    }
  };

  const warningMessage = folderHasFilesCreatedByAnother
    ? "This action will permantly delete all media files in this folder - these include files created by your teammates."
    : "This action will permantly delete all media files in this folder.";

  return (
    <>
      <h5>Are you sure you want to delete {folder.name}?</h5>

      <p className="warning-message">{warningMessage}</p>
      <div className="d-flex">
        <Button secondary onClick={close} className="ml-auto mr-2">
          Cancel
        </Button>
        <Button danger onClick={handleDelete} loading={loading}>
          Delete folder
        </Button>
      </div>
    </>
  );
};
