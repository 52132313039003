import React from "react";
import { Modal } from "../../../../containers/modal/modal";
import { ReferralPresent } from "../../../../components/icons/icons";
import styles from "./referral-sent-modal.module.scss";

interface ReferralSentModalProps {
  number: number;
  closeModal: () => void;
}

export const ReferralSentModal: React.FC<ReferralSentModalProps> = ({
  number,
  closeModal
}) => {
  return (
    <Modal onDismiss={closeModal} className={styles["modal"]}>
      <div className="d-flex">
        <h5 className="mt-2">
          {number > 1 ? `${number} invites sent!` : `Invite sent!`}
        </h5>
        <div className="ml-auto mr-2">
          <ReferralPresent />
        </div>
      </div>
    </Modal>
  );
};
