import React from "react";

export const GreenDotsIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    width="191"
    height="86"
    viewBox="0 0 191 86"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.55506 24.2937C7.07075 24.2937 9.11012 22.2543 9.11012 19.7387C9.11012 17.223 7.07075 15.1836 4.55506 15.1836C2.03937 15.1836 0 17.223 0 19.7387C0 22.2543 2.03937 24.2937 4.55506 24.2937Z"
      fill="#39EFA5"
    />
    <path
      d="M19.7387 9.11012C22.2543 9.11012 24.2937 7.07075 24.2937 4.55506C24.2937 2.03937 22.2543 0 19.7387 0C17.223 0 15.1836 2.03937 15.1836 4.55506C15.1836 7.07075 17.223 9.11012 19.7387 9.11012Z"
      fill="#39EFA5"
    />
    <path
      d="M34.9222 24.2937C37.4379 24.2937 39.4773 22.2543 39.4773 19.7387C39.4773 17.223 37.4379 15.1836 34.9222 15.1836C32.4066 15.1836 30.3672 17.223 30.3672 19.7387C30.3672 22.2543 32.4066 24.2937 34.9222 24.2937Z"
      fill="#39EFA5"
    />
    <path
      d="M4.55506 54.6607C7.07075 54.6607 9.11012 52.6213 9.11012 50.1056C9.11012 47.5899 7.07075 45.5505 4.55506 45.5505C2.03937 45.5505 0 47.5899 0 50.1056C0 52.6213 2.03937 54.6607 4.55506 54.6607Z"
      fill="#39EFA5"
    />
    <path
      d="M19.7387 39.4771C22.2543 39.4771 24.2937 37.4377 24.2937 34.922C24.2937 32.4063 22.2543 30.3669 19.7387 30.3669C17.223 30.3669 15.1836 32.4063 15.1836 34.922C15.1836 37.4377 17.223 39.4771 19.7387 39.4771Z"
      fill="#39EFA5"
    />
    <path
      d="M34.9222 54.6607C37.4379 54.6607 39.4773 52.6213 39.4773 50.1056C39.4773 47.5899 37.4379 45.5505 34.9222 45.5505C32.4066 45.5505 30.3672 47.5899 30.3672 50.1056C30.3672 52.6213 32.4066 54.6607 34.9222 54.6607Z"
      fill="#39EFA5"
    />
    <path
      d="M4.55506 85.0278C7.07075 85.0278 9.11012 82.9885 9.11012 80.4728C9.11012 77.9571 7.07075 75.9177 4.55506 75.9177C2.03937 75.9177 0 77.9571 0 80.4728C0 82.9885 2.03937 85.0278 4.55506 85.0278Z"
      fill="#39EFA5"
    />
    <path
      d="M19.7387 69.8443C22.2543 69.8443 24.2937 67.8049 24.2937 65.2892C24.2937 62.7735 22.2543 60.7341 19.7387 60.7341C17.223 60.7341 15.1836 62.7735 15.1836 65.2892C15.1836 67.8049 17.223 69.8443 19.7387 69.8443Z"
      fill="#39EFA5"
    />
    <path
      d="M34.9222 85.0278C37.4379 85.0278 39.4773 82.9885 39.4773 80.4728C39.4773 77.9571 37.4379 75.9177 34.9222 75.9177C32.4066 75.9177 30.3672 77.9571 30.3672 80.4728C30.3672 82.9885 32.4066 85.0278 34.9222 85.0278Z"
      fill="#39EFA5"
    />
    <path
      d="M50.1058 9.11012C52.6215 9.11012 54.6609 7.07075 54.6609 4.55506C54.6609 2.03937 52.6215 0 50.1058 0C47.5902 0 45.5508 2.03937 45.5508 4.55506C45.5508 7.07075 47.5902 9.11012 50.1058 9.11012Z"
      fill="#39EFA5"
    />
    <path
      d="M65.2894 24.2937C67.8051 24.2937 69.8445 22.2543 69.8445 19.7387C69.8445 17.223 67.8051 15.1836 65.2894 15.1836C62.7737 15.1836 60.7344 17.223 60.7344 19.7387C60.7344 22.2543 62.7737 24.2937 65.2894 24.2937Z"
      fill="#39EFA5"
    />
    <path
      d="M80.473 9.11012C82.9887 9.11012 85.0281 7.07075 85.0281 4.55506C85.0281 2.03937 82.9887 0 80.473 0C77.9573 0 75.918 2.03937 75.918 4.55506C75.918 7.07075 77.9573 9.11012 80.473 9.11012Z"
      fill="#39EFA5"
    />
    <path
      d="M95.6566 24.2937C98.1723 24.2937 100.212 22.2543 100.212 19.7387C100.212 17.223 98.1723 15.1836 95.6566 15.1836C93.1409 15.1836 91.1016 17.223 91.1016 19.7387C91.1016 22.2543 93.1409 24.2937 95.6566 24.2937Z"
      fill="#39EFA5"
    />
    <path
      d="M110.84 9.11012C113.356 9.11012 115.395 7.07075 115.395 4.55506C115.395 2.03937 113.356 0 110.84 0C108.325 0 106.285 2.03937 106.285 4.55506C106.285 7.07075 108.325 9.11012 110.84 9.11012Z"
      fill="#39EFA5"
    />
    <path
      d="M126.023 24.2937C128.539 24.2937 130.578 22.2543 130.578 19.7387C130.578 17.223 128.539 15.1836 126.023 15.1836C123.507 15.1836 121.468 17.223 121.468 19.7387C121.468 22.2543 123.507 24.2937 126.023 24.2937Z"
      fill="#39EFA5"
    />
    <path
      d="M141.207 9.11012C143.723 9.11012 145.762 7.07075 145.762 4.55506C145.762 2.03937 143.723 0 141.207 0C138.692 0 136.652 2.03937 136.652 4.55506C136.652 7.07075 138.692 9.11012 141.207 9.11012Z"
      fill="#39EFA5"
    />
    <path
      d="M156.39 24.2937C158.906 24.2937 160.945 22.2543 160.945 19.7387C160.945 17.223 158.906 15.1836 156.39 15.1836C153.874 15.1836 151.835 17.223 151.835 19.7387C151.835 22.2543 153.874 24.2937 156.39 24.2937Z"
      fill="#39EFA5"
    />
    <path
      d="M186.445 24.2937C188.96 24.2937 191 22.2543 191 19.7387C191 17.223 188.96 15.1836 186.445 15.1836C183.929 15.1836 181.89 17.223 181.89 19.7387C181.89 22.2543 183.929 24.2937 186.445 24.2937Z"
      fill="#39EFA5"
    />
    <path
      d="M171.574 9.11012C174.089 9.11012 176.129 7.07075 176.129 4.55506C176.129 2.03937 174.089 0 171.574 0C169.058 0 167.019 2.03937 167.019 4.55506C167.019 7.07075 169.058 9.11012 171.574 9.11012Z"
      fill="#39EFA5"
    />
    <path
      d="M50.1058 39.4771C52.6215 39.4771 54.6609 37.4377 54.6609 34.922C54.6609 32.4063 52.6215 30.3669 50.1058 30.3669C47.5902 30.3669 45.5508 32.4063 45.5508 34.922C45.5508 37.4377 47.5902 39.4771 50.1058 39.4771Z"
      fill="#39EFA5"
    />
    <path
      d="M65.2894 54.6607C67.8051 54.6607 69.8445 52.6213 69.8445 50.1056C69.8445 47.5899 67.8051 45.5505 65.2894 45.5505C62.7737 45.5505 60.7344 47.5899 60.7344 50.1056C60.7344 52.6213 62.7737 54.6607 65.2894 54.6607Z"
      fill="#39EFA5"
    />
    <path
      d="M80.473 39.4771C82.9887 39.4771 85.0281 37.4377 85.0281 34.922C85.0281 32.4063 82.9887 30.3669 80.473 30.3669C77.9573 30.3669 75.918 32.4063 75.918 34.922C75.918 37.4377 77.9573 39.4771 80.473 39.4771Z"
      fill="#39EFA5"
    />
    <path
      d="M95.6566 54.6607C98.1723 54.6607 100.212 52.6213 100.212 50.1056C100.212 47.5899 98.1723 45.5505 95.6566 45.5505C93.1409 45.5505 91.1016 47.5899 91.1016 50.1056C91.1016 52.6213 93.1409 54.6607 95.6566 54.6607Z"
      fill="#39EFA5"
    />
    <path
      d="M110.84 39.4771C113.356 39.4771 115.395 37.4377 115.395 34.922C115.395 32.4063 113.356 30.3669 110.84 30.3669C108.325 30.3669 106.285 32.4063 106.285 34.922C106.285 37.4377 108.325 39.4771 110.84 39.4771Z"
      fill="#39EFA5"
    />
    <path
      d="M126.023 54.6607C128.539 54.6607 130.578 52.6213 130.578 50.1056C130.578 47.5899 128.539 45.5505 126.023 45.5505C123.507 45.5505 121.468 47.5899 121.468 50.1056C121.468 52.6213 123.507 54.6607 126.023 54.6607Z"
      fill="#39EFA5"
    />
    <path
      d="M141.207 39.4771C143.723 39.4771 145.762 37.4377 145.762 34.922C145.762 32.4063 143.723 30.3669 141.207 30.3669C138.692 30.3669 136.652 32.4063 136.652 34.922C136.652 37.4377 138.692 39.4771 141.207 39.4771Z"
      fill="#39EFA5"
    />
    <path
      d="M156.39 54.6607C158.906 54.6607 160.945 52.6213 160.945 50.1056C160.945 47.5899 158.906 45.5505 156.39 45.5505C153.874 45.5505 151.835 47.5899 151.835 50.1056C151.835 52.6213 153.874 54.6607 156.39 54.6607Z"
      fill="#39EFA5"
    />
    <path
      d="M171.574 39.4771C174.089 39.4771 176.129 37.4377 176.129 34.922C176.129 32.4063 174.089 30.3669 171.574 30.3669C169.058 30.3669 167.019 32.4063 167.019 34.922C167.019 37.4377 169.058 39.4771 171.574 39.4771Z"
      fill="#39EFA5"
    />
    <path
      d="M50.1058 69.8443C52.6215 69.8443 54.6609 67.8049 54.6609 65.2892C54.6609 62.7735 52.6215 60.7341 50.1058 60.7341C47.5902 60.7341 45.5508 62.7735 45.5508 65.2892C45.5508 67.8049 47.5902 69.8443 50.1058 69.8443Z"
      fill="#39EFA5"
    />
    <path
      d="M65.2894 85.0278C67.8051 85.0278 69.8445 82.9885 69.8445 80.4728C69.8445 77.9571 67.8051 75.9177 65.2894 75.9177C62.7737 75.9177 60.7344 77.9571 60.7344 80.4728C60.7344 82.9885 62.7737 85.0278 65.2894 85.0278Z"
      fill="#39EFA5"
    />
    <path
      d="M80.473 69.8443C82.9887 69.8443 85.0281 67.8049 85.0281 65.2892C85.0281 62.7735 82.9887 60.7341 80.473 60.7341C77.9573 60.7341 75.918 62.7735 75.918 65.2892C75.918 67.8049 77.9573 69.8443 80.473 69.8443Z"
      fill="#39EFA5"
    />
    <path
      d="M95.6566 85.0278C98.1723 85.0278 100.212 82.9885 100.212 80.4728C100.212 77.9571 98.1723 75.9177 95.6566 75.9177C93.1409 75.9177 91.1016 77.9571 91.1016 80.4728C91.1016 82.9885 93.1409 85.0278 95.6566 85.0278Z"
      fill="#39EFA5"
    />
    <path
      d="M110.84 69.8443C113.356 69.8443 115.395 67.8049 115.395 65.2892C115.395 62.7735 113.356 60.7341 110.84 60.7341C108.325 60.7341 106.285 62.7735 106.285 65.2892C106.285 67.8049 108.325 69.8443 110.84 69.8443Z"
      fill="#39EFA5"
    />
    <path
      d="M126.023 85.0278C128.539 85.0278 130.578 82.9885 130.578 80.4728C130.578 77.9571 128.539 75.9177 126.023 75.9177C123.507 75.9177 121.468 77.9571 121.468 80.4728C121.468 82.9885 123.507 85.0278 126.023 85.0278Z"
      fill="#39EFA5"
    />
    <path
      d="M141.207 69.8443C143.723 69.8443 145.762 67.8049 145.762 65.2892C145.762 62.7735 143.723 60.7341 141.207 60.7341C138.692 60.7341 136.652 62.7735 136.652 65.2892C136.652 67.8049 138.692 69.8443 141.207 69.8443Z"
      fill="#39EFA5"
    />
    <path
      d="M156.39 85.0278C158.906 85.0278 160.945 82.9885 160.945 80.4728C160.945 77.9571 158.906 75.9177 156.39 75.9177C153.874 75.9177 151.835 77.9571 151.835 80.4728C151.835 82.9885 153.874 85.0278 156.39 85.0278Z"
      fill="#39EFA5"
    />
    <path
      d="M171.574 69.8443C174.089 69.8443 176.129 67.8049 176.129 65.2892C176.129 62.7735 174.089 60.7341 171.574 60.7341C169.058 60.7341 167.019 62.7735 167.019 65.2892C167.019 67.8049 169.058 69.8443 171.574 69.8443Z"
      fill="#39EFA5"
    />
  </svg>
);

export const RedCurveIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.0156288 3.61534C-0.0743713 2.05534 0.185629 0.615334 1.92563 0.145334C2.31674 0.0124474 2.73277 -0.0306225 3.14281 0.0193214C3.55286 0.0692654 3.94638 0.210943 4.29416 0.43383C4.64194 0.656717 4.93505 0.955088 5.1517 1.30678C5.36836 1.65848 5.503 2.05447 5.54563 2.46534C8.28563 15.1853 16.8456 21.9253 28.7756 25.2353C30.6056 25.7353 31.6856 26.7553 31.2956 28.6853C30.9556 30.3353 29.6756 31.0153 28.0456 31.0153C16.9456 31.0953 0.0456295 14.5753 0.0156288 3.61534Z"
      fill="#E2186F"
    />
  </svg>
);

export const SpeechMarkIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    width="40"
    height="32"
    viewBox="0 0 40 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.46334 32C0.821116 27.2593 0 22.558 0 17.8963C0 12.5235 1.40763 8.21729 4.22287 4.97779C7.03811 1.65926 11.1437 0 16.5396 0V7.46668C12.3949 7.46668 10.3226 10.1531 10.3226 15.5259V17.3037H17.8299V32H2.46334ZM24.6334 32C23.0694 27.1802 22.2874 22.479 22.2874 17.8963C22.2874 12.5235 23.6559 8.21729 26.3929 4.97779C29.2082 1.65926 33.3138 0 38.7097 0V7.46668C34.6432 7.46668 32.6099 10.1531 32.6099 15.5259V17.3037H40V32H24.6334Z"
      fill="white"
    />
  </svg>
);
