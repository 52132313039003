import { User, UserSettings } from "./../../interfaces/user";
import { Query } from "@datorama/akita";
import { AuthState, AuthStore, authStore } from "./auth.store";
import { Observable } from "rxjs";
import { UserAccount } from "../../interfaces/account";

export class AuthQuery extends Query<AuthState> {
  get accessToken(): string | undefined {
    return this.getValue().accessToken;
  }

  get user(): User | undefined {
    return this.getValue().user;
  }

  get userId(): string {
    return this.user?.id ?? "";
  }

  get hasOnboarded(): boolean {
    return this.checkHasOnboarded(this.user?.settings);
  }

  get accountId(): string {
    return this.getValue().accountId ?? "";
  }

  get accounts(): UserAccount[] {
    return this.getValue().user?.accounts ?? [];
  }

  constructor(protected store: AuthStore) {
    super(store);
  }

  // TODO: Move this to a new User store
  selectUser(): Observable<User | undefined> {
    return this.select("user");
  }

  selectHasOnboarded(): Observable<boolean> {
    return this.select((s) => this.checkHasOnboarded(s.user?.settings));
  }

  selectAccounts(): Observable<UserAccount[]> {
    return this.select(({ user }) => user?.accounts ?? []);
  }

  private checkHasOnboarded(settings?: UserSettings): boolean {
    if (!settings?.onboarding) {
      return false;
    }

    return (
      settings.onboarding.hasCompleted ||
      Object.values(settings?.onboarding).some((v) => v.length)
    );
  }
}

export const authQuery = new AuthQuery(authStore);
