import { format } from "date-fns";
import React from "react";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import {
  CancelSubParams,
  cancelSubscription,
  downgradeSubscription,
  updateSubscriptionWithCoupon
} from "../../api/billing.service";
import { EN } from "../../assets/i18n/en";
import { Modal } from "../../containers/modal/modal";
import { useAccounts } from "../../hooks/use-accounts";
import { useDataLayer } from "../../hooks/use-data-layer";
import { SublyPlan } from "../../interfaces/billing";
import { ACCOUNT_PATH, SALES_CONTACT } from "../../routes";
import { accountQuery } from "../../state/account";
import {
  getLimits,
  getPlanNameLabel,
  getPlanPrice,
  isMonthlyPlan,
  subscriptionShortName
} from "../../utils/plans";
import { Button } from "../button/button";
import { Checkbox } from "../checkbox/checkbox";
import { DiscountIcon } from "../icons/icons";
import { notificationError, notificationSuccess } from "../notification";
import { QUOTES } from "./cancel-quotes";
import { getRandomInt } from "../../utils";
import { useAnalyticsWithAuth } from "../../hooks/use-analytics-with-auth";
import businessCancelImage from "../../assets/images/cancel-business.gif";
import styles from "./cancel-modal.module.scss";

enum CancelReason {
  NoLongerSubtitle = "No need to subtitle anymore",
  MissingFeature = "Subly is missing a feature", // specify
  Unhappy = "Unhappy with the current Subly service", // specify
  Budget = "Budget cuts",
  OtherProduct = "Found a different product",
  Other = "Other:"
}

interface Reasons {
  [key: string]: string;
}

interface CancelModalProps {
  subscriptionId: string;
  cancelPlan: SublyPlan;
  currentPlan: SublyPlan;
  onDismiss: () => void;
  isTrial?: boolean;
}

export const CancelModal: React.FC<CancelModalProps> = (props) => {
  return (
    <Modal onDismiss={props.onDismiss} hasCloseIcon className={styles["modal"]}>
      <CancelModalContainer {...props} />
    </Modal>
  );
};

const CancelModalContainer: React.FC<CancelModalProps> = ({
  onDismiss,
  subscriptionId,
  cancelPlan,
  currentPlan,
  isTrial
}) => {
  const navigate = useNavigate();

  const [loading, setLoading] = React.useState(false);
  const [hasCompletedSurvey, setHasCompletedSurvey] = React.useState(false);
  const [reasons, setReasons] = React.useState<Reasons>({});
  const [showOther, setShowOther] = React.useState(false);
  const [showOtherProduct, setShowOtherProduct] = React.useState(false);
  const [showMissingFeature, setShowMissingFeature] = React.useState(false);
  const [showUnhappy, setShowUnhappy] = React.useState(false);

  const [other, setOther] = React.useState("");
  const [otherProduct, setOtherProduct] = React.useState("");
  const [missingFeature, setMissingFeature] = React.useState("");
  const [unhappy, setUnhappy] = React.useState("");

  const { subscription } = useAccounts();
  const plan = accountQuery.getValue().plans.find((p) => p.name === cancelPlan);

  const dataLayer = useDataLayer();
  const { trackEventWithAuth } = useAnalyticsWithAuth();
  const { currentAccount } = useAccounts();
  const [seenCoupon, setSeenCoupon] = React.useState(false);
  const hasUsedCoupon = currentAccount?.settings?.hasUsedReturnCoupon;

  const toggleReason = (reason: CancelReason) => {
    const newValues = { ...reasons };

    if (!newValues[reason]) {
      newValues[reason] = reason;
    } else {
      delete newValues[reason];
    }

    setReasons(newValues);
  };

  const cancel = async (
    subscriptionId: string,
    cancelParams?: CancelSubParams
  ) => {
    try {
      setLoading(true);

      if (cancelPlan === SublyPlan.Free) {
        const planName = getPlanNameLabel(currentPlan);

        await cancelSubscription(subscriptionId, cancelParams);

        if (isTrial) {
          const event = `Cancelled trial ${planName}`;
          dataLayer(event);
          trackEventWithAuth("Cancelled trial", { ...cancelParams });
        } else {
          const event = "Downgrade to Free";
          dataLayer(event, { oldPlan: planName });
          trackEventWithAuth(event, { ...cancelParams, oldPlan: planName });
        }

        notificationSuccess("Your plan has been cancelled");
      } else if (plan) {
        await downgradeSubscription(subscriptionId, {
          productId: plan.id,
          immediate: false
        });

        const planName = getPlanNameLabel(currentPlan);
        const cancelPlanName = getPlanNameLabel(cancelPlan);
        const event = `Downgrade to ${cancelPlanName}`;
        dataLayer(event, { oldPlan: planName });
        trackEventWithAuth("Downgraded plan", {
          ...cancelParams,
          oldPlan: planName,
          toPlan: cancelPlanName
        });

        notificationSuccess(`Your plan has been downgraded to ${planName}`);
      }

      navigate(ACCOUNT_PATH);
      onDismiss();
    } catch (error) {
      setLoading(false);
      notificationError(EN.error.defaultMessage);
      console.error(error);
    }
  };

  const nextInvoiceDatePhrase = subscription?.nextInvoiceDate
    ? " on " + format(new Date(subscription?.nextInvoiceDate), "dd MMMM yyyy")
    : "";

  const completeSurvey = () => {
    const updatedReasons = { ...reasons };

    if (other) {
      updatedReasons[CancelReason.Other] = `${CancelReason.Other} ${other}`;
    } else {
      delete updatedReasons[CancelReason.Other];
    }

    if (otherProduct) {
      updatedReasons[
        CancelReason.OtherProduct
      ] = `${CancelReason.OtherProduct} ${otherProduct}`;
    } else {
      delete updatedReasons[CancelReason.OtherProduct];
    }

    if (missingFeature) {
      updatedReasons[
        CancelReason.MissingFeature
      ] = `${CancelReason.MissingFeature} ${missingFeature}`;
    } else {
      delete updatedReasons[CancelReason.MissingFeature];
    }

    if (unhappy) {
      updatedReasons[
        CancelReason.Unhappy
      ] = `${CancelReason.Unhappy} ${unhappy}`;
    } else {
      delete updatedReasons[CancelReason.Unhappy];
    }

    setHasCompletedSurvey(true);
    setReasons(updatedReasons);
  };

  if (
    plan &&
    plan.name !== SublyPlan.Free &&
    plan.name !== SublyPlan.Pro100 &&
    plan.name !== SublyPlan.Premium
  ) {
    return (
      <>
        <h5>Downgrade your subscription?</h5>
        <p>
          You'll be downgraded to our {subscriptionShortName(plan.name)} plan
          once your billing period ends.
        </p>

        <p>
          You will be charged ${getPlanPrice(cancelPlan)} when your new Subly{" "}
          {subscriptionShortName(cancelPlan)} plan starts{" "}
          {nextInvoiceDatePhrase}
        </p>

        <div className={styles["actions"]}>
          <Button secondary onClick={onDismiss} className="mr-2">
            No
          </Button>
          <Button
            danger
            onClick={() => cancel(subscriptionId)}
            loading={loading}
          >
            Yes, downgrade
          </Button>
        </div>
      </>
    );
  }

  const currentPlanName = `Subly ${getPlanNameLabel(currentPlan)}`;
  const title = isTrial
    ? `Cancel ${currentPlanName} trial`
    : `Cancel ${currentPlanName} and downgrade`;

  const mandatoryReason = !(
    (showMissingFeature && missingFeature.length > 3) ||
    (showUnhappy && unhappy.length > 3) ||
    (showOtherProduct && otherProduct.length > 3) ||
    (showOther && other.length > 3) ||
    Object.keys(reasons).length != 0
  );

  if (!hasCompletedSurvey) {
    return (
      <>
        <h5>{title}</h5>
        <p>
          To help improve our product we'd love to know why you're choosing to
          downgrade.
        </p>
        <ReasonCheckbox
          reason={CancelReason.NoLongerSubtitle}
          value={reasons}
          onChange={toggleReason}
        />
        <Checkbox value={showMissingFeature} onChange={setShowMissingFeature}>
          Subly is missing a feature
        </Checkbox>
        {showMissingFeature && (
          <input
            type="text"
            className="form-control mb-2"
            placeholder="* Please specify"
            value={missingFeature}
            onChange={(e) => setMissingFeature(e.target.value)}
            autoFocus
          />
        )}
        <Checkbox value={showUnhappy} onChange={setShowUnhappy}>
          Unhappy with the current Subly service
        </Checkbox>
        {showUnhappy && (
          <input
            type="text"
            className="form-control mb-2"
            placeholder="* Please specify"
            value={unhappy}
            onChange={(e) => setUnhappy(e.target.value)}
            autoFocus
          />
        )}
        <ReasonCheckbox
          reason={CancelReason.Budget}
          value={reasons}
          onChange={toggleReason}
        />
        <Checkbox value={showOtherProduct} onChange={setShowOtherProduct}>
          Found a different product
        </Checkbox>
        {showOtherProduct && (
          <input
            type="text"
            className="form-control mb-2"
            placeholder="* Please specify"
            value={otherProduct}
            onChange={(e) => setOtherProduct(e.target.value)}
            autoFocus
          />
        )}
        <Checkbox value={showOther} onChange={setShowOther}>
          Other
        </Checkbox>
        {showOther && (
          <input
            type="text"
            className="form-control"
            placeholder="* Please specify"
            value={other}
            onChange={(e) => setOther(e.target.value)}
            autoFocus
          />
        )}

        <div className={styles["actions"]}>
          <Button
            primary
            className="mt-4"
            onClick={completeSurvey}
            disabled={mandatoryReason}
          >
            Continue
          </Button>
        </div>
      </>
    );
  }

  const reason = Object.values(reasons).join("; ");

  //Check to see if the user is downgrading from a monthly plan to a free / Pro monthly
  //Also check if they have previously used the coupon and they are not trialling atm
  const checkSelectedCancelPlan =
    cancelPlan === SublyPlan.Free ||
    cancelPlan === SublyPlan.Pro100 ||
    cancelPlan === SublyPlan.Premium;
  const checkCurrentIsMonthly = isMonthlyPlan(currentPlan);

  if (
    checkSelectedCancelPlan &&
    checkCurrentIsMonthly &&
    !seenCoupon &&
    !hasUsedCoupon &&
    !isTrial
  ) {
    return (
      <CouponOfferModal
        currentPlan={currentPlan}
        downgradePlan={cancelPlan}
        onDismiss={onDismiss}
        setSeenCoupon={setSeenCoupon}
      />
    );
  }

  if (isTrial) {
    return (
      <>
        <h5>{title}</h5>
        <p>
          You're about to cancel your {currentPlanName} trial. You will lose
          access to all your {subscriptionShortName(currentPlan)} features. Are
          you sure you want to cancel?
        </p>

        <div className="d-flex flex-row justify-content-between mb-2">
          <Button secondary className={styles["button"]} onClick={onDismiss}>
            I want to stay
          </Button>
          <Button
            danger
            onClick={() => cancel(subscriptionId, { reason, immediate: true })}
            loading={loading}
          >
            Cancel {currentPlanName} trial
          </Button>
        </div>
      </>
    );
  }

  return (
    <>
      <h5>{title}</h5>
      <p>
        If you cancel {currentPlanName}, you will still have full access to{" "}
        {subscriptionShortName(currentPlan)} features until the end of your
        billing cycle {nextInvoiceDatePhrase}.
      </p>
      {cancelPlan !== SublyPlan.Free && (
        <p>
          You will be charged ${getPlanPrice(cancelPlan)} when your new Subly{" "}
          {subscriptionShortName(cancelPlan)} plan starts{" "}
          {nextInvoiceDatePhrase}
        </p>
      )}

      <div className="d-flex flex-row justify-content-between mb-2">
        <Button secondary className={styles["button"]} onClick={onDismiss}>
          I want to stay
        </Button>
        <Button
          danger
          onClick={() => cancel(subscriptionId, { reason })}
          loading={loading}
          className={styles["button"]}
        >
          Cancel {currentPlanName}
        </Button>
      </div>
    </>
  );
};

interface ReasonCheckboxProps {
  reason: CancelReason;
  value: Reasons;
  onChange: (reason: CancelReason) => void;
}
const ReasonCheckbox: React.FC<ReasonCheckboxProps> = ({
  reason,
  value,
  onChange
}) => {
  return (
    <Checkbox value={Boolean(value[reason])} onChange={() => onChange(reason)}>
      {reason}
    </Checkbox>
  );
};

interface CouponOfferModalProps {
  currentPlan: SublyPlan;
  downgradePlan: SublyPlan;
  onDismiss: () => void;
  setSeenCoupon: (seen: boolean) => void;
}

const CouponOfferModal: React.FC<CouponOfferModalProps> = ({
  currentPlan,
  downgradePlan,
  onDismiss,
  setSeenCoupon
}) => {
  const [acceptsCoupon, setAcceptsCoupon] = React.useState(false);
  const [showDowngradeList, setShowDowngradeList] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { subscription } = useAccounts();
  const navigate = useNavigate();

  const applyCoupon = async () => {
    if (subscription) {
      try {
        setLoading(true);
        await updateSubscriptionWithCoupon(subscription?.id, "RETURN50", {
          productId: subscription?.productId
        });

        navigate(ACCOUNT_PATH);
        onDismiss();
      } catch (error) {
        setLoading(false);
        notificationError(EN.error.defaultMessage);
        console.error(error);
      }
    }
  };

  if (acceptsCoupon) {
    return (
      <>
        <h5>50% off next 2 months</h5>
        <p>We’re so glad to hear that you’re continuing with {currentPlan}!</p>
        <div className="d-flex flex-row justify-content-between mb-2">
          <Button
            secondary
            className={styles["button"]}
            onClick={() => setAcceptsCoupon(false)}
          >
            Back
          </Button>
          <Button
            primary
            className={styles["button"]}
            onClick={() => applyCoupon()}
            loading={loading}
          >
            Confirm
          </Button>
        </div>
      </>
    );
  }

  if (showDowngradeList) {
    return (
      <>
        <h5>We’ll miss you if you go</h5>
        <p>
          We wouldn't want you to miss out on all your content features by
          cancelling your subscription today. Here is a special offer just for
          you.
        </p>
        <div className={styles["coupon-box"]}>
          <h5>50% off next 2 months</h5>
          <span>One time offer</span>
          <DiscountIcon className="my-3" />
          <Button
            primary
            className={styles["button"]}
            onClick={() => setAcceptsCoupon(true)}
          >
            Receive 50% off
          </Button>
        </div>
        <div className="d-flex flex-row justify-content-between mb-2">
          <Button secondary className={styles["button"]} onClick={onDismiss}>
            I want to stay
          </Button>
          <Button
            primary
            className={styles["button"]}
            onClick={() => setSeenCoupon(true)}
          >
            Cancel subscription
          </Button>
        </div>
      </>
    );
  }

  return (
    <>
      <DowngradeInfo currentPlan={currentPlan} downgradePlan={downgradePlan} />

      <div className="d-flex flex-row justify-content-end my-2">
        <Button
          secondary
          className={classNames(styles["button"], "mr-3")}
          onClick={onDismiss}
        >
          I want to stay
        </Button>
        <Button
          primary
          className={styles["button"]}
          onClick={() => setShowDowngradeList(true)}
        >
          I want to cancel
        </Button>
      </div>
    </>
  );
};

interface DowngradeInfoProps {
  currentPlan: SublyPlan;
  downgradePlan: SublyPlan;
}

const DowngradeInfo: React.FC<DowngradeInfoProps> = ({
  currentPlan,
  downgradePlan
}) => {
  const currentLimits = getLimits(currentPlan);
  const downgradeLimits = getLimits(downgradePlan);

  const currentPlanTitle = getPlanNameLabel(currentPlan);
  const downgradePlanTitle = getPlanNameLabel(downgradePlan);

  const storageLoss = currentLimits.storage - downgradeLimits.storage;
  let minutesLoss = "";
  if (currentLimits.minutes && downgradeLimits.minutes) {
    minutesLoss = `Lose ${
      currentLimits.minutes - downgradeLimits.minutes
    } minutes per month`;
  }

  const downgradeFromBusiness = [
    "Access to all your files online (don’t forget to download your files first before leaving)",
    "The ability to upload one video and download as many snippets as you want",
    "Super-fast video captioning",
    "Easiest hack to increase your watch time",
    "Ready-made templates for different social media channels"
  ];

  const downgradeFromPremium = [
    `Lose ${minutesLoss} minutes per month`,
    <>
      Lose {storageLoss} GB of storage{" "}
      <i>(download your videos before downgrading)</i>
    </>,
    "Lose access to translating features",
    "Lose access to advanced video editing features"
  ];

  const downgradeFromPro = [
    `Lose ${minutesLoss} minutes per month`,
    <>
      Lose {storageLoss} GB of storage{" "}
      <i>(download your videos before downgrading)</i>
    </>,
    "Lose access to brand logo and headlines",
    "Unable to remove Subly's watermark on videos"
  ];

  const { src, name } = QUOTES[getRandomInt(QUOTES.length)];

  if (currentPlan === SublyPlan.Business) {
    const renderList = downgradeFromBusiness.map((m, idx) => {
      return <li key={idx}>{m}</li>;
    });

    return (
      <div className={styles["business"]}>
        <div className={styles["header"]}>
          <h5>You’re leaving?! For real?</h5>
          <p>
            We did NOT see that coming. Ok, Ok, let’s see if we can work this
            out.
          </p>
        </div>

        <div className={styles["image"]}>
          <img src={businessCancelImage} alt="We did NOT see that coming" />
        </div>

        <div className={styles["features"]}>
          <span className={styles["features-title"]}>
            You’re ready to leave all this behind?{" "}
          </span>
          <ul className={styles["features-list"]}>{renderList}</ul>
        </div>

        <span className={styles["contact"]}>
          Would you rather{" "}
          <a
            href={SALES_CONTACT}
            target="_blank"
            rel="noopener noreferrer"
            className={styles["link"]}
          >
            contact us
          </a>{" "}
          and see if we can work something out instead?
        </span>
      </div>
    );
  }

  if (currentPlan === SublyPlan.Premium) {
    const renderList = downgradeFromPremium.map((m, idx) => {
      return <li key={idx}>{m}</li>;
    });

    return (
      <>
        <h5>Try Subly {currentPlanTitle} for a little longer?</h5>
        <div className="mb-3">
          <h6>
            Once you downgrade from Subly {currentPlanTitle} to Subly{" "}
            {downgradePlanTitle} you will:
          </h6>

          <ul className={styles["downgrade-list"]}>{renderList}</ul>
          <div className={styles["downgrade-quote"]}>
            <img src={src} alt={name} />
            <span> - {name}</span>
          </div>
        </div>
      </>
    );
  }

  const renderList = downgradeFromPro.map((m, idx) => {
    return <li key={idx}>{m}</li>;
  });

  return (
    <>
      <h5>Try Subly {currentPlanTitle} for a little longer?</h5>
      <div className="mb-3">
        <h6>
          Once you downgrade from Subly {currentPlanTitle} to Subly{" "}
          {downgradePlanTitle} you will:
        </h6>
        <ul className={styles["downgrade-list"]}>{renderList}</ul>
      </div>
    </>
  );
};
