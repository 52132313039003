import React, { useEffect, useState } from "react";

import { mdiAlert, mdiChevronLeft } from "@mdi/js";
import Icon from "@mdi/react";
import classNames from "classnames";
import { Link, useNavigate } from "react-router-dom";
import {
  isZoomFailAuth,
  IZoomRecordingSummary,
  listZoomRecordings,
  ZoomTabs
} from "../../api/apps.service";
import { LoadingIcon } from "../../components/loading-icon/loading-icon";
import { useModal } from "../../hooks/use-modal";
import { ModalType } from "../../interfaces/modal-type";
import { APPS_HOME_PATH, DASHBOARD_PATH } from "../../routes";
import { uploadQuery, uploadStore } from "../../state/upload";
import { ZoomUploadModal } from "../dashboard/upload-modal/zoom-upload-modal";
import { ZoomHeader } from "./zoom-header";
import { ZoomRecordingList } from "./zoom-recording-list";
import styles from "./zoom.module.scss";

export const ZoomContainer: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [recordingList, setRecordingList] = useState<
    IZoomRecordingSummary | undefined
  >();
  const navigate = useNavigate();

  const [showModal, hideModal] = useModal(ModalType.ZoomModal); // add another one for storage or share?
  const [tab, setTab] = useState<ZoomTabs>(ZoomTabs.NotImported);
  const [selected, setSelected] = useState<string[]>([]);

  useEffect(() => {
    const fetchZoomList = async () => {
      const fetchResp = await listZoomRecordings();

      if (isZoomFailAuth(fetchResp)) {
        navigate(APPS_HOME_PATH);
        return;
      }

      setRecordingList(fetchResp);
      setIsLoading(false);
    };

    fetchZoomList();
  }, []);

  const handleCloseModal = (goToDashboard?: boolean) => {
    hideModal();

    if (!uploadQuery.getValue().isUploading) {
      uploadStore.resetUpload();
    }

    if (goToDashboard) {
      navigate(DASHBOARD_PATH);
    }
  };

  const startImportingFiles = () => {
    const items = recordingList?.items ?? [];
    const files = items
      .flatMap((rec) =>
        rec.files.map((f) => ({
          fileId: f.id,
          meetingId: rec.meetingNumber,
          name: `${rec.startTime.substring(0, 10)} - ${rec.topic}`,
          type: f.fileType.toLowerCase(),
          duration: f.duration,
          size: f.size
        }))
      )
      .filter((f) => selected.includes(f.fileId));

    if (!files) {
      return;
    }

    showModal(
      <ZoomUploadModal initialFiles={files} closeModal={handleCloseModal} />
    );
  };

  const onChange = (id: string) => {
    if (selected.includes(id)) {
      setSelected(selected.filter((s) => s !== id));
    } else {
      setSelected([...selected, id]);
    }
  };

  if (isLoading) {
    return (
      <ZoomWrapper>
        <div className="d-flex align-items-center">
          <LoadingIcon /> <span>Loading...</span>
        </div>
      </ZoomWrapper>
    );
  }

  if (!recordingList) {
    return (
      <ZoomWrapper>
        <div className="text-center mt-5">
          <Icon path={mdiAlert} size="3rem" color="var(--destructive-600)" />
        </div>
        <div className="text-center mt-1">
          Error loading recordings, please contact support
        </div>
      </ZoomWrapper>
    );
  }

  if (recordingList.count === 0) {
    return (
      <ZoomWrapper>
        <div className="text-center mt-5">No meeting recordings found</div>
      </ZoomWrapper>
    );
  }

  return (
    <ZoomWrapper>
      <ZoomHeader
        tab={tab}
        setTab={setTab}
        importFiles={startImportingFiles}
        showImport
        disableImport={!selected.length}
        showArchive={false}
        disableArchive
      />
      <ZoomRecordingList
        recordingSummary={recordingList}
        selected={selected}
        onChange={(id) => onChange(id)}
      />
    </ZoomWrapper>
  );
};

const ZoomWrapper: React.FC = ({ children }) => {
  return (
    <>
      <Link to={APPS_HOME_PATH}>
        <h3 className={classNames(styles.title, "my-3")}>
          <Icon path={mdiChevronLeft} size="2rem" />
          Zoom
        </h3>
      </Link>
      {children}
    </>
  );
};
