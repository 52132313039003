import classNames from "classnames";
import React from "react";

type IntrinsicTextAreaProps = JSX.IntrinsicElements["textarea"];
interface TextAreaProps extends IntrinsicTextAreaProps {
  label?: string;
  hasError?: boolean;
}

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ rows = 2, className, label, hasError, ...props }, ref) => {
    return (
      <>
        {label && (
          <label className="mb-1" htmlFor={props.id || label}>
            {label}
          </label>
        )}
        <textarea
          className={classNames(
            "form-control",
            { "is-invalid": hasError },
            className
          )}
          ref={ref}
          rows={rows}
          {...props}
        />
      </>
    );
  }
);
