import classNames from "classnames";
import numeral from "numeral";
import React from "react";
import { Coupon } from "../../../../api/billing.service";
import { PlanInterval, SublyPlan } from "../../../../interfaces/billing";
import { getPlanNameLabel } from "../../../../utils/plans";
import { getVAT } from "../../../../utils/vat-rates";
import styles from "../plans.module.scss";

interface TotalCardProps {
  amount: number;
  interval: PlanInterval;
  country?: string;
  coupon?: Coupon;
  plan: SublyPlan;
  credit?: number;
}
export const TotalCard: React.FC<TotalCardProps> = ({
  amount,
  interval,
  country = "",
  coupon,
  plan,
  credit = 0
}) => {
  const couponPercentOff = coupon?.percent_off ?? 0;
  const taxRate = getVAT(country) || 0;
  const showTax = taxRate > 0;

  const amountWithDiscount = amount * (1 - couponPercentOff / 100);

  // If credit > amountWithDiscount it will return 0;
  const amountWithCredit = Math.max(amountWithDiscount - credit, 0);

  const total = amountWithCredit * (1 + taxRate);

  const discountedPriceFormat = amountWithDiscount % 10 > 0 ? "0.00" : "0";

  const renderTitle = coupon ? (
    <h3 className="my-0 mr-2">
      <span className={classNames("mr-2", styles["old-price"])}>
        ${numeral(amount).format("0")}
      </span>
      ${numeral(amountWithDiscount).format(discountedPriceFormat)}
    </h3>
  ) : (
    <h3 className="my-0 mr-2">${numeral(amount).format("0")}</h3>
  );

  return (
    <div className={classNames(styles["card"], styles["total-card"])}>
      <h6>Get Subly {getPlanNameLabel(plan)}</h6>
      {coupon && (
        <p className="small">
          {coupon.percent_off}% off for {coupon.duration_in_months} months
        </p>
      )}
      <div className="d-flex align-items-end">
        {renderTitle}
        <p className="small m-0">
          USD / {interval === PlanInterval.Yearly ? "year" : "month"}
        </p>
      </div>
      <p className="small mt-2">Billing starts today</p>
      <div className={styles["total-table"]}>
        <div className={styles["subtotal-row"]}>
          <p className="small mb-0">Subtotal</p>
          <p className="small mb-0">${numeral(amount).format("0.00")}</p>
        </div>
        {coupon && (
          <div className={styles["tax-row"]}>
            <p className="small mb-0">
              Discount ({numeral(coupon.percent_off).format("0")}%)
            </p>
            <p className="small mb-0">
              - ${numeral((amount * couponPercentOff) / 100).format("0.00")}
            </p>
          </div>
        )}
        {credit > 0 && (
          <div className={styles["credit-row"]}>
            <p className="small mb-0">Credit</p>
            <p className="small mb-0">-${numeral(credit).format("0.00")}</p>
          </div>
        )}
        {showTax && (
          <div className={styles["tax-row"]}>
            <p className="small mb-0">
              Tax ({numeral(taxRate * 100).format("0")}%)
            </p>
            <p className="small mb-0">
              ${numeral(amountWithCredit * taxRate).format("0.00")}
            </p>
          </div>
        )}
        <div className={styles["total-row"]}>
          <h6 className="mb-0">Due today</h6>
          <h6 className="mb-0">${numeral(total).format("0.00")}</h6>
        </div>
      </div>
    </div>
  );
};
