import numeral from "numeral";
import React from "react";
import { updateSubscription } from "../../api/billing.service";
import { EN } from "../../assets/i18n/en";
import { Button } from "../../components/button/button";
import { notificationError } from "../../components/notification";
import { useAccounts } from "../../hooks/use-accounts";
import { usePlan } from "../../hooks/use-plan";
import { Subscription } from "../../interfaces/billing";
import { PLANS_PATH } from "../../routes";
import {
  getFeatures,
  getPlanMinutes,
  getPlanNameLabel,
  getPlanPrice
} from "../../utils/plans";
import { firstLetterToLowerCase } from "../../utils/strings";
import { calculateTotals } from "../../utils/tax";
import { Modal, ModalProps } from "../modal/modal";
import { FeatureItem } from "../settings/plans/plans-table";
import styles from "./trial-confirm-modal.module.scss";

interface TrialConfirmModalProps extends ModalProps {
  subscription: Subscription;
  fromPlansPage?: boolean;
}
export const TrialConfirmModal: React.FC<TrialConfirmModalProps> = (props) => {
  return (
    <Modal onDismiss={props.onDismiss} hasCloseIcon>
      <TrialConfirmModalContainer {...props} />
    </Modal>
  );
};

const TrialConfirmModalContainer: React.FC<TrialConfirmModalProps> = ({
  subscription,
  fromPlansPage,
  onDismiss
}) => {
  const [isLoading, setLoading] = React.useState(false);
  const { plan } = usePlan();
  const { billing } = useAccounts();

  const handlePurchaseTrial = async () => {
    setLoading(true);

    try {
      await updateSubscription(subscription.id, {
        productId: subscription.productId,
        immediate: true
      });

      onDismiss && onDismiss();
    } catch (error) {
      console.error(error);
      notificationError(EN.error.defaultMessage);
      setLoading(false);
    }
  };

  const minutes = getPlanMinutes(plan);
  const features = getFeatures(plan);
  const featuresTitle = firstLetterToLowerCase(features.title);
  const renderFeatures = features.items.map((i) => {
    return <FeatureItem key={i} feature={i}></FeatureItem>;
  });
  const amount = getPlanPrice(plan) * 100;
  const country = billing?.details?.address?.country ?? "";
  const { amounts } = calculateTotals({ amount, country });
  const formattedAmount = numeral(amounts.total).format("$0.00");
  const purchaseLabel = `Purchase now (${formattedAmount})`;

  if (fromPlansPage) {
    return (
      <>
        <h5>Upgrade now to Subly {getPlanNameLabel(plan)}</h5>
        <p className="mb-1">
          When you upgrade, you will have {minutes} minutes and access to{" "}
          {featuresTitle}:
        </p>
        <ul className={styles.list}>{renderFeatures}</ul>

        <div className={styles.actions}>
          <Button secondary onClick={onDismiss} className="mr-2">
            Stay on my trial
          </Button>
          <Button primary onClick={handlePurchaseTrial} loading={isLoading}>
            {purchaseLabel}
          </Button>
        </div>
      </>
    );
  }

  return (
    <>
      <h5>Upgrade now to Subly {getPlanNameLabel(plan)}</h5>
      <p className="mb-1">
        When you upgrade, you will have {minutes} minutes and access to{" "}
        {featuresTitle}
      </p>
      <ul className={styles.list}>{renderFeatures}</ul>

      <div className={styles.actions}>
        <Button secondary to={PLANS_PATH} className="mr-2" onClick={onDismiss}>
          Pick plan
        </Button>
        <Button primary onClick={handlePurchaseTrial} loading={isLoading}>
          {purchaseLabel}
        </Button>
      </div>
    </>
  );
};
