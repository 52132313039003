import React from "react";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton
} from "react-share";
import {
  ShareEmailIcon,
  ShareFacebookIcon,
  ShareLinkedInIcon
} from "../../../../components/icons/icons";

interface ReferralSocialProps {
  name?: string;
  link?: string;
}

export const ReferralSocial: React.FC<ReferralSocialProps> = ({
  name,
  link
}) => {
  if (!link) {
    return <></>;
  }

  return (
    <>
      <EmailShareButton
        url={link}
        subject={`Your friend ${
          name ? `${name} ` : ""
        }sent you free minutes to start subtitling content🎁`}
        body={`Join me on Subly to get free minutes to start subtitling your content today

Use my referral link to claim your free minutes:`}
        windowWidth={750}
        windowHeight={600}
      >
        <ShareEmailIcon />
      </EmailShareButton>
      <FacebookShareButton
        url={link}
        quote={`Join me on Subly to get free minutes to start subtitling your content today

Use my referral link to claim your free minutes ${link}`}
        windowWidth={750}
        windowHeight={600}
        style={{ margin: 22, padding: 0 }}
      >
        <ShareFacebookIcon />
      </FacebookShareButton>
      <LinkedinShareButton url={link} windowWidth={750} windowHeight={600}>
        <ShareLinkedInIcon />
      </LinkedinShareButton>
    </>
  );
};
