import React, { useState } from "react";
import { Badge } from "../../../components/badge/badge";
import { Button } from "../../../components/button/button";
import { GoogleChooser } from "../../../components/google-picker/google-picker";
import { DriveIcon } from "../../../components/icons/icons";
import { notificationError } from "../../../components/notification";
import { PlanUpgradeModal } from "../../../components/upgrade-modal/plan-upgrade-modal";
import config from "../../../config";
import { useAnalyticsWithAuth } from "../../../hooks/use-analytics-with-auth";
import { useModal } from "../../../hooks/use-modal";
import { usePermissions } from "../../../hooks/use-permissions";
import { SublyPlan } from "../../../interfaces/billing";
import {
  GoogleDriveAuthInfo,
  GoogleDriveFileDoc,
  GoogleDriveFileInfo,
  GoogleDrivePickerEvent,
  GooglePickerError,
  isGoogleDriveFileDoc
} from "../../../interfaces/google-picker";
import { ModalType } from "../../../interfaces/modal-type";
import { onGooglePickerAuthFailed } from "../../../utils/google-picker";
import { cleanFileName } from "../../../utils/strings";

interface GoogleDriveUploaderProps {
  handleGoogleDriveFileSelection: (
    fileInfo: GoogleDriveFileInfo
  ) => Promise<void>;
  accept: string[];
  multiple?: boolean;
  className?: string;
}
export const GoogleDriveUploader: React.FC<GoogleDriveUploaderProps> = ({
  handleGoogleDriveFileSelection,
  multiple,
  accept,
  className
}) => {
  const { hasPermission } = usePermissions(SublyPlan.Premium);
  const [isLoading, setIsLoading] = useState(false);
  const [googleDriveAuth, setGoogleDriveAuth] = useState<GoogleDriveAuthInfo>({
    accessToken: "",
    authorizationCode: ""
  });
  const { trackEventWithAuth } = useAnalyticsWithAuth();
  const [showModal, hideAll] = useModal(ModalType.UpgradePlan);

  const onClickContainer = (e: React.MouseEvent<HTMLElement>) => {
    // Otherwise the dropzone click event fires
    e.stopPropagation();
  };

  if (!hasPermission) {
    const handleFreeGoogleUploadModal = () => {
      trackEventWithAuth("Click free Google Drive upload");

      showModal(
        <PlanUpgradeModal
          upgradePlan={SublyPlan.Premium}
          closeModal={hideAll}
        />
      );
    };

    return (
      <div onClick={onClickContainer} className={className}>
        <Button
          secondary
          className="d-block upload-btn"
          loading={isLoading}
          onClick={handleFreeGoogleUploadModal}
        >
          <DriveIcon className="mr-2" />
          Select from Google Drive
          <Badge className="ml-2">Premium</Badge>
        </Button>
      </div>
    );
  }

  const loadGoogleDriveFile = async (file: GoogleDriveFileDoc) => {
    try {
      // Google Chooser's mimeTypes property doesn't seem to work properly - allows invalid file types to show / load, and does not recognise audio/ogg
      if (!accept.includes(file.mimeType)) {
        notificationError("Sorry, we don't support this file type.");
        return;
      }

      setIsLoading(true);

      const fileInfo: GoogleDriveFileInfo = {
        accessToken: googleDriveAuth.accessToken,
        fileId: file.id,
        filename: cleanFileName(file.name, file.mimeType),
        mimeType: file.mimeType
      };

      await handleGoogleDriveFileSelection(fileInfo);

      setIsLoading(false);
    } catch (err) {
      notificationError(
        "Sorry, we failed to load the file. Please try again or contact support."
      );
      setIsLoading(false);
    }
  };

  const onGooglePickerChange = async (event: GoogleDrivePickerEvent) => {
    if (event.action === "picked") {
      trackEventWithAuth("Select Google Drive file");
      for await (const file of event.docs) {
        if (file && isGoogleDriveFileDoc(file)) {
          await loadGoogleDriveFile(file);
        }
      }
    }
  };

  return (
    <div onClick={onClickContainer} className={className}>
      <GoogleChooser
        clientId={config.googleDrive.clientId}
        developerKey={config.googleDrive.apiKey}
        scope={config.googleDrive.scope}
        onChange={(e: GoogleDrivePickerEvent) => onGooglePickerChange(e)}
        onAuthenticate={(auth: GoogleDriveAuthInfo) => setGoogleDriveAuth(auth)}
        onAuthFailed={(e: GooglePickerError) => onGooglePickerAuthFailed(e)}
        multiselect={multiple ?? false}
        navHidden={false}
        authImmediate={false}
        mimeTypes={accept}
        viewId={"FOLDERS"}
      >
        <Button
          secondary
          className="d-block upload-btn"
          loading={isLoading}
          onClick={() => trackEventWithAuth("Click Google Drive upload")}
        >
          <DriveIcon className="mr-2" />
          Select from Google Drive
        </Button>
      </GoogleChooser>
    </div>
  );
};
