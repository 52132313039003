import React from "react";
import { Link } from "react-router-dom";
import "./blank-billing-card.scss";

export interface BlankBillingCardProps {
  to: string;
}
export const BlankBillingCard: React.FC<BlankBillingCardProps> = ({
  to,
  children
}) => {
  return (
    <Link to={to}>
      <div className="account-card blank-billing-card card my-4">
        <div className="card-body">{children}</div>
      </div>
    </Link>
  );
};
