import { User } from "./../../interfaces/user";
import { Store, StoreConfig } from "@datorama/akita";

export interface AuthState {
  user?: User;
  accessToken?: string;
  accountId?: string;
}

@StoreConfig({ name: "auth" })
export class AuthStore extends Store<AuthState> {
  constructor() {
    super({});
  }

  setAccessToken = (accessToken?: string): void => {
    this.update({ accessToken });
  };

  setUser = (user?: User): void => {
    this.update({ user });
  };

  setAccountId = (accountId: string): void => {
    this.update({ accountId });
  };

  updateAccountName = (accountId: string, accountName: string): void => {
    this.update((s) => {
      if (!s.user?.accounts) {
        return s;
      }

      const userAccounts = s.user?.accounts ?? [];
      const accounts = userAccounts.map((a) => {
        if (a.accountId === accountId) {
          a.accountName = accountName;
        }

        return a;
      });

      return {
        ...s,
        user: {
          ...s.user,
          accounts
        }
      };
    });
  };
}

export const authStore = new AuthStore();
