import classNames from "classnames";
import { format } from "date-fns";
import numeral from "numeral";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { getCustomer } from "../../../api/billing.service";
import { Badge } from "../../../components/badge/badge";
import { Button } from "../../../components/button/button";
import { Divider } from "../../../components/divider/divider";
import { ClockIcon } from "../../../components/icons/icons";
import { LoadingIcon } from "../../../components/loading-icon/loading-icon";
import { CancelModal } from "../../../components/plans/cancel-modal";
import { SettingsCardContainer } from "../../../components/settings-card/settings-card-container";
import { Input } from "../../../components/controls/input";
import { PlansTableContext } from "../../../contexts/plans-table.context";
import { useAccounts } from "../../../hooks/use-accounts";
import { useAnalyticsWithAuth } from "../../../hooks/use-analytics-with-auth";
import { useModal } from "../../../hooks/use-modal";
import { usePlan } from "../../../hooks/use-plan";
import { SublyPlan } from "../../../interfaces/billing";
import { ModalType } from "../../../interfaces/modal-type";
import { PLANS_PATH, PLANS_UPGRADE_PATH } from "../../../routes";
import {
  getPlanData,
  getPlanMinutes,
  outOfMinutes,
  parseCreditToText,
  SublyPlanData
} from "../../../utils/plans";
import { formatBytes } from "../../../utils/storage-size";
import { TrialConfirmModal } from "../../trial/trial-confirm-modal";
import { PlanIcon } from "../plans/plans-table";
import styles from "./plan-card.module.scss";

interface LocationState {
  redirect?: string;
}

export const PlansCard: React.FC = () => {
  const [showTrialConfirmModal, hideTrialConfirmModal] = useModal(
    ModalType.TrialConfirmModal
  );
  const [showCancelModal, hideCancelModal] = useModal(ModalType.CancelModal);
  const { user, isAdmin, subscription, deal, isLoading, paymentMethods } =
    useAccounts();
  const { isTrial, trialEndDate, plan, isPayg, credit } = usePlan();
  const { trackEventWithAuth } = useAnalyticsWithAuth();
  const { interval, coupon: stripeCoupon } =
    React.useContext(PlansTableContext);
  const state = useLocation().state as LocationState;
  const planData = getPlanData(plan);
  const totalCredit = credit?.total ?? 0;

  React.useEffect(() => {
    if (!user) {
      return;
    }

    getCustomer({ force: false });
  }, [user]);

  if (!isAdmin) {
    return null;
  }

  if (isLoading) {
    return (
      <SettingsCardContainer title="Plan">
        <div className="mt-4">
          <LoadingIcon />
        </div>
      </SettingsCardContainer>
    );
  }

  if (deal) {
    return (
      <SettingsCardContainer title="Plan">
        <div className={styles["plan-card"]}>
          <div className={styles["plan"]}>
            <PlanIcon plan={planData} />
          </div>
          <div className="mt-2">
            <label className="font-weight-bold">Subly Business</label>
          </div>
          <BalanceStorage />
        </div>
      </SettingsCardContainer>
    );
  }

  if (isPayg) {
    const planData = getPlanData(SublyPlan.PAYG);

    return (
      <SettingsCardContainer title="Plan">
        <PlanCard planData={planData} credit={totalCredit} />{" "}
      </SettingsCardContainer>
    );
  }

  if (!subscription) {
    return (
      <SettingsCardContainer title="Plan">
        <div className={styles["plan-card"]}>
          <div className={styles["plan"]}>
            <div>
              <PlanIcon plan={planData} />
              <label className={styles["free"]}>Subly Free</label>
            </div>
          </div>
          <div className={styles["info"]}>
            <p className={styles["date"]}>
              Upgrade now to get access to more features
            </p>
            <BalanceStorage />
          </div>
        </div>

        <div className={classNames("mt-4", styles["plan-card-actions"])}>
          <span className={styles["features"]}>Want more Subly features?</span>
          <Button
            to={PLANS_PATH}
            primary
            onClick={() => trackEventWithAuth("Click on Upgrade")}
          >
            Upgrade
          </Button>
        </div>
      </SettingsCardContainer>
    );
  }

  if (isTrial && trialEndDate) {
    const handlePurchaseTrial = () => {
      showTrialConfirmModal(
        <TrialConfirmModal
          onDismiss={hideTrialConfirmModal}
          subscription={subscription}
        />
      );
    };

    const handleCancelTrial = () => {
      showCancelModal(
        <CancelModal
          subscriptionId={subscription.id}
          cancelPlan={SublyPlan.Free}
          currentPlan={plan}
          onDismiss={hideCancelModal}
          isTrial
        />
      );
    };

    return (
      <SettingsCardContainer title="Plan">
        <div className={styles["plan-card"]}>
          <div className={styles["plan"]}>
            <PlanIcon plan={planData} />
            <Badge className={styles["trial-badge"]}>TRIAL</Badge>
          </div>
          <div className={styles["info"]}>
            <p className={styles["date"]}>
              Trial ends on {format(new Date(trialEndDate), "dd MMM yyyy")}
            </p>
            <BalanceStorage />
            <p className={styles["cancel"]}>
              <span>
                <Link to="#" onClick={handleCancelTrial}>
                  Cancel trial
                </Link>
              </span>
            </p>
          </div>
        </div>
        <div className={classNames("mt-4", styles["plan-card-actions"])}>
          {paymentMethods?.length ? (
            <Button to="#" primary onClick={handlePurchaseTrial}>
              Purchase now
            </Button>
          ) : (
            <Button
              primary
              to={{
                pathname: PLANS_PATH
              }}
              state={{
                ...state,
                plan,
                interval,
                coupon: stripeCoupon
              }}
            >
              Upgrade
            </Button>
          )}
        </div>
      </SettingsCardContainer>
    );
  }

  return (
    <SettingsCardContainer title="Plan">
      <PlanCard planData={planData} credit={totalCredit} />
    </SettingsCardContainer>
  );
};

interface PlanCardProps {
  planData: SublyPlanData;
  credit: number;
}

export const PlanCard: React.FC<PlanCardProps> = ({ planData, credit }) => {
  const [quantity, setQuantity] = React.useState<number | undefined>();
  const creditMinutes = credit / 60;
  const creditLeft = creditMinutes < 30;

  return (
    <div className={styles["plan-card"]}>
      <TopUpHeader planData={planData} credit={credit} />

      <span className={styles["sub-header"]}>
        {creditLeft
          ? "You’re running out of minutes. Top up now."
          : " Need to add extra minutes?"}
      </span>
      <TopUpMinuteInput quantity={quantity} setQuantity={setQuantity} />

      {quantity != null && quantity > 19 && (
        <>
          <TopUpTotal quantity={quantity} />
          <div className="d-flex">
            <Button
              primary
              to={{ pathname: PLANS_UPGRADE_PATH }}
              state={{
                plan: planData.plan,
                quantity,
                topup: true,
                skipSettingValue: true
              }}
              className="mt-3"
              disabled={quantity < 20}
            >
              Purchase minutes
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

interface TopUpMinuteInputProps {
  quantity: number | undefined;
  setQuantity: React.Dispatch<React.SetStateAction<number | undefined>>;
}
export const TopUpMinuteInput: React.FC<TopUpMinuteInputProps> = ({
  quantity = "",
  setQuantity
}) => {
  return (
    <div className={styles["top-up-box"]}>
      <label className={styles["top-up-label-left"]}>Top up minutes</label>

      <div className="d-flex align-items-center position-relative">
        <ClockIcon className={styles.stopwatchIcon} />
        <Input
          type="number"
          min="20"
          placeholder="Enter minutes"
          value={quantity}
          onChange={({ target }) => {
            setQuantity(target.value ? +target.value : undefined);
          }}
          className={styles["top-up-input"]}
        />
      </div>
      <label className={styles["top-up-label-right"]}>
        $0.60 per minute (minimum 20 minutes)
      </label>
    </div>
  );
};

interface TopUpTotalProps {
  quantity: number;
}
export const TopUpTotal: React.FC<TopUpTotalProps> = ({ quantity }) => {
  return (
    <div className={styles["total-box"]}>
      <span>{quantity} mins</span>
      <div>
        <span className="mr-2">Total</span>
        <span className={styles["quantity"]}>
          ${numeral((quantity || 0) * 0.6).format("0.00")} USD
        </span>
      </div>
    </div>
  );
};

interface TopUpHeaderProps {
  planData: SublyPlanData;
  credit: number;
  checkout?: boolean;
}
export const TopUpHeader: React.FC<TopUpHeaderProps> = ({
  planData,
  credit,
  checkout = false
}) => {
  const creditMinutes = credit / 60;
  const lowMinutes = outOfMinutes(planData.plan, creditMinutes);
  const planMinutes = getPlanMinutes(planData.plan);
  const { subscription } = useAccounts();
  const nextInvoiceDatePhrase = subscription?.nextInvoiceDate
    ? format(new Date(subscription?.nextInvoiceDate), "MMMM dd, yyyy")
    : "";

  const isPAYG = planData.plan === SublyPlan.PAYG;

  if (!checkout && !isPAYG) {
    const currentBalance = parseCreditToText(credit, false, false).replace(
      "mins",
      ""
    );
    return (
      <div className={styles["header"]}>
        <div className={styles["logo"]}>
          <PlanIcon plan={planData} />
        </div>

        <div className={styles["plan-info"]}>
          <span className={styles["text"]}>
            Balance:{" "}
            <span className={styles["data"]}>
              <span
                className={classNames(styles["data"], {
                  [styles["data-warning"]]: lowMinutes
                })}
              >
                {currentBalance}
              </span>
              / {planMinutes} minutes
            </span>
          </span>

          <span className={styles["text"]}>
            End of billing cycle:{" "}
            <span className={styles["data"]}>{nextInvoiceDatePhrase}</span>
          </span>
        </div>

        <Divider className={styles["divider"]} />
      </div>
    );
  }
  return (
    <div className={styles["header"]}>
      <div className={styles["logo"]}>
        <PlanIcon plan={planData} />
        <div className={styles["balance"]}>
          <span className={styles["text"]}>
            Balance:{" "}
            <span
              className={classNames(styles["credit"], {
                [styles["credit-warning"]]: lowMinutes
              })}
            >
              {parseCreditToText(credit)}
            </span>
          </span>
        </div>
      </div>
      <Divider className={styles["divider"]} />
    </div>
  );
};

const BalanceStorage: React.FC = () => {
  const { totalStorageLabel, storageBytesUsed, isFree, isPayg, credit } =
    usePlan();
  const { size, units } = formatBytes(storageBytesUsed);
  const storageUsedText = `${size} ${units}`;

  const totalCredit = credit?.total ?? 0;

  const balanceText =
    isFree && !isPayg ? "0 minutes" : parseCreditToText(totalCredit);

  return (
    <>
      <p className={styles.balance}>
        <span>Current balance: </span>
        <span>{balanceText}</span>
      </p>

      <p className={styles.balance}>
        <span>Storage: </span>
        <span>
          {storageUsedText} / {totalStorageLabel}
        </span>
      </p>
    </>
  );
};
