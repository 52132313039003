import { Query } from "@datorama/akita";
import { Observable } from "rxjs";
import { UploadListFile } from "../../containers/dashboard/upload-modal/files-list";
import { UploadFile, UploadState, uploadStore } from "./upload.store";

const errorsWarningFirstSort = (a: UploadListFile, b: UploadListFile) => {
  if (a?.error) {
    return -1;
  }
  if (b?.error) {
    return 1;
  }
  if (a?.warning) {
    return -1;
  }
  if (b?.warning) {
    return 1;
  }
  return 0;
};

export class UploadQuery extends Query<UploadState> {
  get upload(): UploadState {
    return this.getValue();
  }

  get uploadFolderId(): string | undefined {
    return this.upload.folderId;
  }

  selectUploadFolderId(): Observable<string | undefined> {
    return this.select((s) => s.folderId);
  }

  get isChecking(): boolean {
    return this.isChecking;
  }

  selectIsChecking(): Observable<boolean> {
    return this.select((s) => s.isChecking);
  }

  selectTotalCount(): Observable<number> {
    return this.select((s) => s.queue.length);
  }

  selectTotalDuration(): Observable<number> {
    return this.select((s) => s.queue.reduce((t, p) => t + p.duration, 0) ?? 0);
  }

  selectTotalSize(): Observable<number> {
    return this.select((s) => s.queue.reduce((t, p) => t + p.size, 0) ?? 0);
  }

  selectUploadList(): Observable<UploadListFile[]> {
    return this.select((s) =>
      s.queue
        .map((f) => ({
          id: f.id,
          name: f.mediaName,
          type: (f as UploadFile)?.media?.type,
          duration: f.duration,
          size: f.size,
          error: f.error,
          warning: f.warning
        }))
        .sort(errorsWarningFirstSort)
    );
  }
}

export const uploadQuery = new UploadQuery(uploadStore);
