import { getOsEnvArray } from "../utils/env";

export default {
  isProduction: process.env.REACT_APP_NODE_ENV === "production",
  latestReleaseVersion: "2.6.0",
  // Api
  apiUrl: process.env.REACT_APP_API_URL,
  // Pusher
  pusher: {
    clientKey: process.env.REACT_APP_PUSHER_KEY || "",
    cluster: process.env.REACT_APP_PUSHER_CLUSTER || "",
    authEndpoint: `${process.env.REACT_APP_API_URL}/api/v1/pusher/auth`
  },
  // Analytics
  gtmId: process.env.REACT_APP_GTM_ID || "",
  mixpanel: process.env.REACT_APP_MIXPANEL_API || "",
  amplitude: process.env.REACT_APP_AMPLITUDE_API || "",
  hubspot: process.env.REACT_APP_HUBSPOT_API || "",

  // Sentry
  sentry: process.env.REACT_APP_SENTRY_DSN,
  // OAuth
  oauth: {
    google: `${window.location.origin}/auth/google/callback`
  },
  // Stripe
  stripe: {
    clientKey: process.env.REACT_APP_STRIPE_CLIENT_KEY
  },
  // Maintenance mode message
  maintenanceMode: {
    message: process.env.REACT_APP_MAINTENANCE_MODE_MESSAGE
  },
  // Google Drive
  googleDrive: {
    apiKey: process.env.REACT_APP_GOOGLE_DRIVE_API_KEY,
    clientId: process.env.REACT_APP_GOOGLE_DRIVE_CLIENT_ID,
    scope: ["https://www.googleapis.com/auth/drive"]
  },
  reCAPTCHA_V3: process.env.REACT_APP_RECAPTCHA_KEY_V3 || "",

  useDataAnalytics: process.env.REACT_APP_USE_MIXPANEL === "true",
  previewBaseUrl: process.env.REACT_APP_PREVIEW_API_URL || "",

  // Feature flags
  features: {
    customVocabulary: process.env.REACT_APP_ENABLE_CUSTOM_VOCABULARY === "true",
    hasEducationCoupon: process.env.REACT_APP_ENABLE_EDU_COUPON === "true",
    hasProgressBars: process.env.REACT_APP_ENABLE_PROGRESS_BARS === "true",
    hasApps: process.env.REACT_APP_USE_APPS === "true",
    hasUnlinkApps: process.env.REACT_APP_USE_APPS_UNLINK === "true",
    hasReferral: process.env.REACT_APP_ENABLE_REFERRALS === "true",
    hasUserPresence: process.env.REACT_APP_USER_PRESENCE === "true",
    notifications: process.env.REACT_APP_NOTIFICATIONS === "true",
    languageUpload: process.env.REACT_APP_LANGUAGE_UPLOAD === "true",
    NewEditor: process.env.REACT_APP_NEW_EDITOR === "true"
  },

  zoom: {
    clientId: process.env.REACT_APP_ZOOM_CLIENT_ID,
    redirectUri: process.env.REACT_APP_ZOOM_REDIRECT_URI || ""
  },

  // Education discount exemptions
  discountExemptions: getOsEnvArray("REACT_APP_DISCOUNT_EXEMPTIONS"),
  trialDownloadsLimit: 2
};
