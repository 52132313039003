import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { AppId, listApps } from "../../api/apps.service";
import zoomScreenshot from "../../assets/images/apps/screenshots/zoom.png";
import { ZoomIcon } from "../../components/icons/icons";
import config from "../../config";
import { AppList } from "../../containers/app/app-list";
import { App } from "../../interfaces/app";
import { SublyPlan } from "../../interfaces/billing";
import { SettingsLayout } from "../../layouts/settings/settings.layout";

import styles from "./app-page.module.scss";

const zoomUrl = `https://zoom.us/oauth/authorize?response_type=code&client_id=${
  config.zoom.clientId
}&redirect_uri=${encodeURIComponent(config.zoom.redirectUri)}`;

export const APP_LIST: Omit<App, "connected">[] = [
  {
    appId: AppId.Zoom,
    icon: <ZoomIcon className={classNames(styles.icon)} />,
    name: "Zoom",
    description: "Save time by importing videos from Zoom directly into Subly",
    link: "/apps/zoom",
    modal: {
      title: "Zoom Integration",
      description:
        "Once the Zoom app has been connected, Subly will pull in meetings from the past 30 days. When subsequent meetings are recorded into your Zoom account, this will be visible inside your Subly Zoom dashboard.",
      screenshot: zoomScreenshot,
      link: zoomUrl,
      permission: SublyPlan.Premium
    }
  }
];

export const AppsHomePage: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [apps, setApps] = useState<App[]>([]);

  useEffect(() => {
    const fetchAppList = async () => {
      const fetchResp = await listApps();

      if (fetchResp) {
        const connectedApps = fetchResp.map((a) => a.appId);
        setApps(
          APP_LIST.map((a) => ({
            ...a,
            connected: connectedApps.includes(a.appId)
          }))
        );
      }
      setIsLoading(false);
    };

    fetchAppList();
  }, []);

  return (
    <SettingsLayout hasBackArrow={false}>
      <div className="container-fluid">
        <h3 className={classNames(styles.title, "my-3")}>Apps</h3>
        <AppList appList={apps} isLoading={isLoading} showSuggestionCard />
      </div>
    </SettingsLayout>
  );
};
