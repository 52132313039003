import {
  AspectRatio,
  BurnQuality,
  hashSubtitles,
  Transcription
} from "@getsubly/common";
import axios from "axios";
import { Dict } from "mixpanel-browser";
import { notificationSuccess } from "../components/notification";
import config from "../config";
import settings from "../config/settings/settings";
import { getAccountId } from "../config/settings/settings.service";
import { AudioFormat } from "../contexts/download-dropdown.context";
import { SublyPlan } from "../interfaces/billing";
import { GoogleDriveDownloadInfo } from "../interfaces/google-picker";
import {
  BasicMedia,
  BurningTask,
  Media,
  MediaJob,
  MediaLanguage,
  MediaStatus,
  SubtitleFormat
} from "../interfaces/media";
import { TranscriptionMap } from "../interfaces/media-editor";
import { UnknownObject } from "../interfaces/types";
import { accountStore } from "../state/account";
import {
  downloadQueueStore,
  QueueFileStatus
} from "../state/download-queue/download-queue.store";
import { foldersQuery } from "../state/folders/folders.query";
import {
  DefaultFoldersId,
  FolderId,
  foldersStore
} from "../state/folders/folders.store";
import { mediaQuery, mediaStore } from "../state/media";
import {
  isUploadFile,
  isUploadUrlFile,
  isUploadZoomFile,
  UploadFile,
  uploadQuery,
  uploadStore,
  UploadUrlFile,
  UploadZoomFile
} from "../state/upload";
import {
  getFileFromJob,
  getMediaLanguageProperties,
  isSubtitleFileType,
  transformToBasicMedia,
  transformToBasicMediaList
} from "../utils/media-functions";
import { pluralize } from "../utils/strings";
import { getAccessToken } from "./auth.service";
import {
  downloadBurntMedia,
  downloadSubtitles,
  saveLegacyTranscription
} from "./file.service";
import { handleError } from "./handle-error";

const baseURL = `${config.apiUrl}/api/v1`;

interface BaseResponse {
  message: string;
}

interface ListMediaResponse extends BaseResponse {
  items: Media[];
  count?: number;
  total?: number;
}

interface MediaRequestInfo {
  count: number;
  total: number;
}

interface MediaRequestParams {
  includeSharedWithMe?: boolean;
  isPublic?: boolean;
  sharedWithMe?: boolean;
  folderId?: FolderId;
  limit?: number;
  skip?: number;
}

export const getAllMedia = async (
  folderId?: FolderId,
  limit = 4,
  skip = 0
): Promise<MediaRequestInfo | void> => {
  try {
    mediaStore.setLoading(true);
    mediaStore.setError("");

    const params = { ...getMediaParams(folderId), skip, limit };

    const {
      data: { items: media, total }
    } = await axios.get<ListMediaResponse>(`/${getAccountId()}/media`, {
      baseURL: baseURL,
      headers: { "x-access-token": await getAccessToken() },
      params
    });

    if (total !== mediaQuery.activeFolderMediaTotal) {
      mediaStore.setActiveFolderMediaTotal(total);
    }

    const basicMediaList = transformToBasicMediaList(media, {
      sharedWithMe: params.sharedWithMe
    });

    // Hide all media which seems to be uploading
    const mediaList = basicMediaList.filter(
      (m) => m.status !== MediaStatus.Uploading
    );
    mediaStore.upsertMany(mediaList);

    await updateMediaProgressStatus(mediaList);

    mediaStore.setLoading(false);
    mediaList.forEach(({ mediaId }) =>
      uploadStore.removeFileByMediaId(mediaId)
    );
  } catch (e) {
    handleError(e);
    mediaStore.setError(
      e.message || "There was an error. Please try again or contact support"
    );
    mediaStore.setLoading(false);
  }
};

export const getOrFetchMedia = async (
  mediaId: string
): Promise<BasicMedia | undefined> => {
  const media = mediaQuery.getEntity(mediaId);

  // If no media owner is set, fetch the single Media fresh
  if (media?.owner) {
    return media;
  }

  return await fetchMedia(mediaId);
};

interface GetMediaResponse extends BaseResponse {
  media: Media;
}

export const fetchMedia = async (
  mediaId: string,
  options = { stopUpload: false }
): Promise<BasicMedia | undefined> => {
  try {
    const { data: media } = await axios.get<Media>(
      `/${getAccountId()}/media/${mediaId}`,
      {
        baseURL: baseURL,
        headers: { "x-access-token": await getAccessToken() }
      }
    );

    const basicMedia = transformToBasicMedia(media);

    mediaStore.upsert(mediaId, basicMedia);

    await updateMediaProgressStatus([basicMedia]);

    if (options.stopUpload) {
      uploadStore.removeFileByMediaId(mediaId);
      if (uploadQuery.getValue().queue.length === 0) {
        uploadStore.resetUpload();
      }
    }
    return basicMedia;
  } catch (e) {
    handleError(e);
  }
};

export const renameMedia = async (
  mediaId: string,
  name: string
): Promise<void> => {
  try {
    await axios.put(
      `/${getAccountId()}/media/${mediaId}/rename`,
      { name },
      {
        baseURL: baseURL,
        headers: { "x-access-token": await getAccessToken() }
      }
    );

    const media = mediaQuery.getEntity(mediaId);

    if (!media) {
      return;
    }

    const updatedMedia = { ...media, name };
    mediaStore.update(mediaId, updatedMedia);
  } catch (e) {
    handleError(e);
  }
};

export const moveMedia = async (
  mediaId: string,
  folderId?: string
): Promise<void> => {
  const items = [{ mediaId, folderId }];

  try {
    await axios.post(
      `/${getAccountId()}/media/move`,
      { items },
      {
        baseURL: baseURL,
        headers: { "x-access-token": await getAccessToken() }
      }
    );

    const media = mediaQuery.getEntity(mediaId);

    if (!media) {
      return;
    }

    const updatedMedia = { ...media, folderId };
    mediaStore.update(mediaId, updatedMedia);

    foldersStore.incrementCount(folderId);
    foldersStore.decrementCount(media.folderId);
  } catch (e) {
    handleError(e);
  }
};

type MakeShareableResponse = GetMediaResponse;
export const makeMediaShareable = async (
  mediaId: string
): Promise<BasicMedia | undefined> => {
  try {
    const {
      data: { media }
    } = await axios.post<MakeShareableResponse>(
      `/${getAccountId()}/media/${mediaId}/share`,
      {},
      {
        baseURL,
        headers: { "x-access-token": await getAccessToken() }
      }
    );

    const basicMedia = transformToBasicMedia(media);

    mediaStore.upsert(mediaId, basicMedia);

    return basicMedia;
  } catch (e) {
    handleError(e);
  }
};

export const fetchAllTranscriptions = async (
  media: BasicMedia
): Promise<TranscriptionMap> => {
  try {
    if (!media.transcriptions.originalId) {
      return await legacyFetchAndCreateOriginalTranscription(media.mediaId);
    }

    const fileIds = [
      media.transcriptions.originalId,
      ...media.transcriptions.translationsIds
    ];

    return await fetchTranscriptions(media.mediaId, fileIds);
  } catch (e) {
    handleError(e);
    throw new Error(e);
  }
};

export const fetchTranscriptions = async (
  mediaId: string,
  fileIds: string[]
): Promise<TranscriptionMap> => {
  const response: TranscriptionMap = {};

  for await (const id of fileIds) {
    response[id] = await fetchTranscriptionById(mediaId, id);
  }

  return response;
};

export const fetchTranscriptionById = async (
  mediaId: string,
  fileId: string
): Promise<Transcription> => {
  try {
    const { data } = await axios.get<Transcription>(
      `/${getAccountId()}/media/${mediaId}/json/${fileId}`,
      {
        baseURL: baseURL,
        headers: { "x-access-token": await getAccessToken() }
      }
    );

    return data;
  } catch (e) {
    handleError(e);
    return [];
  }
};

// This is used for legacy files that don't have a transcription
// and this transcription gets generated by the .srt
const legacyFetchAndCreateOriginalTranscription = async (
  mediaId: string
): Promise<TranscriptionMap> => {
  try {
    const { data } = await axios.get<Transcription>(
      `/${getAccountId()}/media/${mediaId}/json`,
      {
        baseURL: baseURL,
        headers: { "x-access-token": await getAccessToken() }
      }
    );

    const { fileId } = await saveLegacyTranscription(mediaId, data);

    if (!fileId) {
      throw new Error();
    }

    const media = mediaQuery.getEntity(mediaId) as BasicMedia;
    const originalLanguage = getMediaLanguageProperties(
      fileId,
      settings.transcription.languages,
      media.language
    );

    if (!originalLanguage) {
      throw new Error();
    }

    const transcriptions = await fetchTranscriptions(media.mediaId, [
      fileId,
      ...media.transcriptions.translationsIds
    ]);

    const updatedMedia: BasicMedia = {
      ...media,
      transcriptions: {
        ...media.transcriptions,
        originalId: fileId,
        original: originalLanguage
      }
    };

    mediaStore.update(mediaId, updatedMedia);

    return transcriptions;
  } catch (e) {
    throw new Error(e);
  }
};

export const burnOrDownloadMedia = async (
  mediaId: string,
  language: MediaLanguage,
  options: {
    ratio?: AspectRatio;
    quality?: BurnQuality;
    googleDrive?: GoogleDriveDownloadInfo;
    snippetId?: string;
    useOriginal?: boolean;
  } = {},
  analyticsData: UnknownObject
): Promise<void> => {
  const media = mediaQuery.getEntity(mediaId);

  if (!media) {
    return;
  }

  if (options?.snippetId) {
    const snippet = media.assConfig.snippets?.find(
      (s) => s.id === options?.snippetId
    );
    if (snippet && snippet?.transcriptionId) {
      const transcription = media?.files?.find(
        (f) => f.id === snippet.transcriptionId
      );
      if (transcription?.language) {
        const snippetLanguage = getMediaLanguageProperties(
          snippet.transcriptionId,
          settings.translation.languages
        );

        language = snippetLanguage ?? language;
      }
    }
  }

  // Get the latest saved version from the server
  const transcription = await fetchTranscriptionById(
    media.mediaId,
    language.id
  );

  const burnHash = hashSubtitles(
    transcription,
    media.assConfig,
    options.ratio,
    language.language,
    options?.snippetId
  );

  const { ratio, quality, googleDrive } = options;

  const burntFile = media.files.find((f) => f.burnHash === burnHash);

  const disabledDownload = media.isBurning || !burntFile;
  const canDownload = !disabledDownload;

  if (canDownload && burntFile) {
    downloadBurntMedia({
      mediaId,
      burntFile,
      analyticsData,
      googleDrive
    });

    return;
  }

  return burnMedia({
    mediaId,
    language,
    transcription,
    ratio,
    quality,
    googleDrive,
    useOriginal: Boolean(options?.useOriginal),
    snippetId: options?.snippetId
  });
};

interface IBurnMediaParams {
  mediaId: string;
  language: MediaLanguage;
  transcription: Transcription;
  ratio?: AspectRatio;
  quality?: BurnQuality;
  googleDrive?: GoogleDriveDownloadInfo;
  useOriginal: boolean;
  snippetId?: string;
}

const burnMedia = async ({
  mediaId,
  language,
  transcription,
  ratio,
  quality,
  googleDrive,
  useOriginal,
  snippetId
}: IBurnMediaParams) => {
  try {
    const mediaConfig = mediaQuery.getEntity(mediaId)?.assConfig;

    if (!mediaConfig) {
      throw new Error();
    }

    const hash = hashSubtitles(
      transcription,
      mediaConfig,
      ratio,
      language.language,
      snippetId
    );

    // Don't send a ratio back to the server
    if (ratio === AspectRatio.Original) {
      ratio = undefined;
    }

    const payload = {
      hash,
      ratio,
      quality,
      subtitleFileId: language.id,
      googleDrive,
      useOriginal,
      snippetId
    };
    const url = `/${getAccountId()}/transcriptions/${mediaId}/burn`;

    await axios.post(url, payload, {
      baseURL: baseURL,
      headers: { "x-access-token": await getAccessToken() }
    });

    mediaStore.update(mediaId, { isBurning: true });
  } catch (e) {
    handleError(e);
  }
};

export const cancelBurnMedia = async (mediaId: string): Promise<void> => {
  const media = mediaQuery.getEntity(mediaId);

  if (!media || !media.isBurning) {
    return;
  }

  try {
    await axios.put(
      `/${getAccountId()}/transcriptions/${mediaId}/abort`,
      {},
      {
        baseURL: baseURL,
        headers: { "x-access-token": await getAccessToken() }
      }
    );

    mediaStore.update(mediaId, { isBurning: false });
  } catch (e) {
    handleError(e);
  }
};

export const cancelMediaJob = async (
  mediaId: string,
  jobId: string
): Promise<void> => {
  try {
    downloadQueueStore.updateQueueJob(mediaId, jobId, {
      status: QueueFileStatus.Cancelling,
      progress: 0,
      hasDownloaded: true,
      isProcessing: false
    });

    await axios.put(
      `/media/${mediaId}/abort`,
      {
        jobId
      },
      {
        baseURL: baseURL,
        headers: { "x-access-token": await getAccessToken() }
      }
    );

    downloadQueueStore.updateQueueJob(mediaId, jobId, {
      status: QueueFileStatus.Cancelled,
      progress: 0,
      hasDownloaded: true,
      isProcessing: false
    });
  } catch (e) {
    handleError(e);
  }
};

export const deleteMedia = async (mediaId: string): Promise<void> => {
  try {
    await axios.delete(`/${getAccountId()}/media/${mediaId}`, {
      baseURL: baseURL,
      headers: { "x-access-token": await getAccessToken() }
    });

    const folderId = mediaQuery.getEntity(mediaId)?.folderId;
    const fileSize = mediaQuery.getEntity(mediaId)?.fileSize ?? 0;

    mediaStore.remove(mediaId);
    accountStore.decrementStorageBytesUsed(fileSize);

    if (folderId) {
      foldersStore.decrementCount(folderId);

      const folder = foldersQuery.getEntity(folderId);

      if (folder && !folder?.isPublic) {
        accountStore.decrementMediaCount();
      }
    } else {
      accountStore.decrementMediaCount();
    }
  } catch (e) {
    handleError(e);

    // If it fails removing, fetch media again
    fetchMedia(mediaId);
  }
};

interface IUploadMediaRequestPayload {
  name: string;
  language: string;
  folderId?: string;
  filename: string;
  mimeType: string;
  sizeBytes: number;
  transcription?: Transcription;
  settings?: MediaSettings;
}
export interface MediaSettings {
  skippedTranscription?: boolean;
}
export interface IUploadMediaRequestResponse {
  mediaUrl: string;
  mediaId: string;
}

export interface IUploadToBucketParams {
  file: UploadFile | UploadUrlFile | UploadZoomFile;
  language: string;
  folderId?: string;
  transcription?: Transcription;
  skippedTranscription?: boolean;
}

export const uploadFiles = async (
  plan: SublyPlan,
  isProOrHigher: boolean,
  mixpanelTrack: (action: string, properties?: Dict) => void,
  skipTranscription?: boolean
): Promise<void> => {
  const { queue, language, folderId, transcription } = uploadQuery.getValue();

  for (let i = 0; i < queue.length; i++) {
    try {
      const file = queue[i];

      uploadStore.setFileUpload(file.id);

      await uploadMediaToBucket({
        file,
        language,
        folderId,
        transcription: queue.length === 1 ? transcription : undefined,
        skippedTranscription: skipTranscription
      });

      mixpanelTrack("Select standard file", {
        plan,
        isProOrHigher,
        duration: file.duration,
        language,
        size: file.size,
        isAudio: Boolean(file.isAudio),
        includeSRT: Boolean(transcription),
        isGoogleDrive: Boolean((file as UploadFile)?.isGoogleDrive),
        isUrl: Boolean((file as UploadUrlFile)?.isUrl),
        isZoom: Boolean((file as UploadZoomFile)?.isZoom),
        skippedTranscription: skipTranscription
      });
    } catch (e) {
      console.error(e);
    }
  }
};

export const uploadMediaToBucket = async ({
  file,
  language,
  folderId,
  transcription,
  skippedTranscription
}: IUploadToBucketParams): Promise<void> => {
  const { id, mediaName, filename, size } = file;

  uploadStore.update({
    isUploading: true,
    progress: 0,
    mediaName,
    language
  });

  try {
    if (isUploadZoomFile(file)) {
      const { data } = await axios.put<{ mediaId: string }>(
        `/${getAccountId()}/zoom/transcribe`,
        {
          meetingId: file.meetingId,
          fileId: file.fileId,
          name: mediaName,
          language,
          folderId,
          transcription,
          settings: {
            origin: "zoom"
          }
        },
        {
          baseURL,
          headers: { "x-access-token": await getAccessToken() }
        }
      );

      uploadStore.updateFile(id, { mediaId: data.mediaId });
    } else {
      // Post data
      const body: IUploadMediaRequestPayload = {
        name: mediaName,
        language,
        folderId,
        filename: filename,
        sizeBytes: size,
        mimeType: (file as UploadFile)?.media?.type ?? "video/mp4",
        transcription,
        settings: {
          skippedTranscription
        }
      };

      const {
        data: { mediaUrl, mediaId }
      } = await axios.post<IUploadMediaRequestResponse>(
        `/${getAccountId()}/media/upload`,
        body,
        {
          baseURL: baseURL,
          headers: {
            "Content-Type": "application/json",
            "x-access-token": await getAccessToken()
          }
        }
      );

      uploadStore.updateFile(id, { mediaId });
      if (isUploadFile(file)) {
        // Upload to bucket
        await axios.put(mediaUrl, file.media, {
          headers: {
            "Content-Type": file.media?.type ?? "video/mp4"
          },
          onUploadProgress: (progressEvent) => {
            const progress = Math.floor(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            uploadStore.setUploadProgress(progress);
          }
        });
      } else if (isUploadUrlFile(file)) {
        await axios.post<IUploadMediaRequestResponse>(
          `/${getAccountId()}/media/url-upload`,
          {
            destUrl: mediaUrl,
            filename,
            sourceUrl: file.url,
            size
          },
          {
            baseURL: baseURL,
            headers: {
              "Content-Type": "application/json",
              "x-access-token": await getAccessToken()
            }
          }
        );
      }
    }
    uploadStore.updateFile(id, { uploaded: true });
    accountStore.incrementStorageBytesUsed(size);
  } catch (e) {
    handleError(e);
    uploadStore.removeFile(id);
  }
};

export interface TranscribeMediaParams {
  mediaId: string;
  language: string;
}

export const transcribeMedia = async ({
  mediaId,
  language
}: TranscribeMediaParams): Promise<void> => {
  try {
    const body = {
      mediaId,
      language
    };

    await axios.post(`/${getAccountId()}/media/${mediaId}/transcribe`, body, {
      baseURL: baseURL,
      headers: { "x-access-token": await getAccessToken() }
    });
  } catch (e) {
    if (e.response?.status != 200) {
      return e.response.data;
    }
    handleError(e);
    return;
  }
};

export interface UrlInfo {
  url: string;
  filename: string;
  duration: number;
  fileSize: number;
}

export interface UrlInfoError {
  url: string;
  error: string;
}
export const isUrlInfoError = (
  item?: UrlInfo | UrlInfoError
): item is UrlInfoError => Boolean(!item || (item as UrlInfoError)?.error);

export const getUrlInfo = async (
  url: string
): Promise<UrlInfo | UrlInfoError | undefined> => {
  try {
    const { data } = await axios.post<UrlInfo | UrlInfoError>(
      `/${getAccountId()}/media/url-info`,
      { url },
      {
        baseURL: baseURL,
        headers: { "x-access-token": await getAccessToken() }
      }
    );

    return data;
  } catch (e) {
    if (
      e.response?.status === 400 &&
      e.response?.data?.error &&
      e.response?.data?.url
    ) {
      return e.response.data;
    }
    handleError(e);
    return;
  }
};

export const updateMediaProgressStatus = async (
  mediaList: BasicMedia[]
): Promise<void> => {
  for (let i = 0; i < mediaList.length; i++) {
    const media = mediaList[i];

    if (media.isBurning && !media.burningTasks) {
      const {
        data: { progress }
      } = await getBurnProgress(media.mediaId);

      if (progress) {
        mediaStore.update(media.mediaId, {
          burningTasks: progress.tasks,
          burnProgress: progress.overallProgress
        });
      }
    }
  }
};

interface IBatchBaseFile {
  metadata: { downloadName: string; languageId?: string };
  googleDrive?: GoogleDriveDownloadInfo;
  hash?: string;
}

export interface IBatchVideo extends IBatchBaseFile {
  hash: string;
  ratio: AspectRatio;
  quality: BurnQuality;
  subtitleFileId: string;
  useOriginal?: boolean;
  snippetId?: string;
  noSubtitles: boolean;
}

export interface IBatchSubtitle extends IBatchBaseFile {
  subtitleFileId: string;
  type: SubtitleFormat;
  language: MediaLanguage;
  snippetId?: string;
}

export interface IBatchAudio extends IBatchBaseFile {
  extension: AudioFormat;
  quality: BurnQuality;
}

export type IBatchFile = IBatchVideo | IBatchSubtitle | IBatchAudio;

interface BatchDownloadResponse {
  batchId: string;
  jobs: MediaJob[];
}
export const startBatchDownload = async (
  mediaId: string,
  batch: IBatchFile[],
  batchId?: string,
  googleDrive?: GoogleDriveDownloadInfo
): Promise<BatchDownloadResponse> => {
  const { data } = await axios.post<BatchDownloadResponse>(
    `/media/${mediaId}/download`,
    { batch, batchId, googleDrive },
    { baseURL: baseURL, headers: { "x-access-token": await getAccessToken() } }
  );

  return data;
};

export const downloadBatchId = async (
  batchId: string,
  media: BasicMedia,
  analyticsData: UnknownObject
): Promise<void> => {
  const batchJobs = media.jobs.filter((j) => j.batchId === batchId);

  if (!batchJobs.length) {
    return;
  }

  notificationSuccess(
    `We'll start downloading your ${pluralize(
      batchJobs.length,
      "file"
    )} soon...`
  );

  const batchFiles = batchJobs.map((j) => {
    return getFileFromJob(media, j.id);
  });

  for await (const file of batchFiles) {
    if (!file) {
      return;
    }

    if (file.burnt) {
      await downloadBurntMedia({
        mediaId: media.id,
        burntFile: file,
        analyticsData
      });
    } else if (isSubtitleFileType(file.type) && file.languageId) {
      const filename = file?.downloadName
        ? `${file.downloadName}.${file.extension}`
        : file.filename;
      await downloadSubtitles(media.id, file.id, filename, analyticsData);
    }
  }
};

// Private functions

interface GetBurnProgressResponse {
  message: string;
  jobId: string;
  processStart: string;
  progress?: {
    overallProgress: number;
    tasks: BurningTask[];
  };
  pending: boolean;
}

const getBurnProgress = async (mediaId: string) => {
  return await axios.get<GetBurnProgressResponse>(
    `/${getAccountId()}/transcriptions/${mediaId}/status`,
    { baseURL: baseURL, headers: { "x-access-token": await getAccessToken() } }
  );
};

const getMediaParams = (folderId?: FolderId): MediaRequestParams => {
  switch (folderId) {
    case DefaultFoldersId.All:
      return { includeSharedWithMe: true };
    case DefaultFoldersId.AllPrivate:
      return { isPublic: false };
    case DefaultFoldersId.AllShared:
      return { isPublic: true };
    case DefaultFoldersId.AllSharedWithMe:
      return { sharedWithMe: true };
    case null:
      return {};
    default:
      return { folderId };
  }
};
