// TODO: Move this file somewhere else, no longer onboarding stuff
import classNames from "classnames";
import React from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { updateSettings } from "../../../api/auth.service";
import { inviteMembers } from "../../../api/invitation.service";
import { EN } from "../../../assets/i18n/en";
import { Button } from "../../../components/button/button";
import { notificationError } from "../../../components/notification";
import { useAccounts } from "../../../hooks/use-accounts";
import { useAnalyticsWithAuth } from "../../../hooks/use-analytics-with-auth";
import { Invitation, RoleName } from "../../../interfaces/account";
import { DASHBOARD_PATH } from "../../../routes";
import { emailPattern } from "../../../utils/regex-patterns";
import { compareStrings } from "../../../utils/strings";
import styles from "../onboarding.module.scss";

type FormValues = {
  invites: Invitation[];
};

export const TrialInviteContainer: React.FC = ({}) => {
  const { user } = useAccounts();
  const { currentAccount } = useAccounts();
  const [isLoading, setLoading] = React.useState(false);
  const { trackEventWithAuth } = useAnalyticsWithAuth();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    control
  } = useForm<FormValues>({
    defaultValues: {
      invites: [
        { id: "", email: "", role: RoleName.Editor },
        { id: "", email: "", role: RoleName.Editor },
        { id: "", email: "", role: RoleName.Editor }
      ]
    },
    mode: "all"
  });

  const { fields, append } = useFieldArray({
    name: "invites",
    control
  });

  const allMembers = currentAccount?.members ?? [];
  const allInvitations = currentAccount?.invitations ?? [];
  const allUsers = [...allMembers, ...allInvitations];
  const teamCapacity = currentAccount?.teamCapacity ?? 1;
  const availableCapacity = teamCapacity - allUsers.length;

  const isInviteValid = (email = ""): boolean => {
    return email.trim().length > 0;
  };

  const onSubmit = (data: FormValues) => {
    const validInvites = data.invites.filter(({ email }) => {
      return isInviteValid(email);
    });

    inviteEmails(validInvites);
  };

  const inviteEmails = async (invites: Invitation[]) => {
    setLoading(true);
    try {
      await inviteMembers(invites);
      await updateSettings({
        trialOnboarding: true
      });
      trackEventWithAuth("Onboarding invitations", {
        invitations: invites.length
      });
      setLoading(false);
      navigate(DASHBOARD_PATH);
    } catch (error) {
      setLoading(false);
      notificationError(EN.error.defaultMessage);
    }
  };

  const invites = watch("invites") ?? [];
  const lastInvite = invites[invites.length - 1];
  const isLastInviteValid = isInviteValid(lastInvite?.email);
  const canAddNewInvite =
    isLastInviteValid && availableCapacity > fields.length;

  const renderInviteFields = fields.map((field, index) => {
    return (
      <div key={field.id}>
        <input
          type="email"
          className="form-control mb-2"
          placeholder="Email address"
          {...register(`invites.${index}.email` as const, {
            required: false,
            pattern: emailPattern,
            validate: {
              isOwner: (email: string) => {
                return (
                  !compareStrings(email, user?.email) ||
                  "You can't invite yourself"
                );
              },
              hasMember: (email: string) => {
                return (
                  !allUsers.some((i) => email === i.email) ||
                  "You already invited this user"
                );
              }
            }
          })}
        />

        {Boolean(errors.invites?.[index]?.email?.message) && (
          <p key={index} className="small mt-1 text-feedback-error">
            {errors.invites?.[index]?.email?.message}
          </p>
        )}
      </div>
    );
  });

  return (
    <div className={classNames(styles["trial-invite-container"], "mt-4")}>
      <h5 className="mb-0">Invite teammates to your workspace</h5>
      <form onSubmit={handleSubmit(onSubmit)}>
        {renderInviteFields}

        <div className="d-flex flex-column w-100">
          {canAddNewInvite && (
            <Button
              className="my-2"
              secondary
              disabled={isLoading}
              onClick={() =>
                append({ id: "", email: "", role: RoleName.Editor })
              }
            >
              + Add more teammates
            </Button>
          )}

          <Button
            className="my-2"
            primary
            type="submit"
            disabled={Boolean(watch().invites[0].email.trim().length === 0)}
            loading={isLoading}
          >
            Continue
          </Button>
        </div>
      </form>
    </div>
  );
};
