import axios from "axios";
import { AuthData } from "pusher-js/types/src/core/auth/options";
import config from "../config";
import { getAccessToken } from "./auth.service";
import { handleError } from "./handle-error";

const baseURL = `${config.apiUrl}/api/v1`;

export const authorisePusher = async (
  name: string,
  socketId: string
): Promise<AuthData | undefined> => {
  try {
    const { data } = await axios.post<AuthData>(
      `/pusher/auth`,
      { channel_name: name, socket_id: socketId },
      {
        baseURL,
        headers: {
          "x-access-token": await getAccessToken()
        }
      }
    );

    return data;
  } catch (error) {
    handleError(error);
  }
};
