import React from "react";
import { getCustomer } from "../../../api/billing.service";
import { Button } from "../../../components/button/button";
import { BrowseIcon } from "../../../components/icons/icons";
import { LoadingIcon } from "../../../components/loading-icon/loading-icon";
import { CancelModal } from "../../../components/plans/cancel-modal";
import { SettingsCardContainer } from "../../../components/settings-card/settings-card-container";
import { useAccounts } from "../../../hooks/use-accounts";
import { useAnalyticsWithAuth } from "../../../hooks/use-analytics-with-auth";
import { useModal } from "../../../hooks/use-modal";
import { usePlan } from "../../../hooks/use-plan";
import { SublyPlan } from "../../../interfaces/billing";
import { ModalType } from "../../../interfaces/modal-type";
import { PLANS_PATH } from "../../../routes";
import styles from "./plan-card.module.scss";

export const ChangePlansCard: React.FC = () => {
  const { user, isAdmin, isLoading, subscription, deal } = useAccounts();
  const { plan } = usePlan();
  const { trackEventWithAuth } = useAnalyticsWithAuth();
  const [showCancelModal, hideCancelModal] = useModal(ModalType.CancelModal);

  React.useEffect(() => {
    if (!user) {
      return;
    }

    getCustomer({ force: false });
  }, [user]);

  if (!isAdmin || deal) {
    return null;
  }

  if (isLoading) {
    return (
      <SettingsCardContainer title="Change Plans">
        <div className="mt-4">
          <LoadingIcon />
        </div>
      </SettingsCardContainer>
    );
  }

  if (!subscription) {
    return (
      <SettingsCardContainer title="Change Plans">
        <div className={styles["change-plans"]}>
          Looking to upgrade or downgrade?
          <div className={styles["plans-button"]}>
            <Button
              to={PLANS_PATH}
              secondary
              onClick={() => trackEventWithAuth("Click on Upgrade")}
            >
              <BrowseIcon className="mr-2" />
              Browse Plans
            </Button>
          </div>
        </div>
      </SettingsCardContainer>
    );
  }

  const handleCancelPlan = () => {
    showCancelModal(
      <CancelModal
        subscriptionId={subscription.id}
        cancelPlan={SublyPlan.Free}
        currentPlan={plan}
        onDismiss={hideCancelModal}
      />
    );
  };

  return (
    <SettingsCardContainer title="Change Plans">
      <div className={styles["change-plans"]}>
        Looking to upgrade or downgrade?
        <div className={styles["plans-button"]}>
          <Button
            to={PLANS_PATH}
            secondary
            onClick={() => trackEventWithAuth("Click on Upgrade")}
          >
            <BrowseIcon className="mr-2" />
            Browse Plans
          </Button>
          <Button danger onClick={handleCancelPlan}>
            Cancel Plan
          </Button>
        </div>
      </div>
    </SettingsCardContainer>
  );
};
