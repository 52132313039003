import classNames from "classnames";

import React from "react";
import styles from "./banner.module.scss";

interface BaseBannerProps {
  message: string;
}

export const BaseBanner: React.FC<BaseBannerProps> = ({ message }) => (
  <div
    className={classNames(
      "d-block py-2",
      styles["promo-banner"],
      styles["bg-warning"]
    )}
  >
    <p className="text-center text-white mb-0 px-2 small">{message}</p>
  </div>
);
