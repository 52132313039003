import React from "react";
import { DashboardSidebar } from "../containers/dashboard/sidebar/dashboard-sidebar";
import { Navbar } from "../containers/navbar/navbar";
import { TopBannerContainer } from "../containers/top-banner/top-banner-container";
import { NavProvider } from "../contexts/nav-context";
import { TopBannerContext } from "../contexts/top-banner.context";

interface AppLayoutProps {
  hideNavbarRight?: boolean;
  hideSidebar?: boolean;
  hideTopBanner?: boolean;
}
export const AppLayout: React.FC<AppLayoutProps> = ({
  hideNavbarRight = false,
  hideSidebar = false,
  hideTopBanner = false,
  children
}) => {
  const { setHideTopBanner } = React.useContext(TopBannerContext);

  React.useEffect(() => {
    setHideTopBanner(hideTopBanner);
  }, [hideTopBanner]);

  return (
    <NavProvider>
      <TopBannerContainer hideTopBanner={hideTopBanner}>
        <div className="d-flex flex-row">
          {!hideSidebar && <DashboardSidebar />}
          <main className="flex-grow-1 full-height overflow-auto">
            <div className="px-3">
              <Navbar hideNavbarRight={hideNavbarRight} />
              {children}
            </div>
          </main>
        </div>
      </TopBannerContainer>
    </NavProvider>
  );
};
