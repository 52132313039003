import React from "react";
import { useAnalyticsWithAuth } from "../../../hooks/use-analytics-with-auth";
import { useModal } from "../../../hooks/use-modal";
import { ModalType } from "../../../interfaces/modal-type";
import { TutorialMedia, Tutorials } from "./tutorial-media";
import { VideoTutorialModal } from "./video-tutorial-modal";
import { TutorialMediaCard } from "../../../components/media-card/tutorial-media-card";

export const VideoTutorials: React.FC = () => {
  const [showModal, hideModal] = useModal(ModalType.HelpVideo);
  const { trackEventWithAuth } = useAnalyticsWithAuth();

  const showUploadModal = (tutorialVideo: TutorialMedia) => {
    showModal(
      <VideoTutorialModal
        tutorialVideo={tutorialVideo}
        closeModal={handleCloseModal}
      />
    );
    const tutorialVideoName = tutorialVideo.name;
    trackEventWithAuth("Clicked on tutorial video", { tutorialVideoName });
  };

  const handleCloseModal = () => {
    hideModal();
  };

  const renderTutorials = Tutorials.map((t) => {
    return (
      <div className="col-sm-6 col-md-4 col-lg-3" key={t.id}>
        <div onClick={() => showUploadModal(t)}>
          <TutorialMediaCard tutorialVideo={t} />
        </div>
      </div>
    );
  });

  return <div className="d-flex flex-row flex-wrap">{renderTutorials}</div>;
};
