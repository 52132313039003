import classNames from "classnames";
import React from "react";

type IntrinsicInputProps = JSX.IntrinsicElements["input"];
interface InputProps extends IntrinsicInputProps {
  label?: string;
  hasError?: boolean;
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ type = "text", className, label, hasError, ...props }, ref) => {
    return (
      <>
        {label && (
          <label className="mb-1" htmlFor={props.id || label}>
            {label}
          </label>
        )}
        <input
          type={type}
          className={classNames(
            "form-control",
            { "is-invalid": hasError },
            className
          )}
          ref={ref}
          id={props.id || label}
          {...props}
        />
      </>
    );
  }
);
