import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { updateAccountName } from "../../api/account.service";
import { getCustomer } from "../../api/billing.service";
import { deleteMember } from "../../api/members.service";
import { EN } from "../../assets/i18n/en";
import { Button } from "../../components/button/button";
import { Fieldset } from "../../components/form-controls/fieldset";
import { LoadingIcon } from "../../components/loading-icon/loading-icon";
import { notificationError } from "../../components/notification";
import { SettingsCardContainer } from "../../components/settings-card/settings-card-container";
import { AuthContext } from "../../contexts/auth.context";
import { useAccounts } from "../../hooks/use-accounts";
import { DASHBOARD_PATH } from "../../routes";

type AccountNameForm = {
  accountName: string;
};
export const WorkspaceCard: React.FC = () => {
  const { user, isLoading, currentAccount, isAdmin } = useAccounts();
  const [loading, setLoading] = React.useState(false);
  const { register, handleSubmit, setValue } = useForm<AccountNameForm>({
    defaultValues: {
      accountName: currentAccount?.accountName
    }
  });

  React.useEffect(() => {
    if (!user) {
      return;
    }

    getCustomer({ force: false });
  }, [user]);

  React.useEffect(() => {
    if (currentAccount?.accountName) {
      setValue("accountName", currentAccount?.accountName);
    }
  }, [currentAccount?.accountName]);

  if (isLoading) {
    return (
      <SettingsCardContainer title="Workspace">
        <div className="mt-4">
          <LoadingIcon />
        </div>
      </SettingsCardContainer>
    );
  }

  const onSubmit = async ({ accountName }: AccountNameForm) => {
    if (!isAdmin) {
      return;
    }

    setLoading(true);

    await updateAccountName(accountName);

    setLoading(false);
  };

  return (
    <SettingsCardContainer title="Workspace">
      <div className="mt-4">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Fieldset title="Workspace name">
            <input
              type="text"
              className="form-control"
              {...register("accountName", { required: true })}
              disabled={!isAdmin}
            />
          </Fieldset>

          <div className="d-flex justify-content-end">
            <WorskpaceCardActions loading={loading} />
          </div>
        </form>
      </div>
    </SettingsCardContainer>
  );
};

interface WorskpaceCardActionsProps {
  loading: boolean;
}
const WorskpaceCardActions: React.FC<WorskpaceCardActionsProps> = ({
  loading
}) => {
  const { isAdmin, isOwner, switchAccount, accounts, user, currentAccount } =
    useAccounts();
  const { logout } = React.useContext(AuthContext);
  const [isLoading, setLoading] = React.useState(false);
  const navigate = useNavigate();

  const handleLeaveWorkspace = async () => {
    if (!user?.id) {
      notificationError(EN.error.defaultMessage);
      return;
    }

    setLoading(true);

    await deleteMember(user.id);

    const anotherAccount = accounts.find(
      (a) => a.accountId !== currentAccount?.accountId
    );

    if (!anotherAccount) {
      logout();
      return;
    }

    await switchAccount(anotherAccount.accountId);
    navigate(DASHBOARD_PATH);
  };

  if (!isAdmin) {
    return (
      <Button danger loading={isLoading} onClick={handleLeaveWorkspace}>
        Leave workspace
      </Button>
    );
  }

  if (isOwner) {
    return (
      <Button
        primary
        type={isAdmin ? "submit" : undefined}
        loading={loading}
        disabled={!isAdmin}
      >
        Save
      </Button>
    );
  }

  return (
    <>
      <Button
        danger
        loading={isLoading}
        onClick={handleLeaveWorkspace}
        className="mr-2"
      >
        Leave workspace
      </Button>
      <Button
        primary
        type={isAdmin ? "submit" : undefined}
        loading={loading}
        disabled={!isAdmin}
      >
        Save
      </Button>
    </>
  );
};
