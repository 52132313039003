import React from "react";
import { VideoTutorials } from "../../containers/settings/video-tutorials/video-tutorials";
import { SettingsLayout } from "../../layouts/settings/settings.layout";
import { FAQ_URL } from "../../routes";

export const HelpPage: React.FC = () => {
  return (
    <SettingsLayout>
      <div className="container-fluid p-4">
        <div className="p-2 mb-2">
          <h3>How to use Subly</h3>
          <p className="small">
            For frequently asked questions,{" "}
            <a href={FAQ_URL} target="_blank" rel="noopener noreferrer">
              click here
            </a>
          </p>
        </div>
        <VideoTutorials />
      </div>
    </SettingsLayout>
  );
};
