import classNames from "classnames";
import React from "react";

interface TableProps {
  responsive?: boolean;
  striped?: boolean;
  header?: string[];
  className?: string;
}
export const Table: React.FC<TableProps> = ({
  responsive,
  header,
  striped,
  className,
  children
}) => {
  return (
    <TableContainer
      className={classNames({ "table-striped": striped }, className)}
      responsive={responsive}
    >
      <TableHeader header={header} />
      {children}
    </TableContainer>
  );
};

interface TableContainerProps {
  responsive?: boolean;
  className?: string;
}
const TableContainer: React.FC<TableContainerProps> = ({
  responsive,
  className,
  children
}) => {
  return (
    <div className={classNames({ "table-responsive": responsive })}>
      <table className={classNames("table", className)}>{children}</table>
    </div>
  );
};

interface TableHeaderProps {
  header?: string[];
}
const TableHeader: React.FC<TableHeaderProps> = ({ header }) => {
  if (!header?.length) {
    return null;
  }

  const renderHeader = header.map((h, i) => {
    return (
      <th scope="col" key={i}>
        {h}
      </th>
    );
  });

  return (
    <thead>
      <tr>{renderHeader}</tr>
    </thead>
  );
};
