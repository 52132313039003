import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { createBusinessAccount } from "../../../api/account.service";
import { Button } from "../../../components/button/button";
import { useAccounts } from "../../../hooks/use-accounts";
import { PLANS_PATH } from "../../../routes";
import { Modal } from "../../modal/modal";

interface CreateWorkspaceModalProps {
  onDismiss: () => void;
}
export const CreateWorkspaceModal: React.FC<CreateWorkspaceModalProps> = ({
  onDismiss
}) => {
  return (
    <Modal onDismiss={onDismiss} hasCloseIcon>
      <CreateWorkspaceModalContainer onDismiss={onDismiss} />
    </Modal>
  );
};
type WorkspaceForm = {
  accountName: string;
};
const CreateWorkspaceModalContainer: React.FC<CreateWorkspaceModalProps> = ({
  onDismiss
}) => {
  const navigate = useNavigate();

  const [isLoading, setLoading] = React.useState(false);
  const { register, handleSubmit } = useForm<WorkspaceForm>();
  const { switchAccount } = useAccounts();

  const handleCreate = async (form: WorkspaceForm) => {
    setLoading(true);

    const { accountId } = await createBusinessAccount(form);
    await switchAccount(accountId);

    onDismiss();
    // navigate(PLANS_PATH, { newAccount: form.accountName }); // TODO: not sure what this is supposed to do and doesn't seem to be in use
    navigate(PLANS_PATH);
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <h5>Create a new workspace</h5>
      </div>
      <form onSubmit={handleSubmit(handleCreate)}>
        <div className="mt-4 mb-3">
          <label htmlFor="accountName">Workspace name</label>
          <input
            type="text"
            className="form-control"
            autoFocus
            {...register("accountName", { required: true })}
          />
        </div>

        <Button primary type="submit" loading={isLoading} isFull>
          Create
        </Button>
      </form>
    </>
  );
};
