import React from "react";
import { mdiHelpCircleOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { FAQ_URL } from "../../routes";

interface FaqButtonProps {
  className?: string;
}

export const FaqButton: React.FC<FaqButtonProps> = ({ className }) => (
  <a
    href={FAQ_URL}
    target="_blank"
    rel="noopener noreferrer"
    className={className}
  >
    <Icon path={mdiHelpCircleOutline} size="22px" color="var(--tertiary)" />
  </a>
);
