import classNames from "classnames";
import React from "react";
import { getCustomer } from "../../../api/billing.service";
import { Button } from "../../../components/button/button";
import {
  CheckoutSuccessCard,
  StorageCheckoutCard
} from "../../../components/extra-storage/storage-checkout-card";
import { Divider } from "../../../components/divider/divider";
import { LoadingIcon } from "../../../components/loading-icon/loading-icon";
import { SettingsCardContainer } from "../../../components/settings-card/settings-card-container";
import { useAccounts } from "../../../hooks/use-accounts";
import { usePlan } from "../../../hooks/use-plan";
import { getPlanData, STORAGE_AMOUNT } from "../../../utils/plans";
import { formatBytes } from "../../../utils/storage-size";
import { StorageAmountToggle } from "../../../components/extra-storage/storage-toggle";
import { DatabaseIcon } from "@heroicons/react/outline";
import styles from "./storage-card.module.scss";

export const StorageCard: React.FC = () => {
  const { user, isAdmin, isLoading, subscription, deal, isLoaded } =
    useAccounts();
  const { plan, totalStorageLabel, isTrial } = usePlan();
  const planData = getPlanData(plan);
  const { currentAccount } = useAccounts();
  const [showStorageOption, setShowStorageOption] =
    React.useState<boolean>(false);
  const [storageAmount, setStorageAmount] = React.useState<number>(0);
  const [showSuccess, setSuccess] = React.useState<boolean>(false);
  const totalStorage = STORAGE_AMOUNT * storageAmount;

  React.useEffect(() => {
    if (!user) {
      return;
    }

    getCustomer({ force: false });
  }, [user]);

  if (!isAdmin || !subscription || deal || isTrial) {
    return null;
  }

  if (isLoading) {
    return (
      <SettingsCardContainer title="Storage">
        <div className="mt-4">
          <LoadingIcon />
        </div>
      </SettingsCardContainer>
    );
  }

  if (!showStorageOption) {
    return (
      <div className={styles["storage-container"]}>
        <SettingsCardContainer title="Storage">
          <StorageBalance />

          <Divider className={styles["divider"]} />

          <span className={styles["storage-text"]}>
            Storage available includes any extra storage purchased. Your{" "}
            {planData.name} plan includes {totalStorageLabel} of monthly
            storage. Increase your monthly storage from only $4.99 per 50GB.{" "}
          </span>

          <div className={styles["button-box"]}>
            <Button secondary onClick={() => setShowStorageOption(true)}>
              <DatabaseIcon className={styles["storage-icon"]} />
              Increase storage
            </Button>
          </div>
        </SettingsCardContainer>

        {showSuccess && !isLoading && isLoaded && (
          <>
            <div className={styles["mobile-view"]}>
              <CheckoutSuccessCard />
            </div>

            <div className={styles["desktop-view"]}>
              <CheckoutSuccessCard />
            </div>
          </>
        )}
      </div>
    );
  }

  const extraStorage = formatBytes(currentAccount?.additionalStorageBytes ?? 0);
  const extraStorageText = `${extraStorage.size} ${extraStorage.units}`;
  return (
    <div className={styles["storage-container"]}>
      <SettingsCardContainer title="Storage">
        <StorageBalance />
        <p className={classNames(styles["storage-text"], "my-3")}>
          Storage available includes extra storage purchased.
        </p>
        <Divider className={styles["divider"]} />

        <p className={styles["balance"]}>
          <span>Extra storage purchased:</span> <span>{extraStorageText}</span>
        </p>

        <span className={styles["storage-text"]}>
          Your {planData.name} plan includes {totalStorageLabel} of storage.
          Increase your storage for only $4.99 per 50GB.{" "}
        </span>

        <StorageAmountToggle
          amount={storageAmount}
          setAmount={setStorageAmount}
          totalAmount={totalStorage}
        />

        <div className={styles["mobile-view"]}>
          {storageAmount > 0 && (
            <StorageCheckoutCard
              subscription={subscription}
              quantity={storageAmount}
              setSuccess={setSuccess}
              setShowStorageOption={setShowStorageOption}
            />
          )}
        </div>
      </SettingsCardContainer>

      <div className={styles["desktop-view"]}>
        {storageAmount > 0 && (
          <StorageCheckoutCard
            subscription={subscription}
            quantity={storageAmount}
            setSuccess={setSuccess}
            setShowStorageOption={setShowStorageOption}
          />
        )}
      </div>
    </div>
  );
};

const StorageBalance: React.FC = () => {
  const { availableStorage, storageBytesUsed } = usePlan();
  const { size, units } = formatBytes(storageBytesUsed);
  const storageUsedText = `${size} ${units}`;

  const available = formatBytes(availableStorage);
  const availableText = `${available.size} ${available.units}`;

  return (
    <>
      <p className={styles.balance}>
        <span>Storage used:</span> <span>{storageUsedText}</span>
      </p>
      <p className={styles.balance}>
        <span>Storage available:</span> <span>{availableText}</span>
      </p>
    </>
  );
};
