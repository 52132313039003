import axios from "axios";
import { notificationSuccess } from "../components/notification";
import config from "../config";
import { getAccountId } from "../config/settings/settings.service";
import { Invitation, RoleName } from "../interfaces/account";
import { accountQuery, accountStore } from "../state/account";
import { authQuery } from "../state/auth/auth.query";
import { authStore } from "../state/auth/auth.store";
import { getAccessToken, getCurrentUser } from "./auth.service";
import { getCustomer } from "./billing.service";
import { handleError } from "./handle-error";

const baseURL = `${config.apiUrl}/api/v1`;

interface InviteMembersResponse {
  invitations: Invitation[];
}
export const inviteMembers = async (invites: Invitation[]): Promise<void> => {
  const accountId = getAccountId();

  if (!accountId) {
    return;
  }

  try {
    const {
      data: { invitations }
    } = await axios.post<InviteMembersResponse>(
      `/${accountId}/invite`,
      { invites },
      {
        baseURL: baseURL,
        headers: { "x-access-token": await getAccessToken() }
      }
    );

    accountStore.update((s) => ({
      ...s,
      invitations: [...s.invitations, ...invitations]
    }));
  } catch (error) {
    handleError(error);
    throw new Error(error);
  }
};

export const updateInvitationRole = async (
  id: string,
  role: RoleName
): Promise<void> => {
  const accountId = getAccountId();

  if (!accountId) {
    return;
  }

  try {
    await axios.put(
      `/${accountId}/invitation/${id}`,
      { role },
      {
        baseURL: baseURL,
        headers: { "x-access-token": await getAccessToken() }
      }
    );

    const invitations = accountQuery.getValue().invitations?.map((i) => {
      if (i.id === id) {
        return { ...i, role };
      }

      return i;
    });

    accountStore.update({ invitations });
  } catch (error) {
    handleError(error);
  }
};

export const deleteInvitation = async (id: string): Promise<void> => {
  const accountId = getAccountId();

  if (!accountId) {
    return;
  }

  try {
    await axios.delete(`/${accountId}/invitation/${id}`, {
      baseURL: baseURL,
      headers: { "x-access-token": await getAccessToken() }
    });

    const invitations = accountQuery
      .getValue()
      .invitations?.filter((i) => i.id !== id);

    accountStore.update({ invitations });
  } catch (error) {
    handleError(error);
  }
};

export const resendInvitation = async (id: string): Promise<void> => {
  const accountId = getAccountId();

  if (!accountId) {
    return;
  }

  try {
    await axios.put(`/${accountId}/invitation/${id}/resend`, null, {
      baseURL: baseURL,
      headers: { "x-access-token": await getAccessToken() }
    });
  } catch (error) {
    handleError(error);
  }
};

export const acceptInvitation = async (
  id: string,
  accountName: string
): Promise<void> => {
  const accountId = getAccountId();

  if (!accountId) {
    return;
  }

  try {
    await axios.put(`/invitation/${id}/accept`, null, {
      baseURL: baseURL,
      headers: { "x-access-token": await getAccessToken() }
    });

    await getCurrentUser();
    await getCustomer({ force: true });

    const user = authQuery.user;

    if (user?.invitations?.length) {
      user.invitations = user.invitations?.filter((i) => i.id !== id);

      authStore.update({ user });
    }

    notificationSuccess(`You now have access to ${accountName} workspace!`);
  } catch (error) {
    handleError(error);
  }
};
