import React from "react";
import { useForm } from "react-hook-form";
import { Button } from "../../../components/button/button";
import { Fieldset } from "../../../components/form-controls/fieldset";
import { ModalType } from "../../../interfaces/modal-type";
import { updateFolder } from "../../../state/folders/folders.service";
import { Folder } from "../../../state/folders/folders.store";
import { Modal } from "../../modal/modal";

interface RenameFolderModalProps {
  folder: Folder;
  hideModal: (modal: ModalType) => void;
}
export const RenameFolderModal: React.FC<RenameFolderModalProps> = ({
  folder,
  hideModal
}) => {
  const closeModal = () => hideModal(ModalType.RenameFolder);

  return (
    <Modal onDismiss={closeModal} hasCloseIcon>
      <RenameFolderFolderContainer folder={folder} closeModal={closeModal} />
    </Modal>
  );
};

type RenameForm = { name: string };
interface RenameFolderFolderContainerProps {
  folder: Folder;
  closeModal: () => void;
}
const RenameFolderFolderContainer: React.FC<RenameFolderFolderContainerProps> =
  ({ folder, closeModal }) => {
    const [isLoading, setLoading] = React.useState(false);
    const { register, handleSubmit } = useForm<RenameForm>({
      defaultValues: { name: folder.name }
    });

    const handleSave = async ({ name }: RenameForm) => {
      setLoading(true);

      await updateFolder({ ...folder, name });

      closeModal();
    };

    return (
      <>
        <h5 className="mb-3 d-flex align-items-center">Rename folder</h5>

        <form onSubmit={handleSubmit(handleSave)}>
          <Fieldset title="File name">
            <input
              className="form-control"
              {...register("name", { required: true })}
            />
          </Fieldset>

          <div className="d-flex justify-content-end">
            <Button secondary onClick={closeModal}>
              Cancel
            </Button>
            <Button
              primary
              className="ml-2 px-4"
              loading={isLoading}
              type="submit"
            >
              Rename
            </Button>
          </div>
        </form>
      </>
    );
  };
