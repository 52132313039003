import React, { useEffect, useState } from "react";
import countries from "typed-countries";
import { removeBillingDetails } from "../../../api/billing.service";
import { BlankBillingCard } from "../../../components/blank-billing-card/blank-billing-card";
import { Button } from "../../../components/button/button";
import { LoadingIcon } from "../../../components/loading-icon/loading-icon";
import { BillingDetails } from "../../../interfaces/billing";
import { ADD_BILLING_DETAILS_PATH } from "../../../routes";
import { accountQuery } from "../../../state/account";
import { Modal } from "../../modal/modal";

export const BillingDetailsCard: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState<BillingDetails>();

  useEffect(() => {
    const account$ = accountQuery
      .select(["billing", "loading"])
      .subscribe(({ billing, loading }) => {
        if (!billing) {
          setDetails(undefined);
          setLoading(loading);
          return;
        }

        setLoading(loading || billing.loading);
        setDetails(billing.details);
      });

    return () => account$.unsubscribe();
  }, []);

  const [showRemoveModal, setShowRemoveModal] = useState(false);

  if (loading) {
    return (
      <BlankBillingCard to="#">
        <LoadingIcon />
      </BlankBillingCard>
    );
  }

  if (!details || !details.address) {
    return (
      <BlankBillingCard to={ADD_BILLING_DETAILS_PATH}>
        <p>
          <b>Add billing details</b> so you can receive tax invoices for your
          Subly subscription.
        </p>
      </BlankBillingCard>
    );
  }

  const country =
    countries.find((c) => c.iso === details?.address?.country)?.name ||
    details.address.country;

  return (
    <div className="account-card card my-4">
      <div className="card-body p-4">
        <h4 className="mt-2">Billing details</h4>
        <div className="mt-4">
          <div>{details.name}</div>
          <div>{details.address.line1}</div>
          {details.address.line2 && <div>{details.address.line2}</div>}
          <div>
            {details.address.city} {details.address.state}{" "}
            {details.address.postalCode}
          </div>
          <div>{country}</div>
        </div>
      </div>
      <div className="card-footer py-3">
        <div className="d-flex align-items-center justify-content-end">
          <Button
            tertiary
            to="#"
            onClick={() => setShowRemoveModal(true)}
            className="mr-5"
          >
            Remove
          </Button>
          <Button tertiary to={ADD_BILLING_DETAILS_PATH}>
            Update
          </Button>
        </div>
      </div>

      <RemoveBillingDetailModal
        visible={showRemoveModal}
        confirm={removeBillingDetails}
        close={() => setShowRemoveModal(false)}
      />
    </div>
  );
};

interface RemoveBillingDetailModalProps {
  visible: boolean;
  confirm: () => void;
  close: () => void;
}

const RemoveBillingDetailModal: React.FC<RemoveBillingDetailModalProps> = ({
  visible,
  confirm,
  close
}) => {
  if (!visible) {
    return null;
  }

  return (
    <Modal>
      <h5>Remove billing details?</h5>
      <p>
        You won’t be able to see your billing information on future Subly
        invoices.
      </p>
      <div className="d-flex">
        <Button secondary onClick={close} className="ml-auto mr-2">
          Cancel
        </Button>
        <Button danger onClick={confirm}>
          Remove
        </Button>
      </div>
    </Modal>
  );
};
