import React, { useState } from "react";
import classNames from "classnames";
import { useForm } from "react-hook-form";
import { passwordPattern } from "../../utils/regex-patterns";
import { PasswordRules } from "../password-rules/password-rules";
import { Button } from "../button/button";
import { TermsCheckbox } from "../terms-checkbox/terms-checkbox";

export interface SetPasswordForm {
  password: string;
}

interface SetPasswordProps {
  loading: boolean;
  onSubmit: (formData: SetPasswordForm) => void;
}

export const SetPassword: React.FC<SetPasswordProps> = ({
  loading,
  onSubmit
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch
  } = useForm<SetPasswordForm>();
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showTermsError, setShowTermsError] = useState(false);

  const handleFormSubmit = (data: SetPasswordForm) => {
    if (!termsAccepted) {
      setShowTermsError(true);
      return;
    }

    onSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} className="auth-form">
      <h2 className="title">Choose your password to access Subly</h2>
      <div className="form-container">
        <div className="form-group">
          <label htmlFor="email">Password</label>

          <input
            placeholder="Input your password"
            className={classNames("form-control", {
              "is-invalid": errors.password
            })}
            type="password"
            {...register("password", {
              required: true,
              pattern: passwordPattern
            })}
          />
          <PasswordRules
            value={watch("password")}
            className="d-flex flex-row flex-wrap"
          />
        </div>

        <TermsCheckbox
          accepted={termsAccepted}
          onAccepted={setTermsAccepted}
          showError={showTermsError}
        />
        <Button
          primary
          className="btn-block mt-3"
          type="submit"
          loading={loading}
        >
          Complete Registration
        </Button>
      </div>
    </form>
  );
};
