import { mdiBell, mdiChevronDown } from "@mdi/js";
import Icon from "@mdi/react";
import classNames from "classnames";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { acceptInvitation } from "../../../api/invitation.service";
import {
  Dropdown,
  DropdownButton,
  DropdownContext,
  DropdownDivider,
  DropdownItem,
  DropdownMenu
} from "../../../components/dropdown/dropdown";
import { LoadingIcon } from "../../../components/loading-icon/loading-icon";
import { ProfileImageIcon } from "../../../components/profile-image-icon/profile-image-icon";
import { AuthContext } from "../../../contexts/auth.context";
import { useAccounts } from "../../../hooks/use-accounts";
import { useAnalyticsWithAuth } from "../../../hooks/use-analytics-with-auth";
import { UserInvitation } from "../../../interfaces/user";
import {
  ACCOUNT_PATH,
  APPS_HOME_PATH,
  BILLING_PATH,
  FAQ_URL,
  HELP_PATH,
  PLANS_PATH,
  REFERRAL_PATH,
  TEAM_PATH
} from "../../../routes";
import config from "../../../config";
import styles from "./account-dropdown.module.scss";
import { usePlan } from "../../../hooks/use-plan";

interface AccountDropdownProps {
  dropdownMenu: React.RefObject<HTMLLIElement>;
}
export const AccountDropdown: React.FC<AccountDropdownProps> = ({
  dropdownMenu
}) => {
  const { logout, userPicture } = useContext(AuthContext);
  const { plan } = usePlan();
  const { user, isAdmin, deal } = useAccounts();
  const { trackEventWithAuth } = useAnalyticsWithAuth();

  if (!user) {
    return null;
  }

  const enableZoomForUser = user?.id === "043a034d-30d9-43b9-af5a-c4acbab3b122";

  const handleClick = (event: string) => {
    trackEventWithAuth(event);
  };

  return (
    <Dropdown>
      <DropdownButton
        dropdownEl={dropdownMenu}
        className={styles["dropdown-button"]}
      >
        <Link
          to="#"
          className="nav-link nav-link-dropdown d-flex align-items-center"
        >
          <ProfileImage picture={userPicture} name={user.name} />{" "}
          <Icon
            path={mdiChevronDown}
            size="1.2em"
            color="var(--text-primary)"
          />
        </Link>
      </DropdownButton>

      <DropdownMenu isRight className={styles["dropdown-menu"]}>
        <DropdownItem
          to={ACCOUNT_PATH}
          onClick={() => handleClick("Click on user name")}
          className={styles.item}
        >
          <AccountDropdownInfoMenu
            userPicture={userPicture}
            name={user.name}
            email={user.email}
          />
        </DropdownItem>

        <UserInvitations />

        <DropdownItem
          to={ACCOUNT_PATH}
          className={styles.item}
          onClick={() => handleClick("Click on settings")}
        >
          <span className="mb-0 smaller">Account</span>
        </DropdownItem>
        {isAdmin && (
          <DropdownItem
            to={BILLING_PATH}
            className={styles.item}
            onClick={() => handleClick("Click on billing")}
          >
            <span className="mb-0 smaller">Billing</span>
          </DropdownItem>
        )}
        {!Boolean(deal?.id) && (
          <DropdownItem
            to={{
              pathname: PLANS_PATH
            }}
            state={{
              plan
            }}
            className={styles.item}
            onClick={() => handleClick("Click on subscription plans")}
          >
            <span className="mb-0 smaller">Explore plans</span>
          </DropdownItem>
        )}
        <DropdownItem
          to={TEAM_PATH}
          className={styles.item}
          onClick={() => handleClick("Click on manage teammates")}
        >
          <span className="mb-0 smaller">Manage teammates</span>
        </DropdownItem>

        {config.features.hasReferral && (
          <DropdownItem
            to={REFERRAL_PATH}
            className={styles.item}
            onClick={() => handleClick("Click on referral")}
          >
            <span className="mb-0 smaller">Refer a friend</span>
          </DropdownItem>
        )}

        {(config.features.hasApps || enableZoomForUser) && (
          <DropdownItem
            to={APPS_HOME_PATH}
            className={styles.item}
            onClick={() => handleClick("Click on apps")}
          >
            <span className="mb-0 smaller">Apps</span>
          </DropdownItem>
        )}
        <DropdownItem
          to={FAQ_URL}
          className={styles.item}
          onClick={() => handleClick("FAQ")}
          blank
        >
          <span className="mb-0 smaller">FAQ</span>
        </DropdownItem>
        <DropdownItem
          to={HELP_PATH}
          className={styles.item}
          onClick={() => handleClick("Click on help")}
        >
          <span className="mb-0 smaller">Help</span>
        </DropdownItem>
        <DropdownDivider className={styles.divider} />
        <DropdownItem
          className={styles.item}
          onClick={() => {
            handleClick("Click on sign out");
            logout();
          }}
        >
          <span className="mb-0 smaller">Sign out</span>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

interface AccountDropdownInfoMenuProps {
  userPicture: string;
  name: string;
  email: string;
}
const AccountDropdownInfoMenu: React.FC<AccountDropdownInfoMenuProps> = ({
  userPicture,
  name,
  email
}) => {
  const { user } = useAccounts();

  return (
    <div className="d-flex align-items-center">
      <div className="mr-2">
        <ProfileImageIcon
          src={userPicture}
          name={name}
          size="50px"
          className="mr-2"
          twoLetters={Boolean(user?.familyName)}
        />
      </div>
      <div className="text-truncate">
        <h6 className="mb-0 d-block text-truncate">{name}</h6>
        <p className="smaller mb-0 d-block text-truncate">{email}</p>
      </div>
    </div>
  );
};

interface ProfileImageProps {
  picture: string;
  name: string;
  size?: string;
}
const ProfileImage: React.FC<ProfileImageProps> = ({
  picture,
  name,
  size = "32px"
}) => {
  const { isOpen } = useContext(DropdownContext);

  return (
    <ProfileImageIcon
      src={picture}
      name={name}
      size={size}
      className={classNames("nav-icon", {
        "is-active": isOpen
      })}
    />
  );
};

const UserInvitations: React.FC = () => {
  const { user } = useAccounts();

  if (!user?.invitations) {
    return null;
  }

  const renderInvitations = user.invitations.map((i) => (
    <UserInvitationItem invitation={i} key={i.id} />
  ));

  return (
    <>
      {renderInvitations}

      <DropdownDivider className={styles.divider} />
    </>
  );
};

interface UserInvitationItemProps {
  invitation: UserInvitation;
}
export const UserInvitationItem: React.FC<UserInvitationItemProps> = ({
  invitation: { id, accountName }
}) => {
  const [isLoading, setLoading] = React.useState(false);

  const handleAccept = async () => {
    setLoading(true);

    await acceptInvitation(id, accountName);
  };

  return (
    <div
      className={classNames(
        styles.item,
        styles["item--notification"],
        "d-flex align-items-center"
      )}
    >
      <Icon path={mdiBell} size="1rem" className="flex-shrink-0 mr-2" />
      <p className="small mb-0">
        You've been invited to join the {accountName} workspace.{" "}
        {isLoading ? (
          <LoadingIcon />
        ) : (
          <>
            <Link
              to="#"
              onClick={handleAccept}
              className="font-weight-normal underline text-dark"
            >
              Accept invite
            </Link>
            .
          </>
        )}
      </p>
    </div>
  );
};
