import React from "react";
import { Helmet } from "react-helmet";
import SublyLogo from "../assets/images/logo-black.svg";
import SublyLogo2 from "../assets/images/logo.png";
import { AuthNavbar } from "../containers/navbar/auth-navbar";
import styles from "./trial-auth-layout.module.scss";

interface TrialAuthLayoutProps {
  showLogin?: boolean;
  showRight?: boolean;
}
export const TrialAuthLayout: React.FC<TrialAuthLayoutProps> = ({
  showLogin,
  showRight,
  children
}) => {
  const features = [
    "Automatic subtitling",
    `"Loads of time" up to 240 min`,
    "Translation - 67 languages",
    "Video snippets and trimming",
    "Video resizing for social media",
    "Team collaboration - for up to 10 users",
    "Styling templates to speed up your work",
    "Audio transcription & audio to video conversion",
    "2 free downloads (video, audio, SRT, VTT, TXT)"
  ];

  const renderFeatureList = features.map((f) => {
    return <li key={f}>{f}</li>;
  });

  return (
    <>
      <Helmet>
        <title>Start Your Trial - Subly</title>
      </Helmet>
      <div className={styles["container"]}>
        <div className={styles["company-container"]}>
          <div className={styles["logo"]}>
            <img src={SublyLogo} alt="Subly" loading="lazy" />
          </div>

          <div className={styles["content-main"]}>
            <div className={styles["header"]}>
              <span className={styles["title"]}>Get Subly Now</span>
              <span className={styles["sub-title"]}>
                The simplest way to make your video &amp; audio outperform
              </span>
            </div>

            <div className={styles["features-list"]}>
              <span>Your 7 day trial:</span>
              <ul>{renderFeatureList}</ul>
            </div>
          </div>
        </div>
        <div className={styles["form-container"]}>
          <AuthNavbar
            showLogin={showLogin}
            showRight={showRight}
            trialPage={true}
          />

          <div className={styles["form"]}>
            <img
              className={styles["subly-logo"]}
              src={SublyLogo2}
              alt="Subly"
              loading="lazy"
              width="90"
              height="35"
            />
            {children}

            <div className={styles["features-mobile"]}>
              <span>Your 7 day trial:</span>
              <ul> {renderFeatureList}</ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
