import { ChevronLeftIcon } from "@heroicons/react/outline";
import React, { useEffect, useState } from "react";
import { moveMedia } from "../../api/media.service";
import { Button } from "../../components/button/button";
import { SearchBar } from "../../components/search-bar/search-bar";
import { useFolders } from "../../hooks/use-folders";
import { Folder } from "../../state/folders/folders.store";
import { pluralize } from "../../utils/strings";
import { Modal } from "../modal/modal";
import { FolderListContainer } from "./folder-list-container";
import styles from "./move-media-modal.module.scss";

interface MoveMediaModalProps {
  mediaIds: string[];
  closeModal: (shouldClear?: boolean) => void;
  isEditor?: boolean;
}
export const MoveMediaModal: React.FC<MoveMediaModalProps> = ({
  mediaIds,
  closeModal,
  isEditor = false
}) => {
  return (
    <Modal
      onDismiss={closeModal}
      hasCloseIcon
      closeIconClassName={styles["close-icon"]}
      className={styles.modal}
      disableBackgroundDismiss
    >
      <MoveMediaModalContainer
        mediaIds={mediaIds}
        closeModal={closeModal}
        isEditor={isEditor}
      />
    </Modal>
  );
};

const MoveMediaModalContainer: React.FC<MoveMediaModalProps> = ({
  mediaIds,
  closeModal,
  isEditor
}) => {
  const { activeFolder, allFolders } = useFolders();
  const [selectedFolder, setSelectedFolder] = useState(activeFolder);
  const [isLoading, setLoading] = useState(false);
  const [searchedFolders, setSearchedFolders] = useState<Folder[]>([]);
  const [searchWords, setSearchWords] = useState<string>("");

  const handleMove = async () => {
    setLoading(true);
    for await (const mediaId of mediaIds) {
      await moveMedia(mediaId, selectedFolder?.id);
    }
    setLoading(false);
    closeModal(true);
  };

  useEffect(() => {
    const filteredFolders = allFolders.filter((f) => {
      if (!searchWords) {
        return false;
      }

      return f.name.toLowerCase().includes(searchWords.toLowerCase());
    });

    setSearchedFolders(filteredFolders);
  }, [searchWords]);

  return (
    <>
      <div className={styles["modal-header"]}>
        <div className={styles.chevron} onClick={() => closeModal()}>
          <ChevronLeftIcon className={styles.icon} />
        </div>
        <h5 className={styles["header-text"]}>
          Move {isEditor ? "this" : mediaIds.length}{" "}
          {pluralize(mediaIds.length, "project")} to folder
        </h5>
      </div>

      <SearchBar
        placeholder="Search folders"
        searchWords={searchWords}
        setSearchWords={setSearchWords}
        className={styles.searchbar}
      />

      <FolderListContainer
        selectedFolder={selectedFolder}
        setSelectedFolder={setSelectedFolder}
        searchedFolders={searchedFolders}
      />

      <div className="d-flex justify-content-end mt-4">
        <Button
          primary
          className="ml-2 px-4"
          onClick={handleMove}
          loading={isLoading}
        >
          Move to folder
        </Button>
      </div>
    </>
  );
};
