import classNames from "classnames";
import React from "react";
import {
  BackgroundOnboardingOne,
  BackgroundOnboardingFour,
  BackgroundOnboardingThree,
  BackgroundOnboardingTwo
} from "../../../components/icons/onboarding-icons";
import { useOnboarding } from "../../../contexts/onboarding.context";
import { OnboardingCompany } from "../forms/onboarding-company/onboarding-company";
import { OnboardingIntro } from "../forms/onboarding-intro/onboarding-intro";
import { OnboardingTeam } from "../forms/onboarding-team/onboarding-team";
import { OnboardingUser } from "../forms/onboarding-user/onboarding-user";
import styles from "./onboarding-container.module.scss";

export const Onboarding: React.FC = () => {
  const { currentStep } = useOnboarding();

  switch (currentStep) {
    case 1:
      return (
        <OnboardingContainer>
          <BackgroundOnboardingOne
            className={classNames(styles.background, styles.bottomRight)}
          />
          <OnboardingIntro />
        </OnboardingContainer>
      );
    case 2:
      return (
        <OnboardingContainer>
          <BackgroundOnboardingTwo
            className={classNames(styles.background, styles.topLeft)}
          />
          <OnboardingUser />
        </OnboardingContainer>
      );
    case 3:
      return (
        <OnboardingContainer>
          <BackgroundOnboardingThree
            className={classNames(styles.background, styles.bottomRight)}
          />
          <OnboardingCompany />
        </OnboardingContainer>
      );
    case 4:
      return (
        <OnboardingContainer>
          <BackgroundOnboardingFour
            className={classNames(styles.background, styles.topRight)}
          />
          <OnboardingTeam />
        </OnboardingContainer>
      );

    default:
      return (
        <OnboardingContainer>
          <BackgroundOnboardingOne
            className={classNames(styles.background, styles.bottomRight)}
          />
          <OnboardingIntro />
        </OnboardingContainer>
      );
  }
};

const OnboardingContainer: React.FC = ({ children }) => {
  return <div className={styles.container}>{children}</div>;
};
