import React from "react";
import { Modal } from "../../containers/modal/modal";
import { PLANS_PATH } from "../../routes";
import { Button } from "../button/button";
import { FeatureList } from "../../containers/settings/plans/plans-table";
import styles from "./upgrade-modal.module.scss";
import { getFeatures, getLimits } from "../../utils/plans";
import { SublyPlan } from "../../interfaces/billing";

interface UpgradeReach95FreeModalProps {
  closeModal: () => void;
}
export const UpgradeReach95FreeModal: React.FC<UpgradeReach95FreeModalProps> =
  ({ closeModal }) => {
    const { minutesLabel, storageLabel, uploadLabel } = getLimits(
      SublyPlan.Pro100
    );
    const allFeatures = getFeatures(SublyPlan.Pro100);
    const features = {
      items: [
        ...allFeatures.items,
        minutesLabel + " per month",
        storageLabel + " storage",
        uploadLabel + " file upload"
      ]
    };

    return (
      <Modal onDismiss={closeModal} className={styles["modal"]} hasCloseIcon>
        <h5 className="mb-3 d-flex align-items-center">
          You're a subtitling superstar{" "}
          <span role="img" aria-labelledby="Superstar" className="ml-1">
            🌟
          </span>
        </h5>

        <p className="text-black">
          Wow, you're on a roll... and we don't want to stop you now!
        </p>
        <p className="text-black">
          Need to subtitle more content this month? Try Subly Pro for more
          minutes and a load of extra features.
        </p>

        <FeatureList features={features} />

        <p className="text-black mb-4">
          Upgrade your subscription today. Did you know, you can downgrade your
          subscription at any time?
        </p>
        <div className="d-flex justify-content-end">
          <Button secondary className="mr-3" onClick={closeModal}>
            Maybe later
          </Button>
          <Button primary to={PLANS_PATH} onClick={closeModal}>
            Upgrade now
          </Button>
        </div>
      </Modal>
    );
  };
