import React from "react";

interface UploadButtonProps {
  accept: string;
  onChange: (file: File) => void;
  className?: string;
}
export const UploadButton: React.FC<UploadButtonProps> = ({
  accept,
  onChange,
  className,
  children
}) => {
  const fileRef = React.useRef<HTMLInputElement>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files ?? [];

    if (files.length > 0) {
      onChange(files[0]);
    }
  };

  return (
    <div
      onClick={() => fileRef.current?.click()}
      style={{ width: "100%", height: "100%" }}
      className={className}
    >
      <input
        type="file"
        accept={accept}
        style={{ display: "none" }}
        ref={fileRef}
        onChange={handleChange}
      />
      {children}
    </div>
  );
};
