import { format } from "date-fns";
import React from "react";
import { Coupon } from "../../../../api/billing.service";
import { PlanInterval } from "../../../../interfaces/billing";
import { PRIVACY_URL, TERMS_URL } from "../../../../routes";

interface TermsCopyProps {
  coupon?: Coupon;
  isPayg?: boolean;
  interval?: PlanInterval;
}

export const TermsCopy: React.FC<TermsCopyProps> = ({
  coupon,
  isPayg,
  interval
}) => {
  if (isPayg) {
    return (
      <p className="caption mt-4">
        By completing the above purchase you agree to the{" "}
        <a href={TERMS_URL} target="_blank" rel="noopener noreferrer">
          Terms of Service
        </a>{" "}
        and{" "}
        <a href={PRIVACY_URL} target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </a>
        .
      </p>
    );
  }

  if (coupon?.metadata?.cancel_at_end) {
    return (
      <p className="caption mt-4">
        You will have free access to Subly Pro for {coupon?.duration_in_months}{" "}
        months. Cancel any time.
        <br />
        By clicking upgrade subscription you agree to the{" "}
        <a href={TERMS_URL} target="_blank" rel="noopener noreferrer">
          Terms of Service
        </a>{" "}
        and{" "}
        <a href={PRIVACY_URL} target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </a>
        .
      </p>
    );
  }
  const planStartDay = format(new Date(), "do");
  const planStartMonth = format(new Date(), "MMMM");

  return (
    <p className="caption mt-4">
      Billing starts today and will{" "}
      <span className="font-weight-bold font-size-inherit">
        renew on the{" "}
        {interval === PlanInterval.Monthly
          ? `${planStartDay} day of every month`
          : `${planStartDay} of ${planStartMonth} every year`}
        .{" "}
      </span>
      Cancel any time.
      <br />
      By clicking upgrade subscription you agree to the{" "}
      <a href={TERMS_URL} target="_blank" rel="noopener noreferrer">
        Terms of Service
      </a>{" "}
      and{" "}
      <a href={PRIVACY_URL} target="_blank" rel="noopener noreferrer">
        Privacy Policy
      </a>
      .
    </p>
  );
};
