import { mdiCheckCircle, mdiInformationOutline } from "@mdi/js";
import Icon from "@mdi/react";
import classNames from "classnames";
import React from "react";
import {
  amountRule,
  lowercaseRule,
  numberRule,
  symbolRule,
  uppercaseRule
} from "../../utils/regex-patterns";
import "./password-rules.scss";

interface PasswordRulesProps {
  value: string;
  className?: string;
}
export const PasswordRules: React.FC<PasswordRulesProps> = ({
  value,
  className
}) => {
  return (
    <div className={classNames("password-rules", className)}>
      <PasswordRule
        label="Password must be minimum 8 characters long  "
        rule={amountRule}
        value={value}
      />
      <PasswordRule
        label="Include uppercase alphabet"
        rule={uppercaseRule}
        value={value}
      />
      <PasswordRule
        label="Include lowercase alphabet"
        rule={lowercaseRule}
        value={value}
      />
      <PasswordRule label="One symbol" rule={symbolRule} value={value} />
      <PasswordRule label="One number" rule={numberRule} value={value} />
    </div>
  );
};

interface PasswordRuleProps {
  value: string;
  label: string;
  rule: RegExp;
}
const PasswordRule: React.FC<PasswordRuleProps> = ({ label, rule, value }) => {
  const isValid = rule.test(value);
  const icon =
    value && isValid ? (
      <Icon path={mdiCheckCircle} className="icon success" size="0.75rem" />
    ) : (
      <Icon
        path={mdiInformationOutline}
        className="icon warning"
        size="0.75rem"
      />
    );

  return (
    <span className="rule">
      {icon} {label}
    </span>
  );
};
