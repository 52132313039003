import React, { useRef, useEffect } from "react";
import { ButtonProps, Button } from "./button";

interface UploadButtonProps extends ButtonProps {
  handleChange: (file: File) => void;
  accept: string;
}
export const UploadButton: React.FC<UploadButtonProps> = ({
  handleChange,
  accept,
  ...props
}) => {
  const isUploading = props.loading;
  useEffect(() => {
    if (isUploading && fileRef.current) {
      fileRef.current.value = "";
    }
  }, [isUploading]);

  const fileRef = useRef<HTMLInputElement>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file) {
      handleChange(file);
      if (fileRef.current) {
        fileRef.current.value = "";
      }
    }
  };

  return (
    <>
      <input
        type="file"
        id="upload-button"
        style={{ display: "none" }}
        ref={fileRef}
        accept={accept}
        onChange={handleInputChange}
      />

      <Button {...props} onClick={() => fileRef.current?.click()}></Button>
    </>
  );
};
