import { ProgressType } from "@getsubly/common";
import axios, { AxiosResponse } from "axios";
import config from "../config";
import { getAccountId } from "../config/settings/settings.service";
import {
  AccountSettings,
  AccountTemplate,
  UserAccount
} from "../interfaces/account";
import { SublyPlan } from "../interfaces/billing";
import { User } from "../interfaces/user";
import {
  accountQuery,
  accountStore,
  DEFAULT_ACCOUNT_SETTINGS
} from "../state/account";
import { authStore } from "../state/auth/auth.store";
import { mediaStore } from "../state/media";
import { AudioTemplate, ProgressBarStyle } from "../types/media-editor.types";
import { getAccessToken } from "./auth.service";
import { handleError } from "./handle-error";

const baseURL = `${config.apiUrl}/api/v1`;

interface AccountSettingsResponse {
  settings: AccountSettings;
}
export const getAccountSettings = async (): Promise<void> => {
  const accountId = getAccountId();

  if (!accountId) {
    return;
  }

  try {
    const { data } = await axios.get<AccountSettingsResponse>(
      `/${accountId}/settings`,
      {
        baseURL: baseURL,
        headers: { "x-access-token": await getAccessToken() }
      }
    );

    accountStore.updateWithShowReleaseModal(data.settings);
  } catch (error) {
    handleError(error);
  }
};

interface UpdateAccountSettingsResponse {
  settings: AccountSettings;
}
export const updateAccountSettings = async (
  settings: Partial<AccountSettings>
): Promise<void> => {
  try {
    const { data } = await axios.put<UpdateAccountSettingsResponse>(
      `/${getAccountId()}/settings`,
      { settings },
      {
        baseURL,
        headers: {
          "x-access-token": await getAccessToken()
        }
      }
    );

    accountStore.update({
      settings: { ...DEFAULT_ACCOUNT_SETTINGS, ...data.settings }
    });
  } catch (error) {
    handleError(error);
    throw new Error(error);
  }
};

export const updateAccountSettingsByMedia = async (
  mediaId: string,
  settings: Partial<AccountSettings>
): Promise<void> => {
  try {
    const { data } = await axios.put<UpdateAccountSettingsResponse>(
      `/media/${mediaId}/settings`,
      { settings },
      {
        baseURL,
        headers: {
          "x-access-token": await getAccessToken()
        }
      }
    );

    mediaStore.ui.update(mediaId, {
      accountSettings: { ...DEFAULT_ACCOUNT_SETTINGS, ...data.settings }
    });
  } catch (error) {
    handleError(error);
    throw new Error(error);
  }
};

export const saveAccountTemplates = async (
  templates: AccountTemplate[],
  mediaId?: string
): Promise<void> => {
  if (mediaId) {
    await updateAccountSettingsByMedia(mediaId, { templates });
  } else {
    await updateAccountSettings({ templates });
  }
};

export const updateAccountAudioTemplate = async (
  template: AudioTemplate,
  mediaId?: string
): Promise<void> => {
  const { settings } = accountQuery.getValue();

  const updatedAudioTemplates = settings.audioTemplates.map((t) => {
    if (t.templateId === template.templateId) {
      return template;
    }

    return t;
  });

  if (mediaId) {
    await updateAccountSettingsByMedia(mediaId, {
      audioTemplates: updatedAudioTemplates
    });
  } else {
    await updateAccountSettings({
      audioTemplates: updatedAudioTemplates
    });
  }
};

export const updateAccountColours = async (
  colours: Partial<
    Pick<
      AccountSettings,
      "primaryColors" | "outlineColors" | "aspectRatioColors" | "borderColors"
    >
  >,
  mediaId?: string
): Promise<void> => {
  if (mediaId) {
    await updateAccountSettingsByMedia(mediaId, {
      ...colours
    });
  } else {
    await updateAccountSettings({
      ...colours
    });
  }
};

export const updateAccountProgressBars = async (
  id: ProgressType,
  styles: ProgressBarStyle,
  mediaId?: string
): Promise<void> => {
  const { settings } = accountQuery.getValue();

  if (mediaId) {
    await updateAccountSettingsByMedia(mediaId, {
      progressBars: {
        ...settings.progressBars,
        [id]: styles
      }
    });
  } else {
    await updateAccountSettings({
      progressBars: {
        ...settings.progressBars,
        [id]: styles
      }
    });
  }
};

interface IssueReport {
  accountId?: string;
  mediaId?: string;
  message?: string;
}
export const reportIssue = async (issue: IssueReport): Promise<void> => {
  try {
    await axios.post(`/${getAccountId()}/report-issue`, issue, {
      baseURL,
      headers: {
        "x-access-token": await getAccessToken()
      }
    });
  } catch (e) {
    handleError(e);
  }
};

export const startCheckout = async (plan: SublyPlan): Promise<void> => {
  try {
    await axios.post(
      `/plans/${getAccountId()}/start-checkout`,
      { plan },
      {
        baseURL,
        headers: {
          "x-access-token": await getAccessToken()
        }
      }
    );
  } catch (e) {
    console.error(e);
  }
};

export interface EditUserProfileParams {
  givenName: string;
  familyName: string;
  email: string;
  name: string;
  password: string;
  newPassword: string;
  passwordConfirm: string;
}
interface UserProfileResponse {
  data: {
    user: User;
  };
}
export const editUserProfile = async (
  data: EditUserProfileParams
): Promise<AxiosResponse<UserProfileResponse>> => {
  return await axios.put<UserProfileResponse>(`${baseURL}/auth/update`, data, {
    headers: { "x-access-token": await getAccessToken() }
  });
};

export const editUserProfilePicture = async (
  file: File
): Promise<AxiosResponse<UserProfileResponse>> => {
  const bodyFormData = new FormData();
  bodyFormData.append("file", file);

  return await axios.put<UserProfileResponse>(
    `${baseURL}/auth/update-picture`,
    bodyFormData,
    {
      headers: {
        "Content-Type": "form-data",
        "x-access-token": await getAccessToken()
      }
    }
  );
};

export const removeUserProfilePicture = async (): Promise<
  AxiosResponse<unknown>
> => {
  return await axios.put(
    `${baseURL}/auth/remove-picture`,
    {},
    {
      headers: {
        "x-access-token": await getAccessToken()
      }
    }
  );
};

export const deleteUser = async (): Promise<AxiosResponse<unknown>> =>
  await axios.delete(`${baseURL}/auth/user`, {
    headers: {
      "x-access-token": await getAccessToken()
    }
  });

export interface NewsletterFormData {
  givenName: string;
  familyName: string;
  email: string;
}
export const subscribeNewsletter = async (
  data: NewsletterFormData
): Promise<AxiosResponse<unknown>> => {
  return await axios.post(`${baseURL}/marketing/newsletter`, data);
};

interface CreateBusinessAccount {
  accountName: string;
}
interface CreateBusinessAccountResponse {
  account: UserAccount;
}
export const createBusinessAccount = async (
  data: CreateBusinessAccount
): Promise<UserAccount> => {
  const {
    data: { account }
  } = await axios.post<CreateBusinessAccountResponse>(`/accounts`, data, {
    baseURL,
    headers: {
      "x-access-token": await getAccessToken()
    }
  });

  authStore.update((s) => {
    if (!s.user?.accounts) {
      return s;
    }

    return {
      ...s,
      user: { ...s.user, accounts: [...s.user.accounts, account] },
      accountId: account.accountId
    };
  });

  return account;
};

export const updateAccountName = async (name: string): Promise<void> => {
  const accountId = getAccountId();

  try {
    await axios.put(
      `/accounts/${accountId}/rename`,
      { name },
      {
        baseURL,
        headers: {
          "x-access-token": await getAccessToken()
        }
      }
    );

    accountStore.update({ accountName: name });
    authStore.updateAccountName(accountId, name);
  } catch (error) {
    handleError(error);
  }
};
