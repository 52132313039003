import { useObservable } from "@mindspace-io/react";
import { User } from "../interfaces/user";
import { authQuery } from "../state/auth/auth.query";

interface UseUser {
  user?: User;
  hasOnboarded: boolean;
}
export function useUser(): UseUser {
  const [user] = useObservable(authQuery.selectUser(), authQuery.user);
  const [hasOnboarded] = useObservable(
    authQuery.selectHasOnboarded(),
    authQuery.hasOnboarded
  );

  return {
    user,
    hasOnboarded
  };
}
