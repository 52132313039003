import classNames from "classnames";
import numeral from "numeral";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Button } from "../../../components/button/button";
import { LocationState } from "../../../components/button/plans-button";
import { Input } from "../../../components/controls/input";
import { ArrowLeftIcon, StopwatchIcon } from "../../../components/icons/icons";
import { useAnalyticsWithAuth } from "../../../hooks/use-analytics-with-auth";
import { SublyPlan } from "../../../interfaces/billing";
import { ACCOUNT_PATH, PLANS_UPGRADE_PATH } from "../../../routes";
import { getPlanData, PAYG_PRICE } from "../../../utils/plans";
import styles from "./payg-pricing.module.scss";
import { FeatureList, PlanIcon } from "./plans-table";

interface PayAsYouGoPricingProps {
  updateQuantity: (quantity?: number) => void;
}
export const PayAsYouGoPricing: React.FC<PayAsYouGoPricingProps> = ({
  updateQuantity
}) => {
  const state = useLocation().state as LocationState;
  const { trackEventWithAuth } = useAnalyticsWithAuth();
  const [quantity, setQuantity] = useState<number | undefined>(20);
  const planData = getPlanData(SublyPlan.PAYG);

  return (
    <div className={styles["wrapper"]}>
      <div className={styles["payg-header"]}>
        <h1 className={styles["payg-title"]}>Pay as you go</h1>
        <p>Buy minutes when you need them.</p>
      </div>
      <div className={styles["plans-setting"]}>
        <Link to={ACCOUNT_PATH}>
          <ArrowLeftIcon width="2rem" height="2rem" />
          Settings
        </Link>
      </div>

      <div className={styles["container"]}>
        <div className={styles["card"]}>
          <h4 className="font-weight-bold">How many minutes you need?</h4>
          <div className="d-flex align-items-center position-relative mt-4">
            <StopwatchIcon className={styles.stopwatchIcon} />
            <Input
              type="number"
              min="20"
              placeholder="Enter minutes"
              value={quantity ?? ""}
              onChange={({ target }) => {
                const v = target.value ? +target.value : undefined;
                setQuantity(v);
                updateQuantity(v);
              }}
              className={styles["payg-input"]}
            />
          </div>
          <label className={classNames("small", styles["payg-label"])}>
            $0.60 per minute (20 minutes minimum)
          </label>
          <h4 className={styles["minutes"]}>{quantity || 0} mins</h4>

          <div className={styles["cost-box"]}>
            <span className="mt-3">Total</span>
            <div className={styles["cost"]}>
              <h4 className="mb-0 mr-1">
                ${numeral((quantity || 0) * PAYG_PRICE).format("0.00")}
              </h4>
              <span>USD</span>
            </div>
          </div>

          <Button
            primary
            isFull
            disabled={!quantity || quantity < 20}
            to={{
              pathname: PLANS_UPGRADE_PATH
            }}
            state={{
              ...state,
              plan: SublyPlan.PAYG,
              quantity,
              upgradeToPAYG: true
            }}
            onClick={() => trackEventWithAuth("Click to purchase minutes")}
          >
            Purchase minutes
          </Button>
        </div>

        <div className={styles["card"]}>
          <PlanIcon plan={planData} />
          <h4 className={styles["payg-tagline"]}>
            If you have a one-time project or don't want a monthly obligation.
            We got you!
          </h4>
          <div className={styles["features-wrapper"]}>
            <FeatureList features={planData.features} isTwoColumn={true} />
          </div>
        </div>
      </div>
    </div>
  );
};
