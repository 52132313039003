import React from "react";
import { Button } from "../../components/button/button";
import { useOnboarding } from "../../contexts/onboarding.context";
import styles from "./onboarding-buttons.module.scss";

interface OnboardingButtonsProps {
  onPrev?: () => void;
  onNext?: () => void;
  disableNext?: boolean;
  skipToFinish?: boolean;
  isLoading?: boolean;
}
export const OnboardingButtons: React.FC<OnboardingButtonsProps> = ({
  onPrev,
  onNext,
  skipToFinish = false,
  disableNext = false,
  isLoading = false
}) => {
  const { previousStep, nextStep } = useOnboarding();

  const handleBack = () => {
    if (!onPrev) {
      previousStep();
      return;
    }

    onPrev();
  };

  const handleNext = () => {
    if (!onNext) {
      nextStep();
      return;
    }

    onNext();
  };

  const handleSkip = () => {
    nextStep(true);
    return;
  };

  if (skipToFinish) {
    return (
      <div className={styles.container}>
        <Button tertiary onClick={handleBack}>
          Back
        </Button>
        <Button
          primary
          onClick={handleSkip}
          disabled={disableNext}
          loading={isLoading}
        >
          Next
        </Button>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <Button tertiary onClick={handleBack}>
        Back
      </Button>
      <Button
        primary
        onClick={handleNext}
        disabled={disableNext}
        loading={isLoading}
      >
        Next
      </Button>
    </div>
  );
};
