export interface GooglePickerError {
  error: string;
  details?: string;
}

export type GoogleDrivePickerAction = "loaded" | "picked" | string;

export interface GoogleDrivePickerEvent {
  action: GoogleDrivePickerAction;
  docs: (GoogleDriveFileDoc | GoogleDriveFolderDoc)[];
}

export const isGoogleDriveFileDoc = (
  doc: GoogleDriveFileDoc | GoogleDriveFolderDoc
): doc is GoogleDriveFileDoc => !isGoogleDriveFolderDoc(doc);

export const isGoogleDriveFolderDoc = (
  doc: GoogleDriveFileDoc | GoogleDriveFolderDoc
): doc is GoogleDriveFolderDoc => doc.type === "folder";

export interface GoogleDriveDoc {
  id: string;
  name: string;
  description: string;
  type: "video" | "folder" | string;

  mimeType: string;
  sizeBytes: number;
  url: string;
  embedUrl: string;

  serviceId: "docs" | string;
  parentId?: string;
  lastEditedUtc: number;
}

export interface GoogleDriveFileDoc extends GoogleDriveDoc {
  duration: number;
}

export interface GoogleDriveFolderDoc extends GoogleDriveDoc {
  type: "folder";
  mimeType: "application/vnd.google-apps.folder";
  sizeBytes: 0;
  isShared: boolean;
}

export interface GoogleDriveAuthInfo {
  accessToken: string;
  authorizationCode: string;
}

export interface GoogleDriveDownloadInfo extends GoogleDriveAuthInfo {
  folderId: string;
}

export interface GoogleDriveFileInfo {
  accessToken: string;
  fileId: string;
  filename: string;
  mimeType: string;
}
