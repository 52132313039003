import { useObservable } from "@mindspace-io/react";
import { SublyPlan } from "../interfaces/billing";
import { AccountCredit, accountQuery } from "../state/account";
import { getPlanUploadLimit } from "../utils/plans";
import { getBytes } from "../utils/storage-size";
import { useAccounts } from "./use-accounts";

// TODO: Implement this across the app, and remove it from the AuthContext
interface UsePlan {
  plan: SublyPlan;
  hasPaymentOverdue: boolean;
  isFree: boolean;
  isPro: boolean;
  isProOrHigher: boolean;
  isPremiumOrHigher: boolean;
  isPro100: boolean;
  isProAnnual: boolean;
  isPremium: boolean;
  isBusiness: boolean;
  isTrial: boolean;
  trialEndDate?: Date;
  hasTrialed: boolean;
  isPayg: boolean;
  credit: AccountCredit;
  storageBytesUsed: number;
  totalStorageLabel: string;
  hasCustomUploadLimit: boolean;
  maxUploadLimit: number;
  availableStorage: number;
  availableSeconds: number;
}

export function usePlan(): UsePlan {
  const { user, settings } = useAccounts();
  const [plan] = useObservable(
    accountQuery.selectCurrentPlan(),
    accountQuery.currentPlan
  );
  const [hasPaymentOverdue] = useObservable(
    accountQuery.selectHasPaymentOverdue(),
    accountQuery.hasPaymentOverdue
  );
  const [isPro] = useObservable(accountQuery.selectIsPro(), accountQuery.isPro);
  const [isProOrHigher] = useObservable(
    accountQuery.selectIsProOrHigher(),
    accountQuery.isProOrHigher
  );
  const [isPremiumOrHigher] = useObservable(
    accountQuery.selectIsPremiumOrHigher(),
    accountQuery.isPremiumOrHigher
  );
  const [isPro100] = useObservable(
    accountQuery.selectIsPro100(),
    accountQuery.isPro100
  );
  const [isProAnnual] = useObservable(
    accountQuery.selectIsProAnnual(),
    accountQuery.isProAnnual
  );
  const [isPremium] = useObservable(
    accountQuery.selectIsPremium(),
    accountQuery.isPremium
  );
  const [isBusiness] = useObservable(
    accountQuery.selectIsBusiness(),
    accountQuery.isBusiness
  );
  const [isTrial] = useObservable(
    accountQuery.selectIsTrial(),
    accountQuery.isTrial
  );
  const [trialEndDate] = useObservable(
    accountQuery.selectTrialEndDate(),
    accountQuery.trialEndDate
  );
  const [isPayg] = useObservable(
    accountQuery.selectIsPayg(),
    accountQuery.isPayg
  );
  const [credit] = useObservable(
    accountQuery.selectCredit(),
    accountQuery.credit
  );
  const [storageBytesUsed] = useObservable(
    accountQuery.selectStorageBytesUsed(),
    accountQuery.storageBytesUsed
  );
  const [totalStorageLabel] = useObservable(
    accountQuery.selectTotalStorageLabel(),
    ""
  );
  const [availableStorage] = useObservable(
    accountQuery.selectAvailableStorage(),
    accountQuery.availableStorage
  );
  const [customUploadLimitBytes] = useObservable(
    accountQuery.selectCustomUploadLimitBytes(),
    accountQuery.customUploadLimitBytes
  );

  const hasAlreadyTrialled = Boolean(
    user?.settings?.hasAlreadyTrialled || settings?.hasAlreadyTrialled
  );
  const hasTrialed = isTrial || hasAlreadyTrialled;

  const isFree = plan === SublyPlan.Free;

  const hasCustomUploadLimit = !!customUploadLimitBytes;
  const maxUploadLimit = hasCustomUploadLimit
    ? customUploadLimitBytes
    : getBytes(getPlanUploadLimit(plan), "GB");

  const availableSeconds = credit?.total ?? 0;

  return {
    plan,
    hasPaymentOverdue,
    isFree,
    isPro,
    isProOrHigher,
    isPremiumOrHigher,
    isPro100,
    isProAnnual,
    isPremium,
    isBusiness,
    isTrial,
    trialEndDate,
    hasTrialed,
    isPayg,
    credit,
    storageBytesUsed,
    totalStorageLabel,
    hasCustomUploadLimit,
    maxUploadLimit,
    availableStorage,
    availableSeconds
  };
}
