import React from "react";
import { Modal } from "../../containers/modal/modal";
import { usePlan } from "../../hooks/use-plan";
import { SUPPORT_URL } from "../../routes";
import { Button } from "../button/button";
import styles from "./trial-dashboard-modal.module.scss";

interface MigrationModalProps {
  closeModal: () => void;
}
export const MigrationModal: React.FC<MigrationModalProps> = ({
  closeModal
}) => {
  const { plan } = usePlan();
  const isYearly = plan.includes("Yearly");
  const label = isYearly ? "1 year" : "6 months";

  return (
    <Modal onDismiss={closeModal} className={styles["modal"]} hasCloseIcon>
      <h5 className="mb-1 d-flex align-items-center text-text-primary">
        <strong>Updates to your Subly subscription</strong>
      </h5>
      <p className="mb-3">
        There have been a few changes to your Subly subscription over the past
        couple of weeks and as a thank you for being our loyal customer we are
        giving you access to Subly Premium plan for {label}.
      </p>

      <p className="text-text-primary mb-1">
        <strong>So, what do you get with your Premium plan?</strong>
      </p>
      <p>
        <span role="img" aria-labelledby="Styling" className="mr-1">
          🎨
        </span>{" "}
        Keep creating styling templates
        <br />
        <span role="img" aria-labelledby="Drive" className="mr-1">
          🗂
        </span>{" "}
        Keep access to Google Drive integration
        <br />
        <span role="img" aria-labelledby="World" className="mr-1">
          🌍
        </span>{" "}
        Translate to over 40 languages
        <br />
        <span role="img" aria-labelledby="Audio" className="mr-1">
          🎙
        </span>{" "}
        Convert audio to video
        <br />
        <span role="img" aria-labelledby="TV" className="mr-1">
          📺
        </span>{" "}
        Export HD files <br />
        <span role="img" aria-labelledby="Share" className="mr-1">
          👥
        </span>{" "}
        Share files with up to 3 teammates
      </p>

      <p>
        Have a question about any of the changes? Contact our superhero support
        team at <a href={SUPPORT_URL}>support@getsubly.com</a>.
      </p>

      <div className="d-flex justify-content-end">
        <Button primary onClick={closeModal} className={styles.button}>
          Let’s try it
        </Button>
      </div>
    </Modal>
  );
};
