import React from "react";
import styles from "./file-action-toolbar.module.scss";
import { ToolbarSeparator } from "../../../components/toolbar-separator/toolbar-separator";
import { Button } from "../../../components/button/button";
import {
  TrashCanOutlinePauseIcon,
  CloseIcon
} from "../../../components/icons/icons";
import { ToolTip } from "../../../containers/tooltip/tooltip";
import classNames from "classnames";
import { FolderIcon } from "@heroicons/react/outline";
import { DeleteMediaModal } from "../../delete-media-modal/delete-media-modal";
import { useModal } from "../../../hooks/use-modal";
import { ModalType } from "../../../interfaces/modal-type";
import { MoveMediaModal } from "../../move-media-modal/move-media-modal";

export interface DashboardFileActionToolBarProps {
  selectedFiles: string[];
  handleDeselectAllTheFiles: () => void;
}
export const DashboardFileActionToolBar: React.FC<
  DashboardFileActionToolBarProps
> = ({ selectedFiles, handleDeselectAllTheFiles }) => {
  const [showDeleteModal, hideDeleteModal] = useModal(ModalType.DeleteMedia);
  const [showMoveModal, hideMoveModal] = useModal(ModalType.MoveMedia);

  const handleCloseMoveModal = (shouldClear?: boolean) => {
    hideMoveModal();

    if (shouldClear) {
      handleDeselectAllTheFiles();
    }
  };

  const handleCloseDeleteModal = (shouldClear?: boolean) => {
    hideDeleteModal();

    if (shouldClear) {
      handleDeselectAllTheFiles();
    }
  };

  return (
    <div
      className={classNames(styles.toolbar, {
        [styles["toolbar--hide"]]: !selectedFiles.length,
        [styles["toolbar--show"]]: selectedFiles.length
      })}
    >
      <div className={styles["toolbar-contents"]}>
        <div className={styles["selected-container"]}>
          <p>Selected</p>
          <div className={styles.badge}>{selectedFiles.length}</div>
        </div>
        <ToolbarSeparator className={styles.separator} />
        <div className={styles.actions}>
          <ToolTip text="Move selected files" className={styles.tooltip}>
            <div>
              <Button
                onClick={() => {
                  showMoveModal(
                    <MoveMediaModal
                      mediaIds={selectedFiles}
                      closeModal={handleCloseMoveModal}
                    />
                  );
                }}
              >
                <FolderIcon className={styles.folderIcon} />
              </Button>
            </div>
          </ToolTip>

          <ToolTip text="Delete selected files" className={styles.tooltip}>
            <div>
              <Button
                className={styles["delete-button"]}
                onClick={() => {
                  showDeleteModal(
                    <DeleteMediaModal
                      mediaIds={selectedFiles}
                      closeModal={handleCloseDeleteModal}
                    />
                  );
                }}
              >
                <TrashCanOutlinePauseIcon />
              </Button>
            </div>
          </ToolTip>
        </div>
      </div>
      <Button
        className={styles["close-button"]}
        onClick={() => {
          handleDeselectAllTheFiles();
        }}
      >
        <CloseIcon />
      </Button>
    </div>
  );
};
