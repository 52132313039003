/* eslint-disable @typescript-eslint/no-explicit-any */
import { Transcription } from "@getsubly/common";
import { parseToTranscription } from "@getsubly/common/dist/assParser";
import { parseString } from "@fast-csv/parse";

export const readFileAsText = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event: any) => {
      if (event.target.error) {
        reject(event.target.error);
      }

      resolve(event.target.result);
    };

    reader.readAsText(file);
  });

export const readFileAsTranscription = async (
  file: File
): Promise<Transcription | undefined> => {
  try {
    const fileBody = await readFileAsText(file);
    if (fileBody) {
      return parseToTranscription(fileBody);
    }
    return undefined;
  } catch (error) {
    return undefined;
  }
};

export const readCsvFile = async <T>(file: File): Promise<T[] | undefined> => {
  try {
    const fileBody = await readFileAsText(file);
    if (fileBody) {
      return new Promise((resolve, reject) => {
        const parse = parseString(fileBody);
        const rows: T[] = [];

        parse.on("data", (row) => {
          rows.push(row);
        });

        parse.on("end", () => {
          resolve(rows);
        });

        parse.on("error", () => {
          reject(undefined);
        });
      });
    }
    return undefined;
  } catch (error) {
    return undefined;
  }
};
