import React from "react";
import { Button } from "../../../../components/button/button";
import buttonStyle from "../../../../components/button/button.module.scss";
import classNames from "classnames";
import styles from "../plans.module.scss";
import { FeatureList, PlanIcon } from "../plans-table";
import {
  getPlanData,
  PlanLabelTypes,
  SublyPlanData
} from "../../../../utils/plans";
import { PlanInterval, SublyPlan } from "../../../../interfaces/billing";
import { SALES_CONTACT } from "../../../../routes";

interface PlanSuggestionCardProps {
  quantity?: number;
  isPAYGView: boolean;
  setIsPAYGView: React.Dispatch<React.SetStateAction<boolean>>;
  executeScroll: () => void;
}

export const PlanSuggestionCard: React.FC<PlanSuggestionCardProps> = ({
  quantity = 0,
  isPAYGView,
  setIsPAYGView,
  executeScroll
}) => {
  const suggestPro = quantity >= 30 && quantity < 100;
  const suggestPremium = quantity >= 100 && quantity < 250;
  const suggestBusiness = quantity >= 250 && quantity < 12000;
  const suggestCustom = quantity >= 12000;

  if (suggestCustom) {
    return (
      <div
        className={classNames(
          styles["payg-sub-view-card"],
          styles["suggest-custom"]
        )}
      >
        <div
          className={classNames(
            styles["view-card-content"],
            styles["suggest-custom"]
          )}
        >
          <div>
            <h3 className="text-white font-weight-bold">
              Contact us for bespoke pricing
            </h3>
            <p className={styles["view-card-text"]}>
              Looks like your adding in a lot amount of minutes. We could offer
              you a better value for money with one of our bespoke subscription
              plans! Reach out to our friendly sales team now.
            </p>
          </div>
          <Button
            secondaryBorder
            className={styles["sales-button"]}
            to={SALES_CONTACT}
            type="blank"
          >
            Contact sales
          </Button>
        </div>
      </div>
    );
  }

  if (suggestPro || suggestPremium || suggestBusiness) {
    const upsellSuggestion = suggestPro
      ? PlanLabelTypes.Pro
      : suggestPremium
      ? PlanLabelTypes.Premium
      : PlanLabelTypes.Business;
    const cardData = getCardData(upsellSuggestion);
    const upsellProPremium =
      upsellSuggestion === "Pro" || upsellSuggestion === "Premium";
    const upsellBusiness = upsellSuggestion === "Business";

    return (
      <div
        className={classNames(
          styles["payg-sub-view-card"],
          { [styles["suggest-pro-premium"]]: upsellProPremium },
          { [styles["suggest-business"]]: upsellBusiness }
        )}
      >
        <div className={styles["view-card-content"]}>
          <div>
            <p className="text-white mb-2">{cardData.subheading}</p>
            <h3 className="text-white font-weight-bold">{cardData.heading}</h3>
          </div>
          <div className={styles["list-price"]}>
            <FeatureList features={{ items: cardData.featuresList }} />
            <div>
              <span>For only</span>
              <h3 className="font-weight-bold pr-6">
                ${cardData.monthlyPrice} <span>/ month</span>
              </h3>
            </div>
          </div>
          <Button
            primary
            className={styles["suggestion-button"]}
            onClick={() => setIsPAYGView((s) => !s)}
          >
            {cardData.buttonText}
          </Button>
        </div>

        <div className={styles["plan-icon"]}>
          <PlanIcon plan={cardData.planData} />
        </div>
      </div>
    );
  }

  if (isPAYGView) {
    return (
      <div
        className={classNames(
          styles["payg-sub-view-card"],
          styles["payg-sub-view-card--sub-version"]
        )}
      >
        <div className={styles["view-card-content"]}>
          <div>
            <h3 className="font-weight-bold">Subly Subscriptions</h3>
            <p className={styles["view-card-text--sub-version"]}>
              By far the best value for money. Subly’s monthy plans provides you
              with ample storage and minutes to continuously create content.
            </p>
          </div>
          <Button
            primary
            className={classNames(
              buttonStyle["plans-button"],
              buttonStyle["payg-sub-view"]
            )}
            onClick={() => {
              setIsPAYGView((s) => !s);
              executeScroll();
            }}
          >
            View Subscription plans
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className={styles["payg-sub-view-card"]}>
      <div className={styles["view-card-content"]}>
        <div>
          <h3 className="font-weight-bold">Pay as you go</h3>
          <p className={styles["view-card-text"]}>
            Only need to use Subly as a one off? Checkout our pay as you go
            pricing if you are only after Subly for occasional use.
          </p>
        </div>
        <Button
          primary
          className={classNames(
            buttonStyle["plans-button"],
            buttonStyle["payg-sub-view"]
          )}
          onClick={() => {
            setIsPAYGView((s) => !s);
            executeScroll();
          }}
        >
          View pay as you go pricing
        </Button>
      </div>
    </div>
  );
};

export interface getCardData {
  subheading: string;
  heading: string;
  planData: SublyPlanData;
  monthlyPrice: string;
  yearlyPrice: string;
  buttonText: string;
  modalButtonText: string;
  featuresList: string[];
  minutes: string;
}

export const getCardData = (
  suggestion: PlanLabelTypes,
  interval?: PlanInterval
): getCardData => {
  const isYearly = interval === PlanInterval.Yearly;
  switch (suggestion) {
    case PlanLabelTypes.Pro:
      return {
        subheading:
          "Looks like you’re trying to purchase over 30 minutes for $18 USD",
        heading:
          "Let us help you save. Try Subly’s flexible Pro subscription plan",
        planData: isYearly
          ? getPlanData(SublyPlan.Pro100Yearly)
          : getPlanData(SublyPlan.Pro100),
        monthlyPrice: "17",
        yearlyPrice: "14",
        buttonText: "View Pro plan",
        modalButtonText: "to Subly Pro",
        featuresList: ["100 mins / month", "10 GB storage", "1 GB file upload"],
        minutes: "100"
      };
    case PlanLabelTypes.Premium:
      return {
        subheading:
          "Looks like you’re trying to purchase over 100 minutes for $60 USD",
        heading:
          "Let us help you save. Try Subly’s flexible Premium subscription plan",
        planData: isYearly
          ? getPlanData(SublyPlan.PremiumYearly)
          : getPlanData(SublyPlan.Premium),
        monthlyPrice: "39",
        yearlyPrice: "31",
        buttonText: "View Premium plan",
        modalButtonText: "to Subly Premium",
        featuresList: ["240 mins / month", "50 GB storage", "2 GB file upload"],
        minutes: "240"
      };
    case PlanLabelTypes.Business:
      return {
        subheading: "Looks like you’re trying to purchase over 900 minutes",
        heading: "Save with Subly Business",
        planData: isYearly
          ? getPlanData(SublyPlan.BusinessYearly)
          : getPlanData(SublyPlan.Business),
        monthlyPrice: "162",
        yearlyPrice: "135",
        buttonText: "View Business plan",
        modalButtonText: "to Subly Business",
        featuresList: [
          "1000 mins / month",
          "200 GB storage",
          "5 GB file upload"
        ],
        minutes: "1000"
      };
  }
};
