import React from "react";
import { Button } from "../../../../components/button/button";
import { ShareUserIcon } from "../../../../components/icons/icons";
import "./referral-nav.scss";

interface ReferralNavProps {
  showReferralModal: (show: boolean) => void;
}
export const ReferralNav: React.FC<ReferralNavProps> = ({
  showReferralModal
}) => {
  const handleShowUpload = () => {
    showReferralModal(true);
  };

  return (
    <nav className="navbar justify-content-between my-2">
      <h3 className="title text-black">Your referrals</h3>

      <Button primary className="ml-2" onClick={handleShowUpload}>
        <ShareUserIcon className="mr-2" />
        Invite a friend
      </Button>
    </nav>
  );
};
