import { mdiCamera } from "@mdi/js";
import Icon from "@mdi/react";
import classNames from "classnames";
import React, { useContext, useState } from "react";
import { EN } from "../../../assets/i18n/en";
import { Button } from "../../../components/button/button";
import { FoundingMembersBadge } from "../../../components/icons/icons";
import { LoadingIcon } from "../../../components/loading-icon/loading-icon";
import {
  notificationError,
  notificationSuccess
} from "../../../components/notification";
import { ProfileImageIcon } from "../../../components/profile-image-icon/profile-image-icon";
import { UploadButton } from "../../../components/upload-button/upload-button";
import { AuthContext } from "../../../contexts/auth.context";
import { EDIT_ACCOUNT_PATH } from "../../../routes";
import { verifyEmailResend } from "../../../api/auth.service";
import "./account-card.scss";

export const AccountCard: React.FC = () => {
  const { user, isFoundingMember, isGoogleAuth } = useContext(AuthContext);
  const [sendingConfirmation, setSendingConfirmation] = useState(false);

  if (!user) {
    return null;
  }

  const handleRequestVerifyEmail = async () => {
    setSendingConfirmation(true);

    try {
      await verifyEmailResend();

      notificationSuccess(
        "Please check your email to confirm your email address."
      );
    } catch (e) {
      console.error(e);

      notificationError(EN.error.defaultMessage);
    }

    setSendingConfirmation(false);
  };

  return (
    <div className="account-card card my-4">
      {isFoundingMember && <FoundingMemberHeader />}

      <div className="card-body p-4">
        <h6 className="title">Profile</h6>

        <div className="mt-2">
          <label className="font-weight-bold">Profile photo</label>
          <div className="mt-2 profile-picture-container">
            <UploadProfilePicture />
          </div>
        </div>

        <div className="mt-4">
          <label className="font-weight-bold">Name</label>
          <p>{user.name}</p>
        </div>

        <div
          className={classNames("mt-4", {
            ["font-italic"]: !user.emailConfirmed
          })}
        >
          <label className="font-weight-bold">Email</label>
          <p>{user.email}</p>
          {!user.emailConfirmed && (
            <Button
              className="w-50"
              primary
              onClick={handleRequestVerifyEmail}
              loading={sendingConfirmation}
            >
              Confirm Email
            </Button>
          )}
        </div>

        {!isGoogleAuth && (
          <div className="mt-4">
            <label className="font-weight-bold">Password</label>
            <p>••••••••••</p>
          </div>
        )}

        <div className="mt-4 account-profile-card-actions">
          <Button primary to={EDIT_ACCOUNT_PATH}>
            Change details
          </Button>
        </div>
      </div>
    </div>
  );
};

const UploadProfilePicture: React.FC = () => {
  const { user, uploadProfilePicture, userPicture } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  if (!user) {
    return null;
  }

  const handleUpload = async (file: File) => {
    try {
      setLoading(true);

      await uploadProfilePicture(file);

      notificationSuccess("Your profile has been updated.");
    } catch (e) {
      console.error(e);

      notificationError(EN.error.defaultMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="profile-picture">
      <UploadButton accept="image/*" onChange={handleUpload}>
        <ProfileImageIcon
          src={userPicture}
          name={user.name}
          size="100%"
          isUploader={true}
        />

        {loading ? (
          <div className="loading">
            <LoadingIcon primary />
          </div>
        ) : (
          <div className="icon-holder">
            <Icon path={mdiCamera} color="white" size="2rem" />
            <div className="icon-holder-text">Upload</div>
          </div>
        )}
      </UploadButton>
    </div>
  );
};

const FoundingMemberHeader: React.FC = () => {
  return (
    <div className="card-header founding-members-header">
      <FoundingMembersBadge className="mr-2" />
      SUBLY FOUNDING MEMBER
    </div>
  );
};
