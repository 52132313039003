import { format } from "date-fns";
import React from "react";
import Lottie from "react-lottie-player";
import { Modal } from "../../containers/modal/modal";
import { usePlan } from "../../hooks/use-plan";
import { Button } from "../button/button";
import trialWelcomeAnimation from "../../assets/images/trial/trial-welcome-animation.json";
import { UploadModal } from "../../containers/dashboard/upload-modal/upload-modal";
import { useModal } from "../../hooks/use-modal";
import { ModalType } from "../../interfaces/modal-type";
import { uploadQuery, uploadStore } from "../../state/upload";
import styles from "./trial-welcome-modal.module.scss";

interface TrialWelcomeModalProps {
  closeModal: () => void;
}
export const TrialWelcomeModal: React.FC<TrialWelcomeModalProps> = ({
  closeModal
}) => {
  const [showUploadModal, closeUploadModal] = useModal(ModalType.Upload); // add another one for storage or share?
  const { trialEndDate } = usePlan();

  const handleCloseModal = () => {
    closeUploadModal();
    if (!uploadQuery.getValue().isUploading) {
      uploadStore.resetUpload();
    }
  };

  const showUploadVideoModal = () => {
    closeModal();
    showUploadModal(<UploadModal closeModal={handleCloseModal} />);
  };

  return (
    <Modal onDismiss={closeModal} className={styles["modal"]} hasCloseIcon>
      <div className={styles["box"]}>
        <div className={styles["content"]}>
          <h5 className={styles["header"]}>Welcome to Subly's 7-day trial!</h5>
          <p>
            Enjoy 40 minutes of credit to use for subtitling and translating
            content. You get <b>2 free downloads</b> as part of our free trial.
            Access to minutes and downloads expires on{" "}
            {trialEndDate && format(new Date(trialEndDate), "MMMM do")}.<br />
            <br />
            Let’s get this party started!
          </p>
        </div>

        <div className={styles["animation-container"]}>
          <Lottie
            loop
            animationData={trialWelcomeAnimation}
            speed={0.5}
            className={styles["animation"]}
            play
          />
        </div>

        <div className={styles["button-container"]}>
          <Button
            primary
            className={styles["button"]}
            onClick={showUploadVideoModal}
          >
            Upload video or audio
          </Button>
        </div>
      </div>
    </Modal>
  );
};
