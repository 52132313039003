import {
  akitaConfig,
  akitaDevtools,
  enableAkitaProdMode
} from "@datorama/akita";
import { devTools } from "@ngneat/elf-devtools";
import { enableElfProdMode } from "@ngneat/elf";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import React from "react";
import ReactDOM from "react-dom";
import TagManager from "react-gtm-module";
import App from "./App";
import config from "./config";
import * as serviceWorker from "./serviceWorker";
import "./styles/index.scss";

if (config.isProduction) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: config.isProduction ? "production" : "development",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    release: process.env.REACT_APP_SENTRY_RELEASE
  });
}

// Google Tag manager
const tagManagerArgs = {
  gtmId: config.gtmId
};

TagManager.initialize(tagManagerArgs);

if (config.isProduction) {
  enableAkitaProdMode();
  enableElfProdMode();
} else {
  akitaDevtools({ name: "Subly Akita" });
  devTools({ name: "Subly Elf" });
}

akitaConfig({ resettable: true });

// This function transforms the --vh css var when there is a resize of the viewport. Handy to
// handle the 100vh in mobile browsers. We have a postcss transforming all vh in css to use
// this var, but it is backword compatible.
// https://github.com/Faisal-Manzer/postcss-viewport-height-correction
function setViewportProperty(doc: HTMLElement) {
  let prevClientHeight: number;

  function handleResize() {
    const clientHeight = doc.clientHeight;

    if (clientHeight === prevClientHeight) return;

    requestAnimationFrame(function updateViewportHeight() {
      doc.style.setProperty("--vh", clientHeight * 0.01 + "px");
      prevClientHeight = clientHeight;
    });
  }

  handleResize();

  return handleResize;
}

window.addEventListener(
  "resize",
  setViewportProperty(document.documentElement)
);

ReactDOM.render(<App />, document.getElementById("root"));
serviceWorker.unregister();
