import { Store, StoreConfig } from "@datorama/akita";
import { Transcription } from "@getsubly/common";
import { DefaultFoldersId } from "../folders/folders.store";

export interface UploadFileBase {
  id: string;
  mediaId?: string;
  mediaName: string;
  filename: string;
  size: number;
  duration: number;
  isUploading: boolean;
  uploaded: boolean;
  isAudio: boolean;
  // language: string;
  error?: UploadErrorTypes | string;
  warning?: string;
}

export interface UploadFile extends UploadFileBase {
  media: File;
  isGoogleDrive?: boolean;
}

export interface UploadUrlFile extends UploadFileBase {
  url: string;
  isUrl: true;
}

export interface UploadZoomFile extends UploadFileBase {
  meetingId: number;
  fileId: string;
  isZoom: true;
}

export const isUploadFile = (
  file: UploadFile | UploadUrlFile | UploadZoomFile
): file is UploadFile => Boolean((file as UploadFile)?.media);

export const isUploadUrlFile = (
  file: UploadFile | UploadUrlFile | UploadZoomFile
): file is UploadUrlFile => Boolean((file as UploadUrlFile)?.isUrl);

export const isUploadZoomFile = (
  file: UploadFile | UploadUrlFile | UploadZoomFile
): file is UploadZoomFile => Boolean((file as UploadZoomFile)?.isZoom);

export enum UploadErrorTypes {
  file = "Error loading this file. Try again",
  credit = "Insufficient credits",
  storage = "Insufficient storage",
  duration = "The file is over maximum duration of 4 hours",
  fileSize = "File size is too large",
  fileSizeMax500MB = "File size is too large. Reduce the size to max 500MB",
  fileSizeMax2GB = "File size is too large. Reduce the size to max 2GB",
  fileSizeMax5GB = "File size is too large. Reduce the size to max 5GB"
}

export interface UploadState {
  isChecking: boolean;
  isUploading: boolean;
  progress: number;
  thumbnail?: string;
  mediaName: string;
  isGoogleDrive?: boolean;
  language: string;
  folderId?: string;
  transcription?: Transcription;
  queue: (UploadFile | UploadUrlFile | UploadZoomFile)[];
}

export const createInitialState = (): UploadState => ({
  isChecking: false,
  isUploading: false,
  progress: 0,
  mediaName: "",
  language: "",
  folderId: DefaultFoldersId.All,
  queue: [],
  transcription: undefined
});

@StoreConfig({ name: "upload", resettable: true })
export class UploadStore extends Store<UploadState> {
  constructor() {
    super(createInitialState());
  }

  setChecking = (isChecking: boolean): void => {
    this.update((s) => ({
      ...s,
      isChecking
    }));
  };

  setTranscription = (transcription?: Transcription): void => {
    this.update((s) => ({
      ...s,
      transcription
    }));
  };

  resetUpload = (): void => {
    this.update(createInitialState());
  };

  addFiles = (files: (UploadFile | UploadUrlFile | UploadZoomFile)[]): void => {
    const newFileIds = files.map((f) => f.id);

    this.update((s) => ({
      ...s,
      queue: [...s.queue.filter((f) => !newFileIds.includes(f.id)), ...files]
    }));
  };

  updateFile = (id: string, data: Partial<UploadFile>): void => {
    this.update((s) => {
      const { queue } = s;
      const index = queue.findIndex((f) => f.id === id);
      if (index > -1) {
        queue[index] = {
          ...queue[index],
          ...data
        };
      }
      return {
        ...s,
        queue
      };
    });
  };

  removeFile = (fileId: string): void => {
    this.update((s) => ({
      ...s,
      queue: [...s.queue.filter((f) => f.id !== fileId)]
    }));
  };

  removeFileByMediaId = (id: string): void => {
    this.update((s) => ({
      ...s,
      queue: [...s.queue.filter((f) => f.mediaId !== id)]
    }));
  };

  removeErrorFiles = (): void => {
    this.update((state) => ({
      queue: state.queue.filter((f) => !f.error)
    }));
  };

  setFileUpload = (id: string): void => {
    this.updateFile(id, { isUploading: true });
  };

  setUploadProgress = (progress: number): void => {
    this.update((state) => ({
      ...state,
      progress
    }));
  };
}

export const uploadStore = new UploadStore();
