import classNames from "classnames";
import React from "react";
import { AnalyticsContext } from "../../contexts/analytics";
import { useQuery } from "../../hooks/use-query";
import { useSession } from "../../hooks/use-session";
import { PRIVACY_URL, TERMS_URL } from "../../routes";
import { getGoogleSignInHref } from "../../utils/links";
import { Checkbox } from "../checkbox/checkbox";
import styles from "./google-button.module.scss";

export interface Params {
  state?: string;
  redirect?: string;
  invite?: string;
  gclid?: string;
}
interface GoogleButtonProps {
  mixpanelAction?: string;
  className?: string;
  acceptTerms?: boolean;
}
export const GoogleButton: React.FC<GoogleButtonProps> = ({
  mixpanelAction,
  className,
  acceptTerms = false,
  children
}) => {
  const [acceptedTerms, setAcceptedTerms] = React.useState(false);
  const [acceptingTerms, setAcceptingTerms] = React.useState(false);
  const [showTermsError, setShowTermsError] = React.useState(false);
  const { trackEvent } = React.useContext(AnalyticsContext);
  const { state, redirect, invite, gclid } = useQuery<Params>();
  const { update: updateSession } = useSession();

  if (acceptTerms && !acceptingTerms && !acceptedTerms) {
    return (
      <GoogleButtonContainer
        className={className}
        onClick={() => setAcceptingTerms(true)}
      >
        {children}
      </GoogleButtonContainer>
    );
  }
  const href = getGoogleSignInHref(state || redirect, gclid);

  if (invite) {
    updateSession({ invite });
  }

  if (acceptTerms) {
    return (
      <>
        <Checkbox
          className="mb-3"
          value={acceptedTerms}
          onChange={setAcceptedTerms}
        >
          <p
            className={classNames("small mb-0", {
              "text-black": !showTermsError,
              "text-feedback-error": showTermsError
            })}
          >
            By clicking on Sign up, I agree to the{" "}
            <a
              href={TERMS_URL}
              target="_blank"
              rel="noopener noreferrer"
              className={classNames({ "text-feedback-error": showTermsError })}
            >
              Terms of Service
            </a>{" "}
            and{" "}
            <a
              href={PRIVACY_URL}
              target="_blank"
              rel="noopener noreferrer"
              className={classNames({ "text-feedback-error": showTermsError })}
            >
              Privacy Policy.
            </a>
          </p>
        </Checkbox>
        <GoogleButtonContainer
          href={acceptedTerms ? href : undefined}
          className={className}
          onClick={() => {
            if (!acceptedTerms) {
              setShowTermsError(true);
              return;
            }
          }}
        >
          Continue with Google
        </GoogleButtonContainer>
      </>
    );
  }

  return (
    <GoogleButtonContainer
      href={href}
      className={className}
      onClick={() =>
        mixpanelAction && trackEvent(mixpanelAction, { redirectTo: state })
      }
    >
      {children}
    </GoogleButtonContainer>
  );
};

interface GoogleButtonContainerProps {
  href?: string;
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
}
const GoogleButtonContainer: React.FC<GoogleButtonContainerProps> = ({
  href,
  className,
  onClick,
  disabled,
  children
}) => {
  if (!href) {
    return (
      <div
        className={classNames(styles["google-button"], className, {
          [styles["google-button--disabled"]]: disabled
        })}
        onClick={onClick}
      >
        <div className={styles["customGPlusSignIn"]}>
          <span className={styles["icon"]} />
          <span className={styles["label"]}>{children}</span>
        </div>
      </div>
    );
  }

  return (
    <a
      href={href}
      className={classNames(styles["google-button"], className, {
        [styles["google-button--disabled"]]: disabled
      })}
      onClick={onClick}
    >
      <div className={styles["customGPlusSignIn"]}>
        <span className={styles["icon"]} />
        <span className={styles["label"]}>{children}</span>
      </div>
    </a>
  );
};
