import classNames from "classnames";
import React from "react";
import { SkeletonTheme } from "react-loading-skeleton";
import { NavContext } from "../../../contexts/nav-context";
import { TopBannerContext } from "../../../contexts/top-banner.context";
import { WorkspacesDropdown } from "../workspace-dropdown/workspaces-dropdown";
import styles from "./sidebar.module.scss";

interface SidebarContainerProps {
  isSettings?: boolean;
}
export const SidebarContainer: React.FC<SidebarContainerProps> = ({
  isSettings = false,
  children
}) => {
  const { isTopBannerVisible } = React.useContext(TopBannerContext);
  const { showSidebar, toggleSidebar } = React.useContext(NavContext);
  const skeletonColor = isSettings ? "#4f1191" : "#4f1191";
  const skeletonHighlightColor = isSettings ? "#410d79" : "#410d79";

  return (
    <>
      <div
        className={classNames(styles.bg, {
          [styles["bg--open"]]: showSidebar
        })}
        onClick={() => toggleSidebar(false)}
      ></div>
      <SkeletonTheme
        baseColor={skeletonColor}
        highlightColor={skeletonHighlightColor}
      >
        <div
          className={classNames(styles.sidebar, {
            [styles["with-banner"]]: isTopBannerVisible,
            [styles["settings"]]: isSettings,
            [styles["sidebar--open"]]: showSidebar,
            "animate animate-slide-right": showSidebar
          })}
        >
          <div className={styles["sidebar-container"]}>{children}</div>

          <WorkspacesDropdown />
        </div>
      </SkeletonTheme>
    </>
  );
};
