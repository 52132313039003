import { mdiFolder } from "@mdi/js";
import Icon from "@mdi/react";
import { useObservable } from "@mindspace-io/react";
import classNames from "classnames";
import React from "react";
import Skeleton from "react-loading-skeleton";
import { useFolders } from "../../../hooks/use-folders";
import { useOnClickOutside } from "../../../hooks/use-on-click-outside";
import { foldersQuery } from "../../../state/folders/folders.query";
import { updateFolder } from "../../../state/folders/folders.service";
import { Folder } from "../../../state/folders/folders.store";
import styles from "./sidebar.module.scss";

interface SidebarFolderProps {
  folder: Folder;
}
export const SidebarFolder: React.FC<SidebarFolderProps> = ({ folder }) => {
  const { selectedId, selectFolder } = useFolders();
  const { id, name, count } = folder;
  const isActive = selectedId === id;
  const [isEditing] = useObservable(foldersQuery.selectIsEditing(id), false);
  const [isLoading] = useObservable(foldersQuery.selectIsLoading(id), false);

  if (isLoading) {
    return <SidebarFolderSekeleton />;
  }

  if (!isEditing) {
    return (
      <SidebarFolderContainer
        isActive={isActive}
        onClick={() => selectFolder(id)}
      >
        <span className="d-block w-100 smaller text-truncate">
          {name} ({count ?? 0})
        </span>
      </SidebarFolderContainer>
    );
  }

  return (
    <SidebarFolderContainer
      isActive={isActive}
      onClick={() => selectFolder(id)}
    >
      <SidebarFolderEditor folder={folder} />
    </SidebarFolderContainer>
  );
};

interface SidebarFolderContainerProps {
  onClick?: () => void;
  isActive?: boolean;
  hideIcon?: boolean;
}
const SidebarFolderContainer: React.FC<SidebarFolderContainerProps> = ({
  onClick,
  isActive,
  hideIcon = false,
  children
}) => {
  return (
    <div
      className={classNames(styles["sidebar-folder"], {
        [styles["sidebar-folder--active"]]: isActive
      })}
      onClick={onClick}
    >
      {!hideIcon && <Icon path={mdiFolder} size="14px" />}
      {children}
    </div>
  );
};

interface SidebarFolderEditorProps {
  folder: Folder;
}
const SidebarFolderEditor: React.FC<SidebarFolderEditorProps> = ({
  folder
}) => {
  const [name, setName] = React.useState(folder.name);
  const ref = React.useRef<HTMLInputElement | null>(null);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    saveName();
  };

  const saveName = async () => {
    if (!name) {
      return;
    }

    updateFolder({ ...folder, name });
  };

  useOnClickOutside(ref, saveName);

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        name="name"
        className={styles["sidebar-folder--input"]}
        autoFocus
        ref={ref}
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
    </form>
  );
};

export const SidebarFolderSekeleton: React.FC = () => {
  return (
    <SidebarFolderContainer hideIcon>
      <span className="d-block w-100 smaller text-truncate">
        <Skeleton />
      </span>
    </SidebarFolderContainer>
  );
};
