// TODO: Move this file somewhere else, no longer onboarding stuff
import classNames from "classnames";
import React from "react";
import { useForm } from "react-hook-form";
import { Button } from "../../../components/button/button";
import { ProfileIcon, TrashCanIcon } from "../../../components/icons/icons";
import { useAccounts } from "../../../hooks/use-accounts";
import { Invitation, RoleName } from "../../../interfaces/account";
import { emailPattern } from "../../../utils/regex-patterns";
import { compareStrings } from "../../../utils/strings";
import styles from "../onboarding.module.scss";
import { RoleNameDropdown } from "./role-name-dropdown";

type EmailForm = {
  email: string;
};
interface InviteEmailsContainerProps {
  invitations: Invitation[];
  setInvitations: React.Dispatch<React.SetStateAction<Invitation[]>>;
  label?: string;
}
export const InviteEmailsContainer: React.FC<InviteEmailsContainerProps> = ({
  invitations,
  setInvitations,
  label = "Email"
}) => {
  const { user } = useAccounts();
  const { currentAccount } = useAccounts();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch
  } = useForm<EmailForm>({
    mode: "all"
  });

  const allMembers = currentAccount?.members ?? [];
  const allInvitations = currentAccount?.invitations ?? [];
  const allUsers = [...allMembers, ...allInvitations];

  const onSubmit = ({ email }: EmailForm) => {
    if (invitations.find((i) => compareStrings(email, i.email))) {
      return;
    }

    setInvitations((s) => [
      ...s,
      { id: "", email: email.toLowerCase(), role: RoleName.Editor }
    ]);
    setValue("email", "");
  };

  const removeInvitation = (invitation: Invitation) => {
    setInvitations((s) => s.filter((i) => i.email != invitation.email));
  };

  const handleChange = (invitation: Invitation, role: RoleName) => {
    setInvitations((s) =>
      s.map((i) => {
        if (i.email === invitation.email) {
          i.role = role;
        }

        return i;
      })
    );
  };

  const canAdd = Boolean(!errors.email && watch().email);

  const renderInvitations = invitations.map((i) => {
    return (
      <InvitationRow
        key={i.email}
        invitation={i}
        onDelete={removeInvitation}
        onChange={handleChange}
      />
    );
  });

  return (
    <div className="mt-4">
      <form onSubmit={handleSubmit(onSubmit)}>
        <label>{label}</label>
        <div className="form-row">
          <div className="col">
            <input
              type="email"
              className="form-control"
              placeholder="Email address"
              {...register("email", {
                required: true,
                pattern: emailPattern,
                validate: {
                  isOwner: (email: string) => {
                    return (
                      !compareStrings(email, user?.email) ||
                      "You can't invite yourself"
                    );
                  },
                  hasInvited: (email: string) => {
                    return (
                      !invitations.some((i) =>
                        compareStrings(email, i.email)
                      ) || "You already invited this user"
                    );
                  },
                  hasMember: (email: string) => {
                    return (
                      !allUsers.some((i) => email === i.email) ||
                      "You already invited this user"
                    );
                  },
                  maxInvitations: () => {
                    const teamCapacity = currentAccount?.teamCapacity ?? 1;
                    const totalMembers = allUsers.length + invitations.length;

                    return (
                      totalMembers < teamCapacity ||
                      `You have reached team capacity. Upgrade to Business for a bigger team`
                    );
                  }
                }
              })}
            />
          </div>
          <div className="col-auto">
            <Button
              secondary={!canAdd}
              primary={canAdd}
              disabled={!canAdd}
              type="submit"
              className={styles["add-button"]}
            >
              Add
            </Button>
          </div>
        </div>
      </form>
      {Boolean(errors.email?.message) && (
        <p className="small mt-1 text-feedback-error">
          {errors.email?.message}
        </p>
      )}
      <div className="mt-2">{renderInvitations}</div>
    </div>
  );
};

interface InvitationRowProps {
  invitation: Invitation;
  onDelete: (invitation: Invitation) => void;
  onChange: (invitation: Invitation, role: RoleName) => void;
}
const InvitationRow: React.FC<InvitationRowProps> = ({
  invitation,
  onDelete,
  onChange
}) => {
  const { isAdmin } = useAccounts();

  const handleRoleChange = (role: RoleName) => {
    onChange(invitation, role);
  };

  return (
    <div className={styles.invitation}>
      <ProfileIcon className={styles.icon} />
      <span className={classNames(styles.email, "text-truncate")}>
        {invitation.email}
      </span>
      <RoleNameDropdown
        value={invitation.role}
        onChange={handleRoleChange}
        isAdmin={isAdmin}
      />
      <span className={styles.delete} onClick={() => onDelete(invitation)}>
        <TrashCanIcon />
      </span>
    </div>
  );
};
