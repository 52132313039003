import React from "react";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { LOGIN_PATH, SIGN_UP_PATH } from "../routes";
import { cleanSearchString } from "../utils/links";
import { PromoState } from "../utils/plans";

export const PromoAuthPage: React.FC = () => {
  const { plan } = useParams();
  const { pathname: path, search, hash } = useLocation();

  const pathname = path.includes("/p/login") ? LOGIN_PATH : SIGN_UP_PATH;

  const cleanSearch = cleanSearchString(search, "&");

  switch (plan) {
    case PromoState.Pro50:
      return (
        <Navigate
          to={{ pathname, search: `state=${plan}${cleanSearch}`, hash }}
        />
      );

    default:
      return <Navigate to={{ pathname, search, hash }} />;
  }
};
