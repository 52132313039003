import { UploadIcon } from "@heroicons/react/outline";
import { mdiCloudUpload } from "@mdi/js";
import Icon from "@mdi/react";
import classNames from "classnames";
import React, { useCallback, useContext } from "react";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";
import { AppId, listApps } from "../../../api/apps.service";
import { getGoogleDriveFile } from "../../../api/file.service";
import { AppModal } from "../../../components/app-modal/app-modal";
import { Button } from "../../../components/button/button";
import { ZoomIcon } from "../../../components/icons/icons";
import { LockFeaturePremium } from "../../../components/lock-feature/lock-feature-premium";
import { notificationError } from "../../../components/notification";
import config from "../../../config";
import { TopBannerContext } from "../../../contexts/top-banner.context";
import { useAnalyticsWithAuth } from "../../../hooks/use-analytics-with-auth";
import { useModal } from "../../../hooks/use-modal";

import { GoogleDriveFileInfo } from "../../../interfaces/google-picker";
import { ModalType } from "../../../interfaces/modal-type";
import { APP_LIST } from "../../../pages/apps/apps-home-page";
import "./file-drop-card.scss";
import { GoogleDriveUploader } from "./google-drive-uploader";

interface FileDropCardProps {
  setFiles: (files?: File[], isGoogleDrive?: boolean) => void;
  className?: string;
  activeClassName?: string;
  dropActiveChildren?: React.ReactNode;
  noClick?: boolean;
  srtUpload?: boolean;
  multiple?: boolean;
  disableGoogleDrive?: boolean;
  accept: string[];
  disabled?: boolean;
}

export const FileDropCard: React.FC<FileDropCardProps> = ({
  setFiles,
  className,
  activeClassName = "",
  children,
  noClick = false,
  dropActiveChildren = <FileDropCardTextActiveDragging />,
  disableGoogleDrive,
  srtUpload,
  multiple,
  accept,
  disabled = false
}) => {
  const { isTopBannerVisible } = useContext(TopBannerContext);
  const { trackEventWithAuth } = useAnalyticsWithAuth();

  const onDrop = useCallback(
    (files: File[]) => {
      const eventName =
        files.length > 1 ? "Select multiple files" : "Select standard file";
      trackEventWithAuth(eventName);

      setFiles(files);
    },
    [setFiles, trackEventWithAuth]
  );

  const { getRootProps, getInputProps, isDragAccept, isDragReject } =
    useDropzone({
      onDrop,
      accept,
      minSize: 0,
      multiple,
      noClick,
      disabled
    });

  const handleDriveFileSelection = async (
    fileInfo: GoogleDriveFileInfo
  ): Promise<void> => {
    if (disableGoogleDrive) {
      return;
    }

    const file = await getGoogleDriveFile(fileInfo);
    const isGoogleDrive = true;

    setFiles([file], isGoogleDrive);
  };

  return (
    <div
      className={classNames("upload-zone", className, {
        active: isDragAccept,
        error: isDragReject,
        "with-banner": isTopBannerVisible,
        [activeClassName]: isDragAccept && activeClassName
      })}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      {isDragAccept
        ? dropActiveChildren
        : children || (
            <FileDropCardText
              handleDriveFileSelection={handleDriveFileSelection}
              accept={accept}
              multiple={multiple}
              hasGoogleDrive={!disableGoogleDrive}
              srtUpload={srtUpload}
            />
          )}
    </div>
  );
};

interface FileDropCardTextProps {
  handleDriveFileSelection: (fileInfo: GoogleDriveFileInfo) => Promise<void>;
  accept: string[];
  multiple?: boolean;
  hasGoogleDrive: boolean;
  srtUpload?: boolean;
}

const FileDropCardText: React.FC<FileDropCardTextProps> = ({
  handleDriveFileSelection,
  accept,
  multiple,
  hasGoogleDrive,
  srtUpload
}) => {
  const [showModal, hideModal] = useModal(ModalType.AppModal);
  const navigate = useNavigate();
  const zoomApp = APP_LIST.find((a) => a.appId === AppId.Zoom);

  const handleZoomUpload = async (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    if (!zoomApp) {
      notificationError("The information about Zoom integration is not found");
      return;
    }

    const apps = await listApps();
    const isConnected = apps?.some((a) => a.appId === zoomApp.appId) ?? false;

    if (isConnected) {
      navigate(zoomApp.link);
    } else {
      showModal(
        <AppModal
          app={{ ...zoomApp, connected: isConnected }}
          closeModal={hideModal}
        />
      );
    }
  };

  return (
    <>
      <div className="d-mouse-flex flex-column align-items-center">
        <p className="text mb-0">Drag and drop files here</p>
        <p className="text mb-2">or</p>
        <Button primary className="upload-btn">
          <UploadIcon color="white" className="icon" />
          Browse
        </Button>

        {hasGoogleDrive && (
          <GoogleDriveUploader
            className="upload-btn"
            accept={accept}
            multiple={multiple}
            handleGoogleDriveFileSelection={handleDriveFileSelection}
          />
        )}
        {config.features.hasApps && (
          <LockFeaturePremium>
            <Button
              secondary
              className="upload-btn"
              onClick={(e) => handleZoomUpload(e)}
            >
              <ZoomIcon className="icon" />
              Select from Zoom
            </Button>
          </LockFeaturePremium>
        )}
      </div>

      <div className="d-touch-flex flex-column align-items-center">
        <Icon
          path={mdiCloudUpload}
          color="var(--text-primary)"
          size="28px"
          className="mb-3"
        />

        <Button primary className="upload-btn">
          <UploadIcon color="white" className="icon" />
          Browse
        </Button>

        {hasGoogleDrive && (
          <GoogleDriveUploader
            className="upload-btn"
            accept={accept}
            multiple={multiple}
            handleGoogleDriveFileSelection={handleDriveFileSelection}
          />
        )}

        <LockFeaturePremium>
          <Button secondary className="upload-btn">
            <ZoomIcon className="icon" />
            Select from Zoom
          </Button>
        </LockFeaturePremium>
      </div>
      {srtUpload && (
        <p className="srt-warning">We currently only accept .SRT files.</p>
      )}
    </>
  );
};

const FileDropCardTextActiveDragging: React.FC = () => {
  return <p className="text">Drop it like it's hot</p>;
};

export const DashboardActiveDrop: React.FC = () => {
  return (
    <div className="active-container">
      <FileDropCardTextActiveDragging />
    </div>
  );
};
