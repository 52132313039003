import { CookieConsentsProvider } from "@enzsft/react-cookie-consents";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { AppRoutes } from "./AppRoutes";
import { CookieBanner } from "./components/cookieBanner/cookie-banner";
import { HubspotAnalytics } from "./components/hubspot-analytics";
import config from "./config";
import { ModalsContainer } from "./containers/modals-container";
import { AnalyticsProvider } from "./contexts/analytics";
import { AuthProvider } from "./contexts/auth.context";
import { PricingIntervalToggleProvider } from "./contexts/interval-toggle.context";
import { NotificationsProvider } from "./contexts/notifications.context";
import { SpeakerRecognitionProvider } from "./contexts/speaker-recognition.context";
import { TestimonialProvider } from "./contexts/testimonial.context";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = config.stripe.clientKey
  ? loadStripe(config.stripe.clientKey)
  : null;

const App: React.FC = () => (
  <React.StrictMode>
    <AnalyticsProvider>
      <CookieConsentsProvider cookieName="cookieConsents" expiryInDays={365}>
        <Elements stripe={stripePromise}>
          <TestimonialProvider>
            <Router>
              <AuthProvider>
                <NotificationsProvider>
                  <PricingIntervalToggleProvider>
                    <SpeakerRecognitionProvider>
                      <AppRoutes />
                      <HubspotAnalytics />
                      <ModalsContainer />
                    </SpeakerRecognitionProvider>
                  </PricingIntervalToggleProvider>
                </NotificationsProvider>
              </AuthProvider>
            </Router>
          </TestimonialProvider>
          <CookieBanner />
        </Elements>
      </CookieConsentsProvider>
    </AnalyticsProvider>
  </React.StrictMode>
);

export default App;
