import {
  AspectRatio,
  assColorToHex,
  AssStyle,
  BackgroundStyle,
  BurnPosition,
  CustomLogoConfig
} from "@getsubly/common";
import { isEqual, omit, pick } from "lodash-es";
import { AudioTemplate, AudioTemplateStyle } from "../types/media-editor.types";
import { AccountTemplateStyles } from "../interfaces/account";
import { useMediaEditor } from "../contexts/media-editor.context";
import {
  AUDIO_TEMPLATE_1,
  AUDIO_TEMPLATE_2,
  AUDIO_TEMPLATE_3,
  AUDIO_TEMPLATE_4
} from "../containers/media-editor/toolbars/media-elements-toolbar/templates-dropdown/audio-templates";
import { useArtworkUrl } from "../hooks/use-artwork-url";

export const assStyleToStyleConvert = (
  style?: AccountTemplateStyles
): React.CSSProperties => {
  if (!style) {
    return {};
  }

  const effects = assEffectsToStyle(style);
  const alignment = assAlignmentToStyle(style);

  return {
    fontFamily: style.font,
    // Skip the size to keep it inside the box
    // fontSize: `${style.fontSize}px`,
    lineHeight: 1,
    fontWeight: style.bold === -1 ? "bold" : "normal",
    fontStyle: style.italic === -1 ? "italic" : "",
    textDecoration: style.underline === -1 ? "underline" : "",
    color: assColorToHex(style.colorPrimary),
    ...alignment,
    ...effects
  };
};

const assEffectsToStyle = (style: AssStyle): React.CSSProperties => {
  const color = assColorToHex(style.colorOutline);

  if (style.subtitles?.fullWidth) {
    return {
      width: "100%",
      background: color
    };
  }

  switch (style.backgroundStyle) {
    case BackgroundStyle.Outline:
      return {
        WebkitTextStroke: "0.75px",
        WebkitTextStrokeColor: color
      };
    case BackgroundStyle.DropShadow:
      return {
        textShadow: `${color} 1px 1px`
      };
    case BackgroundStyle.Background:
      return {
        background: color
      };

    default:
      return {};
  }
};

const assAlignmentToStyle = (style: AssStyle): React.CSSProperties => {
  const alignmentValue =
    style.alignment - Math.floor((style.alignment - 1) / 3) * 3;

  switch (alignmentValue) {
    case 1:
      return {
        justifyContent: "flex-start"
      };
    case 3:
      return {
        justifyContent: "flex-end"
      };

    default:
      return {
        justifyContent: "center"
      };
  }
};

const STYLE_ALL_PROPERTIES: Array<keyof AccountTemplateStyles> = [
  "font",
  "fontSize",
  "colorPrimary",
  "colorSecondary",
  "colorOutline",
  "colorBack",
  "bold",
  "italic",
  "underline",
  "strikeOut",
  "spacing",
  "angle",
  "borderStyle",
  "outline",
  "shadow",
  "alignment",
  "marginL",
  "marginR",
  "marginV",
  "encoding",
  "hasBackground",
  "backgroundStyle",
  "showWatermark",
  "customLogo",
  "aspectRatio",
  "border",
  "headline",
  "templateId",
  "customFont",
  "subtitles"
];

export const templateHasChanged = (
  template?: AccountTemplateStyles,
  style?: AccountTemplateStyles
): boolean => {
  if (!template || !style) {
    return false;
  }

  const saved = parseMediaConfig(
    omit(
      template,
      "aspectRatio.ratio",
      "templateId",
      "headline.text",
      "customLogo",
      "aspectRatio.backgroundImage"
    )
  );
  const current = parseMediaConfig(
    omit(
      style,
      "aspectRatio.ratio",
      "templateId",
      "headline.text",
      "customLogo",
      "aspectRatio.backgroundImage"
    )
  );

  return !isEqual(saved, current);
};

export const parseMediaConfig = (
  raw: AccountTemplateStyles
): AccountTemplateStyles => {
  return pick(raw, ...STYLE_ALL_PROPERTIES);
};

export const audioTemplateConfigToStyles = (
  template: AudioTemplate
): {
  cardStyles: React.CSSProperties;
  headlineStyles: React.CSSProperties;
  cueStyles: React.CSSProperties;
  logoStyles: React.CSSProperties;
} => {
  const { mediaConfig } = useMediaEditor();
  let aspectRatio = mediaConfig?.aspectRatio?.ratio ?? AspectRatio.Square;

  if (aspectRatio === AspectRatio.Original) {
    aspectRatio = AspectRatio.Square;
  }

  const [baseWidth, baseHeight] = getRatioWidth(aspectRatio, 313, 313);
  const headlinePosition = getTextPosition(
    template.headline.style,
    baseWidth,
    baseHeight
  );
  const cuePosition = getTextPosition(template, baseWidth, baseHeight);

  const artwork = getBackgroundImage(template.templateId, aspectRatio);
  const artworkUrl = useArtworkUrl(artwork, true);

  return {
    cardStyles: {
      width: baseWidth,
      height: baseHeight,
      backgroundColor: template.aspectRatio.color,
      backgroundImage: `url(${artworkUrl})`,
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      position: "relative",
      borderRadius: "8px"
    },
    headlineStyles: {
      color: assColorToHex(template.headline.style.colorPrimary),
      fontWeight: "bold",
      fontSize: "10px",
      textAlign: getTextAlignment(template.headline.style.alignment),
      margin: 0,
      ...headlinePosition
    },
    cueStyles: {
      color: assColorToHex(template.colorPrimary),
      fontSize: "10px",
      lineHeight: "18px",
      margin: 0,
      textAlign: getTextAlignment(template.alignment),
      ...cuePosition
    },
    logoStyles: getLogoStyles(
      template.customLogo,
      baseWidth,
      baseHeight,
      aspectRatio
    )
  };
};

const getTextAlignment = (alignment: number) => {
  switch (alignment) {
    case 1:
    case 4:
    case 7:
      return "left";
    case 2:
    case 5:
    case 8:
      return "center";
    case 3:
    case 6:
    case 9:
      return "right";
  }
};

const getTextPosition = (
  style: AudioTemplateStyle,
  width: number,
  height: number
): React.CSSProperties => {
  const { marginL, marginV, marginR, alignment } = style;

  const widthRatio = width / 1080;
  const heightRatio = height / 1080;

  switch (alignment) {
    case 1:
    case 2:
    case 3:
      return {
        position: "absolute",
        bottom: `${marginV * heightRatio + 3}px`,
        left: `${marginL * widthRatio}px`,
        right: `${marginR * widthRatio}px`
      };
    case 4:
    case 5:
    case 6:
      return {
        position: "absolute",
        top: `${height * heightRatio + 30}px`,
        left: `${marginL * widthRatio}px`,
        right: `${marginR * widthRatio}px`
      };

    case 7:
    case 8:
    case 9:
      return {
        position: "absolute",
        top: `${marginV * heightRatio}px`,
        left: `${marginL * widthRatio}px`,
        right: `${marginR * widthRatio}px`
      };
  }

  return {};
};

const getRatioWidth = (
  ratio: AspectRatio,
  width: number,
  height: number
): [number, number] => {
  switch (ratio) {
    case AspectRatio.Square:
      break;
    case AspectRatio.Landscape:
      height = height / (16 / 9);
      break;
    case AspectRatio.Portrait:
      width = width / (16 / 9);
      break;
    case AspectRatio.Vertical:
      width = width / (5 / 4);
      break;
  }
  return [width, height];
};

const getLogoStyles = (
  { position, padding }: CustomLogoConfig,
  width: number,
  height: number,
  ratio: AspectRatio
): React.CSSProperties => {
  const widthRatio = width / 1080;
  const heightRatio = height / 1080;

  const size = ratio === AspectRatio.Portrait ? "25px" : "32px";

  switch (position) {
    case BurnPosition.BottomLeft:
      return {
        objectFit: "scale-down",
        position: "absolute",
        bottom: `${padding.y * heightRatio}px`,
        left: `${padding.x * widthRatio}px`,
        height: `${size}`,
        width: `${size}`
      };
    case BurnPosition.BottomRight:
      return {
        objectFit: "scale-down",
        position: "absolute",
        bottom: `${padding.y * heightRatio}px`,
        right: `${padding.x * widthRatio}px`,
        height: `${size}`,
        width: `${size}`
      };
    case BurnPosition.TopLeft:
      return {
        objectFit: "scale-down",
        position: "absolute",
        top: `${padding.y * heightRatio}px`,
        left: `${padding.x * widthRatio}px`,
        height: `${size}`,
        width: `${size}`
      };
    case BurnPosition.TopRight:
      return {
        objectFit: "scale-down",
        position: "absolute",
        top: `${padding.y * heightRatio}px`,
        right: `${padding.x * widthRatio}px`,
        height: `${size}`,
        width: `${size}`
      };
    case BurnPosition.BottomCenter:
      return {};
  }
};

export const getBackgroundImage = (
  templateId: string,
  aspectRatio: AspectRatio
): string => {
  switch (aspectRatio) {
    case AspectRatio.Landscape:
      if (templateId === AUDIO_TEMPLATE_1.templateId) {
        return "audio-templates/template-1-1920x1080.png";
      }
      if (templateId === AUDIO_TEMPLATE_2.templateId) {
        return "audio-templates/template-2-1920x1080.png";
      }
      if (templateId === AUDIO_TEMPLATE_3.templateId) {
        return "audio-templates/template-3-1920x1080.png";
      }
      if (templateId === AUDIO_TEMPLATE_4.templateId) {
        return "audio-templates/template-4-1920x1080.png";
      }
      break;

    case AspectRatio.Portrait:
      if (templateId === AUDIO_TEMPLATE_1.templateId) {
        return "audio-templates/template-1-1080x1920.png";
      }
      if (templateId === AUDIO_TEMPLATE_2.templateId) {
        return "audio-templates/template-2-1080x1920.png";
      }
      if (templateId === AUDIO_TEMPLATE_3.templateId) {
        return "audio-templates/template-3-1080x1920.png";
      }
      if (templateId === AUDIO_TEMPLATE_4.templateId) {
        return "audio-templates/template-4-1080x1920.png";
      }
      break;

    case AspectRatio.Vertical:
      if (templateId === AUDIO_TEMPLATE_1.templateId) {
        return "audio-templates/template-1-1080x1350.png";
      }
      if (templateId === AUDIO_TEMPLATE_2.templateId) {
        return "audio-templates/template-2-1080x1350.png";
      }
      if (templateId === AUDIO_TEMPLATE_3.templateId) {
        return "audio-templates/template-3-1080x1350.png";
      }
      if (templateId === AUDIO_TEMPLATE_4.templateId) {
        return "audio-templates/template-4-1080x1350.png";
      }
      break;

    case AspectRatio.Square:
      if (templateId === AUDIO_TEMPLATE_1.templateId) {
        return "audio-templates/template-1.png";
      }
      if (templateId === AUDIO_TEMPLATE_2.templateId) {
        return "audio-templates/template-2.png";
      }
      if (templateId === AUDIO_TEMPLATE_3.templateId) {
        return "audio-templates/template-3.png";
      }
      if (templateId === AUDIO_TEMPLATE_4.templateId) {
        return "audio-templates/template-4.png";
      }
      break;
  }

  return "";
};
