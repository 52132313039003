import { getLimits, getPlanStorage } from "./plans";
import { SublyPlan } from "../interfaces/billing";
import { accountQuery } from "../state/account";

export interface StorageInfo {
  isStorageFull: boolean;
  isStorageLow: boolean;
  storageBytesUsed: number;
  planStorage: number;
  planStorageText: string;
  storageUsed: number;
  storageUsedText: string;
  usagePercentage: number;
}

export interface FormatBytes {
  size: number;
  units: string;
}

const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
const k = 1024;

export const formatBytes = (bytes: number, decimals = 2): FormatBytes => {
  if (bytes === 0) return { size: 0, units: "Bytes" };

  const dm = decimals < 0 ? 0 : decimals;
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const formattedSize = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));

  return { size: formattedSize, units: sizes[i] };
};

export const getBytes = (size: number, units = "GB"): number =>
  size * k ** (sizes.indexOf(units) || 0);

export const getStorageInfo = (plan: SublyPlan): StorageInfo => {
  const storageBytesUsed = accountQuery.storageBytesUsed;
  const planStorage = getBytes(getPlanStorage(plan));
  const totalStorage = accountQuery.totalStorage;
  const isStorageFull = storageBytesUsed >= totalStorage;
  const usagePercentage = Math.round((storageBytesUsed / totalStorage) * 100);
  const isStorageLow = usagePercentage >= 80;
  const { size: storageUsed, units } = formatBytes(storageBytesUsed);
  const storageUsedText = storageUsed + " " + units;
  const planStorageText = getLimits(plan).storageLabel;

  return {
    isStorageFull,
    isStorageLow,
    storageBytesUsed,
    planStorage,
    planStorageText,
    storageUsed,
    storageUsedText,
    usagePercentage
  };
};
