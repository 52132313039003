import classNames from "classnames";
import React from "react";
import styles from "./checkbox.module.scss";

interface CheckboxProps {
  inverted?: boolean;
  value?: boolean;
  onChange?: (value: boolean) => void;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  className?: string;
  disabled?: boolean;
}
export const Checkbox: React.FC<CheckboxProps> = ({
  value,
  onChange,
  onClick,
  className,
  inverted,
  disabled,
  children
}) => {
  const [id] = React.useState(String(Math.random()));

  return (
    <div
      className={classNames(
        "custom-control custom-checkbox",
        styles["custom-control"],
        {
          [styles["custom-checkbox-inverted"]]: inverted,
          [styles["disabled"]]: disabled
        },
        className
      )}
      onClick={onClick}
    >
      <input
        type="checkbox"
        className="custom-control-input"
        id={id}
        checked={value}
        onChange={(e) => onChange && onChange(e.target.checked)}
        disabled={disabled}
      />
      <label
        className={classNames("custom-control-label", styles["input-label"])}
        htmlFor={id}
      >
        {children}
      </label>
    </div>
  );
};
