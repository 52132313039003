import React, { useState, useRef } from "react";
import classNames from "classnames";
import {
  ChevronRightIcon,
  ChevronLeftIcon,
  FolderIcon,
  UserIcon,
  UserGroupIcon,
  PencilIcon
} from "@heroicons/react/outline";
import { useFolders } from "../../hooks/use-folders";
import { Folder, foldersStore } from "../../state/folders/folders.store";
import { foldersQuery } from "../../state/folders/folders.query";
import { updateFolder } from "../../state/folders/folders.service";
import { useOnClickOutside } from "../../hooks/use-on-click-outside";
import { AddFolderButton } from "../../components/button/add-folder-button";
import { useObservable } from "@mindspace-io/react";
import styles from "./folder-list-container.module.scss";

const FOLDER_CATEGORIES = [
  { name: "Private", image: <UserIcon className={styles.icon} /> },
  {
    name: "Shared",
    image: <UserGroupIcon className={styles.icon} />
  }
];

interface FolderListContainerProps {
  selectedFolder?: Folder;
  setSelectedFolder: (folder?: Folder) => void;
  searchedFolders: Folder[];
}

export const FolderListContainer: React.FC<FolderListContainerProps> = ({
  selectedFolder,
  setSelectedFolder,
  searchedFolders
}) => {
  const { privateFolders, sharedFolders } = useFolders();
  const [folderOptions, setSelectedFolderOptions] = useState<string>("");

  //Folder list changes into filtered folder list when search bar is used
  const renderSearchedFolders = () => {
    return (
      <FolderOptions
        folders={searchedFolders}
        selectedFolder={selectedFolder}
        setSelectedFolder={setSelectedFolder}
        onClick={() => setSelectedFolderOptions("")}
      />
    );
  };
  // Modal first displays folder categories allowing users to choose which category to proceed. Diffrenet folders are rendered depending on which folder category is clicked.
  const renderFolders = () => {
    switch (folderOptions) {
      case "Private":
        return (
          <FolderOptions
            folderCategory="Private"
            folders={privateFolders}
            selectedFolder={selectedFolder}
            setSelectedFolder={setSelectedFolder}
            onClick={() => setSelectedFolderOptions("")}
          />
        );
      case "Shared":
        return (
          <FolderOptions
            folderCategory="Shared"
            folders={sharedFolders}
            selectedFolder={selectedFolder}
            setSelectedFolder={setSelectedFolder}
            onClick={() => setSelectedFolderOptions("")}
          />
        );
      default:
        return (
          <>
            {FOLDER_CATEGORIES.map((c) => (
              <div
                key={c.name}
                className={styles["folder-option"]}
                onClick={() => setSelectedFolderOptions(c.name)}
              >
                <div className={styles["folder-title-container"]}>
                  {c.image}
                  <p>{c.name}</p>
                </div>

                <ChevronRightIcon className={styles.icon} />
              </div>
            ))}
          </>
        );
    }
  };

  return (
    <>
      <div className={styles["folder-list-container"]}>
        {searchedFolders.length >= 1
          ? renderSearchedFolders()
          : renderFolders()}
      </div>
    </>
  );
};

interface FolderOptionsProps {
  folders: Folder[];
  selectedFolder?: Folder;
  setSelectedFolder: (folder?: Folder) => void;
  onClick: () => void;
  folderCategory?: string;
}

const FolderOptions: React.FC<FolderOptionsProps> = ({
  folders,
  selectedFolder,
  setSelectedFolder,
  onClick,
  folderCategory
}) => {
  return (
    <>
      {folderCategory && (
        <div className={styles["folder-option"]}>
          <div className={styles["folder-title-container"]} onClick={onClick}>
            <ChevronLeftIcon className={styles.icon} />
            <p>{folderCategory}</p>
          </div>
          <AddFolderButton
            folderCategory={folderCategory}
            toolTipPlace="bottom"
          />
        </div>
      )}
      <div className={styles["folder-list"]}>
        {folders.map((folder) => (
          <SingleFolder
            key={folder.id}
            folder={folder}
            selectedFolder={selectedFolder}
            setSelectedFolder={setSelectedFolder}
          />
        ))}
      </div>
    </>
  );
};

interface SingleFolderProps {
  selectedFolder?: Folder;
  folder: Folder;
  setSelectedFolder: (folder?: Folder) => void;
}

const SingleFolder: React.FC<SingleFolderProps> = ({
  folder,
  selectedFolder,
  setSelectedFolder
}) => {
  const [isEditing] = useObservable(
    foldersQuery.selectIsEditing(folder.id),
    false
  );
  const isActive = folder.id === selectedFolder?.id;

  const handleRenameFolder = (folder: Folder) => {
    foldersStore.updateEditing(folder.id, true);
  };

  return (
    <div
      key={folder.id}
      className={classNames(styles["folder-option"], {
        [styles["folder-option--active"]]: isActive
      })}
      onClick={(e) => {
        e.detail === 1 ? setSelectedFolder(folder) : handleRenameFolder(folder);
      }}
    >
      <div className={styles["folder-title-container"]}>
        <FolderIcon className={styles.icon} />
        {!isEditing ? (
          <FolderTitle folder={folder} />
        ) : (
          <FolderEditor folder={folder} />
        )}
      </div>
    </div>
  );
};

interface FolderTitleProps {
  folder: Folder;
}

const FolderTitle: React.FC<FolderTitleProps> = ({ folder }) => {
  return (
    <>
      <p>{folder.name}</p>
      <div className={styles.badge}>{folder.count}</div>
      <PencilIcon
        className={classNames(styles.icon, styles["pencil-icon"])}
        onClick={(e) => {
          e.stopPropagation();
          foldersStore.updateEditing(folder.id, true);
        }}
      />
    </>
  );
};

interface FolderEditorProps {
  folder: Folder;
}

const FolderEditor: React.FC<FolderEditorProps> = ({ folder }) => {
  const [name, setName] = useState(folder.name);
  const ref = useRef<HTMLInputElement | null>(null);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    saveName();
  };

  const saveName = async () => {
    if (!name) {
      return;
    }

    updateFolder({ ...folder, name });
    foldersStore.updateEditing(folder.id, false);
  };

  useOnClickOutside(ref, saveName, true);

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        name="name"
        className={styles["folder-input"]}
        autoFocus
        ref={ref}
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
    </form>
  );
};
