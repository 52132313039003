import { EntityUIQuery, QueryEntity } from "@datorama/akita";
import { Observable } from "rxjs";
import {
  Folder,
  FoldersState,
  FoldersStore,
  foldersStore,
  FolderUIState
} from "./folders.store";

class FoldersQuery extends QueryEntity<FoldersState> {
  ui!: EntityUIQuery<FolderUIState>;

  get privateFolders(): Folder[] {
    return this.getAll({ filterBy: (f) => !Boolean(f.isPublic) });
  }

  get sharedFolders(): Folder[] {
    return this.getAll({ filterBy: (f) => Boolean(f.isPublic) });
  }

  get sharedWithMeFolders(): Folder[] {
    return this.getAll({
      filterBy: (f) => Boolean(f.sharedWithMe)
    });
  }

  constructor(protected store: FoldersStore) {
    super(store);
    this.createUIQuery();
  }

  isFolderPrivate(id?: string): boolean {
    if (!id || !this.getEntity(id)) {
      return true;
    }

    return this.getEntity(id)?.isPublic === false;
  }

  selectIsLoading(id: string): Observable<boolean> {
    return this.ui.selectEntity(id, (s) => Boolean(s?.isLoading));
  }

  selectIsEditing(id: string): Observable<boolean> {
    return this.ui.selectEntity(id, (s) => Boolean(s?.isEditing));
  }

  selectPrivateFolders(): Observable<Folder[]> {
    return this.selectAll({ filterBy: (f) => !Boolean(f.isPublic) });
  }

  selectSharedFolders(): Observable<Folder[]> {
    return this.selectAll({ filterBy: (f) => Boolean(f.isPublic) });
  }
  selectSharedWithMeFolders(): Observable<Folder[]> {
    return this.selectAll({
      filterBy: (f) => Boolean(f.sharedWithMe)
    });
  }
}

export const foldersQuery = new FoldersQuery(foldersStore);
