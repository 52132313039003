import React from "react";
import classNames from "classnames";
import styles from "./community-spotlight.module.scss";
import { SpeechMarkIcon } from "../icons/auth-assets";
import { Testimonial } from "./community-testimonials";

interface CommunitySpotlightProps {
  testimonial: Testimonial;
  className?: string;
}
export const CommunitySpotlight: React.FC<CommunitySpotlightProps> = ({
  testimonial,
  className
}) => {
  const { name, role, testimony, company, photoURL, logoURL } = testimonial;

  return (
    <div className={classNames(styles.customerContainer, className)}>
      <div className={styles.imageContainer}>
        <div className={styles.imageGrid}>
          <img
            src={photoURL}
            alt={name}
            loading="lazy"
            className={styles.memberImage}
          />
          <img
            src={logoURL}
            alt={company}
            loading="lazy"
            className={styles.logoImage}
          />
        </div>
      </div>
      <div className="py-2">
        <SpeechMarkIcon
          className={classNames("mb-lg-3 mb-1", styles.speechMark)}
        />
        <h4 className="text-white font-weight-normal">{testimony}</h4>
        <span className="text-brand">{name}</span>{" "}
        <span className="small text-white">- {role}</span>
      </div>
    </div>
  );
};
