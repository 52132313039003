import React from "react";
import { FontColorIcons } from "./icons";

export const BackgroundFontColorIcon: React.FC<FontColorIcons> = ({
  selectedColor,
  ...props
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 2C0 0.89543 0.895431 0 2 0H22C23.1046 0 24 0.895431 24 2V22C24 23.1046 23.1046 24 22 24H2C0.89543 24 0 23.1046 0 22V2Z"
      fill={selectedColor}
    />
    <mask x="0" y="0" width="24" height="24">
      <rect
        width="24"
        height="24"
        rx="2"
        fill={selectedColor !== "#FFFFFFFF" ? "white" : "black"}
      />
    </mask>
    <g>
      <path
        d="M10.851 4L4.5 20H7.09815L8.39145 16.5714H15.6085L16.9018 20H19.5L13.1605 4H10.851ZM9.25751 14.2857L12.0058 7.05143L14.754 14.2857H9.25751Z"
        fill={selectedColor !== "#FFFFFFFF" ? "white" : "black"}
      />
    </g>
  </svg>
);

export const PlusSquareIcon: React.FC<FontColorIcons> = ({ ...props }) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="1"
      y="1"
      width="38"
      height="38"
      rx="3"
      stroke="black"
      strokeWidth="2"
    />
    <path
      d="M20 14V26M26 20L14 20"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const SelectedCheckIcon: React.FC<FontColorIcons> = ({ ...props }) => (
  <svg
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 7L5 11L15 1"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CheckCircleIcon: React.FC<FontColorIcons> = ({ ...props }) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.6667 15L13.5556 17.8889L19.3333 12.1111M28 15C28 22.1797 22.1797 28 15 28C7.8203 28 2 22.1797 2 15C2 7.8203 7.8203 2 15 2C22.1797 2 28 7.8203 28 15Z"
      stroke="#1E1E1E"
      strokeWidth="2.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const EditIcon: React.FC<FontColorIcons> = ({ ...props }) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.7059 5.05883H5.05883C3.36949 5.05883 2 6.42831 2 8.11765V24.9412C2 26.6305 3.36949 28 5.05883 28H21.8824C23.5717 28 24.9412 26.6305 24.9412 24.9412V17.2941M22.7783 2.89591C23.9728 1.70136 25.9095 1.70136 27.1041 2.89591C28.2986 4.09045 28.2986 6.02719 27.1041 7.22174L13.9729 20.3529H9.64706L9.64706 16.0271L22.7783 2.89591Z"
      stroke="#1E1E1E"
      strokeWidth="2.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const TrashCanIcon: React.FC<FontColorIcons> = ({ ...props }) => (
  <svg
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16 5L15.1327 17.1425C15.0579 18.1891 14.187 19 13.1378 19H4.86224C3.81296 19 2.94208 18.1891 2.86732 17.1425L2 5M7 9V15M11 9V15M12 5V2C12 1.44772 11.5523 1 11 1H7C6.44772 1 6 1.44772 6 2V5M1 5H17"
      strokeOpacity="0.9"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const SmallSquareRatioIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({
  ...props
}) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="0.5" y="0.5" width="13" height="13" rx="1.5" stroke="#1E1E1E" />
  </svg>
);

export const SmallLandscapeRatioIcon: React.FC<
  React.SVGProps<SVGSVGElement>
> = ({ ...props }) => (
  <svg
    width="25"
    height="14"
    viewBox="0 0 25 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="0.5"
      y="13.5"
      width="13"
      height="24"
      rx="1.5"
      transform="rotate(-90 0.5 13.5)"
      stroke="#1E1E1E"
    />
  </svg>
);

export const SmallPortraitRatioIcon: React.FC<
  React.SVGProps<SVGSVGElement>
> = ({ ...props }) => (
  <svg
    width="14"
    height="24"
    viewBox="0 0 14 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="0.5" y="0.5" width="13" height="23" rx="1.5" stroke="#1E1E1E" />
  </svg>
);

export const SmallVerticalRatioIcon: React.FC<
  React.SVGProps<SVGSVGElement>
> = ({ ...props }) => (
  <svg
    width="14"
    height="18"
    viewBox="0 0 14 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="0.5" y="1" width="13" height="16" rx="1.5" stroke="#1E1E1E" />
  </svg>
);

export const BigSquareRatioIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({
  ...props
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="0.5" y="0.5" width="23" height="23" rx="1.5" stroke="#1E1E1E" />
  </svg>
);

export const BigLandscapeRatioIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({
  ...props
}) => (
  <svg
    width="41"
    height="24"
    viewBox="0 0 41 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="0.5"
      y="23.5"
      width="23"
      height="40"
      rx="1.5"
      transform="rotate(-90 0.5 23.5)"
      stroke="#1E1E1E"
    />
  </svg>
);

export const BigPortraitRatioIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({
  ...props
}) => (
  <svg
    width="24"
    height="41"
    viewBox="0 0 24 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="0.5"
      y="0.5"
      width="23"
      height="39.4211"
      rx="1.5"
      stroke="#1E1E1E"
    />
  </svg>
);

export const BigVerticalRatioIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({
  ...props
}) => (
  <svg
    width="24"
    height="30"
    viewBox="0 0 24 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="-0.5"
      y="0.5"
      width="23"
      height="29"
      rx="1.5"
      transform="matrix(-1 0 0 1 23 0)"
      stroke="#1E1E1E"
    />
  </svg>
);

export const UploadIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({
  ...props
}) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3 13.5L3 14.375C3 15.8247 4.17525 17 5.625 17L14.375 17C15.8247 17 17 15.8247 17 14.375L17 13.5M13.5 6.5L10 3M10 3L6.5 6.5M10 3L10 13.5"
      stroke="#4B5563"
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const UpdateIcon: React.FC<FontColorIcons> = ({ ...props }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3 3V7.375H3.50883M16.9458 9.125C16.5153 5.67198 13.5697 3 10 3C7.06229 3 4.54726 4.80965 3.50883 7.375M3.50883 7.375H7.375M17 17V12.625H16.4912M16.4912 12.625C15.4527 15.1904 12.9377 17 10 17C6.43033 17 3.48474 14.328 3.05416 10.875M16.4912 12.625H12.625"
      stroke="white"
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const PlusIcon: React.FC<FontColorIcons> = ({ ...props }) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6 1V11M11 6L1 6"
      stroke="#4B5563"
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const MenuBurgerIcon: React.FC<FontColorIcons> = ({ ...props }) => (
  <svg
    width="14"
    height="12"
    viewBox="0 0 14 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.75 1.3125H13.25M0.75 6H13.25M0.75 10.6875H13.25"
      stroke="white"
      strokeWidth="1.13"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const FolderIcon: React.FC<FontColorIcons> = ({ ...props }) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.8611 13.25V2.13889C11.8611 1.37183 11.2393 0.75 10.4722 0.75H3.52778C2.76072 0.75 2.13889 1.37183 2.13889 2.13889V13.25M11.8611 13.25L13.25 13.25M11.8611 13.25H8.38889M2.13889 13.25L0.75 13.25M2.13889 13.25H5.61111M4.91667 3.52776H5.61111M4.91667 6.30554H5.61111M8.38889 3.52776H9.08333M8.38889 6.30554H9.08333M5.61111 13.25V9.77776C5.61111 9.39423 5.92202 9.08332 6.30556 9.08332H7.69444C8.07798 9.08332 8.38889 9.39423 8.38889 9.77776V13.25M5.61111 13.25H8.38889"
      stroke="#1E1E1E"
      strokeWidth="1.13"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const SendIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.55556 10L3 17L17 10L3 3L4.55556 10ZM4.55556 10L10.7778 10"
      stroke="#6B7280"
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const SubtitleIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.5 10.3333H19.5M1.5 13.8333H17.5"
      stroke="currentColor"
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.22568 10.8188C2.12942 11.125 1.8456 11.3333 1.52464 11.3333C1.0216 11.3333 0.667266 10.8393 0.828582 10.3628L2.22073 6.2508C2.40641 5.70237 2.921 5.33331 3.5 5.33331C4.079 5.33331 4.59359 5.70237 4.77927 6.2508L6.17135 10.3626C6.3327 10.8392 5.97829 11.3333 5.47514 11.3333C5.15427 11.3333 4.87049 11.1252 4.77408 10.8191L3.52205 6.84503H3.47501L2.22568 10.8188ZM1.85522 8.97199H5.1242V10.0735H1.85522V8.97199Z"
      fill="currentColor"
    />
  </svg>
);

export const TemplatesIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="3.25"
      y="2.08337"
      width="14.5"
      height="16.5"
      rx="1.25"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <line
      x1="3.56667"
      y1="5.39819"
      x2="17.4333"
      y2="5.39819"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.3 12.5831C15.3 12.9973 14.9642 13.3331 14.55 13.3331H12.55C12.1358 13.3331 11.8 12.9973 11.8 12.5831C11.8 12.1689 12.1358 11.8331 12.55 11.8331H14.55C14.9642 11.8331 15.3 12.1689 15.3 12.5831Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.2938 15.0831C15.2938 15.4973 14.958 15.8331 14.5438 15.8331H6.44376C6.02954 15.8331 5.69376 15.4973 5.69376 15.0831C5.69376 14.6689 6.02954 14.3331 6.44376 14.3331H14.5438C14.958 14.3331 15.2938 14.6689 15.2938 15.0831Z"
      fill="currentColor"
    />
    <path
      d="M7.97335 7.9315C8.13909 7.4006 8.86075 7.4006 9.02649 7.9315L9.30792 8.83295C9.38204 9.07037 9.59462 9.23112 9.83449 9.23112H10.7452C11.2815 9.23112 11.5045 9.94545 11.0706 10.2736L10.3339 10.8307C10.1398 10.9774 10.0586 11.2375 10.1327 11.475L10.4141 12.3764C10.5799 12.9073 9.99605 13.3488 9.56213 13.0207L8.82536 12.4635C8.63131 12.3168 8.36854 12.3168 8.17448 12.4635L7.43771 13.0207C7.00379 13.3488 6.41995 12.9073 6.5857 12.3764L6.86712 11.475C6.94124 11.2375 6.86004 10.9774 6.66599 10.8307L5.92921 10.2736C5.49529 9.94545 5.7183 9.23112 6.25465 9.23112H7.16535C7.40522 9.23112 7.6178 9.07037 7.69193 8.83295L7.97335 7.9315Z"
      fill="currentColor"
    />
  </svg>
);
