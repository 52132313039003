import React from "react";
import {
  Coupon,
  dontCancelSubscription,
  getCoupon
} from "../api/billing.service";
import { CancelModal } from "../components/plans/cancel-modal";
import { useAccounts } from "../hooks/use-accounts";
import { useModal } from "../hooks/use-modal";
import { usePlan } from "../hooks/use-plan";
import { PlanInterval, SublyPlan } from "../interfaces/billing";
import { SublyCouponCode } from "../utils/plans";
import { ModalType } from "../interfaces/modal-type";

export interface DowngradeSubscription {
  subscriptionId: string;
  plan: SublyPlan;
}

interface PlansTableContext {
  isYearly: boolean;
  setIsYearly: (isYearly: boolean) => void;
  handleDontCancel: (subscriptionId: string) => void;
  handleDowngrade: ({ subscriptionId, plan }: DowngradeSubscription) => void;
  interval: PlanInterval;
  proPlan: SublyPlan;
  premiumPlan: SublyPlan;
  businessPlan: SublyPlan;
  PAYG: SublyPlan;
  isLoading: boolean;
  coupon?: Coupon;
  couponCode?: string;
}

export const PlansTableContext = React.createContext<PlansTableContext>({
  isYearly: false,
  setIsYearly: () => null,
  handleDontCancel: () => null,
  handleDowngrade: () => null,
  interval: PlanInterval.Yearly,
  proPlan: SublyPlan.Pro100,
  premiumPlan: SublyPlan.Premium,
  businessPlan: SublyPlan.BusinessYearly,
  PAYG: SublyPlan.PAYG,
  isLoading: true
});

interface PlansTableProviderProps {
  interval: PlanInterval;
  couponCode?: SublyCouponCode;
}
export const PlansTableProvider: React.FC<PlansTableProviderProps> = ({
  interval: propsInterval,
  couponCode,
  children
}) => {
  const [showModal, hideModal] = useModal(ModalType.CancelModal);
  const [isYearly, setIsYearly] = React.useState(
    propsInterval === PlanInterval.Monthly
  );
  const { isLoadingPlans, isLoading: isLoadingAccount } = useAccounts();
  const { plan: currentPlan, isTrial } = usePlan();
  const [coupon, setCoupon] = React.useState<Coupon>();
  const [couponLoading, setCouponLoading] = React.useState(false);

  React.useEffect(() => {
    if (!couponCode) {
      return;
    }

    setCouponLoading(true);

    const fetchCoupon = async (c: SublyCouponCode) => {
      const data = await getCoupon(c);
      setCoupon(data.coupon);
      setCouponLoading(false);
    };

    fetchCoupon(couponCode);
  }, [couponCode]);

  const isLoading = isLoadingPlans || isLoadingAccount || couponLoading;

  const handleDontCancel = async (subscriptionId: string) => {
    await dontCancelSubscription(subscriptionId);
  };

  const handleDowngrade = ({ subscriptionId, plan }: DowngradeSubscription) => {
    showModal(
      <CancelModal
        onDismiss={hideModal}
        subscriptionId={subscriptionId}
        cancelPlan={plan}
        currentPlan={currentPlan}
        isTrial={isTrial}
      />
    );
  };

  const interval = isYearly ? PlanInterval.Yearly : PlanInterval.Monthly;
  const proPlan = isYearly ? SublyPlan.Pro100Yearly : SublyPlan.Pro100;
  const premiumPlan = isYearly ? SublyPlan.PremiumYearly : SublyPlan.Premium;
  const businessPlan = isYearly ? SublyPlan.BusinessYearly : SublyPlan.Business;
  const PAYG = SublyPlan.PAYG;

  return (
    <PlansTableContext.Provider
      value={{
        isYearly,
        setIsYearly,
        handleDontCancel,
        handleDowngrade,
        interval,
        proPlan,
        premiumPlan,
        businessPlan,
        PAYG,
        isLoading,
        coupon,
        couponCode
      }}
    >
      {children}
    </PlansTableContext.Provider>
  );
};
