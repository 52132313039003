import React from "react";
import { Modal } from "../containers/modal/modal";
import { LoadingIcon } from "./loading-icon/loading-icon";

export const WorkspaceLoadingModal: React.FC = () => {
  return (
    <Modal disableBackgroundDismiss>
      <h5 className="mb-3 d-flex align-items-center justify-content-center text-center">
        We are switching your account 😎
      </h5>

      <p className="text-black text-center">
        Please hold tight and take a sip from your caipirinha 🍹 while we load
        your workspace!
      </p>

      <p className="text-black text-center">
        <LoadingIcon />
      </p>
    </Modal>
  );
};
