import classNames from "classnames";
import React, { MutableRefObject, useContext } from "react";
import {
  Dropdown,
  DropdownButton,
  DropdownContext,
  DropdownItem,
  DropdownMenu
} from "../../components/dropdown/dropdown";
import { useModal } from "../../hooks/use-modal";
import { BasicMedia } from "../../interfaces/media";
import { ModalType } from "../../interfaces/modal-type";

import { DeleteMediaModal } from "../../containers/delete-media-modal/delete-media-modal";
import { MoveMediaModal } from "../../containers/move-media-modal/move-media-modal";
import { RenameMediaModal } from "../../containers/rename-media-modal/rename-media-modal";
import { KebabMenuIcon } from "../icons/icons";
import styles from "./media-card-dropdown.module.scss";

interface MediaCardDropdownProps {
  media: BasicMedia;
  setDropdownOpen: (isOpen: boolean) => void;
  className?: string;
}

export const MediaCardDropdown: React.FC<MediaCardDropdownProps> = ({
  className,
  ...props
}) => {
  const dropdownRef = React.useRef(null);

  return (
    <div
      ref={dropdownRef}
      className={classNames(className, styles["dropdown"])}
    >
      <Dropdown>
        <DropdownContent dropdownRef={dropdownRef} {...props} />
      </Dropdown>
    </div>
  );
};

interface DropdownContentProps extends MediaCardDropdownProps {
  dropdownRef: MutableRefObject<HTMLElement | null>;
}
const DropdownContent: React.FC<DropdownContentProps> = ({
  setDropdownOpen,
  media,
  dropdownRef
}) => {
  const [showMoveModal, hideMoveModal] = useModal(ModalType.MoveMedia);
  const [showDeleteModal, hideDeleteModal] = useModal(ModalType.DeleteMedia);
  const [showRenameModal, hideRenameModal] = useModal(ModalType.RenameMedia);

  const { isOpen } = useContext(DropdownContext);

  React.useEffect(() => {
    setDropdownOpen(isOpen);
  }, [isOpen]);

  return (
    <>
      <DropdownButton
        dropdownEl={dropdownRef}
        className={styles["dropdown-button"]}
        isOpenClassName={styles["dropdown-button--open"]}
      >
        <KebabMenuIcon className={styles["kebab-menu-icon"]} />
      </DropdownButton>

      <DropdownMenu className={styles["dropdown-menu"]} animate="down">
        <DropdownItem
          className={styles["dropdown-item"]}
          onClick={() =>
            showRenameModal(
              <RenameMediaModal media={media} hideModal={hideRenameModal} />
            )
          }
          disabled={media.isBurning}
        >
          <span className="small m-0">Rename</span>
        </DropdownItem>

        <DropdownItem
          className={styles["dropdown-item"]}
          onClick={() =>
            showMoveModal(
              <MoveMediaModal
                mediaIds={[media.id]}
                closeModal={hideMoveModal}
              />
            )
          }
        >
          <span className="small m-0">Move...</span>
        </DropdownItem>

        <DropdownItem
          className={styles["dropdown-item"]}
          onClick={() =>
            showDeleteModal(
              <DeleteMediaModal
                mediaIds={[media.id]}
                closeModal={hideDeleteModal}
              />
            )
          }
          disabled={media.isBurning}
        >
          <span className="small m-0">Delete file</span>
        </DropdownItem>
      </DropdownMenu>
    </>
  );
};
