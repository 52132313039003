import sanitizeHtml from "sanitize-html";
import { extensionForMimeType } from "./mime-types";

export const extractErrorDescription = (
  errorDescription?: string
): string | undefined => errorDescription?.split("|")?.[1] || errorDescription;

export const clearExtension = (filename: string): string => {
  return filename.split(".").slice(0, -1).join(".");
};

export const cleanFileName = (filename: string, mimeType: string): string => {
  // Add default extension if there isn't one
  const extension = getFileExtension(filename);
  if (extension) {
    return filename;
  }

  // Add default extension if there is one
  const defaultExtension = extensionForMimeType(mimeType);
  if (!defaultExtension) {
    return filename;
  }

  return `${filename}.${defaultExtension}`;
};

export const getFileExtension = (filename: string): string | undefined =>
  /(?:\.([^.]+))?$/.exec(filename)?.[1];

export const capitalize = (text?: string): string => {
  if (!text) {
    return "";
  }

  return text.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());
};

export const firstLetterToLowerCase = (text?: string): string => {
  if (!text) {
    return "";
  }

  return text[0].toLowerCase() + text.slice(1);
};

export const formatMediaName = (text: string): string => {
  return capitalize(clearExtension(text));
};

export const compareStrings = (a?: string, b?: string): boolean => {
  if (typeof a !== "string" || typeof b !== "string") {
    return false;
  }

  return a.toLowerCase() === b.toLowerCase();
};

// Taken from https://stackoverflow.com/a/53387532/2623173
export function compareVersions(a: string, b: string): -1 | 0 | 1 {
  function prep(t: string) {
    return (
      ("" + t)
        //treat non-numerical characters as lower version
        //replacing them with a negative number based on charcode of first character
        //and remove non alpha-numerical characters
        .replace(/[^0-9.]+/g, function (c) {
          return (
            "." +
            (c === c.replace(/[\W_]+/, "")
              ? c.toLowerCase().charCodeAt(0) - 65536
              : "") +
            "."
          );
        })
        //remove trailing ".", "0" and ".0"
        .replace(/^\.|0*\.$|(?:\.0+)+$/g, "")
        //replace double .
        .replace(/\.+/g, ".")
        //remove trailing "0" if followed by non-numerical characters (1.0.0b);
        .replace(/(?:\.0+)*(\.-[0-9]+)(\.[0-9]+)?$/g, function (_, b, c) {
          return b + (c || "");
        })
        .split(".")
    );
  }

  const prepA = prep(a);
  const prepB = prep(b);

  for (let i = 0; i < Math.max(prepA.length, prepB.length); i++) {
    //convert to integer the most efficient way
    const aV = ~~prepA[i];
    const bV = ~~prepB[i];
    if (aV > bV) return 1;
    else if (aV < bV) return -1;
  }
  return 0;
}

export const pluralize = (
  value: number,
  singular: string,
  plural?: string
): string => {
  if (value === 1) {
    return singular;
  }

  if (plural) {
    return plural;
  }

  return singular + "s";
};

export const replaceAt = (
  value: string,
  index: number,
  length: number,
  replacement: string
): string => {
  return value.slice(0, index) + replacement + value.slice(index + length);
};

export const wrapWithHtmlTag = (
  value: string,
  tag = "mark",
  className = ""
): string => {
  return `<${tag} class="${className}">${value}</${tag}>`;
};

export const addHttps = (url: string): string => {
  if (!/^https?:\/\//.test(url)) {
    url = "https://" + url;
  }
  return url;
};

export const htmlDecode = (input: string): string => {
  const doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent ?? input;
};

export const transformKeysToMac = (keys: string[]): string[] => {
  return keys.map((key) =>
    key.replace(/Ctrl/gi, "Cmd").replace(/Alt/gi, "Option")
  );
};

export const sanitizeString = (text: string): string =>
  sanitizeHtml(text, {
    disallowedTagsMode: "discard",
    allowedTags: []
  });
