import React from "react";
import { Table } from "../../../components/table/table";
import { mdiCheckCircle, mdiMinus } from "@mdi/js";
import Icon from "@mdi/react";
import styles from "./comparison-table.module.scss";
import { PlanUpgradeButton } from "../../../components/button/plans-button";
import { PlansTableContext } from "../../../contexts/plans-table.context";

interface PlanComparisonTable {
  title: string;
  proPlan: boolean | string;
  premiumPlan: boolean | string;
  businessPlan: boolean | string;
}

type PlanComparisonData = Array<PlanComparisonTable>;

export const PlanComparisonTable: React.FC = () => {
  const {
    handleDontCancel,
    handleDowngrade,
    isLoading,
    proPlan,
    premiumPlan,
    businessPlan
  } = React.useContext(PlansTableContext);

  const renderTable = comparisonTableData.map((item, k) => {
    return (
      <tr key={k}>
        <td>{item.title}</td>
        <td>
          {typeof item.proPlan === "string" ? (
            item.proPlan
          ) : (
            <Icon
              path={item.proPlan ? mdiCheckCircle : mdiMinus}
              size="1.75em"
              color="#9370F1"
            />
          )}
        </td>
        <td>
          {typeof item.premiumPlan === "string" ? (
            item.premiumPlan
          ) : (
            <Icon
              path={item.premiumPlan ? mdiCheckCircle : mdiMinus}
              size="1.75em"
              color="#9370F1"
            />
          )}
        </td>
        <td>
          {typeof item.businessPlan === "string" ? (
            item.businessPlan
          ) : (
            <Icon
              path={item.premiumPlan ? mdiCheckCircle : mdiMinus}
              size="1.75em"
              color="#9370F1"
            />
          )}
        </td>
      </tr>
    );
  });

  return (
    <div className={styles["container"]}>
      <h1 className="font-weight-bold text-center">Compare Plans</h1>
      <Table
        responsive
        header={["All the basics", "Pro", "Premium", "Business"]}
        className={styles["table"]}
      >
        <tbody>
          {renderTable}
          <tr>
            <td></td>
            <td>
              <PlanUpgradeButton
                isComparisonTable
                plan={proPlan}
                loading={isLoading}
                handleDowngrade={handleDowngrade}
                handleDontCancel={handleDontCancel}
              />
            </td>
            <td>
              <PlanUpgradeButton
                isComparisonTable
                plan={premiumPlan}
                loading={isLoading}
                handleDowngrade={handleDowngrade}
                handleDontCancel={handleDontCancel}
              />
            </td>
            <td>
              <PlanUpgradeButton
                isComparisonTable
                plan={businessPlan}
                loading={isLoading}
                handleDowngrade={handleDowngrade}
                handleDontCancel={handleDontCancel}
              />
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

const comparisonTableData: PlanComparisonData = [
  {
    title: "Automatic subtitles for video",
    proPlan: true,
    premiumPlan: true,
    businessPlan: true
  },
  {
    title: "Download videos with subtitles",
    proPlan: true,
    premiumPlan: true,
    businessPlan: true
  },
  {
    title: "Style subtitles",
    proPlan: true,
    premiumPlan: true,
    businessPlan: true
  },
  {
    title: "Edit timestamps for subtitles",
    proPlan: true,
    premiumPlan: true,
    businessPlan: true
  },
  {
    title: "Filler words",
    proPlan: true,
    premiumPlan: true,
    businessPlan: true
  },
  {
    title: "Public link sharing",
    proPlan: true,
    premiumPlan: true,
    businessPlan: true
  },
  {
    title: "Combine TXT, SRT, VTT",
    proPlan: true,
    premiumPlan: true,
    businessPlan: true
  },
  {
    title: "Transcribe audio",
    proPlan: true,
    premiumPlan: true,
    businessPlan: true
  },
  {
    title: "Download SRT files",
    proPlan: true,
    premiumPlan: true,
    businessPlan: true
  },
  {
    title: "Download VTT files",
    proPlan: true,
    premiumPlan: true,
    businessPlan: true
  },
  {
    title: "Upload & download to Google Drive",
    proPlan: false,
    premiumPlan: true,
    businessPlan: true
  },
  {
    title: "Upload your own font",
    proPlan: false,
    premiumPlan: true,
    businessPlan: true
  },
  {
    title: "Number of minutes (per month)",
    proPlan: "100 minutes",
    premiumPlan: "240 minutes",
    businessPlan: "1000+ minutes"
  },
  {
    title: "Upload file size limit",
    proPlan: "1 GB",
    premiumPlan: "2 GB",
    businessPlan: "5 GB"
  },
  {
    title: "Storage",
    proPlan: "10 GB",
    premiumPlan: "50 GB",
    businessPlan: "200 GB"
  },
  {
    title: "Email support",
    proPlan: "Subly Support",
    premiumPlan: "Subly Support",
    businessPlan: "Priority"
  },
  {
    title: "Add brand logo",
    proPlan: true,
    premiumPlan: true,
    businessPlan: true
  },
  {
    title: "Resize for social platforms",
    proPlan: true,
    premiumPlan: true,
    businessPlan: true
  },
  {
    title: "Convert audio to video",
    proPlan: true,
    premiumPlan: true,
    businessPlan: true
  },
  {
    title: "Move content to border",
    proPlan: true,
    premiumPlan: true,
    businessPlan: true
  },
  {
    title: "Styling templates",
    proPlan: false,
    premiumPlan: true,
    businessPlan: true
  },
  {
    title: "Translate subtitles",
    proPlan: false,
    premiumPlan: true,
    businessPlan: true
  },
  {
    title: "HD files",
    proPlan: false,
    premiumPlan: true,
    businessPlan: true
  },
  {
    title: "Bulk upload",
    proPlan: false,
    premiumPlan: true,
    businessPlan: true
  },
  {
    title: "Team file sharing",
    proPlan: false,
    premiumPlan: "Up to 3 users",
    businessPlan: "3+ team members"
  },
  {
    title: "Team collaboration",
    proPlan: false,
    premiumPlan: false,
    businessPlan: true
  },
  {
    title: "Team billing by invoice",
    proPlan: false,
    premiumPlan: false,
    businessPlan: true
  },
  {
    title: "Account manager",
    proPlan: false,
    premiumPlan: false,
    businessPlan: true
  }
];
