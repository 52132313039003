import React from "react";
import { ResetPassword } from "../containers/auth/reset-password";
import { AuthLayout } from "../layouts/auth.layout";

export const ResetPasswordPage: React.FC = () => {
  return (
    <AuthLayout showLogin>
      <ResetPassword />
    </AuthLayout>
  );
};
