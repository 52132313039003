import classNames from "classnames";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { EditUserProfileParams } from "../../../api/account.service";
import { Button } from "../../../components/button/button";
import {
  notificationError,
  notificationSuccess
} from "../../../components/notification";
import { PasswordRules } from "../../../components/password-rules/password-rules";
import { AuthContext } from "../../../contexts/auth.context";
import { ACCOUNT_PATH } from "../../../routes";
import { passwordPattern } from "../../../utils/regex-patterns";
import "./edit-account-form.scss";

export const EditAccountForm: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { user, updateProfile, isGoogleAuth } = useContext(AuthContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
    getValues
  } = useForm<EditUserProfileParams>();

  useEffect(() => {
    if (user) {
      reset({
        givenName: user.givenName || user.name,
        familyName: user.familyName
      });
    }
  }, [user, reset]);

  if (!user) {
    return null;
  }

  const onSubmit = async (data: EditUserProfileParams) => {
    try {
      setLoading(true);

      data.familyName = data.familyName.trim();
      data.givenName = data.givenName.trim();

      await updateProfile(data);

      notificationSuccess("Your profile has been updated.");

      navigate(ACCOUNT_PATH);
    } catch (e) {
      notificationError("Failed to update profile!");
    } finally {
      setLoading(false);
    }
  };

  return (
    <form
      className="edit-account-form mt-sm-5"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="form-group">
        <div className="form-row">
          <div className="col">
            <label htmlFor="first-name">First name</label>
            <input
              type="text"
              className={classNames("form-control", {
                "is-invalid": errors.givenName
              })}
              id="first-name"
              autoComplete="first-name"
              {...register("givenName", { required: true })}
            />
          </div>
          <div className="col">
            <label htmlFor="last-name">Last name</label>
            <input
              type="text"
              className={classNames("form-control", {
                "is-invalid": errors.familyName
              })}
              id="last-name"
              autoComplete="last-name"
              {...register("familyName")}
            />
          </div>
        </div>
      </div>
      <div className="form-group">
        <label>Email</label>
        <input
          type="email"
          className="form-control"
          readOnly
          value={user?.email}
        />
      </div>
      {!isGoogleAuth && (
        <>
          <div className="form-group">
            <label htmlFor="current-password">Old password</label>
            <input
              type="password"
              className={classNames("form-control", {
                "is-invalid": errors.password
              })}
              id="current-password"
              autoComplete="current-password"
              {...register("password", {
                pattern: passwordPattern,
                validate: {
                  required: () => {
                    const { password, newPassword, passwordConfirm } =
                      getValues();

                    if (!password && !newPassword && !passwordConfirm) {
                      return true;
                    }

                    return !!password && (!!newPassword || !!passwordConfirm);
                  }
                }
              })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="new-password">New password</label>

            <input
              type="password"
              className={classNames("form-control", {
                "is-invalid": errors.newPassword
              })}
              id="new-password"
              autoComplete="new-password"
              {...register("newPassword", {
                pattern: passwordPattern,
                validate: {
                  required: () => {
                    const { password, newPassword, passwordConfirm } =
                      getValues();

                    if (!password && !newPassword && !passwordConfirm) {
                      return true;
                    }

                    return !!newPassword && (!!password || !!passwordConfirm);
                  }
                }
              })}
            />
            <PasswordRules
              value={watch("newPassword")}
              className="d-flex flex-row flex-wrap"
            />
          </div>
          <div className="form-group">
            <label htmlFor="password-confirm">Confirm new password</label>
            <input
              type="password"
              className={classNames("form-control", {
                "is-invalid": errors.passwordConfirm
              })}
              id="password-confirm"
              autoComplete="new-password"
              {...register("passwordConfirm", {
                validate: {
                  required: () => {
                    const { password, newPassword, passwordConfirm } =
                      getValues();

                    if (!password && !newPassword && !passwordConfirm) {
                      return true;
                    }

                    return !!passwordConfirm && (!!password || !!newPassword);
                  },
                  matchesPreviousPassword: (value) => {
                    if (!value) {
                      return true;
                    }

                    const { newPassword } = getValues();
                    return newPassword === value || "Passwords should match!";
                  }
                }
              })}
            />
          </div>
        </>
      )}
      <div className="form-group d-flex justify-content-end">
        <Button secondary to={ACCOUNT_PATH} className="mr-2">
          Cancel
        </Button>
        <Button type="submit" primary className="mr-0" loading={loading}>
          Save changes
        </Button>
      </div>
    </form>
  );
};
