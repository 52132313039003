import { format } from "date-fns";
import React from "react";
import { Modal } from "../../containers/modal/modal";
import { usePlan } from "../../hooks/use-plan";
import { PLANS_PATH } from "../../routes";
import { Button } from "../button/button";
import styles from "./trial-welcome-modal.module.scss";
import trialOneDownloadImage from "../../assets/images/trial/trial-one-download-remain-cover.svg";

interface TrialOneDownloadRemainModal {
  closeModal: () => void;
}
export const TrialOneDownloadRemainModal: React.FC<
  TrialOneDownloadRemainModal
> = ({ closeModal }) => {
  const { trialEndDate } = usePlan();

  return (
    <Modal onDismiss={closeModal} className={styles["modal"]}>
      <div className={styles["box"]}>
        <div className={styles["content"]}>
          <h5 className={styles["header"]}>You have 1 free download</h5>
          <p>
            Your trial will end on{" "}
            {trialEndDate && format(new Date(trialEndDate), "MMMM do")}. Upgrade
            now to <b>download unlimited files</b> and access{" "}
            <b>100+ minutes.</b>
          </p>
        </div>
        <img alt="1 free download left" src={trialOneDownloadImage} />

        <div className={styles["link"]}>
          <Button className={styles["button"]} secondary onClick={closeModal}>
            Continue on trial
          </Button>
          <Button
            className={styles["button"]}
            primary
            to={PLANS_PATH}
            onClick={closeModal}
          >
            Browse plans
          </Button>
        </div>
      </div>
    </Modal>
  );
};
