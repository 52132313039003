import React from "react";
import { SettingsSidebar } from "../../containers/dashboard/sidebar/settings-sidebar";
import { Navbar } from "../../containers/navbar/navbar";
import { TopBannerContainer } from "../../containers/top-banner/top-banner-container";
import { NavProvider } from "../../contexts/nav-context";

export const SettingsLayout: React.FC<{ hasBackArrow?: boolean }> = ({
  children,
  hasBackArrow = true
}) => {
  return (
    <NavProvider>
      <TopBannerContainer>
        <div className="d-flex flex-row">
          <SettingsSidebar />
          <main className="flex-grow-1 full-height overflow-auto">
            <div className="px-3">
              <Navbar hasBackArrow={hasBackArrow} />
              {children}
            </div>
          </main>
        </div>
      </TopBannerContainer>
    </NavProvider>
  );
};
