import { useObservable } from "@mindspace-io/react";
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { updateAccountSettings } from "../../api/account.service";
import { getAllMedia } from "../../api/media.service";
import { Button } from "../../components/button/button";
import { notificationError } from "../../components/notification";
import { OverdueInvoiceModal } from "../../components/overdue-invoice-modal/overdue-invoice-modal";
import { DashboardNav } from "../../containers/dashboard/dashboard-nav/dashboard-nav";
import { DashboardShortcuts } from "../../containers/dashboard/dashboard-shortcuts";
import { MediaList } from "../../containers/dashboard/media-list/media-list";
import { AuthContext } from "../../contexts/auth.context";
import { SelectedFilesProvider } from "../../contexts/selected-files.context";
import { useAccounts } from "../../hooks/use-accounts";
import { useAnalyticsWithAuth } from "../../hooks/use-analytics-with-auth";
import { useFolders } from "../../hooks/use-folders";
import { useIntersection } from "../../hooks/use-intersection";
import { useModal } from "../../hooks/use-modal";
import { usePlan } from "../../hooks/use-plan";
import { useUpload } from "../../hooks/use-upload";
import { ModalType } from "../../interfaces/modal-type";
import { AppLayout } from "../../layouts/app.layout";
import { accountStore } from "../../state/account";
import { mediaQuery } from "../../state/media";
import { getAllNotifications } from "../../api/notifications.service";

export const DashboardPage: React.FC = () => {
  const [showInvoiceModal, hideInvoiceModal] = useModal(
    ModalType.OverdueInvoiceModal
  );
  const [isUpdating, setIsUpdating] = useState(false);
  const [{ loading, ids, activeFolderMediaTotal }] = useObservable(
    mediaQuery.select(["loading", "ids", "activeFolderMediaTotal"]),
    {
      loading: mediaQuery.isLoading,
      ids: []
    }
  );
  const { upload } = useUpload();
  const [media] = useObservable(mediaQuery.selectAllFiltered(), []);
  const { selectedId } = useFolders();
  const [unfilteredMediaLength] = useObservable(
    mediaQuery.selectAllUnfilteredLength(),
    0
  );
  const { hasPaymentOverdue, isTrial } = usePlan();
  const { currentAccount, settings, isLoaded, isLoading } = useAccounts();
  const isUploading = Boolean(upload?.isUploading);
  const {
    hasShownOverduePaymentPopUp,
    setHasShownOverduePaymentPopUp,
    setHasUserClosedOverduePaymentPopUp
  } = useContext(AuthContext);
  const { trackEventWithAuth } = useAnalyticsWithAuth();
  const [loadMoreRef, setLoadMoreRef] = React.useState<HTMLDivElement | null>(
    null
  );
  useIntersection(loadMoreRef, "100%", () => loadMoreMedia());
  const showLoadMore = Boolean(
    activeFolderMediaTotal && activeFolderMediaTotal > unfilteredMediaLength
  );

  const onCloseOverduePaymentPopup = () => {
    setHasUserClosedOverduePaymentPopUp(true);
  };

  const loadMoreMedia = () => {
    getAllMedia(selectedId, 12, unfilteredMediaLength).then();
  };

  // Fetch all media for a folder
  useEffect(() => {
    if (!currentAccount?.accountId) {
      return;
    }
    getAllMedia(selectedId, 11).then();
  }, [selectedId, currentAccount?.accountId]);

  useEffect(() => {
    setIsUpdating(loading);
  }, [loading, ids]);

  useEffect(() => {
    if (Boolean(hasShownOverduePaymentPopUp)) {
      return;
    }

    if (hasPaymentOverdue) {
      trackEventWithAuth("Automatic payment failed warning");

      notificationError(
        <>
          <p className="mb-0">
            It seems that the latest payment hasn't been processed. <br />
            Please{" "}
            <span
              className="text-black underline"
              onClick={() => {
                toast.dismiss("payment-overdue");
                showInvoiceModal(
                  <OverdueInvoiceModal closeAlert={hideInvoiceModal} />
                );
              }}
            >
              click here to confirm payment
            </span>{" "}
            before accessing your Subly account.
          </p>
        </>,
        {
          toastId: "payment-overdue",
          closeButton: true,
          autoClose: false,
          onClose: onCloseOverduePaymentPopup,
          closeOnClick: false
        }
      );

      setHasShownOverduePaymentPopUp(true);
    }
  });

  // Track the first time a user has seen the Dashboard or goes to the Dashboard
  useEffect(() => {
    if (!isLoaded || !settings) {
      return;
    }

    if (settings.hasSeenDashboard) {
      trackEventWithAuth("User in dashboard");
      return;
    }

    const track = async () => {
      accountStore.updateAccountSettings({ hasSeenDashboard: true });
      trackEventWithAuth("First time in Dashboard");
      await updateAccountSettings({ hasSeenDashboard: true });
    };

    track();
  }, [isLoaded]);

  useEffect(() => {
    if (!currentAccount?.accountId) {
      return;
    }
    getAllNotifications();
  }, []);

  return (
    <SelectedFilesProvider>
      <AppLayout hideTopBanner={isLoading}>
        <Helmet>
          <title>Subly - Dashboard</title>
          {isTrial && (
            <script
              src="https://a.omappapi.com/app/js/api.min.js"
              async
              data-user="191284"
              data-account="206548"
            />
          )}
        </Helmet>
        <DashboardShortcuts />
        <DashboardNav isUpdating={isUpdating} />
        <div className="container-fluid">
          <MediaList mediaList={media} isUploading={isUploading} />
          {showLoadMore && (
            <div ref={setLoadMoreRef}>
              <Button
                className="mb-4"
                tertiary
                onClick={loadMoreMedia}
                loading={loading}
              >
                Load more...
              </Button>
            </div>
          )}
        </div>
      </AppLayout>
    </SelectedFilesProvider>
  );
};
