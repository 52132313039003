import { notificationError } from "../components/notification";
import { GooglePickerError } from "../interfaces/google-picker";

export const onGooglePickerAuthFailed = (
  googlePickerError: GooglePickerError
): void => {
  //react-google-picker throws the onAuthFailed exception when the user closes the auth pop-up without selecting a Google account
  if (googlePickerError.error === "popup_closed_by_user") {
    return;
  }

  if (googlePickerError.error === "popup_blocked_by_browser") {
    notificationError(
      "Google Drive popup blocked, please allow popups in your browser and try again."
    );
    return;
  }

  if (googlePickerError.error === "idpiframe_initialization_failed") {
    notificationError(
      "Cookies disabled on browser. Please enable to cookies to use Google Drive"
    );

    return;
  }

  notificationError("Google authentication failed");
};
