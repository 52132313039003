import React from "react";
import { useLocation } from "react-router-dom";
import { Modal } from "../../containers/modal/modal";
import { FeatureList } from "../../containers/settings/plans/plans-table";
import { useAnalyticsWithAuth } from "../../hooks/use-analytics-with-auth";
import { usePlan } from "../../hooks/use-plan";
import { SublyPlan } from "../../interfaces/billing";
import { PLANS_PATH } from "../../routes";
import { modalStore } from "../../state/modal/modal.store";
import { getFeatures, getLimits, getPlanNameLabel } from "../../utils/plans";
import { Button } from "../button/button";
import styles from "./upgrade-modal.module.scss";

interface PlaneUpgradeModalProps {
  upgradePlan: SublyPlan;
  closeModal: () => void;
}
export const PlanUpgradeModal: React.FC<PlaneUpgradeModalProps> = ({
  upgradePlan = SublyPlan.Pro100,
  closeModal
}) => {
  const { pathname: redirect } = useLocation();
  const { isFree } = usePlan();
  const { minutesLabel, storageLabel, uploadLabel } = getLimits(upgradePlan);
  const allFeatures = getFeatures(upgradePlan);
  const planName = getPlanNameLabel(upgradePlan);
  const features = {
    items: [
      ...allFeatures.items,
      minutesLabel + " per month",
      storageLabel + " storage",
      uploadLabel + " file upload"
    ]
  };

  const { trackEventWithAuth } = useAnalyticsWithAuth();

  const handleStartTrialUpgrade = () => {
    trackEventWithAuth("Upgrade plan");
    closeModal();
    modalStore.hideAll();
  };

  if (isFree) {
    return (
      <Modal onDismiss={closeModal} className={styles["modal"]} hasCloseIcon>
        <h4 className="text-center">
          You've discovered a paid feature{" "}
          <span role="img" aria-labelledby="cool">
            😎
          </span>
        </h4>
        <p className="text-center">
          Upgrade now to unlock this feature and more:
        </p>

        <div className="d-flex align-items-center justify-content-between mt-4">
          <Button
            secondary
            className="d-block flex-fill mr-3"
            onClick={closeModal}
          >
            Not now
          </Button>
          <Button
            primary
            className="d-block flex-fill"
            to={{ pathname: PLANS_PATH }}
            state={{ redirect }}
            onClick={handleStartTrialUpgrade}
          >
            Upgrade now
          </Button>
        </div>
      </Modal>
    );
  }

  return (
    <Modal onDismiss={closeModal} className={styles["modal"]} hasCloseIcon>
      <h3 className="mt-3 mb-2">
        You've discovered a {planName} feature{" "}
        <span role="img" aria-labelledby="cool">
          😎
        </span>
      </h3>
      <p className="text-black">
        Upgrade now to unlock these features and more:
      </p>
      <FeatureList features={features} />

      <div className="d-flex align-items-center justify-content-end mt-4">
        <Button
          secondary
          className="d-block flex-fill mr-3"
          onClick={closeModal}
        >
          Not now
        </Button>
        <Button
          primary
          className="d-block flex-fill"
          to={{ pathname: PLANS_PATH }}
          state={{ redirect }}
          onClick={handleStartTrialUpgrade}
        >
          Upgrade now
        </Button>
      </div>
    </Modal>
  );
};
