import classNames from "classnames";
import React from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useForm } from "react-hook-form";
import { SignUpParams } from "../../api/auth.service";
import { Button } from "../../components/button/button";
import { Checkbox } from "../../components/checkbox/checkbox";
import { Divider } from "../../components/divider/divider";
import { GoogleButton } from "../../components/google-button/google-button";
import { PasswordRules } from "../../components/password-rules/password-rules";
import { PRIVACY_URL, TERMS_URL } from "../../routes";
import { emailPattern, passwordPattern } from "../../utils/regex-patterns";
import styles from "./auth.module.scss";
import { EmbeddedWebviewMsg } from "./embedded-webview-msg";

interface NewUserEntryProps {
  onSubmit: (data: SignUpParams) => void;
  loading: boolean;
  acceptedTerms: boolean;
  onAcceptedTerms: (accepted: boolean) => void;
  showTermsError: boolean;
  isWebView: boolean;
  initialData: Partial<SignUpParams>;
  isTrial?: boolean;
}

export const NewUserEntry: React.FC<NewUserEntryProps> = ({
  onSubmit,
  showTermsError,
  onAcceptedTerms,
  acceptedTerms,
  initialData,
  isWebView,
  loading,
  isTrial = false
}) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue
  } = useForm<SignUpParams>();

  const handleReCaptchaVerify = React.useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }

    const token = await executeRecaptcha();
    console.log({ token });
    setValue("token", token);
  }, [executeRecaptcha]);

  React.useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  const submitForm = async (data: SignUpParams) => {
    await handleReCaptchaVerify();

    onSubmit(data);
  };

  return (
    <>
      {isTrial ? (
        <div className={styles["header"]}>
          <h2>Start for free</h2>
          <span>No credit card required</span>
        </div>
      ) : (
        <h4 className="mb-4 text-center">Create your Subly Account</h4>
      )}

      {isWebView ? (
        <EmbeddedWebviewMsg />
      ) : (
        <>
          <GoogleButton
            className={styles["btn-google"]}
            mixpanelAction="Sign up Google"
            acceptTerms
          >
            Sign up with Google
          </GoogleButton>

          <Divider>OR</Divider>
        </>
      )}

      <form onSubmit={handleSubmit(submitForm)} data-rewardful="true">
        <div className="form-row">
          <div className="form-group col-6">
            <input
              id="givenName"
              placeholder="John"
              className={classNames("form-control", {
                "is-invalid": errors.givenName
              })}
              autoCapitalize="word"
              defaultValue={initialData.givenName}
              {...register("givenName", { required: true })}
            />
          </div>

          <div className="form-group col-6">
            <input
              id="familyName"
              placeholder="Smith"
              className={classNames("form-control", {
                "is-invalid": errors.familyName
              })}
              autoCapitalize="word"
              defaultValue={initialData.familyName}
              {...register("familyName", { required: true })}
            />
          </div>
        </div>

        <div className="form-group">
          <input
            id="email"
            placeholder="john.smith@gmail.com"
            className={classNames("form-control", {
              "is-invalid": errors.email
            })}
            autoCapitalize="off"
            type="email"
            defaultValue={initialData.email}
            {...register("email", { required: true, pattern: emailPattern })}
          />
        </div>

        <div className="form-group">
          <input
            id="password"
            placeholder="Input your password"
            className={classNames("form-control mb-2", {
              "is-invalid": errors.password
            })}
            type="password"
            defaultValue={initialData.password}
            {...register("password", {
              required: true,
              pattern: passwordPattern
            })}
          />

          <PasswordRules
            value={watch("password")}
            className="d-flex flex-row flex-wrap"
          />
        </div>

        <Checkbox value={acceptedTerms} onChange={onAcceptedTerms}>
          <p
            className={classNames("small mb-0", {
              "text-black": !showTermsError,
              "text-feedback-error": showTermsError,
              [styles["terms"]]: isTrial
            })}
          >
            {!isTrial && "By clicking on Sign up,"} I agree to the{" "}
            <a
              href={TERMS_URL}
              target="_blank"
              rel="noopener noreferrer"
              className={classNames({
                "text-feedback-error": showTermsError
              })}
            >
              Terms of Service
            </a>{" "}
            and{" "}
            <a
              href={PRIVACY_URL}
              target="_blank"
              rel="noopener noreferrer"
              className={classNames({
                "text-feedback-error": showTermsError
              })}
            >
              Privacy Policy.
            </a>
          </p>
        </Checkbox>

        <Button
          primary
          className="btn-block my-2"
          type="submit"
          loading={loading}
        >
          {isTrial ? "Get started" : "Sign up"}
        </Button>
      </form>
    </>
  );
};
