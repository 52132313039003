import classNames from "classnames";
import numeral from "numeral";
import React from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { Badge } from "../../../components/badge/badge";
import { Button } from "../../../components/button/button";
import { PlanUpgradeButton } from "../../../components/button/plans-button";
import {
  BusinessTagName,
  PayAsYouGoTagName,
  PremiumTagName,
  ProTagName
} from "../../../components/icons/brand-assets";
import { ArrowLeftIcon, CheckmarkIcon } from "../../../components/icons/icons";
import { PricingToggle } from "../../../components/toggle/pricing-toggle";
import { AuthContext } from "../../../contexts/auth.context";
import { PlansTableContext } from "../../../contexts/plans-table.context";
import { SublyPlan } from "../../../interfaces/billing";
import { ACCOUNT_PATH, SALES_CONTACT } from "../../../routes";
import {
  getDiscount,
  getPlanData,
  isBusinessPlan,
  SublyPlanData,
  SublyPlanFeatures,
  SublyPlanLimits
} from "../../../utils/plans";
import styles from "./plans-table.module.scss";

export const PlansTable: React.FC = () => {
  const { isYearly, setIsYearly, proPlan, premiumPlan, businessPlan } =
    React.useContext(PlansTableContext);

  return (
    <div className={styles.wrapper}>
      <h1 className={styles["plans-title"]}>
        Starting from only $17 per month
      </h1>
      <div className={styles["plans-setting"]}>
        <Link to={ACCOUNT_PATH}>
          <ArrowLeftIcon width="2rem" height="2rem" />
          Settings
        </Link>
      </div>

      <div className="d-flex align-items-center justify-content-center">
        <PricingToggle value={isYearly} onChange={setIsYearly} />
      </div>

      <div className={styles.container}>
        <div className={styles["cards-container"]}>
          <PlanCard plan={proPlan} />
          <PlanCard plan={premiumPlan} isPopular />
          <PlanCard plan={businessPlan} />
        </div>
      </div>
    </div>
  );
};

interface PlanCardProps {
  plan: SublyPlan;
  isPopular?: boolean;
}
export const PlanCard: React.FC<PlanCardProps> = ({ plan, isPopular }) => {
  const { handleDontCancel, handleDowngrade, isLoading } =
    React.useContext(PlansTableContext);

  const planData = getPlanData(plan);

  return (
    <div className={styles.card}>
      <div className={styles.top}>
        {isPopular && (
          <Badge className={styles["popular-badge"]}>Best Value</Badge>
        )}

        <PlanIcon plan={planData} />

        <PlanPrice planData={planData} />

        <PlanUpgradeButton
          plan={plan}
          loading={isLoading}
          handleDowngrade={handleDowngrade}
          handleDontCancel={handleDontCancel}
        />

        <FeatureList features={planData.features} />
        {isBusinessPlan(plan) && (
          <div className={styles["sales-section"]}>
            <span className={styles["description"]}>
              If you need over 10 users or 1000 mins
            </span>
            <Button to={SALES_CONTACT} type="blank" secondaryBorder isFull>
              Contact us
            </Button>
          </div>
        )}
      </div>

      <div className="position-relative">
        <div className={styles["bottom"]}>
          <div className={styles["box"]}>
            <PlanLimits
              limits={planData.limits}
              isBusiness={isBusinessPlan(plan)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

interface PlanPriceProps {
  planData: SublyPlanData;
}
export const PlanPrice: React.FC<PlanPriceProps> = ({ planData }) => {
  const { isYearly, isLoading, coupon, couponCode } =
    React.useContext(PlansTableContext);
  const { partner, isEducation, user } = React.useContext(AuthContext);

  if (isLoading) {
    return (
      <div>
        <h2 className={classNames(styles.price, "mt-3")}>
          <Skeleton />
        </h2>
      </div>
    );
  }

  const price = planData.price;

  const discount =
    1 -
    getDiscount(planData.plan, {
      partner,
      isEducation,
      email: user?.email,
      coupon,
      couponCode
    });
  const hasDiscount = discount !== 1;

  const factorPrice = isYearly ? price / 12 : price;

  const yearlyPrice = hasDiscount
    ? numeral(price * discount).format("0[.]00")
    : price;

  const priceTitle = (
    <PricingLabel
      hasDiscount={hasDiscount}
      factorPrice={factorPrice}
      yearlyPrice={yearlyPrice}
      discount={discount}
      planData={planData}
      isYearly={isYearly}
    />
  );

  const billedLabel = (
    <BilledLabel
      isYearly={isYearly}
      planData={planData}
      factorPrice={factorPrice}
    />
  );

  return (
    <div>
      {priceTitle}
      <p className="medium m-0 d-inline align-text-bottom font-weight-bold">
        {billedLabel}
      </p>
    </div>
  );
};

interface FeatureListProps {
  features: SublyPlanFeatures;
  isTwoColumn?: boolean;
}
export const FeatureList: React.FC<FeatureListProps> = ({
  features: { title, items },
  isTwoColumn = false
}) => {
  const renderFeatures = items.map((f) => {
    return <FeatureItem feature={f} key={f} />;
  });

  return (
    <ul
      className={classNames(styles["plan-list"], {
        [styles["two-column"]]: isTwoColumn
      })}
    >
      {!isTwoColumn && <li className={styles["item-title"]}>{title}</li>}
      {renderFeatures}
    </ul>
  );
};

interface FeatureItemProps {
  feature: string;
}
export const FeatureItem: React.FC<FeatureItemProps> = ({ feature }) => {
  return (
    <li className={classNames(styles.item)}>
      <CheckmarkIcon className={styles["icon"]} />
      {feature}
    </li>
  );
};

interface PlanLimitsProps {
  limits: SublyPlanLimits;
  isBusiness: boolean;
  onModal?: boolean;
}
export const PlanLimits: React.FC<PlanLimitsProps> = ({ limits, onModal }) => {
  return (
    <>
      <div
        className={classNames(styles.limit, {
          [styles["limit--modal-version"]]: onModal
        })}
      >
        <div>{`${limits.minutesLabel}/month`}</div>
      </div>
      <div
        className={classNames(styles.limit, {
          [styles["limit--modal-version"]]: onModal
        })}
      >
        <div>{limits.storageLabel}</div>
        <div
          className={classNames(styles.label, {
            [styles["label--modal-version"]]: onModal
          })}
        >
          storage
        </div>
      </div>
      <div
        className={classNames(styles.limit, {
          [styles["limit--modal-version"]]: onModal
        })}
      >
        <div>{limits.uploadLabel}</div>
        <div
          className={classNames(styles.label, {
            [styles["label--modal-version"]]: onModal
          })}
        >
          per/file
        </div>
      </div>
    </>
  );
};

interface PlanIconProps {
  plan: SublyPlanData;
}
export const PlanIcon: React.FC<PlanIconProps> = ({ plan }) => {
  if (plan.plan === SublyPlan.Free) {
    return (
      <img src={plan.icon} className={styles["plan-icon"]} alt="plan icon" />
    );
  }

  let bgColor;

  switch (plan.plan) {
    case SublyPlan.Pro100:
    case SublyPlan.Pro100Yearly:
      bgColor = "#0472E7";
      break;
    case SublyPlan.Premium:
    case SublyPlan.PremiumYearly:
      bgColor = "#48248D";
      break;
    case SublyPlan.Business:
    case SublyPlan.BusinessYearly:
      bgColor = "Black";
      break;
    case SublyPlan.PAYG:
      bgColor = "#39EFA5";
      break;
  }

  let tagName;

  switch (plan.plan) {
    case SublyPlan.Premium:
    case SublyPlan.PremiumYearly:
      tagName = <PremiumTagName />;
      break;

    case SublyPlan.Pro100:
    case SublyPlan.Pro100Yearly:
      tagName = <ProTagName />;
      break;

    case SublyPlan.Business:
    case SublyPlan.BusinessYearly:
      tagName = <BusinessTagName />;
      break;

    case SublyPlan.PAYG:
      tagName = <PayAsYouGoTagName />;
      break;
  }

  return (
    <div className={styles["plan-name-box"]}>
      <img
        src={plan.icon}
        className={classNames(styles["plan-icon"], styles["plan-icon-frame"])}
        alt="plan icon"
        style={{ backgroundColor: bgColor }}
      />
      <div className={styles["plan-name"]}>
        <span className={styles["subly"]}>Subly</span>
        {tagName}
      </div>
    </div>
  );
};

interface BilledLabelProps {
  planData: SublyPlanData;
  isYearly: boolean;
  factorPrice: number;
}

const BilledLabel: React.FC<BilledLabelProps> = ({
  planData,
  isYearly,
  factorPrice
}) => {
  if (!isYearly && planData.plan === SublyPlan.BusinessYearly) {
    return <>Billed yearly</>;
  }

  if (!isYearly) {
    return null;
  }

  if (planData.plan === SublyPlan.BusinessYearlyCustom) {
    return <p className="small m-0">&nbsp;</p>;
  }

  return <>{`($${factorPrice} /month)`}</>;
};

interface PricingLabelProps {
  planData: SublyPlanData;
  hasDiscount: boolean;
  factorPrice: number;
  discount: number;
  yearlyPrice: string | number;
  isYearly: boolean;
}
const PricingLabel: React.FC<PricingLabelProps> = ({
  planData,
  hasDiscount,
  factorPrice,
  discount,
  yearlyPrice,
  isYearly
}) => {
  if (planData.plan === SublyPlan.BusinessYearlyCustom) {
    return <h2 className={styles.price}>Let's talk</h2>;
  }
  let suffix = isYearly ? "/year" : "/month";
  if (planData.plan === SublyPlan.PAYG) {
    suffix = `/${planData.limits.minutes} minutes`;
  }

  if (hasDiscount) {
    return (
      <h2 className={styles.price}>
        <span className={styles["old-price"]}>
          ${numeral(factorPrice).format("0[.]00")}
        </span>{" "}
        ${numeral(factorPrice * discount).format("0[.]00")}
        <span>/month</span>
      </h2>
    );
  }

  return (
    <h2 className={styles.price}>
      <span className={styles["dollar-sign"]}>$</span>
      {isYearly ? yearlyPrice : factorPrice}
      <span>{suffix}</span>
    </h2>
  );
};
