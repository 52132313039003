import queryString from "query-string";
import { useLocation } from "react-router-dom";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useQuery<T = any>(): T {
  const { search } = useLocation();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return queryString.parse(search) as any as T;
}
