import React from "react";
import Lottie from "react-lottie-player";
import companyAnimation from "../../../../assets/images/onboarding/company-animation.json";
import { useOnboarding } from "../../../../contexts/onboarding.context";
import { OnboardingButtons } from "../../onboarding-buttons";
import { OnboardingSteps } from "../../onboarding-steps";
import styles from "./onboarding-company.module.scss";

export const OnboardingCompany: React.FC = () => {
  const { data, setData } = useOnboarding();

  const handleCompanyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const companyName = e.target.value ?? "";
    setData((d) => ({ ...d, companyName }));
  };

  return (
    <div className={styles.wrapper}>
      <Lottie
        loop
        animationData={companyAnimation}
        speed={0.5}
        className={styles.image}
        play
      />
      <div className={styles.container}>
        <div className={styles.form}>
          <h3 className={styles.header}>
            What is the name of your company?
            <OnboardingSteps />
          </h3>

          <input
            type="text"
            className="form-control"
            value={data.companyName ?? ""}
            placeholder="Company name"
            onChange={handleCompanyChange}
          />
        </div>
        <OnboardingButtons />
      </div>
    </div>
  );
};
