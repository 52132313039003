import { AspectRatio, MediaConfig } from "@getsubly/common";
import { AccountType } from "../api/billing.service";
import { Folder } from "../state/folders/folders.store";
import { AudioTemplate, ProgressBarStyle } from "../types/media-editor.types";
import { BillingDetails } from "./billing";

export interface UserAccount {
  accountId: string;
  accountName: string;
  accountType: AccountType;
  creditFreeSeconds: number;
  creditPaidSeconds: number;
  creditExtraSeconds: number;
  creditPaygSeconds: number;
  billing?: {
    loading: boolean;
    loaded: boolean;
    details?: BillingDetails;
    stripeId?: string;
  };
  folders: Folder[];
  mediaCount?: number;
  mediaSharedWithMeCount?: number;
  invitations: Invitation[];
  members?: AccountMember[];
  owner: string;
  isOwner?: boolean;
  role?: RoleName;
  settings?: AccountSettings;
  totalFreeSecondsUsed: number;
  totalPaidSecondsUsed: number;
  updatedAt: Date;
  teamCapacity?: number;
  isPayg: boolean;
  additionalStorageBytes: number;
}

export interface Invitation {
  id: string;
  email: string;
  role: RoleName;
}

export interface AccountMember {
  id: string;
  name: string;
  email: string;
  createdAt: Date;
  role: RoleName;
}

export enum RoleName {
  FullAccess = "Full Access",
  Editor = "Editor"
}

export enum NewBadgeFeature {
  MediaComments = "MediaComments"
}

export type AccountTemplateStyles = Omit<
  MediaConfig,
  "playResX" | "playResY" | "snippets"
>;
export interface AccountTemplate extends AccountTemplateStyles {
  id: string;
  name: string;
  ratio: AspectRatio;
}

export interface ProgressBarStylesSettings {
  [key: string]: ProgressBarStyle;
}

export type NewFeatureBadgeSettings = {
  [key in NewBadgeFeature]: Date;
};
export interface AccountSettings {
  primaryColors?: string[];
  outlineColors?: string[];
  aspectRatioColors?: string[];
  borderColors?: string[];
  hasSeenMediaEditorTour?: boolean;
  hasSeenSpeakersTour?: boolean;
  customStorageBytes?: number;
  customUploadLimitBytes?: number;
  templates: AccountTemplate[];
  lastVersionTour: string;
  showReleaseModal: boolean;
  hasTranslated: boolean;
  hidePositioningHint: boolean;
  hideTranslationWarning: boolean;
  beta?: boolean;
  hasAlreadyTrialled?: boolean;
  hasSeenTrialModal: boolean;
  hasSeenTrialWelcome: boolean;
  hasSeenTrialOneDownloadRemain: boolean;
  hasSeenTrialLimitModal: boolean;
  migratedProToPremium?: boolean;
  hasUsedReturnCoupon?: boolean;
  audioTemplates: AudioTemplate[];
  progressBars: ProgressBarStylesSettings;
  trialDownloads: number;
  hasSeenTemplateDeleteModal: boolean;
  hasSeenDashboard?: boolean;
  newFeatureBadge?: NewFeatureBadgeSettings;
}

export const DEFAULT_PRIMARY_COLORS = [
  "#000000",
  "#7F1D1D",
  "#78350F",
  "#075535",
  "#003A77",
  "#2C0575",
  "#374151",
  "#991B1B",
  "#B45309",
  "#0EB370",
  "#034A96",
  "#3F08A6",
  "#6B7280",
  "#DC2626",
  "#D97706",
  "#12E28E",
  "#0472E7",
  "#691FF5",
  "#9CA3AF",
  "#EF4444",
  "#FFAB00",
  "#39EFA5",
  "#228BFB",
  "#8A50F7",
  "#D1D5DB",
  "#FCA5A5",
  "#FCD34D",
  "#86EFAC",
  "#55A6FC",
  "#B794FA",
  "#E5E7EB",
  "#FEE2E2",
  "#FDE68A",
  "#BBF7D0",
  "#A8D1FF",
  "#D5C6F1",
  "#FFFFFF",
  "#FEF2F2",
  "#FEF3C7",
  "#DCFCE7",
  "#DBECFF",
  "#F3EFFB"
];
