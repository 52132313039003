import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import countries from "typed-countries";
import {
  AccountType,
  BillingDetailsParams,
  setBillingDetails
} from "../../../api/billing.service";
import { Button } from "../../../components/button/button";
import {
  notificationError,
  notificationSuccess
} from "../../../components/notification";
import {
  getTaxIdTypeSelectOptions,
  TaxIdTypeSelectOption
} from "../../../interfaces/billing";
import { BILLING_PATH } from "../../../routes";
import { accountQuery } from "../../../state/account";
import { BillingDetailsForm } from "./billing-details-form";

export interface BillingDetailsForm {
  name: string;
  address: string;
  city: string;
  state: string;
  postalCode: string;
  country: {
    value: string;
    label: string;
  };
  taxIdNumber?: string;
  taxIdType?: TaxIdTypeSelectOption;
}

export const AddBillingDetailsForm: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [isBusiness, setBusiness] = React.useState(false);
  const form = useForm<BillingDetailsForm>();
  const watchTaxIdType = form.watch("taxIdType");
  const taxIdTypeSelectOptions = getTaxIdTypeSelectOptions();
  const navigate = useNavigate();

  useEffect(() => {
    const account$ = accountQuery
      .select(["loading", "billing"])
      .subscribe(({ loading, billing }) => {
        setLoading(loading || !!billing?.loading);
        const name = billing?.details?.name;
        const address = billing?.details?.address;
        if (name && address) {
          const countryLabel =
            countries.find((c) => c.iso === address.country)?.name ||
            address.country;

          let taxIdTypeSelectOption: TaxIdTypeSelectOption | undefined;

          if (billing?.details?.taxId?.type) {
            taxIdTypeSelectOption = taxIdTypeSelectOptions?.find(
              (x) =>
                x.code === billing?.details?.taxId.type &&
                x.country === billing?.details?.taxId.country
            );
          }
          const initForm: BillingDetailsForm = {
            name: name,
            address: address.line1,
            city: address.city,
            state: address.state,
            postalCode: address.postalCode,
            country: {
              value: address.country,
              label: countryLabel
            },
            taxIdNumber: billing?.details?.taxId?.value,
            taxIdType: taxIdTypeSelectOptions.find(
              (x) => x.value === taxIdTypeSelectOption?.value
            )
          };

          form.reset(initForm);

          if (billing?.details?.accountType === AccountType.Business) {
            setBusiness(true);
          }
        }
      });

    return () => account$.unsubscribe();
  }, [form.reset]);

  const onSubmit = async ({
    name,
    address: line1,
    city,
    postalCode,
    state,
    country: { value: country },
    taxIdNumber,
    taxIdType
  }: BillingDetailsForm) => {
    try {
      const billingDetails: BillingDetailsParams = {
        name,
        line1,
        city,
        postalCode,
        state,
        country,
        taxIdNumber: taxIdNumber ?? undefined,
        taxIdType: taxIdType?.code,
        accountType: isBusiness ? AccountType.Business : AccountType.Personal
      };

      await setBillingDetails(billingDetails);

      notificationSuccess("Your details have been updated.");

      navigate(BILLING_PATH);
    } catch (e) {
      let message = "Failed to update billing details.";

      if (e?.response?.data?.code == "tax_id_invalid") {
        message += ` (Invalid tax number - it should follow this format: ${watchTaxIdType?.format})`;
      }

      notificationError(message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <BillingDetailsForm
      className="edit-account-form mt-sm-5"
      useForm={form}
      handleSubmit={form.handleSubmit(onSubmit)}
    >
      <div className="form-group d-flex justify-content-end mt-3">
        <Button secondary to={BILLING_PATH} className="mr-2">
          Cancel
        </Button>
        <Button type="submit" primary className="mr-0" loading={loading}>
          Save changes
        </Button>
      </div>
    </BillingDetailsForm>
  );
};
