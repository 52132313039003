import React from "react";
import { BaseBanner } from "./base-banner";
import config from "../../config";

// This component shows a maintenance message on a top bar. To set this message just update
// the environment variable `REACT_APP_MAINTENANCE_MODE_MESSAGE` with the message to be displayed.
// The advised message length is around 90 characters.
export const MaintenanceBanner: React.FC = () => {
  const message = config.maintenanceMode.message;

  if (!message) {
    return null;
  }

  return <BaseBanner message={message} />;
};
