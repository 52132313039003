import { InformationCircleIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Badge } from "../../../components/badge/badge";
import { Button } from "../../../components/button/button";
import { Fieldset } from "../../../components/form-controls/fieldset";
import { LockFeaturePremium } from "../../../components/lock-feature/lock-feature-premium";
import { useMediaInfo } from "../../../hooks/use-media-info";
import { usePermissions } from "../../../hooks/use-permissions";
import { SublyPlan } from "../../../interfaces/billing";
import { uploadStore } from "../../../state/upload";
import { urlPattern } from "../../../utils/regex-patterns";
import { ToolTip } from "../../tooltip/tooltip";
import styles from "./url-uploader.module.scss";

interface UrlUploaderProps {
  className?: string;
}
export const UrlUploader: React.FC<UrlUploaderProps> = ({ className }) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<{ url: string }>();
  const [videoURL, setVideoURL] = useState<string>("");
  const { convertUrlToUploadFile } = useMediaInfo();
  const { hasPermission: hasPremiumPermission } = usePermissions(
    SublyPlan.Premium
  );

  const addFileFromURL = async (url: string): Promise<void> => {
    if (!url) {
      return;
    }

    if (!hasPremiumPermission) {
      uploadStore.resetUpload();
    }

    uploadStore.setChecking(true);

    const uploadFile = await convertUrlToUploadFile(url);

    if (uploadFile) {
      uploadStore.addFiles([uploadFile]);
    }

    uploadStore.setChecking(false);
  };
  const message = (
    <>
      Upload video from URL:
      <br />
      Copy and paste link from any of the following Youtube or Vimeo.
    </>
  );
  return (
    <div className={className}>
      <Fieldset
        className="col-12"
        title={
          <div className="d-flex align-item-center">
            Or upload video from URL
            <Badge className="ml-1">
              {hasPremiumPermission ? "Beta" : "Premium"}
            </Badge>
            <ToolTip
              offset={{ top: -5, left: -1.5 }}
              forceRebuild
              text={message}
            >
              <InformationCircleIcon className={styles["information-icon"]} />
            </ToolTip>
          </div>
        }
      >
        <LockFeaturePremium mixpanelEvent="URL Transcribe - Pop up to upgrade">
          <form
            onSubmit={handleSubmit(async ({ url }) => {
              await addFileFromURL(url);
              setVideoURL("");
            })}
          >
            <div className="d-flex">
              <input
                id="url"
                className={classNames("form-control", {
                  "is-invalid": errors.url
                })}
                value={videoURL}
                {...register("url", { pattern: urlPattern })}
                placeholder="https://www..."
                onChange={(v) => setVideoURL(v.target.value)}
              />
              <Button
                type="submit"
                primary
                className="upload-btn ml-2"
                disabled={!videoURL}
              >
                Add
              </Button>
            </div>
            {errors.url && (
              <span className={classNames(styles.error)}>
                Please enter a valid URL
              </span>
            )}
          </form>
        </LockFeaturePremium>
      </Fieldset>
    </div>
  );
};
