import { useObservable } from "@mindspace-io/react";
import { UploadListFile } from "../containers/dashboard/upload-modal/files-list";
import {
  UploadFile,
  uploadQuery,
  UploadState,
  UploadUrlFile,
  UploadZoomFile
} from "../state/upload";

interface UseUpload {
  upload: UploadState;
  uploadFolderId?: string;
  totalCount: number;
  totalDuration: number;
  totalSize: number;
  isChecking?: boolean;
  progress: number;
  thumbnail?: string;
  mediaName: string;
  isGoogleDrive?: boolean;
  queue: (UploadFile | UploadUrlFile | UploadZoomFile)[];
  uploadList: UploadListFile[];
}

export function useUpload(): UseUpload {
  const [upload] = useObservable(uploadQuery.select(), uploadQuery.getValue());
  const [uploadFolderId] = useObservable(
    uploadQuery.selectUploadFolderId(),
    uploadQuery.uploadFolderId
  );
  const [totalCount] = useObservable(uploadQuery.selectTotalCount(), 0);
  const [totalDuration] = useObservable(uploadQuery.selectTotalDuration(), 0);
  const [totalSize] = useObservable(uploadQuery.selectTotalSize(), 0);
  const [uploadList] = useObservable(uploadQuery.selectUploadList(), []);

  return {
    upload,
    uploadFolderId,
    totalCount,
    totalDuration,
    totalSize,
    uploadList,
    ...upload
  };
}
