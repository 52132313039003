import React from "react";
import { AuthNavbar } from "../containers/navbar/auth-navbar";
import classNames from "classnames";
import styles from "./auth-layout.module.scss";
import { CommunitySpotlight } from "../components/community-spotlight/community-spotlight";
import { GreenDotsIcon, RedCurveIcon } from "../components/icons/auth-assets";
import { TestimonialContext } from "../contexts/testimonial.context";

interface AuthLayoutProps {
  showLogin?: boolean;
  showRight?: boolean;
}
export const AuthLayout: React.FC<AuthLayoutProps> = ({
  showLogin,
  showRight = true,
  children
}) => {
  const { testimonial } = React.useContext(TestimonialContext);

  return (
    <>
      <AuthNavbar showLogin={showLogin} showRight={showRight} />
      <div className="container">
        <div className={styles.authContainer}>
          <div
            className={classNames(
              "d-flex justify-content-center",
              styles.formContainer
            )}
          >
            {children}
          </div>
          <CommunitySpotlight
            testimonial={testimonial}
            className={styles.customerSpotlight}
          />

          <GreenDotsIcon
            className={classNames(
              "d-none d-md-block position-absolute",
              styles.dots
            )}
          />

          <RedCurveIcon
            className={classNames(
              "d-none d-md-block position-absolute",
              styles.vectorCurve
            )}
          />
        </div>
      </div>
    </>
  );
};
