import { BurnQuality, LINKEDIN_PARTNER } from "@getsubly/common";
import { Coupon } from "../api/billing.service";
import businessIcon from "../assets/images/plans/business.png";
import freeIcon from "../assets/images/plans/free.svg";
import {
  default as premiumIcon,
  default as paygIcon
} from "../assets/images/plans/premium.png";
import proIcon from "../assets/images/plans/pro.png";
import { PartnerOptions } from "../contexts/auth.context";
import {
  PlanInterval,
  SublyPlan,
  SubscriptionStatus
} from "../interfaces/billing";
import { ModalType } from "../interfaces/modal-type";
import { PLANS_PATH, PLANS_UPGRADE_PATH } from "../routes";

export const isInferiorPlan = (
  plan: SublyPlan,
  currentPlan: SublyPlan
): boolean => {
  const planIndex = subscriptionPlanIndex(plan);
  const currentPlanIndex = subscriptionPlanIndex(currentPlan);

  return planIndex <= currentPlanIndex;
};

export const subscriptionPlanIndex = (plan: SublyPlan): number => {
  return Object.values(SublyPlan).findIndex((v) => v === plan);
};

export const subscriptionShortName = (plan: SublyPlan): string => {
  return plan.split(" ")[1].trim();
};

export const maxFileDuration = 14400; // 4 hour limit with AWS transcribe

export const ANNUAL_PRO_LIST = [
  SublyPlan.Pro100Yearly,
  SublyPlan.Pro500Yearly,
  SublyPlan.Pro1000Yearly
];

export const PRO_LIST = [
  SublyPlan.Pro100,
  SublyPlan.Pro500,
  SublyPlan.Pro1000,
  ...ANNUAL_PRO_LIST
];

export const ANNUAL_PREMIUM_LIST = [
  SublyPlan.PremiumYearly,
  SublyPlan.PremiumEducation,
  SublyPlan.Premium1000Yearly
];

export const PREMIUM_LIST = [
  SublyPlan.Premium,
  SublyPlan.Premium1000,
  ...ANNUAL_PREMIUM_LIST
];

export const ANNUAL_BUSINESS_LIST = [
  SublyPlan.BusinessYearly,
  SublyPlan.BusinessYearlyCustom
];

export const BUSINESS_LIST = [SublyPlan.Business, ...ANNUAL_BUSINESS_LIST];
export const PREMIUM_AND_HIGHER_LIST = [...PREMIUM_LIST, ...BUSINESS_LIST];
export const PRO_AND_HIGHER_LIST = [...PRO_LIST, ...PREMIUM_AND_HIGHER_LIST];

export const isProPlan = (plan: SublyPlan): boolean => {
  return PRO_LIST.includes(plan);
};

export const isProOrHigherPlan = (plan: SublyPlan): boolean => {
  return PRO_AND_HIGHER_LIST.includes(plan);
};

export const isPremiumOrHigherPlan = (plan: SublyPlan): boolean => {
  return PREMIUM_AND_HIGHER_LIST.includes(plan);
};

export const isProAnnualPlan = (plan: SublyPlan): boolean => {
  return ANNUAL_PRO_LIST.includes(plan);
};

export const isPremiumPlan = (plan: SublyPlan): boolean => {
  return PREMIUM_LIST.includes(plan);
};

export const isBusinessPlan = (plan: SublyPlan): boolean => {
  return BUSINESS_LIST.includes(plan);
};

export const isTrial = (status?: SubscriptionStatus): boolean => {
  return status === SubscriptionStatus.Trialing;
};

export enum PlanLabelTypes {
  Pro = "Pro",
  Premium = "Premium",
  Business = "Business"
}

export const getPlanNameLabel = (plan: SublyPlan): string => {
  switch (plan) {
    case SublyPlan.Free:
      return "Free";
    case SublyPlan.Pro100:
    case SublyPlan.Pro500:
    case SublyPlan.Pro1000:
    case SublyPlan.Pro100Yearly:
    case SublyPlan.Pro500Yearly:
    case SublyPlan.Pro1000Yearly:
      return "Pro";
    case SublyPlan.Premium:
    case SublyPlan.PremiumYearly:
    case SublyPlan.PremiumEducation:
    case SublyPlan.Premium1000:
    case SublyPlan.Premium1000Yearly:
      return "Premium";
    case SublyPlan.Business:
    case SublyPlan.BusinessYearly:
    case SublyPlan.BusinessYearlyCustom:
      return "Business";
    case SublyPlan.PAYG:
      return "Pay As You Go";
  }
};

export const getPlanPrice = (plan: SublyPlan): number => {
  switch (plan) {
    case SublyPlan.Free:
      return 0;
    case SublyPlan.Pro100:
      return 17;
    case SublyPlan.Pro500:
      return 39;
    case SublyPlan.Pro1000:
      return 69;
    case SublyPlan.Pro100Yearly:
      return 168;
    case SublyPlan.Pro500Yearly:
      return 372;
    case SublyPlan.Pro1000Yearly:
      return 660;
    case SublyPlan.Premium:
      return 39;
    case SublyPlan.PremiumYearly:
      return 372;
    case SublyPlan.Premium1000:
      return 149;
    case SublyPlan.Premium1000Yearly:
      return 1548;
    case SublyPlan.Business:
      return 218;
    case SublyPlan.BusinessYearly:
      return 2184;
    case SublyPlan.PAYG:
      return PAYG_PRICE * 20;
    default:
      return 9999;
  }
};

export const outOfMinutes = (plan: SublyPlan, credit: number): boolean => {
  switch (plan) {
    case SublyPlan.Free:
      return true;
    case SublyPlan.Pro100:
    case SublyPlan.Pro500:
    case SublyPlan.Pro1000:
    case SublyPlan.Pro100Yearly:
    case SublyPlan.Pro500Yearly:
    case SublyPlan.Pro1000Yearly:
    case SublyPlan.PAYG:
      return credit < 20;
    case SublyPlan.Premium:
    case SublyPlan.PremiumYearly:
    case SublyPlan.PremiumEducation:
    case SublyPlan.Premium1000:
    case SublyPlan.Premium1000Yearly:
      return credit < 40;
    case SublyPlan.Business:
    case SublyPlan.BusinessYearly:
    case SublyPlan.BusinessYearlyCustom:
      return credit < 200;
  }
};

export const PAYG_PRICE = 0.6;
export const STORAGE_PRICE_MONTHLY = 4.99;
export const STORAGE_PRICE_YEARLY = 59.99;
export const STORAGE_AMOUNT = 50;

// List of some coupon codes hardcoded across the app
export enum SublyCouponCode {
  EarlyBird = "EARLYBIRD",
  FMUpgrade = "FMUPGRADE",
  EDUpgrade = "EDUPGRADE",
  PremiumUpgrade = "PREMUPGRADE",
  HubspotOffer = "UG6VQB",
  Pro50 = "PRO50"
}

export interface ShowDiscountOptions {
  partner?: PartnerOptions;
  isEducation?: boolean;
  email?: string;
  proStartDate?: Date;
  coupon?: Coupon;
  couponCode?: string;
}
export const plansWithDiscount = (
  options: ShowDiscountOptions
): SublyPlan[] => {
  if (options?.couponCode === SublyCouponCode.HubspotOffer) {
    return [SublyPlan.Pro100, SublyPlan.Premium, SublyPlan.Premium1000];
  }

  if (options.partner?.name === LINKEDIN_PARTNER.name) {
    return [...PRO_LIST, ...PREMIUM_LIST];
  }

  if (options.partner?.showBanner) {
    return [SublyPlan.Pro100];
  }

  return [];
};

export const getDiscount = (
  plan: SublyPlan,
  options: ShowDiscountOptions
): number => {
  const plans = plansWithDiscount(options);

  if (!plans.includes(plan)) {
    return 0;
  }

  if (options.coupon?.percent_off) {
    return options.coupon.percent_off / 100;
  }

  if (options.partner?.name === LINKEDIN_PARTNER.name) {
    return 0.25;
  }

  return 0.2;
};

export const getPlanMinutes = (plan: SublyPlan): number => {
  return getLimits(plan).minutes;
};

// TODO: Check this modal
export const showPlanUpgradeModal = (
  currentPlan: SublyPlan,
  beforeCredit: number,
  afterCredit: number,
  isTrial: boolean,
  isPayg: boolean
): ModalType | undefined => {
  if (isPayg) {
    return;
  }

  if (isTrial) {
    return;
  }

  if (currentPlan === SublyPlan.Pro1000) {
    return;
  }

  // Check if there was an update in the credit
  if (beforeCredit <= afterCredit) {
    return;
  }

  const planSeconds = getPlanMinutes(currentPlan) * 60;

  const beforePercentage = 1 - beforeCredit / planSeconds;
  const afterPercentage = 1 - afterCredit / planSeconds;

  // >95%
  if (beforePercentage < 0.95 && afterPercentage > 0.95) {
    // Free
    if (currentPlan === SublyPlan.Free) {
      return ModalType.UpgradeFree95;
    }
  }

  // >80%
  if (beforePercentage < 0.8 && afterPercentage > 0.8) {
    return ModalType.UpgradeReach80;
  }

  return;
};

export const parseCreditToText = (
  totalCredit: number,
  withSeconds = true,
  fullText = true,
  ceil = false
): string => {
  if (!totalCredit) {
    return fullText ? "0 minutes" : "0 min";
  }

  let minutes = Math.floor(totalCredit / 60);
  const seconds = totalCredit - minutes * 60;

  minutes = seconds > 0 && ceil ? minutes + 1 : minutes;

  if (minutes && seconds && withSeconds) {
    return `${minutes} min ${seconds} sec`;
  }
  if (seconds && withSeconds) {
    return `${seconds} sec`;
  }

  return fullText ? `${minutes} minutes` : `${minutes} min`;
};

export const getDiscountCode = (
  hasDiscount: boolean,
  options: ShowDiscountOptions
): string | undefined => {
  if (options.coupon && options.couponCode) {
    return options.couponCode;
  }

  if (options.couponCode === SublyCouponCode.Pro50) {
    return options.couponCode;
  }

  if (!hasDiscount) {
    return;
  }

  if (options.partner?.discountCode) {
    return options.partner.discountCode;
  }

  return;
};

export const getFeatures = (plan: SublyPlan): SublyPlanFeatures => {
  switch (plan) {
    case SublyPlan.Free:
      return {
        items: [
          "Automatic subtitles for video",
          "Edit subtitles and timestamps",
          "Public link sharing ",
          "Subly watermark"
        ]
      };
    case SublyPlan.Pro100:
    case SublyPlan.Pro500:
    case SublyPlan.Pro1000:
    case SublyPlan.Pro100Yearly:
    case SublyPlan.Pro500Yearly:
    case SublyPlan.Pro1000Yearly:
      return {
        title: "Pro includes:",
        items: [
          "Subtitles and timestamps editor",
          "Automatic subtitles for video",
          "Captioned video downloads",
          "Automatic export to SRT, TXT, VTT",
          "Content styling and branding",
          "Audio files transcription",
          "Video resizing for social platforms",
          "Audio to video conversion",
          "Public link sharing",
          "Unlimited downloads"
        ]
      };
    case SublyPlan.Premium:
    case SublyPlan.PremiumYearly:
    case SublyPlan.PremiumEducation:
    case SublyPlan.Premium1000:
    case SublyPlan.Premium1000Yearly:
      return {
        title: "All you get with Pro, plus:",
        items: [
          "Subtitle translator in 67 languages",
          "Team collaboration for up to 3 users",
          "HD files",
          "Google Drive integration",
          "Styling templates",
          "Audio to video converter",
          "Bulk file uploads",
          "File upload from URL",
          "Speaker recognition",
          "Trim and snippets editor",
          "Filler words removal"
        ]
      };
    case SublyPlan.Business:
      return {
        title: "All you get with Premium, plus:",
        items: [
          "Up to 10 team members",
          "Priority support",
          "Account manager",
          "Video / audio comments"
        ]
      };
    case SublyPlan.BusinessYearly:
      return {
        title: "All you get with Premium, plus:",
        items: [
          "Up to 10 team members",
          "Priority support",
          "Account manager",
          "Video / audio comments",
          "12,000 minutes upfront"
        ]
      };
    case SublyPlan.BusinessYearlyCustom:
      return {
        title: "All you get with Premium, plus:",
        items: [
          "Team collaboration",
          "Unlimited team members",
          "Priority support",
          "Account manager",
          "Team billing by invoice",
          "Scalable plan"
        ]
      };
    case SublyPlan.PAYG:
      return {
        items: [
          "No expiry date to use minutes",
          "Subtitle translator in 67 languages",
          "2 GB file upload",
          "50 GB file storage",
          "HD files",
          "Styling templates",
          "Advanced video editing",
          "Bulk upload files",
          "Transcribe from URL",
          "Remove filler words in one click",
          "Edit subtitles and timestamps",
          "Automatic subtitles for video",
          "Download SRT, TXT and VTT",
          "Style and brand content",
          "Audio files transcription",
          "Resize for social platforms",
          "Convert audio to video",
          "File sharing up to 3 users",
          "Public link sharing"
        ]
      };
  }
};

export const getLimits = (plan: SublyPlan): SublyPlanLimits => {
  switch (plan) {
    case SublyPlan.Pro100:
    case SublyPlan.Pro100Yearly:
      return {
        minutes: 100,
        minutesLabel: "100 min",
        storage: 10,
        storageLabel: "10 GB",
        upload: 1,
        uploadLabel: "1 GB"
      };
    case SublyPlan.Pro500:
    case SublyPlan.Pro500Yearly:
    case SublyPlan.Premium:
    case SublyPlan.PremiumYearly:
    case SublyPlan.PremiumEducation:
      return {
        minutes: 240,
        minutesLabel: "240 min",
        storage: 50,
        storageLabel: "50 GB",
        upload: 2,
        uploadLabel: "2 GB"
      };
    case SublyPlan.Pro1000:
    case SublyPlan.Pro1000Yearly:
    case SublyPlan.Premium1000:
    case SublyPlan.Premium1000Yearly:
      return {
        minutes: 1000,
        minutesLabel: "1000 min",
        storage: 50,
        storageLabel: "50 GB",
        upload: 2,
        uploadLabel: "2 GB"
      };
    case SublyPlan.Business:
    case SublyPlan.BusinessYearly:
      return {
        minutes: 1000,
        minutesLabel: "1000+ min",
        storage: 200,
        storageLabel: "200 GB",
        upload: 5,
        uploadLabel: "5 GB"
      };

    case SublyPlan.BusinessYearlyCustom:
      return {
        minutes: 1000,
        minutesLabel: "1000+ min",
        storage: 200,
        storageLabel: "200 GB",
        upload: 5,
        uploadLabel: "5 GB"
      };
    case SublyPlan.PAYG:
      return {
        minutes: 20,
        minutesLabel: "—",
        storage: 50,
        storageLabel: "50 GB",
        upload: 2,
        uploadLabel: "2 GB"
      };
    case SublyPlan.Free:
      return {
        minutes: 0,
        minutesLabel: "0 min",
        storage: 1,
        storageLabel: "1 GB",
        upload: 0.5,
        uploadLabel: "500 MB"
      };
  }
};

export const payAsYouGoLimits: Partial<SublyPlanLimits> = {};
export const getPlanStorage = (plan: SublyPlan): number =>
  getLimits(plan).storage;

export const getPlanUploadLimit = (plan: SublyPlan): number =>
  getLimits(plan).upload;

export const getPlanIcon = (plan: SublyPlan): string => {
  switch (plan) {
    case SublyPlan.Free:
      return freeIcon;
    case SublyPlan.Pro100:
    case SublyPlan.Pro500:
    case SublyPlan.Pro1000:
    case SublyPlan.Pro100Yearly:
    case SublyPlan.Pro500Yearly:
    case SublyPlan.Pro1000Yearly:
      return proIcon;
    case SublyPlan.Premium:
    case SublyPlan.PremiumYearly:
    case SublyPlan.PremiumEducation:
    case SublyPlan.Premium1000:
    case SublyPlan.Premium1000Yearly:
      return premiumIcon;
    case SublyPlan.Business:
    case SublyPlan.BusinessYearly:
    case SublyPlan.BusinessYearlyCustom:
      return businessIcon;
    case SublyPlan.PAYG:
      return paygIcon;
  }
};

export enum TrialState {
  Pro = "trial-pro",
  Premium = "trial-premium",
  PickPlanMonth = "pick-plan-month",
  PickPlanYear = "pick-plan-year"
}

export enum PromoState {
  Pro50 = "pro-50"
}
interface NavigateToPlan {
  plan?: SublyPlan;
  interval?: PlanInterval;
  pathname?: string;
  coupon?: SublyCouponCode;
  couponCode?: SublyCouponCode;
  analyticsEvent?: string;
}
export const URLStateToPlan = (
  state?: string,
  coupon?: SublyCouponCode
): NavigateToPlan => {
  if (!state) {
    return {};
  }

  switch (state.toLowerCase()) {
    case "pro":
    case "pro100":
      return {
        plan: SublyPlan.Pro100,
        interval: PlanInterval.Monthly,
        pathname: PLANS_UPGRADE_PATH,
        coupon,
        analyticsEvent: "Purchased Pro Monthly"
      };
    case "proannual":
    case "pro100annual":
    case "pro100annualy":
      return {
        plan: SublyPlan.Pro100Yearly,
        interval: PlanInterval.Yearly,
        pathname: PLANS_UPGRADE_PATH,
        coupon,
        analyticsEvent: "Purchased Pro Yearly"
      };
    case "premium":
      return {
        plan: SublyPlan.Premium,
        interval: PlanInterval.Monthly,
        pathname: PLANS_UPGRADE_PATH,
        coupon,
        analyticsEvent: "Purchased Premium Monthly"
      };
    case "premiumannual":
    case "premiumannualy":
      return {
        plan: SublyPlan.PremiumYearly,
        interval: PlanInterval.Yearly,
        pathname: PLANS_UPGRADE_PATH,
        coupon,
        analyticsEvent: "Purchased Premium Yearly"
      };
    case "business":
      return {
        plan: SublyPlan.Business,
        interval: PlanInterval.Monthly,
        pathname: PLANS_UPGRADE_PATH,
        coupon,
        analyticsEvent: "Purchased Business Monthly"
      };
    case "businessyearly":
      return {
        plan: SublyPlan.BusinessYearly,
        interval: PlanInterval.Yearly,
        pathname: PLANS_UPGRADE_PATH,
        coupon,
        analyticsEvent: "Purchased Business Yearly"
      };
    case "payg":
      return {
        plan: SublyPlan.PAYG,
        pathname: PLANS_UPGRADE_PATH,
        coupon,
        analyticsEvent: "Purchased Pay As You Go Minutes"
      };
    case TrialState.PickPlanMonth:
    case TrialState.PickPlanYear: {
      const interval =
        state === TrialState.PickPlanMonth
          ? PlanInterval.Monthly
          : PlanInterval.Yearly;
      return {
        interval,
        pathname: PLANS_PATH
      };
    }

    case "undefined":
      return {};

    // Is a redirect
    default:
      return {
        pathname: state
      };
  }
};

export const getBurnQualityForPlan = (plan: SublyPlan): BurnQuality => {
  if (PREMIUM_AND_HIGHER_LIST.includes(plan)) {
    return BurnQuality.High;
  }

  if (isProPlan(plan)) {
    return BurnQuality.Medium;
  }

  // Free
  return BurnQuality.Low;
};

export interface SublyPlanFeatures {
  title?: string;
  items: string[];
}

export interface SublyPlanLimits {
  minutes: number;
  minutesLabel: string;
  storage: number;
  storageLabel: string;
  upload: number;
  uploadLabel: string;
}

export interface SublyPlanData {
  plan: SublyPlan;
  name: string;
  price: number;
  icon: string;
  features: SublyPlanFeatures;
  limits: SublyPlanLimits;
}

export const getPlanData = (plan: SublyPlan): SublyPlanData => {
  return {
    plan: plan,
    name: getPlanNameLabel(plan),
    price: getPlanPrice(plan),
    icon: getPlanIcon(plan),
    features: getFeatures(plan),
    limits: getLimits(plan)
  };
};

/**
 * Given a plan, it will return an array pair with the [Yearly, Monthly] plans.
 *
 * Important note: it should return the yearly plan first
 */
export const getPlansForPlan = (plan: SublyPlan): [SublyPlan, SublyPlan] => {
  switch (plan) {
    case SublyPlan.Pro100:
    case SublyPlan.Pro100Yearly:
      return [SublyPlan.Pro100Yearly, SublyPlan.Pro100];

    case SublyPlan.Premium:
    case SublyPlan.PremiumYearly:
      return [SublyPlan.PremiumYearly, SublyPlan.Premium];

    case SublyPlan.Premium1000:
    case SublyPlan.Premium1000Yearly:
      return [SublyPlan.Premium1000Yearly, SublyPlan.Premium1000];

    case SublyPlan.Business:
    case SublyPlan.BusinessYearly:
      return [SublyPlan.BusinessYearly, SublyPlan.Business];

    default:
      return [SublyPlan.Pro100Yearly, SublyPlan.Pro100];
  }
};

export const getUpgradePlansList = (plan: SublyPlan): SublyPlan[] => {
  switch (plan) {
    case SublyPlan.Free:
      return [SublyPlan.Pro100Yearly, SublyPlan.PremiumYearly];
    case SublyPlan.Pro100:
    case SublyPlan.Pro500:
    case SublyPlan.Pro1000:
    case SublyPlan.Pro100Yearly:
    case SublyPlan.Pro500Yearly:
    case SublyPlan.Pro1000Yearly:
      return [SublyPlan.PremiumYearly, SublyPlan.BusinessYearly];
    case SublyPlan.Premium:
    case SublyPlan.PremiumYearly:
    case SublyPlan.PremiumEducation:
    case SublyPlan.Premium1000:
    case SublyPlan.Premium1000Yearly:
    default:
      return [SublyPlan.BusinessYearly];
  }
};

export const isMonthlyPlan = (plan: SublyPlan): boolean => {
  switch (plan) {
    case SublyPlan.Free:
    case SublyPlan.Pro100Yearly:
    case SublyPlan.Pro500Yearly:
    case SublyPlan.Pro1000Yearly:
    case SublyPlan.PremiumYearly:
    case SublyPlan.Premium1000Yearly:
    case SublyPlan.BusinessYearly:
    case SublyPlan.BusinessYearlyCustom:
    case SublyPlan.PremiumEducation:
    case SublyPlan.PAYG:
      return false;
    case SublyPlan.Pro100:
    case SublyPlan.Pro500:
    case SublyPlan.Pro1000:
    case SublyPlan.Premium:
    case SublyPlan.Premium1000:
    case SublyPlan.Business:
      return true;
  }
};

export const getPlanMediaShareLimits = (plan: SublyPlan): number => {
  switch (plan) {
    case SublyPlan.Free:
    case SublyPlan.Pro100:
    case SublyPlan.Pro500:
    case SublyPlan.Pro1000:
    case SublyPlan.Pro100Yearly:
    case SublyPlan.Pro500Yearly:
    case SublyPlan.Pro1000Yearly:
      return 0;
    case SublyPlan.Premium:
    case SublyPlan.PremiumYearly:
    case SublyPlan.PremiumEducation:
    case SublyPlan.Premium1000:
    case SublyPlan.Premium1000Yearly:
    case SublyPlan.PAYG:
      return 3;
    case SublyPlan.Business:
    case SublyPlan.BusinessYearly:
    case SublyPlan.BusinessYearlyCustom:
      return 10;
  }
};
