import React from "react";
import { AccountCard } from "../../../containers/settings/account-card/account-card";
import { DeleteAccountCard } from "../../../containers/settings/plans/delete-account-card/delete-account-card";
import { WorkspaceCard } from "../../../containers/settings/workspace-card";
import { SettingsLayout } from "../../../layouts/settings/settings.layout";
import { useAccounts } from "../../../hooks/use-accounts";
import { ChangePlansCard } from "../../../containers/settings/plan-card/change-plan-card";
import { PlansCard } from "../../../containers/settings/plan-card/plan-card";
import { StorageCard } from "../../../containers/settings/storage-card/storage-card";

export const AccountPage: React.FC = () => {
  const { isOwner } = useAccounts();

  return (
    <SettingsLayout>
      <div className="container-fluid my-2">
        <div className="row">
          <div className="col mt-3 mb-2">
            <h3 className="title text-black">Account</h3>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <AccountCard />
            <PlansCard />
            <ChangePlansCard />
            <StorageCard />
            <WorkspaceCard />
            {isOwner && <DeleteAccountCard />}
          </div>
        </div>
      </div>
    </SettingsLayout>
  );
};
