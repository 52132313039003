import React from "react";
import {
  Testimonial,
  TESTIMONIALS
} from "../components/community-spotlight/community-testimonials";
import { getRandomInt } from "../utils";

interface TestimonialContext {
  testimonial: Testimonial;
}

export const TestimonialContext = React.createContext<TestimonialContext>({
  testimonial: TESTIMONIALS[0]
});

export const TestimonialProvider: React.FC = ({ children }) => {
  const [testimonial] = React.useState(
    TESTIMONIALS[getRandomInt(TESTIMONIALS.length)]
  );

  return (
    <TestimonialContext.Provider value={{ testimonial }}>
      {children}
    </TestimonialContext.Provider>
  );
};
