export interface Survey {
  surveyId: string;
  surveyType: SurveyType;
  result: unknown;
}

export enum SurveyType {
  Chips = "Chips",
  Thumbs = "Thumbs",
  Slider = "Slider",
  MultiSelect = "MultiSelect",
  Text = "Text"
}

export enum SurveyQuestion {
  OtherFeatures = "What other features would you like to see in Subly?",
  AppIdea = "What apps would you want to see here?"
}

export enum ImprovementFeature {
  Transcription = "Transcription",
  Translation = "Translation",
  VideoControls = "Video controls",
  Timeline = "Timeline",
  Audiograms = "Audiograms",
  Collaboration = "Collaboration"
}
