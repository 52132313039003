import { Query } from "@datorama/akita";
import { Observable } from "rxjs";
import { Referral } from "../../interfaces/referral";
import { ReferralState, ReferralStore, referralStore } from "./referral.store";

export class ReferralQuery extends Query<ReferralState> {
  get isLoaded(): boolean {
    return this.getValue().loaded;
  }

  get referralLink(): string {
    return this.getValue().affiliate?.link;
  }

  get referrals(): Referral[] {
    return this.getValue().referrals;
  }

  constructor(protected store: ReferralStore) {
    super(store);
  }

  selectIsLoaded(): Observable<boolean> {
    return this.select("loaded");
  }

  selectReferralLink(): Observable<string | undefined> {
    return this.select((s) => s.affiliate?.link);
  }

  selectReferrals(): Observable<Referral[]> {
    return this.select((s) => s.referrals);
  }
}

export const referralQuery = new ReferralQuery(referralStore);
