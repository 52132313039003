import classNames from "classnames";
import { format } from "date-fns";
import React from "react";
import { Link } from "react-router-dom";
import { Badge } from "../../components/badge/badge";
import { Button } from "../../components/button/button";
import { Divider } from "../../components/divider/divider";
import {
  Dropdown,
  DropdownButton,
  DropdownMenu
} from "../../components/dropdown/dropdown";
import { LoadingIcon } from "../../components/loading-icon/loading-icon";
import { useAccounts } from "../../hooks/use-accounts";
import { usePlan } from "../../hooks/use-plan";
import { SublyPlan } from "../../interfaces/billing";
import { ACCOUNT_PATH, PLANS_PATH, PLANS_UPGRADE_PATH } from "../../routes";
import { accountQuery } from "../../state/account";
import {
  getPlanMinutes,
  isMonthlyPlan,
  parseCreditToText
} from "../../utils/plans";
import { formatBytes } from "../../utils/storage-size";
import styles from "./balance.module.scss";
interface BalanceProps {
  showUpgrade?: boolean;
  dropdownMenu: React.RefObject<HTMLLIElement>;
}
export const Balance: React.FC<BalanceProps> = ({
  showUpgrade = false,
  dropdownMenu
}) => {
  const {
    isProOrHigher,
    credit,
    isFree,
    isPayg,
    availableStorage,
    plan,
    isTrial
  } = usePlan();
  const isMonthly = isMonthlyPlan(plan);

  const { subscription, deal } = useAccounts();
  const nextInvoiceDatePhrase = subscription?.nextInvoiceDate
    ? format(new Date(subscription?.nextInvoiceDate), "MMM dd, yyyy")
    : "";
  if (!credit || credit.loading) {
    return (
      <span>
        Balance: <LoadingIcon className="ml-1" />
      </span>
    );
  }

  const totalCredit = credit?.total ?? 0;
  const paygCredit = credit?.payg ?? 0;

  const paygCreditText = parseCreditToText(paygCredit, false, false);

  const balanceText =
    isFree && !isPayg ? "0 mins" : parseCreditToText(totalCredit, false, false);

  const planMinutes = getPlanMinutes(plan);

  const showUpgradeButton = showUpgrade && !isProOrHigher;

  const availableStorageBytes = formatBytes(availableStorage);
  const availableStorageText = `${availableStorageBytes.size} ${availableStorageBytes.units}`;

  const totalStorage = formatBytes(accountQuery.totalStorage);
  const totalStorageText = `${totalStorage.size} ${totalStorage.units}`;

  const renderUpgrade = showUpgradeButton && (
    <span>
      <Link to={PLANS_PATH}>
        <Badge className="text-white">Upgrade</Badge>
      </Link>
    </span>
  );

  if (isTrial || deal || isFree) {
    return (
      <>
        {renderUpgrade}
        <span className="ml-1"> Balance: </span>
        <span>{balanceText}</span>
      </>
    );
  }

  return (
    <div className={classNames("dropdown", styles["balance-container"])}>
      {renderUpgrade}

      <Dropdown>
        <DropdownButton
          dropdownEl={dropdownMenu}
          mixpanelEvent="Click Balance Button"
        >
          <Button secondary>
            <span> Balance: {balanceText}</span>
          </Button>
        </DropdownButton>

        <DropdownMenu
          isRight
          title="Balance"
          className={styles["balance-menu"]}
        >
          <div className={styles["info-box"]}>
            <h6>Minutes balance</h6>

            <span className={styles["balance"]}>
              Balance:{" "}
              <span className={styles["data"]}>
                {balanceText} / {planMinutes} mins
              </span>
            </span>

            <Divider className={styles["divider"]} />

            <div>
              <span className={styles["balance"]}>
                Topped up minutes:{" "}
                <span className={styles["data"]}>{paygCreditText}</span>
              </span>

              <p className={styles["topped-up"]}>
                Current balance includes topped up minutes. Topped up minutes
                will roll over to the next month if unused.
              </p>
            </div>

            <Button
              className={styles["upgrade-button"]}
              secondary
              to={{ pathname: PLANS_UPGRADE_PATH }}
              state={{
                plan: SublyPlan.PAYG,
                quantity: 20,
                topup: true
              }}
            >
              Top up minutes
            </Button>
          </div>

          <div
            className={classNames(
              styles["info-box"],
              styles["info-box--storage"]
            )}
          >
            <h6>Storage usage</h6>

            <div className="my-2">
              <span className={styles["balance"]}>
                Storage available:{" "}
                <span className={styles["data"]}>
                  {availableStorageText} / {totalStorageText}
                </span>
              </span>
            </div>

            <Button
              className={styles["upgrade-button"]}
              secondary
              to={{ pathname: ACCOUNT_PATH }}
            >
              Increase storage
            </Button>
          </div>
          <p className={styles["monthly"]}>
            Your {isMonthly ? "monthly" : "yearly"} minutes resets{" "}
            {nextInvoiceDatePhrase}
          </p>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};
