import { mdiArrowLeft } from "@mdi/js";
import Icon from "@mdi/react";
import { motion, Variants } from "framer-motion";
import React from "react";
import { useAccounts } from "../../hooks/use-accounts";
import { useUpload } from "../../hooks/use-upload";
import { STORAGE_AMOUNT } from "../../utils/plans";
import { formatBytes } from "../../utils/storage-size";
import { Button } from "../button/button";
import { StorageCheckoutCard } from "../extra-storage/storage-checkout-card";
import { UploadStorageAmount } from "../extra-storage/storage-toggle";

const STORAGE_AMOUNT_VARIANTS: Variants = {
  open: {
    opacity: 0,
    x: "-10%",
    display: "none"
  },
  closed: {
    opacity: 1,
    x: 0
  }
};

const STORAGE_PURCHASE_VARIANTS: Variants = {
  open: {
    opacity: 1,
    x: 0,
    display: "flex",
    flexDirection: "column"
  },
  closed: {
    opacity: 0,
    x: "-10%",
    display: "none"
  }
};

export const UpgradeStorage: React.FC = () => {
  const { subscription, billing } = useAccounts();
  const { totalSize } = useUpload();

  if (!subscription || !billing) {
    return null;
  }

  const [showOptions, toggleOptions] = React.useState(false);
  const [storageAmount, setStorageAmount] = React.useState<number>(0);

  const totalStorage = STORAGE_AMOUNT * storageAmount;

  const fileSize = formatBytes(totalSize ?? 0);
  const fileSizeText = `${fileSize.size} ${fileSize.units}`;

  return (
    <div style={{ width: "320px", height: "100%" }}>
      <motion.div
        animate={showOptions ? "open" : "closed"}
        variants={STORAGE_AMOUNT_VARIANTS}
      >
        <UploadStorageAmount
          amount={storageAmount}
          setAmount={setStorageAmount}
          totalAmount={totalStorage}
          toggleNext={toggleOptions}
          exceededLimit={fileSizeText}
        />
      </motion.div>

      <motion.div
        animate={showOptions ? "open" : "closed"}
        variants={STORAGE_PURCHASE_VARIANTS}
      >
        <Button
          secondary
          className="mb-2 text-left mr-auto"
          onClick={() => toggleOptions(false)}
        >
          <Icon
            path={mdiArrowLeft}
            style={{ width: "1.5em", height: "1.4em" }}
            className="mr-1"
          />
          Edit storage
        </Button>
        <StorageCheckoutCard
          subscription={subscription}
          quantity={storageAmount}
          isModal={false}
        />
      </motion.div>
    </div>
  );
};
