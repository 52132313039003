import classNames from "classnames";
import React from "react";
import { ModalType } from "../../interfaces/modal-type";
import { useModal } from "../../hooks/use-modal";
import { usePermissions } from "../../hooks/use-permissions";
import { SublyPlan } from "../../interfaces/billing";
import { PlanUpgradeModal } from "../upgrade-modal/plan-upgrade-modal";
import { Badge } from "./badge";
import styles from "./badge.module.scss";

interface BusinessBadgeProps {
  className?: string;
  inverted?: boolean;
}
export const BusinessBadge: React.FC<BusinessBadgeProps> = ({
  className,
  inverted = false
}) => {
  const [showModal, hideAll] = useModal(ModalType.UpgradePlan);
  const { hasPermission } = usePermissions(SublyPlan.Business);

  if (hasPermission) {
    return null;
  }

  const handleClick = () => {
    showModal(
      <PlanUpgradeModal upgradePlan={SublyPlan.Business} closeModal={hideAll} />
    );
  };

  return (
    <Badge
      className={classNames(styles["badge--link"], className)}
      inverted={inverted}
      onClick={() => handleClick()}
    >
      BUSINESS
    </Badge>
  );
};
