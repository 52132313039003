import { DEFAULT_CONFIG, languageLookup } from "@getsubly/common";
import settings from "../config/settings/settings";
import {
  BasicMedia,
  BasicMediaExtras,
  BurningTask,
  Language,
  Media,
  MediaFile,
  MediaFileType,
  MediaJob,
  MediaJobType,
  MediaLanguage,
  MediaStatus,
  SubtitleFormat
} from "../interfaces/media";
import { notEmpty } from "./arrays";

export const transformToBasicMediaList = (
  mediaList: Media[],
  extra?: BasicMediaExtras
): BasicMedia[] => mediaList.map((m) => transformToBasicMedia(m, extra));

export const transformToBasicMedia = (
  media: Media,
  extras?: BasicMediaExtras
): BasicMedia => {
  return {
    ...media,
    id: media.mediaId,
    name: media.name,
    videoUrl: getMediaUrl(media),
    mainFile: getMediaFile(media),
    posterUrl: getThumbnailUrl(media),
    type: getMediaType(media),
    status: getTranscribeStatus(media),
    latestJob: getMediaLatestJob(media),
    isBurning: getIsBurning(media),
    isTranscribing: getIsTranscribing(media),
    assConfig: { ...DEFAULT_CONFIG, ...media.assConfig },
    language: getMediaLanguage(media),
    duration: getMediaDuration(media),
    fileSize: getFileSize(media),
    transcriptions: {
      originalId: getOriginalTranscriptionId(media),
      original: getOriginalTranscription(media),
      translationsIds: getTranslationIds(media),
      translations: getTranslations(media)
    },
    sharedWithMe: extras?.sharedWithMe,
    plan: extras?.plan
  };
};

export const getBurntFileFromJob = (
  media: Media,
  jobId: string
): MediaFile | undefined => {
  if (!media || !media.jobs || !media.files) {
    return;
  }

  const burnJob = media.jobs.find((j) => j.id === jobId);

  if (!burnJob) {
    return;
  }

  const burnJobOutputs = burnJob.outputs ?? [];

  return media.files.find((f) => burnJobOutputs.includes(f.id) && f.burnt);
};

export const getFileFromJob = (
  media: Media,
  jobId: string
): MediaFile | undefined => {
  if (!media || !media.jobs || !media.files) {
    return;
  }

  const burnJob = media.jobs.find((j) => j.id === jobId);

  if (!burnJob) {
    return;
  }

  const burnJobOutputs = burnJob.outputs ?? [];

  return media.files.find((f) => burnJobOutputs.includes(f.id));
};

export const getOriginalFile = (media: Media): MediaFile | undefined => {
  if (!media || !media.files) {
    return;
  }

  if (media.originalFileId) {
    return media.files.find((f) => f.id === media.originalFileId);
  }

  const files = media.files
    .filter((f) => f.type === MediaFileType.Video)
    .sort((a, b) => +new Date(a.uploadedDate) - +new Date(b.uploadedDate));

  return files[0];
};

export const getMediaUrl = (media: Media): string => {
  const mediaFile = getMediaFile(media);

  if (!mediaFile) {
    return "";
  }

  return mediaFile.publicUrl ?? cleanURI(mediaFile.url, media.mediaId);
};

export const getTranscribeStatus = (media: Media): MediaStatus => {
  if (media.status === MediaStatus.Failed) {
    return MediaStatus.Failed;
  }

  const pendingUploadJob = media.jobs.find(
    (j) => j.type === MediaJobType.Upload && j.status === MediaStatus.Pending
  );
  if (pendingUploadJob) {
    return MediaStatus.Uploading;
  }

  const pendingConvertJob = media.jobs.find(
    (j) =>
      j.type === MediaJobType.Conversion && j.status === MediaStatus.Pending
  );
  if (pendingConvertJob) {
    return MediaStatus.Converting;
  }

  const subtitleFile = media.files.find(
    (f) => f.type === MediaFileType.Subtitle && !f.translated
  );

  if (subtitleFile) {
    return MediaStatus.Ready;
  }

  const transcribeJobs = media.jobs
    .filter((j) => j.type === MediaJobType.Transcribe)
    .sort((a, b) => +new Date(b.startDate) - +new Date(a.startDate));

  return transcribeJobs[0]?.status ?? MediaStatus.Pending;
};

export const hasDownload = (media: Media): boolean => {
  const latestBurnJob = getLatestBurnJob(media);

  if (!latestBurnJob) {
    return false;
  }

  return latestBurnJob.status === MediaStatus.Complete;
};

const getLatestBurnJob = (media: Media): MediaJob | undefined => {
  if (!media || !media.jobs) {
    return;
  }

  const burnJobs = media.jobs
    .filter((j) => j.type === MediaJobType.Burn)
    .sort((a, b) => +new Date(b.startDate) - +new Date(a.startDate));

  return burnJobs[0];
};

export const getMediaLatestJob = (media: Media): MediaJob | undefined => {
  if (!media || !media.jobs) {
    return;
  }

  return media.jobs.reduce((a, b) =>
    +new Date(b.startDate) > +new Date(a.startDate) ? b : a
  );
};

const getIsBurning = (media: Media): boolean => {
  const lastBurningJob = getLatestBurnJob(media);

  if (!lastBurningJob) {
    return false;
  }

  return lastBurningJob.status === MediaStatus.Pending;
};

const getThumbnailUrl = (media: Media) => {
  if (!media || !media.files) {
    return "";
  }

  const thumbnail = media.files.find((f) => f.id === media.thumbnail);

  if (!thumbnail) {
    return "";
  }

  return thumbnail.publicUrl ?? cleanURI(thumbnail.url, media.mediaId);
};

const cleanURI = (uri: string, separator: string) => {
  const idSeparator = `${separator}/`;
  const splitURI = uri.split(idSeparator);

  const arrayURI = [splitURI[0], idSeparator, encodeURIComponent(splitURI[1])];

  return arrayURI.join("");
};

export const canShowMov = (userAgent: string): boolean =>
  /(ipod|iphone|ipad|macintosh)/i.test(userAgent);

const getMediaFile = (media: Media): MediaFile | undefined => {
  if (!media || !media.files) {
    return;
  }

  // Use converted file (current)
  return media.files.find((f) => f.id === media.current);
};

export const hasMovFile = (media?: BasicMedia): boolean => {
  if (!media || !media.files) {
    return false;
  }

  // Use converted file (current)
  return media.files.some(
    (f) => f.type === MediaFileType.Video && f.extension === "mov"
  );
};

const getMediaType = (media: Media): MediaFileType => {
  const mainFile = getMediaFile(media);

  if (!mainFile) {
    return MediaFileType.Video;
  }

  return mainFile.type;
};

export const getBurningTaskLabel = (burningTasks?: BurningTask[]): string => {
  if (!burningTasks) {
    return "Preparing media file";
  }

  const burningTask = burningTasks[2];
  const burningTaskProgress = burningTask?.progress;

  if (burningTaskProgress === 100) {
    return "Cleaning up...";
  }

  return "Adding the subtitles to your video";
};

const getMediaLanguage = (media: Media): string | undefined => {
  const mediaFile = getMediaFile(media);

  return mediaFile?.language;
};

export const getMediaDuration = (media: Media): number | undefined => {
  const mediaFile = getMediaFile(media);

  return mediaFile?.durationSeconds;
};

export const getFileSize = (media: Media): number | undefined => {
  const mediaFile = getMediaFile(media);

  return mediaFile?.sizeBytes;
};

const getOriginalTranscription = (media: Media): MediaLanguage | undefined => {
  const original = media.files.find(
    (f) => f.type === MediaFileType.Subtitle && !Boolean(f.translated)
  );

  if (!original) {
    return;
  }

  const language = mapTranscriptionToMediaLanguage(
    original,
    settings.transcription.languages
  );

  if (!language) {
    return;
  }

  return language;
};

const getTranslations = (media: Media): MediaLanguage[] => {
  return media.files
    .filter((f) => Boolean(f.translated) && Boolean(f.language))
    .map((f) =>
      mapTranscriptionToMediaLanguage(f, settings.translation.languages)
    )
    .filter(notEmpty);
};

const mapTranscriptionToMediaLanguage = (
  f: MediaFile,
  lookUp: Language[]
): MediaLanguage | null => {
  return getMediaLanguageProperties(f.id, lookUp, f.language);
};

export const getMediaLanguageProperties = (
  fileId: string,
  languages: Language[],
  // Some old files don't have a language so we default it to "en-GB"
  fileLanguage = "en-GB"
): MediaLanguage | null => {
  const language = languageLookup(languages, fileLanguage);

  if (!language?.language || !language?.code) {
    return null;
  }

  return { ...language, id: fileId };
};

const getOriginalTranscriptionId = (media: Media): string => {
  return getOriginalTranscription(media)?.id ?? "";
};

const getTranslationIds = (media: Media): string[] => {
  return getTranslations(media).map((t) => t.id);
};

export const getMediaAllLanguages = (media?: BasicMedia): string[] => {
  if (!media) {
    return [];
  }

  const mediaLanguage = media.language ?? "";
  const mediaTranslations = media.transcriptions.translations.map(
    (t) => t.code
  );
  return [mediaLanguage, ...mediaTranslations];
};

export const getLastProcessedFiles = (media: Media): MediaFile | undefined => {
  const job = getMediaLatestJob(media);
  if (!job) {
    return;
  }

  return getBurntFileFromJob(media, job.id);
};

interface GenerateFilenameParams {
  name: string;
  code?: string;
  ratio?: string;
  format?: string;
  noSubtitles?: string;
}
export const generateFilename = ({
  name,
  code,
  ratio,
  format,
  noSubtitles
}: GenerateFilenameParams): string => {
  if (format === "Original") {
    format = undefined;
  }

  const language = code && `(${code})`;

  return [name, language, ratio, format, noSubtitles]
    .filter(notEmpty)
    .join(" ");
};

export const getSubtitleFormatLabel = (format: SubtitleFormat): string => {
  switch (format) {
    case "srt":
      return ".srt (SubRip subtitles)";
    case "vtt":
      return ".vtt (WebVTT subtitles)";
    case "txt":
      return ".txt (Plain text)";
  }
};

export const isSubtitleFileType = (type: MediaFileType): boolean => {
  return [MediaFileType.SRT, MediaFileType.VTT, MediaFileType.TXT].includes(
    type
  );
};

export const isJobFailed = (media: BasicMedia, jobId: string): boolean => {
  const job = media.jobs?.find((j) => j.id === jobId);

  if (!job) {
    return false;
  }

  return [MediaStatus.Failed].includes(job.status);
};

const getIsTranscribing = (media: Media): boolean => {
  const job = getMediaLatestJob(media);

  if (!job) {
    return false;
  }

  return (
    job.type === MediaJobType.Transcribe && job.status === MediaStatus.Pending
  );
};
