import { Store, StoreConfig } from "@datorama/akita";

export interface PresenceUser {
  username: string;
  name?: string;
  photo?: string;
  mediaId?: string;
  joinedAt?: Date;
}

export interface UserPresenceState {
  hasLoaded: boolean;
  isLoading: boolean;
  meId: string;
  users: PresenceUser[];
}

export const createInitialState = (): UserPresenceState => ({
  hasLoaded: false,
  isLoading: false,
  meId: "",
  users: []
});

@StoreConfig({ name: "user-presence", resettable: true })
export class UserPresenceStore extends Store<UserPresenceState> {
  constructor() {
    super(createInitialState());
  }

  resetUpload = (): void => {
    this.update(createInitialState());
  };

  updateUser = (username: string, data: Partial<PresenceUser>): void => {
    this.update((s) => {
      const updatedUser: PresenceUser = {
        username,
        ...s.users.find((u) => u.username === username),
        ...data
      };

      return {
        ...s,
        users: [...s.users.filter((u) => u.username !== username), updatedUser]
      };
    });
  };

  removeUser = (username: string): void => {
    this.update((state) => ({
      users: [...state.users.filter((u) => u.username !== username)]
    }));
  };
}

export const userPresenceStore = new UserPresenceStore();
