import { mdiLoading } from "@mdi/js";
import Icon from "@mdi/react";
import classNames from "classnames";
import React from "react";
import { Link, LinkProps } from "react-router-dom";
import { ToolTip } from "../../tooltip/tooltip";
import styles from "./sidebar.module.scss";

export const SidebarItemDivider: React.FC = () => {
  return <div className={styles.divider}></div>;
};

type SidebarItemProps = {
  isActive?: boolean;
  onClick?: () => void;
  addFolder?: () => void;
  to?: LinkProps["to"] | string;
  loading?: boolean;
  state?: unknown;
  blank?: boolean;
} & (
  | {
      blank: true;
      to: string;
    }
  | {
      blank?: false;
      to?: LinkProps["to"] | string;
    }
);
export const SidebarItem: React.FC<SidebarItemProps> = ({
  isActive,
  addFolder,
  onClick,
  loading,
  to,
  blank,
  state,
  children
}) => {
  const hasAddFolder = Boolean(addFolder);

  if (to) {
    if (to && blank) {
      return (
        <a
          className={classNames(styles["sidebar-item"], {
            [styles["sidebar-item--active"]]: isActive
          })}
          href={to as string}
          target="_blank"
          rel="noopener noreferrer"
        >
          {children}
          {hasAddFolder && (
            <SidebarItemAddFolderButton
              addFolder={addFolder}
              loading={loading}
            />
          )}
        </a>
      );
    }

    return (
      <Link
        className={classNames(styles["sidebar-item"], {
          [styles["sidebar-item--active"]]: isActive
        })}
        to={to}
        state={state}
      >
        {children}
        {hasAddFolder && (
          <SidebarItemAddFolderButton addFolder={addFolder} loading={loading} />
        )}
      </Link>
    );
  }

  return (
    <div
      className={classNames(styles["sidebar-item"], {
        [styles["sidebar-item--active"]]: isActive
      })}
      onClick={onClick}
    >
      {children}
      {hasAddFolder && (
        <SidebarItemAddFolderButton addFolder={addFolder} loading={loading} />
      )}
    </div>
  );
};

type SidebarItemAddFolderButtonProps = Pick<
  SidebarItemProps,
  "addFolder" | "loading"
>;
const SidebarItemAddFolderButton: React.FC<SidebarItemAddFolderButtonProps> = ({
  addFolder,
  loading
}) => {
  if (loading) {
    return (
      <div className={styles.add} onClick={addFolder}>
        <Icon path={mdiLoading} spin size="14px" className="p-1" />
      </div>
    );
  }

  return (
    <ToolTip text="Add folder">
      <div className={styles.add} onClick={addFolder}>
        <span>+</span>
      </div>
    </ToolTip>
  );
};

interface SidebarItemLabelProps {
  label: string;
  icon?: string;
}
export const SidebarItemLabel: React.FC<SidebarItemLabelProps> = ({
  label,
  icon
}) => {
  if (!icon) {
    return <h6>{label}</h6>;
  }

  return (
    <h6 className="d-flex align-items-center">
      <Icon path={icon} size="14px" /> {label}
    </h6>
  );
};
