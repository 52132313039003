import { Store, StoreConfig } from "@datorama/akita";
import { Affiliate, Referral } from "../../interfaces/referral";

export interface ReferralState {
  affiliate: Affiliate;
  referrals: Referral[];
  visitors: number;
  loading: boolean;
  loaded: boolean;
}

@StoreConfig({ name: "referral" })
export class ReferralStore extends Store<ReferralState> {
  constructor() {
    super({
      affiliate: undefined,
      referrals: [],
      visitors: 0,
      loaded: false,
      loading: true
    });
  }
}

export const referralStore = new ReferralStore();
