import React from "react";
import classNames from "classnames";
import styles from "./toolbar-separator.module.scss";

export interface ToolbarSeparatorProps {
  className?: string;
}
export const ToolbarSeparator: React.FC<ToolbarSeparatorProps> = ({
  className
}) => <div className={classNames(styles["toolbar-separator"], className)} />;
