import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import axios from "axios";
import classNames from "classnames";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { getAccessToken } from "../../../api/auth.service";
import { getCustomer, getIntent } from "../../../api/billing.service";
import { Button } from "../../../components/button/button";
import config from "../../../config";
import { getAccountId } from "../../../config/settings/settings.service";
import { useAnalyticsWithAuth } from "../../../hooks/use-analytics-with-auth";
import { BILLING_PATH } from "../../../routes";
import { getErrorMessageForDisplay } from "../../../utils/errors";
import "./add-payment-method-form.scss";

interface PaymentMethodForm {
  cardholderName: string;
}

export const AddPaymentMethodForm: React.FC = () => {
  const [focus, setFocus] = useState(false);
  const [complete, setComplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();

  const { trackEventWithAuth } = useAnalyticsWithAuth();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<PaymentMethodForm>({
    mode: "onChange"
  });

  const stripe = useStripe();
  const elements = useElements();

  const payInvoices = async () => {
    const baseURL = `${config.apiUrl}/api/v1`;

    try {
      await axios.post(
        `/billing/${getAccountId()}/invoices/pay-overdue`,
        null,
        {
          baseURL: baseURL,
          headers: { "x-access-token": await getAccessToken() }
        }
      );
    } catch (error) {
      const errorMessage = getErrorMessageForDisplay(error);

      throw new Error(errorMessage);
    }
  };

  const onSubmit = async (data: PaymentMethodForm) => {
    if (!stripe) {
      setError(
        "There is an issue please send an email to support@getsubly.com."
      );
      return;
    }

    if (!elements) {
      setError("There is an issue please contact support@getsubly.com");
      return;
    }

    const canAddCard = isValid && complete;

    if (!canAddCard) {
      setError("Some information is missing.");
      return;
    }

    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      setError("There is an issue, please contact support@getsubly.com.");
      return;
    }

    setError("");
    setLoading(true);

    try {
      const {
        data: { intent }
      } = await getIntent();

      const { error, setupIntent } = await stripe.confirmCardSetup(intent, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: data.cardholderName
          }
        }
      });

      if (error) {
        trackEventWithAuth("Setup payment intent fail", {
          error: error.code
        });
        setError(error.message);
        return;
      }

      if (!setupIntent || !setupIntent.payment_method) {
        setError("There was an unknown error");
        return;
      }

      //we have set up the card correctly. Now we pay off any unpaid invoices
      try {
        await payInvoices();
      } catch (error) {
        setError(error.message);
        return;
      }

      await getCustomer({ force: true });

      navigate(BILLING_PATH);
    } catch (error) {
      setError("There was an error. Please try again or contact support.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <form
      className="edit-account-form mt-sm-5"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="form-group">
        <label className="mb-1">Credit / debit card number</label>
        <CardElement
          className={classNames("form-control d-flex credit-card-input mb-0", {
            focused: focus
          })}
          options={{ hidePostalCode: true }}
          onBlur={() => setFocus(false)}
          onFocus={() => setFocus(true)}
          onChange={(e) => setComplete(e.complete)}
        />
      </div>
      <div className="form-group">
        <div className="form-row">
          <div className="col">
            <label>Cardholder name</label>
            <input
              type="text"
              className={classNames("form-control", {
                "is-invalid": errors.cardholderName
              })}
              {...register("cardholderName", { required: true })}
            />
          </div>
        </div>
      </div>
      <div className="form-group d-flex justify-content-end">
        <Button secondary to={BILLING_PATH} className="mr-2">
          Cancel
        </Button>
        <Button type="submit" primary className="mr-0" loading={loading}>
          Save changes
        </Button>
      </div>
      <div className="form-group d-flex justify-content-end">
        {error && <span className="has-error">{error}</span>}
      </div>
    </form>
  );
};
