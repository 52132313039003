import { mdiAccountMultiple } from "@mdi/js";
import Icon from "@mdi/react";
import React from "react";
import Skeleton from "react-loading-skeleton";
import { Badge } from "../../../components/badge/badge";
import { Button } from "../../../components/button/button";
import { ModalType } from "../../../interfaces/modal-type";
import { useAccounts } from "../../../hooks/use-accounts";
import { useModal } from "../../../hooks/use-modal";
import { usePlan } from "../../../hooks/use-plan";
import { pluralize } from "../../../utils/strings";
import { WorkspaceInviteModal } from "../../dashboard/workspace-dropdown/workspace-invite-modal";
import { MembersTable } from "./members-table";
import { PLANS_PATH } from "../../../routes";
import { Link } from "react-router-dom";
import { usePermissions } from "../../../hooks/use-permissions";
import { SublyPlan } from "../../../interfaces/billing";

export const ManageMembers: React.FC = () => {
  const [showModal, hideModal] = useModal(ModalType.InvitationModal);
  const { currentAccount, isLoading } = useAccounts();
  const { hasPermission } = usePermissions(SublyPlan.Premium);

  if (!currentAccount) {
    return null;
  }

  const membersCount = currentAccount.members?.length ?? 0;
  const invitationsCount = currentAccount.invitations?.length ?? 0;
  const total = membersCount + invitationsCount;

  const canAddMembers = hasPermission;

  const teamCapacity = currentAccount?.teamCapacity ?? 1;
  const reachedMax = canAddMembers && total >= teamCapacity;

  const countTitle = `${total} ${pluralize(total, "Teammate")}`;
  const title = (
    <>
      {countTitle}{" "}
      <span className="font-size-inherit text-text-disabled">
        in {currentAccount.accountName}
      </span>{" "}
      {!canAddMembers && <Badge>Premium</Badge>}
    </>
  );

  return (
    <div className="container-fluid my-2">
      <div className="row">
        <div className="col mt-3 mb-2 d-flex flex-column flex-sm-row align-items-center justify-content-between">
          <h3 className="title text-black mb-0 text-center text-sm-left">
            {isLoading ? <Skeleton width="400px" /> : title}
          </h3>
          {!isLoading && (
            <Button
              primary
              disabled={!canAddMembers || reachedMax}
              onClick={() =>
                showModal(<WorkspaceInviteModal onDismiss={hideModal} />)
              }
              className="mt-2 mt-sm-0"
            >
              <Icon path={mdiAccountMultiple} size="1.2rem" className="mr-2" />
              Invite new teammates
            </Button>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col">
          <MembersTable
            members={currentAccount.members}
            invitations={currentAccount.invitations}
            isLoading={isLoading}
          />
        </div>
      </div>
      {!isLoading && <MaxMembersWarning total={total} />}
    </div>
  );
};

interface MaxMembersWarningProps {
  total: number;
}
const MaxMembersWarning: React.FC<MaxMembersWarningProps> = ({ total }) => {
  const { isPremiumOrHigher, isPremium } = usePlan();
  const { currentAccount, isOwner, isAdmin } = useAccounts();

  const canAddMembers = isOwner || isAdmin;

  if (!canAddMembers) {
    return null;
  }

  if (!isPremiumOrHigher) {
    return null;
  }

  const teamCapacity = currentAccount?.teamCapacity ?? 1;
  const hasCapacity = total < teamCapacity;

  const businessText = hasCapacity
    ? `${teamCapacity} teammates maximum on your plan.`
    : "You have reached the maximum teammates for your plan.";

  if (isPremium) {
    return (
      <div className="row">
        <div className="col">
          <p className="text-center small mt-4 text-text-secondary">
            {businessText}
            <br />
            <Link to={PLANS_PATH} className="text-text-primary underline">
              Upgrade
            </Link>{" "}
            to Subly Business to add more teammates.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="row">
      <div className="col">
        <p className="text-center small mt-4 text-text-secondary">
          {businessText}
          <br />
          Contact your account manager to add more teammates.
        </p>
      </div>
    </div>
  );
};
