import classNames from "classnames";
import React, { useState } from "react";
import { Button } from "../../../components/button/button";
import { Checkbox } from "../../../components/checkbox/checkbox";
import { BulkUrlUploader } from "./bulk-url-uploader";
import { SrtUploader } from "./srt-uploader";
import styles from "./upload-modal.module.scss";

interface AdvancedOptionsProps {
  disableSrtUpload?: boolean;
  disableBulkUrl?: boolean;
  skipTranscription?: boolean;
  setSkipTranscription: (skip: boolean) => void;
}
export const AdvancedOptions: React.FC<AdvancedOptionsProps> = ({
  disableSrtUpload,
  disableBulkUrl,
  skipTranscription,
  setSkipTranscription
}) => {
  const [showOptions, setShowOptions] = useState<boolean>(false);

  if (!showOptions) {
    return (
      <div className="d-flex flex-row align-self-center">
        <Button
          className={classNames(styles["option-btn"])}
          onClick={() => setShowOptions(true)}
        >
          Advanced options +
        </Button>
      </div>
    );
  }

  return (
    <>
      <div className={classNames("row", styles["row"])}>
        <div className="col-sm-auto col-md-6">
          {!disableSrtUpload && <SrtUploader className="mt-4 mb-3" />}
        </div>
        <div className="col-sm-auto">
          {!disableBulkUrl && <BulkUrlUploader className="mt-4 mb-3" />}
        </div>
      </div>

      <div className={classNames("row", styles["row"])}>
        <div className="col-sm-auto col-md-6">
          <div className={styles["check-box"]}>
            <Checkbox
              onClick={(e) => e.stopPropagation()}
              onChange={() => {
                setSkipTranscription(!skipTranscription);
              }}
              value={skipTranscription}
            >
              Upload files without transcribing
            </Checkbox>
            <span className="small"> You will not be charged any minutes</span>
          </div>
        </div>
      </div>

      <div className="d-flex flex-row align-self-center">
        <Button
          className={classNames(styles["option-btn"])}
          onClick={() => setShowOptions(false)}
        >
          Show less options -
        </Button>
      </div>
    </>
  );
};
