import classNames from "classnames";
import React from "react";
import { ProgressBar } from "../progress-bar/progress-bar";
import { ThumbnailImage } from "../thumbnail-image/thumbnail-image";
import styles from "./media-card.module.scss";

interface UploadMediaCardProps {
  progress: number;
  isUploading?: boolean;
  uploaded?: boolean;
  thumbnail?: string;
  mediaName: string;
  isIndeterminate?: boolean;
}

export const UploadMediaCard: React.FC<UploadMediaCardProps> = ({
  progress,
  isUploading,
  uploaded,
  thumbnail,
  mediaName,
  isIndeterminate
}) => {
  if (uploaded) {
    return (
      <div className={styles["media-card"]}>
        <figure className={classNames(styles["figure"], "m-0")}>
          <ThumbnailImage src={thumbnail} alt={mediaName} />
          <div
            className={classNames(
              styles["toolbar"],
              styles["toolbar--progress"]
            )}
          >
            <p className="small text-black text-center mb-1">Uploaded</p>
          </div>
        </figure>
        <div className={styles["media-card-bottom"]}>
          <h6 className={classNames(styles["name"], "m-0 text-truncate")}>
            {mediaName}
          </h6>
          <div className={styles.info}>Uploaded</div>
        </div>
      </div>
    );
  }

  if (isUploading) {
    return (
      <div className={styles["media-card"]}>
        <figure className={classNames(styles["figure"], "m-0")}>
          <ThumbnailImage src={thumbnail} alt={mediaName} />
          <div
            className={classNames(
              styles["toolbar"],
              styles["toolbar--progress"]
            )}
          >
            <p className="small text-black text-center mb-1">Uploading</p>
            <>
              <h6 className="text-center mb-2">
                {isIndeterminate ? "" : `${progress} %`}
              </h6>
              <ProgressBar progress={progress} loading={isIndeterminate} />
            </>
          </div>
        </figure>
        <div className={styles["media-card-bottom"]}>
          <h6 className={classNames(styles["name"], "m-0 text-truncate")}>
            {mediaName}
          </h6>
          <div className={styles.info}>Uploading...</div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles["media-card"]}>
      <figure className={classNames(styles["figure"], "m-0")}>
        <ThumbnailImage src={thumbnail} alt={mediaName} />
        <div
          className={classNames(styles["toolbar"], styles["toolbar--progress"])}
        >
          <p className="small text-black text-center mb-1">Queued</p>
        </div>
      </figure>
      <div className={styles["media-card-bottom"]}>
        <h6 className={classNames(styles["name"], "m-0 text-truncate")}>
          {mediaName}
        </h6>
        <div className={styles.info}>Queued</div>
      </div>
    </div>
  );
};
