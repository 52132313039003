import axios from "axios";
import config from "../config";
import { getAccountId } from "../config/settings/settings.service";
import { RoleName } from "../interfaces/account";
import { accountQuery, accountStore } from "../state/account";
import { getAccessToken } from "./auth.service";
import { handleError } from "./handle-error";

const baseURL = `${config.apiUrl}/api/v1`;

export const updateMemberRole = async (
  id: string,
  role: RoleName
): Promise<void> => {
  const accountId = getAccountId();

  if (!accountId) {
    return;
  }

  try {
    await axios.put(
      `/${accountId}/members/${id}`,
      { role },
      {
        baseURL: baseURL,
        headers: { "x-access-token": await getAccessToken() }
      }
    );

    const members = accountQuery.getValue().members?.map((m) => {
      if (m.id === id) {
        return { ...m, role };
      }

      return m;
    });

    accountStore.update({ members });
  } catch (error) {
    handleError(error);
  }
};

export const deleteMember = async (id: string): Promise<void> => {
  const accountId = getAccountId();

  if (!accountId) {
    return;
  }

  try {
    await axios.delete(`/${accountId}/members/${id}`, {
      baseURL: baseURL,
      headers: { "x-access-token": await getAccessToken() }
    });

    const members = accountQuery.getValue().members?.filter((m) => m.id !== id);

    accountStore.update({ members });
  } catch (error) {
    handleError(error);
  }
};
