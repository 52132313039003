import { getVAT } from "./vat-rates";

interface CalculateParams {
  country: string;
  amount: number;
  percentOff?: number;
  credit?: number;
}

interface Totals {
  tax: {
    rate: number;
    visible: boolean;
  };
  amounts: {
    original: number;
    discount: number;
    afterDiscount: number;
    afterCredit: number;
    taxed: number;
    total: number;
  };
}

export const calculateTotals = ({
  amount,
  country,
  percentOff = 0,
  credit = 0
}: CalculateParams): Totals => {
  const taxRate = getVAT(country) ?? 0;

  const original = amount / 100;
  const discount = original * (percentOff / 100);
  const afterDiscount = original - discount;
  const afterCredit = Math.max(afterDiscount - credit, 0);
  const taxed = afterCredit * taxRate;
  const total = afterCredit + taxed;

  return {
    tax: {
      rate: taxRate * 100,
      visible: taxRate > 0
    },
    amounts: {
      original,
      discount,
      afterDiscount,
      afterCredit,
      taxed,
      total
    }
  };
};
