import classNames from "classnames";
import React from "react";
import { ModalType } from "../../interfaces/modal-type";
import { useModal } from "../../hooks/use-modal";
import { usePermissions } from "../../hooks/use-permissions";
import { SublyPlan } from "../../interfaces/billing";
import { PlanUpgradeModal } from "../upgrade-modal/plan-upgrade-modal";
import { Badge } from "./badge";
import styles from "./badge.module.scss";

interface PremiumBadgeProps {
  className?: string;
  hideLink?: boolean;
  inverted?: boolean;
}
export const PremiumBadge: React.FC<PremiumBadgeProps> = ({
  className,
  hideLink = false,
  inverted = false
}) => {
  const [showModal, hideAll] = useModal(ModalType.UpgradePlan);
  const { hasPermission } = usePermissions(SublyPlan.Premium);

  if (hasPermission) {
    return null;
  }

  if (hideLink) {
    return (
      <Badge className={className} inverted={inverted}>
        PREMIUM
      </Badge>
    );
  }

  const handleClick = () => {
    showModal(
      <PlanUpgradeModal upgradePlan={SublyPlan.Premium} closeModal={hideAll} />
    );
  };

  return (
    <Badge
      className={classNames(
        styles["badge--premium"],
        styles["badge--link"],
        className
      )}
      inverted={inverted}
      onClick={() => handleClick()}
    >
      PREMIUM
    </Badge>
  );
};
