import React from "react";
import { SignUpForm } from "../containers/auth/sign-up-form";
import { AuthLayout } from "../layouts/auth.layout";
import { useQuery } from "../hooks/use-query";
import { URLStateToPlan } from "../utils/plans";
import { TrialAuthLayout } from "../layouts/trial-auth.layout";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import config from "../config";

interface QueryParams {
  state?: string;
}
export const SignUpPage: React.FC = () => {
  const { state: queryState } = useQuery<QueryParams>();

  const redirectState = URLStateToPlan(queryState);

  const { plan } = redirectState;

  if (plan !== undefined) {
    return (
      <AuthLayout showLogin>
        <SignUpForm />
      </AuthLayout>
    );
  }

  return (
    <GoogleReCaptchaProvider reCaptchaKey={config.reCAPTCHA_V3}>
      <TrialAuthLayout showLogin>
        <SignUpForm isTrial={true} />
      </TrialAuthLayout>
    </GoogleReCaptchaProvider>
  );
};
