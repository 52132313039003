import React from "react";
import Lottie from "react-lottie-player";
import teamAnimation from "../../../../assets/images/onboarding/team-animation.json";
import { RadioInput } from "../../../../components/radio-input/radio-input";
import { useOnboarding } from "../../../../contexts/onboarding.context";
import { TeamSize } from "../../../../interfaces/user";
import { OnboardingButtons } from "../../onboarding-buttons";
import { OnboardingSteps } from "../../onboarding-steps";
import styles from "./onboarding-team.module.scss";

export const OnboardingTeam: React.FC = () => {
  const { data, setData, isLoading } = useOnboarding();

  const teamSizes = Object.values(TeamSize);
  const hasTeamSize = teamSizes.includes(data.teamSize as TeamSize);
  const initOther = hasTeamSize ? "" : data.teamSize;

  const [otherTeamSize, setOtherTeamSize] = React.useState(initOther);

  const renderTeamSizes = teamSizes.map((size) => {
    const isOther =
      size === TeamSize.OverTwoHundred &&
      Boolean(!hasTeamSize && otherTeamSize);
    const defaultChecked = isOther || data.teamSize === size;

    return (
      <RadioInput
        className="mt-2"
        id={size}
        name="teamSize"
        value={size}
        defaultChecked={defaultChecked}
        key={size}
      >
        {size}
      </RadioInput>
    );
  });

  const handleTeamSizeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const teamSize = e.target.value as TeamSize;
    setData((d) => ({ ...d, teamSize }));
  };

  const handleOtherChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const teamSize = e.target.value;

    setData((d) => ({ ...d, teamSize }));
    setOtherTeamSize(teamSize);
  };

  const showOther =
    Boolean(!hasTeamSize && otherTeamSize) ||
    data.teamSize === TeamSize.OverTwoHundred;

  return (
    <div className={styles.wrapper}>
      <Lottie
        loop
        animationData={teamAnimation}
        speed={0.5}
        className={styles.image}
        play
      />
      <div className={styles.container}>
        <div className={styles.form}>
          <h3 className={styles.header}>
            How big is your team?
            <OnboardingSteps />
          </h3>
          <div onChange={handleTeamSizeChange}>
            {renderTeamSizes}

            {showOther && (
              <input
                type="text"
                className="form-control mt-2"
                placeholder="Please specify"
                value={otherTeamSize}
                onChange={handleOtherChange}
              />
            )}
          </div>
        </div>
        <OnboardingButtons isLoading={isLoading} disableNext={!data.teamSize} />
      </div>
    </div>
  );
};
