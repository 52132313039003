import React from "react";

interface NavContext {
  showSidebar: boolean;
  toggleSidebar: React.Dispatch<React.SetStateAction<boolean>>;
}

export const NavContext = React.createContext<NavContext>({
  showSidebar: false,
  toggleSidebar: () => null
});

export const NavProvider: React.FC = ({ children }) => {
  const [showSidebar, toggleSidebar] = React.useState(false);

  return (
    <NavContext.Provider
      value={{
        showSidebar,
        toggleSidebar
      }}
    >
      {children}
    </NavContext.Provider>
  );
};
