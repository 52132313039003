import americanExpress from "../assets/images/cards/american-express.png";
import dinnersClub from "../assets/images/cards/dinners-club.png";
import discover from "../assets/images/cards/discover.png";
import jcb from "../assets/images/cards/jcb.png";
import mastercard from "../assets/images/cards/mastercard.png";
import visa from "../assets/images/cards/visa.png";

const CARD_IMAGES: { [key: string]: string } = {
  "american express": americanExpress,
  "diners club": dinnersClub,
  discover: discover,
  jcb: jcb,
  mastercard: mastercard,
  visa: visa
};

export const getCardImage = (brand?: string): string | null => {
  if (!brand) {
    return null;
  }

  return CARD_IMAGES[brand.toLowerCase()];
};
