import React from "react";

export const TranslatingImage: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    width="210"
    height="90"
    viewBox="0 0 210 90"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="210" height="90" rx="4" fill="white" />
    <mask
      id="mask0_2431_124752"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="210"
      height="90"
    >
      <rect width="210" height="90" rx="4" fill="white" />
    </mask>
    <g mask="url(#mask0_2431_124752)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M142.1 64.0953L148.575 62.5557L148.069 60.8796L151.023 60.1773L151.529 61.8534L159.14 60.0436L159.914 62.6071L156.726 63.365C156.973 65.4936 156.454 68.0327 155.236 70.3051C155.098 70.5632 154.949 70.8197 154.791 71.0734C156.096 71.5846 157.343 71.876 158.358 71.9241L158.158 74.578C156.556 74.5021 154.766 74.036 153.036 73.305C151.543 74.8237 149.577 76.055 147.106 76.6423L146.332 74.0789C147.927 73.6998 149.274 72.9372 150.368 71.9362C148.404 70.7362 146.598 69.1634 145.439 67.3332L148.116 66.0302C149.009 67.4396 150.469 68.7361 152.151 69.7617C152.264 69.5787 152.37 69.3925 152.472 69.2036C153.414 67.4454 153.801 65.5802 153.723 64.0791L150.826 64.7679L142.874 66.6587L142.1 64.0953Z"
        fill="#39EFA5"
      />
      <path
        d="M166.632 55.4093L166.928 51.5489L173.14 50.0717L175.58 53.2818L178.775 52.5221L168.619 39.5663L164.924 40.445L163.437 56.169L166.632 55.4093ZM167.105 49.2704L167.595 42.962L167.724 42.9313L171.703 48.1769L167.105 49.2704Z"
        fill="#9E9E9E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M175.079 57.6587C175.776 59.9678 175.514 61.9724 174.471 63.5343C173.742 64.6257 172.563 65.6049 170.84 66.3364L170.358 64.7412L165.296 69.1477L172.163 70.7181L171.699 69.1814C174.147 68.2352 176.016 66.831 177.211 65.0419C178.81 62.6468 179.056 59.7752 178.176 56.8612L175.079 57.6587Z"
        fill="#E0E0E0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M148.398 56.2598C147.701 53.9507 147.964 51.946 149.007 50.3842C149.735 49.2927 150.914 48.3136 152.638 47.582L153.12 49.1773L158.181 44.7707L151.315 43.2004L151.779 44.737C149.331 45.6833 147.461 47.0874 146.267 48.8765C144.668 51.2716 144.422 54.1432 145.302 57.0573L148.398 56.2598Z"
        fill="#E0E0E0"
      />
      <path
        d="M138.73 22.8232L138.409 22.7647C124.091 20.1529 119.716 0.499992 119.318 -9H232.671C237.244 14.1176 247.585 66.8118 240.426 67.2353C231.477 67.7647 211.014 62.4706 199.858 44.4706C192.523 32.635 161.959 27.0601 138.73 22.8232Z"
        fill="#E2FDF2"
        fillOpacity="0.7"
      />
      <path
        d="M40.0858 72.0947C20.1041 74.7565 -9.09996 64.6415 -19.0908 55.0588V98.7132C11.1379 99.7779 70.182 100.416 73.1325 96.5837C85.429 80.6126 60.0676 69.4328 40.0858 72.0947Z"
        fill="#E2FDF2"
        fillOpacity="0.7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M66.0619 46.4565C64.2595 48.6342 62.1201 49.8801 59.8988 50.2285C58.3465 50.472 56.6062 50.3002 54.7482 49.546L55.9934 48.0415L48.3232 47.4502L51.3279 53.6785L52.5274 52.2292C55.0755 53.3672 57.6894 53.7462 60.2339 53.3471C63.6404 52.8127 66.6187 50.9312 68.8933 48.183L66.0619 46.4565Z"
        fill="#E0E0E0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.8336 27.7342C52.636 25.5565 54.7754 24.3106 56.9967 23.9621C58.549 23.7186 60.2893 23.8905 62.1473 24.6446L60.9021 26.1491L68.5723 26.7405L65.5676 20.5122L64.3681 21.9614C61.82 20.8234 59.2061 20.4445 56.6616 20.8436C53.2551 21.3779 50.2769 23.2594 48.0022 26.0077L50.8336 27.7342Z"
        fill="#E0E0E0"
      />
      <path
        d="M42.4409 50.2451C41.9111 51.4131 40.842 52.4547 39.2336 53.37C37.8255 54.1797 36.2658 54.7636 34.5548 55.1217C32.9245 55.4695 31.6945 55.5087 30.8647 55.2393C30.1013 54.9915 29.3252 54.6177 28.5364 54.118C27.9337 53.7396 27.2392 53.2279 26.4529 52.5828C25.7863 52.0375 25.1253 51.494 24.4698 50.9522L25.0409 50.4068C25.7085 50.7819 26.4038 51.166 27.1267 51.559C27.8559 51.9663 28.5414 52.2741 29.1831 52.4824C30.5993 52.9422 32.2738 52.9864 34.2067 52.615C35.962 52.2713 37.6066 51.654 39.1404 50.7633C40.679 49.8619 41.6698 48.9228 42.1129 47.946C42.4934 47.1071 42.6387 46.1432 42.5488 45.0543C42.4766 44.1781 42.2481 43.2268 41.8632 42.2004L43.9434 40.007C44.4846 41.1085 44.788 42.1328 44.8534 43.0799C44.926 44.1511 44.7071 45.2494 44.1965 46.3749L42.4409 50.2451ZM46.7016 31.4922L44.3689 33.4027L42.3348 31.1709L44.6177 29.2443L46.7016 31.4922ZM48.685 35.3886L46.3502 37.2618L44.2829 35.0193L46.5608 33.1458L48.685 35.3886ZM44.2177 35.5828L41.9088 37.4827L39.8271 35.272L42.1028 33.3613L44.2177 35.5828Z"
        fill="#9E9E9E"
      />
      <path
        d="M78.4628 40.3926L81.2517 34.2678L83.8898 35.12L79.1249 45.5847L76.5396 44.7495L77.3647 42.9372L77.224 42.8918C76.6589 43.3501 75.9486 43.6546 75.0932 43.8055C74.2456 43.9537 73.3147 43.864 72.3005 43.5364C71.4328 43.2561 70.7321 42.8603 70.1982 42.3492C69.6722 41.8354 69.3521 41.224 69.2378 40.515C69.1294 39.8079 69.2716 39.0229 69.6645 38.1599L72.6953 31.5037L75.3246 32.3531L72.4056 38.764C72.0809 39.4771 72.0774 40.1272 72.3951 40.7144C72.7128 41.3016 73.2791 41.7268 74.094 41.99C74.5864 42.1491 75.1194 42.2119 75.6928 42.1783C76.2721 42.1466 76.8109 41.9898 77.3091 41.7079C77.8132 41.4279 78.1978 40.9895 78.4628 40.3926Z"
        fill="#39EFA5"
      />
      <ellipse
        rx="1.17493"
        ry="1.16272"
        transform="matrix(0.952774 0.307792 -0.415136 0.911732 77.1045 29.597)"
        fill="#39EFA5"
      />
      <ellipse
        rx="1.17493"
        ry="1.16272"
        transform="matrix(0.952774 0.307792 -0.415136 0.911732 81.7412 31.1695)"
        fill="#39EFA5"
      />
    </g>
  </svg>
);

export const TranscribingImage: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    width="210"
    height="90"
    viewBox="0 0 210 90"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="210" height="90" rx="4" fill="white" />
    <mask
      id="mask0_2431_124650"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="210"
      height="90"
    >
      <rect width="210" height="90" rx="4" fill="white" />
    </mask>
    <g mask="url(#mask0_2431_124650)">
      <path
        d="M178.275 22.1465C184.966 68.8394 116.709 85.2934 190.826 123.817L302.279 29.438C263.403 1.73639 233.944 -48.632 212.33 -45.9618C155.778 -38.9752 171.584 -24.5464 178.275 22.1465Z"
        fill="#F9F6FF"
      />
      <path
        d="M80.3013 40.5882L81.4163 37.6367L86.5905 37.6081L87.7535 40.547L90.4145 40.5324L85.4791 28.615L82.4015 28.632L77.6403 40.6029L80.3013 40.5882ZM82.0761 35.895L83.9037 31.0732L84.0113 31.0727L85.9063 35.8739L82.0761 35.895Z"
        fill="#520F9A"
      />
      <mask
        id="mask1_2431_124650"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="77"
        y="37"
        width="55"
        height="26"
      >
        <rect
          x="92.9155"
          y="37.0586"
          width="38.3971"
          height="3.56543"
          fill="#F2E5FF"
        />
        <rect
          x="77.5566"
          y="44.1895"
          width="47.9964"
          height="3.56543"
          fill="#F2E5FF"
        />
        <rect
          x="77.5566"
          y="51.3203"
          width="50.8762"
          height="3.56543"
          fill="#F2E5FF"
        />
        <rect
          x="77.5566"
          y="58.4512"
          width="53.756"
          height="3.56543"
          fill="#F2E5FF"
        />
      </mask>
      <g mask="url(#mask1_2431_124650)">
        <rect
          x="74.6768"
          y="35.6096"
          width="61.4354"
          height="28.9624"
          fill="#8A50F7"
        />
      </g>
    </g>
  </svg>
);

export const CreatingAudiogramImage: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    width="210"
    height="90"
    viewBox="0 0 210 90"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="210" height="90" rx="4" fill="white" />
    <mask
      id="mask0_2431_124644"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="210"
      height="90"
    >
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H206C208.209 0 210 1.79086 210 4V86C210 88.2091 208.209 90 206 90H4C1.79086 90 0 88.2091 0 86V4Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_2431_124644)">
      <path
        d="M26.1371 59.7614C26.8816 87.7662 37.9172 115.461 -24.1568 114.561L-27.9528 -28.2439C16.8171 -21.0257 109.732 -7.67752 90.1738 -1.26362C63.3715 7.52593 25.3927 31.7566 26.1371 59.7614Z"
        fill="#FFF7E6"
      />
      <path
        d="M183.695 59.7614C182.95 87.7662 171.915 115.461 233.989 114.561L237.785 -28.2439C193.015 -21.0257 100.1 -7.67752 119.658 -1.26362C146.461 7.52593 184.439 31.7566 183.695 59.7614Z"
        fill="#FFF7E6"
      />
    </g>
    <g clipPath="url(#clip0_2431_124644)">
      <path
        d="M109.164 64.6703C108.671 66.3214 106.808 66.7059 105 66.7059C103.192 66.7059 101.329 66.3214 100.836 64.6703C100.103 62.201 98.8639 56.0137 98.8639 53.1346C98.8639 50.0811 101.85 49.3346 105 49.3346C108.151 49.3346 111.137 50.0811 111.137 53.1346C111.137 55.9964 109.903 62.1832 109.164 64.6703ZM98.5635 47.2982C96.7712 45.708 95.6911 43.4615 95.8036 40.9925C96.0006 36.6736 99.8685 33.1792 104.637 33.012C109.877 32.8282 114.205 36.6368 114.205 41.3438C114.205 43.6739 113.144 45.7836 111.437 47.298C111.181 47.5256 111.207 47.9048 111.497 48.096C112.39 48.6835 113.075 49.4286 113.533 50.2989C113.7 50.6163 114.156 50.7037 114.439 50.4631C117.203 48.1143 118.903 44.7495 118.803 41.0336C118.625 34.4146 112.705 29.0293 105.399 28.8416C97.6048 28.6413 91.1934 34.3271 91.1934 41.3438C91.1934 44.9363 92.8744 48.1794 95.5608 50.4628C95.8439 50.7035 96.3001 50.6165 96.4671 50.2989C96.9249 49.4285 97.6103 48.6835 98.5032 48.096C98.7936 47.9049 98.8199 47.5257 98.5635 47.2982V47.2982ZM105 22.2354C93.1306 22.2354 83.5229 30.9371 83.5229 41.6912C83.5229 49.5076 88.5665 56.0788 95.5789 59.1849C95.9943 59.3688 96.4674 59.0507 96.3972 58.6368C96.1688 57.2895 95.9809 55.9489 95.8789 54.7857C95.8649 54.6262 95.7687 54.4829 95.6221 54.3938C91.0774 51.6312 88.0894 46.9227 88.1255 41.5957C88.1823 33.2369 95.7279 26.4264 104.955 26.4046C114.281 26.3825 121.875 33.2485 121.875 41.6912C121.875 47.0704 118.792 51.8092 114.142 54.5345C114.047 55.7537 113.848 57.1912 113.603 58.6367C113.533 59.0507 114.006 59.3688 114.422 59.1848C121.418 56.0857 126.477 49.5246 126.477 41.6912C126.477 30.9388 116.872 22.2354 105 22.2354ZM105 36.1324C101.611 36.1324 98.8639 38.6212 98.8639 41.6912C98.8639 44.7613 101.611 47.2501 105 47.2501C108.389 47.2501 111.137 44.7613 111.137 41.6912C111.137 38.6212 108.389 36.1324 105 36.1324Z"
        fill="#FFAB00"
      />
      <path
        d="M98.5635 47.2982C96.7712 45.708 95.6911 43.4615 95.8036 40.9925C96.0006 36.6736 99.8685 33.1792 104.637 33.012C109.877 32.8282 114.205 36.6368 114.205 41.3438C114.205 43.6739 113.144 45.7836 111.437 47.298C111.181 47.5256 111.207 47.9048 111.497 48.096C112.39 48.6835 113.075 49.4286 113.533 50.2989C113.7 50.6163 114.156 50.7037 114.439 50.4631C117.203 48.1143 118.903 44.7495 118.803 41.0336C118.625 34.4146 112.705 29.0293 105.399 28.8416C97.6048 28.6413 91.1934 34.3271 91.1934 41.3438C91.1934 44.9363 92.8744 48.1794 95.5608 50.4628C95.8439 50.7035 96.3001 50.6165 96.4671 50.2989C96.9249 49.4285 97.6103 48.6835 98.5032 48.096C98.7936 47.9049 98.8199 47.5257 98.5635 47.2982ZM105 22.2354C93.1306 22.2354 83.5229 30.9371 83.5229 41.6912C83.5229 49.5076 88.5665 56.0788 95.5789 59.1849C95.9943 59.3688 96.4674 59.0507 96.3972 58.6368C96.1688 57.2895 95.9809 55.9489 95.8789 54.7857C95.8649 54.6262 95.7687 54.4829 95.6221 54.3938C91.0774 51.6312 88.0894 46.9227 88.1255 41.5957C88.1823 33.2369 95.7279 26.4264 104.955 26.4046C114.281 26.3825 121.875 33.2485 121.875 41.6912C121.875 47.0704 118.792 51.8092 114.142 54.5345C114.047 55.7537 113.848 57.1912 113.603 58.6367C113.533 59.0507 114.006 59.3688 114.422 59.1848C121.418 56.0857 126.477 49.5246 126.477 41.6912C126.477 30.9388 116.872 22.2354 105 22.2354Z"
        fill="#FFF7E6"
        fillOpacity="0.8"
      />
      <path
        d="M105 22.2354C93.1306 22.2354 83.5229 30.9371 83.5229 41.6912C83.5229 49.5076 88.5665 56.0788 95.5789 59.1849C95.9943 59.3688 96.4674 59.0507 96.3972 58.6368C96.1688 57.2895 95.9809 55.9489 95.8789 54.7857C95.8649 54.6262 95.7687 54.4829 95.6221 54.3938C91.0774 51.6312 88.0894 46.9227 88.1255 41.5957C88.1823 33.2369 95.7279 26.4264 104.955 26.4046C114.281 26.3825 121.875 33.2485 121.875 41.6912C121.875 47.0704 118.792 51.8092 114.142 54.5345C114.047 55.7537 113.848 57.1912 113.603 58.6367C113.533 59.0507 114.006 59.3688 114.422 59.1848C121.418 56.0857 126.477 49.5246 126.477 41.6912C126.477 30.9388 116.872 22.2354 105 22.2354Z"
        fill="#FFAB00"
        fillOpacity="0.5"
      />
    </g>
    <defs>
      <clipPath id="clip0_2431_124644">
        <rect
          width="42.9545"
          height="44.4706"
          fill="white"
          transform="translate(83.5229 22.2354)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const PairingSubtitleImage: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    width="210"
    height="90"
    viewBox="0 0 210 90"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="210" height="90" rx="4" fill="white" />
    <mask
      id="mask0_2431_124664"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="210"
      height="90"
    >
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H206C208.209 0 210 1.79086 210 4V86C210 88.2091 208.209 90 206 90H4C1.79086 90 0 88.2091 0 86V4Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_2431_124664)">
      <path
        d="M201.601 74.7223C157.217 81.7006 112.227 85.6589 140.613 132.622L272.465 111.136C265.572 82.9213 277.406 67.5406 261.857 66.2518C244.764 64.835 245.985 67.7441 201.601 74.7223Z"
        fill="#E2186F"
        fillOpacity="0.1"
      />
      <path
        d="M51.4069 10.0432C96.1091 4.90842 141.258 2.81526 115.369 -45.2708L-17.4672 -29.2623C-12.0638 -0.792882 -24.6934 14.0816 -9.22826 16.0122C7.77105 18.1344 6.70459 15.178 51.4069 10.0432Z"
        fill="#E2186F"
        fillOpacity="0.1"
      />
    </g>
    <path
      d="M72.5011 66.6957L73.2553 64.6992L76.7553 64.6799L77.5419 66.6678L79.3419 66.6579L76.0035 58.5966L73.9218 58.6081L70.7012 66.7056L72.5011 66.6957ZM73.7016 63.5211L74.9379 60.2595L75.0106 60.2591L76.2925 63.5068L73.7016 63.5211Z"
      fill="#E2186F"
    />
    <rect
      x="82.4395"
      y="59.2942"
      width="46.5341"
      height="3.17647"
      fill="#E2186F"
      fillOpacity="0.5"
    />
    <rect
      x="82.4395"
      y="63.5295"
      width="53.6932"
      height="3.17647"
      fill="#E2186F"
      fillOpacity="0.2"
    />
    <rect
      x="68.1211"
      y="22.2354"
      width="72.7841"
      height="32.8235"
      fill="#FCE6E6"
    />
    <path
      d="M109.866 38.3509C110.448 38.748 110.448 39.6053 109.866 40.0024L101.901 45.4439C101.237 45.8974 100.337 45.422 100.337 44.6182L100.337 33.7351C100.337 32.9312 101.237 32.4559 101.901 32.9093L109.866 38.3509Z"
      fill="white"
    />
  </svg>
);

export const ExploreMoreImage: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    width="210"
    height="90"
    viewBox="0 0 210 90"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="210" height="90" fill="white" />
    <mask
      id="mask0_2431_124760"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="82"
      y="40"
      width="46"
      height="10"
    >
      <circle cx="87" cy="45" r="5" fill="url(#paint0_linear_2431_124760)" />
      <circle cx="105" cy="45" r="5" fill="url(#paint1_linear_2431_124760)" />
      <circle cx="123" cy="45" r="5" fill="url(#paint2_linear_2431_124760)" />
    </mask>
    <g mask="url(#mask0_2431_124760)">
      <rect
        x="50"
        y="25"
        width="102"
        height="40"
        fill="url(#paint3_linear_2431_124760)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_2431_124760"
        x1="82"
        y1="40"
        x2="93.2335"
        y2="41.6618"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#39EFA5" />
        <stop offset="1" stopColor="#7B61FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2431_124760"
        x1="100"
        y1="40"
        x2="111.233"
        y2="41.6618"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#39EFA5" />
        <stop offset="1" stopColor="#7B61FF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2431_124760"
        x1="118"
        y1="40"
        x2="129.233"
        y2="41.6618"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#39EFA5" />
        <stop offset="1" stopColor="#7B61FF" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_2431_124760"
        x1="50"
        y1="25"
        x2="152.503"
        y2="63.6661"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#39EFA5" />
        <stop offset="1" stopColor="#7B61FF" />
      </linearGradient>
    </defs>
  </svg>
);

export const ResizeImage: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="210"
    height="90"
    viewBox="0 0 210 90"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="210" height="90" rx="4" fill="white" />
    <mask
      id="mask0_4120_554641"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="210"
      height="90"
    >
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H206C208.209 0 210 1.79086 210 4V86C210 88.2091 208.209 90 206 90H4C1.79086 90 0 88.2091 0 86V4Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_4120_554641)">
      <path
        d="M75.5 24C75.5 23.7239 75.7239 23.5 76 23.5H135C135.276 23.5 135.5 23.7239 135.5 24V56.7742C135.5 57.0503 135.276 57.2742 135 57.2742H76C75.7239 57.2742 75.5 57.0503 75.5 56.7742V24Z"
        fill="#B9DAFE"
        stroke="#87C0FD"
        strokeWidth="3"
      />
      <path
        d="M97.5744 65.7255L99.7665 58.0481C99.8891 57.6187 100.281 57.3226 100.728 57.3226H110.272C110.718 57.3226 111.111 57.6187 111.233 58.0481L113.425 65.7255C113.608 66.3643 113.128 67 112.464 67H98.5359C97.8716 67 97.392 66.3643 97.5744 65.7255Z"
        fill="#87C0FD"
        stroke="#87C0FD"
      />
      <line
        x1="76.2104"
        y1="49.5322"
        x2="135.895"
        y2="49.5322"
        stroke="#87C0FD"
        strokeWidth="3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M115.447 46.6774H79.5264V44.2581H115.447V46.6774Z"
        fill="#87C0FD"
      />
      <path
        d="M118.763 23.4516L118.763 36.2742L118.763 49.0968"
        stroke="#87C0FD"
        strokeWidth="3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M78.099 35.5484L84.8696 31.3499C85.4508 30.9894 86.2119 31.1406 86.6112 31.6959C87.0333 32.2828 86.8959 33.1012 86.3054 33.5181L83.6908 35.364H110.73L108.115 33.5181C107.525 33.1012 107.388 32.2828 107.81 31.6959C108.209 31.1407 108.97 30.9895 109.551 31.3499L116.322 35.5484C117.135 35.9903 117.135 37.1581 116.322 37.6L109.551 41.7985C108.97 42.1589 108.209 42.0077 107.81 41.4525C107.388 40.8656 107.525 40.0472 108.116 39.6303L110.732 37.7834H83.6892L86.3053 39.6303C86.8959 40.0472 87.0333 40.8656 86.6112 41.4525C86.2119 42.0077 85.4508 42.1589 84.8696 41.7985L78.099 37.6C77.2854 37.1581 77.2854 35.9903 78.099 35.5484Z"
        fill="#0472E7"
      />
      <path
        d="M140.06 -0.459934C255.153 18.097 194.097 150.359 263.193 152.532L276.974 -92.6375C229.957 -82.7066 205.138 -103.077 202.197 -77.43C198.965 -49.2387 24.9671 -19.0169 140.06 -0.459934Z"
        fill="#0472E7"
        fillOpacity="0.05"
      />
      <path
        d="M19.5643 60.7245C10.1395 39.9117 7.34334 -2.14309 -19.6512 -1.59415L-20.847 103.022C7.5068 97.0411 61.53 110.729 61.1785 93.4312C60.8298 76.2681 28.9891 81.5373 19.5643 60.7245Z"
        fill="#0472E7"
        fillOpacity="0.05"
      />
    </g>
  </svg>
);

export const SnippetsImage: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    width="210"
    height="90"
    viewBox="0 0 210 90"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="210" height="90" rx="4" fill="white" />
    <mask
      id="mask0_4120_587255"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="210"
      height="90"
    >
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H206C208.209 0 210 1.79086 210 4V86C210 88.2091 208.209 90 206 90H4C1.79086 90 0 88.2091 0 86V4Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_4120_587255)">
      <path
        d="M224.318 35.4706C217.756 86.2941 158.097 69.353 158.097 111.137L272.464 111.137C265.572 82.9215 277.406 67.5408 261.856 66.252C244.764 64.8352 230.881 -15.3529 224.318 35.4706Z"
        fill="#0472E7"
        fillOpacity="0.1"
      />
      <path
        d="M28.0393 21.1765C72.7416 16.0417 104.041 5.20379 78.153 -42.8823L-17.4674 -29.2623C-12.064 -0.792806 -24.4144 95.4812 -8.94928 97.4118C8.05003 99.534 -16.663 26.3113 28.0393 21.1765Z"
        fill="#0472E7"
        fillOpacity="0.1"
      />
    </g>
    <rect
      x="54.2424"
      y="33.1538"
      width="44.3131"
      height="23.6923"
      rx="2"
      fill="#0095FF"
    />
    <path
      d="M79.2095 44.6232C79.4379 44.8225 79.4379 45.1775 79.2095 45.3767L75.6064 48.5204C75.283 48.8026 74.7777 48.5729 74.7777 48.1437L74.7777 41.8563C74.7777 41.427 75.283 41.1973 75.6064 41.4795L79.2095 44.6232Z"
      fill="white"
    />
    <rect
      x="51"
      y="31"
      width="2.16162"
      height="28"
      rx="1.08081"
      fill="#0095FF"
      fillOpacity="0.5"
    />
    <rect
      x="99.6364"
      y="31"
      width="2.16162"
      height="28"
      rx="1.08081"
      fill="#0095FF"
      fillOpacity="0.5"
    />
    <line
      x1="56.9041"
      y1="53.1154"
      x2="94.8131"
      y2="53.1154"
      stroke="white"
      strokeLinecap="square"
      strokeDasharray="3 3"
    />
    <line
      x1="56.9041"
      y1="36.9615"
      x2="94.8131"
      y2="36.9615"
      stroke="white"
      strokeLinecap="square"
      strokeDasharray="3 3"
    />
    <rect
      x="111.444"
      y="33.1538"
      width="44.3131"
      height="23.6923"
      rx="2"
      fill="#0095FF"
    />
    <path
      d="M136.412 44.6232C136.64 44.8225 136.64 45.1775 136.412 45.3767L132.808 48.5204C132.485 48.8026 131.98 48.5729 131.98 48.1437L131.98 41.8563C131.98 41.427 132.485 41.1973 132.808 41.4795L136.412 44.6232Z"
      fill="white"
    />
    <rect
      x="108.202"
      y="31"
      width="2.16162"
      height="28"
      rx="1.08081"
      fill="#0095FF"
      fillOpacity="0.5"
    />
    <rect
      x="156.838"
      y="31"
      width="2.16162"
      height="28"
      rx="1.08081"
      fill="#0095FF"
      fillOpacity="0.5"
    />
    <line
      x1="114.106"
      y1="53.1154"
      x2="152.015"
      y2="53.1154"
      stroke="white"
      strokeLinecap="square"
      strokeDasharray="3 3"
    />
    <line
      x1="114.106"
      y1="36.9615"
      x2="152.015"
      y2="36.9615"
      stroke="white"
      strokeLinecap="square"
      strokeDasharray="3 3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M104.75 25.5C104.336 25.5 104 25.1642 104 24.75C104 24.3358 104.336 24 104.75 24L105.25 24C105.664 24 106 24.3358 106 24.75C106 25.1642 105.664 25.5 105.25 25.5L104.75 25.5ZM105 31.5C104.448 31.5 104 31.0523 104 30.5L104 29.5C104 28.9477 104.448 28.5 105 28.5C105.552 28.5 106 28.9477 106 29.5L106 30.5C106 31.0523 105.552 31.5 105 31.5ZM105 37.5C104.448 37.5 104 37.0523 104 36.5L104 35.5C104 34.9477 104.448 34.5 105 34.5C105.552 34.5 106 34.9477 106 35.5L106 36.5C106 37.0523 105.552 37.5 105 37.5ZM105 43.5C104.448 43.5 104 43.0523 104 42.5L104 41.5C104 40.9477 104.448 40.5 105 40.5C105.552 40.5 106 40.9477 106 41.5L106 42.5C106 43.0523 105.552 43.5 105 43.5ZM105 49.5C104.448 49.5 104 49.0523 104 48.5L104 47.5C104 46.9477 104.448 46.5 105 46.5C105.552 46.5 106 46.9477 106 47.5L106 48.5C106 49.0523 105.552 49.5 105 49.5ZM105 55.5C104.448 55.5 104 55.0523 104 54.5L104 53.5C104 52.9477 104.448 52.5 105 52.5C105.552 52.5 106 52.9477 106 53.5L106 54.5C106 55.0523 105.552 55.5 105 55.5ZM105 61.5C104.448 61.5 104 61.0523 104 60.5L104 59.5C104 58.9477 104.448 58.5 105 58.5C105.552 58.5 106 58.9477 106 59.5L106 60.5C106 61.0523 105.552 61.5 105 61.5ZM104.75 66C104.336 66 104 65.6642 104 65.25C104 64.8358 104.336 64.5 104.75 64.5L105.25 64.5C105.664 64.5 106 64.8358 106 65.25C106 65.6642 105.664 66 105.25 66L104.75 66Z"
      fill="#0095FF"
    />
  </svg>
);

export const SubtitlesImage: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    width="210"
    height="90"
    viewBox="0 0 210 90"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="210" height="90" rx="4" fill="white" />
    <mask
      id="mask0_4120_178783"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="210"
      height="90"
    >
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H206C208.209 0 210 1.79086 210 4V86C210 88.2091 208.209 90 206 90H4C1.79086 90 0 88.2091 0 86V4Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_4120_178783)">
      <path
        d="M19.0425 53.5495C32.0556 78.3584 54.1696 98.353 -1.94485 124.908L-68.3025 -1.60038C-24.9353 -14.8565 64.349 -43.8333 49.621 -29.4547C29.4378 -9.7504 6.02934 28.7406 19.0425 53.5495Z"
        fill="#FEF2F2"
        fillOpacity="0.5"
      />
      <path
        d="M192.574 51.8432C179.561 76.6521 157.447 96.6467 213.561 123.201L279.919 -3.30668C236.552 -16.5628 147.268 -45.5396 161.996 -31.161C182.179 -11.4567 205.587 27.0343 192.574 51.8432Z"
        fill="#FEF2F2"
        fillOpacity="0.5"
      />
      <mask
        id="mask1_4120_178783"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="91"
        y="27"
        width="27"
        height="37"
      >
        <path
          d="M93 27H110.131C110.685 27 111.215 27.2301 111.593 27.6354L117.462 33.9237C117.808 34.294 118 34.7817 118 35.2883V61.5C118 62.6046 117.105 63.5 116 63.5H93C91.8954 63.5 91 62.6046 91 61.5V29C91 27.8954 91.8954 27 93 27Z"
          fill="#FEE2E2"
        />
      </mask>
      <g mask="url(#mask1_4120_178783)">
        <path
          d="M93 27H110.131C110.685 27 111.215 27.2301 111.593 27.6354L117.462 33.9237C117.808 34.294 118 34.7817 118 35.2883V61.5C118 62.6046 117.105 63.5 116 63.5H93C91.8954 63.5 91 62.6046 91 61.5V29C91 27.8954 91.8954 27 93 27Z"
          fill="#FECACA"
        />
        <rect x="93" y="42" width="23" height="5" rx="2" fill="#F87171" />
        <rect x="93" y="49" width="23" height="5" rx="2" fill="#F87171" />
        <path
          d="M118 34.5L111 27V32.5C111 33.6046 111.895 34.5 113 34.5H118Z"
          fill="#F87171"
        />
      </g>
    </g>
  </svg>
);

export const UploadVideoImage: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    width="210"
    height="90"
    viewBox="0 0 210 90"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Frame 1329">
      <g id="CTA/shortcut/upload video">
        <rect id="Rectangle 407" width="210" height="90" rx="4" fill="white" />
        <mask
          id="mask0_323_809"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="210"
          height="90"
        >
          <rect
            id="Rectangle 408"
            width="210"
            height="90"
            rx="4"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_323_809)">
          <path
            id="Vector 7"
            opacity="0.05"
            d="M22.55 60.4988C25.4487 13.4181 95.6233 11.1204 30.8383 -41.6077L-97.4112 28.2588C-64.947 63.2559 -46.2925 118.544 -24.5855 120.304C32.2097 124.909 19.6512 107.579 22.55 60.4988Z"
            fill="#B850F7"
          />
          <g id="Group 465">
            <path
              id="Vector"
              d="M87.0009 59.0983C87.0009 60.4125 88.0576 61.4703 89.3695 61.4703H115.634C116.288 61.4703 116.762 60.8522 116.68 60.2035C116.635 59.8418 116.61 59.4736 116.61 59.0983C116.61 53.8577 120.852 49.6091 126.086 49.6091C126.46 49.6091 126.827 49.6336 127.187 49.6788C127.836 49.7604 128.455 49.2863 128.455 48.6322V37.9911H87C87 48.6991 87.0012 53.9901 87.0009 59.0983ZM104.767 44.6079C104.767 43.8222 105.632 43.3434 106.298 43.7602L112.888 47.8853C113.514 48.277 113.514 49.189 112.888 49.5808C110.47 51.0943 108.686 52.2107 106.298 53.7052C105.632 54.122 104.767 53.6437 104.767 52.858V48.7329V44.6079Z"
              fill="#DDACFB"
            />
            <g id="Vector_2">
              <path
                d="M87.001 31.372C87.001 30.0582 88.0572 29 89.3696 29H97.9198L91.7941 36.1167H87.001V31.372Z"
                fill="#C36DF8"
              />
              <path
                d="M94.9029 36.1167L101.029 29H109.764L103.638 36.1167H94.9029Z"
                fill="#C36DF8"
              />
              <path
                d="M106.747 36.1167L112.873 29H121.608L115.482 36.1167H106.747Z"
                fill="#C36DF8"
              />
              <path
                d="M118.591 36.1167L124.717 29H126.086C127.398 29 128.455 30.0578 128.455 31.372V36.1167H118.591Z"
                fill="#C36DF8"
              />
            </g>
            <g id="Vector_3">
              <path
                d="M126.709 51.4419C122.13 51.4419 118.418 55.1594 118.418 59.7446C118.418 64.3303 122.131 68.0473 126.709 68.0473C131.288 68.0473 135 64.3298 135 59.7446C135 55.1588 131.288 51.4419 126.709 51.4419ZM126.709 54.4072C127.051 54.4072 127.344 54.5644 127.561 54.7965C128.547 55.6764 129.519 56.7752 130.503 57.725C130.979 58.202 130.966 58.9296 130.503 59.393C130.04 59.8563 129.3 59.8563 128.837 59.393L127.893 58.4473C127.893 58.4473 127.893 63.2407 127.893 63.8959C127.893 64.5512 127.363 65.082 126.709 65.082C126.055 65.082 125.525 64.5508 125.525 63.8959V58.4473L124.581 59.3925C124.118 59.8559 123.378 59.8559 122.915 59.3925C122.453 58.9292 122.444 58.2016 122.915 57.7246C123.879 56.7536 124.889 55.6942 125.858 54.7961C126.074 54.5644 126.367 54.4072 126.709 54.4072Z"
                fill="#B850F7"
              />
              <path
                d="M126.709 51.4419C122.13 51.4419 118.418 55.1594 118.418 59.7446C118.418 64.3303 122.131 68.0473 126.709 68.0473C131.288 68.0473 135 64.3298 135 59.7446C135 55.1588 131.288 51.4419 126.709 51.4419ZM126.709 54.4072C127.051 54.4072 127.344 54.5644 127.561 54.7965C128.547 55.6764 129.519 56.7752 130.503 57.725C130.979 58.202 130.966 58.9296 130.503 59.393C130.04 59.8563 129.3 59.8563 128.837 59.393L127.893 58.4473C127.893 58.4473 127.893 63.2407 127.893 63.8959C127.893 64.5512 127.363 65.082 126.709 65.082C126.055 65.082 125.525 64.5508 125.525 63.8959V58.4473L124.581 59.3925C124.118 59.8559 123.378 59.8559 122.915 59.3925C122.453 58.9292 122.444 58.2016 122.915 57.7246C123.879 56.7536 124.889 55.6942 125.858 54.7961C126.074 54.5644 126.367 54.4072 126.709 54.4072Z"
                fill="#B850F7"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const BrandYourVideoImage: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    width="210"
    height="90"
    viewBox="0 0 210 90"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="mask0_297_64382"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="210"
      height="90"
    >
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H206C208.209 0 210 1.79086 210 4V86C210 88.2091 208.209 90 206 90H4C1.79086 90 0 88.2091 0 86V4Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_297_64382)">
      <path
        d="M39.5004 20.5C66.0003 0.000104908 75.0004 -25.8043 -13.3544 -25.8043L-32.8975 128.828C11.2877 118.625 62.9445 107.588 43.0003 102.5C15.669 95.5272 13.0004 40.9999 39.5004 20.5Z"
        fill="#FCF9F3"
      />
      <path
        d="M185.5 58.0001C204 28.0001 203 -17.9999 250.3 -12.2037L244.514 130.534C200.329 120.331 95.5724 102.126 115.5 103.5C130 104.5 167 88.0001 185.5 58.0001Z"
        fill="#FCF9F3"
      />
      <mask
        id="mask1_297_64382"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="85"
        y="31"
        width="24"
        height="34"
      >
        <path
          d="M102.255 64.3081L89.523 42.2559C89.406 42.0533 89.3637 41.8161 89.4036 41.5855L89.7437 39.6178C89.7835 39.3873 89.7413 39.1501 89.6243 38.9475L85.9887 32.6505C85.7865 32.3002 86.0623 31.8672 86.4652 31.9024L89.2263 32.1436C89.5514 32.172 89.8422 32.3572 90.0054 32.6398L92.6414 37.2056C92.7584 37.4082 92.9427 37.5634 93.1622 37.6441L95.0364 38.3335C95.2559 38.4142 95.4402 38.5694 95.5572 38.772L108.289 60.8242C108.565 61.3025 108.401 61.9141 107.923 62.1902L103.621 64.6741C103.143 64.9502 102.531 64.7864 102.255 64.3081Z"
          fill="#C1912F"
        />
      </mask>
      <g mask="url(#mask1_297_64382)">
        <path
          d="M101.013 62.157L89.523 42.2559C89.406 42.0533 89.3637 41.8161 89.4036 41.5855L89.7437 39.6178C89.7835 39.3873 89.7413 39.1501 89.6243 38.9475L85.9887 32.6505C85.7865 32.3002 86.0623 31.8672 86.4652 31.9024L89.2263 32.1436C89.5514 32.172 89.8422 32.3572 90.0054 32.6398L92.6414 37.2056C92.7584 37.4082 92.9427 37.5634 93.1622 37.6441L95.0364 38.3335C95.2559 38.4142 95.4402 38.5694 95.5572 38.772L107.047 58.6731C108.009 60.3394 107.438 62.4701 105.772 63.4322C104.106 64.3942 101.975 63.8233 101.013 62.157Z"
          fill="#AD822A"
        />
        <line
          x1="89.0327"
          y1="37.9229"
          x2="92.0498"
          y2="36.181"
          stroke="white"
        />
        <line
          x1="88.8411"
          y1="43.3974"
          x2="96.8867"
          y2="38.7522"
          stroke="white"
        />
      </g>
      <mask
        id="mask2_297_64382"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="99"
        y="24"
        width="10"
        height="40"
      >
        <path
          d="M100.723 62.8323V40.7163C100.723 40.5676 100.756 40.4207 100.82 40.2864L102.786 36.159C102.945 35.8232 102.906 35.4265 102.683 35.129L99.9863 31.5337C99.7319 31.1946 99.719 30.7318 99.9542 30.379L103.375 25.2481C103.771 24.6543 104.643 24.6543 105.039 25.2481L108.46 30.379C108.695 30.7318 108.682 31.1946 108.428 31.5337L105.731 35.129C105.508 35.4265 105.468 35.8232 105.628 36.159L107.594 40.2864C107.658 40.4207 107.691 40.5676 107.691 40.7163V62.8323C107.691 63.3846 107.243 63.8323 106.691 63.8323H101.723C101.171 63.8323 100.723 63.3846 100.723 62.8323Z"
          fill="#D4A84D"
        />
      </mask>
      <g mask="url(#mask2_297_64382)">
        <path
          d="M100.723 60.3484V40.7163C100.723 40.5676 100.756 40.4207 100.82 40.2864L102.786 36.159C102.945 35.8232 102.906 35.4265 102.683 35.129L99.9863 31.5337C99.7319 31.1946 99.719 30.7318 99.9542 30.379L103.375 25.2481C103.771 24.6543 104.643 24.6543 105.039 25.2481L108.46 30.379C108.695 30.7318 108.682 31.1946 108.428 31.5337L105.731 35.129C105.508 35.4265 105.468 35.8232 105.628 36.159L107.594 40.2864C107.658 40.4207 107.691 40.5676 107.691 40.7163V60.3484C107.691 62.2725 106.131 63.8323 104.207 63.8323C102.283 63.8323 100.723 62.2725 100.723 60.3484Z"
          fill="#D4A84D"
        />
        <line
          x1="101.884"
          y1="36.2742"
          x2="106.53"
          y2="36.2742"
          stroke="white"
        />
        <line
          x1="104.126"
          y1="30.9677"
          x2="104.126"
          y2="35.6129"
          stroke="white"
        />
        <line
          x1="99.5618"
          y1="40.9193"
          x2="108.852"
          y2="40.9193"
          stroke="white"
        />
        <circle cx="104.207" cy="30.3871" r="1.24193" stroke="white" />
      </g>
      <mask
        id="mask3_297_64382"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="100"
        y="30"
        width="23"
        height="35"
      >
        <path
          d="M100.276 60.9624L112.227 40.2628C112.36 40.0331 112.395 39.7609 112.367 39.4972C112.219 38.1014 112.41 33.4504 120.876 30.8591C121.404 30.6974 121.957 31.0166 122.081 31.5548C124.07 40.1821 120.138 42.6732 118.854 43.2426C118.612 43.3501 118.394 43.517 118.262 43.7467L106.311 64.4463C106.034 64.9246 105.423 65.0884 104.945 64.8123L100.642 62.3284C100.164 62.0523 100 61.4407 100.276 60.9624Z"
          fill="#E8D1A1"
        />
      </mask>
      <g mask="url(#mask3_297_64382)">
        <path
          d="M101.518 58.8113L112.227 40.2628C112.36 40.0331 112.395 39.7609 112.367 39.4972C112.219 38.1014 112.41 33.4504 120.876 30.8591C121.404 30.6974 121.957 31.0166 122.081 31.5548C124.07 40.1821 120.138 42.6732 118.854 43.2426C118.612 43.3501 118.394 43.517 118.262 43.7467L107.553 62.2952C106.59 63.9615 104.46 64.5324 102.793 63.5704C101.127 62.6083 100.556 60.4776 101.518 58.8113Z"
          fill="#E8D1A1"
        />
        <line
          x1="109.356"
          y1="42.9134"
          x2="117.402"
          y2="47.5585"
          stroke="white"
        />
        <line
          x1="111.098"
          y1="39.8962"
          x2="119.144"
          y2="44.5414"
          stroke="white"
        />
        <path
          d="M120.02 33.947C121.203 33.3412 122.543 33.0511 123.426 32.9656L123.538 34.1215C122.762 34.1966 121.572 34.4571 120.55 34.9806C119.727 35.402 119.09 35.951 118.795 36.6456C118.669 36.9408 118.381 37.1662 118.064 37.1205C117.747 37.0747 117.521 36.7776 117.62 36.4724C118.005 35.2775 119 34.4694 120.02 33.947Z"
          fill="white"
        />
      </g>
    </g>
  </svg>
);
