import { resetStores } from "@datorama/akita";
import { PartnerDiscountCode, PROMO_PARTNERS } from "@getsubly/common";
import { useObservable } from "@mindspace-io/react";
import * as Sentry from "@sentry/react";
import axios from "axios";
import { addDays, addMonths, isAfter } from "date-fns";
import { isBefore } from "date-fns/esm";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  deleteUser,
  editUserProfile,
  EditUserProfileParams,
  editUserProfilePicture,
  removeUserProfilePicture
} from "../api/account.service";
import {
  forgotPassword,
  ForgotPasswordParams,
  getAccessToken,
  getCurrentUser,
  providerSignIn,
  resetPassword,
  ResetPasswordParams,
  signIn,
  SignInParams,
  SignInResponse,
  signUp,
  SignUpParams,
  triggerEmailVerification,
  verifyEmailCode,
  verifyUser,
  VerifyUserParams
} from "../api/auth.service";
import { Params } from "../components/google-button/google-button";
import config from "../config";
import { getUserSettings } from "../config/settings/settings.service";
import { useAnalyticsWithAuth } from "../hooks/use-analytics-with-auth";
import { useQuery } from "../hooks/use-query";
import { useRewardful } from "../hooks/use-rewardful";
import { useSession } from "../hooks/use-session";
import { SublyPlan, Subscription } from "../interfaces/billing";
import { UnknownObject } from "../interfaces/types";
import { User, UserType } from "../interfaces/user";
import { DASHBOARD_PATH } from "../routes";
import { accountQuery, accountStore } from "../state/account";
import { assetsStore } from "../state/assets/assets.store";
import { authQuery } from "../state/auth/auth.query";
import { authStore } from "../state/auth/auth.store";
import { foldersStore } from "../state/folders/folders.store";
import { mediaStore } from "../state/media";
import { getStripeId } from "../utils/accounts";
import { getGoogleSignInHref } from "../utils/links";
import { extractErrorDescription } from "../utils/strings";
import { AnalyticsContext, useAnalytics } from "./analytics";

interface QueryResult {
  success: boolean;
  loading: boolean;
  useAlert: boolean;
  alertMessage: string;
  data?: UnknownObject;
  user?: User;
}

export interface PartnerOptions {
  name: string;
  showBanner: boolean;
  discountCode?: PartnerDiscountCode;
}
interface AuthContext {
  login: (params: SignInParams) => Promise<QueryResult>;
  getAccessTokenAndCurrentUser: () => Promise<void>;
  oauthLogin: (
    code: string,
    state?: string | null,
    rewardfulReferral?: string,
    invite?: string
  ) => Promise<QueryResult>;
  logout: () => void;
  updateProfile: (values: EditUserProfileParams) => Promise<void>;
  uploadProfilePicture: (file: File) => Promise<QueryResult>;
  signUp: (values: SignUpParams) => Promise<QueryResult>;
  resetPassword: (values: ResetPasswordParams) => Promise<QueryResult>;
  forgotPassword: (values: ForgotPasswordParams) => Promise<QueryResult>;
  verifyUser: (values: VerifyUserParams) => Promise<QueryResult>;
  clearPhoneVerification: (userId: string) => Promise<QueryResult>;
  verifyEmail: (code: string) => Promise<QueryResult>;
  deleteUser: () => Promise<QueryResult>;
  saveAuthInfo: (data: SignInResponse) => User;
  isAuthenticated?: boolean;
  user?: User;
  setUser: (user?: User) => void;
  userPicture: string;
  isFoundingMember: boolean;
  isGoogleAuth: boolean;
  partner?: PartnerOptions;
  plan: SublyPlan;
  isProOrHigher: boolean;
  isPro100: boolean;
  isProAnnual: boolean;
  subscription?: Subscription;
  showUpgradeWelcome: boolean;
  setShowUpgradeWelcome: (show: boolean) => void;
  showReferral?: boolean;
  isEducation: boolean;
  hasUserClosedOverduePaymentPopUp?: boolean;
  setHasUserClosedOverduePaymentPopUp: (hasClosed: boolean) => void;
  hasShownOverduePaymentPopUp?: boolean;
  setHasShownOverduePaymentPopUp: (hasClosed: boolean) => void;
}

const AuthContext = React.createContext<AuthContext>({
  login: () => ({} as Promise<QueryResult>),
  getAccessTokenAndCurrentUser: () => ({} as Promise<void>),
  oauthLogin: () => ({} as Promise<QueryResult>),
  logout: () => null,
  updateProfile: () => Promise.resolve(),
  uploadProfilePicture: () => ({} as Promise<QueryResult>),
  signUp: () => ({} as Promise<QueryResult>),
  resetPassword: () => ({} as Promise<QueryResult>),
  forgotPassword: () => ({} as Promise<QueryResult>),
  verifyUser: () => ({} as Promise<QueryResult>),
  verifyEmail: () => ({} as Promise<QueryResult>),
  deleteUser: () => ({} as Promise<QueryResult>),
  clearPhoneVerification: () => ({} as Promise<QueryResult>),
  saveAuthInfo: () => ({} as User),
  user: undefined,
  setUser: () => ({} as Promise<QueryResult>),
  userPicture: "",
  isFoundingMember: false,
  isGoogleAuth: false,
  plan: SublyPlan.Free,
  isProOrHigher: false,
  isPro100: false,
  isProAnnual: false,
  showUpgradeWelcome: false,
  setShowUpgradeWelcome: () => null,
  isEducation: false,
  hasShownOverduePaymentPopUp: false,
  setHasShownOverduePaymentPopUp: () => null,
  hasUserClosedOverduePaymentPopUp: false,
  setHasUserClosedOverduePaymentPopUp: () => null
});

const authURL = `${config.apiUrl}/api/v1/auth`;

const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<User | undefined>(authQuery.user);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | undefined>();
  const [isProOrHigher] = useObservable(
    accountQuery.selectIsProOrHigher(),
    accountQuery.isProOrHigher
  );
  const [isPro100] = useObservable(
    accountQuery.selectIsPro100(),
    accountQuery.isPro100
  );
  const [isProAnnual] = useObservable(
    accountQuery.selectIsProAnnual(),
    accountQuery.isProAnnual
  );
  const [plan] = useObservable(
    accountQuery.selectCurrentPlan(),
    accountQuery.currentPlan
  );

  const [subscription] = useObservable(accountQuery.select("subscription"));
  const [showUpgradeWelcome, setShowUpgradeWelcome] = useState(false);
  const { analyticsIdentify } = useContext(AnalyticsContext);

  const navigate = useNavigate();
  const { referral: rewardfulReferral, clearRewardful } = useRewardful();
  const { get: getSession, update: updateSession } = useSession();
  const { state, redirect, gclid } = useQuery<Params>();
  const { trackEvent } = useContext(AnalyticsContext);
  const { hsSignUpIdentify } = useAnalytics();
  const { trackEventWithAuth } = useAnalyticsWithAuth();

  const saveUserProfile = (updatedUser: User) => {
    const newUserDetails = { ...user, ...updatedUser };
    setUser(newUserDetails);

    authStore.setUser(newUserDetails);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleCatchError = (error: any): QueryResult => {
    const status = error?.response?.status;
    let message = error?.response?.data?.message;

    if (status === 409 && message === "Google user") {
      message =
        "Log in with Google should be used for this email, you will be redirected in a second...";
    }

    return {
      success: false,
      loading: false,
      useAlert: true,
      alertMessage: message || error.message
    };
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSuccess = (data: any, status: number): QueryResult => {
    if (status !== 200) {
      throw new Error(data.message);
    }

    return {
      success: true,
      loading: false,
      useAlert: false,
      alertMessage: "",
      data
    };
  };

  const saveAuthInfo = ({ data }: SignInResponse): User => {
    try {
      const userAuth = data.auth;
      const userDetails = data.user;

      setUser(userDetails);

      const { workspaceId } = getUserSettings();
      const hasWorkspaceId = userDetails.accountIds.some(
        (id) => id === workspaceId
      );
      const accountId = hasWorkspaceId
        ? (workspaceId as string)
        : userDetails.accountId;

      authStore.setAccountId(accountId);

      const userAccount =
        userDetails.accounts?.find((a) => a.accountId === accountId) ??
        userDetails.accounts[0];

      if (userAccount) {
        accountStore.update(userAccount);
      }

      authStore.setAccessToken(userAuth.accessToken);
      authStore.setUser(userDetails);

      //this needs to be last, or SecureRoute will render without the info it needs (eg accountId)
      setIsAuthenticated(true);

      return userDetails;
    } catch (err) {
      setIsAuthenticated(false);
      throw err;
    }
  };

  const updateUser = (user?: User) => {
    setUser(user);
    authStore.setUser(user);
  };

  const login = async (params: SignInParams): Promise<QueryResult> => {
    try {
      const { data, status } = await signIn(params);

      const { settings, id, email } = saveAuthInfo(data);

      const stripeId = getStripeId(data?.data?.user);
      analyticsIdentify({ userId: id, stripeId, email });

      trackEventWithAuth("Sign in", { password: true });

      updateSession({ invite: undefined });

      const trialOnboarding = settings?.trialOnboarding ?? true;

      return handleSuccess(
        {
          trialOnboarding: trialOnboarding
        },
        status
      );
    } catch (e) {
      if (e?.response?.data?.message === "Google user") {
        const href = getGoogleSignInHref(state || redirect, gclid);
        setTimeout(() => {
          window.location.href = href;
          trackEvent("Sign in Google", { redirectTo: state });
        }, 2000);
      }
      return handleCatchError(e);
    }
  };

  const clearPhoneVerification = async (
    userId: string
  ): Promise<QueryResult> => {
    try {
      const { status } = await triggerEmailVerification(userId);

      return handleSuccess({}, status);
    } catch (e) {
      return handleCatchError(e);
    }
  };

  const getAccessTokenAndCurrentUser = async (): Promise<void> => {
    try {
      const accessToken = await getAccessToken();

      if (!accessToken) {
        authStore.reset();
        navigate(DASHBOARD_PATH);
      }

      if (!authQuery.user) {
        await getCurrentUser();
      }

      // We use the same data schema as that used by signIn
      // effectively we're doing the work of signIn (ie getting and saving accessToken / user) on every browser refresh
      const authData: SignInResponse = {
        data: {
          auth: { accessToken: accessToken || "" },
          user: authQuery.user as User
        }
      };
      const { id, email } = saveAuthInfo(authData);

      const stripeId = getStripeId(authData.data.user);
      analyticsIdentify({ userId: id, stripeId, email });

      setIsAuthenticated(true);
    } catch (e) {
      setIsAuthenticated(false);
      Sentry.captureException(e);
    }
  };

  const oauthLogin = async (code: string, state?: string | null) => {
    try {
      const { invite } = getSession();

      const { data, status } = await providerSignIn({
        code,
        redirect_uri: config.oauth.google,
        state,
        rewardfulReferral,
        invite
      });

      const { id, isNewUser, email, settings } = saveAuthInfo(data);
      const stripeId = getStripeId(data.data.user);
      analyticsIdentify({ userId: id, stripeId, email });

      trackEventWithAuth("Sign in", { password: false, isGoogleAuth: true });

      updateSession({ invite: undefined });
      clearRewardful();

      const trialOnboarding = settings?.trialOnboarding ?? true;

      return handleSuccess(
        {
          isNewUser: Boolean(isNewUser),
          trialOnboarding: trialOnboarding
        },
        status
      );
    } catch (e) {
      return handleCatchError(e);
    }
  };

  const logout = async (): Promise<void> => {
    try {
      // We sign out on the server to remove the refresh token
      // It's a httpOnly cookie, so we can't do it in javascript
      try {
        await axios.get("/signout", {
          baseURL: authURL,
          headers: { "x-access-token": await getAccessToken() },
          withCredentials: true
        });
      } catch (err) {
        // do nothing
      }

      authStore.reset();
      setUser(undefined);
      setIsAuthenticated(false);

      // TODO: There is a bug in Akita and it doesn't reset well Entity Stores
      // Issue raised to Akita: https://github.com/datorama/akita/issues/634
      resetStores({ exclude: ["media", "assets", "folders"] });

      // TODO: This will clean the media and assets on store
      mediaStore.set([]);
      assetsStore.set([]);
      foldersStore.set([]);

      navigate(DASHBOARD_PATH);
    } catch (e) {
      console.error(e);
    }
  };

  const updateProfile = async (
    values: EditUserProfileParams
  ): Promise<void> => {
    try {
      if (!user?.email) {
        throw new Error("User not found");
      }

      const { data } = await editUserProfile({
        ...values,
        email: user?.email,
        name: `${values.givenName} ${values.familyName}`
      });

      saveUserProfile(data.data.user);
    } catch (e) {
      throw new Error(e);
    }
  };

  const uploadProfilePicture = async (file: File): Promise<QueryResult> => {
    try {
      await removeUserProfilePicture();
    } catch (error) {
      // Ignore error of removing picture
      console.error("There was an error deleting profile picture");
    }

    try {
      const { data, status } = await editUserProfilePicture(file);

      const result = handleSuccess(data, status);

      saveUserProfile(data.data.user);

      return result;
    } catch (e) {
      return handleCatchError(e);
    }
  };

  const handleSignUp = async (values: SignUpParams): Promise<QueryResult> => {
    try {
      hsSignUpIdentify(values.email);

      const { data, status } = await signUp(values);

      return handleSuccess(data, status);
    } catch (e) {
      console.log("e", e); // eslint-disable-line no-console
      const error = handleCatchError(e);
      console.log("error", error); // eslint-disable-line no-console
      error.alertMessage =
        extractErrorDescription(error.alertMessage) || error.alertMessage;
      return error;
    }
  };

  const handleResetPassword = async (params: ResetPasswordParams) => {
    try {
      const { data, status } = await resetPassword(params);
      return handleSuccess(data, status);
    } catch (e) {
      return handleCatchError(e);
    }
  };

  const handleForgotPassword = async (params: ForgotPasswordParams) => {
    try {
      const { data, status } = await forgotPassword(params);
      return handleSuccess(data, status);
    } catch (e) {
      if (e?.response?.data?.message === "Google user") {
        const href = getGoogleSignInHref(state || redirect, gclid);
        setTimeout(() => {
          window.location.href = href;
          trackEvent("Sign in Google", { redirectTo: state });
        }, 2000);
      }
      return handleCatchError(e);
    }
  };

  const handleVerifyUser = async (params: {
    code: string;
    id: string;
  }): Promise<QueryResult> => {
    try {
      const { data, status } = await verifyUser({
        ...params,
        rewardfulReferral
      });

      clearRewardful();
      return handleSuccess(data, status);
    } catch (e) {
      return handleCatchError(e);
    }
  };

  const handleVerifyEmail = async (code: string): Promise<QueryResult> => {
    try {
      const { data, status } = await verifyEmailCode(code);

      return handleSuccess(data, status);
    } catch (e) {
      return handleCatchError(e);
    }
  };

  const handleDeleteUser = async (): Promise<QueryResult> => {
    try {
      if (!user?.id) {
        throw new Error("User not found");
      }
      const { data, status } = await deleteUser();
      return handleSuccess(data, status);
    } catch (e) {
      return handleCatchError(e);
    }
  };

  const isPartner = () => {
    if (!user?.partner) {
      return;
    }

    const partner = PROMO_PARTNERS.find((p) => p.name === user.partner);
    const isPromoPartner = Boolean(partner);

    const futureDate = addMonths(new Date(user.createdAt), 6);
    const isInsidePromo = isBefore(new Date(), futureDate);

    const showBanner = !isProOrHigher && isPromoPartner && isInsidePromo;

    return {
      name: user.partner,
      showBanner,
      discountCode: partner?.discountCode
    };
  };

  const partner = isPartner();

  const userPicture = user?.picturePublic ?? user?.picture ?? "";

  const isFoundingMember = Boolean(user?.settings?.foundingMember);

  const isGoogleAuth = (user?.id ?? "").slice(0, 6) === "Google";

  const isEducation =
    user?.settings?.onboarding?.industry?.toLowerCase() === "education" ||
    user?.settings?.onboarding?.userType === UserType.Education;

  const showReferral =
    user?.createdAt &&
    !Boolean(user?.settings?.hasSeenReferral) &&
    isAfter(new Date(), addDays(new Date(user.createdAt), 1));

  const [hasShownOverduePaymentPopUp, setHasShownOverduePaymentPopUp] =
    useState(false);
  const [
    hasUserClosedOverduePaymentPopUp,
    setHasUserClosedOverduePaymentPopUp
  ] = useState(false);

  return (
    <AuthContext.Provider
      value={{
        login,
        getAccessTokenAndCurrentUser,
        oauthLogin,
        updateProfile,
        uploadProfilePicture,
        signUp: handleSignUp,
        logout,
        resetPassword: handleResetPassword,
        forgotPassword: handleForgotPassword,
        verifyUser: handleVerifyUser,
        verifyEmail: handleVerifyEmail,
        deleteUser: handleDeleteUser,
        saveAuthInfo,
        isAuthenticated,
        user,
        setUser: updateUser,
        userPicture,
        isFoundingMember,
        isGoogleAuth,
        partner,
        plan,
        isProOrHigher,
        isProAnnual,
        isPro100,
        subscription,
        showUpgradeWelcome,
        setShowUpgradeWelcome,
        clearPhoneVerification,
        showReferral,
        isEducation,
        hasShownOverduePaymentPopUp,
        setHasShownOverduePaymentPopUp,
        hasUserClosedOverduePaymentPopUp,
        setHasUserClosedOverduePaymentPopUp
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
export { AuthProvider, AuthContext };
