import React from "react";
import classNames from "classnames";
import numeral from "numeral";
import { usePlan } from "../../../../hooks/use-plan";
import { parseCreditToText } from "../../../../utils/plans";
import { getVAT } from "../../../../utils/vat-rates";
import styles from "../plans.module.scss";
import { Coupon } from "../../../../api/billing.service";

interface PayAsYouGoTotalCardProps {
  quantity: number;
  amount: number;
  coupon?: Coupon;
  country?: string;
  credit?: number;
}
export const PayAsYouGoTotalCard: React.FC<PayAsYouGoTotalCardProps> = ({
  quantity,
  amount,
  coupon,
  country = "",
  credit = 0
}) => {
  const { credit: creditSeconds } = usePlan();

  const paygCredit = (creditSeconds?.payg || 0) + (creditSeconds?.extra ?? 0);
  const taxRate = getVAT(country) || 0;
  const showTax = taxRate > 0;
  const totalCredit = paygCredit + quantity * 60;

  const couponPercentOff = coupon?.percent_off ?? 0;
  const couponAmountOff = coupon?.amount_off ?? 0;
  const amountWithDiscount =
    amount * (1 - couponPercentOff / 100) - couponAmountOff / 100;

  const total = Math.max(amountWithDiscount * (1 + taxRate) - credit, 0);

  return (
    <div
      className={classNames(
        styles["card"],
        styles["total-card"],
        styles["total-card--payg"]
      )}
    >
      <h6 className="text-white">Buy Subly minutes</h6>
      <div className={styles["total-table--payg"]}>
        <div className={styles["balance-row--payg"]}>
          <div className="d-flex">
            <p
              className={classNames(
                "small m-0",
                styles["payg-balance"],
                styles["payg-balance--total-card"]
              )}
            >
              Current balance:{" "}
            </p>
            <p
              className={classNames(
                "small m-0",
                styles["payg-balance-value"],
                styles["payg-balance-value--total-card"]
              )}
            >
              {parseCreditToText(paygCredit)}
            </p>
          </div>
          <div className="d-flex">
            <p
              className={classNames(
                "small m-0",
                styles["payg-balance"],
                styles["payg-balance--total-card"]
              )}
            >
              Balance after top up:{" "}
            </p>
            <p
              className={classNames(
                "small",
                styles["payg-balance-value"],
                styles["payg-balance-value--total-card"]
              )}
            >
              {parseCreditToText(totalCredit)}
            </p>
          </div>
        </div>

        <div
          className={classNames(
            styles["subtotal-row"],
            styles["subtotal-row--payg"]
          )}
        >
          <p className="small mb-0">Subtotal</p>
          <p className="small mb-0">${numeral(amount).format("0.00")}</p>
        </div>
        {coupon && (
          <div
            className={classNames(styles["tax-row"], styles["tax-row--payg"])}
          >
            <p className="small mb-0">
              Discount{" "}
              {coupon?.percent_off && coupon?.percent_off > 0 && (
                <>({numeral(coupon.percent_off).format("0")}%)</>
              )}
            </p>
            <p className="small mb-0">
              {couponPercentOff ? (
                <>
                  - ${numeral((amount * couponPercentOff) / 100).format("0.00")}
                </>
              ) : (
                <>- ${numeral(couponAmountOff / 100).format("0.00")}</>
              )}
            </p>
          </div>
        )}
        {credit > 0 && (
          <div
            className={classNames(
              styles["credit-row"],
              styles["credit-row--payg"]
            )}
          >
            <p className="small mb-0">Credit</p>
            <p className="small mb-0">-${numeral(credit).format("0.00")}</p>
          </div>
        )}
        {showTax && (
          <div
            className={classNames(styles["tax-row"], styles["tax-row--payg"])}
          >
            <p className="small mb-0">
              Tax ({numeral(taxRate * 100).format("0")}%)
            </p>
            <p className="small mb-0">
              ${numeral(amountWithDiscount * taxRate).format("0.00")}
            </p>
          </div>
        )}
        <div className={styles["total-row--payg"]}>
          <h6 className="mb-1 text-white">Total</h6>
          <h3 className="mb-0 text-white">${numeral(total).format("0.00")}</h3>
        </div>
      </div>
    </div>
  );
};
