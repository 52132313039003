// We need to explicit say the MIME type because of a bug in Safari
const VIDEO_FORMATS = ["video/mp4", "video/quicktime", ".mp4", ".mov"];
const AUDIO_FORMATS = ["audio/mpeg", "audio/m4a", "audio/ogg", "audio/*"];

export const ACCEPTED_FORMATS_FREE = [...VIDEO_FORMATS];
export const ACCEPTED_FORMATS_PRO_AND_HIGHER = [
  ...VIDEO_FORMATS,
  ...AUDIO_FORMATS
];

export const getAcceptedFormats = (
  isProOrHigher: boolean,
  isPayg: boolean
): string[] => {
  return isProOrHigher || isPayg
    ? ACCEPTED_FORMATS_PRO_AND_HIGHER
    : ACCEPTED_FORMATS_FREE;
};

const MIME_EXT_MAP: { [key: string]: string } = {
  "video/mp4": "mp4",
  "video/quicktime": "mov",
  "audio/mpeg": "mp3",
  "audio/m4a": "m4a",
  "audio/ogg": "oga",
  srt: "srt"
};

export const extensionForMimeType = (mimeType: string): string | undefined =>
  MIME_EXT_MAP[mimeType];
