import classNames from "classnames";
import React from "react";
import Skeleton from "react-loading-skeleton";
import { useAccounts } from "../../hooks/use-accounts";
import { usePlan } from "../../hooks/use-plan";
import { Badge } from "./badge";
import styles from "./badge.module.scss";

interface PlanBadgeProps {
  className?: string;
}
export const PlanBadge: React.FC<PlanBadgeProps> = ({ className }) => {
  const { plan, isTrial, isPayg } = usePlan();
  const { isLoading } = useAccounts();

  if (isLoading) {
    return (
      <div
        className={classNames(styles.loading, styles["badge--plan"], className)}
      >
        <Skeleton />
      </div>
    );
  }

  if (isPayg) {
    return (
      <Badge className={classNames(styles["badge--plan"], className)}>
        Pay as you go
      </Badge>
    );
  }

  if (isTrial) {
    return (
      <Badge className={classNames(styles["badge--plan"], className)}>
        Subly Trial
      </Badge>
    );
  }

  return (
    <Badge className={classNames(styles["badge--plan"], className)}>
      {plan}
    </Badge>
  );
};
