import classNames from "classnames";
import React, { useState } from "react";
import audioThumbnail from "../../assets/images/audio-thumbnail.jpg";
import uploadThumbnail from "../../assets/images/upload-thumbnail.png";
import { MediaFileType } from "../../interfaces/media";
import styles from "./thumbnail-image.module.scss";

interface ThumbnailImageProps {
  src?: string;
  alt?: string;
  mediaType?: MediaFileType;
  className?: string;
}
export const ThumbnailImage: React.FC<ThumbnailImageProps> = ({
  src,
  alt,
  mediaType = MediaFileType.Video,
  className
}) => {
  const [loaded, setLoaded] = useState(false);

  if (!src) {
    if (mediaType === MediaFileType.Audio) {
      src = audioThumbnail;
    } else {
      src = uploadThumbnail;
    }
  }

  return (
    <img
      src={src}
      className={classNames(
        "figure-img img-fluid",
        styles.thumbnail,
        className,
        {
          [styles["loaded"]]: loaded
        }
      )}
      onLoad={() => setLoaded(true)}
      alt={alt}
    />
  );
};
