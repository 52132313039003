import React from "react";
import { ManageMembers } from "../../containers/settings/manage-members/manage-members";
import { SettingsLayout } from "../../layouts/settings/settings.layout";

export const TeamPage: React.FC = () => {
  return (
    <SettingsLayout>
      <ManageMembers />
    </SettingsLayout>
  );
};
