import { useObservable } from "@mindspace-io/react";
import React from "react";
import {
  mediaHistoryRepository,
  mediaHistory
} from "../state/media/media-history.state";
import { useMediaEditor } from "./media-editor.context";

interface UndoRedoContext {
  hasPast: boolean;
  hasFuture: boolean;
  handleUndo: () => void;
  handleRedo: () => void;
}

const UndoRedoContext = React.createContext<UndoRedoContext>({
  hasPast: false,
  hasFuture: false,
  handleUndo: () => null,
  handleRedo: () => null
});

export const UndoRedoProvider: React.FC = ({ children }) => {
  const { handleUndoRedo } = useMediaEditor();

  // This enables tracking changes for undo/redo
  useObservable(mediaHistoryRepository.trackTranscriptionChanges$);
  useObservable(mediaHistoryRepository.trackConfigChanges$);

  const [hasPast] = useObservable(mediaHistory.hasPast$, false);
  const [hasFuture] = useObservable(mediaHistory.hasFuture$, false);

  const afterHistoryChange = () => {
    const { mediaConfig, transcriptions } = mediaHistoryRepository;

    handleUndoRedo(transcriptions, mediaConfig);
  };

  const handleUndo = () => {
    if (hasPast) {
      mediaHistory.undo();
      afterHistoryChange();
    }
  };

  const handleRedo = () => {
    if (hasFuture) {
      mediaHistory.redo();
      afterHistoryChange();
    }
  };

  return (
    <UndoRedoContext.Provider
      value={{
        hasPast,
        hasFuture,
        handleUndo,
        handleRedo
      }}
    >
      {children}
    </UndoRedoContext.Provider>
  );
};

export const useUndoRedo = (): UndoRedoContext => {
  const context = React.useContext(UndoRedoContext);

  if (context === undefined) {
    throw new Error("useUndoRedo must be used within a UndoRedoProvider");
  }

  return context;
};
