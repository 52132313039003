import React from "react";
import classNames from "classnames";
import numeral from "numeral";
import { usePlan } from "../../../../hooks/use-plan";
import { parseCreditToText } from "../../../../utils/plans";
import { getVAT } from "../../../../utils/vat-rates";
import { Coupon } from "../../../../api/billing.service";
import styles from "../plans.module.scss";
import { Divider } from "../../../../components/divider/divider";
import { Button } from "../../../../components/button/button";

interface TopUpTotalCardProps {
  quantity: number;
  amount: number;
  submitting: boolean;
  handlePurchase: () => void;
  purchaseEnabled: boolean;
  coupon?: Coupon;
  country?: string;
  credit?: number;
}
export const TopUpTotalCard: React.FC<TopUpTotalCardProps> = ({
  quantity,
  amount,
  submitting,
  handlePurchase,
  purchaseEnabled,
  coupon,
  country = "",
  credit = 0
}) => {
  const { credit: creditSeconds } = usePlan();

  const taxRate = getVAT(country) || 0;
  const showTax = taxRate > 0;
  const currentCredit = creditSeconds?.total ?? 0;
  const totalCredit = currentCredit + quantity * 60;

  const couponPercentOff = coupon?.percent_off ?? 0;
  const couponAmountOff = coupon?.amount_off ?? 0;
  const amountWithDiscount =
    amount * (1 - couponPercentOff / 100) - couponAmountOff / 100;

  const total = Math.max(amountWithDiscount * (1 + taxRate) - credit, 0);

  return (
    <div
      className={classNames(
        styles["card"],
        styles["total-card"],
        styles["total-card-topup"]
      )}
    >
      <h6>Top up minutes</h6>

      <div className={styles["total-topup-balance"]}>
        <div>
          <p className={styles["total-topup-balance-text"]}>
            Current balance:{" "}
            <span className={styles["total-topup-balance-quantity"]}>
              {" "}
              {parseCreditToText(currentCredit, true, false, false)}
            </span>
          </p>
        </div>
        <div>
          <p className={styles["total-topup-balance-text"]}>
            Balance after top-up:
            <span className={styles["total-topup-balance-quantity"]}>
              {" "}
              {parseCreditToText(totalCredit, true, false, false)}
            </span>
          </p>
        </div>
      </div>

      <div className={styles["topup-rollover"]}>
        <p>
          Topped up minutes will roll over to next month’s minutes if not used
          up.
        </p>
      </div>

      <Divider className="bg-white" />

      <div className={styles["topup-total"]}>
        <div className={classNames(styles["topup-total-row"], "mb-2")}>
          <p>Minutes</p>
          <p>{quantity}</p>
        </div>
        <div className={styles["topup-total-row"]}>
          <p>Subtotal</p>
          <p>${numeral(amount).format("0.00")}</p>
        </div>
        {coupon && (
          <div className={styles["topup-total-row"]}>
            <p>
              Discount{" "}
              {coupon?.percent_off && coupon?.percent_off > 0 && (
                <>({numeral(coupon.percent_off).format("0")}%)</>
              )}
            </p>
            <p>
              {" "}
              {couponPercentOff ? (
                <>
                  - ${numeral((amount * couponPercentOff) / 100).format("0.00")}
                </>
              ) : (
                <>- ${numeral(couponAmountOff / 100).format("0.00")}</>
              )}
            </p>
          </div>
        )}
        {credit > 0 && (
          <div className={styles["topup-total-row"]}>
            <p>Credit</p>
            <p>-${numeral(credit).format("0.00")}</p>
          </div>
        )}
        {showTax && (
          <div className={styles["topup-total-row"]}>
            <p>Tax ({numeral(taxRate * 100).format("0")}%)</p>
            <p>${numeral(amountWithDiscount * taxRate).format("0.00")}</p>
          </div>
        )}

        <div className={classNames(styles["topup-total-row"], "mt-2")}>
          <p>Total</p>
          <span className={styles["topup-total-row-grand-total"]}>
            ${numeral(total).format("0.00")}
          </span>
        </div>

        <Button
          primary
          className={styles["pay-btn"]}
          disabled={purchaseEnabled}
          onClick={handlePurchase}
          loading={submitting}
          type="submit"
        >
          Pay now
        </Button>
      </div>
    </div>
  );
};
