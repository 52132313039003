import * as Sentry from "@sentry/react";
import { AxiosError } from "axios";
import { EN } from "../assets/i18n/en";
import { notificationError } from "../components/notification";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handleError = (e: AxiosError<any>): void => {
  Sentry.captureException(e);
  console.error(e);

  const responseStatus = e.response?.status;
  const errDescription = e.response?.data?.description;
  const errMessage = e.response?.data?.message;

  if (responseStatus === 422 && errDescription) {
    notificationError(errDescription);
    return;
  }
  if (responseStatus === 500 && errMessage.includes("Private video")) {
    notificationError(EN.error.PrivateVideo);
    return;
  }

  notificationError(EN.error.defaultMessage);
};
