import { differenceInDays } from "date-fns";
import React from "react";
import { Link } from "react-router-dom";
import { usePlan } from "../../hooks/use-plan";
import { pluralize } from "../../utils/strings";
import { Modal, ModalProps } from "../modal/modal";
import styles from "./trial-corner-modal.module.scss";
import { PLANS_PATH } from "../../routes";
import { CancelModal } from "../../components/plans/cancel-modal";
import { useModal } from "../../hooks/use-modal";
import { ModalType } from "../../interfaces/modal-type";
import { SublyPlan } from "../../interfaces/billing";

interface TrialCornerModalProps extends ModalProps {
  subscriptionId: string;
  plan: SublyPlan;
  endDate: Date;
}
export const TrialCornerModal: React.FC<TrialCornerModalProps> = (props) => {
  return (
    <Modal hideBackground className={styles.modal}>
      <TrialCornerModalContainer {...props} />
    </Modal>
  );
};

const TrialCornerModalContainer: React.FC<TrialCornerModalProps> = ({
  subscriptionId,
  plan,
  endDate
}) => {
  const { credit } = usePlan();
  const [showCancelModal, hideCancelModal] = useModal(ModalType.CancelModal);
  const daysLeft = differenceInDays(new Date(endDate), new Date()) + 1;

  const minutes = credit?.total ?? 0;
  const minutesLeft = Math.floor(minutes / 60);

  const handleCancelTrial = () => {
    showCancelModal(
      <CancelModal
        subscriptionId={subscriptionId}
        cancelPlan={SublyPlan.Free}
        currentPlan={plan}
        onDismiss={hideCancelModal}
        isTrial
      />
    );
  };

  return (
    <>
      <p className="m-0 smaller">
        {daysLeft} {pluralize(daysLeft, "day")} / {minutesLeft}{" "}
        {pluralize(minutesLeft, "minute")} remaining in trial
      </p>
      <div className="row center">
        <div className="col pr-1">
          <Link
            to={{
              pathname: PLANS_PATH
            }}
            className="smaller text-interactive d-block"
          >
            Browse plans
          </Link>
        </div>
        <div className="col pl-1">
          <Link
            to="#"
            onClick={handleCancelTrial}
            className="smaller text-tertiary d-block"
          >
            Cancel trial
          </Link>
        </div>
      </div>
    </>
  );
};
