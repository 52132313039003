import classNames from "classnames";
import React from "react";
import styles from "./radio-input.module.scss";

export const RadioInput: React.FC<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >
> = ({ children, className, ...props }) => {
  return (
    <div
      className={classNames("custom-control custom-radio d-flex", className)}
    >
      <input type="radio" className="custom-control-input" {...props} />
      <label
        className={classNames("custom-control-label", styles["radio-label"])}
        htmlFor={props.id}
      >
        {children}
      </label>
    </div>
  );
};
