import { mdiChevronDown } from "@mdi/js";
import Icon from "@mdi/react";
import classNames from "classnames";
import React, { useRef } from "react";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu
} from "../../../components/dropdown/dropdown";
import { HelpIcon } from "../../../components/help-icon/help-icon";
import { InvitationHelpModal } from "../../../components/invitation-help-modals/invitation-help-modals";
import { useModal } from "../../../hooks/use-modal";
import { ModalType } from "../../../interfaces/modal-type";
import { RoleName } from "../../../interfaces/account";
import styles from "../onboarding.module.scss";

interface RoleNameDropdownProps {
  value: RoleName;
  onChange: (role: RoleName) => void;
  isButtonSmall?: boolean;
  isAdmin?: boolean;
}
export const RoleNameDropdown: React.FC<RoleNameDropdownProps> = ({
  value,
  onChange,
  isButtonSmall,
  isAdmin
}) => {
  const dropdownRef = useRef(null);
  const label = value === RoleName.Editor ? "Editor" : "Admin";
  const [showModal, hideModal] = useModal(ModalType.InvitationHelp);
  const showHelp = () => {
    showModal(<InvitationHelpModal closeModal={hideModal} />);
  };

  return (
    <div ref={dropdownRef} className="dropdown">
      <Dropdown>
        <DropdownButton dropdownEl={dropdownRef}>
          <span className={classNames({ small: isButtonSmall })}>{label}</span>{" "}
          <Icon path={mdiChevronDown} size="1.2em" />
        </DropdownButton>

        <DropdownMenu
          hasOverflow
          isRight
          animate="down"
          className={styles["dropdown-menu"]}
        >
          <DropdownItem
            selected={value === RoleName.Editor}
            className={styles["dropdown-item"]}
            onClick={() => onChange(RoleName.Editor)}
          >
            Editor
            <HelpIcon showHelp={showHelp} />
          </DropdownItem>
          <DropdownItem
            selected={value === RoleName.FullAccess}
            className={styles["dropdown-item"]}
            onClick={() => onChange(RoleName.FullAccess)}
            disabled={!isAdmin}
          >
            Admin
            <HelpIcon showHelp={showHelp} />
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};
