import { mdiLoading } from "@mdi/js";
import Icon from "@mdi/react";
import React from "react";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { verificationResend, VerifyUserParams } from "../../api/auth.service";
import { Button } from "../../components/button/button";
import { notificationSuccess } from "../../components/notification";
import { SetPassword } from "../../components/set-password/set-password";
import { AuthContext } from "../../contexts/auth.context";
import { LOGIN_PATH } from "../../routes";
import styles from "./auth.module.scss";

interface ResponseData {
  alreadyVerified?: boolean;
}

interface UserVerificationProps {
  external: boolean;
}

export const UserVerification: React.FC<UserVerificationProps> = ({
  external
}) => {
  const { id = "", code = "" } = useParams();
  const [needsPassword] = React.useState(external);
  const [isLoading, setLoading] = React.useState(!external);
  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [responseData, setResponseData] = React.useState<ResponseData>();
  const [alertMessage, setAlertMessage] = React.useState("");
  const { verifyUser } = React.useContext(AuthContext);
  const { search } = useLocation();

  const handleVerifyUser = async (params: VerifyUserParams) => {
    try {
      setLoading(true);

      const { success, data, alertMessage } = await verifyUser(params);
      setLoading(false);
      setResponseData(data);
      setAlertMessage(alertMessage);

      if (success) {
        notificationSuccess("Verification was successful. Please login.");
      }

      setError(!success);
      setSuccess(success);
    } catch (e) {
      setLoading(false);
      setError(true);
    }
  };

  React.useEffect(() => {
    if (!needsPassword) {
      // Trigger verification
      handleVerifyUser({ code, id });
    }
  }, [code, id, needsPassword]);

  if (error) {
    return <VerificationError id={id} message={alertMessage} />;
  }

  const renderContent = () => {
    if (responseData?.alreadyVerified) {
      return (
        <>
          <p className="text-center">
            Your email was already confirmed. Please login to your account.
          </p>
          <Button primary to={{ pathname: LOGIN_PATH, search }}>
            Go to Login
          </Button>
        </>
      );
    }

    if (success) {
      return (
        <>
          <h4 className="text-center mb-4">Verification was successful</h4>
          <p className="text-center">
            Your email was confirmed. Please login to your account.
          </p>
          <Navigate to={{ pathname: LOGIN_PATH, search }} />
        </>
      );
    }

    if (needsPassword) {
      return (
        <SetPassword
          loading={isLoading}
          onSubmit={async ({ password }) => {
            await handleVerifyUser({
              code,
              id,
              password
            });
          }}
        />
      );
    }

    // default is triggered message
    return (
      <>
        <h4 className="text-center mb-4">We are verifying your account</h4>
        <p className="text-center">
          <Icon path={mdiLoading} spin size="1.2rem" className="mr-2" /> Please
          wait a moment...
        </p>
      </>
    );
  };

  return <div className={styles.container}>{renderContent()}</div>;
};

interface VerificationErrorProps {
  id: string;
  message: string;
}
const VerificationError: React.FC<VerificationErrorProps> = ({
  id,
  message
}) => {
  const [hasResentVerification, setResentVerification] = React.useState(false);

  const handleResendVerification = (id: string) => {
    verificationResend(id);
    setResentVerification(true);
  };

  if (hasResentVerification) {
    return (
      <VerificationErrorContainer>
        <p className="text-center">
          We have just emailed you a new verification code, please check your
          email.
        </p>
      </VerificationErrorContainer>
    );
  }

  if (
    message.includes("limit exceeded") ||
    message.includes("does not exist")
  ) {
    return (
      <VerificationErrorContainer>
        <p className="text-center">{message}</p>
        <Button primary to="mailto:support@getsubly.com" type="blank">
          Contact support
        </Button>
      </VerificationErrorContainer>
    );
  }

  return (
    <VerificationErrorContainer>
      <p className="text-center">{message}</p>

      <Button
        primary
        disabled={hasResentVerification}
        onClick={() => handleResendVerification(id)}
      >
        Resend verification code
      </Button>
    </VerificationErrorContainer>
  );
};

const VerificationErrorContainer: React.FC = ({ children }) => {
  return (
    <div className={styles.container}>
      <h4 className="text-center mb-4">Verification failed</h4>
      {children}
    </div>
  );
};
