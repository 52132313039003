import { AccountState } from "../state/account";
import { useObservable } from "@mindspace-io/react";
import { foldersQuery } from "../state/folders/folders.query";
import { Folder, foldersStore } from "../state/folders/folders.store";
import { mediaStore } from "../state/media";

interface UseFolders {
  allFolders: Folder[];
  privateFolders: Folder[];
  sharedFolders: Folder[];
  selectedId?: string | null;
  selectFolder: (id: string | null) => void;
  activeFolder?: Folder;
  getFolderById: (id?: string) => Folder | undefined;
  countFiles: (folders: Folder[]) => number;
  countPrivateAllFiles: (currentAccount: AccountState | undefined) => number;
  countSharedWithMeAllFiles: (
    currentAccount: AccountState | undefined
  ) => number;
}

export function useFolders(): UseFolders {
  const [allFolders] = useObservable(
    foldersQuery.selectAll(),
    foldersQuery.getAll()
  );
  const [privateFolders] = useObservable(
    foldersQuery.selectPrivateFolders(),
    foldersQuery.privateFolders
  );
  const [sharedFolders] = useObservable(
    foldersQuery.selectSharedFolders(),
    foldersQuery.sharedFolders
  );
  const [selectedId] = useObservable(
    foldersQuery.selectActiveId(),
    foldersQuery.getActiveId()
  );
  const [activeFolder] = useObservable(
    foldersQuery.selectActive(),
    foldersQuery.getActive()
  );

  const selectFolder = (id: string | null) => {
    mediaStore.update({ activeFolderId: id });
    foldersStore.setActive(id);
  };

  const getFolderById = (id?: string): Folder | undefined => {
    if (!id) {
      return;
    }

    return foldersQuery.getEntity(id);
  };

  const countFiles = (folders: Folder[]): number => {
    const folderCounts = folders.flatMap((x) => x.count || 0);
    return folderCounts.reduce((a, b) => a + b, 0);
  };

  const countPrivateAllFiles = (
    currentAccount: AccountState | undefined
  ): number => {
    const allMediaCount = currentAccount?.mediaCount ?? 0;
    return allMediaCount - countFiles(sharedFolders);
  };

  const countSharedWithMeAllFiles = (
    currentAccount: AccountState | undefined
  ): number => {
    return currentAccount?.mediaSharedWithMeCount ?? 0;
  };

  return {
    allFolders,
    privateFolders,
    sharedFolders,
    selectedId,
    selectFolder,
    activeFolder,
    getFolderById,
    countFiles,
    countPrivateAllFiles,
    countSharedWithMeAllFiles
  };
}
