import React, { useContext } from "react";
import { PricingIntervalToggleContext } from "../../contexts/interval-toggle.context";
import { PlanInterval } from "../../interfaces/billing";
import {
  SegmentedControl,
  SegmentedOption
} from "../segmented-control/segmented-control";
import styles from "./interval-toggle.module.scss";

export const IntervalToggle: React.FC = () => {
  const { pricingToggle, setPricingToggle } = useContext(
    PricingIntervalToggleContext
  );
  const options: SegmentedOption<PlanInterval>[] = [
    {
      id: 0,
      label: "Monthly",
      value: PlanInterval.Monthly
    },
    {
      id: 1,
      label: "Yearly",
      value: PlanInterval.Yearly
    }
  ];

  const value = options.find((o) => o.value === pricingToggle) ?? options[0];

  return (
    <>
      <SegmentedControl
        options={options}
        value={value}
        onChange={({ value }) => setPricingToggle(value)}
        className={styles["pricing-toggle"]}
        selectedClassName={styles["selected"]}
      />
    </>
  );
};
