import classNames from "classnames";
import React from "react";
import {
  CloseIcon,
  JpgIcon,
  MovIcon,
  Mp3Icon,
  Mp4Icon,
  PdfIcon,
  SrtIcon,
  UnkIcon
} from "../../../components/icons/icons";
import { FaqButton } from "../../../components/faq-button/faq-button";
import { ToolTip } from "../../tooltip/tooltip";
import { UploadErrorTypes } from "../../../state/upload";
import { extensionForMimeType } from "../../../utils/mime-types";
import { parseCreditToText } from "../../../utils/plans";
import styles from "./files-list.module.scss";
import { LoadingIcon } from "../../../components/loading-icon/loading-icon";
import { formatBytes } from "../../../utils/storage-size";
import { LanguageDropdown } from "../../dropdown/language-dropdown";
import { Transcription } from "@getsubly/common";

export interface UploadListFile {
  id: string;
  name: string;
  type?: string;
  duration?: number;
  size?: number;
  error?: UploadErrorTypes | string;
  warning?: string;
  disableTooltip?: boolean;
}

export interface UploadListTranscriptFile extends UploadListFile {
  transcription?: Transcription;
  language?: string;
  needsTrim?: boolean;
}

interface FilesListProps {
  files: UploadListFile[];
  isChecking?: boolean;
  removeFile: (fileId: string) => void;
  hasLanguageDropdown?: boolean;
  onLanguageChange?: (id: string, language: string) => void;
}
export const FilesList: React.FC<FilesListProps> = ({
  files,
  isChecking,
  removeFile,
  hasLanguageDropdown,
  onLanguageChange
}) => {
  if (!files) {
    return <p className="small mb-0">No files selected</p>;
  }

  const errorsWarningFirstSort = (a: UploadListFile, b: UploadListFile) => {
    if (a?.error) {
      return -1;
    }
    if (b?.error) {
      return 1;
    }
    if (a?.warning) {
      return -1;
    }
    if (b?.warning) {
      return 1;
    }
    return 0;
  };

  const list = files
    .sort(errorsWarningFirstSort)
    .map((f) => (
      <FileItem
        key={f.id}
        file={f}
        remove={() => removeFile(f.id)}
        hasLanguageDropdown={hasLanguageDropdown}
        onLanguageChange={onLanguageChange}
      />
    ));

  return (
    <div>
      {list}
      {isChecking && (
        <div className={classNames("d-flex m-1")}>
          <p className="small m-0">
            <LoadingIcon /> Checking file...
          </p>
        </div>
      )}
    </div>
  );
};

interface FilesItemProps {
  file: UploadListFile;
  remove: () => void;
  hasLanguageDropdown?: boolean;
  onLanguageChange?: (id: string, language: string) => void;
}
const FileItem: React.FC<FilesItemProps> = ({
  file,
  remove,
  hasLanguageDropdown,
  onLanguageChange
}) => {
  const fileDuration = parseCreditToText(
    file.duration ?? 0,
    false,
    false,
    true
  );

  const fileSize = formatBytes(file.size ?? 0);
  const fileSizeText = `${fileSize.size} ${fileSize.units}`;

  const iconStyle = file.error
    ? styles.error
    : file.warning
    ? styles.warning
    : styles.success;
  const closeIconStyle = file.error
    ? styles.errorCloseIcon
    : file.warning
    ? styles.warningCloseIcon
    : styles.successCloseIcon;

  const HEVCFileTooltip =
    "HEVC files are not supported on all platforms, we recommend the following to convert the file:\n1. Open Quicktime Player\n2. File --> export as --> 4K\n3. In the modal select “Greater Compatibility (H.264)”\n4. Save";
  const languageDropdownVisible =
    hasLanguageDropdown && onLanguageChange && !file.error;
  return (
    <div
      className={classNames("d-flex align-items-center mb-3", {
        [styles["file-item--hasLanguageDropdown"]]: hasLanguageDropdown
      })}
    >
      {languageDropdownVisible && (
        <LanguageDropdown
          onLanguageChange={onLanguageChange}
          fileId={file.id}
        />
      )}
      <FileIcon type={file.type || "mp4"} iconStyle={iconStyle} />

      <label
        className={classNames(
          "ml-2 mb-0 smaller text-truncate w-25",
          styles["text-secondary"]
        )}
      >
        {file.name}
      </label>

      {Boolean(file.error || file.warning) && (
        <label className={classNames("mt-1 smaller", iconStyle)}>
          {file.error || file.warning}
        </label>
      )}
      {file.warning && !file.disableTooltip && (
        <ToolTip text={HEVCFileTooltip} className={styles["tooltip"]}>
          <FaqButton className="ml-1" />
        </ToolTip>
      )}

      <label className={classNames("ml-auto mb-0", styles["text-secondary"])}>
        <span
          className={classNames("smaller mr-2", { [iconStyle]: file.error })}
        >
          {(file.size ?? 0) > 0 && fileSizeText}
        </span>
        <span className={classNames("smaller", { [iconStyle]: file.error })}>
          {(file.duration ?? 0) > 0 && fileDuration}
        </span>
      </label>

      <CloseIcon
        className={classNames("close", styles.closeIcon, closeIconStyle)}
        onClick={remove}
      />
    </div>
  );
};

interface FileIconProps {
  type: string;
  iconStyle: string;
}
const FileIcon: React.FC<FileIconProps> = ({ type, iconStyle }) => {
  const extension = extensionForMimeType(type);

  switch (extension) {
    case "mp4":
      return <Mp4Icon className={iconStyle} />;
    case "mp3":
      return <Mp3Icon className={iconStyle} />;
    case "mov":
      return <MovIcon className={iconStyle} />;
    case "srt":
      return <SrtIcon className={iconStyle} />;
    case "pdf":
      return <PdfIcon className={iconStyle} />;
    case "jpg":
      return <JpgIcon className={iconStyle} />;
    default:
      return <UnkIcon className={iconStyle} />;
  }
};
