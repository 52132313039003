import React from "react";

interface SelectedFilesContext {
  selectedFiles: string[];
  setSelectedFiles: React.Dispatch<React.SetStateAction<string[]>>;
}

export const SelectedFilesContext = React.createContext<SelectedFilesContext>({
  selectedFiles: [],
  setSelectedFiles: () => null
});

export const SelectedFilesProvider: React.FC = ({ children }) => {
  const [selectedFiles, setSelectedFiles] = React.useState<string[]>([]);

  return (
    <SelectedFilesContext.Provider
      value={{
        selectedFiles,
        setSelectedFiles
      }}
    >
      {children}
    </SelectedFilesContext.Provider>
  );
};

export const useSelectedFiles = (): SelectedFilesContext =>
  React.useContext(SelectedFilesContext);
