import { mdiArrowLeft } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { getCustomer } from "../../../api/billing.service";
import { InvoiceTable } from "../../../containers/settings/invoices-card/invoice-table";
import { AuthContext } from "../../../contexts/auth.context";
import { SettingsLayout } from "../../../layouts/settings/settings.layout";
import { BILLING_PATH } from "../../../routes";

export const InvoicesPage: React.FC = () => {
  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (!user) {
      return;
    }

    getCustomer({ force: false });
  }, [user]);

  return (
    <SettingsLayout>
      <div className="container-fluid my-2">
        <div className="row">
          <div className="col mt-3 mb-2">
            <h4 className="title text-black d-flex align-items-center">
              <Link to={BILLING_PATH}>
                <Icon
                  path={mdiArrowLeft}
                  size="25px"
                  color="black"
                  className="mr-3"
                />
              </Link>{" "}
              Invoices
            </h4>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <InvoiceTable />
          </div>
        </div>
      </div>
    </SettingsLayout>
  );
};
