import {
  Partner,
  PartnerFlow,
  COMEDY_CROWD,
  LSFF,
  LOYAL,
  CUTTLES,
  TRW
} from "@getsubly/common";
import classNames from "classnames";
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { SignUpParams } from "../../../api/auth.service";
import { EN } from "../../../assets/i18n/en";
import sent from "../../../assets/images/partners/sent.svg";
import { Button } from "../../../components/button/button";
import { notificationError } from "../../../components/notification";
import { AuthContext } from "../../../contexts/auth.context";
import { LOGIN_PATH, PRIVACY_URL, TERMS_URL } from "../../../routes";
import { emailPattern, passwordPattern } from "../../../utils/regex-patterns";
import { PasswordRules } from "../../../components/password-rules/password-rules";

interface SignUpPartnerForm {
  givenName: string;
  familyName: string;
  email: string;
  repEmail: string;
  password: string;
}

interface SignUpPartnerProps {
  partner: Partner;
}
export const SignUpPartner: React.FC<SignUpPartnerProps> = ({ partner }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const { signUp } = useContext(AuthContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch
  } = useForm<SignUpPartnerForm>();

  const onSubmit = async (data: SignUpPartnerForm) => {
    setLoading(true);

    try {
      const params: SignUpParams = {
        email: data.email,
        name: `${data.givenName} ${data.familyName}`,
        givenName: data.givenName,
        familyName: data.familyName,
        password: data.password,
        referralCode: data.repEmail,
        partner: partner.slug
      };

      const { useAlert, alertMessage } = await signUp(params);

      if (useAlert) {
        throw new Error(alertMessage);
      }

      setLoading(false);
      setSuccess(true);
    } catch (e) {
      notificationError(e.message || EN.error.defaultMessage);

      setLoading(false);
    }
  };

  if (success) {
    return (
      <div className="auth-form">
        <SignUpSuccess flow={partner.flow} />
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="auth-form">
      <div className="cta-top-right">
        <p>
          Already have an account?{" "}
          <Link className="cta-btn" to={LOGIN_PATH}>
            Sign In
          </Link>
        </p>
      </div>

      <PartnerHeadlineTitle partner={partner} />

      <div className="form-container">
        <div className="form-row">
          <div className="form-group col-6">
            <label htmlFor="givenName">First Name</label>
            <input
              id="givenName"
              placeholder="John"
              className={classNames("form-control", {
                "is-invalid": errors.givenName
              })}
              autoCapitalize="word"
              {...register("givenName", { required: true })}
            />
          </div>
          <div className="form-group col-6">
            <label htmlFor="familyName">Last Name</label>
            <input
              id="familyName"
              placeholder="Smith"
              className={classNames("form-control", {
                "is-invalid": errors.familyName
              })}
              autoCapitalize="word"
              {...register("familyName", { required: true })}
            />
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            id="email"
            placeholder="john.smith@gmail.com"
            className={classNames("form-control", {
              "is-invalid": errors.email
            })}
            autoCapitalize="off"
            type="email"
            {...register("email", { required: true, pattern: emailPattern })}
          />
        </div>

        {partner.flow === PartnerFlow.Representative ? (
          <div className="form-group">
            <label htmlFor="repEmail">
              {partner.name} Representative Email
            </label>
            <input
              placeholder={`jenny.appleseed@gmail.com`}
              className={classNames("form-control", {
                "is-invalid": errors.repEmail
              })}
              autoCapitalize="off"
              type="email"
              {...register("repEmail", {
                required: true,
                pattern: emailPattern
              })}
            />
          </div>
        ) : (
          <>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input
                id="password"
                placeholder="Input your password"
                className={classNames("form-control", {
                  "is-invalid": errors.password
                })}
                type="password"
                {...register("password", {
                  required: true,
                  pattern: passwordPattern
                })}
              />
              <PasswordRules value={watch("password")} />
            </div>
          </>
        )}

        <Button
          primary
          className="btn-block mt-3"
          type="submit"
          loading={loading}
        >
          Register
        </Button>

        <PartnerTermsAndConditions partner={partner} />
      </div>
    </form>
  );
};

interface SignUpSuccessProps {
  flow?: PartnerFlow;
}
export const SignUpSuccess: React.FC<SignUpSuccessProps> = ({ flow }) => {
  if (flow === PartnerFlow.Representative) {
    return (
      <div className="success">
        <img src={sent} alt="Sign up succesful" />
        <p>
          Thanks for registering! We have sent you an email to complete your
          sign up by creating a password. Please check your email to access your
          new Subly account.
        </p>
      </div>
    );
  }

  return (
    <div className="success">
      <img src={sent} alt="Sign up succesful" />
      <h2 className="m-v-0 p-t-15 text-center">Thanks for signing up</h2>
      <h5 className="m-v-0 p-v-15 text-center">
        Please check your email. We have just sent you a verification link.
      </h5>
      <Link className="btn btn-primary btn-block" to={LOGIN_PATH}>
        Go back
      </Link>
    </div>
  );
};

interface PartnerHeadlineTitleProps {
  partner: Partner;
}
const PartnerHeadlineTitle: React.FC<PartnerHeadlineTitleProps> = ({
  partner
}) => {
  switch (partner.slug) {
    case COMEDY_CROWD.slug:
      return (
        <h2 className="title mt-5">
          Get a Subly account to add subtitles to your next comedy video
        </h2>
      );
    case LSFF.slug:
    case LOYAL.slug:
    case CUTTLES.slug:
    case TRW.slug:
      return (
        <h2 className="title mt-5">
          Sign up to add subtitles to your next video
        </h2>
      );

    default:
      return (
        <h2 className="title mt-5">
          Get a Subly account to add subtitles to your next {partner.name} video
        </h2>
      );
  }
};
interface PartnerTermsAndConditionsProps {
  partner: Partner;
}
const PartnerTermsAndConditions: React.FC<PartnerTermsAndConditionsProps> = ({
  partner
}) => {
  if (partner.slug === COMEDY_CROWD.slug) {
    return (
      <p className="mt-3 mb-4 terms text-center">
        By clicking "Register", I agree to Subly's{" "}
        <a href={TERMS_URL} target="_blank" rel="noopener noreferrer">
          Terms of Service
        </a>{" "}
        and{" "}
        <a href={PRIVACY_URL} target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </a>
        . Subly will share the registration data with {partner.privacyCompany}{" "}
        so that {partner.privacyCompany} can understand usage and subtitling
        needs of the community. Learn more about{" "}
        <a href={partner.privacyURL} target="_blank" rel="noopener noreferrer">
          {partner.privacyCompany}’s Privacy Policy
        </a>
        .
      </p>
    );
  }

  if (partner.slug === CUTTLES.slug) {
    return (
      <p className="mt-3 mb-4 terms text-center">
        By clicking "Register", I agree to Subly's{" "}
        <a href={TERMS_URL} target="_blank" rel="noopener noreferrer">
          Terms of Service
        </a>{" "}
        and{" "}
        <a href={PRIVACY_URL} target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </a>
        . Subly will share the registration data with {partner.privacyCompany}{" "}
        so that {partner.privacyCompany} can understand usage and subtitling
        needs of the community. Learn more about{" "}
        <a href={partner.privacyURL} target="_blank" rel="noopener noreferrer">
          {partner.privacyCompany} Privacy Policy
        </a>
        .
      </p>
    );
  }

  return (
    <p className="mt-3 mb-4 terms text-center">
      By clicking "Register", I agree to Subly's{" "}
      <a href={TERMS_URL} target="_blank" rel="noopener noreferrer">
        Terms of Service
      </a>{" "}
      and{" "}
      <a href={PRIVACY_URL} target="_blank" rel="noopener noreferrer">
        Privacy Policy
      </a>
      . Subly will share the registration data with {partner.privacyCompany} so
      that {partner.privacyCompany} can understand usage and subtitling needs
      for clients.{" "}
      {partner.privacyURL && (
        <>
          Learn more about{" "}
          <a
            href={partner.privacyURL}
            target="_blank"
            rel="noopener noreferrer"
          >
            {partner.privacyCompany}’s Privacy Policy
          </a>
          .
        </>
      )}
    </p>
  );
};
