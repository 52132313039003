import React from "react";
import { Icon } from "@mdi/react";
import { IconProps } from "@mdi/react/dist/IconProps";
import classNames from "classnames";

import styles from "./icon-button.module.scss";

type IconButtonProps = {
  onClick: () => void;
  className?: string;
} & IconProps;

export const IconButton: React.FC<IconButtonProps> = ({
  onClick,
  className,
  ...iconProps
}) => (
  <button onClick={onClick} className={classNames(styles.button, className)}>
    <Icon {...iconProps} />
  </button>
);
