import classNames from "classnames";
import React, { useContext, useEffect, useState } from "react";
import { useAccounts } from "../hooks/use-accounts";
import { usePlan } from "../hooks/use-plan";
import { Subscription, SubscriptionStatus } from "../interfaces/billing";
import { isMonthlyPlan } from "../utils/plans";
import { getStorageInfo } from "../utils/storage-size";
import { AuthContext, PartnerOptions } from "./auth.context";

export enum BannerType {
  PartnerWelcome = "PartnerWelcome",
  PartnerUpgrade = "PartnerUpgrade",
  PaymentOverdue = "PaymentOverdue",
  Trial = "Trial",
  StorageLow = "StorageLow",
  SpeakerStyleCreated = "SpeakerStyleCreated",
  SaveYearly = "SaveYearly"
}
interface TopBannerContext {
  isTopBannerVisible: boolean;
  setTopBannerVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setHideTopBanner: React.Dispatch<React.SetStateAction<boolean>>;
  bannerType?: BannerType;
}

export const TopBannerContext = React.createContext<TopBannerContext>({
  isTopBannerVisible: false,
  setTopBannerVisible: () => null,
  setHideTopBanner: () => null
});

export const TopBannerProvider: React.FC = ({ children }) => {
  const { partner } = useContext(AuthContext);
  const { isTrial, plan, isPayg } = usePlan();
  const { subscription, isLoaded } = useAccounts();
  const { isStorageLow } = getStorageInfo(plan);

  const monthlyPlan = isMonthlyPlan(plan);

  const bannerType = getBannerType({
    partner,
    isPayg,
    isTrial,
    subscription,
    isStorageLow,
    monthlyPlan
  });

  const [isTopBannerVisible, setTopBannerVisible] = useState(false);
  const [hideTopBanner, setHideTopBanner] = useState(false);

  useEffect(() => {
    if (isLoaded) {
      setTopBannerVisible(!hideTopBanner && Boolean(bannerType));
    }
  }, [isLoaded, bannerType, hideTopBanner]);

  return (
    <TopBannerContext.Provider
      value={{
        isTopBannerVisible,
        setTopBannerVisible,
        setHideTopBanner,
        bannerType
      }}
    >
      <div className={classNames({ "has-top-bar": isTopBannerVisible })}>
        {children}
      </div>
    </TopBannerContext.Provider>
  );
};

interface GetBannerType {
  isPayg: boolean;
  isTrial: boolean;
  partner?: PartnerOptions;
  subscription?: Subscription;
  isStorageLow?: boolean;
  monthlyPlan?: boolean;
}
const getBannerType = ({
  isPayg,
  isTrial,
  partner,
  subscription,
  isStorageLow,
  monthlyPlan
}: GetBannerType): BannerType | undefined => {
  const { deal } = useAccounts();

  if (
    subscription?.status === SubscriptionStatus.PastDue ||
    subscription?.status === SubscriptionStatus.Unpaid
  ) {
    return BannerType.PaymentOverdue;
  }

  if (isTrial) {
    return BannerType.Trial;
  }

  if (isStorageLow) {
    return BannerType.StorageLow;
  }

  if (monthlyPlan && !isTrial && !deal && !isPayg) {
    return BannerType.SaveYearly;
  }

  if (partner?.name) {
    return BannerType.PartnerUpgrade;
  }

  if (partner?.showBanner) {
    return BannerType.PartnerWelcome;
  }

  return undefined;
};
