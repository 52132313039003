import { MediaConfig } from "@getsubly/common";
import axios from "axios";
import { EN } from "../assets/i18n/en";
import { notificationError } from "../components/notification";
import config from "../config";
import { MediaEditor, TranscriptionMap } from "../interfaces/media-editor";
import { assetsStore } from "../state/assets/assets.store";
import { commentsStore } from "../state/comments";
import { mediaStore } from "../state/media";
import { transformToBasicMedia } from "../utils/media-functions";
import { getAccessToken } from "./auth.service";
import { handleError } from "./handle-error";
import { updateMediaProgressStatus } from "./media.service";

const baseURL = `${config.apiUrl}/api/v1`;

const load = async (mediaId: string): Promise<MediaEditor | undefined> => {
  try {
    const { editorLoading } = mediaStore.getValue();

    if (editorLoading) {
      return;
    }

    mediaStore.update({ editorLoading: true });

    const { data } = await axios.get<MediaEditor>(`/media/${mediaId}/editor`, {
      baseURL: baseURL,
      headers: { "x-access-token": await getAccessToken() }
    });

    return data;
  } catch (e) {
    if (e.response?.status === 404) {
      notificationError(EN.media.NotFound);
    } else {
      handleError(e);
    }
  } finally {
    mediaStore.update({ editorLoading: false });
  }
};

export const loadMediaEditor = async (
  mediaId: string
): Promise<{
  accountId: string;
  assConfig: MediaConfig;
  transcriptions: TranscriptionMap;
  isTranscribing: boolean;
}> => {
  assetsStore.update({ loading: true });
  commentsStore.update({ loading: true });

  const data = await load(mediaId);

  if (!data) {
    throw Error("Failed to load media");
  }
  const { account, media: resMedia, assets, comments } = data;

  // Media
  const basicMedia = transformToBasicMedia(resMedia, {
    plan: account?.plan
  });

  mediaStore.upsert(mediaId, basicMedia);

  await updateMediaProgressStatus([basicMedia]);

  // Assets
  assetsStore.set(assets);
  assetsStore.update({ loading: false });

  // Comments
  commentsStore.set(comments);
  commentsStore.update({ loading: false });

  mediaStore.update({ editorLoaded: true });
  mediaStore.setActive(mediaId);
  mediaStore.ui.setActive(mediaId);

  mediaStore.updateActive({
    assConfig: resMedia.assConfig
  });
  mediaStore.ui.updateActive({
    accountSettings: account.settings
  });

  const { media, transcriptions } = data;

  if (!media) {
    throw Error("Failed to load media");
  }

  return {
    accountId: media.accountId,
    assConfig: media.assConfig,
    transcriptions,
    isTranscribing: basicMedia.isTranscribing
  };
};
