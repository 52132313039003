import {
  ActiveState,
  EntityState,
  EntityStore,
  EntityUIStore,
  StoreConfig
} from "@datorama/akita";

export enum DefaultFoldersId {
  All = "all",
  AllPrivate = "allPrivate",
  AllShared = "allShared",
  AllSharedWithMe = "allSharedWithMe"
}
export type FolderId = DefaultFoldersId | string | null;
export interface Folder {
  id: string;
  name: string;
  isPublic?: boolean;
  sharedWithMe?: boolean;
  count?: number;
  userId?: string;
}

export interface FoldersState
  extends EntityState<Folder, FolderId>,
    ActiveState<FolderId> {}

export interface FolderUI {
  isEditing: boolean;
  isLoading: boolean;
}

export type FolderUIState = EntityState<FolderUI>;

@StoreConfig({ name: "folders" })
export class FoldersStore extends EntityStore<FoldersState> {
  ui!: EntityUIStore<FolderUIState>;

  constructor() {
    super({ active: null });
    this.createUIStore().setInitialEntityState<FolderUI>({
      isEditing: false,
      isLoading: false
    });
  }

  updateEditing(id: string, isEditing: boolean): void {
    this.ui.update(id, { isEditing });
  }

  incrementCount(id?: string): void {
    if (!id) {
      return;
    }
    this.update(id, (x) => ({ ...x, count: (x?.count || 0) + 1 }));
  }

  decrementCount(id?: string): void {
    if (!id) {
      return;
    }
    this.update(id, (x) => ({ ...x, count: (x?.count || 0) - 1 }));
  }
}

export const foldersStore = new FoldersStore();
