import { Query } from "@datorama/akita";
import { combineLatest, Observable, of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { AccountSettings, AccountTemplate } from "../../interfaces/account";
import {
  Product,
  SublyPlan,
  SubscriptionStatus
} from "../../interfaces/billing";
import {
  isBusinessPlan,
  isPremiumPlan,
  isProAnnualPlan,
  isProOrHigherPlan,
  isProPlan,
  isTrial,
  isPremiumOrHigherPlan,
  getPlanStorage
} from "../../utils/plans";
import { formatBytes, getBytes } from "../../utils/storage-size";
import { mediaQuery } from "../media";
import {
  AccountCredit,
  AccountState,
  AccountStore,
  accountStore
} from "./account.store";

export class AccountQuery extends Query<AccountState> {
  get isLoaded(): boolean {
    return this.getValue().loaded;
  }

  get hasBillingDetails(): boolean {
    return Boolean(this.getValue().billing?.details?.address);
  }

  get credit(): AccountCredit {
    return this.getValue().credit;
  }

  get currentPlan(): SublyPlan {
    if (this.getValue().deal) {
      return SublyPlan.Business;
    }

    // Force Premium plan to Pay as you go users, this way
    // unlocks all premium features to PayG users.
    if (this.getValue().isPayg) {
      return SublyPlan.Premium;
    }

    return this.getValue().subscription?.name ?? SublyPlan.Free;
  }

  get currentStatus(): SubscriptionStatus | undefined {
    return this.getValue().subscription?.status;
  }

  get hasPaymentOverdue(): boolean {
    const status = this.getValue().subscription?.status;

    if (!status) {
      return false;
    }

    return (
      status === SubscriptionStatus.PastDue ||
      status === SubscriptionStatus.Unpaid
    );
  }

  get currentTrialEndDate(): Date | undefined {
    return this.getValue().subscription?.trialEndDate;
  }

  get isPro(): boolean {
    return isProPlan(this.currentPlan);
  }

  get isProOrHigher(): boolean {
    return isProOrHigherPlan(this.currentPlan);
  }

  get isPremiumOrHigher(): boolean {
    return isPremiumOrHigherPlan(this.currentPlan);
  }

  get isProAnnual(): boolean {
    return isProAnnualPlan(this.currentPlan);
  }

  get isPro100(): boolean {
    return this.currentPlan === SublyPlan.Pro100;
  }

  get isPremium(): boolean {
    return isPremiumPlan(this.currentPlan);
  }

  get isBusiness(): boolean {
    return isBusinessPlan(this.currentPlan);
  }

  get isTrial(): boolean {
    return isTrial(this.currentStatus);
  }

  get trialEndDate(): Date | undefined {
    return this.getValue().subscription?.trialEndDate ?? undefined;
  }

  get isPayg(): boolean {
    return this.getValue().isPayg;
  }

  get settings(): AccountSettings {
    const activeMediaSettings = mediaQuery.ui.getActive()?.accountSettings;

    if (activeMediaSettings) {
      return activeMediaSettings;
    }

    return this.getValue().settings;
  }

  get templates(): AccountTemplate[] {
    return this.getValue().settings.templates ?? [];
  }

  get lastVersionTour(): string {
    return this.getValue().settings.lastVersionTour;
  }

  get hasTranslated(): boolean {
    return Boolean(this.getValue().settings?.hasTranslated);
  }

  get storageBytesUsed(): number {
    return this.getValue().storageBytesUsed;
  }

  get customStorageBytes(): number | undefined {
    return this.getValue().settings?.customStorageBytes;
  }

  get customUploadLimitBytes(): number | undefined {
    return this.getValue().settings?.customUploadLimitBytes;
  }

  get additionalStorageBytes(): number {
    return this.getValue().additionalStorageBytes ?? 0;
  }

  get totalStorage(): number {
    if (this.customStorageBytes) {
      return this.customStorageBytes;
    }

    const add = this.additionalStorageBytes ?? 0;
    const plan = this.currentPlan;

    const gb = getPlanStorage(plan);

    return getBytes(gb, "GB") + add;
  }

  get availableStorage(): number {
    return this.totalStorage - (this.customStorageBytes ?? 0);
  }

  constructor(protected store: AccountStore) {
    super(store);
  }

  selectIsLoaded(): Observable<boolean> {
    return this.select("loaded");
  }

  selectCredit(): Observable<AccountCredit> {
    return this.select("credit");
  }

  selectPrimaryColors(): Observable<string[] | undefined> {
    return this.selectSettings().pipe(switchMap((s) => of(s.primaryColors)));
  }

  selectOutlineColors(): Observable<string[] | undefined> {
    return this.selectSettings().pipe(switchMap((s) => of(s.outlineColors)));
  }

  selectAspectRatioColors(): Observable<string[] | undefined> {
    return this.selectSettings().pipe(
      switchMap((s) => of(s.aspectRatioColors))
    );
  }

  selectBorderColors(): Observable<string[] | undefined> {
    return this.selectSettings().pipe(switchMap((s) => of(s.borderColors)));
  }

  selectTemplates(): Observable<AccountTemplate[]> {
    return this.selectSettings().pipe(switchMap((s) => of(s.templates ?? [])));
  }

  selectTrialDownloads(): Observable<number> {
    return this.selectSettings().pipe(
      switchMap((s) => of(s?.trialDownloads ?? 0))
    );
  }

  selectHasTranslated(): Observable<boolean> {
    return this.selectSettings().pipe(switchMap((s) => of(s?.hasTranslated)));
  }

  selectTrialEndDate(): Observable<Date | undefined> {
    return this.select((s) => s?.subscription?.trialEndDate ?? undefined);
  }

  selectCurrentPlan(): Observable<SublyPlan> {
    return this.select(["subscription", "deal", "isPayg"]).pipe(
      switchMap(({ subscription, deal, isPayg }) => {
        if (deal) {
          return of(SublyPlan.Business);
        }

        // Force Premium plan to Pay as you go users, this way
        // unlocks all premium features to PayG users.
        if (isPayg) {
          return of(SublyPlan.Premium);
        }

        const name = subscription?.name ?? SublyPlan.Free;

        return of(name);
      })
    );
  }

  selectCurrentStatus(): Observable<SubscriptionStatus | undefined> {
    return this.select(["subscription"]).pipe(
      switchMap(({ subscription: s }) => {
        if (s) {
          return of(s.status);
        }

        return of(undefined);
      })
    );
  }

  selectHasPaymentOverdue(): Observable<boolean> {
    return this.select(["subscription"]).pipe(
      switchMap(({ subscription: s }) => {
        if (s) {
          return of(
            s.status === SubscriptionStatus.PastDue ||
              s.status === SubscriptionStatus.Unpaid
          );
        }

        return of(false);
      })
    );
  }

  selectIsPro(): Observable<boolean> {
    return this.selectCurrentPlan().pipe(
      switchMap((v) => {
        return of(isProPlan(v));
      })
    );
  }

  selectIsProOrHigher(): Observable<boolean> {
    return this.selectCurrentPlan().pipe(
      switchMap((v) => {
        return of(isProOrHigherPlan(v));
      })
    );
  }

  selectIsPremiumOrHigher(): Observable<boolean> {
    return this.selectCurrentPlan().pipe(
      switchMap((v) => {
        return of(isPremiumOrHigherPlan(v));
      })
    );
  }

  selectIsPro100(): Observable<boolean> {
    return this.selectCurrentPlan().pipe(
      switchMap((v) => {
        return of(v === SublyPlan.Pro100);
      })
    );
  }

  selectIsProAnnual(): Observable<boolean> {
    return this.selectCurrentPlan().pipe(
      switchMap((v) => {
        return of(isProAnnualPlan(v));
      })
    );
  }

  selectIsPremium(): Observable<boolean> {
    return this.selectCurrentPlan().pipe(
      switchMap((v) => {
        return of(isPremiumPlan(v));
      })
    );
  }

  selectIsBusiness(): Observable<boolean> {
    return this.selectCurrentPlan().pipe(
      switchMap((v) => {
        return of(isBusinessPlan(v));
      })
    );
  }

  selectIsTrial(): Observable<boolean> {
    return this.selectCurrentStatus().pipe(
      switchMap((s) => {
        return of(isTrial(s));
      })
    );
  }

  selectIsPayg(): Observable<boolean> {
    return this.select((s) => Boolean(s.isPayg));
  }

  selectPlans(): Observable<Product[]> {
    return this.select((s) => s.plans);
  }

  selectStorageBytesUsed(): Observable<number> {
    return this.select("storageBytesUsed").pipe(
      switchMap((storageBytesUsed) => {
        return of(storageBytesUsed || 0);
      })
    );
  }

  selectCustomStorageBytes(): Observable<number | undefined> {
    return this.select((s) => s?.settings?.customStorageBytes);
  }

  selectAdditionalStorageBytes(): Observable<number> {
    return this.select((s) => s?.additionalStorageBytes ?? 0);
  }

  selectTotalStorage(): Observable<number> {
    return combineLatest([
      this.selectCurrentPlan(),
      this.selectCustomStorageBytes(),
      this.selectAdditionalStorageBytes()
    ]).pipe(
      switchMap(([plan, customStorageBytes, additionalStorageBytes]) => {
        const add = additionalStorageBytes ?? 0;
        if (customStorageBytes) {
          return of(customStorageBytes + add);
        }

        const gb = getPlanStorage(plan);
        const bytes = getBytes(gb, "GB");

        return of(bytes + add);
      })
    );
  }

  selectTotalStorageLabel(): Observable<string> {
    return this.selectTotalStorage().pipe(
      switchMap((bytes) => {
        const { size, units } = formatBytes(bytes);

        return of(`${size} ${units}`);
      })
    );
  }

  selectAvailableStorage(): Observable<number> {
    return combineLatest([
      this.selectTotalStorage(),
      this.selectStorageBytesUsed()
    ]).pipe(
      switchMap(([total, used]) => {
        return of(total - used);
      })
    );
  }

  selectCustomUploadLimitBytes(): Observable<number | undefined> {
    return this.select((s) => s?.settings?.customUploadLimitBytes);
  }

  selectSettings(): Observable<AccountSettings> {
    return combineLatest([
      this.select("settings"),
      mediaQuery.selectActiveSettings()
    ]).pipe(
      switchMap(([accountSettings, mediaAccountSettings]) => {
        if (mediaAccountSettings) {
          return of(mediaAccountSettings);
        }
        return of(accountSettings);
      })
    );
  }
}

export const accountQuery = new AccountQuery(accountStore);
