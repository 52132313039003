import React from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { AppCard } from "../../components/app-card/app-card";
import { SuggestionAppCard } from "../../components/app-card/suggest-app-card";
import { AppModal } from "../../components/app-modal/app-modal";
import { useModal } from "../../hooks/use-modal";
import { App } from "../../interfaces/app";
import { ModalType } from "../../interfaces/modal-type";
import styles from "./app-list.module.scss";

interface MediaListProps {
  appList: App[];
  isLoading: boolean;
  showSuggestionCard: boolean;
}
export const AppList: React.FC<MediaListProps> = ({
  appList,
  isLoading,
  showSuggestionCard
}) => {
  const [showModal, hideModal] = useModal(ModalType.AppModal);
  const navigate = useNavigate();

  const renderApp = appList.map((a) => (
    <div className="col-sm-6 col-md-4 col-xl-3" key={a.name}>
      <AppCard
        app={a}
        open={() => {
          if (a.connected) {
            navigate(a.link);
          } else {
            showModal(<AppModal app={a} closeModal={hideModal} />);
          }
        }}
      />
    </div>
  ));

  if (isLoading) {
    return (
      <div className="container-fluid px-0">
        <div className="row">
          <SkeletonCard />
          <SkeletonCard />
        </div>
      </div>
    );
  }

  return (
    <div className="container-fluid px-0">
      <div className="row">
        {renderApp}
        {showSuggestionCard && <SuggestionAppCard />}
      </div>
    </div>
  );
};

const SkeletonCard: React.FC = () => {
  return (
    <div className={styles.skeletonCard}>
      <Skeleton height={30} width={30} className="mb-3" />
      <Skeleton height={30} className="mb-2" />
      <Skeleton width="400px" count={2} />
    </div>
  );
};
