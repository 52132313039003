import classNames from "classnames";
import React from "react";
import styles from "./settings-card.module.scss";

// TODO: Refactor all settings cards to this
// TODO: Verify that styles are copied along and working
interface SettingsCardContainerProps {
  title: string;
}
export const SettingsCardContainer: React.FC<SettingsCardContainerProps> = ({
  title,
  children
}) => {
  return (
    <div className={classNames("my-4", styles.card)}>
      <div className="card-body p-4">
        <h6 className={styles["title"]}>{title}</h6>
        {children}
      </div>
    </div>
  );
};
