// TODO: Reusable billing form that can be used in all other components where this is used
// REFACTOR: `plans-upgrade-container.tsx`, `add-billing-details-form.tsx`

import classNames from "classnames";
import React from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import countries from "typed-countries";
import { Input } from "../../../components/controls/input";
import { Select } from "../../../components/controls/select";
import { TextArea } from "../../../components/controls/text-area";
import { RadioInput } from "../../../components/radio-input/radio-input";
import { useAccounts } from "../../../hooks/use-accounts";
import { getTaxIdTypeSelectOptions } from "../../../interfaces/billing";
import { UnknownObject } from "../../../interfaces/types";
import { BillingDetailsForm as IBillingDetailsForm } from "../../settings/billing-details-card/add-billing-details-form";

interface BillingDetailsFormProps {
  className?: string;
  useForm: UseFormReturn<IBillingDetailsForm>;
  handleSubmit: () => void;
}
export const BillingDetailsForm: React.FC<BillingDetailsFormProps> = ({
  handleSubmit,
  useForm,
  className,
  children
}) => {
  const [isBusiness, setBusiness] = React.useState(false);
  const { billing } = useAccounts();
  const {
    register,
    formState: { errors },
    control,
    setValue,
    reset,
    watch
  } = useForm;
  const watchCountry = watch("country");
  const taxIdTypeSelectOptions = getTaxIdTypeSelectOptions();

  React.useEffect(() => {
    const name = billing?.details?.name;
    const address = billing?.details?.address;

    if (billing && name && address) {
      const countryLabel =
        countries.find((c) => c.iso === address.country)?.name ||
        address.country;

      const taxIdTypeSelectOption = taxIdTypeSelectOptions?.find(
        (x) =>
          x.code === billing.details?.taxId?.type &&
          x.country === billing.details?.taxId?.country
      );

      const initForm: IBillingDetailsForm = {
        name: name,
        address: address.line1,
        city: address.city,
        state: address.state,
        postalCode: address.postalCode,
        country: {
          value: address.country,
          label: countryLabel
        },
        taxIdNumber: billing.details?.taxId?.value,
        taxIdType: taxIdTypeSelectOptions.find(
          (x) => x.value === taxIdTypeSelectOption?.value
        )
      };

      reset(initForm);
    }
  }, [billing]);

  const watchTaxIdType = watch("taxIdType");

  // If TaxIdType has the empty option selected, hide and clear TaxIdNumber
  const showTaxIdNumber = Boolean(watchTaxIdType?.value);

  React.useEffect(() => {
    if (watchCountry) {
      const emptySelection = {
        code: "",
        country: "",
        format: "",
        label: "​",
        name: "",
        value: ""
      };

      const selection = taxIdTypeSelectOptions.find(
        (option) => option.country === watchCountry.value
      );

      setValue("taxIdType", selection ? selection : emptySelection);
      setValue("taxIdNumber", "");
    }
  }, [watchCountry]);

  React.useEffect(() => {
    if (!showTaxIdNumber) {
      setValue("taxIdNumber", "");
    }
  }, [watchTaxIdType]);

  const customStyles = {
    control: (base: UnknownObject) => ({
      ...base,
      height: 40,
      minHeight: 40,
      border: errors.country
        ? "1px solid var(--red)"
        : "1px solid var(--tertiary);"
    })
  };

  return (
    <>
      <form className={className} onSubmit={handleSubmit}>
        <div
          className="d-flex align-items-center mb-3"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setBusiness(e.target.value === "business");
          }}
        >
          <RadioInput
            className="custom-control-inline"
            name="billingType"
            id="personal"
            value="personal"
            defaultChecked
          >
            Personal
          </RadioInput>
          <RadioInput
            className="custom-control-inline"
            name="billingType"
            id="business"
            value="business"
          >
            Business
          </RadioInput>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="form-group mb-3">
              <Input
                label="Name"
                hasError={Boolean(errors.name)}
                {...register("name", { required: true })}
              />
            </div>
            <div className="form-group mb-3">
              <TextArea
                label="Address"
                hasError={Boolean(errors.address)}
                {...register("address", { required: true })}
              />
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group mb-3">
                  <Input
                    label="City"
                    hasError={Boolean(errors.city)}
                    {...register("city", { required: true })}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group mb-3">
                  <Input
                    label="State (optional)"
                    hasError={Boolean(errors.state)}
                    {...register("state")}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group mb-3">
                  <Input
                    label="Postal Code"
                    hasError={Boolean(errors.postalCode)}
                    {...register("postalCode", { required: true })}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group mb-3">
                  <label className="mb-1">Country</label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        className="country-select"
                        options={countries.map((c) => ({
                          value: c.iso,
                          label: c.name
                        }))}
                        styles={customStyles}
                        {...field}
                      />
                    )}
                    control={control}
                    rules={{ required: true }}
                    name="country"
                  />
                </div>
              </div>
            </div>

            {isBusiness && (
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group mb-3">
                    <label className="mb-1">Tax Type</label>
                    <Controller
                      render={({ field }) => (
                        <Select
                          options={taxIdTypeSelectOptions}
                          styles={customStyles}
                          {...field}
                        />
                      )}
                      control={control}
                      name="taxIdType"
                    />
                  </div>
                </div>
                <div
                  className={classNames("col-sm-6", {
                    "d-none": !showTaxIdNumber
                  })}
                >
                  <Input
                    label="Tax Number"
                    hasError={Boolean(errors.taxIdNumber)}
                    placeholder={watchTaxIdType?.format}
                    {...register("taxIdNumber", {
                      required: Boolean(showTaxIdNumber)
                    })}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        {children}
      </form>
    </>
  );
};
