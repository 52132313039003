import React, { useContext, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { getCustomer } from "../api/billing.service";
import { AuthContext } from "../contexts/auth.context";
import { LOGIN_PATH } from "../routes";
import { authQuery } from "../state/auth/auth.query";
import { Pusher } from "./pusher/pusher";

export const SecureRoute: React.FC = ({ children }) => {
  const { isAuthenticated, getAccessTokenAndCurrentUser } =
    useContext(AuthContext);
  const { pathname, search } = useLocation();

  useEffect(() => {
    if (isAuthenticated === undefined) {
      loadAuthInfo();
    } else if (isAuthenticated === true) {
      // Make sure it always has the latest customer info
      getCustomer({ getSettings: true });
    }
  }, [isAuthenticated]);

  const loadAuthInfo = async () => {
    await getAccessTokenAndCurrentUser();
  };

  if (isAuthenticated && authQuery.userId && authQuery.accountId) {
    return (
      <>
        <Pusher accountId={authQuery.accountId} userId={authQuery.userId} />
        {children}
      </>
    );
  }

  if (isAuthenticated === false) {
    // Save path to redirect on login
    const redirect =
      pathname !== "/"
        ? `redirect=${encodeURIComponent(pathname + search)}`
        : "";

    return <Navigate to={{ pathname: LOGIN_PATH, search: redirect }} />;
  }

  // isAuthenticated is undefined - we don't render anything till we know whether authentication has succeeded
  return <></>;
};
