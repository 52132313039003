import {
  EntityUIQuery,
  Order,
  QueryConfig,
  QueryEntity
} from "@datorama/akita";
import {
  AspectRatio,
  generateRatioConfig,
  MediaConfig
} from "@getsubly/common";
import { combineLatest, Observable, of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { AccountSettings } from "../../interfaces/account";
import { BasicMedia, MediaFileType } from "../../interfaces/media";
import { foldersQuery } from "../folders/folders.query";
import { DefaultFoldersId } from "../folders/folders.store";
import { PresenceUser, userPresenceQuery } from "../user-presence";
import {
  DashboardFilter,
  MediaState,
  MediaStore,
  mediaStore,
  MediaUIState,
  NewSnippet,
  SavingFileType
} from "./media.store";

@QueryConfig({
  sortBy: "createdAt",
  sortByOrder: Order.DESC
})
export class MediaQuery extends QueryEntity<MediaState> {
  ui!: EntityUIQuery<MediaUIState>;

  get isLoading(): boolean {
    return this.getValue().loading;
  }

  get activeFolderMediaTotal(): number | undefined {
    return this.getValue().activeFolderMediaTotal;
  }

  constructor(protected store: MediaStore) {
    super(store);
    this.createUIQuery();
  }

  uiHasSavingType = (
    mediaId: string,
    savingFileType: SavingFileType
  ): boolean => {
    const mediaUI = this.ui.getEntity(mediaId);

    if (!mediaUI) {
      return false;
    }

    return mediaUI.savingFileTypes.includes(savingFileType);
  };

  selectHasViewer(): Observable<boolean> {
    return this.selectActiveId().pipe(
      switchMap((id) => {
        if (!id) {
          return of(false);
        }
        return userPresenceQuery
          .selectLockedUsers(id.toString())
          .pipe(switchMap((u) => of(Boolean(u.length))));
      })
    );
  }

  selectLockedUser(): Observable<PresenceUser | undefined> {
    return this.selectActiveId().pipe(
      switchMap((id) => {
        if (!id) {
          return of(undefined);
        }
        return userPresenceQuery.selectActiveUser(id.toString());
      })
    );
  }

  selectActiveUser(): Observable<PresenceUser | undefined> {
    return combineLatest([
      this.select((s) => s.loadedAt),
      this.selectLockedUser()
    ]).pipe(
      switchMap(([loadedAt, activeUser]) => {
        if (!loadedAt) {
          return of(undefined);
        }

        if (!activeUser || !activeUser?.joinedAt) {
          return of(undefined);
        }

        if (activeUser.joinedAt.valueOf() < loadedAt.valueOf()) {
          return of(activeUser);
        }
        return of(undefined);
      })
    );
  }

  selectAllUnfilteredLength(): Observable<number> {
    return this.select(["activeFolderId"]).pipe(
      switchMap(({ activeFolderId }) => {
        return this.selectCount((m) =>
          isMediaFolderAndFilter(m, activeFolderId)
        );
      })
    );
  }

  selectAllFiltered(): Observable<BasicMedia[]> {
    return this.select([
      "ids", // select this to update when the list is updated (new upload)
      "dashboardFilter",
      "activeFolderId"
    ]).pipe(
      switchMap(({ dashboardFilter, activeFolderId }) => {
        return this.selectAll({
          filterBy: (m) =>
            isMediaFolderAndFilter(m, activeFolderId, dashboardFilter)
        });
      })
    );
  }

  selectMediaConfig(): Observable<MediaConfig | undefined> {
    return this.selectActive((b) => b.assConfig).pipe(
      switchMap((assConfig) => {
        if (!assConfig) {
          return of(assConfig);
        }

        const ratio = assConfig.aspectRatio?.ratio ?? AspectRatio.Original;

        const styles = generateRatioConfig(assConfig, ratio);

        return of(styles);
      })
    );
  }

  selectCurrentTranscriptionId(): Observable<string> {
    return combineLatest([
      this.ui.selectActive((m) => m.currentTranscriptionId),
      this.selectActive((m) => m.transcriptions?.originalId)
    ]).pipe(
      switchMap(([activeId, originalId]) => {
        const id = (activeId || originalId) as string;
        return of(id);
      })
    );
  }

  selectActiveSettings(): Observable<AccountSettings | undefined> {
    return this.ui.selectActive((s) => s?.accountSettings);
  }

  selectSnippets(mediaId?: string): Observable<NewSnippet> {
    if (!mediaId) {
      return of({});
    }

    return this.ui.selectEntity(mediaId, "snippets").pipe(
      switchMap((snippets) => {
        if (!snippets) {
          return of({});
        }
        return of(snippets);
      })
    );
  }
}

const isMediaFolderAndFilter = (
  m: BasicMedia,
  activeFolderId?: string | null,
  dashboardFilter?: DashboardFilter
): boolean => {
  const isAllFilesFolder = activeFolderId === DefaultFoldersId.All;
  const isPrivateAllFilesFolder =
    activeFolderId === DefaultFoldersId.AllPrivate;
  const isSharedAllFilesFolder = activeFolderId === DefaultFoldersId.AllShared;
  const isSharedWithMeAllFilesFolder =
    activeFolderId === DefaultFoldersId.AllSharedWithMe;
  const isMediaNotInActiveFolder =
    activeFolderId && m.folderId !== activeFolderId;

  if (
    !isAllFilesFolder &&
    !isPrivateAllFilesFolder &&
    !isSharedAllFilesFolder &&
    !isSharedWithMeAllFilesFolder &&
    isMediaNotInActiveFolder
  ) {
    return false;
  }

  if (isPrivateAllFilesFolder && !foldersQuery.isFolderPrivate(m.folderId)) {
    return false;
  }

  if (isSharedAllFilesFolder && foldersQuery.isFolderPrivate(m.folderId)) {
    return false;
  }

  if (!isSharedWithMeAllFilesFolder && m?.sharedWithMe && !isAllFilesFolder) {
    return false;
  }

  if (isSharedWithMeAllFilesFolder && !m?.sharedWithMe) {
    return false;
  }

  switch (dashboardFilter) {
    case DashboardFilter.Video:
      return m.type === MediaFileType.Video;
    case DashboardFilter.Audio:
      return m.type === MediaFileType.Audio;

    default:
      return true;
  }
};

export const mediaQuery = new MediaQuery(mediaStore);
