import { SublyPlan } from "../interfaces/billing";
import { mediaQuery } from "../state/media";
import { isInferiorPlan } from "../utils/plans";
import { usePlan } from "./use-plan";

interface UsePermissions {
  hasPermission: boolean;
}

export function usePermissions(
  minPermission = SublyPlan.Pro100
): UsePermissions {
  const { plan: currentPlan, hasPaymentOverdue, isPayg } = usePlan();
  const mediaPlan = mediaQuery.getActive()?.plan;

  if (mediaPlan) {
    const hasPermission = isInferiorPlan(minPermission, mediaPlan);

    return {
      hasPermission
    };
  }

  if (hasPaymentOverdue) {
    return { hasPermission: false };
  }

  const plan = isPayg ? SublyPlan.Premium : currentPlan;

  const hasPermission = isInferiorPlan(minPermission, plan);

  return {
    hasPermission
  };
}
