import {
  mdiAccountMultiple,
  mdiChevronDown,
  mdiCircle,
  mdiCircleOutline
} from "@mdi/js";
import Icon from "@mdi/react";
import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";
import { Divider } from "../../../components/divider/divider";
import {
  Dropdown,
  DropdownButton,
  DropdownContext,
  DropdownMenu
} from "../../../components/dropdown/dropdown";
import { useAccounts } from "../../../hooks/use-accounts";
import { useModal } from "../../../hooks/use-modal";
import { usePlan } from "../../../hooks/use-plan";
import { UserAccount } from "../../../interfaces/account";
import { ModalType } from "../../../interfaces/modal-type";
import { TEAM_PATH } from "../../../routes";
import { CreateWorkspaceModal } from "./create-workspace-modal";
import styles from "./workspaces-dropdown.module.scss";

export const WorkspacesDropdown: React.FC = () => {
  const dropdownRef = React.useRef(null);
  const { accounts, currentAccount } = useAccounts();
  const { isBusiness } = usePlan();
  const [showModal, hideModal] = useModal(ModalType.CreateWorkspace);

  const handleCreateWorkspace = () => {
    showModal(<CreateWorkspaceModal onDismiss={hideModal} />);
  };

  const showWorkplaces = accounts.length > 1 || isBusiness;

  if (!showWorkplaces) {
    return null;
  }

  const renderAccounts = accounts.map((a) => {
    return (
      <WorkspacesDropdownItem
        key={a.accountId}
        account={a}
        isSelected={a.accountId === currentAccount?.accountId}
      />
    );
  });

  return (
    <div className={styles.workspaces}>
      <div
        ref={dropdownRef}
        className={classNames("dropdown user-select-none", styles.dropdown)}
      >
        <Dropdown>
          <DropdownButton
            dropdownEl={dropdownRef}
            className={styles["dropdown-button"]}
            isOpenClassName={styles["dropdown-button--open"]}
          >
            <div className="d-flex align-items-center justify-content-between">
              <span className="text-truncate ">
                {currentAccount?.accountName}
              </span>
              <span className="flex-shrink-0">
                <Icon path={mdiChevronDown} size="1.6em" />
              </span>
            </div>
          </DropdownButton>

          <DropdownMenu
            isUp
            title="Workspaces"
            className={styles["dropdown-menu"]}
            hasOverflow={true}
          >
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  <h6 className="text-interactive">
                    {currentAccount?.accountName} Workspace
                  </h6>
                  <Link to={TEAM_PATH}>
                    <span className="smaller">
                      <Icon
                        path={mdiAccountMultiple}
                        size="1rem"
                        className="mr-1"
                      />{" "}
                      Manage teammates
                    </span>
                  </Link>
                  <Divider />

                  <p className="smaller">All workspaces</p>
                  {renderAccounts}

                  {isBusiness && (
                    <>
                      <Divider />

                      <Link to="#" onClick={handleCreateWorkspace}>
                        <span className="smaller">+ Create new Workspace</span>
                      </Link>
                    </>
                  )}
                </div>
              </div>
            </div>
          </DropdownMenu>
        </Dropdown>
      </div>
    </div>
  );
};

interface WorkspacesDropdownItemProps {
  account: UserAccount;
  isSelected?: boolean;
}
const WorkspacesDropdownItem: React.FC<WorkspacesDropdownItemProps> = ({
  isSelected,
  account
}) => {
  const { toggleOpen } = React.useContext(DropdownContext);
  const { switchAccount } = useAccounts();
  const icon = isSelected ? mdiCircle : mdiCircleOutline;

  const handleSwitch = () => {
    toggleOpen(false);
    switchAccount(account.accountId);
  };

  return (
    <div>
      <Link to="#" onClick={handleSwitch}>
        <span className="smaller">
          <Icon path={icon} size="0.4rem" className="mr-1" />{" "}
          {account.accountName}
        </span>
      </Link>
    </div>
  );
};
