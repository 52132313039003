// Based from https://ec.europa.eu/taxation_customs/sites/taxation/files/resources/documents/taxation/vat/how_vat_works/rates/vat_rates_en.pdf

interface VATRate {
  [country: string]: number;
}
const VAT_RATES: VATRate = {
  BE: 0.21,
  BG: 0.2,
  CZ: 0.21,
  DK: 0.25,
  DE: 0.19,
  EE: 0.2,
  IE: 0.23,
  EL: 0.24,
  ES: 0.21,
  FR: 0.2,
  HR: 0.25,
  IT: 0.22,
  CY: 0.19,
  LV: 0.21,
  LT: 0.21,
  LU: 0.17,
  HU: 0.27,
  MT: 0.18,
  NL: 0.21,
  AT: 0.2,
  PL: 0.23,
  PT: 0.23,
  RO: 0.19,
  SI: 0.22,
  SK: 0.2,
  FI: 0.24,
  SE: 0.25,
  GB: 0.2
};

export const getVAT = (country: string): number | undefined => {
  return VAT_RATES[country];
};
