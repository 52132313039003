import React from "react";
import classNames from "classnames";

import styles from "./auth.module.scss";

export const EmbeddedWebviewMsg: React.FC = () => (
  <div className={classNames(styles.webView, "p-1", "mb-3")}>
    <p className="text-center text-white mb-0 px-2 small">
      For the best experience with Subly please open outside of this app in your
      preferred browser.
    </p>
  </div>
);
