import React from "react";
import Icon from "@mdi/react";
import { mdiLoading } from "@mdi/js";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { AuthContext } from "../../contexts/auth.context";
import { notificationSuccess } from "../../components/notification";
import { Button } from "../../components/button/button";
import { verifyEmailResend } from "../../api/auth.service";
import { DASHBOARD_PATH } from "../../routes";

import styles from "./auth.module.scss";

interface ResponseData {
  alreadyVerified?: boolean;
}

export const EmailVerification: React.FC = () => {
  const { id, code = "" } = useParams();
  const [, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [responseData, setResponseData] = React.useState<ResponseData>();
  const [alertMessage, setAlertMessage] = React.useState("");
  const { verifyEmail } = React.useContext(AuthContext);
  const { search } = useLocation();

  const handleVerifyEmail = async (code: string) => {
    try {
      setLoading(true);

      const { success, data, alertMessage } = await verifyEmail(code);
      setLoading(false);
      setResponseData(data);
      setAlertMessage(alertMessage);

      if (success) {
        notificationSuccess("Email verification was successful.");
      }

      setError(!success);
      setSuccess(success);
    } catch (e) {
      setLoading(false);
      setError(true);
    }
  };

  React.useEffect(() => {
    handleVerifyEmail(code);
  }, [id, code]);

  if (error) {
    return <VerificationError message={alertMessage} />;
  }

  const renderContent = () => {
    if (responseData?.alreadyVerified) {
      return (
        <>
          <p className="text-center">Your email was already confirmed.</p>
          <Button primary to={{ pathname: DASHBOARD_PATH, search }}>
            Go to Dashboard
          </Button>
        </>
      );
    }

    if (success) {
      return (
        <>
          <h4 className="text-center mb-4">Verification was successful</h4>
          <p className="text-center">
            Your email was confirmed. Please go to the dashboard.
          </p>
          <Navigate to={{ pathname: DASHBOARD_PATH, search }} />
        </>
      );
    }

    // default is triggered message
    return (
      <>
        <h4 className="text-center mb-4">We are verifying your account</h4>
        <p className="text-center">
          <Icon path={mdiLoading} spin size="1.2rem" className="mr-2" /> Please
          wait a moment...
        </p>
      </>
    );
  };

  return <div className={styles.container}>{renderContent()}</div>;
};

interface VerificationErrorProps {
  message: string;
}
const VerificationError: React.FC<VerificationErrorProps> = ({ message }) => {
  const [hasResentVerification, setResentVerification] = React.useState(false);

  const handleResendVerification = () => {
    verifyEmailResend();
    setResentVerification(true);
  };

  if (hasResentVerification) {
    return (
      <VerificationErrorContainer>
        <p className="text-center">
          We have just emailed you a new verification code, please check your
          email.
        </p>
      </VerificationErrorContainer>
    );
  }

  if (
    message.includes("limit exceeded") ||
    message.includes("does not exist")
  ) {
    return (
      <VerificationErrorContainer>
        <p className="text-center">{message}</p>
        <Button primary to="mailto:support@getsubly.com" type="blank">
          Contact support
        </Button>
      </VerificationErrorContainer>
    );
  }

  return (
    <VerificationErrorContainer>
      <p className="text-center">{message}</p>

      <Button
        primary
        disabled={hasResentVerification}
        onClick={handleResendVerification}
      >
        Resend verification email
      </Button>
    </VerificationErrorContainer>
  );
};

const VerificationErrorContainer: React.FC = ({ children }) => {
  return (
    <div className={styles.container}>
      <h4 className="text-center mb-4">Verification failed</h4>
      {children}
    </div>
  );
};
