import slugify from "slugify";
import config from "../config";

export const makeShareUrl = ({
  previewBaseUrl,
  publicShareId,
  name
}: {
  previewBaseUrl: string;
  publicShareId: string;
  name?: string;
}): string =>
  `${previewBaseUrl}/preview/${publicShareId}${
    name && slugify(name) ? `/${slugify(name)}` : ""
  }`;

/**
 * Clean the search string if it exists.
 *
 * `cleanSearchString("?param1=value&param2=value")`
 * `param1=value&param2=value`
 */
export const cleanSearchString = (search = "", append = ""): string => {
  const splitSearch = search.split("?");
  const newSearch = splitSearch.length > 1 ? splitSearch[1] : search;

  return newSearch ? `${append}${newSearch}` : "";
};

export const encodeParamsToState = (state?: string, gclid?: string): string => {
  if (!state && !gclid) {
    return "";
  }

  return btoa(JSON.stringify({ state, gclid }));
};

export const isBase64 = (encoded?: string): boolean => {
  if (!encoded || encoded.trim() === "" || encoded === "undefined") {
    return false;
  }

  try {
    return btoa(atob(encoded)) === encoded;
  } catch (err) {
    return false;
  }
};

interface DecodedState {
  state?: string;
  gclid?: string;
}
export const decodeQueryState = (queryState?: string): DecodedState => {
  if (!queryState || queryState === "undefined") {
    return {};
  }

  // State is base64 encoded and can have `state` and `gclid` params
  try {
    const state = JSON.parse(atob(queryState));

    return {
      state: state.state,
      gclid: state.gclid
    };
  } catch (error) {
    return {};
  }
};

export const getGoogleSignInHref = (
  param: string | undefined,
  gclid: string | undefined
): string => {
  const encodedParams = encodeParamsToState(param, gclid);
  let href = `${config.apiUrl}/api/v1/auth/google/authorize?redirect_uri=${config.oauth.google}`;

  if (encodedParams) {
    href += `&state=${encodedParams}`;
  }
  return href;
};
