import React, { useEffect } from "react";
import { Navigate } from "react-router";
import { getCustomer } from "../../../api/billing.service";
import { BillingDetailsCard } from "../../../containers/settings/billing-details-card/billing-details-card";
import { InvoicesCard } from "../../../containers/settings/invoices-card/invoices-card";
import { PaymentMethodCard } from "../../../containers/settings/payment-method-card/payment-method-card";
import { useAccounts } from "../../../hooks/use-accounts";
import { SettingsLayout } from "../../../layouts/settings/settings.layout";
import { ACCOUNT_PATH } from "../../../routes";

export const BillingPage: React.FC = () => {
  const { isAdmin, user } = useAccounts();

  useEffect(() => {
    if (!user) {
      return;
    }

    getCustomer({ force: false });
  }, [user]);

  if (!user || !isAdmin) {
    return <Navigate to={ACCOUNT_PATH} />;
  }

  return (
    <SettingsLayout>
      <div className="container-fluid my-2">
        <div className="row">
          <div className="col mt-3 mb-2">
            <h3 className="title text-black">Billing</h3>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <PaymentMethodCard />
            <BillingDetailsCard />
            <InvoicesCard />
          </div>
        </div>
      </div>
    </SettingsLayout>
  );
};
