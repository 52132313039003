import classNames from "classnames";
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { CloseIcon } from "../../components/icons/icons";
import styles from "./modal.module.scss";

export interface ModalProps {
  hideBackground?: boolean;
  className?: string;
  onDismiss?: () => void;
  hasCloseIcon?: boolean;
  closeIconClassName?: string;
  disableBackgroundDismiss?: boolean;
}
export const Modal: React.FC<ModalProps> = ({
  onDismiss,
  hideBackground,
  className,
  hasCloseIcon,
  closeIconClassName,
  disableBackgroundDismiss,
  children
}) => {
  const portalElement = document.querySelector("#portal");
  const el = document.createElement("div");

  useEffect(() => {
    if (!portalElement) {
      return;
    }

    portalElement.appendChild(el);

    return () => {
      portalElement.removeChild(el);
    };
  }, [portalElement, el]);

  useEffect(() => {
    const handleEscKey = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onDismiss && onDismiss();
      }
    };

    document.querySelector("body")?.classList.add("overflow-hidden");
    document.addEventListener("keydown", handleEscKey);

    return () => {
      document.querySelector("body")?.classList.remove("overflow-hidden");
      document.removeEventListener("keydown", handleEscKey);
    };
  }, [onDismiss]);

  if (!el) {
    return null;
  }

  return ReactDOM.createPortal(
    <div
      className={classNames(styles["modal"], {
        [styles["modal--no-bg"]]: hideBackground
      })}
      onClick={() => !disableBackgroundDismiss && onDismiss && onDismiss()}
    >
      {!hideBackground && <div className={styles["background"]} />}
      <div
        className={classNames(styles["content"], className)}
        onClick={(e) => e.stopPropagation()}
      >
        {hasCloseIcon && (
          <CloseIcon
            className={classNames(styles["close"], closeIconClassName)}
            onClick={onDismiss}
          />
        )}
        {children}
      </div>
    </div>,
    el
  );
};
