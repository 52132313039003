import classNames from "classnames";
import React from "react";
import { updateSettings } from "../../api/auth.service";
import { DASHBOARD_PATH } from "../../routes";
import { Link } from "react-router-dom";
import SublyLogo from "../../assets/images/logo-bg-dark.svg";
import styles from "./onboarding-trial-page.module.scss";
import { TrialInviteContainer } from "../../containers/onboarding/forms/trial-invite-container";

export const OnboardingTrialPage: React.FC = () => {
  const skip = async () => {
    await updateSettings({
      trialOnboarding: true
    });
  };

  return (
    <div className={styles["trial-onboarding-container"]}>
      <nav className="navbar navbar-expand navbar-dark top-navbar border-bottom-0 py-4 px-md-5">
        <img src={SublyLogo} alt="Subly" loading="lazy" />
      </nav>
      <div className={classNames("container")}>
        <div className={classNames("mt-3", styles["invite-box"])}>
          <TrialInviteContainer />
          <div className={styles["skip-btn"]}>
            <Link className={styles["link"]} onClick={skip} to={DASHBOARD_PATH}>
              Skip this step
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
